/**
 *
 * Application
 *
 */

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {Switch, Route, Redirect} from 'react-router-dom';

import actions from '../../actions';

// routes
import Login from '../Login';
import Authentication from '../Authentication';
import WithoutAuthentication from '../Authentication/withoutAuthentication';
import GuestDashboard from '../Dashboard/dashboardView';
import AuthSuccess from '../AuthSuccess';
import Dashboard from '../Dashboard';
import Notification from '../Notification';
import Page404 from '../../components/Common/Page404';
import Page500 from '../../components/Common/Page500';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import VerifyEmail from '../VerifyEmail';
import Header from '../../components/Common/Header';
import Footer from '../../components/Common/Footer';
import Verification from '../Dashboard/verification';
import View from '../Petitions/view';
import CommunityIssues from '../CommunityIssues';
import RegisterFlow from "../../components/Manager/RegisterFlow";
import ConfirmExistingUser from "../../components/Manager/RegisterFlow/confirmExistingUser";
import { getUserNotificationPreferences } from '../UserNotificationPreferences/actions';

const Application = (props) => {
  const {
    fetchProfile,
    authenticated,
    fetchUserProfile,
    fetchFirebaseToken,
    getUnReadNotification,
    loadMsgStatus,
    isSilentUser,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (authenticated === true || fetchUserProfile === true) {
      if (token) {
        //Proceed further for logged-in user
        fetchFirebaseToken().then(() => {
          fetchProfile();
          getUnReadNotification();
          dispatch(getUserNotificationPreferences());  
        });
      }
    }
  }, [
    fetchProfile,
    authenticated,
    fetchUserProfile,
    fetchFirebaseToken,
    getUnReadNotification,
    isSilentUser
  ]);

  return (
    <>
      <div id="wrapper">
        <Notification />
        <Header />
        {loadMsgStatus === true ? (
          <div className={`mt-4 pt-3 mb-2 load-msg-status`}>
            <p>{`Connecting your residents with you.`}</p>
          </div>
        ) : (
          ''
        )}
        <Switch>
          {authenticated === false ? (
            <Route path={'/dashboard/initiative'} component={View} />
          ) : (
            ''
          )}
          {authenticated === false ? (
            <Route
              path={'/dashboard/user-profile'}
              component={WithoutAuthentication(GuestDashboard)}
            />
          ) : (
            ''
          )}
          {authenticated === false ? (
            <Route
              path={'/dashboard/civicmap'}
              component={WithoutAuthentication(GuestDashboard)}
            />
          ) : (
            ''
          )}
          {authenticated === false ? (
            <Route
              path={'/dashboard/engagement'}
              component={WithoutAuthentication(GuestDashboard)}
            />
          ) : (
            ''
          )}
          {authenticated === false ? (
              <Route
                  path={'/dashboard/civicfeed'}
                  component={WithoutAuthentication(GuestDashboard)}
              />
          ) : (
              ''
          )}
          {authenticated === false ? (
              <Route
                  path={'/dashboard/localnews'}
                  component={WithoutAuthentication(GuestDashboard)}
              />
          ) : (
              ''
          )}
          {authenticated === false ? (
              <Route
                  path={'/community-issues'}
                  component={WithoutAuthentication(GuestDashboard)}
              />
          ) : (
              ''
          )}
          <Route exact path="/" component={() => <Redirect to="/dashboard/civicfeed" />} />
          {/*<Route path="/login" component={Login} />*/}
          {/*<Route path="/register" component={Login} />*/}
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/verifyemail" component={VerifyEmail} />
          <Route path="/auth/success" component={AuthSuccess} />
          <Route path="/verification" component={Verification} />
          <Route path="/dashboard" component={Authentication(Dashboard)} />
          <Route
              path={'/dashboard/localnews'}
              component={WithoutAuthentication(GuestDashboard)}
          />
          <Route
            path={'/community-issues'}
            component={authenticated ? Authentication(Dashboard) : WithoutAuthentication(GuestDashboard)}
          />
          <Route path="/404" component={Page404} />
          <Route path="/500" component={Page500} />
          <Route path="*" component={Page404} />
        </Switch>
        <RegisterFlow />
        <ConfirmExistingUser />
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    fetchUserProfile: state.account.fetchUserProfile,
    firebaseToken: state.account.firebaseToken,
    loadMsgStatus: state.authentication.loadMsgStatus,
    isSilentUser: state.authentication.isSilentUser
  };
};

export default connect(mapStateToProps, actions)(Application);
