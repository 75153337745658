/**
 *
 * Dashboard Account Panel
 *
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page404 from '../../Common/Page404';

const AccountPanel = (props) => {
  const { pages, user } = props;

  return (
    <>
      {Object.keys(pages).length !== 0 &&
      typeof user.roleName !== 'undefined' ? (
        <Switch>
          {pages[user.roleName].map((link, index) =>
            link.type === 'page' ? (
              <Route
                exact
                path={link.to}
                component={link.component}
                key={`route-${index}`}
              />
            ) : (
              ''
            ),
          )}
          <Route path="*" component={Page404} />
        </Switch>
      ) : (
        ''
      )}
      {Object.keys(pages).length !== 0 &&
      typeof user.roleName === 'undefined' ? (
        <Switch>
          {pages['GUEST'].map((link, index) =>
            link.type === 'page' && link.authentication === false ? (
              <Route
                exact
                path={link.to}
                component={link.component}
                key={`route-${index}`}
              />
            ) : (
              ''
            ),
          )}
          <Route path="*" component={Page404} />
        </Switch>
      ) : (
        ''
      )}
    </>
  );
};

AccountPanel.defaultProps = {
  pages: [],
};

export default AccountPanel;
