/*
 *
 * Application reducer
 *
 */

import {
  DEFAULT_ACTION,
  SET_COUNTDOWN_STATUS,
  SET_STATUS_MESSAGES,
} from './constants';

const initialState = {
  countDown: true,
  statusMessages: [],
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case SET_COUNTDOWN_STATUS:
      return {
        ...state,
        countDown: action.payload,
      };
    case SET_STATUS_MESSAGES:
      return {
        ...state,
        statusMessages: action.payload,
      };
    default:
      return state;
  }
};

export default applicationReducer;
