/**
 *
 * Eligible Petition Popup
 *
 */

import React from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import { useTranslation } from 'react-i18next';

const EligiblePetition = (props) => {
  const { submitSignPetition, petitionSingle, isPetitionLoading } = props;
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSignPetition(petitionSingle.id);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {isPetitionLoading === true ? (
          <LoadingIndicator position={`fixed`} />
        ) : (
          ''
        )}
        <Notification module={`petition`} />
        <div className="form-group">
          <p className="text-center">
            {t('Are you sure you want to sign this initiative?')}
          </p>
        </div>
        <div className="signin-petition">
          <button type="submit" className="btn clsyellow-btn">
            {t('Yes')}
          </button>
          <button
            type="button"
            className="btn clsyellow-btn"
            data-dismiss="modal"
          >
            {t('No')}
          </button>
        </div>
      </form>
    </>
  );
};

export default EligiblePetition;
