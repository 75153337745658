/**
 *
 * Dashboard
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Dashboard from '../../components/Manager/Dashboard';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

const Account = (props) => {
  const { isLoading, user } = props;

  return (
    <>
      {isLoading && typeof user.roleName === 'undefined' ? (
        <LoadingIndicator inline />
      ) : (
        <Dashboard {...props} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    blocks: state.account.blocks,
    pages: state.account.pages,
    isLoading: state.account.isLoading,
    inactiveMessages: state.account.inactiveMessages,
  };
};

export default connect(mapStateToProps, actions)(Account);
