/**
 *
 * Image/Video Slider
 *
 */

import React, { useEffect, useState } from 'react';
import CarouselSlider from '../../Common/CarouselSlider';
import { responsiveOneItemCarousel } from '../../Common/CarouselSlider/helpers';
import { getFileExtension } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';

const ImgSlider = (props) => {
  const { engagement } = props;
  const [loaded, setLoaded] = useState(false);
  const [slideItems, setSlideItems] = useState(null);
  const [arrow, setArrow] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (loaded === false) {
      let attached_documents = null;
      let allowedFormat = [
        'jpeg',
        'png',
        'jpg',
        'tiff',
        'svg',
        'gif',
        'mp4',
        'mov',
        'flv',
        'avi',
        'webm',
      ];
      if (engagement.attached_documents !== null) {
        attached_documents = engagement.attached_documents
          .map((file, index) => {
            let fileExt = getFileExtension(JSON.parse(file).fileLocation);
            if (allowedFormat.includes(fileExt) === true) {
              return JSON.parse(file);
            }
            return undefined;
          })
          .filter(function (item) {
            return item !== undefined;
          });
      }
      setSlideItems(attached_documents);
      setLoaded(true);
    }
  }, [setSlideItems, engagement, loaded, setLoaded]);

  return (
    <>
      {loaded === true ? (
        <>
          {slideItems !== null ? (
            <div
              className="post-img-block mt-2"
              onMouseOver={() => {
                if (slideItems.length > 1) {
                  setArrow(true);
                }
              }}
              onMouseLeave={() => {
                setArrow(false);
              }}
            >
              <CarouselSlider
                swipeable={true}
                showDots={false}
                autoPlay={false}
                focusOnSelect={false}
                slides={slideItems}
                responsive={responsiveOneItemCarousel}
                partialVisible={true}
                containerClass="slide-container"
                itemClass={`slide-item`}
                autoPlaySpeed={3000}
                shouldResetAutoplay={false}
                arrows={arrow}
              >
                {slideItems.map((slide, index) =>
                  slide.fileType?.split('/', 1)[0] === 'video' ? (
                    <div
                      className="post-img-block mt-2"
                      key={`post-media-${index}`}
                    >
                      <video width="100%" height="240" controls>
                        <source
                          src={slide.fileLocation}
                          type={
                            slide.fileType === 'video/quicktime'
                              ? 'video/mp4'
                              : slide.fileType
                          }
                        />
                        {t(`Your browser does not support the video tag.`)}
                      </video>
                    </div>
                  ) : (
                    <div
                      className="post-img-block mt-2"
                      key={`post-media-${index}`}
                    >
                      <img src={slide.fileLocation} alt="Post" />
                    </div>
                  ),
                )}
              </CarouselSlider>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ImgSlider;
