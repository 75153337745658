/**
 *
 * Report an issue
 *
 */

import React from 'react';
import Notification from '../../../containers/Notification';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const ReportAnIssue = (props) => {
  const {
    reportFormData,
    reportFormErrors,
    isReportLoading,
    isReportSubmitting,
    reportFormChange,
    submitReportForm,
  } = props;
  const { t } = useTranslation();
  const currentUrl = window.location.href;

  const handleSubmit = (event) => {
    event.preventDefault();
    reportFormChange('reportLink', currentUrl);
    submitReportForm();
  };

  return (
    <div className="clsengagement-form clsevent-form">
      {isReportLoading && <LoadingIndicator />}
      <Notification module={'account'} />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">{t(`Description`)}</label>
          <textarea
            className="form-control"
            rows="5"
            placeholder={t(`Enter Description`)}
            id="description"
            name="description"
            value={reportFormData.description}
            onChange={(e) => reportFormChange(e.target.name, e.target.value)}
          ></textarea>
          {typeof reportFormErrors.description !== 'undefined' &&
            reportFormErrors.description && (
              <div className="clsinvalid">
                {t(reportFormErrors?.['description'])}
              </div>
            )}
        </div>
        <div className="text-center mt-4">
          <button
            type="submit"
            className="btn clsyellow-btn  clsanchor-btn"
            disabled={isReportSubmitting}
          >
            {' '}
            {t(`Report`)}{' '}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReportAnIssue;
