import React, { useState } from 'react';
import Notification from '../../../containers/Notification';
import SelectOption from '../../Common/SelectOption';
import { inviteOthersOptions } from '../../../containers/InviteOthers/constants';
import { handleOptionValue } from '../../../utils/helper';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';
import { useTranslation } from 'react-i18next';
import { INVITE_URL } from '../../../constants';

const Invites = (props) => {
  const {
    inviteFormData,
    inviteOthersChange,
    submitInviteOtherForm,
    inviteFormErrors,
    isInviteSubmitting,
  } = props;

  const url = INVITE_URL;

  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(url);
    setCopySuccess('Copied');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitInviteOtherForm();
  };

  const { t } = useTranslation();

  return (
    <div className="clsinvite-block mb-5">
      <h4>{t(`Invite Others`)}</h4>
      <p>
        {t(
          `Help more people connect and participate in local civic engagement.`,
        )}
      </p>
      <div className="clsinvite-form clswhite-box mt-3">
        <form onSubmit={handleSubmit}>
          <Notification module={`inviteOther`} />
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputEmail4">{t(`Whom are you inviting?`)}</label>
              <SelectOption
                options={inviteOthersOptions}
                name="inviteWhom"
                value={handleOptionValue(
                  inviteOthersOptions,
                  inviteFormData.inviteWhom,
                )}
                handleSelectChange={(e) =>
                  inviteOthersChange('inviteWhom', e.value)
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputPassword4">{t(`Their Email`)}</label>
              <input
                type="text"
                className="form-control"
                name={`email`}
                placeholder={t(`Enter Email`)}
                onChange={(e) =>
                  inviteOthersChange(e.target.name, e.target.value)
                }
                value={inviteFormData.email}
              />
              {typeof inviteFormErrors.email !== 'undefined' &&
                inviteFormErrors.email && (
                  <div className="clsinvalid">{t(inviteFormErrors?.['email'])}</div>
                )}
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn clsyellow-btn"
              disabled={isInviteSubmitting}
            >
              {t(`Send Invite`)}
            </button>
          </div>
        </form>
      </div>

      <p className="clsor-text text-center mt-4 mb-4">
        <span>{t(`OR`)}</span>
      </p>

      <div className="clsinvite-link-blk clswhite-box text-center">
        <label>{t(`Copy link`)}</label>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              <img
                src={require('../../../assets/img/link.svg').default}
                alt="Link"
              />
            </span>
          </div>
          <input type="text" className="form-control" value={url} readOnly />
          <div className="input-group-append">
            <button
              className="btn"
              type="button"
              id="button-addon2"
              onClick={(e) => {
                copyToClipboard();
              }}
            >
              {copySuccess ? t(`Copied`) : t(`Copy`)}
            </button>
          </div>
        </div>
        <label className="mb-3">{t(`Share on Social Media`)}</label>
        <div className="clsinvite-social-media">
          <ul>
            <li>
              <TwitterShareButton
                url={url}
                className="Demo__some-network__share-button dropdown-item"
              >
                <TwitterIcon size={40} />
                <label htmlFor="" className="mt-2">
                  {t(`Twitter`)}
                </label>
              </TwitterShareButton>
            </li>
            <li>
              <FacebookShareButton
                url={url}
                className="Demo__some-network__share-button dropdown-item"
              >
                <FacebookIcon size={40} />
                <label htmlFor="" className="mt-2">
                  {t(`Facebook`)}
                </label>
              </FacebookShareButton>
            </li>
            <li>
              <LinkedinShareButton
                url={url}
                className="Demo__some-network__share-button dropdown-item"
              >
                <LinkedinIcon size={40} />
                <label htmlFor="" className="mt-2">
                  {t(`LinkedIn`)}
                </label>
              </LinkedinShareButton>
            </li>
            <li>
              <EmailShareButton
                url={url}
                className="Demo__some-network__share-button dropdown-item"
              >
                <EmailIcon size={40} />
                <label htmlFor="" className="mt-2">
                  {t(`Email`)}
                </label>
              </EmailShareButton>
            </li>
            <li>
              <a
                href="https://nextdoor.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('../../../assets/img/nextdoor.png').default}
                  alt="Nextdoor"
                  className="nextdoor-width"
                />
              </a>
              <label htmlFor="" className="mt-2">
                {t(`Nextdoor`)}
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Invites;
