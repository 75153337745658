import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import LoadingIndicator from '../../Common/LoadingIndicator';
import moment from 'moment-timezone';
import { getEventDateDetail } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
const MapBlock = (props) => {
  const { event, position } = props;
  const { t } = useTranslation();
  return (
    <MapContainer
      scrollWheelZoom={false}
      id="eventMapId"
      zoom="10"
      center={position}
    >
      <Marker position={position}>
        <Popup>
          <h3>{event.name}</h3>
          <p
            dangerouslySetInnerHTML={{ __html: getEventDateDetail(event) }}
          ></p>
          <p>
            Time :
            {moment
              .utc(`${event.start_date} ${event.start_time}`)
              .tz(moment.tz.guess())
              .format('LT')}
            {event.end_time !== '00:00:00'
              ? ' - ' +
                moment
                  .utc(`${event.start_date} ${event.end_time}`)
                  .tz(moment.tz.guess())
                  .format('LT')
              : ''}
          </p>

          <p>
            {t(`Location`)} :{event.location}
          </p>
        </Popup>
      </Marker>

      <TileLayer
        attribution='CivicBell GIS | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />
    </MapContainer>
  );
};

const EventMap = (props) => {
  const [mapComponent, setMapComponent] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  const renderMap = React.useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setMapComponent(<Component {...props} />);
        return resolve(true);
      });
    },
    [props],
  );

  useEffect(() => {
    if (initialLoad) {
      setTimeout(function () {
        renderMap(MapBlock);
        setInitialLoad(false);
      }, 5000);
    }
  }, [initialLoad, renderMap]);

  return (
    <div id="eventMap" className="clswhite-box clsmap">
      {initialLoad === true ? <LoadingIndicator position={`relative`} /> : ''}
      {mapComponent}
    </div>
  );
};

export default EventMap;
