/*
 *
 * Login constants
 *
 */
import i18next from 'i18next';

export const REGISTER_CHANGE = 'src/Register/REGISTER_CHANGE';
export const REGISTER_RESET = 'src/Register/REGISTER_RESET';
export const SET_REGISTER_LOADING = 'src/Register/SET_REGISTER_LOADING';
export const SET_REGISTER_FORM_ERRORS = 'src/Register/SET_REGISTER_FORM_ERRORS';
export const SET_REGISTER_SUBMITTING = 'src/Register/SET_REGISTER_SUBMITTING';
export const RESET_APP = 'src/Register/RESET_APP';

export const signUpTypeOptions = [
  { value: 'individual', label: 'Resident', translationParsing: i18next.t('Resident') },
  /*{value: 'candidate', label: 'Candidate')},*/
  { value: 'public_official', label: 'Public Official / Agency', translationParsing: i18next.t('Public Official / Agency')  },
  { value: 'elected_official', label: 'Elected Official', translationParsing: i18next.t('Elected Official')  },
];

export const verificationOptions = [
  { value: 'phone', label: 'Phone Number', translationParsing: i18next.t('Phone Number') },
  { value: 'mail', label: 'Mail Letter', translatingParsing: i18next.t('Mail Letter') },
  { value: 'email', label: 'Email', translationParsing: i18next.t('Email') },
];

export const positionOptions = [
  { value: 'vice_mayor', label: 'Vice Mayor', translationParsing: i18next.t('Vice Mayor') },
  { value: 'senator', label: 'Senator', translationParsing: i18next.t('Senator') },
  { value: 'president_of_school', label: 'President Of School Board', translationParsing: i18next.t('President Of School Board') },
];

export const genderOptions = [
  { value: 'male', label: 'Male', translationParsing: i18next.t('Male') },
  { value: 'female', label: 'Female', translationParsing: i18next.t('Female')},
  { value: 'other', label: 'Other', translationParsing: i18next.t('Other') },
];

export const highEduOptions = [
  { value: 'some_high_school', label: 'Some High School', translationParsing: i18next.t('Some High School') },
  { value: 'high_school', label: 'High School', translationParsing: i18next.t('High School') },
  { value: 'college', label: 'College', translationParsing: i18next.t('College') },
  { value: 'graduate', label: 'Graduate', translationParsing: i18next.t('Graduate') },
  { value: 'phd', label: 'PhD', translationParsing: i18next.t('PhD') },
];

export const employmentStatusOptions = [
  { value: 'student', label: 'Student', translationParsing: i18next.t('Student') },
  { value: 'self_employed', label: 'Self Employed', translationParsing: i18next.t('Self Employed') },
  { value: 'employed', label: 'Employed', translationParsing: i18next.t('Employed') },
  { value: 'unemployed', label: 'Unemployed', translationParsing: i18next.t('Unemployed') },
  { value: 'retired', label: 'Retired', translationParsing: i18next.t('Retired') },
];

export const martialOptions = [
  { value: 'single', label: 'Single', translationParsing: i18next.t('Single') },
  { value: 'domestic_partnership', label: 'Domestic Partnership', translationParsing: i18next.t('Domestic Partnership') },
  { value: 'married', label: 'Married', translationParsing: i18next.t('Married') },
  { value: 'divorced', label: 'Divorced', translationParsing: i18next.t('Divorced')},
  { value: 'widowed', label: 'Widowed', translationParsing: i18next.t('Widowed') },
];

export const affiliateOptions = [
  { value: 'conservative', label: 'Conservative', translationParsing: i18next.t('Conservative')},
  { value: 'slightly_conservative', label: 'Slightly conservative', translationParsing: i18next.t('Slightly conservative') },
  { value: 'slightly_liberal', label: 'Slightly liberal', translationParsing: i18next.t('Slightly liberal') },
  { value: 'liberal', label: 'Liberal', translationParsing: i18next.t('Liberal') },
];

export const religionOptions = [
  { value: 'atheism', label: 'Atheism', translationParsing: i18next.t('Atheism') },
  { value: 'buddhism', label: 'Buddhism', translationParsing: i18next.t('Buddhism') },
  { value: 'christianity', label: 'Christianity', translationParsing: i18next.t('Christianity') },
  { value: 'hinduism', label: 'Hinduism', translationParsing: i18next.t('Hinduism') },
  { value: 'islam', label: 'Islam', translationParsing: i18next.t('Islam') },
  { value: 'judaism', label: 'Judaism', translationParsing: i18next.t('Judaism') },
  { value: 'other', label: 'Other', translationParsing: i18next.t('Other') },
  { value: 'none', label: 'None', translationParsing: i18next.t('None') },
];

/*export const raceOptions = [
    {value: 'white', label: 'White')},
    {value: 'asian', label: 'Asian')},
    {value: 'black_or_african_american', label: 'Black or African American')},
    {value: 'american_indian_or_alaska_native', label: 'American Indian or Alaska Native')},
    {value: 'native_hawaiian_or_other_pacific_islander', label: 'Native Hawaiian or Other Pacific Islander')},
    {value: 'non_hispanic', label: 'Non-hispanic')},
    {value: 'hispanic', label: 'Hispanic')}
];*/

export const raceOptions = [
  { value: 'white', label: 'White', translationParsing: i18next.t('White') },
  { value: 'hispanic', label: 'Hispanic', translationParsing: i18next.t('Hispanic') },
  { value: 'black', label: 'Black or African American', translationParsing: i18next.t('Black or African American') },
  { value: 'native', label: 'Native American', translationParsing: i18next.t('Native American') },
  { value: 'asian', label: 'Asian American', translationParsing: i18next.t('Asian American') },
  { value: 'pacific_islander', label: 'Pacific Islander', translationParsing: i18next.t('Pacific Islander') },
  { value: 'mixed', label: 'Two or More Races', translationParsing: i18next.t('Two or More Races') },
  { value: 'other', label: 'Other Race', translationParsing: i18next.t('Other Race') },
];

export const otherConstants = [
  { value: 'Unknown', label: 'Unknown', translationParsing: i18next.t('Unknown') },
  { value: 'Start New Engagement', label: 'Start New Engagement', translationParsing: i18next.t('Start New Engagement') },
  { value: 'New Survey', label: 'New Survey', translationParsing: i18next.t('New Survey') },
  { value: 'New Post', label: 'New Post', translationParsing: i18next.t('New Post') },
  { value: 'New Discussion Topic', label: 'New Discussion Topic', translationParsing: i18next.t('New Discussion Topic')},
  { value: 'Engagement close time should not be prior to engagement start time.', label: 'Engagement close time should not be prior to engagement start time.', translatingParsing: i18next.t('Engagement close time should not be prior to engagement start time.') },
  { value: 'Engagement complete time should not be prior to engagement close time.', label: 'Engagement complete time should not be prior to engagement close time.', translationParsing: i18next.t('Engagement complete time should not be prior to engagement close time.')},
  { value: 'Engagement complete time should not be prior to engagement start time.', label: 'Engagement complete time should not be prior to engagement start time.', translationParsing: i18next.t('Engagement complete time should not be prior to engagement start time.')},
  { value: 'Please select the date and time to schedule the post.', label: 'Please select the dateand time to schedule the post.', translationParsing: i18next.t('Please select the dateand time to schedule the post.')},
  { value: 'Please enter up to 5000 characters', label: 'Please enter up to 5000 characters', translationParsing: i18next.t('Please enter up to 5000 characters') },
  { value: 'Please enter up to 500 characters', label: 'Please enter up to 500 characters', translationParsing: i18next.t('Please enter up to 500 characters')},
  { value: 'Required', label: 'Required', translationParsing: i18next.t('Required') },
  { value: 'Multiple Choice Answer', label: 'Multiple Choice Answer', translationParsing: i18next.t('Multiple Choice Answer')},
  { value: 'At least one topic is required', label: 'At least one topic is required', translationParsing: i18next.t('At least one topic is required')},
  { value: 'Edit Engagement', label: 'Edit Engagement', translationParsing: i18next.t('Edit Engagement')},
  { value: 'Time', label: 'Time', translatingParsing: i18next.t('Time') },
  { value: 'Please provide at least two characters', label: 'Please provide at least two characters', translationParsing: i18next.t('Please provide at least two characters')},
  { value: 'The About Me may not be greater than 3000 characters.', label: 'The About Me may not be greater than 3000 characters.', translatingParsing: i18next.t('The About Me may not be greater than 3000 characters.')},
  { value: 'The About Me may not be greater than 1500 characters.', label: 'The About Me may not be greater than 1500 characters.', translatingParsing: i18next.t('The About Me may not be greater than 1500 characters.')},
  { value: 'The political affiliation may not be greater than 3000 characters.', label: 'The political affiliation may not be greater than 3000 characters.', translatingParsing: i18next.t('The political affiliation may not be greater than 3000 characters.')},
  { value: 'The political background may not be greater than 3000 characters.', label: 'The political background may not be greater than 3000 characters.', translatingParsing: i18next.t('The political background may not be greater than 3000 characters.')},
  { value: 'The sentence may not be greater than 3000 characters.', label: 'The sentence may not be greater than 3000 characters.', translatingParsing: i18next.t('The sentence may not be greater than 3000 characters.')},
  { value: 'Email format is invalid.', label: 'Email format is invalid.', translatingParsing: i18next.t('Email format is invalid.')},
  { value: 'New password should not be same as old password.', label: 'New password should not be same as old password.', translatingParsing: i18next.t('New password should not be same as old password.')},
  { value: 'Confirm password and New password fields must match.', label: 'Confirm password and New password fields must match.', translatingParsing: i18next.t('Confirm password and New password fields must match.')},
  { value: 'Error on uploading files. Please remove it and upload it again.', label: 'Error on uploading files. Please remove it and upload it again.', translatingParsing: i18next.t('Error on uploading files. Please remove it and upload it again.')},
  { value: 'Email is required.', label: 'Email is required.', translatingParsing: i18next.t('Email is required.')},
  { value: 'Email format is invalid.', label: 'Email format is invalid.', translatingParsing: i18next.t('Email format is invalid.')},
  { value: 'Password is required.', label: 'Password is required.', translatingParsing: i18next.t('Password is required.')},
  { value: 'Password must be at least 6 characters.', label: 'Password must be at least 6 characters.', translatingParsing: i18next.t('Password must be at least 6 characters.')},
  { value: 'Click the verification link sent to your registered email', label: 'Click the verification link sent to your registered email', translatingParsing: i18next.t('Click the verification link sent to your registered email')},
  { value: 'Initatives can only last between 1 and 26 weeks', label: 'Initatives can only last between 1 and 26 weeks', translatingParsing: i18next.t('Initatives can only last between 1 and 26 weeks')},
  { value: 'Please select the valid district from the auto suggestion box.', label: 'Please select the valid district from the auto suggestion box.', translatingParsing: i18next.t('Please select the valid district from the auto suggestion box.')},
  { value: 'Please enter the email for manual verification', label: 'Please enter the email for manual verification', translatingParsing: i18next.t('Please enter the email for manual verification')},
  { value: 'Please enter the phone number', label: 'Please enter the phone number', translatingParsing: i18next.t('Please enter the phone number')},
  { value: 'Please select the valid district from the auto suggestion box.', label: 'Please select the valid district from the auto suggestion box.', translatingParsing: i18next.t('Please select the valid district from the auto suggestion box.')},
  { value: 'Please enter the phone number', label: 'Please enter the phone number', translatingParsing: i18next.t('Please enter the phone number')},
  { value: 'Re-type the password', label: 'Re-type the password', translatingParsing: i18next.t('Re-type the password')},
]
