/**
 *
 * Engagement Reactions
 *
 */

import { useTranslation } from 'react-i18next';

const Reactions = (props) => {
  const { engagementReactFormData } = props;
  const { t } = useTranslation();

  return (
    <div className="clsengagement-reactions">
      <ul>
        <li>
          <div className="clsreactions-box">
            <span>
              <img
                src={require('../../../assets/img/approved.svg').default}
                alt="Approved"
              />{' '}
              {t(`Approved`)}
            </span>{' '}
            <span>
              {/*engagementReactFormData.engagement.reactionCount*/}
              {engagementReactFormData.engagement.liked_count}
            </span>
          </div>
        </li>
        <li>
          <div className="clsreactions-box">
            <span>
              <img
                src={require('../../../assets/img/disapproved.svg').default}
                alt="Confused"
              />{' '}
              {t(`Confused`)}
            </span>
            <span>{engagementReactFormData.engagement.disliked_count}</span>
          </div>
        </li>
        {/*<li>
                <div className="clsreactions-box">
                    <span><img src={require('../../../assets/img/confused.svg').default} alt="Confused" /> {t(`Confused`)}</span> <span>0</span>
                </div>
            </li> */}
      </ul>
    </div>
  );
};

export default Reactions;
