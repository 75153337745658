import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import { isJson, isImageLink } from '../../../utils/helper';

const PetitionList = (props) => {
  const { petition, user } = props;

  const hashUrl = '#';
  const history = useHistory();
  const navigateTo = (url) => {
    history.push(url);
  };

  const { t } = useTranslation();

  return (
    <div className="clsdashboard-content-blk">
      <div
        className="tabbable-panel clsdashboard-panel"
        style={{ width: '160%' }}
      >
        <div className="tabbable-line">
          <div className="clspetitions-list-blk">
            <div className="clspetitions-list-box clswhite-box mb-3">
              <div className="media">
                <img
                  src={
                    petition.images.length > 0
                      ? isImageLink(petition.images[0])
                      : require('../../../assets/img/profile-post-img.png')
                          .default
                  }
                  className="align-self-center mr-2"
                  alt=""
                  width={115}
                  height={146}
                  onClick={() => {
                    navigateTo('/dashboard/initiative/?id=' + petition.id);
                  }}
                />
                <div className="media-body">
                  <a
                    href={hashUrl}
                    onClick={() => {
                      navigateTo('/dashboard/initiative/?id=' + petition.id);
                    }}
                  >
                    <div className="mt-0 clstotal-signed mb-2">
                      <span>
                        <img
                          src={
                            require('../../../assets/img/check-yellow.svg')
                              .default
                          }
                          alt="Signed"
                        />
                        {petition.signeesCount} people have signed
                      </span>
                      {petition.eligible_to_vote !== false &&
                      user.roleName === 'RESIDENT' ? (
                        <span className="clseligible-sign">
                          <img
                            src={
                              require('../../../assets/img/blue-check.svg')
                                .default
                            }
                            alt=""
                          />{' '}
                          {t('Eligible to Sign')}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>

                    <h6 className="mb-1">{petition.title}</h6>
                    {/* <p className="description-less">{petition.description}</p> */}
                    <div className={`editor-content`}>
                      {isJson(petition.description) === true ? (
                        <p
                          dangerouslySetInnerHTML={convertFromJSONToHTML(
                            petition.description,
                          )}
                        ></p>
                      ) : (
                        <p style={{ whiteSpace: 'pre-wrap' }}>
                          {petition.description}
                        </p>
                      )}
                    </div>
                    <p className="clspetition-location mt-2">
                      <img
                        src={
                          require('../../../assets/img/location.svg').default
                        }
                        alt="Location"
                      />
                      {petition.division}
                    </p>
                    <div className="clsbadge-tag-list mt-2 mb-2">
                      <ul>
                        {petition.topics.map((topic, index) => (
                          <li key={index}>
                            <span className="badge clslight-grey-badge">
                              {
                                user.selected_language === topic.originalLanguage ?
                                  topic.value
                                :
                                  (JSON.parse(topic.translation)?.[user.selected_language]?.name) ?
                                    JSON.parse(topic.translation)?.[user.selected_language]?.name
                                  :
                                    topic.value
                              }
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </a>
                  {/* {user.userId === petition.userId && activePetitionTab !== 'expired-petitions' ?
                    <span className="edit-petition">
                      <a href={hashUrl} onClick={() => { updatePetitionSingle(petition)}} data-toggle="modal" data-target="#form-petition"><span><img src={require('../../../assets/img/edit.svg').default} alt="Approve" /></span><span>Edit Initiative</span></a>
                    </span>
                    : ""} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PetitionList;
