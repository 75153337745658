/*
 *
 * New Engagement Form
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import EngagementForm from './newEngagementForm';
import CivicModal from '../../Common/CivicModal';
import { useTranslation } from 'react-i18next';

const NewEngagement = (props) => {
  const {
    user,
    editEngagement,
    setEngagementFormData,
    engagementFormData,
    engagementChange,
    submitEngagement,
    engagementErrors,
    isEngagementLoading,
    isEngagementSubmitting,
    setPreviousStep,
    commentEditChange,
    addQuestion,
    removeQuestion,
    addChoice,
    removeChoice,
    questionChange,
    choiceChange,
    getTitle,
    type,
    removeLink,
    getTagsData,
    linkMetaChange,
    commentLexicalChange
  } = props;

  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      if (typeof editEngagement !== 'undefined') {
        if (typeof editEngagement.id !== 'undefined') {
          setEngagementFormData(editEngagement);
          setLoaded(true);
        }
      }
    }
    getTitle();
  }, [
    loaded,
    setEngagementFormData,
    editEngagement,
    engagementFormData.step,
    getTitle,
  ]);

  return (
    <>
      {typeof editEngagement === 'undefined' ||
      (typeof editEngagement !== 'undefined' && loaded === true) ? (
        <>
          {type === 'Modal' ? (
            <CivicModal
              id={
                loaded === false
                  ? `new-engagement`
                  : `edit-engagement-${editEngagement.id}`
              }
              title={t(engagementFormData.title)}
              dismissal={engagementFormData.dismissal}
            >
              <EngagementForm
                user={user}
                engagementFormData={engagementFormData}
                engagementChange={engagementChange}
                submitEngagement={submitEngagement}
                engagementErrors={engagementErrors}
                isEngagementLoading={isEngagementLoading}
                isEngagementSubmitting={isEngagementSubmitting}
                setPreviousStep={setPreviousStep}
                commentEditChange={commentEditChange}
                addQuestion={addQuestion}
                removeQuestion={removeQuestion}
                addChoice={addChoice}
                removeChoice={removeChoice}
                questionChange={questionChange}
                choiceChange={choiceChange}
                removeLink={removeLink}
                linkMetaChange={linkMetaChange}
                getTagsData={getTagsData}
                commentLexicalChange={commentLexicalChange}
              />
            </CivicModal>
          ) : (
            <EngagementForm
              user={user}
              engagementFormData={engagementFormData}
              engagementChange={engagementChange}
              submitEngagement={submitEngagement}
              engagementErrors={engagementErrors}
              isEngagementLoading={isEngagementLoading}
              isEngagementSubmitting={isEngagementSubmitting}
              setPreviousStep={setPreviousStep}
              commentEditChange={commentEditChange}
              addQuestion={addQuestion}
              removeQuestion={removeQuestion}
              addChoice={addChoice}
              removeChoice={removeChoice}
              questionChange={questionChange}
              choiceChange={choiceChange}
              removeLink={removeLink}
              linkMetaChange={linkMetaChange}
              commentLexicalChange={commentLexicalChange}
            />
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    engagementFormData: state.engagement.engagementFormData,
    engagementErrors: state.engagement.formErrors,
    isEngagementLoading: state.engagement.isEngagementLoading,
    isEngagementSubmitting: state.engagement.isEngagementSubmitting,
  };
};

NewEngagement.defaultProps = {
  type: 'Modal',
};

export default connect(mapStateToProps, actions)(NewEngagement);
