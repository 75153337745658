import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import CommunityIssueImageEntry from './CommunityIssueImageEntry';

const CommunityIssuesYouFollow = (props) => {
  const { t } = useTranslation();
  const {
    user,
    allCommunityIssues,
    isCommunityIssuesLoading,
  } = props;

  const filteredCommunityIssues = Object.values(allCommunityIssues).filter((communityIssue) => (communityIssue.isFollowing));

  return (
      <div className={filteredCommunityIssues.length > 0 ? 'my-community-issues-block' : ''}>
        <div className="clsdashboard-upcoming-event clswhite-box my-community-issues">
          <h5
              className="mb-1"
              style={{
                alignItems: 'center'
              }}
          >
            <img
                src={require('../../../assets/img/community-issues-icon-exclamation.svg').default}
                alt="Community Issue Exclamation"
            />
            {t('Community Issues You Follow')}
          </h5>
          {
            filteredCommunityIssues.length > 0 ?
                filteredCommunityIssues.map((communityIssue) => {
                  return (
                      <CommunityIssueImageEntry communityIssueId={communityIssue?.communityIssueId} />
                  )
                })
                :
                <div className='no-started-issues'>
                  {
                    isCommunityIssuesLoading ?
                        <p>
                          {t('Loading...')}
                        </p>
                        :
                        <p>
                          {t('You aren’t following any Community Issues.')}
                        </p>
                  }
                </div>
          }
        </div>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    isCommunityIssuesLoading: state.communityIssues.isCommunityIssuesLoading,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssuesYouFollow);