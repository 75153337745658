/*
 *
 * Map reducer
 *
 */

import {
  CIVICMAP_CHANGE,
  SET_CIVICMAP_LOADING,
  SET_GEO_JSON,
  SET_GEO_JSON_ARRAY,
  SET_MERGE_GEO_JSON,
  FETCH_CIVIC_MAP,
  MAP_RELOAD,
  MAP_RESET,
  OFFICIALS_CHANGE,
  SET_OFFICIALS_LOADING,
  OFFICIALS_RELOAD_LIST,
  OFFICIALS_LIST_CHANGE,
  FETCH_OFFICIALS,
  SET_CURRENT_DISTRICT,
  SET_FILTER_LEVEL,
  RESET_APP,
} from './constants';

const initialState = {
  filterDismissal: false,
  districtBoxDismissal: true,
  filter: {},
  filterSelected: {
    defaultMap: '',
    map: '',
  },
  isCivicMapLoading: false,
  reloadMap: false,
  civicMaps: [],
  geoJsonData: null,
  geoJsonDataArray: [],
  position: [37.3707897, -100.0375932],
  mapCenter: [37.3707897, -100.0375932],
  zoom: 4,
  mapSearch: null,
  officials: {},
  district: null,
  noRecords: false,
  reloadList: false,
  pageLoader: false,
  isOfficialLoading: false,
  districtOfficial: {},
  currentLayer: null,
  currentLoadedMap: '',
  noData: false,
};

const defaultState = JSON.stringify(initialState);

const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case CIVICMAP_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_MERGE_GEO_JSON:
      return {
        ...state,
        geoJsonData: [
          ...state.geoJsonData.features,
          ...action.payload.features,
        ],
      };
    case SET_GEO_JSON:
      return {
        ...state,
        geoJsonData: action.payload,
      };
    case SET_GEO_JSON_ARRAY:
      return {
        ...state,
        geoJsonDataArray: [...state.geoJsonDataArray, ...action.payload],
      };
    case FETCH_CIVIC_MAP:
      return {
        ...state,
        civicMaps: action.payload,
      };
    case MAP_RELOAD:
      return {
        ...state,
        reloadMap: action.payload,
      };
    case MAP_RESET:
      return {
        ...state,
        reloadMap: true,
        geoJsonData: null,
        geoJsonDataArray: [],
      };
    case SET_CIVICMAP_LOADING:
      return {
        ...state,
        isCivicMapLoading: action.payload,
      };
    case FETCH_OFFICIALS:
      return {
        ...state,
        officials: action.payload,
      };
    case SET_CURRENT_DISTRICT:
      return {
        ...state,
        district: action.payload,
      };
    case OFFICIALS_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case OFFICIALS_RELOAD_LIST:
      return {
        ...state,
        officials: {},
        reloadList: true,
        noRecords: false,
        pageLoader: false,
      };
    case OFFICIALS_LIST_CHANGE:
      let officialList = state.officials;
      let list = state.officials;

      if (officialList[action.payload.level].length > 0)
        officialList[action.payload.level].forEach((value, index) => {
          if (value.officialInfoId === action.payload.officialId) {
            list[action.payload.level][index] = {
              ...list[action.payload.level][index],
              ...action.payload.values,
            };
          }
        });
      return {
        ...state,
        officials: list,
      };
    case SET_OFFICIALS_LOADING:
      return {
        ...state,
        isOfficialLoading: action.payload,
      };
    case SET_FILTER_LEVEL:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default MapReducer;
