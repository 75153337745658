/**
 *
 * Profile Background Image Modal
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicModal from '../../components/Common/CivicModal';
import ProfileBgImageForm from '../../components/Manager/Profile/profileBgImageForm';
import { useTranslation } from 'react-i18next';

const ProfileBgImage = (props) => {
  const { user, setProfileBgImgData, profileBgImgData } = props;
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);

  const loadProfile = useCallback(async () => {
    if (typeof user.roleName !== 'undefined') {
      await setProfileBgImgData(user).then(() => {
        setLoaded(true);
      });
    }
  }, [setProfileBgImgData, setLoaded, user]);

  useEffect(() => {
    if (loaded === false && typeof user.roleName !== 'undefined') {
      loadProfile();
    }
  }, [loaded, loadProfile, user.roleName]);

  return (
    <CivicModal
      id={`profile-bg-image-modal-` + user.userId}
      title={t(profileBgImgData.title)}
      dismissal={profileBgImgData.dismissal}
      modalDialogueClass={`modalMinWidth`}
    >
      <ProfileBgImageForm {...props} />
    </CivicModal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    profileBgImgData: state.account.profileBgImgData,
    profileBgImgError: state.account.profileBgImgError,
    isProfileLoading: state.account.isProfileLoading,
  };
};

export default connect(mapStateToProps, actions)(ProfileBgImage);
