import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import CommunityIssueChildComment from './CommunityIssueChildComment';
import CommunityIssueCommentEmotions from './CommunityIssueCommentEmotions';
import { useLocation } from 'react-router-dom';

const CommunityIssueChildCommentsContainer = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    commentId,
    allCommunityIssues,
    allCommunityIssueComments,
    isCommunityIssuesLoading,
    authenticated,
    user,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];
  const comment = allCommunityIssueComments[commentId];

  const [isViewChildCommentsToggled, setIsViewChildCommentsToggled] = useState(false);

  const [childCommentIds, setChildCommentIds] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setChildCommentIds(
      Object.values(allCommunityIssueComments)
        .filter(allComment => (allComment?.parentCommentId) === comment.commentId)
        .sort((a, b) => a.commentId - b.commentId)
        .map(allComment => allComment.commentId)
    )
  }, [allCommunityIssues, allCommunityIssueComments, comment, location.search])

  if (!comment || !childCommentIds || childCommentIds?.length === 0 || !authenticated) {
    return (
      <>
      </>
    )
  }
  else return (
    <>
      <div
        id={`comment-show-more-${comment.commentId}`}
        className="collapse clsreplied-content-blk clsrequired-conten"
      >
        <div className="clsreplied-content-blk clsrequired-conten clspost-box">
          {
            childCommentIds && childCommentIds.map((childCommentId, idx) => { 
              const childComment = allCommunityIssueComments?.[childCommentId];
              return (
                <div className="media mt-3" key={childCommentId}>
                  <NavLink
                    to={'/dashboard/user-profile/?id=' + childComment?.userId}
                    activeClassName="active"
                    exact
                  >
                    <img
                      src={
                        childComment?.user?.profile_picture ?? require('../../../assets/img/profile-image.jpeg').default
                      }
                      className="align-self-center mr-3 engagement-profile-img"
                      alt={childComment?.commentOwner}
                    />
                  </NavLink>
                  <div className="media-body">
                    <CommunityIssueChildComment
                      communityIssueId={communityIssueId}
                      childComment={childComment}
                    />
                    <CommunityIssueCommentEmotions
                      communityIssueId={communityIssueId}
                      commentId={childComment?.commentId}
                      showCommentBox={false}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <p className="mt-3" id={`show-hide-comment-${comment.commentId}`}>
        <a
          href={`#comment-show-more-${comment.commentId}`}
          data-target={`#comment-show-more-${comment.commentId}`}
          data-toggle="collapse"
          className="nav-toggle clsreply-link"
          id={`show-hide-link-${comment.commentId}`}
          onClick={() => {
            setIsViewChildCommentsToggled(!isViewChildCommentsToggled);
          }}
        >
          {
            !isViewChildCommentsToggled ?
              t('Show') + ` ${childCommentIds?.length} ` + t('replies') 
            :
              t('Hide replies')
          }
        </a>
      </p>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueComments: state.communityIssues.allCommunityIssueComments,
    isCommunityIssuesLoading: state.communityIssues.isCommunityIssuesLoading,
    authenticated: state.authentication.authenticated,
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueChildCommentsContainer);