/*
 *
 * UserNotificationPreferences Reducer
 *
 */
import {
  RESET_APP,
  GET_USER_NOTIFICATION_PREFERENCES,
  UPDATE_USER_NOTIFICATION_PREFERENCES, 
} from './constants';

const initialState = {
  all: {},
}

const defaultState = JSON.stringify(initialState);

const UserNotificationPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case GET_USER_NOTIFICATION_PREFERENCES:
      const all = {};
      action.payload.forEach((preference) => {
        all[`${preference.receiver}, ${preference.resourceType}`] = preference.setting;
      });

      return {
        all,
      }
    case UPDATE_USER_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        all: {
          ...state.all,
          [`${action.payload.receiver}, ${action.payload.resourceType}`]: action.payload.setting,
        },
      };
    default: 
      return state;
  }
}

export default UserNotificationPreferencesReducer;