/*
 *
 * CommunityIssues Reducer
 *
 */
import {
  RESET_APP,
  RESET_COMMUNITY_ISSUE_FEED,
  GET_COMMUNITY_ISSUES,
  GET_MY_COMMUNITY_ISSUES,
  GET_INDIVIDUAL_COMMUNITY_ISSUE,
  CREATE_COMMUNITY_ISSUE,
  UPDATE_COMMUNITY_ISSUE,
  CLOSE_COMMUNITY_ISSUE,
  SIGN_OR_UNSIGN_COMMUNITY_ISSUE,
  SET_FOLLOWING_COMMUNITY_ISSUE,
  GET_TOPICS,
  SET_COMMUNITY_ISSUES_LOADING,
  SET_TOPICS_LOADING,
  SET_COMMUNITY_ISSUE_LIST_PAGE,
  SET_COMMUNITY_ISSUE_LIST_LIMIT,
  SET_COMMUNITY_ISSUE_LIST_FILTER,
  SET_COMMUNITY_ISSUE_LIST_ISSUE_CATEGORY,
  SET_COMMUNITY_ISSUE_SORT_ORDER,
  SET_COMMUNITY_ISSUE_LIST_AREA_FILTER,
  DELETE_COMMUNITY_ISSUE,
  CREATE_COMMUNITY_ISSUE_COMMENT,
  SET_SIGNEES_LOADING,
  GET_COMMUNITY_ISSUE_SIGNEES,
  REACT_TO_COMMUNITY_ISSUE_COMMENT,
  HIDE_COMMUNITY_ISSUE,
  SET_MY_COMMUNITY_ISSUES_LOADING,
} from './constants';

const initialState = {
  isCommunityIssuesLoading: false,
  isMyCommunityIssuesLoading: false,
  isTopicsLoading: false,
  allCommunityIssues: {}, // (communityIssueId -> communityIssue)
  currentCommunityIssuesFeedIds: [],
  myCommunityIssuesFeedIds: [],
  allTopics: {},
  listPage: 1,
  listLimit: null,
  listFilterType: null,
  listIssueCategory: null,
  allCommunityIssueComments: {}, // (commentId -> comment)
  isSigneesLoading: false,
  allCommunityIssueSignees: {}, // (communityIssueId -> [signees])
  sortOrder: 'Newest',
  areaFilter: 'All Issues',
}

const defaultState = JSON.stringify(initialState);

const CommunityIssuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP: {
      return JSON.parse(defaultState);
    }
    case RESET_COMMUNITY_ISSUE_FEED: {
      return {
        ...state,
        listPage: 1,
        currentCommunityIssuesFeedIds: [],
      }
    }
    case GET_COMMUNITY_ISSUES: {
      const getCommunityIssuesAll = {
        ...state.allCommunityIssues,
      };
      const getCommunityIssueCommentsAll = {
        ...state.allCommunityIssueComments,
      }
      const newCommunityIssueIds = [];
      action.payload.forEach((communityIssue) => {
        getCommunityIssuesAll[communityIssue.communityIssueId] = communityIssue;
        if (communityIssue.comments) {
          communityIssue.comments.forEach((comment) => {
            getCommunityIssueCommentsAll[comment.commentId] = comment;
            if (comment?.comments) {
              comment?.comments.forEach((childComment) => {
                getCommunityIssueCommentsAll[childComment.commentId] = {
                  ...childComment,
                  comments: [],
                }
              })
            }
          });
        }
        if (communityIssue.latestComment) {
          getCommunityIssueCommentsAll[communityIssue.latestComment.commentId] = communityIssue.latestComment;
        }
        newCommunityIssueIds.push(communityIssue.communityIssueId);
      });

      return {
        ...state,
        allCommunityIssues: getCommunityIssuesAll,
        allCommunityIssueComments: getCommunityIssueCommentsAll,
        currentCommunityIssuesFeedIds: [...state.currentCommunityIssuesFeedIds, ...newCommunityIssueIds]
      }
    }
    case GET_MY_COMMUNITY_ISSUES: {
      const getCommunityIssuesAll = {
        ...state.allCommunityIssues,
      };
      const getCommunityIssueCommentsAll = {
        ...state.allCommunityIssueComments,
      }
      const newCommunityIssueIds = [];
      action.payload.forEach((communityIssue) => {
        getCommunityIssuesAll[communityIssue.communityIssueId] = communityIssue;
        if (communityIssue.comments) {
          communityIssue.comments.forEach((comment) => {
            getCommunityIssueCommentsAll[comment.commentId] = comment;
            if (comment?.comments) {
              comment?.comments.forEach((childComment) => {
                getCommunityIssueCommentsAll[childComment.commentId] = {
                  ...childComment,
                  comments: [],
                }
              })
            }
          });
        }
        if (communityIssue.latestComment) {
          getCommunityIssueCommentsAll[communityIssue.latestComment.commentId] = communityIssue.latestComment;
        }
        newCommunityIssueIds.push(communityIssue.communityIssueId);
      });

      return {
        ...state,
        allCommunityIssues: getCommunityIssuesAll,
        allCommunityIssueComments: getCommunityIssueCommentsAll,
        myCommunityIssuesFeedIds: [...state.myCommunityIssuesFeedIds, ...newCommunityIssueIds],
      }
    }

    case GET_INDIVIDUAL_COMMUNITY_ISSUE: {
      const getCommunityIssuesAll = {
        ...state.allCommunityIssues,
      };
      const getCommunityIssueCommentsAll = {
        ...state.allCommunityIssueComments,
      }

      getCommunityIssuesAll[action.payload.communityIssueId] = action.payload;
      if (action.payload.comments) {
        action.payload.comments.forEach((comment) => {
          getCommunityIssueCommentsAll[comment.commentId] = comment;
          if (comment?.comments) {
            comment?.comments.forEach((childComment) => {
              getCommunityIssueCommentsAll[childComment.commentId] = {
                ...childComment,
                comments: [],
              }
            })
          }
        });
      }

      return {
        ...state,
        allCommunityIssues: getCommunityIssuesAll,
        allCommunityIssueComments: getCommunityIssueCommentsAll,
      }
    }
    case CREATE_COMMUNITY_ISSUE: {
      return {
        ...state,
        allCommunityIssues: {
          ...state.allCommunityIssues,
          [action.payload.communityIssueId]: action.payload,
        },
        myCommunityIssuesFeedIds: [...state.myCommunityIssuesFeedIds, action.payload.communityIssueId],
      }
    }
    case UPDATE_COMMUNITY_ISSUE: {
      return {
        ...state,
        allCommunityIssues: {
          ...state.allCommunityIssues,
          [action.payload.communityIssueId]: {
            ...state.allCommunityIssues[action.payload.communityIssueId],
            ...action.payload,
          }
        }
      }
    }
    case CLOSE_COMMUNITY_ISSUE: {
      return {
        ...state,
        allCommunityIssues: {
          ...state.allCommunityIssues,
          [action.payload.communityIssueId]: {
            ...state.allCommunityIssues[action.payload.communityIssueId],
            ...action.payload,
            comments: state.allCommunityIssues[action.payload.communityIssueId].comments, // For some reason payload overwrites comments
            latestComment: state.allCommunityIssues[action.payload.communityIssueId].latestComment, // For some reason payload overwrites comments
          }
        }
      }
    }
    case HIDE_COMMUNITY_ISSUE: {
      return {
        ...state,
        myCommunityIssuesFeedIds: state.myCommunityIssuesFeedIds.filter((communityIssueId) => action.payload != communityIssueId)
      }
    }
    case DELETE_COMMUNITY_ISSUE: {
      let newAllCommunityIssues = { ...state.allCommunityIssues };
      delete newAllCommunityIssues[action.payload];

      return {
        ...state,
        allCommunityIssues: newAllCommunityIssues,
        currentCommunityIssuesFeedIds: state.currentCommunityIssuesFeedIds.filter((communityIssueId) => action.payload !== communityIssueId),
        myCommunityIssuesFeedIds: state.myCommunityIssuesFeedIds.filter((communityIssueId) => action.payload != communityIssueId)
      }
    }
    case SIGN_OR_UNSIGN_COMMUNITY_ISSUE: {
      return {
        ...state,
        allCommunityIssues: {
          ...state.allCommunityIssues,
          [action.payload.communityIssueId]: {
            ...state.allCommunityIssues[action.payload.communityIssueId],
            hasUserSigned: action.payload.signed,
            numSignees: (action.payload.signed) ? 
              state.allCommunityIssues[action.payload.communityIssueId].numSignees + 1 
            : 
              state.allCommunityIssues[action.payload.communityIssueId].numSignees - 1
          },
        }
      }
    }
    case SET_FOLLOWING_COMMUNITY_ISSUE: {
      return {
        ...state,
        allCommunityIssues: {
          ...state.allCommunityIssues,
          [action.payload.communityIssueId]: {
            ...state.allCommunityIssues[action.payload.communityIssueId],
            ...action.payload,
          }
        }
      }
    }
    case GET_TOPICS: {
      const getTopicsAll = {};
      action.payload.forEach((topic) => {
        getTopicsAll[topic.topicId] = topic;
      });

      return {
        ...state,
        allTopics: getTopicsAll,
      }
    }
    case SET_COMMUNITY_ISSUES_LOADING: {
      return {
        ...state,
        isCommunityIssuesLoading: action.payload,
      }
    }
    case SET_MY_COMMUNITY_ISSUES_LOADING: {
      return {
        ...state,
        isMyCommunityIssuesLoading: action.payload,
      }
    }
    case SET_TOPICS_LOADING: {
      return {
        ...state,
        isTopicsLoading: action.payload,
      }
    }
    case SET_COMMUNITY_ISSUE_LIST_PAGE: {
      return {
        ...state,
        listPage: action.payload,
      }
    }
    case SET_COMMUNITY_ISSUE_LIST_LIMIT: {
      return {
        ...state,
        listLimit: action.payload,
      }
    }
    case SET_COMMUNITY_ISSUE_LIST_FILTER: {
      return {
        ...state,
        listFilterType: action.payload,
      }
    }
    case SET_COMMUNITY_ISSUE_LIST_ISSUE_CATEGORY: {
      return {
        ...state,
        listIssueCategory: action.payload,
      }
    }
    case SET_COMMUNITY_ISSUE_SORT_ORDER: {
      return {
        ...state,
        sortOrder: action.payload,
      }
    }
    case SET_COMMUNITY_ISSUE_LIST_AREA_FILTER: {
      return {
        ...state,
        listFilterType: action.payload,
        areaFilter: action.payload,
      }
    }
    case SET_SIGNEES_LOADING: {
      return {
        ...state,
        isSigneesLoading: action.payload,
      }
    }
    case CREATE_COMMUNITY_ISSUE_COMMENT: {
      return {
        ...state,
        allCommunityIssues: {
          ...state.allCommunityIssues,
          [action.payload.communityIssueId]: {
            ...state.allCommunityIssues[action.payload.communityIssueId],
            comments: [
              ...state.allCommunityIssues[action.payload.communityIssueId].comments,
              action.payload,
            ],
            latestComment: !(action.payload.parentCommentId) ? action.payload : state.allCommunityIssues[action.payload.communityIssueId].latestComment,
          }
        },
        allCommunityIssueComments: {
          ...state.allCommunityIssueComments,
          [action.payload.commentId]: action.payload,
        }
      }
    }
    case GET_COMMUNITY_ISSUE_SIGNEES: {
      return {
        ...state,
        allCommunityIssueSignees: {
          ...state.allCommunityIssueSignees,
          [action.payload.communityIssueId]: action.payload.data,
        }
      }
    }
    case REACT_TO_COMMUNITY_ISSUE_COMMENT: {
      return {
        ...state,
        allCommunityIssues: {
          ...state.allCommunityIssues,
          [action.payload.communityIssueId]: {
            ...state.allCommunityIssues[action.payload.communityIssueId],
            comments: state.allCommunityIssues[action.payload.communityIssueId].comments.map((comment, index) => {
              if (comment.commentId === action.payload.commentId) {
                return {
                  ...comment,
                  ...action.payload,
                }
              }
              return comment;
            }),
            latestComment: !(state.allCommunityIssueComments[action.payload.commentId].parentCommentId) 
              ? 
                {
                  ...state.allCommunityIssues[action.payload.communityIssueId].latestComment,
                  ...action.payload,
                }
              : 
                state.allCommunityIssues[action.payload.communityIssueId].latestComment,
          }
        },
        allCommunityIssueComments: {
          ...state.allCommunityIssueComments,
          [action.payload.commentId]: {
            ...state.allCommunityIssueComments[action.payload.commentId],
            ...action.payload
          },
        }
      }
    }
    default: {
      return state;
    }
  }
}

export default CommunityIssuesReducer;