/*
 *
 * Petition View
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import IndividualPetition from '../../components/Manager/Petitions/individualPetition';
import NoPetition from '../../components/Manager/Petitions/noPetition';
import NoAuthPetition from '../../components/Manager/Petitions/noAuthPetition';

const View = (props) => {
  const {
    location,
    setPetitionData,
    user,
    petitionSingle,
    withoutAuthenticatePetitionData,
  } = props;

  const [loaded, setLoaded] = useState(false);

  const Id = new URLSearchParams(location.search).get('id');

  const loadPetition = useCallback(async () => {
    if (typeof user.roleName !== 'undefined') {
      if (Id === null) {
        setLoaded(true);
      } else {
        await setPetitionData(Id).then(() => {
          setLoaded(true);
        });
      }
    } else {
      if (Id === null) {
        setLoaded(true);
      } else {
        await withoutAuthenticatePetitionData(Id).then(() => {
          setLoaded(true);
        });
      }
    }
  }, [setPetitionData, setLoaded, user, withoutAuthenticatePetitionData, Id]);

  useEffect(() => {
    if (loaded === false) {
      loadPetition();
    }
  }, [loaded, loadPetition, user.roleName]);

  return (
    <>
      {loaded === false ? <LoadingIndicator position={`fixed`} /> : ''}
      {Id !== undefined &&
      loaded === true &&
      typeof user.roleName !== 'undefined' &&
      typeof petitionSingle !== 'undefined' ? (
        <div>
          {<IndividualPetition {...props} petitionSingle={petitionSingle} />}
        </div>
      ) : Id !== undefined &&
        loaded === true &&
        typeof user.roleName === 'undefined' &&
        typeof petitionSingle !== 'undefined' ? (
        <div>
          {<NoAuthPetition {...props} petitionSingle={petitionSingle} />}
        </div>
      ) : (
        <NoPetition />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    petitionSingle: state.petition.petitionSingle,
    filterDismissal: state.petition.filterDismissal,
  };
};

export default connect(mapStateToProps, actions)(View);
