/*
 *
 * Invite Others
 *
 */

import React from 'react';
import Invite from '../../components/Manager/InviteOthers';
import { connect } from 'react-redux';
import actions from '../../actions';

const InviteOthers = (props) => {
  return (
    <>
      <Invite {...props} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isInviteSubmitting: state.inviteOther.isInviteSubmitting,
    inviteFormData: state.inviteOther.inviteFormData,
    inviteFormErrors: state.inviteOther.inviteFormErrors,
  };
};

export default connect(mapStateToProps, actions)(InviteOthers);
