/**
 *
 * Password Reset Container
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicModal from '../../components/Common/CivicModal';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import ResetPassword from '../../components/Manager/Profile/resetPassword';
import { useTranslation } from 'react-i18next';

const PasswordReset = (props) => {
  const { user, passwordData, setPasswordFormData } = props;
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  const loadPasswordFormData = useCallback(async () => {
    if (typeof user.roleName !== 'undefined') {
      await setPasswordFormData().then(() => {
        setLoaded(true);
      });
    }
  }, [setPasswordFormData, setLoaded, user]);

  useEffect(() => {
    if (loaded === false && typeof user.roleName !== 'undefined') {
      loadPasswordFormData();
    }
  }, [loaded, loadPasswordFormData, user.roleName]);

  return (
    <CivicModal
      id={`reset-password-form`}
      title={t(`RESET PASSWORD`)}
      dismissal={passwordData.dismissal}
      modalDialogueClass={`clswidth-480`}
      modalBodyClass={`clswidth-365 pt-1`}
    >
      {loaded === true ? (
        <ResetPassword {...props} />
      ) : (
        <LoadingIndicator position={`fixed`} />
      )}
    </CivicModal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    passwordData: state.account.passwordData,
    passwordFormError: state.account.passwordFormError,
    isProfileLoading: state.account.isProfileLoading,
  };
};

export default connect(mapStateToProps, actions)(PasswordReset);
