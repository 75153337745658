/**
 *
 * AuthSuccess
 *
 */

import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import actions from '../../actions';
import setToken from '../../utils/token';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

const AuthSuccess = (props) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);
  }, []);
  const { authenticated } = props;
  return authenticated ? <Redirect to="/dashboard" /> : <LoadingIndicator />;
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
  };
};

export default connect(mapStateToProps, actions)(AuthSuccess);
