import { createHeadlessEditor } from "@lexical/headless";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { ListItemNode, ListNode } from '@lexical/list';
import { LinkNode } from '@lexical/link';
import { $getRoot, $getSelection } from 'lexical';

const editor = createHeadlessEditor({
  nodes: [LinkNode, ListItemNode, ListNode],
  onError: (e) => { console.log(e); },
  theme: {
    link: 'lexical-link',
    list: {
      listitem: 'lexical-listitem',
    },
  }
});

const getHtmlFromEditor = (editorState) => new Promise((resolve) => {
  editor.setEditorState(editorState);

  editor.update(() => {
    const html = $generateHtmlFromNodes(editor, null);
    resolve(html);
  });
});

export const convertLexicalEditorStateToHtml = async (state) => {
  const htmlString = await getHtmlFromEditor(state);
  return htmlString;
}

export const loadLexicalEditorFromHtml = (html, lexicalEditor) => new Promise((resolve) => {
  lexicalEditor.update(() => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(html, 'text/html');

    // https://github.com/facebook/lexical/issues/3677#issuecomment-1545968041
    const elementsWithBr = dom.querySelectorAll('*:not(br) > br');
    for (let i = 0; i < elementsWithBr.length; i += 1) {
      const brElement = elementsWithBr[i];
      const parentElement = brElement.parentNode;
      const lastBr = parentElement?.querySelectorAll('br:last-child')[0];
      if (lastBr === brElement) {
        parentElement?.removeChild(brElement);
      }
    }
  
    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(lexicalEditor, dom);

    $getRoot().select();
    
    // Insert them at a selection.
    const selection = $getSelection();
    selection.insertNodes(nodes);

    resolve();
  });
});