/*
 *
 * Engagement
 *
 */

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import EngagementList from './engagementList';
import { useTranslation } from 'react-i18next';
import TabTitle from '../../components/Common/CivicTabs/Title';
import { capitalizeFirstLetter } from '../../utils/helper';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

const Engagement = (props) => {
  const {
    activeTab,
    toggleTab,
    resetEngagementData,
    resetResult,
    searchSubmission,
    searchResultReset,
    reloadList,
    setReloadEngagementStatus,
    getEngagements,
  } = props;
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(true);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [reloadStatus, setReloadStatus] = useState(false);

  const loadEngagement = useCallback(async () => {
    await new Promise((resolve, reject) => {
      resolve(true);
    });
    await new Promise((resolve, reject) => {
      toggleTab(activeTab);
      resolve(true);
    });
  }, [toggleTab, activeTab]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await loadEngagement();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, loadEngagement],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    if (reloadList === true && reloadStatus === false) {
      new Promise(async (resolve, reject) => {
        setReloadStatus(true);
        await loadEngagement();
        await setReloadEngagementStatus(false);
        resolve(true);
      }).then(() => {
        setReloadStatus(false);
      });
    }
  }, [reloadList, loadEngagement, setReloadEngagementStatus, reloadStatus]);

  const tabs = ['unpublished', 'open', 'closed', 'outcome'];

  return (
    <>
      <div 
        className={`tabbable-panel`}
        style={{
          marginBottom: 20,
        }}
      >
        <div className={`tabbable-line`}>
          <ul className="nav nav-tabs" role="tablist">
            {tabs.map((tab) => (
              <TabTitle
                tabTitle={t(capitalizeFirstLetter(tab))}
                linkId={tab}
                isActive={(activeTab === tab)}
                onClick={async () => {
                  toggleTab(tab);
                  setLoaded(false);
                  await getEngagements(tab);
                  setLoaded(true);
                }}
                key={tab}
              />
            ))}
          </ul>
        </div>
      </div>
      {
        loaded ? 
          <EngagementList 
            {...props} 
            activeTab={activeTab} 
            status={activeTab}  
          />
        :
          <LoadingIndicator position={'relative'} />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    activeTab: state.engagement.activeTab,
    isEngagementLoading: state.engagement.isEngagementLoading,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    reloadList: state.engagement.reloadList,
  };
};

export default connect(mapStateToProps, actions)(Engagement);
