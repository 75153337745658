import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';

const SignatoriesModal = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    allCommunityIssues,
    allCommunityIssueSignees,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];
  const communityIssueSignees = allCommunityIssueSignees[communityIssueId];

  return (
    <div className="signatories-modal">
      {
        communityIssueSignees && communityIssueSignees?.map((signature) => {
          return (
            <div className='signature-container'>
              <div className='left'>
                <img
                  class='profile-avatar'
                  src={require('../../../assets/img/profile-image.jpeg').default}
                  alt="User Icon"
                />
                <p>
                  {`${signature?.user?.displayName}`}
                </p>
              </div>
              <div className='right'>
                <div>
                  <p 
                    style={{
                      color: '#566677'
                    }}
                  >
                    {`${signature?.displayPlace}`}
                  </p>
                </div>
                <div>
                  {new Date(signature?.createdAt).toLocaleDateString('es-pa')}
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueSignees: state.communityIssues.allCommunityIssueSignees,
  };
};

export default connect(mapStateToProps, actions)(SignatoriesModal);