import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { profileImg } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import { isJson } from '../../../utils/helper';

const NoAuthPetition = (props) => {
  const { petitionSingle, user } = props;

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="clsdashboard-main-container">
      <div className="container">
        <div className="clsdashboard-right-content-blk clsdashboard-right-content-mid-blk">
          <div className="clsdashboard-right-common-blk">
            <div className="alert alert-primary" role="alert">
              <Link
                to={{
                  pathname: `/login`,
                  state: {
                    prevPath:
                      history.location.pathname + history.location.search,
                  },
                }}
              >
                {t(
                  'Sign-up now to see if you’re eligible to join this Initiative',
                )}
              </Link>
            </div>
            <div className="clsprofile-details-blk clspetitions-details-blk clswhite-box">
              <figure className="clsprofile-post-img">
                <img
                  width={810}
                  height={400}
                  src={
                    petitionSingle.images.length > 0
                      ? petitionSingle.images
                      : require('../../../assets/img/profile-post-img.png')
                          .default
                  }
                  alt="profile"
                />
              </figure>
              <div className="clsprofile-details-box mt-2">
                <div className="clsprofile-details">
                  <div className="clsprofile-details-left">
                    <h4 className="mb-3">{petitionSingle.name}</h4>
                    <div className="media">
                      <img
                        src={profileImg(petitionSingle.user.profile_picture)}
                        className=" header-profile-img"
                        alt="Profile"
                      />
                      <div className="media-body align-self-center mb-1 ml-1">
                        <h5 className="mt-0">
                          {petitionSingle.user.first_name}
                        </h5>
                        <p>
                          {t('Started this petition on')}{' '}
                          {moment(petitionSingle.createdAt).format(
                            'DD MMM YYYY',
                          )}{' '}
                          {t('For')} {petitionSingle.division}
                        </p>
                        <p className="clspetition-location">
                          <img
                            src={
                              require('../../../assets/img/location.svg')
                                .default
                            }
                            alt="Location"
                          />{' '}
                          {petitionSingle.division}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="clsdescription-blk clswhite-box mt-3">
                  <div className="clsbadge-tag-list mt-2 mb-4">
                    <ul>
                      {petitionSingle.topics.map((topic, index) => (
                        <li key={index}>
                          <span className="badge clslight-grey-badge">
                            {
                              user.selected_language === topic.originalLanguage ?
                                topic.value
                              :
                                (JSON.parse(topic.translation)?.[user.selected_language]?.name) ?
                                  JSON.parse(topic.translation)?.[user.selected_language]?.name
                                :
                                  topic.value
                            }
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <h5 className="mb-2 pb-1">{t('Description')}</h5>
                  <div className={`editor-content`}>
                    {isJson(petitionSingle.description) === true ? (
                      <p
                        dangerouslySetInnerHTML={convertFromJSONToHTML(
                          petitionSingle.description,
                        )}
                      ></p>
                    ) : (
                      <p style={{ whiteSpace: 'pre-wrap' }}>
                        {petitionSingle.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="clspetition-signin-box clswhite-box mt-3">
                  <p>
                    <img
                      src={
                        petitionSingle.signed === true
                          ? require('../../../assets/img/check-yellow.svg')
                              .default
                          : ''
                      }
                      alt=""
                    />
                    {petitionSingle.signeesCount} {t('have signed')}
                  </p>
                  <div className="mt-2">
                    <Link
                      to={{
                        pathname: `/login`,
                        state: {
                          prevPath:
                            history.location.pathname + history.location.search,
                        },
                      }}
                      className="btn btn-block clsyellow-btn"
                    >
                      {t('Sign-up to check for join initative')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAuthPetition;
