/*
 *
 * CivicFeed
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicFeed from '../../components/Manager/CivicFeed';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';

const EngagementList = (props) => {
  const {
    getOfficialCivicEngagements,
    engagements,
    noRecords,
    reloadList,
    resetResult,
    searchSubmission,
    reloadCivicEngagement,
    searchResultReset,
    civicEngagementChangeByOption,
    isEngagementLoading,
    user,
    setViewedStatus,
    officialId,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [engagementListComponent, setEngagementListComponent] = useState('');

  const [page, setPage] = useState(1);

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setEngagementListComponent(
          <Component
            {...props}
            civicEngagementChangeByOption={civicEngagementChangeByOption}
            setViewedStatus={setViewedStatus}
          />,
        );
        return resolve(true);
      });
    },
    [
      setEngagementListComponent,
      props,
      civicEngagementChangeByOption,
      setViewedStatus,
    ],
  );

  const fetchEngagements = useCallback(async () => {
    setInProcess(true);
    await getOfficialCivicEngagements(page, officialId).then(async () => {
      await renderComponent(CivicFeed).then(() => {
        setPage(page + 1);
      });
    });
    setInProcess(false);
  }, [getOfficialCivicEngagements, renderComponent, setPage, page, officialId]);

  useEffect(() => {
    if (loading === true) {
      setEngagementListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        const fetchEngagements = async () => {
          await getOfficialCivicEngagements(1, officialId).then(() => {
            setLoading(false);
            setInProcess(false);
          });
        };
        fetchEngagements();
      }
    }
  }, [
    loading,
    setLoading,
    getOfficialCivicEngagements,
    renderComponent,
    page,
    officialId,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    engagements,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(CivicFeed);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setPage, setLoading, setInitialLoad]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await reloadCivicEngagement();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, reloadCivicEngagement],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    renderComponent(CivicFeed);
  }, [user, renderComponent]);

  return (
    <div className="mt-3">
      {isEngagementLoading === true ? (
        <LoadingIndicator position={`fixed`} />
      ) : (
        ''
      )}
      {engagementListComponent}
      {page === 2 && !noRecords ? (
        <div style={{ minHeight: '200px' }}></div>
      ) : (
        ''
      )}
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={engagements.length}
            next={fetchEngagements}
            hasMore={!noRecords}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    engagements: state.civicFeed.engagements,
    noRecords: state.civicFeed.noRecords,
    reloadList: state.civicFeed.reloadList,
    pageLoader: state.civicFeed.pageLoader,
    sort: state.civicFeed.sort,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    filterDismissal: state.civicFeed.filterDismissal,
    filter: state.civicFeed.filter,
    filterSelected: state.civicFeed.filterSelected,
    searchBox: state.civicFeed.searchBox,
    suggestionBox: state.civicFeed.suggestionBox,
    isEngagementLoading: state.civicFeed.isEngagementLoading,
  };
};

export default connect(mapStateToProps, actions)(EngagementList);
