/* eslint-disable no-useless-constructor */
import { $createLineBreakNode, ParagraphNode } from 'lexical';

export class ExtendedParagraphNode extends ParagraphNode {
  static getType() {
    return 'extended-paragraph';
  }

  static clone(node) {
    return new ExtendedParagraphNode(node.__key);
  }

  static importDOM() {
    const importers = ParagraphNode.importDOM();

    return {
      ...importers,
      p: () => ({
        conversion: patchStyleConversion(importers?.p),
        priority: 1
      }),
    }
  }

  static importJSON(serializedNode) {
    return ParagraphNode.importJSON(serializedNode);
  }
}

function patchStyleConversion(
  originalDOMConverter
) {
  return (node) => {
    const original = originalDOMConverter?.(node);
    if (!original) {    
      return null;
    }
    const originalOutput = original.conversion(node);

    const isMarginTop = !!node.style.marginTop;
    const isMarginBottom = !!node.style.marginBottom;

    return {
      ...originalOutput,
      // node: $createLineBreakNode(),
      after: (childLexicalNodes) => {
        if (isMarginTop) {
          childLexicalNodes.unshift($createLineBreakNode());
        } else if (isMarginBottom) {
          childLexicalNodes.push($createLineBreakNode());
        }
        return [...childLexicalNodes];
      }
    };
  }
}