/*
 *
 * Close Engagement Form
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CloseEngagementForm from '../../components/Manager/CloseEngagement';
import CivicModal from '../../components/Common/CivicModal';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

const CloseEngagement = (props) => {
  const {
    engagement,
    closeEngagementData,
    closeEngagementErrors,
    closeEngagementLoading,
    closeEngagementSubmitting,
    closeEngagementChange,
    submitCloseEngagement,
    setCloseEngagementData,
    closeCommentEditChange,
    user,
  } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      setCloseEngagementData(engagement);
      setLoaded(true);
    }
  }, [loaded, setCloseEngagementData, engagement]);

  return (
    <>
      {loaded === true &&
      typeof closeEngagementData[engagement.id] !== 'undefined' ? (
        <div key={`close-engagement-div-${engagement.id}`}>
          <CivicModal
            id={`close-engagement-${engagement.id}`}
            title={closeEngagementData[engagement.id].title}
            dismissal={closeEngagementData[engagement.id].dismissal}
          >
            <CloseEngagementForm
              closeEngagementData={closeEngagementData[engagement.id]}
              closeEngagementErrors={closeEngagementErrors}
              closeEngagementLoading={closeEngagementLoading}
              closeEngagementSubmitting={closeEngagementSubmitting}
              closeEngagementChange={closeEngagementChange}
              submitCloseEngagement={submitCloseEngagement}
              closeCommentEditChange={closeCommentEditChange}
              user={user}
            />
          </CivicModal>
        </div>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    closeEngagementData: state.closeEngagement.closeEngagementData,
    closeEngagementErrors: state.closeEngagement.closeEngagementErrors,
    closeEngagementLoading: state.closeEngagement.closeEngagementLoading,
    closeEngagementSubmitting: state.closeEngagement.closeEngagementSubmitting,
  };
};

CloseEngagement.defaultProps = {
  type: 'Modal',
};

export default connect(mapStateToProps, actions)(CloseEngagement);
