import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import actions from "../../../actions";
import {Modal} from "react-bootstrap";

const OfficialStepThree = (props) => {

    const {
        registration,
        registerChange,
        registrationFormErrors,
        registerNewOfficial,
        showCloseButton,
        registrationFinished,
        setRegistrationModalOpen,
        setStep,
        resetRegisterState
    } = props;

    const {t} = useTranslation();

    const [dataSent, setDataSent] = useState(false);

    const handleCloseModal = () => {
        setRegistrationModalOpen(false);
        setStep(1);
        resetRegisterState();
        setDataSent(false);
    }

    useEffect(() => {
        if (registrationFinished) {
            setDataSent(true);
        }
    }, [registrationFinished]);

    return <>
        <Modal.Header closeButton={showCloseButton}>
            <Modal.Title className="w-100 text-center">
                {t(`Welcome to CivicBell`)}, {registration.registerFormData.firstName}!
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display: !dataSent ? 'block' : 'none'}}>
                <div className="form-group col-md-12">
                    <div>{t(`Thank you for signing up! Unfortunately, we don't have a ${registration.registerFormData.firstName} ${registration.registerFormData.lastName} from ${registration.registerFormData.district} on file!`)}</div>
                </div>

                <div className="form-group col-md-12">
                    <div>
                        {t(`Please let us know how to best reach you and we will verify your account within 24 hours!`)}
                    </div>
                </div>

                <div className="form-group col-md-12">
                    <span>{t(`Your official email address`)}</span>
                </div>

                <div className="form-group col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t(`Email`)}
                        name={'officialEmail'}
                        value={registration.registerFormData.officialEmail}
                        onChange={(e) => registerChange(e.target.name, e.target.value)}
                        autoComplete={'no'}
                        id="regevent_email"
                    />
                    {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.email && (
                        <div className="clsinvalid">{t(registrationFormErrors?.['email'])}</div>)}
                </div>

                <div className="form-group col-md-12">
                    <span>{t(`Your position`)}</span>
                </div>
                <div className="form-group col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t(`Position`)}
                        name={'position'}
                        value={registration.registerFormData.position}
                        onChange={(e) => registerChange(e.target.name, e.target.value)}
                        autoComplete={'no'}
                        id="regevent_position"
                    />
                    {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.position && (
                        <div className="clsinvalid">{t(registrationFormErrors?.['position'])}</div>)}
                </div>

                <div className="form-group col-md-12" style={{
                    textAlign: 'center'
                }}>
                    <button
                        type="button"
                        className="btn clsyellow-btn"
                        onClick={registerNewOfficial}
                        style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>{t(`Submit`)}
                    </button>
                </div>
            </div>

            <div style={{display: dataSent ? 'block' : 'none'}}>
                <div className="form-group col-md-12 text-center">
                    {t(`Thank you! We will be in touch within 24 hours.`)}
                </div>

                <div className="form-group col-md-12" style={{textAlign: 'center'}}>
                    <button
                        type="button"
                        className="btn clsyellow-btn"
                        onClick={handleCloseModal}
                        style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>{t(`Close`)}
                    </button>
                </div>
            </div>
        </Modal.Body>
    </>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        registrationFormErrors: state.registration.formErrors,
        registrationFinished: state.registration.registerFormData.registrationFinished
    };
};

export default connect(mapStateToProps, actions)(OfficialStepThree);