/*
 *
 * Survey questions constants
 *
 */

export const SURVEY_CHANGE = 'src/SurveyQuestions/SURVEY_CHANGE';
export const SURVEY_LOADING = 'src/SurveyQuestions/SURVEY_LOADING';
export const SET_SURVEY_DATA = 'src/SurveyQuestions/SET_SURVEY_DATA';
export const SET_SURVEY_RELOAD = 'src/SurveyQuestions/SET_SURVEY_RELOAD';
export const RESET_APP = 'src/SurveyQuestions/RESET_APP';
