/*
 *
 * Survey question
 *
 */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import actions from '../../actions';
//import Questions from '../../components/Manager/QuestionForm';
import QuestionsModal from './questionModal';
import surveyAttachments from '../../components/Manager/QuestionForm/surveyAttachments';
import Reminder from '../../components/Manager/Engagement/reminder';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import Tooltip from '../../components/Common/Tooltip';
import { useTranslation } from 'react-i18next';

const SurveyQuestions = (props) => {
  const {
    engagement,
    location,
    surveyData,
    setSurveyDataFromEngagement,
    surveyChange,
    surveyLoading,
    user,
    showOriginalLanguage,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);

  //Survey Attachments
  const [attachmentComponent, setAttachmentComponent] = useState('');
  const [attachmentComponentloaded, setAttachmentComponentLoaded] =
    useState(false);

  //Survey Questions
  const [questionComponent, setQuestionComponent] = useState('');
  const [questionLoaded, setQuestionLoaded] = useState(false);
  const surveyQuestionRef = useRef(null);

  const renderComponent = useCallback(
    (Component, type, engagement) => {
      if (type === 'surveyAttachments') {
        return new Promise((resolve, reject) => {
          setAttachmentComponent(
            <Component engagement={engagement} {...props} />,
          );
          return resolve(true);
        });
      } else if (type === 'surveyQuestions') {
        return new Promise((resolve, reject) => {
          setQuestionComponent(
            <Component {...props} surveyChange={surveyChange} />,
          );
          return resolve(true);
        });
      }
    },
    [props, surveyChange],
  );

  const attachmentModalRef = useRef(null);

  const showAttachmentModal = (event, engagement) => {
    attachmentModalRef.current.setAttribute(
      'data-target',
      '#survey-attachments-' + engagement.id,
    );
    if (attachmentComponentloaded === false) {
      attachmentModalRef.current.click();
      setAttachmentComponentLoaded(true);
    }
  };

  const showSurveyQuestions = (engagement) => {
    surveyQuestionRef.current.setAttribute(
      'data-target',
      '#survey-question-' + engagement.id,
    );
    if (questionLoaded === false) {
      surveyQuestionRef.current.click();
      setQuestionLoaded(true);
    }
  };

  const hashUrl = '#';

  const uri = location.pathname.replace(/^\/|\/$/g, '');

  const [disabledStatus, setDisabledStatus] = useState(false);
  const [publicResult, setPublishResult] = useState(false);
  const [dashboardView, setDashboardView] = useState(false);

  useEffect(() => {
    if (
      (uri !== 'dashboard/civicfeed' &&
        uri !== 'dashboard/searchresult' &&
        uri !== 'dashboard/user-profile' &&
        uri !== 'dashboard/civiclist' &&
        uri !== 'dashboard/engagement') ||
      engagement.stage === 'closed' ||
      engagement.stage === 'outcome' ||
      engagement.user.userId === user.userId
    ) {
      setDisabledStatus(true);
    } else {
      setDisabledStatus(false);
    }

    if (
      uri === 'dashboard/civicfeed' ||
      uri === 'dashboard/searchresult' ||
      uri === 'dashboard/user-profile' ||
      uri === 'dashboard/civiclist'
    ) {
      if (engagement.stage === 'outcome') {
        setPublishResult(true);
      }
    } else {
      if (engagement.stage === 'closed' || engagement.stage === 'outcome') {
        setPublishResult(true);
        if (user.roleName === 'PUBLICOFFICIAL' || user.roleName === 'ADMIN') {
          setDashboardView(true);
        }
      }
    }
  }, [
    setDisabledStatus,
    setPublishResult,
    uri,
    engagement.stage,
    user.roleName,
  ]);

  const dataFromEngagement = useCallback(() => {
    if (loaded === false) {
      setSurveyDataFromEngagement(engagement);
      setLoaded(true);
    }
  }, [loaded, setLoaded, setSurveyDataFromEngagement, engagement]);

  useEffect(() => {
    dataFromEngagement();
  });

  return (
    <>
      {surveyLoading === true ? <LoadingIndicator position={`fixed`} /> : ''}
      {loaded === true && typeof surveyData[engagement.id] !== 'undefined' ? (
        <div
          className={`clssurvey-preview-blk-in mt-2 ${disabledStatus === true || engagement.canReact === false
              ? 'survey-disabled'
              : 'survey-enabled'
            }`}
          style={{ position: 'relative' }}
        >
          {engagement.stage === 'closed' &&
            (uri === 'dashboard/civicfeed' ||
              uri === 'dashboard/searchresult' ||
              uri === 'dashboard/user-profile' ||
              uri === 'dashboard/civiclist' ||
              uri === 'dashboard/engagement') ? (
            <div className="clsread-only-alert mb-3">
              {t(
                `This engagement is closed and read-only at this point. Follow this post to receive status updates.`,
              )}
            </div>
          ) : (
            ''
          )}
          <h5 className="mb-2">
            {
              user.selected_language === engagement.originalLanguage || showOriginalLanguage  ?
                surveyData[engagement.id].question[0].title
                :
                (surveyData[engagement.id].question[0].translation) && (JSON.parse(surveyData[engagement.id].question[0].translation)?.[user.selected_language]?.question) ?
                  JSON.parse(surveyData[engagement.id].question[0].translation)?.[user.selected_language]?.question
                  :
                  surveyData[engagement.id].question[0].title
            }
          </h5>
          <>
            {attachmentComponent}
            {typeof user.roleName !== 'undefined' ? (
              <figure className="clssurvey-info">
                <a
                  href={hashUrl}
                  data-toggle="modal"
                  data-target={hashUrl}
                  onClick={(event) => {
                    renderComponent(
                      surveyAttachments,
                      'surveyAttachments',
                      engagement,
                    ).then(() => {
                      showAttachmentModal(event, engagement);
                    });
                  }}
                  ref={attachmentModalRef}
                >
                  <img
                    src={require('../../assets/img/info.svg').default}
                    alt="Info"
                  />
                </a>
              </figure>
            ) : (
              ''
            )}
          </>
          <fieldset
            disabled={
              disabledStatus === true || engagement.canReact === false
                ? 'disabled'
                : ''
            }
          >
            <div
              className={`${disabledStatus === true || engagement.canReact === false
                  ? 'choice-disabled '
                  : 'choice-enabled '
                } ${surveyData[engagement.id].question[0].type === '1' &&
                  (engagement.stage === 'unpublished' ||
                    engagement.stage === 'open')
                  ? 'clssurvey-choose mb-2'
                  : surveyData[engagement.id].question[0].type === '1' &&
                    (engagement.stage === 'closed' ||
                      engagement.stage === 'outcome')
                    ? 'clssurvey-choose mb-2 clssurvey-yes-no-disable'
                    : 'mb-3'
                }`}
            >
              {surveyData[engagement.id]?.question[0]?.['choice']?.map(
                (choice, index) => (
                  <div className="checkbox survey-choice" key={index}>
                    <input
                      type="checkbox"
                      id={`questzero-choice-${surveyData[engagement.id].question[0]['choice'][index]
                          .id
                        }`}
                      name={`question-choice-${engagement.id}`}
                      checked={
                        surveyData[engagement.id].question[0]['choice'][index]
                          .answered === true
                          ? true
                          : false
                      }
                      onChange={() => {
                        surveyChange(
                          engagement.id,
                          0,
                          index,
                          !surveyData[engagement.id].question[0]['choice'][
                            index
                          ].answered,
                          surveyData[engagement.id].question[0].type,
                          true,
                        );
                      }}
                    />
                    <label
                      className={
                        surveyData[engagement.id].question[0].type === '1'
                          ? ''
                          : 'choice-option-label'
                      }
                      id={`questzero-label-${surveyData[engagement.id].question[0]['choice'][index]
                          .id
                        }`}
                      htmlFor={`questzero-choice-${surveyData[engagement.id].question[0]['choice'][index]
                          .id
                        }`}
                    >
                      <h4
                        className={
                          surveyData[engagement.id].question[0].type === '1' &&
                            (dashboardView === true ||
                              (publicResult === true &&
                                surveyData[engagement.id].question[0]
                                  .isSelected === true))
                            ? 'two-choice-text' +
                            (typeof choice.result === 'undefined' ||
                              choice.result === ''
                              ? ' choicePosition'
                              : '')
                            : 'multiple-choice-text'
                        }
                      >
                        {
                          user.selected_language === engagement.originalLanguage || showOriginalLanguage ?
                              choice.value
                            :
                            choice.translation && (JSON.parse(choice.translation)?.[user.selected_language]?.answer) ?
                                JSON.parse(choice.translation)?.[user.selected_language]?.answer
                              :
                                choice.value
                        }
                      </h4>
                      {dashboardView === true ||
                        (publicResult === true &&
                          surveyData[engagement.id].question[0].isSelected ===
                          true) ? (
                        <span
                          className={
                            surveyData[engagement.id].question[0].type === '1'
                              ? 'two-choice-percentage'
                              : 'multiple-choice-percentage'
                          }
                        >
                          {choice.result}
                        </span>
                      ) : (
                        ''
                      )}
                    </label>
                    {engagement.canReact === false ? (
                      <Tooltip
                        targetId={`questzero-label-${surveyData[engagement.id].question[0]['choice'][index]
                            .id
                          }`}
                        targetStatus={true}
                        styleClass={'react-tooltip'}
                        message={t(engagement.restriction_reason)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                ),
              )}
            </div>
          </fieldset>
          {surveyData[engagement.id].question.length > 1 &&
            typeof user.roleName !== 'undefined' ? (
            <>
              <div className="clsaddtional-question">
                <a
                  href={hashUrl}
                  data-toggle="modal"
                  data-target={hashUrl}
                  onClick={(event) => {
                    if (typeof user.roleName !== 'undefined') {
                      renderComponent(
                        QuestionsModal,
                        'surveyQuestions',
                        engagement,
                      ).then(() => {
                        showSurveyQuestions(engagement);
                      });
                    } else {
                      history.push('/login');
                    }
                  }}
                  ref={surveyQuestionRef}
                >
                  <span>
                    <img
                      src={require('../../assets/img/survey.svg').default}
                      alt="Survey"
                    />
                    {engagement.stage === 'outcome'
                      ? t(
                        'See additional questions on multi-answer poll outcome',
                      )
                      : t('Answer Additional Questions')}
                  </span>
                  <span>
                    <img
                      src={
                        require('../../assets/img/chevron_right_icon.svg')
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
              {questionComponent}
            </>
          ) : (
            ''
          )}
          {user.userId !== engagement.user.userId ? (
            <Reminder {...props} />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    surveyData: state.surveyQuestion.surveyData,
    reload: state.surveyQuestion.reload,
    surveyLoading: state.surveyQuestion.surveyLoading,
  };
};

export default connect(mapStateToProps, actions)(SurveyQuestions);
