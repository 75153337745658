import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import actions from "../../../actions";
import {useTranslation} from 'react-i18next';
import CivicModal from '../../Common/CivicModal';
import CommunityIssueEngagementBanner from './CommunityIssueEngagementBanner';
import CommunityIssueEngagementHeader from './CommunityIssueEngagementHeader';
import CommunityIssueTopicList from './CommunityIssueTopicList';
import CommunityIssueEngagementFooter from './CommunityIssueEngagementFooter';
import CommunityIssueTextContent from './CommunityIssueTextContent';
import YourCommunityIssueNotVisible from './YourCommunityIssueNotVisible';
import IssueAreaModal from './IssueAreaModal';
import CommunityIssueCommentsContainer from './CommunityIssueCommentsContainer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {daysDiffUntil} from '../../../utils/helper';
import moment from 'moment-timezone';
import SignThisCommunityIssueModal from './SignThisCommunityIssueModal';
import SignedConfirmationModal from './SignedConfirmationModal';
import CommunityIssueSlider from './CommunityIssueSlider';

import {Modal} from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CommunityIssueEngagement = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    allCommunityIssues,
    user
  } = props;
  const hashUrl = '#';

  const isOfficial = (user.roleName === 'PUBLICOFFICIAL' || user.roleName === 'ADMIN');
  const communityIssue = allCommunityIssues?.[communityIssueId];

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState('');

  const [isSignThisCommunityIssueModalOpen, setIsSignThisCommunityIssueModalOpen] = useState(false);
  const [isSignedConfirmationModalOpen, setIsSignedConfirmationModalOpen] = useState(false);

  const [isIssueAreaModalOpen, setIsIssueAreaModalOpen] = useState(false);
  const [isCommunityIssueOpen, setIsCommunityIssueOpen] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState(new Date(moment
    .utc(new Date())
    .tz(moment.tz.guess())
    .format('YYYY-MM-DD HH:mm:ss')));
  useEffect(() => {
    setCurrentDateTime(new Date(moment
      .utc(new Date())
      .tz(moment.tz.guess())
      .format('YYYY-MM-DD HH:mm:ss')));
    const t1 = currentDateTime.getTime();
    const t2 = new Date(communityIssue?.closingDate).getTime();
    setIsCommunityIssueOpen((t1 - t2 < -20000)); // .getTime() is inaccurrate; need some offset
  }, [communityIssue]);

  let isSwiping = false;

  function updateSwipeState(state) {
    isSwiping = state;
  }

  const handleShowImageModal = (src) => {
    if (src && !isSwiping) {
      setCurrentModalImage(src);
      setIsImageModalOpen(true);
    }
  };

  const handleHideImageModal = () => {
    if (!isSwiping) {
      setCurrentModalImage('');
      setIsImageModalOpen(false);
    }
  };

  const handleDocumentDownload = (src) => {
    if (src && !isSwiping) {
      const fileName = src.split("/").pop();

      // Create XMLHTTP Request.
      const req = new XMLHttpRequest();

      req.open("GET", src, true);
      req.responseType = "blob";
      req.onload = function () {
        // Convert the Byte Data to BLOB object.
        let blob = new Blob([req.response], {type: "application/octetstream"});

        // Check the Browser type and download the File.
        let isIE = !!document.documentMode;

        if (isIE) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          let url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          let a = document.createElement("a");

          a.setAttribute("download", fileName);
          a.setAttribute("href", link);

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      req.send();
    }
  };

  const [viewMapName, setViewMapName] = useState('');
  useEffect(() => {
    if (communityIssue.ocdIdInfo) {
      setViewMapName(communityIssue.ocdIdInfo.name ?? t('View Map'));
    } else if (communityIssue.area) {
      setViewMapName(communityIssue.areaName ?? t('View Map'));
    }
  }, [communityIssue]);

  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);

  if (!communityIssue) {
    return <></>
  }
  return (
    <>
      {
        isOfficial && <CommunityIssueEngagementBanner communityIssueId={communityIssueId} />
      }
      <div
        className="clsdashboard-content-box clswhite-box mb-3"
        id={`community-issue-${communityIssueId}`}
        style={{
          borderTopRightRadius: isOfficial && '0',
          borderTopLeftRadius: isOfficial && '0',
        }}
      >
        {
          communityIssue.numSignees < 2 && (user.userId === communityIssue.userId) && <YourCommunityIssueNotVisible communityIssueId={communityIssueId} />
        }
        {
          isCommunityIssueOpen ?
            <>
            </>
          :
            <div className='community-issues-engagement-closed'>
              <img
                src={require('../../../assets/img/time_warning.svg').default}
              />
              <b>
                {t('This issue is closed and read only at this point.')}
              </b>
            </div>
        }
        <CommunityIssueEngagementHeader 
          communityIssueId={communityIssueId}
          isSignThisCommunityIssueModalOpen={isSignThisCommunityIssueModalOpen}
          setIsSignThisCommunityIssueModalOpen={(x) => setIsSignThisCommunityIssueModalOpen(x)}
          showOriginalLanguage={showOriginalLanguage}
          setShowOriginalLanguage={setShowOriginalLanguage}
        />
        <div className='community-issues-engagement-title-container'>
          <img
            src={communityIssue.mainImage ?? require('../../../assets/img/empty-user-icon.svg').default}
            className='content-preview-image'
          />
          <div className='outer-col'>
            <p className='title-text'>
              {
                user.selected_language === communityIssue.originalLanguage || showOriginalLanguage ?
                  communityIssue.title
                  :
                  (JSON.parse(communityIssue.translation)?.[user.selected_language]?.title) ?
                    JSON.parse(communityIssue.translation)?.[user.selected_language]?.title
                    :
                    communityIssue.title
              }
            </p>
            <div className='inner-row'>
              <img
                src={require('../../../assets/img/location.svg').default}
                alt="Edit Issue"
                className='icon'
              />
              <p>
                {communityIssue?.ocdIdInfo?.name}
              </p>
              {
                !(communityIssue.ocdId) &&
                <a
                  className='text-btn'
                  href={hashUrl}
                  data-toggle="modal"
                  data-target={`#issue-area-${communityIssueId}`}
                  style={{
                    marginLeft: -5,
                  }}
                >
                  {viewMapName}
                </a>
              }
            </div>
            <div className='inner-row'>
              <img
                src={require('../../../assets/img/alarm-clock.svg').default}
                alt="Edit Issue"
                className='icon'
              />
              {
                isCommunityIssueOpen ?
                  <p>
                    {t('Open until ')}
                    <b>{new Date(communityIssue.closingDate).toLocaleDateString('es-pa')}</b>
                    {'('}{daysDiffUntil(new Date(communityIssue.closingDate), currentDateTime, t)}{')'}
                  </p>
                  :
                  <p>
                    {t('This issue closed on')}{' '}
                    <b>{new Date(communityIssue.closingDate).toLocaleDateString('es-pa')}</b>
                  </p>
              }

            </div>
            <CommunityIssueTopicList communityIssueId={communityIssueId} />
          </div>
        </div>
        <hr
          style={{
            backgroundColor: '#F5F4F9',
          }}
        />
        <div className='community-issues-engagement-content'>
          <div className='pill'>
            <img
              className='profile-avatar'
              src={
                communityIssue?.user?.profile_picture ?? require('../../../assets/img/profile-image.jpeg').default
              }
              alt="Profile Avatar"
            />
            <p>
              {`${communityIssue.user.displayName} `}{t('started this community issue on')}{' '}{new Date(communityIssue.timePosted).toLocaleDateString('es-pa')}:
            </p>
          </div>
          <div className={`editor-content`}>
            <span>
              <CommunityIssueTextContent 
                communityIssueId={communityIssueId} 
                showOriginalLanguage={showOriginalLanguage}
              />
            </span>
          </div>
          {
            // https://www.npmjs.com/package/react-responsive-carousel
          }

          <Modal
              show={isImageModalOpen}
              onHide={handleHideImageModal}
              centered
          >
            <Modal.Header closeButton={true}/>
            <Modal.Body style={{textAlign: 'center'}}>
              {currentModalImage.length > 0 && (currentModalImage.includes('http') || currentModalImage.includes('https')) ? <>
                <img style={{maxWidth: '100%'}} src={currentModalImage} alt=""/>
              </> : <>
                <img style={{maxWidth: '100%'}} src={`${currentModalImage}`} alt=""/>
              </>}
            </Modal.Body>
          </Modal>

          <CommunityIssueSlider attachedDocuments={communityIssue.attachedDocuments} />

          <hr
            style={{
              marginTop: 20,
              marginBottom: 20,
              padding: 0,
              borderColor: '#D8D8D8',
              zIndex: 0,
            }}
          />
          <CommunityIssueEngagementFooter communityIssueId={communityIssueId} />
          <hr
            style={{
              marginTop: 20,
              marginBottom: 5,
              padding: 0,
              borderColor: '#D8D8D8',
              zIndex: 0,
            }}
          />
          <CommunityIssueCommentsContainer communityIssueId={communityIssueId} />
        </div>
        <CivicModal
          id={`issue-area-${communityIssueId}`}
          title={`${viewMapName}`}
          footerClass={`text-center mt-4`}
          modalDialogueClass={`clswidth-480`}
          onOpen={() => { setIsIssueAreaModalOpen(true) }}
          onClose={() => { setIsIssueAreaModalOpen(false) }}
        >
          <IssueAreaModal
            communityIssueId={communityIssueId}
            isOpen={isIssueAreaModalOpen}
          />
        </CivicModal>
        <Modal
          show={isSignThisCommunityIssueModalOpen}
          onHide={() => {
            setIsSignThisCommunityIssueModalOpen(false);
          }}
          centered
        >
          <Modal.Header closeButton={true} />
          <Modal.Title style={{ textAlign: 'center' }}>{t('Sign this Community Issue')}</Modal.Title>
          <Modal.Body style={{ textAlign: 'center' }}>
            <SignThisCommunityIssueModal
              communityIssueId={communityIssueId}
              isSignThisCommunityIssueModalOpen={isSignThisCommunityIssueModalOpen}
              setIsSignThisCommunityIssueModalOpen={setIsSignThisCommunityIssueModalOpen}
              SignedConfirmationModalOpen={isSignedConfirmationModalOpen}
              setIsSignedConfirmationModalOpen={setIsSignedConfirmationModalOpen}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={isSignedConfirmationModalOpen}
          onHide={() => {
            setIsSignedConfirmationModalOpen(false);
          }}
          centered
        >
          <Modal.Header closeButton={true} />
          <Modal.Title style={{ textAlign: 'center' }}>{t('Congratulations! You signed this issue.')}</Modal.Title>
          <Modal.Body style={{ textAlign: 'center' }}>
            <SignedConfirmationModal
              communityIssueId={communityIssueId} 
              setIsSignedConfirmationModalOpen={setIsSignedConfirmationModalOpen}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueEngagement)