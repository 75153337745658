/**
 *
 * Date Input
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const DateInput = ({
  onChange,
  placeholder,
  value,
  id,
  onClick,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <input
      onChange={onChange}
      placeholder={placeholder}
      value={t(value)}
      id={id}
      onClick={onClick}
      className={`form-control date-placeholder ${className}`}
      readOnly
    />
  )
};

DateInput.defaultProps = {
  className: '',
};

export default DateInput;
