/*
 *
 * Petitions constants
 *
 */
import i18next from 'i18next';

export const SET_PETITION_TAB = 'src/Petition/SET_PETITION_TAB';
export const SET_PETITION_LOADING = 'src/Petition/SET_PETITION_LOADING';
export const FETCH_PETITION_DATA = 'src/Petition/FETCH_PETITION_DATA';
export const PETITION_RELOAD_LIST = 'src/Petition/PETITION_RELOAD_LIST';
export const PETITION_CHANGE = 'src/Petition/PETITION_CHANGE';
export const PETITION_FORM_CHANGE = 'src/Petition/PETITION_FORM_CHANGE';
export const PETITION_RESET = 'src/Petition/PETITION_RESET';
export const SET_PETITION_FORM_ERRORS = 'src/Petition/SET_PETITION_FORM_ERRORS';
export const SET_PETITION_FORM_RESET = 'src/Petition/SET_PETITION_FORM_RESET';
export const SET_PETITION_SINGLE = 'src/Petition/SET_PETITION_SINGLE';
export const RESET_PETITION_SINGLE = 'src/Petition/RESET_PETITION_SINGLE';
export const RESET_SIGNIN_PETITION = 'src/Petition/RESET_SIGNIN_PETITION';
export const SET_SIGNIN_PETITION = 'src/Petition/RESET_SIGNIN_PETITION';
export const PETITION_SPECIFIC_DATA = 'src/Petition/PETITION_SPECIFIC_DATA';
export const PETITION_DIVISION_DATA = 'src/Petition/PETITION_DIVISION_DATA';
export const FETCH_MY_PETITION_DATA = 'src/Petition/FETCH_MY_PETITION_DATA';
export const SET_MYPETITION_TAB = 'src/Petition/SET_MYPETITION_TAB';
export const RESET_APP = 'src/Petition/RESET_APP';
export const SET_FILTER_LEVEL = 'src/Petition/SET_FILTER_LEVEL';

export const sortOptionsPetition = [
  { value: 'newest', label: 'Newest on top', translationParsing: i18next.t('Newest on top') },
  { value: 'oldest', label: 'Oldest on top', translationParsing: i18next.t('Oldest on top') },
];

export const divisionOptions = [
  { value: 'division', label: 'Division Options', translationParsing: i18next.t('Division Options') },
];
