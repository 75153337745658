/*
 *
 * CivicMaps actions
 *
 */

import {
  CIVICMAP_CHANGE,
  SET_CIVICMAP_LOADING,
  // SET_GEO_JSON,
  SET_GEO_JSON_ARRAY,
  // SET_MERGE_GEO_JSON,
  FETCH_CIVIC_MAP,
  MAP_RELOAD,
  MAP_RESET,
  OFFICIALS_CHANGE,
  SET_OFFICIALS_LOADING,
  OFFICIALS_LIST_CHANGE,
  OFFICIALS_RELOAD_LIST,
  FETCH_OFFICIALS,
  SET_CURRENT_DISTRICT,
  SET_FILTER_LEVEL,
  RESET_APP,
} from './constants';
import {
  BASE_API_URL,
  AWS_S3_BUCKET_NAME,
  AWS_S3_BUCKET_MAP_DIRECTORY_NAME,
} from '../../constants';
import axios from 'axios';
import { addNotification } from '../Notification/actions';
import { GeoPackageAPI, setSqljsWasmLocateFile } from '@ngageoint/geopackage';
// import geojsonMerge from '@mapbox/geojson-merge';
import { GeoJSONToGeoPackage } from '@ngageoint/geopackage-geojson-js';
setSqljsWasmLocateFile(function (filename) {
  return 'https://unpkg.com/@ngageoint/geopackage@4.1.0/dist/' + filename;
});
const converter = new GeoJSONToGeoPackage();

export const resetMapState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

// Civic Map Related funcitons
//

export const civicMapChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: CIVICMAP_CHANGE, payload: formData });
  };
};

export const setFilterLevel = (formData) => {
  return async (dispatch, getState) => {
    // var filterData = getState().map.filter;
    // if(formData.level1 in filterData)
    // filterData[formData.level1] = [...new Set([...filterData[formData.level1], formData.level2])];
    // else
    // filterData[formData.level1] = [formData.level2];

    return dispatch({ type: SET_FILTER_LEVEL, payload: formData });
  };
};

export const setGeoJsonData = (formData) => {
  return async (dispatch, getState) => {
    // var geoJsonData = getState().map.geoJsonData;
    dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
    dispatch({ type: SET_GEO_JSON_ARRAY, payload: [formData] });

    // if(geoJsonData===null)
    // return dispatch({ type: SET_GEO_JSON, payload: formData });
    // else
    // return dispatch({ type: SET_MERGE_GEO_JSON, payload: formData });
  };
};

export const getGeoJsonData = (geoPackageFile) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      geoPackageFile = geoPackageFile.includes('.geojson')
        ? geoPackageFile.replace('.geojson', '.gpkg')
        : geoPackageFile;
      dispatch({ type: SET_CIVICMAP_LOADING, payload: true });
      let blob = await fetch(geoPackageFile).then((r) => r.blob());
      var r = new FileReader();
      r.readAsArrayBuffer(blob);
      r.onload = async function () {
        var array = new Uint8Array(r.result);
        GeoPackageAPI.open(array)
          .then(function (gp) {
            let tableName = gp.getFeatureTables()[0];
            converter
              .extract(gp, tableName)
              .then(function (geoJson) {
                return dispatch(setGeoJsonData(geoJson));
              })
              .catch((e) => {
                return;
              });
          })
          .catch((e) => {
            return;
          });
      };
      return resolve(true);
    });
  };
};

// export const setGeoJsonDataArray = (formData) => {
//   return async (dispatch, getState) => {
//     var geoJsonDataArray = getState().map.geoJsonDataArray;
//     dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
//     geoJsonDataArray.push(formData)
//     //dispatch({ type: SET_GEO_JSON, payload: formData });
//     return dispatch({ type: SET_GEO_JSON_ARRAY, payload: [formData] });
//   }
// };

// export const getGeoJsonDataArray = (geoPackageFile) => {
//   return async (dispatch, getState) => {
//     return new Promise(async(resolve, reject) => {
//       dispatch({ type: SET_CIVICMAP_LOADING, payload: true });
//       let blob = await fetch(geoPackageFile).then(r => r.blob());
//       var r = new FileReader();
//       r.readAsArrayBuffer(blob);
//       r.onload = async function() {
//         var array = new Uint8Array(r.result);
//         GeoPackageAPI.open(array).then(function(gp) {
//           converter.extract(gp, tableName).then(function(geoJson) {
//             return dispatch(setGeoJsonDataArray(geoJson));
//             }).catch(e=>{
//               return;
//             });
//         }).catch(e=>{
//           return;
//         });
//       }
//       return resolve(true)
//     })
//   }
// };

// const concatGeoJSON = (g1, g2)=>{
//   var newGeoJson = {
//       "type" : "FeatureCollection",
//       "features": [... g1.features, ... g2.features]
//   };
//   newGeoJson.features = newGeoJson.features.map((item,index)=>{
//     item.id=index+1;
//     item.properties.fid=item.id;
//     return item;
//   })
//   return newGeoJson;
// }

// export const getFilterData = () => {
//   return async (dispatch, getState) => {
//     return new Promise(async(resolve, reject) => {
//       var civicMaps = getState().map.civicMaps;
//       const requests = civicMaps.map(function(value) {
//         var formdata = {'level1':value.level1, 'level2':value.level2}
//         //dispatch(setFilterLevel(formdata));
//         return formdata;
//       });

//       // Wait for all requests, and then setState
//       return Promise.all(requests).then(() => {
//       });

//     })
//   }
// }

export const getFilterData = (civicMaps) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      var filterData = { Local: [], State: [], Federal: [] };
      const requests = civicMaps.map(function (value) {
        if (value.level1 in filterData) {
          if (value.level2 in filterData[value.level1]) {
            filterData[value.level1][value.level2].push(value);
          } else {
            filterData[value.level1][value.level2] = [value];
          }
        }
        // else //restricted other level 1 items other than Local, State, Federal
        // {
        //   filterData[value.level1] = {[value.level2]:[value]}
        // }
        return filterData;
      });

      // Wait for all requests, and then setState
      return Promise.all(requests).then(() => {
        dispatch(setFilterLevel(filterData));
      });
    });
  };
};

// export const selectAllFilterLevels = () => {
//   return async (dispatch, getState) => {
//     return new Promise(async(resolve, reject) => {
//       var filterData = getState().map.filter;
//       var filterSelected = getState().map.filterSelected;

//       Object.keys(filterData).map((level1,index)=>{
//         filterSelected['level2'] = [...filterSelected['level2'],...filterData[level1]]
//         dispatch(civicMapChange({"filterSelected":filterSelected}))
//         return filterSelected;
//       })

//       // Wait for all requests, and then setState
//       return resolve(true);
//     })
//   }
// }

export const fetchAddress = (address) => {
  if (address === null || address === undefined) return;
  let formData = { address: '', zip_code: '', city: '', state: '' };
  for (const component of address) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        formData.address = component.long_name;
        break;
      }

      case 'route': {
        formData.address = formData.hasOwnProperty('address')
          ? formData.address + ' ' + component.long_name
          : component.long_name;
        break;
      }

      case 'postal_code': {
        formData.zip_code = component.long_name;
        break;
      }
      case 'locality':
        formData.city = component.long_name;
        break;
      case 'administrative_area_level_1': {
        formData.state = component.long_name;
        break;
      }
      default: {
        break;
      }
    }
  }
  return formData;
};

export const getCivicMapOnSearch = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let mapSearch = getState().map.mapSearch;
      let user = getState().account.user;
      let address_object = fetchAddress(mapSearch.address_components);
      let apiUri = '';

      if (
        typeof user.roleName === 'undefined' &&
        typeof address_object === 'undefined'
      ) {
        apiUri =
          '/users/getOcdidsForAddress?street=1315 10th St&city=Sacramento&state=California&zip=94040';
      } else {
        apiUri =
          '/users/getOcdidsForAddress?street=' +
          address_object.address +
          '&city=' +
          address_object.city +
          '&state=' +
          address_object.state +
          '&zip=' +
          address_object.zip_code;
      }

      let s3URL =
        'https://' +
        AWS_S3_BUCKET_NAME +
        '.s3.us-west-1.amazonaws.com/' +
        AWS_S3_BUCKET_MAP_DIRECTORY_NAME +
        '/';
      dispatch({ type: SET_CIVICMAP_LOADING, payload: true });

      await axios
        .get(BASE_API_URL + apiUri)
        .then(async (response) => {
          if (response.data.success === 'true') {
            var mapData = response.data.data;

            let position = [38.5765924, -121.4954255];
            if (
              typeof user.roleName !== 'undefined' ||
              typeof mapSearch.geometry !== 'undefined'
            ) {
              position = [
                mapSearch.geometry.location.lat(),
                mapSearch.geometry.location.lng(),
              ];
            }
            var gotDefaultMap = false;
            dispatch(
              civicMapChange({ mapCenter: position, position: position }),
            );
            if (mapData.length < 1) {
              dispatch(
                civicMapChange({
                  noData: true,
                  filter: {},
                  filterSelected: { defaultMap: '', map: '' },
                }),
              );
              dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
              return resolve(true);
            }
            dispatch({ type: FETCH_CIVIC_MAP, payload: mapData });
            dispatch(getFilterData(mapData));
            const requests = await mapData.map(async (mapItem, index) => {
              if (mapItem.level2 === 'City' && !mapItem.ocdId.includes('zip')) {
                gotDefaultMap = true;
                dispatch(
                  civicMapChange({
                    filterSelected: { map: mapItem, defaultMap: mapItem },
                    currentLoadedMap: mapItem,
                  }),
                );
                return await dispatch(
                  getGeoJsonData(s3URL + encodeURIComponent(mapItem.cbmapfile)),
                ).then((res) => {});
              }
            });
            if (!gotDefaultMap) {
              dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
              dispatch(
                civicMapChange({
                  filterSelected: { defaultMap: '', map: '' },
                  currentLoadedMap: '',
                  geoJsonDataArray: [],
                }),
              );
            }
            // Wait for all requests, and then setState
            // return resolve(true)
            return Promise.all(requests).then(() => {
              dispatch(civicMapChange({ reloadMap: false }));
            });
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: 'Unable to fetch map data',
              module: 'map',
            }),
          );
          dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
          return resolve(true);
        });
    });
  };
};

export const setCivicMapFilter = (coordinates) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const apiUri = `/users/ocdids/latlng/${coordinates.lat},${coordinates.lng}`;
      const result = await axios.get(BASE_API_URL + apiUri);
      if (result.data.success === 'true') {
        dispatch(
          civicMapChange({
            mapSearch: {
              formatted_address: '',
            },
          }),
        );
        dispatch(getFilterData(result.data.data));
      }
    });
  };
};

export const getCivicMap = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let user = getState().account.user;
      let mapSearch = getState().map.mapSearch;
      if (mapSearch && mapSearch.address_components) {
        dispatch(getCivicMapOnSearch());
      } else {
        if (typeof user.roleName === 'undefined') {
          dispatch(
            civicMapChange({
              position: [38.5765924, -121.4954255],
              zoom: 10,
              mapSearch: {
                formatted_address: '1315 10th St, Sacramento, California, USA',
              },
            }),
          );
          return dispatch(getCivicMapOnSearch());
        } else {
          let apiUri = '/users/geoidbasedOcdids';
          let s3URL =
            'https://' +
            AWS_S3_BUCKET_NAME +
            '.s3.us-west-1.amazonaws.com/' +
            AWS_S3_BUCKET_MAP_DIRECTORY_NAME +
            '/';
          var gotDefaultMap = false;
          dispatch({ type: SET_CIVICMAP_LOADING, payload: true });
          await axios
            .get(BASE_API_URL + apiUri)
            .then(async (response) => {
              if (response.data.success === 'true') {
                var mapData = response.data.data;
                if (
                  response.data.user_location !== null &&
                  response.data.user_location !== ''
                ) {
                  var position = JSON.parse(response.data.user_location);
                  dispatch(
                    civicMapChange({
                      position: [position.lat, position.lng],
                      zoom: 10,
                      mapSearch: {
                        formatted_address: `${user.address}, ${user.city}, ${user.state}`,
                      },
                    }),
                  );
                }
                if (mapData.length < 1) {
                  dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
                  return;
                }
                dispatch({ type: FETCH_CIVIC_MAP, payload: mapData });
                dispatch(getFilterData(mapData));
                const requests = await mapData.map(async (mapItem, index) => {
                  if (
                    mapItem.level2 === 'City' &&
                    !mapItem.ocdId.includes('zip')
                  ) {
                    gotDefaultMap = true;
                    dispatch(
                      civicMapChange({
                        filterSelected: { map: mapItem, defaultMap: mapItem },
                        currentLoadedMap: mapItem,
                      }),
                    );
                    return await dispatch(
                      getGeoJsonData(
                        s3URL + encodeURIComponent(mapItem.cbmapfile),
                      ),
                    ).then((res) => {});
                  }
                });
                if (!gotDefaultMap) {
                  dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
                  dispatch(
                    civicMapChange({
                      filterSelected: { defaultMap: '', map: '' },
                      currentLoadedMap: '',
                      geoJsonDataArray: [],
                    }),
                  );
                }
                // Wait for all requests, and then setState
                // return resolve(true)
                return Promise.all(requests).then(() => {
                  dispatch(civicMapChange({ reloadMap: false }));
                });
              }
            })
            .catch((error) => {
              dispatch(
                addNotification({
                  type: 'error',
                  message: 'Unable to fetch map data',
                  module: 'map',
                }),
              );
              dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
              return resolve(true);
            });
        }
      }
    });
  };
};

export const mapChangeByOption = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData[name] = value;
    return dispatch({ type: CIVICMAP_CHANGE, payload: formData });
  };
};

export const reloadCivicMap = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: MAP_RESET });
      let s3URL = `https://${AWS_S3_BUCKET_NAME}.s3.us-west-1.amazonaws.com/${AWS_S3_BUCKET_MAP_DIRECTORY_NAME}/`;
      let filterSelected = getState().map.filterSelected.map;
      var mapData = getState().map.civicMaps;
      const requests = mapData.map(async (mapItem, index) => {
        if (filterSelected.ocdId === mapItem.ocdId) {
          dispatch(civicMapChange({ currentLoadedMap: mapItem }));
          return await dispatch(
            getGeoJsonData(s3URL + encodeURIComponent(mapItem.cbmapfile)),
          ).then((res) => {});
        }
      });

      return Promise.all(requests).then(() => {
        dispatch({ type: SET_CIVICMAP_LOADING, payload: false });
        dispatch({ type: MAP_RELOAD, payload: false });
      });
    });
  };
};

export const resetCivicMapFilter = () => {
  return async (dispatch, getState) => {
    let filterSelected = getState().map.filterSelected;
    filterSelected['map'] = filterSelected['defaultMap'];
    let formData = {};
    formData['currentLoadedMap'] = '';
    formData['filterDismissal'] = false;
    formData['districtBoxDismissal'] = true;
    formData['filterSelected'] = filterSelected;
    dispatch(civicMapChange(formData));
  };
};

export const civicMapFilterChange = (map) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().map.filterSelected;

    filterSelected['map'] = map;

    let formData = {};
    formData['filterSelected'] = { ...filterSelected };
    return dispatch(civicMapChange(formData));
  };
};

export const submitCivicMapFilter = () => {
  return async (dispatch, getState) => {
    let formData = {};
    formData['filterDismissal'] = false;
    formData['districtBoxDismissal'] = true;
    dispatch(civicMapChange(formData));
    dispatch(reloadCivicMap());
  };
};

// District Officials related functions
//

export const officialChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: OFFICIALS_CHANGE, payload: formData });
  };
};

export const officialChangeByOption = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData[name] = value;
    if (name === 'sort') {
      dispatch({ type: OFFICIALS_CHANGE, payload: formData });
      return dispatch(reloadOfficial());
    } else {
      return dispatch({ type: OFFICIALS_CHANGE, payload: formData });
    }
  };
};

export const getOfficials = (ocdid, page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      //let ocdid = 'ocd-division/country:us/state:ca/county:santa_clara';
      let apiUri = '/users/officialsforOcdids?ocdid=' + ocdid;

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }

      dispatch({ type: SET_OFFICIALS_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({
              type: SET_CURRENT_DISTRICT,
              payload: response.data.district ? response.data.district : null,
            });
            dispatch({ type: FETCH_OFFICIALS, payload: response.data.data });

            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            formData['reloadList'] = false;
            dispatch(officialChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'map',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_OFFICIALS_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const reloadOfficial = () => {
  return async (dispatch, getState) => {
    dispatch({ type: OFFICIALS_RELOAD_LIST });
  };
};

// Follow public official
export const followDistrictOfficial = (officialId, single = false) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let userId = getState().account.user.userId;
      let requestData = { userId: userId, followerId: officialId };
      dispatch({ type: SET_OFFICIALS_LOADING, payload: true });
      await axios
        .post(BASE_API_URL + '/users/follow', requestData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            let officials = getState().map.officials;
            let official;
            if (Object.keys(officials).length > 0)
              Object.keys(officials).forEach((level) => {
                if (!official)
                  official = officials[level].find((item) => {
                    return (
                      item.isRegistered &&
                      item.officialUser.userId === officialId
                    );
                  });
              });
            let formData = {
              level: official.division_three,
              officialId: official.officialInfoId,
              values: { userFollow: !official.userFollow },
            };
            dispatch({ type: OFFICIALS_LIST_CHANGE, payload: formData });
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'map',
            }),
          );
          dispatch({ type: SET_OFFICIALS_LOADING, payload: false });
          return reject(error.response.data.error);
        })
        .finally(() => {
          dispatch({ type: SET_OFFICIALS_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const setCurrentLayer = (featureProps) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch(civicMapChange({ currentLayer: featureProps }));

      // Wait for all requests, and then setState
      return resolve(true);
    });
  };
};
