import React, { useState } from 'react';
import Slider from 'react-slick';
import { Modal } from 'react-bootstrap';

export const handleDocumentDownload = (src, isSwiping = false) => {
  if (src && !isSwiping) {
    const fileName = src.split("/").pop();

    // Create XMLHTTP Request.
    const req = new XMLHttpRequest();

    req.open("GET", src, true);
    req.responseType = "blob";
    req.onload = function () {
      // Convert the Byte Data to BLOB object.
      let blob = new Blob([req.response], {type: "application/octetstream"});

      // Check the Browser type and download the File.
      let isIE = !!document.documentMode;

      if (isIE) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);
        let a = document.createElement("a");

        a.setAttribute("download", fileName);
        a.setAttribute("href", link);

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }
};

const DocumentItem = ({ item, handleShowImageModal, isSwiping }) => {
  return (item.endsWith('.pdf') ?
    <div>
      <div className="slick-slider-content">
        <div className="slick-slider-hover-overlay" onClick={() => handleDocumentDownload(item, isSwiping)}>
          <img src={require('../../../assets/img/hover-overlay.svg').default} alt="Hover overlay image"/>
        </div>
        <div
            className="slick-slider-content-attachment-document slick-slider-content-attachment-document-pdf">PDF
        </div>
      </div>
    </div>
    : item.endsWith('.doc') || item.endsWith('.docx') ?
      <div>
        <div className="slick-slider-content">
          <div className="slick-slider-hover-overlay" onClick={() => handleDocumentDownload(item, isSwiping)}>
            <img src={require('../../../assets/img/hover-overlay.svg').default} alt="Hover overlay image"/>
          </div>
          <div className="slick-slider-content-attachment-document slick-slider-content-attachment-document-doc">DOC</div>
        </div>
      </div>
      : item.endsWith('.xls') || item.endsWith('.xlsx') ?
        <div>
          <div className="slick-slider-content">
            <div className="slick-slider-hover-overlay" onClick={() => handleDocumentDownload(item, isSwiping)}>
              <img src={require('../../../assets/img/hover-overlay.svg').default} alt="Hover overlay image"/>
            </div>
            <div className="slick-slider-content-attachment-document slick-slider-content-attachment-document-xls">XLS</div>
          </div>
        </div>
        : <div>
          <div className="slick-slider-content">
            <div className="slick-slider-hover-overlay" onClick={() => { if (handleShowImageModal) handleShowImageModal(item) } }>
              <img src={require('../../../assets/img/hover-overlay.svg').default} alt="Hover overlay image"/>
            </div>
            <img src={item} alt=""/>
          </div>
        </div>
  );
}

const CommunityIssueSlider = ({attachedDocuments}) => {
  const [currentModalImage, setCurrentModalImage] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  if (!attachedDocuments) {
    return null;
  }
  let isSwiping = false;

  function updateSwipeState(state) {
    isSwiping = state;
  }

  const handleShowImageModal = (src) => {
    if (src && !isSwiping) {
      setCurrentModalImage(src);
      setIsImageModalOpen(true);
    }
  };

  const handleHideImageModal = () => {
    if (!isSwiping) {
      setCurrentModalImage('');
      setIsImageModalOpen(false);
    }
  };

  return (
    <>
      <Slider
        arrows={false}
        draggable={true}
        dots={false}
        infinite={false}
        speed={500}
        // slidesToShow={4}
        slidesToShow={2}
        // lazyLoad={true}
        swipeToSlide={true}
        swipe={true}
        className={["community-issue-slider", "community-issue-slider-single-page"]}
        variableWidth
        style={{ width: 'auto' }}
        afterChange={() => updateSwipeState(false)}
        onSwipe={() => updateSwipeState(true)}
    >
      {attachedDocuments.map(item => (
        <DocumentItem item={item} handleShowImageModal={handleShowImageModal} isSwiping={isSwiping} />
      ))}
      </Slider>
      <Modal
        show={isImageModalOpen}
        onHide={handleHideImageModal}
        centered
      >
        <Modal.Header closeButton={true}/>
        <Modal.Body style={{textAlign: 'center'}}>
          {currentModalImage.length > 0 && (currentModalImage.includes('http') || currentModalImage.includes('https')) ? <>
            <img style={{maxWidth: '100%'}} src={currentModalImage} alt=""/>
          </> : <>
            <img style={{maxWidth: '100%'}} src={`${currentModalImage}`} alt=""/>
          </>}
        </Modal.Body>
      </Modal>
    </>
  )
}

export { DocumentItem };
export default CommunityIssueSlider;