/**
 *
 * Dashboard Left Container
 *
 */

import React from 'react';
import NavLinks from '../../Common/NavLinks/NavLinks';
import CivicModal from '../../Common/CivicModal';
import ReportAnIssue from './report-an-issue';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { useTranslation } from 'react-i18next';

const LeftSidebar = (props) => {
  const hashUrl = '#';
  const { reportFormData, user } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="clsdashboard-left sticky-top">
        <NavLinks {...props} />
        {typeof user.roleName !== 'undefined' ? (
          <div className="clsdashboard-footer-desktop mt-4">
            <p className="footer-text mb-2">
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target="#report-an-issue"
              >
                {' '}
                <img
                  src={require('../../../assets/img/issue.svg').default}
                  alt="Issue"
                />
                {t(`Report an Issue`)}
              </a>{' '}
              © {new Date().getFullYear()} {t(`CivicBell LLC.`)}
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`report-an-issue`}
          title={t(`Report an Issue`)}
          dismissal={reportFormData.dismissal}
          footerClass={`text-center mt-4`}
        >
          <ReportAnIssue {...props} />
        </CivicModal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    reportFormErrors: state.account.reportFormErrors,
    reportFormData: state.account.reportFormData,
    isReportLoading: state.account.isReportLoading,
    isReportSubmitting: state.account.isReportSubmitting,
  };
};

export default connect(mapStateToProps, actions)(LeftSidebar);
