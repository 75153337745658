/*
 *
 * My Petition
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import MyPetitionList from '../../components/Manager/Petitions/myPetition';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Notification from '../Notification';
import { useTranslation } from 'react-i18next';

const MyPetition = (props) => {
  const {
    activePetitionTab,
    activeMyPetitionTab,
    setPetitionTab,
    setMyPetitionTab,
    getMyPetitionLists,
    myPetitions,
    noRecords,
    reloadList,
    resetResult,
    searchSubmission,
    reloadPetition,
    searchResultReset,
    petitionChangeByOption,
    isPetitionLoading,
    user,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [petitionListComponent, setPetitionListComponent] = useState('');

  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const resetTabContent = (tab) => {
    setLoading(true);
    setInitialLoad(true);
    setPage(1);
    setMyPetitionTab(tab);
  };

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setPetitionListComponent(
          <Component
            {...props}
            petitionChangeByOption={petitionChangeByOption}
          />,
        );
        return resolve(true);
      });
    },
    [setPetitionListComponent, props, petitionChangeByOption],
  );

  const fetchPetitions = useCallback(async () => {
    setInProcess(true);
    await getMyPetitionLists(page, 2).then(async () => {
      await renderComponent(MyPetitionList).then(() => {
        setPage(page + 1);
      });
    });
    setInProcess(false);
  }, [getMyPetitionLists, renderComponent, setPage, page]);

  useEffect(() => {
    if (loading === true) {
      setPetitionListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        const fetchPetitions = async () => {
          await getMyPetitionLists().then(() => {
            setLoading(false);
            setInProcess(false);
          });
        };
        fetchPetitions();
      }
    }
  }, [
    loading,
    setLoading,
    setPetitionTab,
    activePetitionTab,
    getMyPetitionLists,
    renderComponent,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    myPetitions,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(MyPetitionList);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setPage, setLoading, setInitialLoad]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await reloadPetition();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, reloadPetition],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    renderComponent(MyPetitionList);
  }, [user, renderComponent]);

  const MyPetitionListContent = (
    <>
      {isPetitionLoading === true ? (
        <LoadingIndicator position={`fixed`} />
      ) : (
        ''
      )}
      <Notification module={`petition`} />
      {petitionListComponent}
      {page === 2 && !noRecords ? (
        <div style={{ minHeight: '200px' }}></div>
      ) : (
        ''
      )}
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={myPetitions.length}
            next={fetchPetitions}
            hasMore={!noRecords}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
        </>
      )}
    </>
  );

  return (
    <>
      <div className="clsall-events-tab">
        <ul className="nav nav-tabs mb-4" id="myPetitionsTab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className={
                activeMyPetitionTab === 'created-petitions'
                  ? 'nav-link active'
                  : 'nav-link'
              }
              id="created-petitions-tab"
              data-toggle="tab"
              href="#created-petitions"
              role="tab"
              aria-controls="created-petitions"
              onClick={() => {
                resetTabContent('created-petitions');
              }}
              aria-selected={
                activeMyPetitionTab === 'created-petitions' ? 'true' : 'false'
              }
            >
              {t('Created Initatives')}
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className={
                activeMyPetitionTab === 'signed-petitions'
                  ? 'nav-link active'
                  : 'nav-link'
              }
              id="signed-petitions-tab"
              data-toggle="tab"
              href="#signed-petitions"
              role="tab"
              aria-controls="signed-petitions"
              onClick={() => {
                resetTabContent('signed-petitions');
              }}
              aria-selected={
                activeMyPetitionTab === 'signed-petitions' ? 'true' : 'false'
              }
            >
              {t('Signed Initatives')}
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myPetitionsTabContent">
          <div
            className={
              activeMyPetitionTab === 'created-petitions'
                ? 'tab-pane fade show active'
                : 'tab-pane fade'
            }
            id="created-petitions"
            role="tabpanel"
            aria-labelledby="created-petitions-tab"
          >
            {activeMyPetitionTab === 'created-petitions'
              ? MyPetitionListContent
              : ''}
          </div>

          <div
            className={
              activeMyPetitionTab === 'signed-petitions'
                ? 'tab-pane fade show active'
                : 'tab-pane fade'
            }
            id="signed-petitions"
            role="tabpanel"
            aria-labelledby="signed-petitions-tab"
          >
            {activeMyPetitionTab === 'signed-petitions'
              ? MyPetitionListContent
              : ''}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPetition;
