/**
 *
 * Header
 *
 */

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import logo from '../../../assets/img/logo.svg';
import { CivicIcons } from '../CivicIcons';
import actions from '../../../actions';
import SearchBox from '../../../containers/Search';
import { profileImg } from '../../../utils/helper';
import CivicModal from '../../Common/CivicModal';
import NotificationFilters from './notificationFilters';
import $ from 'jquery';
import CountDownTimer from './countDownTimer';
import LanguageSelect from '../LanguageSelect';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../Common/Tooltip';
import StatusMessage from '../../Common/StatusMessage';
import Login from "../../Manager/RegisterFlow/login";
import { useUserModals } from '../../../utils/useUserModals';

const Header = (props) => {
  const {
    authenticated,
    pages,
    signOut,
    user,
    createInitialImg,
    disableFirstLogin,
    notificationUnReadList,
    getStatusMesages,
    statusMessages,
    notificationCount,
    registration,
    setRegistrationModalOpen,
    setIsSignUpButtonClicked,
    setLoginModalOpen
  } = props;

  const { t } = useTranslation();

  const history = useHistory();
  const hashUrl = '#';
  const [profileImgStatus, setProfileImgStatus] = useState(false);
  const [statusMsgUpdated, setStatusMsgUpdated] = useState(false);
  const [userDistricts, setUserDistricts] = useState([]);
  const location = useLocation();

  useUserModals();

  const navigateTo = (url) => {
    if (url === 'dashboard') {
      history.push('/dashboard/civicfeed/');
    } else if (url === 'profile') {
      history.push('/dashboard/profile');
    } else if (url === 'setting') {
      history.push('/dashboard/setting');
    } else if (url === 'notification') {
      history.push('/dashboard/pushnotification');
    }
  };

  const collapseRef = useRef(null);

  const hideBars = () => {
    collapseRef.current.setAttribute('class', 'navbar-collapse collapse');
  };

  useEffect(() => {
    if (profileImgStatus === false && authenticated && typeof user.userId !== 'undefined'
        && (user.profile_picture === null || user.profile_picture === '')) {
      createInitialImg();
      setProfileImgStatus(true);
    }
  }, [
    authenticated,
    user,
    profileImgStatus,
    setProfileImgStatus,
    createInitialImg,
  ]);

  useEffect(() => {
    if (statusMsgUpdated === false) {
      getStatusMesages();
      setStatusMsgUpdated(true);
    }
  }, [statusMsgUpdated, setStatusMsgUpdated, getStatusMesages]);

  useEffect(() => {
    setUserDistricts(JSON.parse(localStorage.getItem('user_districts')))

    if (
      authenticated &&
      typeof user.userId !== 'undefined' &&
      typeof user.isFirstLogin !== 'undefined'
    ) {
      if (
          registration.registerFormData.registrationFinished === true &&
          userDistricts &&
          userDistricts?.length > 0 &&
          // user.isFirstLogin === true &&
          user.roleName !== 'PUBLICOFFICIAL' &&
          user.roleName !== 'ADMIN'
      ) {
        $('#first-login-modal').modal({
          backdrop: 'static',
          keyboard: false,
        });
      }
    }
  }, [authenticated, user.userId, user.isFirstLogin, user.roleName, registration]);

  const closeFirstLogin = () => {
    if ($('#first-login-modal')) {
      $('#first-login-modal').modal('hide');
      localStorage.removeItem('user_districts')
      disableFirstLogin();
    }
  };

  return location.pathname === '/dashboard/initiative/' &&
    user.roleName === undefined ? (
    ''
  ) : !authenticated &&
    location.pathname !== '/dashboard/user-profile/' &&
    location.pathname !== '/dashboard/civicmap/' &&
    location.pathname !== '/dashboard/civicmap' &&
    location.pathname !== '/dashboard/civicfeed' &&
    location.pathname !== '/dashboard/civicfeed/' &&
    location.pathname !== '/dashboard/localnews' &&
    location.pathname !== '/dashboard/localnews/' &&
    location.pathname !== '/dashboard/engagement/' &&
    !(location.pathname.includes('/community-issues')) &&
    location.pathname !== '/dashboard/engagement' ? (
    <>
      <div className="clsdashboard-header">
        <CivicModal
            id={`first-login-modal`}
            title={``}
            dismissal={false}
            modalDialogueClass={`firstLoginModal`}
            hideClose={true}
        >
          {user.isGeoidMapped === true ? (
              <>
                <p className={`text-center firstLoginTxt`}>
                  {t(
                      `Welcome to CivicBell, It looks like you are part of the following districts:`,
                  )}
                </p>
                <ul className={`district-list mt-2`}>
                  {userDistricts && typeof userDistricts !== 'undefined' &&
                  userDistricts.map((district, index) => (
                      <li key={index}>{district.name}</li>
                  ))}
                </ul>
                <button
                    type="button"
                    className="mt-4 btn clsyellow-btn firstLoginBtn"
                    onClick={(e) => {
                      closeFirstLogin();
                    }}
                >
                  {t(`Start engaging`)}
                </button>
              </>
          ) : (
              <>
                <p className="mt-n4">
                  {t(`Unfortunately, we couldn\'t map your districts at this point.`)}{' '}
                  <a
                      href="http://civicl.ink/nodistrictfound"
                      target="_blank"
                      alt="Link to Helpcenter"
                      rel="noopener noreferrer"
                  >
                    {t('This could have a variety of reasons.')}
                  </a>
                </p>
                <p>
                  {
                    t(`In most cases we can manually map your districts within 24h. You will receive an email once this is done.`)
                  }
                </p>
                <button
                    type="button"
                    className="mt-4 btn clsyellow-btn firstLoginBtn"
                    onClick={(e) => {
                      closeFirstLogin();
                    }}
                >
                  {t(`Okay`)}
                </button>
              </>
          )}
        </CivicModal>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container">
            <div className={`civic-filter-modal`}>
              <CivicModal
                  id={`notification-model`}
                  title="Notification"
                  footerClass={`text-center mt-4`}
              >
                <NotificationFilters />
              </CivicModal>
            </div>
            <a
                className="navbar-brand"
                href={hashUrl}
                onClick={() => {
                  navigateTo('dashboard');
                }}
            >
              <img
                  src={require('../../../assets/img/logo-horizontal.svg').default}
                  alt="Civic Bell"
                  title="Civic Bell"
              />
            </a>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarScroll"
                aria-controls="navbarScroll"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"/>
            </button>
            <div className="clsheader-desktop-menus ml-auto">
              <ul>
                <ul>
                  <LanguageSelect user={user} authenticated={authenticated} />
                </ul>
                <li>
                  <div className="clsback-link">
                    <a
                        href="#"
                        id="login-modal-button"
                        data-toggle="modal"
                        data-target="#login-modal"
                        onClick={() => setLoginModalOpen(true)}
                    >
                      {t(`Login`)}
                    </a>
                  </div>
                </li>
                <li>
                  <button
                      type="button"
                      className="btn clsyellow-btn"
                      id="registration-modal-button"
                      onClick={() => {setIsSignUpButtonClicked(true); setRegistrationModalOpen(true);}}
                  >
                    {t(`Sign up`)}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Login />
        {authenticated && <button
            type="button"
            id="confirm-existing-user-modal-button"
            data-toggle="modal"
            data-target="#confirm-existing-user-modal"
            style={{display: 'none'}}
        />}
        {authenticated && <button
            type="button"
            className="btn clsyellow-btn"
            id="registration-modal-button"
            data-toggle="modal"
            data-target="#registration-modal"
            style={{display: 'none'}}
        >
          {t(`Sign up`)}
        </button>}
      </div>
    </>
  ) : (
    <div className="clsdashboard-header">
      <CivicModal
        id={`first-login-modal`}
        title={``}
        dismissal={false}
        modalDialogueClass={`firstLoginModal`}
        hideClose={true}
      >
        {user.isGeoidMapped === true ? (
          <>
            <p className={`text-center firstLoginTxt`}>
              {t(
                `Welcome to CivicBell, It looks like you are part of the following districts:`,
              )}
            </p>
            <ul className={`district-list mt-2`}>
              {userDistricts && typeof userDistricts !== 'undefined' &&
              userDistricts.map((district, index) => (
                  <li key={index}>{district.name}</li>
                ))}
            </ul>
            <button
              type="button"
              className="mt-4 btn clsyellow-btn firstLoginBtn"
              onClick={(e) => {
                closeFirstLogin();
              }}
            >
              {t(`Start engaging`)}
            </button>
          </>
        ) : (
          <>
            <p className="mt-n4">
              {t(`Unfortunately, we couldn't map your districts at this point.`)}{' '}
              <a
                href="http://civicl.ink/nodistrictfound"
                target="_blank"
                alt="Link to Helpcenter"
                rel="noopener noreferrer"
              >
                {t(`This could have a variety of reasons.`)}
              </a>
            </p>
            <p>
              {t(`In most cases we can manually map your districts within 24h. You will receive an email once this is done.`)}
            </p>
            <button
              type="button"
              className="mt-4 btn clsyellow-btn firstLoginBtn"
              onClick={(e) => {
                closeFirstLogin();
              }}
            >
              {t(`Okay`)}
            </button>
          </>
        )}
      </CivicModal>
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container">
          <div className={`civic-filter-modal`}>
            <CivicModal
              id={`notification-model`}
              title="Notification"
              footerClass={`text-center mt-4`}
            >
              <NotificationFilters />
            </CivicModal>
          </div>
          <a
            className="navbar-brand"
            href={hashUrl}
            onClick={() => {
              navigateTo('dashboard');
            }}
          >
            <img
              src={require('../../../assets/img/logo-horizontal.svg').default}
              alt="Civic Bell"
              title="Civic Bell"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="clsheader-desktop-menus ml-auto">
            {typeof user.roleName !== 'undefined' ? (
              <ul>
                <li>
                  <SearchBox />
                </li>
                {/** <li>
                                 <a href={hashUrl}><img src={ require('../../../assets/img/messages.svg').default} alt="Messages" /></a>
                             </li> **/}
                <li>
                  {notificationUnReadList.length > 0 ? (
                    <a
                      href={hashUrl}
                      onClick={() => {
                        navigateTo('notification');
                      }}
                      className="clsnew-notifications"
                    >
                      <img
                        src={require('../../../assets/img/bell.svg').default}
                        alt="Notifications"
                      />{' '}
                      <span className="clsstatus">
                        {notificationCount > 10 ? '10+' : notificationCount}
                      </span>
                    </a>
                  ) : (
                    <a
                      href={hashUrl}
                      onClick={() => {
                        navigateTo('notification');
                      }}
                    >
                      <img
                        src={require('../../../assets/img/bell.svg').default}
                        alt="Notifications"
                      />{' '}
                      <span className="clsstatus"></span>
                    </a>
                  )}
                </li>
                <LanguageSelect user={user} authenticated={authenticated} />
                <li className="nav-item dropdown clsavatar-dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href={hashUrl}
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {typeof user.profile_picture !== 'undefined' ? (
                      user.profile_picture !== '' &&
                      user.profile_picture !== null ? (
                        <img
                          src={profileImg(user.profile_picture)}
                          alt="Avatar"
                          className={`header-profile-img`}
                        />
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    {typeof user.show_my_name !== 'undefined' &&
                    user.show_my_name.length > 25 ? (
                      <Tooltip
                        targetId={'usernameTooltip'}
                        parentDivClass={`username-tooltip`}
                        iconDisplay={false}
                        labelTxt={`${user.show_my_name.slice(0, 24)}...`}
                        placement={`left`}
                        message={user.show_my_name}
                      />
                    ) : (
                      user.show_my_name
                    )}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      className="dropdown-item"
                      href={hashUrl}
                      onClick={() => {
                        navigateTo('profile');
                      }}
                    >
                      {t(`My Profile`)}
                    </a>
                    <a
                      className="dropdown-item"
                      href={hashUrl}
                      onClick={() => {
                        navigateTo('setting');
                      }}
                    >
                      {t(`Settings`)}
                    </a>
                    <a
                      className="dropdown-item"
                      href={hashUrl}
                      onClick={() => {
                        signOut();
                      }}
                    >
                      {t(`Logout`)}
                    </a>
                  </div>
                </li>
              </ul>
            ) : (
              <ul>
                <ul>
                  <LanguageSelect user={user} authenticated={authenticated} />
                </ul>
                <li>
                  <div className="clsback-link">
                    <a
                        href="#"
                      // href={hashUrl}
                      // onClick={(e) => {
                      //   history.push('/login');
                      //   e.preventDefault();
                      // }}
                        id="login-modal-button"
                        data-toggle="modal"
                        data-target="#login-modal"
                        onClick={() => setLoginModalOpen(true)}
                    >
                      {t(`Login`)}
                    </a>
                  </div>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn clsyellow-btn"
                    id="registration-modal-button"
                    onClick={() => {setIsSignUpButtonClicked(true); setRegistrationModalOpen(true);}}
                  >
                    {t(`Sign up`)}
                  </button>
                </li>
              </ul>
            )}
          </div>
          {typeof user.roleName !== 'undefined' &&
          typeof pages !== 'undefined' ? (
            <div
              className="collapse navbar-collapse"
              id="navbarScroll"
              ref={collapseRef}
            >
              <ul
                className="navbar-nav ml-auto my-2 my-lg-0 navbar-nav-scroll"
                style={{ maxHeight: '100px' }}
              >
                {pages[user.roleName].map((link, index) =>
                  link.navBar === true ? (
                    <li className="nav-item active" key={index}>
                      {link.type === 'page' ? (
                        <NavLink
                          to={link.to}
                          onClick={hideBars}
                          activeClassName={
                            link.name === 'Local News'
                              ? 'clslocal-news-icon active'
                              : link.name === 'Invite Others'
                              ? 'clsinvite-icon active'
                              : 'active'
                          }
                          className={'nav-link'}
                          exact
                        >
                          {CivicIcons[link.icon]}
                          <span>{t(link.name)}</span>
                        </NavLink>
                      ) : (
                        <NavLink
                          to={{ pathname: link.to }}
                          target="_blank"
                          activeClassName={
                            link.name === 'Local News'
                              ? 'clslocal-news-icon active'
                              : link.name === 'Invite Others'
                              ? 'clsinvite-icon active'
                              : 'active'
                          }
                          className={'nav-link'}
                        >
                          {CivicIcons[link.icon]}
                          <span>{t(link.name)}</span>
                        </NavLink>
                      )}
                    </li>
                  ) : (
                    ''
                  ),
                )}
              </ul>
            </div>
          ) : (
              <div
                  className="collapse navbar-collapse"
                  id="navbarScroll"
                  ref={collapseRef}
              >
                <ul
                    className="navbar-nav ml-auto my-2 my-lg-0 navbar-nav-scroll"
                    style={{ maxHeight: '100px' }}
                >
                  {pages['GUEST'].map((link, index) =>
                      link.navBar === true ? (
                          <li className="nav-item active" key={index}>
                            {link.type === 'page' ? (
                                <NavLink
                                    to={link.to}
                                    onClick={hideBars}
                                    activeClassName={
                                      link.name === 'Local News'
                                          ? 'clslocal-news-icon active'
                                          : link.name === 'Invite Others'
                                              ? 'clsinvite-icon active'
                                              : 'active'
                                    }
                                    className={'nav-link'}
                                    exact
                                >
                                  {CivicIcons[link.icon]}
                                  <span>{t(link.name)}</span>
                                </NavLink>
                            ) : (
                                <NavLink
                                    to={{ pathname: link.to }}
                                    target="_blank"
                                    activeClassName={
                                      link.name === 'Local News'
                                          ? 'clslocal-news-icon active'
                                          : link.name === 'Invite Others'
                                              ? 'clsinvite-icon active'
                                              : 'active'
                                    }
                                    className={'nav-link'}
                                >
                                  {CivicIcons[link.icon]}
                                  <span>{t(link.name)}</span>
                                </NavLink>
                            )}
                          </li>
                      ) : (
                          ''
                      ),
                  )}
                </ul>
              </div>
          )}
        </div>
      </nav>
      <Login />
      {authenticated && <button
          type="button"
          id="confirm-existing-user-modal-button"
          data-toggle="modal"
          data-target="#confirm-existing-user-modal"
          style={{display: 'none'}}
      />}
      {authenticated && <button
          type="button"
          className="btn clsyellow-btn"
          id="registration-modal-button"
          data-toggle="modal"
          data-target="#registration-modal"
          style={{display: 'none'}}
      >
        {t(`Sign up`)}
      </button>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    user: state.account.user,
    blocks: state.account.blocks,
    pages: state.account.pages,
    notificationUnReadList: state.pushnotification.notificationUnReadList,
    statusMessages: state.application.statusMessages,
    notificationCount: state.pushnotification.notificationUnReadList.length,
    registration: state.registration,
    isRegisterSubmitting: state.registration.isRegisterSubmitting
  };
};

export default connect(mapStateToProps, actions)(Header);
