/*
 *
 * Verification Process
 *
 */

import React from 'react';
import VerifyStep from './verifyStep';
import IDmeButton from '../../Common/IDme';
import { useTranslation } from 'react-i18next';

const VerifyUser = (props) => {
  const { redirectToLogin } = props;
  const { t } = useTranslation();
  const hashUrl = '#';

  return (
    <>
      <VerifyStep step={2} />
      <p className="pb-5 verify-text">
        {t(
          `Ensuring that all users on the platform are validated and legitimate is very important for democratic engagement. Please verify your profile so you can engage with your public officials.`,
        )}
      </p>
      {/*<h3 className="verify-with mb-3">Verify with</h3>*/}
      <IDmeButton />
      <p className="clsverify-later-text text-center mt-4 pt-1">
        <a
          href={hashUrl}
          onClick={(e) => {
            redirectToLogin();
            e.preventDefault();
          }}
          id="regevent_step3_verifylater"
        >
          {t(`Verify Later`)}
        </a>
      </p>
    </>
  );
};

export default VerifyUser;
