/**
 *
 * Dashboard
 *
 */

import React, { useEffect, useState } from 'react';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import AccountPanel from './AccountPanel';
import Notification from '../../../containers/Notification';
import InactiveMessage from '../../../components/Common/InactiveMessage';
import {connect} from "react-redux";
import actions from "../../../actions";
import { isMobile} from "react-device-detect";

const Dashboard = (props) => {
  const { pages, user, location, getInactiveStatusMesages, authenticated } = props;
  const [leftSideBar, setLeftSideBar] = useState(false);
  const [rightSideBar, setRightSideBar] = useState(false);
  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const [inactiveStatusMsgUpdated, setInactiveStatusMsgUpdated] =
    useState(false);

  useEffect(() => {
    if (typeof user.roleName !== 'undefined') {
      pages[user.roleName].forEach((link, index) => {
        if ('/' + uri === link.to) {
          setLeftSideBar(link.leftPanel);
          setRightSideBar(link.rightPanel);
        }
      });
    }
  }, [user.roleName, pages, uri, setLeftSideBar, setRightSideBar]);

  useEffect(() => {
    if (inactiveStatusMsgUpdated === false) {
      getInactiveStatusMesages();
      setInactiveStatusMsgUpdated(true);
    }
  }, [
    inactiveStatusMsgUpdated,
    setInactiveStatusMsgUpdated,
    getInactiveStatusMesages,
  ]);

  return (
    <div className="clsdashboard-main-container">
      <div className="container">
        {leftSideBar === true ? <LeftSidebar {...props} /> : ''}
        <div className="clsdashboard-right-content-blk">
          <div
            className={
              rightSideBar === false
                ? `clsdashboard-right-common-blk`
                : `clsdashboard-content-blk`
            }
          >
            <Notification module={'dashboard'} />
            {
              location.pathname === '/dashboard/civicfeed' || location.pathname === '/dashboard/civicfeed/' ?
                <InactiveMessage {...props} /> : null
            }
            <AccountPanel {...props} />
          </div>
          {rightSideBar === true ?
              (isMobile && authenticated ?
                  <div className="d-none"><RightSidebar {...props}/></div> :
                  <RightSidebar {...props}/>) : ''
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated
  };
};

export default connect(mapStateToProps, actions)(Dashboard);
