/**
 *
 * Comment Emotions (Like, Dislike, Comment Count)
 *
 */

import React from 'react';
import CommentBox from '../DiscussionEngagement/commentBox';
import Tooltip from '../../Common/Tooltip';
import { useTranslation } from 'react-i18next';
import { daysDiffTxt } from '../../../utils/helper';

const CommentEmotions = (props) => {
  const {
    id,
    commentId,
    likes,
    disLikes,
    comments,
    commentBoxStatus,
    commentData,
    changePostComment,
    submitComment,
    setReactions,
    user,
    canReact,
    restrictionReason,
    isChildComment,
    authenticated,
  } = props;
  const { t } = useTranslation();

  const hashUrl = '#';
  const isIconDisabled = (!user.isVerified || !user.isIdentityVerified || !user.isRegistrationComplete) && authenticated;

  return (
    <>
      {typeof commentId !== 'undefined' && (
        <div className={`comment-footer ${!authenticated && 'blur'}`}>
          <div className={`clsdashboard-actions-left`}>
            <ul>
              <li>
                <a
                  href={authenticated && hashUrl}
                  data-toggle="modal"
                  id={`comment-like-${commentId}`}
                  onClick={() => {
                    if (canReact === true && !isIconDisabled) {
                      setReactions(id, commentId, 'Like');
                    }
                  }}
                  className={`${!authenticated && 'no-pointer'}`}
                >
                  <span>
                    {isIconDisabled ? ( 
                      <img
                        src={
                          require('../../../assets/img/approve-disable.svg')
                            .default
                        }
                        alt="Approved"
                        key={`Approved`}
                      />
                    ) : (
                      [
                        commentData.userLikeReaction === true ? (
                          <img
                            src={
                              require('../../../assets/img/approve-active.svg')
                                .default
                            }
                            alt="Approved"
                            key={`Approved`}
                          />
                        ) : (
                          <img
                            src={
                              require('../../../assets/img/approve.svg')
                                .default
                            }
                            alt="Approve"
                            key={`Approve`}
                          />
                        ),
                      ]
                    )}
                  </span>
                  <span>{authenticated ? (likes ?? 0) : Math.floor(Math.random() * 10)}</span>
                </a>
                {canReact === false && authenticated && (
                  <Tooltip
                    targetId={`comment-like-${commentId}`}
                    targetStatus={true}
                    styleClass={'react-tooltip'}
                    message={t(restrictionReason)}
                  />
                )}
              </li>
              <li>
                <a
                  href={authenticated && hashUrl}
                  id={`comment-dislike-${commentId}`}
                  onClick={(e) => {
                    if (canReact === true && !isIconDisabled) {
                      setReactions(id, commentId, 'Dislike');
                    }
                    e.preventDefault();
                  }}
                  className={`${!authenticated && 'no-pointer'}`}
                >
                  <span>
                    {isIconDisabled ? ( 
                      <img
                        src={
                          require('../../../assets/img/disapprove-disable.svg')
                            .default
                        }
                        alt="Disapproved"
                        key={`Disapproved`}
                      />
                    ) : (
                      [
                        commentData.userDisLikeReaction === true ? (
                          <img
                            src={
                              require('../../../assets/img/disapproved.svg')
                                .default
                            }
                            alt="Disapprove"
                            key={`Disapprove`}
                          />
                        ) : (
                          <img
                            src={
                              require('../../../assets/img/disapprove.svg')
                                .default
                            }
                            alt="Disapprove"
                            key={`Disapprove`}
                          />
                        ),
                      ]
                    )}
                  </span>{' '}
                  <span>{authenticated ? (disLikes ?? 0) : Math.floor(Math.random() * 10)}</span>
                </a>
                {canReact === false && authenticated && (
                  <Tooltip
                    targetId={`comment-dislike-${commentId}`}
                    targetStatus={true}
                    styleClass={'react-tooltip'}
                    message={t(restrictionReason)}
                  />
                )}
              </li>
              <li>
                {commentBoxStatus === false ? (
                  ''
                ) : (
                  <>
                    <a
                      href={authenticated && `#comment-box-${commentId}`}
                      data-target={`#comment-box-${commentId}`}
                      data-toggle="collapse"
                      className={`nav-toggle ${!authenticated && 'no-pointer'}`}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      id={`comment-icon-${commentId}`}
                    >
                      <span>
                        {typeof user.roleName !== 'undefined' && !isIconDisabled || !authenticated ? (
                          <img
                            src={
                              require('../../../assets/img/comment_icon.svg')
                                .default
                            }
                            alt="Comment"
                          />
                        ) : (
                          <img
                            src={
                              require('../../../assets/img/comment-disable.svg')
                                .default
                            }
                            alt="Comment"
                          />
                        )}
                      </span>
                      <span>{(authenticated ? (comments ?? 0) : Math.floor(Math.random() * 10))}</span>
                    </a>
                  </>
                )}
              </li>
            </ul>
          </div>
          <div className={`comment-date`}>
            <p>
              {daysDiffTxt(commentData.createdAt, t)}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

CommentEmotions.defaultProps = {
  likes: 0,
  disLikes: 0,
  comments: 0,
  commentBoxStatus: false,
};

export default React.memo(CommentEmotions);
