/*
 *
 * ForgotPassword actions
 *
 */

import axios from 'axios';
import qs from 'qs';
import { BASE_API_URL } from '../../constants';
import { addNotification } from '../Notification/actions';

import {
  FORGOT_PASSWORD_CHANGE,
  FORGOT_PASSWORD_RESET,
  SET_FORGOT_PASSWORD_FORM_ERRORS,
  SET_FORGOT_PASSWORD_SUBMITTING,
  RESET_APP,
} from './constants';
import { allFieldsValidation } from '../../utils/validation';

export const resetForgetPasswordState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const forgotPasswordChange = (name, value) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_FORGOT_PASSWORD_FORM_ERRORS, payload: {} });
    dispatch({ type: FORGOT_PASSWORD_CHANGE, payload: value });
  }
};

export const forgotPassword = () => {
  return async (dispatch, getState) => {
    const rules = {
      email: 'required|email',
    };

    let user = getState().forgotPassword.forgotFormData;

    const { isValid, errors } = allFieldsValidation(user, rules, {
      'required.email': 'Email is required.',
    });

    if (!isValid) {
      return dispatch({
        type: SET_FORGOT_PASSWORD_FORM_ERRORS,
        payload: errors,
      });
    }
    dispatch({ type: SET_FORGOT_PASSWORD_FORM_ERRORS, payload: {} });
    dispatch({ type: SET_FORGOT_PASSWORD_SUBMITTING, payload: true });
    user = { ...user, email: user.email.toLowerCase() };

    await axios
      .post(BASE_API_URL + '/forgot-password', qs.stringify(user), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic ZGV2Y2xpZW50OmRldnNlY3JldA==',
        },
      })
      .then((response) => {
        if (response.data.success === 'true') {
          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'forgotPassword',
            }),
          );
        }
        dispatch({ type: FORGOT_PASSWORD_RESET });
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'forgotPassword',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_FORGOT_PASSWORD_SUBMITTING, payload: false });
      });
  };
};
