/**
 *
 * Dashboard
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import GuestDashboard from '../../components/Manager/Dashboard/guestDashboard';

const DashboardView = (props) => {
  return (
    <>
      <GuestDashboard {...props} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    blocks: state.account.blocks,
    pages: state.account.pages,
    isLoading: state.account.isLoading,
  };
};

export default connect(mapStateToProps, actions)(DashboardView);
