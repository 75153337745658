/*
 *
 * Complete engagement actions
 *
 */

import {
  COMPLETE_ENGAGEMENT_CHANGE,
  SET_COMPLETE_ENGAGEMENT_LOADING,
  SET_COMPLETE_ENGAGEMENT_FORM_ERRORS,
  SET_COMPLETE_ENGAGEMENT_SUBMITTING,
  SET_COMPLETE_ENGAGEMENT_DATA,
  RESET_APP,
} from './constants';
import { allFieldsValidation } from '../../utils/validation';
import {
  updateEngagementStatus,
  setReloadEngagementStatus,
} from '../Engagement/actions';

export const resetCompleteEngagementState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const completeEngagementChange = (id, name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[id] = getState().completeEngagement.completeEngagementData[id];
    formData[id][name] = value;
    if (name === 'postSelection') {
      let questions =
        getState().completeEngagement.completeEngagementData[id].question;
      let questionSelection = [];
      if (value === 'all') {
        questions.map((question, index) => {
          return questionSelection.push(question.id.toString());
        });
        formData[id]['selectedQuestionList'] = questionSelection;
      } else if (value === 'none') {
        formData[id]['selectedQuestionList'] = [];
      }
    }
    return dispatch({ type: COMPLETE_ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const questionSelectionChange = (id, value) => {
  return async (dispatch, getState) => {
    let postSelection =
      getState().completeEngagement.completeEngagementData[id].postSelection;
    if (postSelection !== 'selected') {
      return false;
    }
    let questionSelection =
      getState().completeEngagement.completeEngagementData[id]
        .selectedQuestionList;
    if (!questionSelection.includes(value)) {
      questionSelection.push(value);
    } else {
      questionSelection.splice(questionSelection.indexOf(value), 1);
    }
    dispatch(
      completeEngagementChange(id, 'selectedQuestionList', questionSelection),
    );
  };
};

export const completeCommentEditChange = (id, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[id] = getState().completeEngagement.completeEngagementData[id];
    formData[id].comment = JSON.stringify(value);
    return dispatch({
      type: COMPLETE_ENGAGEMENT_CHANGE,
      payload: formData,
    });
  };
};

export const setCompleteEngagementData = (engagement) => {
  let formData = {};
  if (engagement.category === 'survey') {
    formData[engagement.id] = {
      id: engagement.id,
      title: 'Complete Engagement',
      comment: '',
      question: engagement.survey.question,
      postSelection: 'selected',
      selectedQuestionList: [],
      dismissal: false,
      type: engagement.category,
    };
  } else {
    formData[engagement.id] = {
      id: engagement.id,
      title: 'Complete Engagement',
      comment: '',
      question: [],
      postSelection: 'selected',
      selectedQuestionList: [],
      dismissal: false,
      type: engagement.category,
    };
  }

  return {
    type: SET_COMPLETE_ENGAGEMENT_DATA,
    payload: formData,
  };
};

export const submitCompleteEngagement = (engagementId) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COMPLETE_ENGAGEMENT_FORM_ERRORS, payload: {} });
    dispatch(validateForm(engagementId))
      .then(async () => {
        let engagement =
          getState().completeEngagement.completeEngagementData[engagementId];
        engagement = { ...engagement, stage: 'closed' };
        dispatch(
          updateEngagementStatus(engagement, 'outcome', engagement.comment),
        ).then(() => {
          let formData = {};
          formData[engagementId] =
            getState().completeEngagement.completeEngagementData[engagementId];
          formData[engagementId]['dismissal'] = true;
          dispatch({ type: COMPLETE_ENGAGEMENT_CHANGE, payload: formData });
          dispatch(setReloadEngagementStatus(true, 'outcome'));
        });
      })
      .catch((error) => {
        dispatch({ type: SET_COMPLETE_ENGAGEMENT_FORM_ERRORS, payload: error });
        dispatch({ type: SET_COMPLETE_ENGAGEMENT_SUBMITTING, payload: false });
        dispatch({ type: SET_COMPLETE_ENGAGEMENT_LOADING, payload: false });
      })
      .finally(() => {
        dispatch({ type: SET_COMPLETE_ENGAGEMENT_SUBMITTING, payload: false });
        dispatch({ type: SET_COMPLETE_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const validateForm = (engagementId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let engagement =
        getState().completeEngagement.completeEngagementData[engagementId];
      let completeEngagementData = {
        comment: engagement.comment,
        selectedQuestionList: engagement.selectedQuestionList,
        postSelection: engagement.postSelection,
      };

      let errorStatus = false;
      let errorList = {};

      //Comment Validation
      let commentData = completeEngagementData.comment;
      if (commentData !== '') {
        commentData = JSON.parse(commentData);
        if (typeof commentData.blocks !== 'undefined') {
          if (commentData.blocks[0].text === '') {
            errorStatus = true;
            errorList = { ...errorList, comment: ['Required'] };
          }
          if (commentData.blocks[0].text.length > 500) {
            errorStatus = true;
            errorList = {
              ...errorList,
              comment: ['Please enter up to 500 characters'],
            };
          }
        } else {
          errorStatus = true;
          errorList = { ...errorList, comment: ['Required'] };
        }
      } else {
        errorStatus = true;
        errorList = { ...errorList, comment: ['Required'] };
      }

      if (engagement.type === 'survey') {
        let rules = {
          postSelection: 'required',
        };
        const { isValid, errors } = allFieldsValidation(
          completeEngagementData,
          rules,
          {
            'required.postSelection': 'Required',
          },
        );

        let selectedQuestionList = engagement.selectedQuestionList;
        if (
          selectedQuestionList.length === 0 &&
          engagement.postSelection !== 'none'
        ) {
          errorStatus = true;
          errorList = {
            ...errorList,
            selectedQuestionList: ['Please select question'],
          };
        }
        if (!isValid) {
          errorStatus = true;
        }

        if (typeof errors !== undefined) {
          errorList = { ...errorList, ...errors };
        }

        if (errorStatus) {
          reject(errorList);
        } else {
          resolve(true);
        }
      } else {
        if (errorStatus) {
          reject(errorList);
        } else {
          resolve(true);
        }
      }
    });
  };
};
