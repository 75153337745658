/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { useGeomanControls } from 'react-leaflet-geoman-v2';
import axios from 'axios';
import { BASE_API_URL } from '../../../constants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function DrawControls({ onNewPolygon, onClearArea }) {
  const map = useMap();
  const currentPolygon = useRef(null);

  const enableDragMode = () => {
    map.pm.enableDraw('Polygon');
    if (currentPolygon.current) {
      currentPolygon.current.pm.enableLayerDrag();
    }
  }

  const clearPolygon = () => {
    if (currentPolygon.current) {
      map.removeLayer(currentPolygon.current);
      currentPolygon.current = null;
    }
  }

  useEffect(() => {
    onClearArea(clearPolygon);
  }, [onClearArea]);

  useEffect(() => {
    if (!currentPolygon.current) {
      map.pm.enableDraw('Polygon');
    } else {
      enableDragMode();
    }
  }, [currentPolygon.current]);

  useGeomanControls({
    options: { 
      drawPolygon: false,
      drawCircle: false,
      drawPolyline: false,
      drawMarker: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawText: false,
      cutPolygon: false,
      rotateMode: false,
      editMode: true,
      dragMode: false,
    },
    onCreate: (e) => {
      currentPolygon.current = e.layer;
      onNewPolygon(e.layer.toGeoJSON());
      map.pm.enableDraw('Polygon');
      e.layer.pm.enableLayerDrag();
    },
    eventDebugFn: console.log,
    onEdit: (e) => {
      onNewPolygon(e.layer.toGeoJSON());
    },
    onGlobalEditModeToggled: (e) => {
      if (!e.enabled) {
        enableDragMode();
      }
    },
    onLayerRemove: (e) => {
      onNewPolygon(null);
      currentPolygon.current = null;
      map.pm.disableGlobalDragMode();
      map.pm.enableDraw('Polygon');
    }
  })

  return null;
}

const SizeInvalidator = ({ invalidateSize }) => {
  const map = useMap();
  useEffect(() => {
    if (invalidateSize) {
      map.invalidateSize();
    }
  }, [map, invalidateSize]);

  return null;
}

const DrawAreaMap = ({ invalidateSize, onCoordinatesChange, reloadToggle, setError }) => {
  const [latLngs, setLatLngs] = useState([]);
  const [population, setPopulation] = useState(0);
  const clearPolygonRef = useRef(null);
  const user = useSelector((state) => state.account.user);

  const onNewPolygon = (layer) => {
    setError(null);

    if (!layer) {
      setLatLngs([]);
      setPopulation(0);
      return;
    }
    setLatLngs(layer.geometry.coordinates[0]);
  }

  const clearArea = () => {
    setLatLngs([]);
    setPopulation(0);
    setError(null);
    if (clearPolygonRef.current) {
      clearPolygonRef.current();
    }
  }

  const setClearPolygon = (clearPolygon) => {
    clearPolygonRef.current = clearPolygon;
  }

  useEffect(() => {
    if (latLngs.length > 0) {
      axios.post(BASE_API_URL + '/coordinatesInfo', {
        coordinates: latLngs
      })
      .then((response) => {
        setPopulation(response.data.population);
        if (onCoordinatesChange) {
          onCoordinatesChange(latLngs);
        }
      })
      .catch((error) => {
        setError(error.response.data.error);
        if (onCoordinatesChange) {
          onCoordinatesChange([]);
        }
      });
    }
  }, [latLngs]);

  useEffect(() => {
    if (reloadToggle) {
      clearArea();
    }
  }, [reloadToggle]);

  const styledPopulation = population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const { t } = useTranslation();

  return (
    <>
      <MapContainer
        scrollWheelZoom={true}
        id="mapId"
        center={user?.coordinates || [37.386051, -122.083855]} // Mountain View
        zoom={13}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
        <DrawControls onNewPolygon={onNewPolygon} onClearArea={setClearPolygon} />
        <SizeInvalidator invalidateSize={invalidateSize} />
        {!!user?.coordinates && (
          <Marker position={user.coordinates} draggable={false}>
            <Popup>
              {user.address}
            </Popup>
          </Marker>
        )}
      </MapContainer>
      <p className="community-issues-area-description">{t('Selected area contains approx.')}{' '}<strong>{styledPopulation}{' '}{t('residents.')}</strong></p>
      <button className="community-issues-btn" onClick={clearArea}>{t('Clear Area')}</button>
    </>
  )
};

export default DrawAreaMap;