/*
 *
 * SearchResult reducer
 *
 */

import { DEFAULT_ACTION, SEARCH_RESULT_CHANGE, RESET_APP } from './constants';

const initialState = {
  districtSearchResult: [],
  anywhereSearchResult: [],
  searchText: '',
  showResult: false,
};

const defaultState = JSON.stringify(initialState);

const searchResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case DEFAULT_ACTION:
      return state;
    case SEARCH_RESULT_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default searchResultReducer;
