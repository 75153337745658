/**
 *
 * Display My Petition List
 *
 */

import React from 'react';
import PetitionList from './petitionList';
import { useTranslation } from 'react-i18next';
import CivicModal from '../../Common/CivicModal';
import NewPetitionForm from './newPetitionForm';

const MyPetitionList = (props) => {
  const {
    myPetitions,
    user,
    activePetitionTab,
    activeMyPetitionTab,
    petitionFormData,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      {myPetitions.length === 0 ? (
        <div className={'mb-3 no-engagement'}>
          <span className={'sad-img'}>
            <img
              src={require('../../../assets/img/sad.svg').default}
              alt="No Initiatives"
            />
          </span>
          <span className="no-engagement-txt">
            {user.roleName === 'RESIDENT' &&
            activePetitionTab === 'my-petitions' &&
            activeMyPetitionTab === 'signed-petitions'
              ? t('You have not signed an initiative')
              : user.roleName === 'RESIDENT' &&
                activePetitionTab === 'my-petitions'
              ? t('You have not started an initiative')
              : user.roleName === 'RESIDENT'
              ? t(
                  `Nobody in your area started a initiatives yet. Be the first one!`,
                )
              : t('Nobody in your area started a initiatives yet.')}
          </span>
        </div>
      ) : (
        myPetitions.map((petition, index) => (
          <PetitionList key={index} {...props} petition={petition} />
        ))
      )}
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`form-petition`}
          title={t('New Initiative')}
          dismissal={petitionFormData.dismissal}
          modalClass={'clsnew-petition-modal'}
        >
          <NewPetitionForm {...props} />
        </CivicModal>
      </div>
    </>
  );
};

export default MyPetitionList;
