import axios from 'axios';
import {BASE_API_URL} from '../../constants';
import {login, setTab} from '../Login/actions';
import {addNotification} from '../Notification/actions';
import {
    REGISTER_CHANGE,
    REGISTER_RESET,
    SET_REGISTER_LOADING,
    SET_REGISTER_FORM_ERRORS,
    SET_REGISTER_SUBMITTING,
    RESET_APP,
    SET_CODE_VALIDATION_ERROR,
    SET_REGISTER_FORM_SUBMISSION_ERRORS,
    SET_REGISTRATION_MODAL_OPEN,
    SET_IS_SIGN_UP_BUTTON_CLICKED,
    SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN,
    SET_IS_LOGIN_MODAL_OPEN,
    errorMessages,
} from './constants';
import {formatPhoneNumber} from '../../utils/helper';
import {allFieldsValidation} from '../../utils/validation';
import {populateLoginData} from "../Login/actions";
import {fetchProfile, updateUserNameInfo} from "../Dashboard/actions";
import { removeSubmissionError } from './utils';
import {SET_PROFILE_LOADING} from "../Dashboard/constants";

export const resetRegisterState = () => {
    return async (dispatch) => {
        return dispatch({type: RESET_APP});
    };
};

export const resetRegistrationState = () => {
    return async (dispatch) => {
        return dispatch({type: REGISTER_RESET});
    }
}

export const registerChange = (name, value) => {
    let formData = {};

    return async (dispatch, getState) => {
        let errors = getState().registration.formErrors;

        if (typeof errors === 'object') {
            errors[name] = '';

            if (name === 'firstName') {
                errors['first_name'] = '';
            }

            if (name === 'lastName') {
                errors['last_name'] = '';
            }

            if (name === 'officialPhoneNumber' || name === 'phoneNumber' || name === 'phone_number') {
                errors['phone_number'] = '';
            }
        }

        const submissionErrors = getState().registration.formSubmissionErrors;
        const newSubmissionErrors = removeSubmissionError(name.includes('phone') ? 'phone' : name, submissionErrors);

        dispatch({type: SET_CODE_VALIDATION_ERROR, payload: false});
        dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
        dispatch({type: SET_REGISTER_FORM_SUBMISSION_ERRORS, payload: newSubmissionErrors });

        if (name === 'officialPhoneNumber' || name === 'phoneNumber' || name === 'phone_number') {
            formData[name] = formatPhoneNumber(value);
        } else {
            formData[name] = value;
        }

        if (name === 'signUpOfficialEmail') {
            if (value === true) {
                formData['officialEmail'] = getState().registration.registerFormData.email;
            }
        }

        if (name === 'signUpType') {
            if (value === true) {
                formData['signUpType'] = 'official';
                formData['signUpTypeBoolean'] = true;
            } else {
                formData['signUpType'] = 'resident';
                formData['signUpTypeBoolean'] = false;
            }
        }

        return dispatch({type: REGISTER_CHANGE, payload: formData});
    };
};

export const fillAddress = (field, address) => {
    return async (dispatch) => {
        if (address === null || address === undefined) return;
        let formData = {address: '', zipCode: '', city: '', state: ''};

        for (const component of address) {
            const componentType = component.types[0];

            switch (componentType) {
                case 'street_number': {
                    formData.address = component.long_name;
                    break;
                }
                case 'route': {
                    formData.address = formData.hasOwnProperty('address')
                        ? formData.address + ' ' + component.short_name
                        : component.short_name;
                    break;
                }
                case 'postal_code': {
                    formData.zipCode = component.long_name;
                    break;
                }
                case 'locality':
                    formData.city = component.long_name;
                    break;
                case 'administrative_area_level_1': {
                    formData.state = component.short_name;
                    break;
                }
                default: {
                    field.current.value = formData.address;
                    break;
                }
            }
        }

        return dispatch({type: REGISTER_CHANGE, payload: formData});
    };
};

export const validateBeforeEmailVerification = () => {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let user = getState().registration.registerFormData;
            let rulesOfficial = {
                firstName: 'required',
                lastName: 'required',
                officialEmail: 'required|email',
            };

            const {isValid, errors} = allFieldsValidation(user, rulesOfficial, {
                'required.officialEmail': 'Email is required.',
                'email.officialEmail': 'Email format is invalid.',
                'required.firstName': 'Required.',
                'required.lastName': 'Required',
            });

            if (!isValid) {
                reject(errors);
            } else {
                resolve(true);
            }
        });
    };
};

export const register = () => {
    return async (dispatch, getState) => {
        let user = getState().registration.registerFormData;

        const rules = {
            email: 'required|email',
            password: 'required|password',
            signUpType: 'required',
        };

        if (user.step === 1) {
            const {isValid, errors} = allFieldsValidation(user, rules, {
                'required.email': 'Email is required.',
                'email.email': 'Email format is invalid.',
                'required.password': 'Password is required.',
                'required.signUpType': 'Required',
            });

            if (!isValid) {
                return dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
            }

            //Check whether the email is already taken
            dispatch({type: SET_REGISTER_SUBMITTING, payload: true});
            dispatch({type: SET_REGISTER_LOADING, payload: true});
            let validStep = true;

            await axios.post(BASE_API_URL + '/user-email', {
                    email: user.email.toLowerCase(),
                    signuptype: user.signUpType.value,
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then((response) => {
                dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
                dispatch({type: SET_REGISTER_LOADING, payload: false});

                validStep = response.data.success === 'true';
            }).catch((error) => {
                validStep = false;
                let emailExistError = {email: [error.response.data.error]};

                dispatch({type: SET_REGISTER_FORM_ERRORS, payload: emailExistError,});
                dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
                dispatch({type: SET_REGISTER_LOADING, payload: false});
            });

            if (validStep === false) return;

            else dispatch({type: SET_REGISTER_FORM_ERRORS, payload: {}});
        }

        if (user.step === 1) {
            let formData = {};
            if (user.signUpType.value === 'official'
            ) {
                formData['step'] = 2;
                return dispatch({type: REGISTER_CHANGE, payload: formData});
            } else {
                formData['step'] = 3;
                return dispatch({type: REGISTER_CHANGE, payload: formData});
            }
        }

        if (user.step === 2) {
            //Check the official email is valid or not
            dispatch(validateRegisterForm()).then(async () => {
                dispatch(registerSubmit());
            }).catch((error) => {
                dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
                dispatch({type: SET_REGISTER_LOADING, payload: false});
            });
        } else {
            dispatch(validateRegisterForm()).then(async () => {
                dispatch(registerSubmit());
            }).catch((error) => {
                dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
                dispatch({type: SET_REGISTER_LOADING, payload: false});
            });
        }
    };
};

//Registration Form Validation
export const validateRegisterForm = () => {
    return async (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let user = getState().registration.registerFormData;

            const reesidentRulesSecondStep = {
                firstName: 'required',
                lastName: 'required',
                address: 'required',
                city: 'required',
                state: 'required',
                zipCode: 'required',
                phoneNumber: 'telephone',
            };

            let rulesOfficial = {};
            rulesOfficial = {
                firstName: 'required',
                lastName: 'required',
                position: 'required',
                district: 'required',
                ocdId: 'required',
                newEmail: [{required_if: ['manualVerification', true]}],
                phoneNumber: [
                    {required_if: ['manualVerification', true]},
                    'telephone_required',
                ],
            };

            if (user.signUpType.value === 'official') {
                const {isValid, errors} = allFieldsValidation(user, rulesOfficial, {
                    'required.firstName': 'Required',
                    'required.lastName': 'Required',
                    'required.position': 'Required',
                    'required.district': 'Required',
                    'required.ocdId': 'Please select the valid district from the auto suggestion box.',
                    'required.newEmail': 'Required',
                    'required_if.newEmail': 'Please enter the email for manual verification',
                    'required.phoneNumber': 'Required',
                    'required_if.phoneNumber': 'Please enter the phone number',
                });

                if (!isValid) {
                    dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
                    return reject(errors);
                } else {
                    let formData = getState().registration.registerFormData;
                    let credentialValidation = formData.validateCredentials;

                    if (credentialValidation === false) {
                        dispatch({type: SET_REGISTER_SUBMITTING, payload: true});
                        dispatch({type: SET_REGISTER_LOADING, payload: true});
                        let data = {
                            first_name: formData.firstName,
                            last_name: formData.lastName,
                            position: formData.position,
                            my_district: formData.district,
                            ocdId: formData.ocdId,
                            email: formData.email,
                        };

                        await axios.post(BASE_API_URL + '/validateCredentials', data, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }).then((response) => {
                            if (response.data.success === 'true') {
                                let status = response.data.data.combinationMatches;
                                let userFormData = {};

                                userFormData['validateCredentials'] = true;
                                //userFormData['manualVerification'] = true;
                                userFormData['manualVerification'] = status !== true;
                                userFormData['showEmailConfirmation'] = status;

                                if (status === true) {
                                    userFormData['poVerifiedEmail'] = response.data.data.email;
                                } else if (status === false) {
                                    userFormData['poVerifiedEmail'] = '';
                                    let newEmailError = {
                                        newEmail: [
                                            "Unfortunately, we don't have someone with your name affiliated with " +
                                            formData.district +
                                            '. Please enter your email and phone number below, and we will reach out within 24 hours.',
                                        ],
                                    };
                                    dispatch({
                                        type: SET_REGISTER_FORM_ERRORS,
                                        payload: newEmailError,
                                    });
                                }
                                dispatch({type: REGISTER_CHANGE, payload: userFormData});
                            }
                        }).catch((error) => {
                            dispatch(addNotification({
                                    type: 'error',
                                    message: error?.response?.data?.error,
                                    module: 'register',
                                }),
                            );

                            let userFormData = {};

                            userFormData['validateCredentials'] = true;
                            userFormData['manualVerification'] = true;
                            dispatch({type: REGISTER_CHANGE, payload: userFormData});
                        }).finally(() => {
                            dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
                            dispatch({type: SET_REGISTER_LOADING, payload: false});
                        });
                        return reject(false);
                    } else {
                        //submit the register form
                        return resolve(true);
                    }
                }
            } else {
                if (user.step < 6) {
                    setNextStep();
                    return reject(false);
                }
                const {isValid, errors} = allFieldsValidation(
                    user,
                    reesidentRulesSecondStep,
                    {
                        'required.firstName': 'Required',
                        'required.lastName': 'Required',
                        'required.address': 'Required',
                        'required.city': 'Required',
                        'required.state': 'Required',
                        'required.zipCode': 'Required',
                    },
                );
                if (!isValid) {
                    dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
                    return reject(errors);
                }
            }

            dispatch({type: SET_REGISTER_FORM_ERRORS, payload: {}});

            if (user.step === 6) {
                return resolve(true);
            }
        });
    };
};

//Registration Form Submission
export const registerSubmit = () => {
    return async (dispatch, getState) => {
        let user = getState().registration.registerFormData;
        let registrationData = {};
        let phoneNumber = user.signUpType.value === 'official' ?
            '' : user.phoneNumber;
        phoneNumber = phoneNumber.replace(/\s/g, '');

        if (user.signUpType.value === 'official') {
            //Revised version
            if (phoneNumber === '+1') phoneNumber = '';
            registrationData = {
                first_name: user.firstName,
                last_name: user.lastName,
                email: user.email.toLowerCase(),
                password: user.password,
                signuptype: user.signUpType.value,
                position: user.position,
                district: user.district,
                ocdId: user.ocdId,
                manualVerification: user.manualVerification,
                newEmail: user.newEmail,
                phone_number: user.phoneNumber,
            };
        } else {
            if (phoneNumber === '+1') phoneNumber = '';
            registrationData = {
                first_name: user.firstName,
                last_name: user.lastName,
                email: user.email.toLowerCase(),
                password: user.password,
                address: user.address,
                city: user.city,
                state: user.state,
                zip_code: user.zipCode,
                phone_number: phoneNumber,
                signuptype: user.signUpType.value,
                alr_off_email: user.signUpOfficialEmail ? 1 : 0,
                off_email_addr: user.officialEmail,
                off_verify_by: '',
                position: '',
                selected_language: getState().account.user.selected_language
            };
        }

        dispatch({type: SET_REGISTER_SUBMITTING, payload: true});
        dispatch({type: SET_REGISTER_LOADING, payload: true});

        //eslint-disable-next-line
        await axios.post(BASE_API_URL + '/register', registrationData)
            .then((response) => {
                const firstName = user.firstName;
                if (
                    user.signUpType.value === 'official'
                ) {
                    if (user.manualVerification === true) {
                        const title = `Thank you! We will reach out to you within 24 hours.`;
                        dispatch(
                            addNotification({
                                type: 'success',
                                message: title,
                                module: 'login',
                                displayFor: 10000,
                            }),
                        );
                    } else {
                        const title = `Hey${
                            firstName ? ` ${firstName}` : ''
                        }, Welcome, we sent an email to confirm your email address. Please check it`;
                        dispatch(
                            addNotification({
                                type: 'success',
                                message: title,
                                module: 'login',
                                displayFor: 10000,
                            }),
                        );
                    }

                    dispatch({type: REGISTER_RESET});
                    return dispatch(setTab(1));
                } else {
                    dispatch({type: REGISTER_RESET});
                    let formData = {};
                    localStorage.setItem('email', user.email);
                    formData['step'] = 7;
                    dispatch({type: REGISTER_CHANGE, payload: formData});
                }
            }).catch((error) => {
                dispatch(
                    addNotification({
                        type: 'error',
                        message: error?.response?.data?.error,
                        module: 'register',
                    }),
                );
            }).finally(() => {
                dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
                dispatch({type: SET_REGISTER_LOADING, payload: false});
            });
    };
};

//Navigate To Previous Step
export const setPriviousStep = () => {
    let formData = {};
    return async (dispatch, getState) => {
        let step = getState().registration.registerFormData.step;

        if (step === 2 || step === 3) {
            formData['step'] = 1;
        } else if (step === 4) {
            formData['step'] = 3;
        } else if (step === 5) {
            formData['step'] = 4;
        } else if (step === 6) {
            formData['step'] = 5;
        } else {
            formData['step'] = step;
        }

        return dispatch({type: REGISTER_CHANGE, payload: formData});
    };
};

//Navigate To Next Step
export const setNextStep = () => {
    return async (dispatch, getState) => {
        let step = getState().registration.registerFormData.step;
        step++;

        let formData = {step};

        dispatch({type: REGISTER_CHANGE, payload: formData});
    };
};

export const redirectToLogin = () => {
    return async (dispatch, getState) => {
        const msg = `Hey, Welcome, we sent an email to confirm your email address. Please check it`;
        dispatch(
            addNotification({
                type: 'success',
                message: msg,
                module: 'login',
                displayFor: 10000,
            }),
        );
        dispatch({type: REGISTER_RESET});
        registerChange('message', '');

        return dispatch(setTab(1));
    };
};

export const districtBoxChange = (key, value) => {
    return async (dispatch, getState) => {
        let suggestionBox = getState().registration.registerFormData.suggestionBox;
        suggestionBox = {...suggestionBox, ...{[key]: value}};
        dispatch(registerChange('suggestionBox', suggestionBox));
    };
};

//getDistrictData
export const getDistrictData = () => {
    return async (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            let value = getState().registration.registerFormData.district;
            let formData = {};
            let suggestionBox = getState().registration.registerFormData.suggestionBox;
            suggestionBox['loading'] = true;
            formData['suggestionBox'] = {...suggestionBox};
            dispatch(registerChange('suggestionBox', suggestionBox));

            await axios.get(BASE_API_URL + '/ocdidSuggestion?keyword=' + value)
                .then((response) => {
                    if (response.data.success === 'true') {
                        suggestionBox = {};
                        suggestionBox = {
                            items: response.data.data,
                            loading: false,
                            display: true,
                        };

                        dispatch(registerChange('suggestionBox', suggestionBox));
                        return resolve(true);
                    }
                }).catch((error) => {
                    return resolve(false);
                });
        });
    };
};

export const officialStepOneSubmit = () => {
    return async (dispatch, getState) => {
        const user = getState().registration.registerFormData;
        let phoneNumber = user.phoneNumber;
        if (phoneNumber === '+1') phoneNumber = '';
        
        const registrationData = {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email.toLowerCase(),
            password: user.password,
            signUpType: user.signUpType,
            position: user.position,
            district: user.district,
            ocdId: user.ocdId,
            manualVerification: user.manualVerification,
            phone_number: user.phoneNumber,
            geoId: getState().account.user.geoId
        };

        const rules = {
            signUpType: 'required',
            email: 'required|email',
            password: 'required|password',
            first_name: 'required',
            last_name: 'required',
            district: 'required',
            phone_number: 'required|telephone'
        };

        const {isValid, errors} = allFieldsValidation(registrationData, rules, errorMessages);
        if (!isValid) {
            return dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
        }

        dispatch({type: SET_REGISTER_SUBMITTING, payload: true});
        dispatch({type: SET_REGISTER_LOADING, payload: true});

        try {
            const response = await axios.post(BASE_API_URL + '/register/officialCheck', registrationData);
            dispatch({type: REGISTER_RESET});
            dispatch({type: REGISTER_CHANGE, payload: { officialEmail: response.data.officialEmail } });

            if (response.data.officialEmail) {
                dispatch(setNextStep());
            } else {
                dispatch(setStep(3));
            }
        } catch (error) {
            dispatch({type: SET_REGISTER_FORM_SUBMISSION_ERRORS, payload: error.response.data.error});
            dispatch(
                addNotification({
                    type: 'error',
                    message: error?.response?.data?.error,
                    module: 'register',
                })
            )
        }
    }
}

export const registrationSubmit = () => {
    return async (dispatch, getState) => {
        let user = getState().registration.registerFormData;
        let registrationData = {};
        let phoneNumber = user.signUpType.value === 'official' ?
            '' : user.phoneNumber;
        phoneNumber = phoneNumber.replace(/\s/g, '');

        let rules = {};

        if (phoneNumber === '+1') phoneNumber = '';
        registrationData = {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email.toLowerCase(),
            password: user.password,
            address: getState().account.user.address,
            city: getState().account.user.city,
            state: getState().account.user.state,
            zip_code: getState().account.user.zip_code,
            dob: user.dob,
            phone_number: phoneNumber,
            signUpType: user.signUpType,
            alr_off_email: user.signUpOfficialEmail ? 1 : 0,
            off_email_addr: user.officialEmail,
            off_verify_by: '',
            position: '',
            geoId: getState().account.user.geoId,
            selected_language: getState().account.user.selected_language,
        };

        rules = {
            signUpType: 'required',
            email: 'required|email',
            password: 'required|password',
            dob: 'required|age_greater_than_13',
            phone_number: 'required|telephone',
            address: 'required',
            city: 'required',
            state: 'required',
            zip_code: 'required',
        };

        const {isValid, errors} = allFieldsValidation(registrationData, rules, errorMessages);

        if (!isValid) {
            return dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
        }

        dispatch({type: SET_REGISTER_SUBMITTING, payload: true});
        dispatch({type: SET_REGISTER_LOADING, payload: true});

        await axios.post(BASE_API_URL + '/register/v2', registrationData)
            .then((response) => {
                dispatch({type: REGISTER_RESET});

                let formData = {};
                localStorage.setItem('email', user.email);
                formData['step'] = 1;

                dispatch({type: REGISTER_CHANGE, payload: formData});

                dispatch(populateLoginData({
                    username: user.email.toLowerCase(),
                    password: user.password
                })).then(() => {
                    dispatch(login()).then(() => {
                        if (user.signUpType === 'resident') {
                            dispatch(sendConfirmationText());
                        }

                        dispatch(fetchProfile(true));
                    });
                });    

                dispatch(setNextStep());
            }).catch((error) => {
                console.log(error);

                if (error.response.data) {
                    dispatch({type: SET_REGISTER_FORM_SUBMISSION_ERRORS, payload: error.response.data.error});
                }

                if (user.signUpType === 'official') {
                    dispatch(setStep(3));
                }

                dispatch(
                    addNotification({
                        type: 'error',
                        message: error?.response?.data?.error,
                        module: 'register',
                    }),
                );
            }).finally(() => {
                // dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
                // dispatch({type: SET_REGISTER_LOADING, payload: false});
            });
    };
};

export const sendConfirmationText = () => {
    return async (dispatch, getState) => {
        await axios.post(BASE_API_URL + '/send-confirmation-text');
    };
}

export const verifyCode = () => {
    return async (dispatch, getState) => {
        dispatch({type: SET_REGISTER_SUBMITTING, payload: true});
        dispatch({type: SET_REGISTER_LOADING, payload: true});
        //eslint-disable-next-line
        await axios.post(BASE_API_URL + '/verify-code', {
            'code': `${getState().registration.registerFormData.verificationCode}`
        }).then((response) => {
            dispatch(predictUserData());
            dispatch(fetchProfile(true));
            // dispatch(createInitialImg());
        }).catch((error) => {
            console.log(error);

            dispatch({type: SET_CODE_VALIDATION_ERROR, payload: true});
        })
    };
};

export const predictUserData = () => {
    return async (dispatch, getState) => {
        const existingUser = getState().registration.registerFormData.existingUser;

        await axios.get(BASE_API_URL + '/register/name')
            .then((response) => {

                if (response.data.success === 'true') {
                    if (existingUser) {
                        let formData;
                        formData = {
                            firstName: response.data.data.first_name,
                            lastName: response.data.data.last_name,
                            telesignVerified: true
                        };

                        dispatch({type: REGISTER_CHANGE, payload: formData});
                        dispatch(verifyUserRegistration(5));
                    } else {
                        let formData;
                        formData = {
                            firstName: response.data.data.first_name,
                            lastName: response.data.data.last_name,
                            telesignVerified: true
                        };

                        dispatch({type: REGISTER_CHANGE, payload: formData});
                        dispatch({type: SET_REGISTER_LOADING, payload: false});
                        dispatch(setStep(3));
                    }
                } else {
                    if (existingUser) {
                        dispatch(setStep(5));
                    } else {
                        dispatch(setStep(4));
                    }
                }
            }).catch((error) => {
                if (existingUser) {
                    dispatch(setStep(5));
                } else {
                    dispatch(setStep(4));
                }
            });
    };
}

export const updateUserData = () => {
    return async (dispatch, getState) => {

        const rules = {
            firstName: 'required',
            lastName: 'required'
        };

        const {isValid, errors} = allFieldsValidation({
            firstName: getState().registration.registerFormData.firstName,
            lastName: getState().registration.registerFormData.lastName,
        }, rules, errorMessages);

        if (!isValid) {
            dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
            return false;
        } else {
            await axios.post(BASE_API_URL + '/register/name', {
                'first_name': getState().registration.registerFormData.firstName,
                'last_name': getState().registration.registerFormData.lastName,
            }).then((response) => {
                if (response.success === 'true') {
                    dispatch(updateUserNameInfo(response.user));
                }
            });

            return true;
        }
    };
}

export const verifyUserRegistration = (step = 5, data = {}) => {
    return async (dispatch, getState) => {
        dispatch({type: SET_REGISTER_LOADING, payload: true});

        await axios.post(BASE_API_URL + '/register/verify', data)
            .then((response) => {
                if (response.data.success === 'true') {
                    dispatch({type: REGISTER_CHANGE, payload: {registrationFinished: true}});
                    // dispatch(setStep(1));
                    dispatch({type: SET_REGISTRATION_MODAL_OPEN, payload: {isRegistrationModalOpen: false}});
                    // dispatch(resetRegisterState());
                    dispatch(fetchProfile());
                } else {
                    dispatch(setStep(step));
                }
            }).catch((error) => {
                console.log(error);

                dispatch(setStep(step));
            })
            .finally(() => {
                dispatch({type: SET_REGISTER_LOADING, payload: false});
            });
    };
}

export const setStep = (step) => {
    return async (dispatch) => {
        dispatch({type: REGISTER_CHANGE, payload: {step}});
    };
};

export const verifyZipCode = () => {
    return async (dispatch, getState) => {
        let data = {
            zipCode: getState().registration.registerFormData.zipCode,
            address: getState().registration.registerFormData.address,
            city: getState().registration.registerFormData.city,
            state: getState().registration.registerFormData.state
        };

        const rules = {
            zipCode: 'required',
            address: 'required',
            city: 'required',
            state: 'required'
        };

        const {isValid, errors} = allFieldsValidation(data, rules, errorMessages);

        if (!isValid) {
            return dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
        }

        data = {
            zip_code: getState().registration.registerFormData.zipCode,
            address: getState().registration.registerFormData.address,
            city: getState().registration.registerFormData.city,
            state: getState().registration.registerFormData.state
        };


        await axios.post(BASE_API_URL + '/register/verify', data)
            .then((response) => {
                dispatch(verifyUserRegistration(1, data));
                dispatch(fetchProfile());
            }).catch((error) => {
                console.log(error);

                dispatch(verifyUserRegistration(6, data));
            });
    }
};

export const sendEmailConfirmation = () => {
    return async (dispatch, getState) => {
        const user = getState().registration.registerFormData;
        const registrationData = {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email.toLowerCase(),
            password: user.password,
            signUpType: user.signUpType,
            position: user.position,
            district: user.district,
            ocdId: user.ocdId,
            manualVerification: user.manualVerification,
            phone_number: user.phoneNumber,
            geoId: getState().account.user.geoId,
            officialEmail: user.officialEmail.toLowerCase(),
        };

        await axios
            .post(BASE_API_URL + '/register/v2', registrationData)
            .then((response) => {
                dispatch({type: REGISTER_CHANGE, payload: {registrationFinished: true}});
                // dispatch(setStep(1));
                // dispatch(resetRegisterState());
                // dispatch({type: SET_REGISTRATION_MODAL_OPEN, payload: {isRegistrationModalOpen: false}});

                dispatch(
                    addNotification({
                        type: 'success',
                        message: response.data.data,
                        module: 'register',
                    }),
                );

                return true;
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        type: 'error',
                        message: error?.response?.data?.error,
                        module: 'register',
                    }),
                );

                return false;
            });
    };
};

export const registerNewOfficial = () => {
    return async (dispatch, getState) => {
        const user = getState().registration.registerFormData;

        const registrationData = {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email.toLowerCase(),
            password: user.password,
            signUpType: user.signUpType,
            position: user.position,
            district: user.district,
            ocdId: user.ocdId,
            manualVerification: user.manualVerification,
            phone_number: user.phoneNumber,
            geoId: getState().account.user.geoId,
            officialEmail: user.officialEmail.toLowerCase(),
            selected_language: getState().account.user.selected_language,
        };

        const rules = {
            signUpType: 'required',
            email: 'required|email',
            password: 'required|password',
            first_name: 'required',
            last_name: 'required',
            district: 'required',
            phone_number: 'required|telephone'
        };

        const {isValid, errors} = allFieldsValidation(registrationData, rules, errorMessages);

        if (!isValid) {
            return dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
        }

        await axios
            .post(BASE_API_URL + '/register/v2', registrationData)
            .then((response) => {
                dispatch({type: REGISTER_CHANGE, payload: {registrationFinished: true}});
                // dispatch(setStep(1));
                // dispatch(resetRegisterState());
                // dispatch({type: SET_REGISTRATION_MODAL_OPEN, payload: {isRegistrationModalOpen: false}});

                dispatch(
                    addNotification({
                        type: 'success',
                        message: response.data.data,
                        module: 'register',
                    }),
                );
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        type: 'error',
                        message: error?.response?.data?.error,
                        module: 'register',
                    }),
                );
            });
    };
};

// Load user data into registerFormData
export const loadRegisterData = () => {
    return async (dispatch, getState) => {
        const user = getState().account.user;

        const registerData = {
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            position: user.position,
            district: user.district,
            ocdId: user.ocd_id,
            manualVerification: user.manual_verification,
            phoneNumber: user.phone_number,
            officialEmail: user.official_email,
            signUpType: 'resident',
        };

        dispatch({type: REGISTER_CHANGE, payload: registerData});
    };
}

export const setRegistrationModalOpen = (value) => {
    return async (dispatch) => {
        return dispatch({type: SET_REGISTRATION_MODAL_OPEN, payload: value});
    };
};

export const setIsSignUpButtonClicked = (value) => {
    return async (dispatch) => {
        return dispatch({type: SET_IS_SIGN_UP_BUTTON_CLICKED, payload: value});
    };
};

export const setAccountConfirmationModalOpen = (value) => {
    return async (dispatch) => {
        return dispatch({type: SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN, payload: value});
    };
};

export const setLoginModalOpen = (value) => {
    return async (dispatch) => {
        return dispatch({type: SET_IS_LOGIN_MODAL_OPEN, payload: value});
    };
}

export const updateUserPhoneNumber = () => {
    return async (dispatch, getState) => {
        let user = getState().registration.registerFormData
        let phoneNumber = user.phoneNumber.replace(/\s/g, '');
        if (phoneNumber === '+1') phoneNumber = '';

        const rules = {
            phone_number: 'required|telephone'
        };

        const {isValid, errors} = allFieldsValidation({
            phone_number: phoneNumber,
        }, rules, errorMessages);

        if (!isValid) {
            dispatch({type: SET_REGISTER_FORM_ERRORS, payload: errors});
            return false;
        } else {
            await axios.post(BASE_API_URL + '/users/editMyProfile', {
                'phone_number': phoneNumber
            }).then((response) => {
                if (response.success === 'true') {
                    dispatch(updateUserNameInfo(response.user));
                }
            }).catch((error) => {
                console.log(error);

                if (error.response.data) {
                    dispatch({type: SET_REGISTER_FORM_SUBMISSION_ERRORS, payload: error.response.data.error});
                }
            });

            return true;
        }
    };
}

export const existingUserRegistration = () => {
    return async (dispatch, getState) => {
        let user = getState().account.user;

        dispatch({
            type: REGISTER_CHANGE, payload: {
                // first_name: user.firstName,
                // last_name: user.lastName,
                // email: user.email.toLowerCase(),
                // password: user.password,
                address: user.address,
                city: user.city,
                state: user.state,
                zip_code: user.zip_code,
                // dob: user.dob,
                phone_number: user.phone_number,
                signUpType: 'resident',
                signUpTypeBoolean: false,
                existingUser: true
            }
        });

        dispatch(setStep(2));
        dispatch(setRegistrationModalOpen(true));
        dispatch(sendConfirmationText());
        // dispatch({type: REGISTER_CHANGE, payload: {existingUser: true}});
    }
}