import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../src/assets/locales/en/translation.json';
import translationES from '../src/assets/locales/es/translation.json';
import translationCN from '../src/assets/locales/cn/translation.json';
import translationTL from '../src/assets/locales/tl/translation.json';
import translationVI from '../src/assets/locales/vi/translation.json';
import translationKO from '../src/assets/locales/ko/translation.json';
import translationRU from '../src/assets/locales/ru/translation.json';

const fallbackLng = ['EN'];
const availableLanguages = ['EN', 'ES', 'CN', 'TL', 'VI', 'KO', 'RU'];
const resources = {
  EN: {
    translation: translationEN,
  },
  ES: {
    translation: translationES,
  },
  CN: {
    translation: translationCN,
  },
  TL: {
    translation: translationTL,
  },
  VI: {
    translation: translationVI,
  },
  KO: {
    translation: translationKO,
  },
  RU: {
    translation: translationRU,
  }
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      DETECTION_OPTIONS,
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    preload: ['en'],
  });

export default i18n;
