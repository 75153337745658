/*
 *
 * District Offcials List
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../../actions';
import OfficialsList from '../../components/Manager/CivicMap/district-officials';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import OfficialFilter from '../../components/Manager/CivicMap/official-filter';
import Notification from '../../containers/Notification';
import { useTranslation } from 'react-i18next';

const OfficialList = (props) => {
  const {
    location,
    getOfficials,
    officials,
    noRecords,
    reloadList,
    resetResult,
    searchSubmission,
    reloadOfficial,
    searchResultReset,
    officialChangeByOption,
    user,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [officialListComponent, setOfficialListComponent] = useState('');
  const ocdid = new URLSearchParams(location.search).get('ocdid');
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setOfficialListComponent(
          <Component
            {...props}
            officialChangeByOption={officialChangeByOption}
          />,
        );
        return resolve(true);
      });
    },
    [setOfficialListComponent, props, officialChangeByOption],
  );

  const fetchOfficials = useCallback(async () => {
    setInProcess(true);
    await getOfficials(ocdid, page, 2).then(async () => {
      await renderComponent(OfficialsList).then(() => {
        setPage(page + 1);
      });
    });
    setInProcess(false);
  }, [getOfficials, renderComponent, setPage, ocdid, page]);

  useEffect(() => {
    if (loading === true) {
      setOfficialListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        const fetchOfficials = async () => {
          await getOfficials(ocdid).then(() => {
            setLoading(false);
            setInProcess(false);
          });
        };
        fetchOfficials();
      }
    }
  }, [
    loading,
    setLoading,
    getOfficials,
    renderComponent,
    ocdid,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    officials,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(OfficialsList);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setPage, setLoading, setInitialLoad]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await reloadOfficial();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, reloadOfficial],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    renderComponent(OfficialsList);
  }, [user, renderComponent]);

  return (
    <>
      {/* {isOfficialLoading === true ? <LoadingIndicator position={`fixed`} /> : ''} */}
      <Notification module={'map'} />
      <OfficialFilter {...props} />
      {officialListComponent}
      {officials.length > 0 ? (
        loading === true || initialLoad === true ? (
          <LoadingIndicator position={`relative`} />
        ) : (
          <>
            <InfiniteScroll
              dataLength={officials.length}
              next={fetchOfficials}
              hasMore={!noRecords}
              loader={
                inProcess === true ? (
                  <LoadingIndicator position={`relative`} />
                ) : (
                  ''
                )
              }
            />
          </>
        )
      ) : (
        ''
      )}
      <div className="clsdistrict-details-blk pt-2">
        <div className="clsdistrict-details-box clswhite-box mb-3">
          <div className="clsdistrict-details-description">
            <div className="clsdistrict-details-description-in">
              <div className="media">
                <img
                  src={require('../../assets/img/placeholder-img.svg').default}
                  className="align-self-center mr-2"
                  width="61"
                  alt="..."
                />
                <div className="media-body align-self-center">
                  <p>{t(`Someone is not listed?`)}</p>
                  <h5 className="mt-0">
                    <NavLink
                      to={'/dashboard/inviteothers'}
                      activeClassName="clsnot-list-link"
                      exact
                    >
                      {t(`Government Official`)}
                    </NavLink>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    officials: state.map.officials,
    district: state.map.district,
    noRecords: state.map.noRecords,
    reloadList: state.map.reloadList,
    pageLoader: state.map.pageLoader,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    filterDismissal: state.map.filterDismissal,
    filter: state.map.filter,
    filterSelected: state.map.filterSelected,
    searchBox: state.map.searchBox,
    isOfficialLoading: state.map.isOfficialLoading,
  };
};

export default connect(mapStateToProps, actions)(OfficialList);
