import {$getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, REDO_COMMAND, UNDO_COMMAND, $isRootOrShadowRoot, TextNode, ParagraphNode} from 'lexical';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect, useState } from 'react';
import { INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, ListItemNode, ListNode, $isListNode, $isListItemNode } from '@lexical/list';
import { LinkNode, TOGGLE_LINK_COMMAND, $isLinkNode } from '@lexical/link';
import { $findMatchingParent, mergeRegister, $getNearestNodeOfType } from '@lexical/utils';
import {
  $patchStyleText, $getSelectionStyleValueForProperty
} from '@lexical/selection';
import FloatingLinkEditorPlugin from './FloatingLinkEditorPlugin';
import DropdownColorPicker from './ui/DropdownColorPicker';
import './editor.css';
import { getSelectedNode } from './utils/getSelectedNode';
import { ExtendedTextNode } from './utils/ExtendedTextNode';
import { ExtendedParagraphNode } from './utils/ExtendedParagraphNode';

const theme = {
  text: {
    bold: 'lexical-editor-bold',
    italic: 'lexical-editor-italic',
    underline: 'lexical-editor-underline',
    strikethrough: 'lexical-editor-strikethrough',
  },
  list: {
    listitem: 'lexical-editor-list-item',
  },
  link: 'lexical-editor-link'
};

const Toolbar = ({ displayColor = true, displayScripts = true }) => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isList, setIsList] = useState(false);
  const [isNumberedList, setIsNumberedList] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [fontColor, setFontColor] = useState('#000');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000'),
      );

      const anchorNode = selection.anchor.getNode();
      let element = anchorNode.getKey() === 'root'
        ? anchorNode
        : $findMatchingParent(anchorNode, (e) => {
            const parent = e.getParent();
            return parent !== null && $isRootOrShadowRoot(parent);
          });
        

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      if ($isListNode(element) || $isListItemNode(element)) {
        const parentList = $getNearestNodeOfType(
          anchorNode,
          ListNode,
        );
        const type = parentList
          ? parentList.getListType()
          : element.getListType();
        
        if (type === 'bullet') {
          setIsList(true);
          setIsNumberedList(false);
        } else if (type === 'number') {
          setIsNumberedList(true);
          setIsList(false);
        }
      } else {
        setIsList(false);
        setIsNumberedList(false);
      }

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const insertList = useCallback(() => {
    if (!isList) {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  }, [editor, isList]);

  const insertNumberedList = useCallback(() => {
    if (!isNumberedList) {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  }, [editor, isNumberedList]);

  const applyStyleText = useCallback(
    (styles) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
      });
    },
    [editor],
  );

  const onFontColorSelect = useCallback(
    (value) => {
      applyStyleText({color: value});
    },
    [applyStyleText],
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [updateToolbar, editor]);

  return (
    <div className="lexical-toolbar">
      <div className="lexical-toolbar-group">
        <button 
          onClick={() => { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold'); }} 
          className={`lexical-toolbar-button ${isBold ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format bold" />
        </button>
        <button 
          onClick={() => { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic'); }} 
          className={`lexical-toolbar-button ${isItalic ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format italic" />
        </button>
        <button 
          onClick={() => { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline'); }} 
          className={`lexical-toolbar-button ${isUnderline ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format underline" />
        </button>
      </div>

      <div className="lexical-toolbar-group">
        <button 
          onClick={insertList} 
          className={`lexical-toolbar-button ${isList ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format list" />
        </button>
        <button 
          onClick={insertNumberedList} 
          className={`lexical-toolbar-button ${isNumberedList ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format number-list" />
        </button>
        <button 
          onClick={insertLink} 
          className="lexical-toolbar-button"
          type="button"
        >
          <i className="format link" />
        </button>
      </div>

      {displayScripts && (<div className="lexical-toolbar-group">
        <button 
          onClick={() => { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough'); }} 
          className={`lexical-toolbar-button ${isStrikethrough ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format strikethrough" />
        </button>
        <button 
          onClick={() => { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript'); }} 
          className={`lexical-toolbar-button ${isSubscript ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format subscript" />
        </button>
        <button 
          onClick={() => { editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript'); }} 
          className={`lexical-toolbar-button ${isSuperscript ? 'lexical-toolbar-selected' : ''}`}
          type="button"
        >
          <i className="format superscript" />
        </button>
      </div>)}

      <div className="lexical-toolbar-group">
        <button onClick={() => { editor.dispatchCommand(UNDO_COMMAND); }} className="lexical-toolbar-button" type="button">
        <i className="format undo" />
        </button>

        <button onClick={() => { editor.dispatchCommand(REDO_COMMAND); }} className="lexical-toolbar-button" type="button">
        <i className="format redo" />
        </button>
      </div>

      {displayColor && (<DropdownColorPicker
        buttonClassName="lexical-toolbar-button"
        buttonAriaLabel="Formatting text color"
        buttonIconClassName="format bg-color"
        color={fontColor}
        onChange={onFontColorSelect}
        title="text color"
      />)}
    </div>
  );
};

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

function Editor({onEditorStateChange, onEditorChange, displayColor, displayScripts, placeholder = <div />}) {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

  function onChange(editorState, editor) {
    onEditorStateChange(editorState);
    onEditorChange(editor);
  }

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const initialConfig = {
    namespace: 'MyEditor',
    theme,
    nodes: [ListItemNode, ListNode, LinkNode, 
      ExtendedTextNode, { replace: TextNode, with: (node) => new ExtendedTextNode(node.__text, node.__key) },
      ExtendedParagraphNode, { replace: ParagraphNode, with: (node) => new ExtendedParagraphNode(node.__key) },
    ],
    onError: (error) => {
      console.log(error);
    }
  };

  return (
    <div style={{position: 'relative'}}>
      <LexicalComposer initialConfig={initialConfig}>
        <Toolbar displayColor={displayColor} displayScripts={displayScripts} />
        <RichTextPlugin
          contentEditable={(
          <div ref={onRef}>
            <ContentEditable className="lexical-content-editable" />
          </div>
          )}
          placeholder={placeholder}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        <TabIndentationPlugin />
        <MyCustomAutoFocusPlugin />
        {floatingAnchorElem && <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />}
      </LexicalComposer>
    </div>
  );
}

export default Editor;