/**
 *
 * Engagement Link
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { useTranslation } from 'react-i18next';

const EngagementLink = (props) => {
  const { blocks, user, resetEngagementForm, addNotification } = props;
  const { t } = useTranslation();

  const canShow =
    typeof blocks[user.roleName] !== 'undefined'
      ? blocks[user.roleName].filter(
          (block) => block.name === 'New Engagement Form',
        )
      : [];

  const hashUrl = '#';

  const resetForm = (e) => {
    if (user.manualverification === true) {
      if (typeof user.isManuallyVerified === 'undefined') {
        addNotification({
          type: 'error',
          message: t('Please wait until you are manually verified'),
          module: 'dashboard',
          displayFor: 5000,
          parentDivClass: 'top-notification',
        });
        e.stopPropagation();
      } else if (user.isManuallyVerified === false) {
        addNotification({
          type: 'error',
          message: t('Please wait until you are manually verified'),
          module: 'dashboard',
          displayFor: 5000,
          parentDivClass: 'top-notification',
        });
        e.stopPropagation();
      }
    }
    /*if(typeof engagementFormData !== 'undefined') {
      if(typeof engagementFormData.id !== 'undefined') {
        resetEngagementForm();
      }
    }*/
    resetEngagementForm();
  };

  return (
    <>
      {typeof blocks !== 'undefined' &&
      Object.keys(blocks).length !== 0 &&
      typeof user.roleName !== 'undefined' &&
      typeof canShow !== 'undefined' ? (
        <>
          {canShow.length > 0 ? (
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target="#new-engagement"
              onClick={(event) => {
                resetForm(event);
              }}
            >
              <span className="start-engagement-link">
                <img
                  src={require('../../../assets/img/plus.svg').default}
                  alt="No Engagements"
                />
                <span className="start-txt">{t(`Start New Engagement`)}</span>
              </span>
            </a>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementFormData: state.engagement.engagementFormData,
    user: state.account.user,
    blocks: state.account.blocks,
  };
};

EngagementLink.defaultProps = {
  user: [],
  blocks: [],
  canShow: [],
};

export default connect(mapStateToProps, actions)(EngagementLink);
