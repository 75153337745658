import i18next from 'i18next';

/*
 *
 * InviteOthers constants
 *
 */

export const INVITE_OTHERS_CHANGE = 'src/InviteOthers/INVITE_OTHERS_CHANGE';
export const INVITE_OTHERS_FORM_CHANGE =
  'src/InviteOthers/INVITE_OTHERS_FORM_CHANGE';
export const INVITE_OTHERS_FORM_RESET =
  'src/InviteOthers/INVITE_OTHERS_FORM_RESET';
export const INVITE_OTHERS_FORM_ERRORS =
  'src/InviteOthers/INVITE_OTHERS_FORM_ERRORS';
export const SET_INVITE_SUBMITTING = 'src/InviteOthers/SET_INVITE_SUBMITTING';
export const RESET_APP = 'src/InviteOthers/RESET_APP';

export const inviteOthersOptions = [
  { value: 'publicofficial', label: 'Elected or Public Official', translationParsing: i18next.t('Elected or Public Official') },
  { value: 'resident', label: 'Resident', translationParsing: i18next.t('Resident') },
];
