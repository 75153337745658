/**
 * Engagement Form First Step
 */

import React from 'react';
import SelectOption from '../../Common/SelectOption';
import { handleOptionValue } from '../../../utils/helper';
import {
  categoryOptions,
  targetPopulationOptions,
} from '../../../containers/Engagement/constants';
import CivicTags from '../../Common/Tags';
import EngagementDate from './engagementDate';
import { useTranslation } from 'react-i18next';

const EngagementFirstStep = (props) => {
  const {
    engagementFormData,
    engagementErrors,
    engagementChange,
    getTagsData,
    user,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="form-group">
        <label htmlFor="category">{t(`Type`)}</label>
        <SelectOption
          error={engagementErrors['category']}
          name={'category'}
          placeholder={t('Type')}
          value={
            engagementFormData.category !== ''
              ? handleOptionValue(categoryOptions, engagementFormData.category)
              : ''
          }
          options={categoryOptions}
          handleSelectChange={(value) => {
            engagementChange('category', value.value);
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="targetPopulation">{t(`Target Population`)}</label>
        <SelectOption
          error={engagementErrors['targetPopulation']}
          name={'targetPopulation'}
          placeholder={t('targetPopulation')}
          value={handleOptionValue(
            targetPopulationOptions,
            engagementFormData.targetPopulation,
          )}
          options={targetPopulationOptions}
          handleSelectChange={(value) => {
            engagementChange('targetPopulation', value.value);
          }}
        />
      </div>
      <div className={`postAsOrganization`}>
        <div className="custom-control custom-switch clsengagement-start-switch postAsOrganization">
          <input
            type="checkbox"
            name="postUnderOrganization"
            className="custom-control-input"
            id="postUnderOrganization"
            value={engagementFormData.postUnderOrganization}
            onChange={(e) =>
              engagementChange(
                e.target.name,
                !engagementFormData.postUnderOrganization,
              )
            }
            checked={engagementFormData.postUnderOrganization}
          />
          <label
            className="custom-control-label"
            htmlFor="postUnderOrganization"
          >
            {t(`Start as organization`)}
          </label>
        </div>
      </div>

      {engagementFormData.category !== 'post' ? (
        <EngagementDate {...props} />
      ) : (
        ''
      )}

      <div className="form-group">
        <label>{t(`Topics (Optional)`)}</label>
        <CivicTags
          tagValues={engagementFormData.tags}
          action={engagementChange}
          id={
            typeof engagementFormData.id === `undefined`
              ? `engagementTag`
              : `engagementTag${engagementFormData.id}`
          }
          suggestAction={getTagsData}
          suggestOption={true}
        />
        {typeof engagementErrors !== 'undefined' && engagementErrors.tags && (
          <div className="clsinvalid">{t(engagementErrors?.['tags'])}</div>
        )}
      </div>
      {/* <div className="clscheckbox">
            <span>
              <label>
                  <input id="visibleToNonConsttuent" type="checkbox" name="visibleToNonConsttuent" checked={engagementFormData.visibleToNonConsttuent} value={engagementFormData.visibleToNonConsttuent} onChange={(e) => engagementChange(e.target.name, !engagementFormData.visibleToNonConsttuent)} />
                  <label htmlFor="visibleToNonConsttuent"><span></span>Visible to Non Constituents</label>
                </label>
            </span>
        </div> */}
      <div className="clscheckbox">
        <span>
          <label>
            <input
              id="enableDiscussionBoard"
              type="checkbox"
              name="enableDiscussionBoard"
              checked={engagementFormData.enableDiscussionBoard}
              value={engagementFormData.enableDiscussionBoard}
              onChange={(e) =>
                engagementChange(
                  e.target.name,
                  !engagementFormData.enableDiscussionBoard,
                )
              }
              disabled={engagementFormData.category === 'discussion'}
            />
            <label
              htmlFor="enableDiscussionBoard"
              className={
                engagementFormData.category === 'discussion'
                  ? 'disabled-color'
                  : ''
              }
            >
              <span></span>
              {t(`Enable Discussion Board`)}
            </label>
          </label>
        </span>
      </div>
    </>
  );
};

export default EngagementFirstStep;
