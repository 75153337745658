import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";
import { languageMap } from '../../Common/LanguageSelect';
import { lorem, capitalizeFirstLetter } from '../../../utils/helper';

const CommunityIssueComment = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    commentId,
    allCommunityIssues,
    allCommunityIssueComments,
    user,
    authenticated,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];
  const comment = allCommunityIssueComments[commentId];

  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 240,
      fontSize: '14px',
      backgroundColor: '#fff',
      color: '#000',
      textAlign: 'center',
      borderRadius: '10px',
      border: '1px solid #f5f4f9',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 9px 0px',
      padding: '10px'
    },
  }));

  if (!authenticated) {
    return (
      <div
        className={`clsdiscussion-details mb-2 blur ${comment?.user?.roleId !== 3 
          ? 'highlight-comment-official' 
          : 'highlight-comment-resident'}`
        }
      >
        <div 
          className="comment-user-detail mt-0 mb-1" 
          style={{ 
            display: 'flex'
          }}
        >
          <h5 className="comment-username" style={{ width: 'auto' }}>
            <div>
              {`${capitalizeFirstLetter(lorem.generateWords(1))} ${capitalizeFirstLetter(lorem.generateWords(1))}`} 
            </div>
            <br />
            <span className="user-position">
              {`${capitalizeFirstLetter(lorem.generateWords(2))}, ${capitalizeFirstLetter(lorem.generateWords(1))} County`}
            </span>
          </h5>
        </div>
        <p className="discussion-comment">
          {lorem.generateParagraphs(1)}
        </p>
      </div>
    )
  }
  else return (
    <div
      className={`clsdiscussion-details mb-2 ${comment?.user?.roleId !== 3 
        ? 'highlight-comment-official' 
        : 'highlight-comment-resident'}`
      }
    >
      <div 
        className="comment-user-detail mt-0 mb-1" 
        style={{ 
          display: 'flex'
        }}
      >
        <h5 className="comment-username" style={{ width: 'auto' }}>
          <NavLink
            to={'/dashboard/user-profile/?id=' + comment.userId}
            activeClassName="active"
            exact
          >
            {comment?.user?.displayName ?? ''}
          </NavLink>
          <br />
          <span className="user-position">
            {comment?.user?.displayPlace ?? ''}
          </span>
        </h5>
        <div className="mr-3 globe-box">
          {(user.selected_language !== comment?.originalLanguage) &&
            <HtmlTooltip placement="bottom" PopperProps={{
              onPointerDown: e => {
                if (e.target instanceof Element && !e.target.classList.contains("MuiTooltip-tooltip")) {
                  e.preventDefault();
                }
              },
            }} title={comment?.translation ?
              <>{t(`This content was originally written in`) + '\u00A0' + `${t(languageMap?.[comment?.originalLanguage]?.label)}.`}
                <a className="translation-link" href='https://civicl.ink/aitranslation'
                  target="_blank">{'\u00A0' + t('Learn more about our AI-based language translation.')}
                </a>
                <button className="translate-btn clsyellow-translate-btn"
                  onClick={() => setShowOriginalLanguage(!showOriginalLanguage)}>
                  {showOriginalLanguage ? t('Revert to requested language') : t('Show in original language')}</button>
              </> : <>{t('No translation exists for this comment.')}</>}>
              {comment?.translation ?
                <img src={require('../../../assets/img/icon-language.svg').default} alt="Globe" /> :
                <img src={require('../../../assets/img/icon-language-crossed.svg').default}
                  alt="Globe Crossed" />}
            </HtmlTooltip>
          }
        </div>
      </div>
      <p className="discussion-comment">
        {user.selected_language === comment.originalLanguage || showOriginalLanguage ?
          comment.content :
          (JSON.parse(comment.translation)?.[user.selected_language]?.content) ?
            JSON.parse(comment.translation)?.[user.selected_language]?.content : comment.comment
        }
      </p>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueComments: state.communityIssues.allCommunityIssueComments,
    user: state.account.user,
    authenticated: state.authentication.authenticated,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueComment);