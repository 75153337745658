/*
 *
 * Government Official
 *
 */

import React from 'react';
const GovtOfficial = (props) => {
  return (
    <div className="text-center">
      <h5>GOVERNMENT OFFICIAL PAGE IS IN PROGRESS</h5>
    </div>
  );
};

export default GovtOfficial;
