/**
 * Display All Engagement List
 */

import React from 'react';
import Survey from './survey';
import Post from './post';
import Discussion from './discussion';
import EngagementLink from './engagementLink';
import { useTranslation } from 'react-i18next';

const Engagements = (props) => {
  const { engagements, status, location } = props;
  const { t } = useTranslation();
  const uri = location.pathname.replace(/^\/|\/$/g, '');

  return (
    <>
      {engagements[status].length === 0 ? (
        <div className={'mb-3 no-engagement'}>
          <span className={'sad-img'}>
            <img
              src={require('../../../assets/img/sad.svg').default}
              alt="No Engagements"
            />
          </span>
          <span className="no-engagement-txt">
            {uri === 'dashboard'
              ? t(`You don’t have any engagements here.`)
              : t(`None of your public officials have posted anything yet.`)}
          </span>
          <EngagementLink />
        </div>
      ) : (
        engagements[status].map((engagement, index) =>
          engagement.category === 'survey' ? (
            <Survey key={index} {...props} engagement={engagement} />
          ) : engagement.category === 'post' ? (
            <Post key={index} {...props} engagement={engagement} />
          ) : engagement.category === 'discussion' ? (
            <Discussion key={index} {...props} engagement={engagement} />
          ) : (
            ''
          ),
        )
      )}
    </>
  );
};

export default Engagements;
