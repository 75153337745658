import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import actions from "../../../actions";
import {Modal} from "react-bootstrap";

const ResidentStepTwo = (props) => {

    const {
        registration,
        registerChange,
        sendConfirmationText,
        verifyCode,
        codeValidationError,
        showCloseButton,
        updateUserPhoneNumber,
        registrationFormErrors,
        registrationFormSubmissionErrors
    } = props;

    const {t} = useTranslation();

    const [showChangePhoneNumber, setShowChangePhoneNumber] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(registration.registerFormData.phoneNumber);
    const [verificationCodeSent, setVerificationCodeSent] = useState(false);

    useEffect(() => {
        if (verificationCodeSent) {
            setTimeout(() => { setVerificationCodeSent(false); }, 2000);
        }
    }, [verificationCodeSent]);

    const sendVerificationCode = () => {
        sendConfirmationText();
        setVerificationCodeSent(true);
    }

    const handleOnClick = () => {
        window.location = 'mailto:support@civicbell.com';
    }

    const handleAllowPhoneNumberChange = () => {
        setShowChangePhoneNumber(true);
    }

    const handleChangePhoneNumber = () => {
        updateUserPhoneNumber().then((response) => {
            if (response) {
                sendConfirmationText();
                setPhoneNumber(registration.registerFormData.phoneNumber);
                setShowChangePhoneNumber(false);
            }
        });
    }

    const getRegistrationFormError = (field) => {
        if (typeof registrationFormSubmissionErrors === 'string') {
            return registrationFormSubmissionErrors.toLowerCase().includes(field) ? registrationFormSubmissionErrors : '';
        }
        if (Array.isArray(registrationFormSubmissionErrors)) {
            return registrationFormSubmissionErrors.find((error) => {
                return error.toLowerCase().includes(field);
            });
        }

        return '';
    };

    return <>
        <Modal.Header closeButton={showCloseButton}>
            <Modal.Title className="w-100 text-center">
                {t(`We've sent you a code!`)}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group col-md-12">{t(`Please enter the code we've just sent
                to`)}<br/><strong>{phoneNumber}</strong>
                <span
                    className="float-right"
                    style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: 'rgb(61 61 151)'
                    }}
                    onClick={handleAllowPhoneNumberChange}
                >{t(`Not your phone number?`)}</span>
            </div>

            <div style={{display: showChangePhoneNumber ? 'block' : 'none'}}>
                <div className="form-group col-md-12">
                    <div className="position-relative">
                        <div className="input-icon">
                            <img width="30px" src={require('../../../assets/img/us-flag.svg').default} alt="US flag"/>
                        </div>
                        <input
                            type="tel"
                            className="form-control pl-5"
                            placeholder={t(`Cell Phone Number`)}
                            name={'phoneNumber'}
                            value={registration.registerFormData.phoneNumber}
                            onChange={(e) => registerChange(e.target.name, e.target.value)}
                            onFocus={(e) => registerChange(e.target.name, e.target.value)}
                            inputmode="numeric"
                            pattern="[0-9]*"
                        />
                    </div>
                    {((typeof registrationFormErrors !== 'undefined' && registrationFormErrors.phone_number) || getRegistrationFormError('phone')) && (
                        <div className="clsinvalid">{t(getRegistrationFormError('phone')) || t(registrationFormErrors?.['phone_number'])}</div>)}
                </div>

                <div className="form-group col-md-12" style={{textAlign: 'center'}}>
                    <button
                        type="button"
                        id="regevent_verify_code"
                        className="btn clsyellow-btn"
                        onClick={handleChangePhoneNumber}
                        style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>{t(`Change my phone number`)}
                    </button>
                </div>
            </div>

            <div style={{display: !showChangePhoneNumber ? 'block' : 'none'}}>
                <div className="form-group col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t(`7-digit code`)}
                        name={'verificationCode'}
                        value={registration.registerFormData.verificationCode}
                        onChange={(e) => registerChange(e.target.name, e.target.value)}
                        autoComplete={'no'}
                        id="regevent_verificationCode"
                    />
                    {codeValidationError && <div className="clsinvalid">{t(`Your verification code is wrong or expired. Please request a new one.`)}</div>}
                </div>

                <div className="form-group col-md-12" style={{textAlign: 'center'}}>
                    <button
                        type="button"
                        id="regevent_verify_code"
                        className="btn clsyellow-btn"
                        onClick={verifyCode}
                        style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>{t(`Continue`)}
                    </button>
                </div>
            </div>

            <div className="form-group col-md-12">
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                  <span
                      style={{
                          textDecoration: verificationCodeSent ? 'none' : 'underline',
                          cursor: verificationCodeSent ? 'default' : 'pointer',
                          color: 'rgb(61 61 151)'
                      }}
                      onClick={verificationCodeSent ? () => {} : sendVerificationCode}
                  >{t(verificationCodeSent ? `Verification Code Sent` : `Resend Verification Code`)}</span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: 'rgb(61 61 151)'
                        }}
                        onClick={handleOnClick}
                    >{t(`Contact Support`)}</span>
                </div>
            </div>
        </Modal.Body>
    </>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        codeValidationError: state.registration.codeValidationError,
        registrationFormErrors: state.registration.formErrors,
        registrationFormSubmissionErrors: state.registration.formSubmissionErrors
    };
};

export default connect(mapStateToProps, actions)(ResidentStepTwo);
