/*
 *
 * Verify Email actions
 *
 */

import axios from 'axios';
import { BASE_API_URL } from '../../constants';
import { addNotification } from '../Notification/actions';
import {
  SET_EMAIL_VERIFIED,
  SET_ERROR_NOTIFICATION,
  RESET_APP,
} from './constants';
import { setAuth } from '../Authentication/actions';
import setToken from '../../utils/token';

export const resetVerifyEmailState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const verifyEmail = () => {
  return async (dispatch, getState) => {
    let pathName = getState().router.location.pathname;
    pathName = pathName.replace('/verifyemail/', '');

    if (pathName !== '') {
      if (!getState().verifyEmail.isEmailVerified) {
        await axios
          .post(BASE_API_URL + '/verifyEmail/' + pathName, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            if (response.data.success === true) {
              dispatch({ type: SET_ERROR_NOTIFICATION, payload: false });
              dispatch({
                type: SET_EMAIL_VERIFIED,
                payload: { isEmailVerified: true, isValidEmail: true },
              });
            }
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);

            setToken(response.data.access_token);

            dispatch(setAuth());
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'verifyEmail',
              }),
            );
          })
          .catch((error) => {
            dispatch(
              addNotification({
                type: 'error',
                message: error?.response?.data?.error,
                module: 'verifyEmail',
              }),
            );
          })
          .finally(() => {
            dispatch({
              type: SET_EMAIL_VERIFIED,
              payload: { isEmailVerified: true, isValidEmail: false },
            });
          });
      } else {
        dispatch(
          addNotification({
            type: 'error',
            message: "We couldn't process your request as it is invalid.",
            module: 'verifyEmail',
          }),
        );
        dispatch({
          type: SET_EMAIL_VERIFIED,
          payload: { isEmailVerified: true, isValidEmail: false },
        });
      }
    } else {
      dispatch(
        addNotification({
          type: 'error',
          message: "We couldn't process your request as it is invalid.",
          module: 'verifyEmail',
        }),
      );
      dispatch({
        type: SET_EMAIL_VERIFIED,
        payload: { isEmailVerified: true, isValidEmail: false },
      });
    }
    return {
      type: SET_EMAIL_VERIFIED,
      payload: { isEmailVerified: true, isValidEmail: false },
    };
  };
};
