/**
 *
 * Resident Profile
 *
 */

import React, { useState, useRef } from 'react';
import Notification from '../../../containers/Notification';
import { profileImg } from '../../../utils/helper';
import ResidentEdit from '../../../containers/Dashboard/residentEdit';
import ProfileEdit from '../../../containers/Dashboard/profileEdit';
import ProfileImage from '../../../containers/Dashboard/profileImage';
import SocialLinks from '../../../containers/Dashboard/socialLink';
import { useTranslation } from 'react-i18next';
import { isJson } from '../../../utils/helper';
import convertFromJSONToHTML from '../../Common/StateToHtml';

const Resident = (props) => {
  const hashUrl = '#';
  const { user, setEditProfileData, setProfileFormData, setSocialLinkData } =
    props;
  const { t } = useTranslation();

  const [residentEdit, setResidentEdit] = useState('');
  const [editProfileLoaded, setEditProfileLoaded] = useState(false);
  const residentEditRef = useRef(null);

  const [profileEdit, setProfileEdit] = useState('');
  const [profileEditLoaded, setProfileEditLoaded] = useState(false);

  const [imgBlock, setImgBlock] = useState('');
  const [imgBlockLoaded, setImgBlockLoaded] = useState(false);
  const imgBlockRef = useRef(null);

  const [socialLinkBlock, setSocialLinkBlock] = useState('');
  const [socialLinkLoaded, setSocialLinkLoaded] = useState(false);
  const socialLinkRef = useRef(null);

  const renderComponent = (Component, type, formType = '') => {
    if (type === 'editProfile') {
      return new Promise(async (resolve, reject) => {
        await setEditProfileData(user).then(() => {
          setResidentEdit(<Component {...props} type={type} user={user} />);
          return resolve(true);
        });
      });
    } else if (type === 'profileEdit') {
      return new Promise(async (resolve, reject) => {
        await setProfileFormData(user, formType).then(() => {
          setProfileEdit(<Component formType={formType} />);
          return resolve(true);
        });
      });
    } else if (type === 'profileImage') {
      return new Promise((resolve, reject) => {
        setImgBlock(<Component />);
        return resolve(true);
      });
    } else if (type === 'socialLink') {
      return new Promise(async (resolve, reject) => {
        await setSocialLinkData(user).then(() => {
          setSocialLinkBlock(<Component timer={new Date().getTime()} />);
          return resolve(true);
        });
      });
    }
  };

  const showProfileEdit = (event, user) => {
    residentEditRef.current.setAttribute(
      'data-target',
      '#edit-profile-' + user.userId,
    );
    if (editProfileLoaded === false) {
      residentEditRef.current.click();
      setEditProfileLoaded(true);
    }
  };

  const showProfileEditForm = (event, user, formType) => {
    event.target.setAttribute(
      'data-target',
      '#profile-edit-' + formType + 'form-modal',
    );
    if (profileEditLoaded === false) {
      event.target.click();
      setProfileEditLoaded(true);
    }
  };

  const showImgBlock = (event, user) => {
    imgBlockRef.current.setAttribute(
      'data-target',
      '#profile-image-modal-' + user.userId,
    );
    if (imgBlockLoaded === false) {
      imgBlockRef.current.click();
      setImgBlockLoaded(true);
    }
  };

  const showSocialLinkBlock = (event, user) => {
    socialLinkRef.current.setAttribute(
      'data-target',
      '#social-link-modal-' + user.userId,
    );
    if (socialLinkLoaded === false) {
      socialLinkRef.current.click();
      setSocialLinkLoaded(true);
    }
  };

  const renderAboutMeJson = () => {
    return (
      isJson(user.aboutMe) === true ? (
        <p
          dangerouslySetInnerHTML={
            convertFromJSONToHTML(user.aboutMe).__html === '<p><br></p>'
              ? { __html: t('Please tell us about you.') }
              : user.selected_language === user.originalLanguage ?
                  convertFromJSONToHTML(user.aboutMe)
                :
                  (JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe) ? 
                    { __html: JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe}
                  :
                    convertFromJSONToHTML(user.aboutMe)
          }
        ></p>
      ) : (
        <p style={{ whiteSpace: 'pre-wrap' }}>
          {user.aboutMe === null || user.aboutMe === ''
            ? t('Please tell us about you.')
            : user.aboutMe}
        </p>
      )
    );
  }

  const renderAboutMeHtml = () => {
    return (
        <p
          dangerouslySetInnerHTML={
            user.selected_language === user.originalLanguage ?
            { __html: user.aboutMe_html }
              :
                (JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe) ? 
                  { __html: JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe}
                :
                  { __html: user.aboutMe_html }
          }
        ></p>
    );
  }

  var socialPlatform_Email = '0';
  if (user.socialPlatform.email !== '') {
    socialPlatform_Email = user.socialPlatform.email;
  }

  return (
    <>
      <Notification module={'profile'} />
      {/*<ProgressBar user={user} />*/}
      {imgBlock}
      <div className="clsprofile-details-blk clswhite-box mt-5">
        <div className="clsprofile-details">
          <div className="media">
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                renderComponent(ProfileImage, 'profileImage').then(() => {
                  showImgBlock(event, user);
                });
              }}
              ref={imgBlockRef}
            >
              <img
                src={profileImg(user.profile_picture)}
                alt="Avatar"
                className={`profile-img`}
              />
            </a>
            <div className="media-body align-self-end mb-1 ml-3">
              <h5 className="mt-0">
                {user.showMyNameAs ? (
                  <div> {user.show_my_name}</div>
                ) : (
                  <div>
                    {' '}
                    {user.first_name} {user.last_name}
                  </div>
                )}
              </h5>
              <p>
                {user.city}{', '}{user.state}
              </p>
            </div>
          </div>
          {residentEdit}
          <div className="clsprofile-followers clsresident-profile-edit">
            <p className="clsprofile-edit">
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target={hashUrl}
                onClick={(event) => {
                  renderComponent(ResidentEdit, 'editProfile').then(() => {
                    showProfileEdit(event, user);
                  });
                }}
                ref={residentEditRef}
              >
                {t(`Edit`)}
              </a>
            </p>
          </div>
        </div>
      </div>
      {profileEdit}
      <div className="clsprofile-about-blk clswhite-box mt-3">
        <h5 className="mb-2 pb-1">
          {t(`ABOUT ME`)}{' '}
          <span>
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                renderComponent(ProfileEdit, 'profileEdit', 'aboutMe').then(
                  () => {
                    showProfileEditForm(event, user, 'aboutMe');
                  },
                );
              }}
            >
              {t(`Edit`)}
            </a>
          </span>
        </h5>
        {!!user.aboutMe_html ? renderAboutMeHtml() : renderAboutMeJson()}
      </div>
      {socialLinkBlock}
      <div className="clsprofile-socialmedia-blk clswhite-box mt-3">
        <h5 className="mb-2 pb-1">
          {t(`MY SOCIAL MEDIA`)}{' '}
          <span>
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                renderComponent(SocialLinks, 'socialLink').then(() => {
                  showSocialLinkBlock(event, user);
                });
              }}
              ref={socialLinkRef}
            >
              {t(`Edit`)}
            </a>
          </span>
        </h5>
        <ul>
          <li>
            <a
              href={
                user.socialPlatform.twitter !== ''
                  ? user.socialPlatform.twitter
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/x-twitter.svg').default}
                alt="Twitter"
              />
            </a>
          </li>
          <li>
            <a
              href={
                user.socialPlatform.facebook !== ''
                  ? user.socialPlatform.facebook
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/facebook.svg').default}
                alt="Facebook"
              />
            </a>
          </li>
          <li>
            <a
              href={
                user.socialPlatform.linkedIn !== ''
                  ? user.socialPlatform.linkedIn
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/linkedin.svg').default}
                alt="Linkedin"
              />
            </a>
          </li>
          <li>
            <a
              href={
                user.socialPlatform.nextDoor !== ''
                  ? user.socialPlatform.nextDoor
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/nextdoor-social.png').default}
                alt="Nextdoor"
              />
            </a>
          </li>
          {socialPlatform_Email ? (
            <li>
              <a href={'mailto:' + user.socialPlatform.email} target={`_blank`}>
                <img
                  src={require('../../../assets/img/envelope.svg').default}
                  alt="Email"
                />
              </a>
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </>
  );
};

export default Resident;
