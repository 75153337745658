/**
 *
 * Display Leave Event?
 *
 */

import React, { useEffect } from 'react';
import $ from 'jquery';
import CivicModal from '../../Common/CivicModal';
import { useTranslation } from 'react-i18next';

const ConfirmPopup = (props) => {
  const { joinEvent, deleteEvent, confirmPopup, eventChange } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (confirmPopup !== null) {
      $('#join-confirmation-modal').modal({
        backdrop: 'static',
        keyboard: false,
      });
    }
  }, [confirmPopup]);

  return (
    <CivicModal
      id={`join-confirmation-modal`}
      title={t(`Are you sure?`)}
      hideClose={true}
      dismissal={false}
      modalClass={`clsnew-petition-modal`}
      footerClass={`text-center mt-4`}
    >
      <p className={`email-confirmation-txt`}>
        {confirmPopup.action === 'leave'
          ? t(`Leaving this event!`)
          : t(`Deleting this event!`)}
      </p>
      <div className="text-center mt-4">
        {confirmPopup.action === 'leave' ? (
          <button
            type="button"
            className="btn clsyellow-btn mr-4"
            data-dismiss="modal"
            onClick={async (e) => {
              await joinEvent(confirmPopup.event, confirmPopup.month);
            }}
          >
            {t(`Yes`)}
          </button>
        ) : (
          <button
            type="button"
            className="btn clsyellow-btn mr-4"
            data-dismiss="modal"
            onClick={async (e) => {
              await deleteEvent(confirmPopup.event, confirmPopup.month);
            }}
          >
            {t(`Yes`)}
          </button>
        )}
        <button
          type="button"
          className="btn clsyellow-btn ml-4"
          data-dismiss="modal"
          onClick={(e) => {
            eventChange({ confirmPopup: null });
          }}
        >
          {t(`No`)}
        </button>
      </div>
    </CivicModal>
  );
};

export default ConfirmPopup;
