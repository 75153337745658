/**
 *
 * Survey Question Form Footer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import Tooltip from '../../Common/Tooltip';
import { useTranslation } from 'react-i18next';

const QuestionFooter = (props) => {
  const { engagement, location, submitSurvey } = props;
  const { t } = useTranslation();
  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const hashUrl = '#';

  return (
    <>
      {(uri === 'dashboard/civicfeed' ||
        uri === 'dashboard/user-profile' ||
        uri === 'dashboard/civiclist' ||
        uri === 'dashboard/engagement') &&
      engagement.stage === 'open' ? (
        <>
          <a
            href={hashUrl}
            id={`question-submit-${engagement.id}`}
            className="btn clsyellow-btn clsanchor-btn mr-3"
            onClick={() => {
              if (engagement.canReact === true) {
                submitSurvey(engagement.id);
              } else {
                return;
              }
            }}
          >
            {t(`Submit`)}
          </a>
          {engagement.canReact === false ? (
            <Tooltip
              targetId={`question-submit-${engagement.id}`}
              targetStatus={true}
              styleClass={'react-tooltip'}
              message={t(engagement.restriction_reason)}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <button type="button" className="btn clsyellow-btn" data-dismiss="modal">
        {t(`Close`)}
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    surveyData: state.surveyQuestion.surveyData,
  };
};

export default connect(mapStateToProps, actions)(QuestionFooter);
