/*
 *
 * Close engagement constants
 *
 */

export const CLOSE_ENGAGEMENT_CHANGE =
  'src/CloseEngagement/CLOSE_ENGAGEMENT_CHANGE';
export const CLOSE_ENGAGEMENT_RESET =
  'src/CloseEngagement/CLOSE_ENGAGEMENT_RESET';
export const SET_CLOSE_ENGAGEMENT_LOADING =
  'src/CloseEngagement/SET_CLOSE_ENGAGEMENT_LOADING';
export const SET_CLOSE_ENGAGEMENT_FORM_ERRORS =
  'src/CloseEngagement/SET_CLOSE_ENGAGEMENT_FORM_ERRORS';
export const SET_CLOSE_ENGAGEMENT_SUBMITTING =
  'src/CloseEngagement/SET_CLOSE_ENGAGEMENT_SUBMITTING';
export const SET_CLOSE_ENGAGEMENT_DATA =
  'src/CloseEngagement/SET_CLOSE_ENGAGEMENT_DATA';
export const RESET_APP = 'src/CloseEngagement/RESET_APP';
