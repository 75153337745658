/**
 *
 * Resident Profile Edit Modal
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicModal from '../../components/Common/CivicModal';
import SocialLinkForm from '../../components/Manager/Profile/socialLinkForm';
import { useTranslation } from 'react-i18next';

const SocialLinks = (props) => {
  const { user, socialLinkData } = props;
  const { t } = useTranslation();

  return (
    <>
      <CivicModal
        id={`social-link-modal-${user.userId}`}
        title={t(socialLinkData.title)}
        dismissal={socialLinkData.dismissal}
        modalClass="clsnew-petition-modal"
      >
        <SocialLinkForm {...props} />
      </CivicModal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    socialLinkData: state.account.socialLinkData,
    socialLinkError: state.account.socialLinkError,
    isProfileLoading: state.account.isProfileLoading,
  };
};

export default connect(mapStateToProps, actions)(SocialLinks);
