import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';

const CommunityIssueTopicList = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    allCommunityIssues,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div className="clsbadge-tag-list mt-2 mb-2">
      <ul>
        <li>
          <span className="badge clslight-grey-badge">
            {communityIssue.topic.name}
          </span>
        </li>
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueTopicList);