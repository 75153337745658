import React from "react";
import {connect} from "react-redux";
import actions from "../../../actions";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {CivicIcons} from "../../Common/CivicIcons";

const ResidentStepFive = (props) => {

    const {
        registration,
        registerChange,
        verifyZipCode,
        showCloseButton,
        registrationFormErrors
    } = props;

    const {t} = useTranslation();

    const returnToFeed = () => {
        verifyZipCode();
    }

    return <>
        <Modal.Header closeButton={showCloseButton}>
            <Modal.Title className="w-100 text-center">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <div>
                        <CivicIcons.WarningIconFilled color="red" size={66} />
                    </div>
                    <div>
                        {t(`Sorry, we couldn't verify your information`)}
                    </div>
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group col-md-12">
                {t(`Is your mailing address different from your residential address? We couldn't verify your address with your cell phone carrier.`)}
            </div>

            <div className="form-group col-md-12">
                {t(`Please confirm the address your carrier has on file with you. This can be any address, including a PO Box or family plan address.`)}
            </div>

            <div className="form-group col-md-12">
                <input
                    type="text"
                    className="form-control"
                    placeholder={t(`Address / PO Box`)}
                    name={'address'}
                    value={registration.registerFormData.address}
                    onChange={(e) => registerChange(e.target.name, e.target.value)}
                    autoComplete={'no'}
                    id="regevent_address"
                />
                {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.address && (
                    <div className="clsinvalid">{t(registrationFormErrors?.['address'])}</div>
                )}
            </div>

            <div className="form-group col-md-12">
                <input
                    type="text"
                    className="form-control"
                    placeholder={t(`City`)}
                    name={'city'}
                    value={registration.registerFormData.city}
                    onChange={(e) => registerChange(e.target.name, e.target.value)}
                    autoComplete={'no'}
                    id="regevent_city"
                />
                {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.city && (
                    <div className="clsinvalid">{t(registrationFormErrors?.['city'])}</div>
                )}
            </div>

            <div className="form-group col-md-12">
                <input
                    type="text"
                    className="form-control"
                    placeholder={t(`State`)}
                    name={'state'}
                    value={registration.registerFormData.state}
                    onChange={(e) => registerChange(e.target.name, e.target.value)}
                    autoComplete={'no'}
                    id="regevent_state"
                />
                {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.state && (
                    <div className="clsinvalid">{t(registrationFormErrors?.['state'])}</div>
                )}
            </div>

            <div className="form-group col-md-12">
                <input
                    type="text"
                    className="form-control"
                    placeholder={t(`ZIP Code`)}
                    name={'zipCode'}
                    value={registration.registerFormData.zipCode}
                    onChange={(e) => registerChange(e.target.name, e.target.value)}
                    autoComplete={'no'}
                    id="regevent_zipCode"
                />
                {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.zipCode && (
                    <div className="clsinvalid">{t(registrationFormErrors?.['zipCode'])}</div>
                )}
            </div>

            <div className="form-group col-md-12 align-center">
                <button
                    type="button"
                    id="regevent_update_zipCode"
                    className="btn clsyellow-btn"
                    onClick={returnToFeed}
                    style={{
                        color: 'black',
                        fontWeight: 'bold'
                    }}>{t(`Confirm and return to CivicFeed`)}
                </button>
            </div>
        </Modal.Body>
    </>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        registrationFormErrors: state.registration.formErrors,
    };
};

export default connect(mapStateToProps, actions)(ResidentStepFive);
