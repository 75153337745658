/*
 *
 * District Offcial View
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import ProfileView from '../../components/Manager/Profile/profile-view';
import NoData from '../../components/Manager/Profile/noData';

const View = (props) => {
  const { location, setUserProfile, user, userProfile } = props;

  const Id = new URLSearchParams(location.search).get('id');

  const [loaded, setLoaded] = useState(false);

  const loadProfile = useCallback(async () => {
    //if(typeof user.roleName !== 'undefined') {
    if (Id === null) {
      setLoaded(true);
    } else {
      await setUserProfile(Id).then(() => {
        setLoaded(true);
      });
    }
    //}
  }, [setUserProfile, Id, setLoaded]);

  useEffect(() => {
    if (loaded === false) {
      loadProfile();
    }
  }, [loaded, loadProfile, user.roleName]);

  return (
    <>
      {loaded === false ? <LoadingIndicator position={`fixed`} /> : ''}
      {Id !== null && loaded === true && typeof userProfile !== 'undefined' ? (
        <div>{<ProfileView {...props} userProfile={userProfile} />}</div>
      ) : (
        <NoData />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    userProfile: state.civicFeed.userProfile,
  };
};

export default connect(mapStateToProps, actions)(View);
