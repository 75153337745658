/*
 *
 * Complete engagement reducer
 *
 */

import {
  COMPLETE_ENGAGEMENT_CHANGE,
  COMPLETE_ENGAGEMENT_RESET,
  SET_COMPLETE_ENGAGEMENT_LOADING,
  SET_COMPLETE_ENGAGEMENT_FORM_ERRORS,
  SET_COMPLETE_ENGAGEMENT_SUBMITTING,
  SET_COMPLETE_ENGAGEMENT_DATA,
  RESET_APP,
} from './constants';

const initialState = {
  completeEngagementData: [
    {
      comment: '',
      dismissal: false,
    },
  ],
  completeEngagementErrors: [],
  completeEngagementLoading: false,
  completeEngagementSubmitting: false,
};

const defaultState = JSON.stringify(initialState);

const completeEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_COMPLETE_ENGAGEMENT_DATA:
      return {
        ...state,
        completeEngagementData: {
          ...state.completeEngagementData,
          ...action.payload,
        },
      };
    case COMPLETE_ENGAGEMENT_CHANGE:
      return {
        ...state,
        completeEngagementData: {
          ...state.completeEngagementData,
          ...action.payload,
        },
      };
    case SET_COMPLETE_ENGAGEMENT_FORM_ERRORS:
      return {
        ...state,
        completeEngagementErrors: action.payload,
      };
    case SET_COMPLETE_ENGAGEMENT_LOADING:
      return {
        ...state,
        completeEngagementLoading: action.payload,
      };
    case SET_COMPLETE_ENGAGEMENT_SUBMITTING:
      return {
        ...state,
        completeEngagementSubmitting: action.payload,
      };
    case COMPLETE_ENGAGEMENT_RESET:
      return {
        ...state,
        completeEngagementData: {
          comment: '',
          dismissal: false,
        },
        completeEngagementErrors: [],
        completeEngagementLoading: false,
      };
    default:
      return state;
  }
};

export default completeEngagementReducer;
