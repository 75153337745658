/*
 *
 * Search Result actions
 *
 */

import { DEFAULT_ACTION, SEARCH_RESULT_CHANGE } from './constants';

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
};

export const searchResultChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: SEARCH_RESULT_CHANGE, payload: formData });
  };
};
