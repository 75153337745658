/*
 *
 * Login Form
 *
 */

import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import LoadingIndicator from '../../Common/LoadingIndicator';
import envelope from '../../../assets/img/envelope.svg';
import passimg from '../../../assets/img/pass.svg';
import Notification from '../../../containers/Notification';
import { useTranslation } from 'react-i18next';

const LoginForm = (props) => {
  const history = useHistory();

  const {
    loginFormData,
    loginChange,
    login,
    formErrors,
    isLoading,
    isSubmitting,
    activeTab,
    resendEmailConfirmation,
    resendConfirmation,
  } = props;
  const { t } = useTranslation();

  const prevPathInitiative =
    history.location.state !== undefined ? history.location.state.prevPath : '';

  const handleSubmit = (event) => {
    event.preventDefault();
    login(prevPathInitiative);
  };

  const navigateToForgotPassword = (event) => {
    history.push('/forgotpassword');
  };

  return (
    <div
      className={activeTab === 1 ? 'tab-pane show active' : 'tab-pane show'}
      id="login"
      role="tabpanel"
      aria-labelledby="home-tab"
      style={{ position: isLoading ? 'relative' : '' }}
    >
      {isLoading && <LoadingIndicator />}
      <Notification module={'login'} />
      {resendConfirmation === true ? (
        <div className="alert alert-danger clsalert-danger">
          <span>
            {t('Click the verification link sent to your registered email.')}{' '}
            <Link to={`#`} onClick={() => resendEmailConfirmation()}>
              {t(`Resend verification email`)}
            </Link>
          </span>
        </div>
      ) : (
        ''
      )}
      <form className="pb-1 mb-1" onSubmit={handleSubmit} noValidate>
        <div className="form-group">
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <img src={envelope} alt="Mail" />
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder={t(`Email`)}
              name={'username'}
              value={loginFormData.username}
              onChange={(e) => loginChange(e.target.name, e.target.value)}
            />
          </div>
          {formErrors['username'] ? (
            <div className="clsinvalid">{t(formErrors?.['username'])}</div>
          ) : (
            ''
          )}
        </div>
        <div className="form-group">
          <div className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <img src={passimg} alt="Password" />{' '}
              </div>
            </div>
            <input
              type="password"
              className="form-control"
              placeholder={t(`Password`)}
              name={'password'}
              value={loginFormData.password}
              autoComplete="off"
              onChange={(e) => loginChange(e.target.name, e.target.value)}
            />
          </div>
          {formErrors['password'] ? (
            <div className="clsinvalid">{t(formErrors?.['password'])}</div>
          ) : (
            ''
          )}
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn clsyellow-btn"
            disabled={isSubmitting}
          >
            {t(`Log In`)}
          </button>
        </div>
        <p className="clsforgot-text text-center">
          <Link to={`#`} onClick={() => navigateToForgotPassword()}>
            {t(`Forgot Password?`)}
          </Link>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;
