/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef } from 'react';
import { $getRoot } from 'lexical';

import Editor from './editor';
import { convertLexicalEditorStateToHtml, loadLexicalEditorFromHtml } from './lexical-utils';
import convertFromJSONToHTML from '../StateToHtml';
import { convertLexicalToDraftJs } from '../../../utils/draftjs';

const EMPTY_CONTENT = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const LexicalEditor = ({ 
  initialHtml, 
  initialDraftJs, 
  onLoad, 
  onHtmlChange, 
  onTextContentChange, 
  onDraftJsChange, 
  reloadToggle, 
  placeholder, 
  displayColor,
  displayScripts
}) => {
  const [loaded, setLoaded] = useState(false);

  const [lexicalEditorState, setLexicalEditorState] = useState(null);
  const [lexicalEditor, setLexicalEditor] = useState(null);

  const previousReload = useRef(reloadToggle);
  const previousHtml = useRef(initialHtml);

  useEffect(() => {
    // Only reload if there's an externally triggered change in the HTML, to avoid infinite loops.
    if (previousReload.current !== reloadToggle && previousHtml.current !== initialHtml) {
      setLoaded(false);
      const editorState = lexicalEditor.parseEditorState(EMPTY_CONTENT);
      lexicalEditor.setEditorState(editorState);

      previousReload.current = reloadToggle;
      previousHtml.current = initialHtml;
    }
  }, [reloadToggle, initialHtml, lexicalEditor]);

  // Final loading phase for the Lexical editor
  useEffect(() => {
    if (lexicalEditor && !loaded) {
      if (!initialHtml) { 
        if (initialDraftJs) { // Load draft.js data into the editor if it exists
          const html = convertFromJSONToHTML(initialDraftJs).__html;
          loadLexicalEditorFromHtml(html, lexicalEditor).then(() => {
            setLoaded(true);
          });
        } else { // If there's no existing comment data, just load the editor
          setLoaded(true);
        }
      } else {
        loadLexicalEditorFromHtml(initialHtml, lexicalEditor).then(() => {
          setLoaded(true);
        });
      }
    }
  }, [lexicalEditor, initialHtml, initialDraftJs, loaded, reloadToggle]) 

  useEffect(() => {
    // Only run this effect once
    if (!loaded && onLoad) {
      onLoad();
    }
  }, [loaded, onLoad]);

  const updateLexicalHtml = useCallback(async (editorState) => {
    const lexicalHtml = await convertLexicalEditorStateToHtml(editorState);
    previousHtml.current = lexicalHtml;

    if (onHtmlChange) onHtmlChange(lexicalHtml);
    if (onDraftJsChange) onDraftJsChange(convertLexicalToDraftJs(lexicalHtml));
    return lexicalHtml;
  }, []);

  // Sync the Lexical editor state with the engagement form data
  useEffect(() => {
    if (loaded && lexicalEditorState && lexicalEditor) {
      updateLexicalHtml(lexicalEditorState);

      // Reverse compatibility with the old editor and DraftJS
      lexicalEditorState.read(() => {
        const content = $getRoot().getTextContent() // Converts everything to plain text for parsing
        if (onTextContentChange) onTextContentChange(content);
      });
    }
  }, [lexicalEditorState, loaded, lexicalEditor, updateLexicalHtml]);

  return (
    <Editor onEditorStateChange={setLexicalEditorState} onEditorChange={setLexicalEditor} placeholder={placeholder} displayColor={displayColor} displayScripts={displayScripts} />
  )
}

export default LexicalEditor;