import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';

const ShareLink = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
  } = props;

  const [clickedCopy, setClickedCopy] = useState(false);

  async function clickCopy () {
    setClickedCopy(true);
    await new Promise(resolve => setTimeout(resolve, 5000));
    setClickedCopy(false);
  }

  return (
    <div className='share-link'>
      <div className='left-container'>
        <img
          src={require('../../../assets/img/link.svg').default}
          alt="Link"
        />
        <a>
          {`https://www.civicbell.com/community-issues/?id=${communityIssueId}`}
        </a>
      </div>
      {
        !(clickedCopy) ?
          <a
            className='text-btn right-container'
            onClick={() => {
              navigator.clipboard.writeText(`https://www.civicbell.com/community-issues/?id=${communityIssueId}`);
              clickCopy();
            }}
          >
            {t('Copy')}
          </a>
        :
          <b
            className='right-container'
          >
            {t('Copied')}
          </b>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps, actions)(ShareLink);