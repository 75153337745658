/*
 *
 * Events constants
 *
 */

export const CIVICMAP_CHANGE = 'src/Map/CIVICMAP_CHANGE';
export const SET_GEO_JSON = 'src/Map/SET_GEO_JSON';
export const SET_GEO_JSON_ARRAY = 'src/Map/SET_GEO_JSON_ARRAY';
export const SET_MERGE_GEO_JSON = 'src/Map/SET_MERGE_GEO_JSON';
export const SET_CIVICMAP_LOADING = 'src/Map/SET_CIVICMAP_LOADING';
export const FETCH_CIVIC_MAP = 'src/Map/FETCH_CIVIC_MAP';
export const MAP_RELOAD = 'src/Map/MAP_RELOAD';
export const MAP_RESET = 'src/Map/MAP_RESET';
export const OFFICIALS_CHANGE = 'src/Map/OFFICIALS_CHANGE';
export const SET_OFFICIALS_LOADING = 'src/Map/SET_OFFICIALS_LOADING';
export const OFFICIALS_LIST_CHANGE = 'src/Map/OFFICIALS_LIST_CHANGE';
export const OFFICIALS_RELOAD_LIST = 'src/Map/OFFICIALS_RELOAD_LIST';
export const FETCH_OFFICIALS = 'src/Map/FETCH_OFFICIALS';
export const SET_CURRENT_DISTRICT = 'src/Map/SET_CURRENT_DISTRICT';
export const SET_FILTER_LEVEL = 'src/Map/SET_FILTER_LEVEL';
export const RESET_APP = 'src/Map/RESET_APP';
