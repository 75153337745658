/**
 *
 * New Petition Form
 *
 */

import React, { useState, useEffect } from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import CivicTags from '../../Common/Tags';
import FileUploader from '../../Common/FileUploader';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const NewPetitionForm = (props) => {
  const {
    isPetitionLoading,
    petitionFormErrors,
    petitionFormData,
    petitionFormChange,
    submitPetitionForm,
    removePetitionImage,
    petitionFormImageChange,
    divisionNames,
    petitionEditorChange,
    filterDivision,
    districtSelected,
    divisionSelectionChange,
  } = props;

  const [loading, setLoading] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    submitPetitionForm();
  };

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    width: '65%',
  };

  useEffect(() => {
    if (loading === true) {
      const divisionValues = async (e) => {
        await divisionNames().then(() => {
          setLoading(false);
        });
      };
      divisionValues();
    }
  }, [loading, divisionNames]);

  // let totalDivision = []

  // petitionDivisionData.map((divisions, index) => {
  //   let petitionDivision = { "value": divisions.name, "label": divisions.name }
  //   return totalDivision.push(petitionDivision)
  // })

  const hashUrl = '#';
  const { t } = useTranslation();

  const [description, setDescription] = useState(EditorState.createEmpty());

  const stringContainingNotify = t(
    `You must get 10 people to join your CivicMotion before it will appear in “Active CivicMotion”. Use the “share” function to spread your CivicMotion manually.`,
  );
  const notifyUser = (
    <div dangerouslySetInnerHTML={{ __html: stringContainingNotify }} />
  );

  useEffect(() => {
    petitionEditorChange(
      'description',
      convertToRaw(description.getCurrentContent()),
    );
  }, [description, petitionEditorChange]);

  return (
    <>
      {typeof petitionFormData !== 'undefined' ? (
        <div className="clsengagement-form clsevent-form">
          <form onSubmit={handleSubmit}>
            {isPetitionLoading === true ? (
              <LoadingIndicator position={`fixed`} />
            ) : (
              ''
            )}
            <div className="clsedit-profile-form-blk">
              <div className="clsedit-profile-lable-blk">
                <label>{t('Initiative Title')}</label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder={t('Enter Title')}
                  className="form-control"
                  name={'title'}
                  onChange={(e) =>
                    petitionFormChange(e.target.name, e.target.value)
                  }
                  value={petitionFormData.title}
                />
                {typeof petitionFormErrors.title !== 'undefined' &&
                  petitionFormErrors.title && (
                    <div className="clsinvalid">
                      {t(petitionFormErrors?.['title'])}
                    </div>
                  )}
              </div>
              <div className="form-group">
                <label>{t('Division')}</label>
                {/* <SelectOption
                  name={'division'}
                  placeholder={t('Select Division')}
                  options={totalDivision}
                  value={handleOptionValue(totalDivision, petitionFormData.division)}
                  handleSelectChange={value => {
                    petitionFormChange('division', value.value);
                  }}>
                </SelectOption> */}
                <div className="tabbable-panel">
                  <div className="tabbable-line mb-2">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {Object.keys(filterDivision).length > 0
                        ? Object.keys(filterDivision).map((level1, index) => (
                            <li
                              className="nav-item"
                              key={index}
                              role="presentation"
                            >
                              <a
                                className={
                                  level1.level1 === level1 || index === 0
                                    ? 'nav-link active'
                                    : 'nav-link'
                                }
                                id={level1 + '-tab'}
                                data-toggle="tab"
                                href={'#' + level1}
                                role="tab"
                                aria-controls={level1}
                                aria-selected="true"
                              >
                                {level1}
                              </a>
                            </li>
                          ))
                        : ''}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {Object.keys(filterDivision).length > 0
                        ? Object.keys(filterDivision).map((level1, index1) => (
                            <div
                              className={
                                level1.level1 === level1 || index1 === 0
                                  ? 'tab-pane show active'
                                  : 'tab-pane show'
                              }
                              key={index1}
                              id={level1}
                              role="tabpanel"
                              aria-labelledby={level1 + '-tab'}
                            >
                              <div className="clsmap-filter-content">
                                <div className="accordion" id="accordionFilter">
                                  {Object.keys(filterDivision[level1]).length >
                                  0 ? (
                                    Object.keys(filterDivision[level1]).map(
                                      (level2, index2) => (
                                        <div className="card" key={index2}>
                                          <div
                                            className="card-header"
                                            id={
                                              'heading-' + index1 + '-' + index2
                                            }
                                          >
                                            <h2 className="mb-0">
                                              <a
                                                href={hashUrl}
                                                data-toggle="collapse"
                                                data-target={
                                                  '#collapse-' +
                                                  index1 +
                                                  '-' +
                                                  index2
                                                }
                                                aria-expanded={
                                                  level2.level2 === level2
                                                    ? true
                                                    : false
                                                }
                                                aria-controls={
                                                  'collapse-' +
                                                  index1 +
                                                  '-' +
                                                  index2
                                                }
                                              >
                                                {level2}
                                              </a>
                                            </h2>
                                          </div>

                                          <div
                                            id={
                                              'collapse-' +
                                              index1 +
                                              '-' +
                                              index2
                                            }
                                            className={
                                              level2.level2 === level2
                                                ? 'collapse show'
                                                : 'collapse'
                                            }
                                            aria-labelledby={
                                              'heading-' + index1 + '-' + index2
                                            }
                                            data-parent="#accordionFilter"
                                          >
                                            <div className="card-body">
                                              <ul>
                                                {filterDivision[level1][
                                                  level2
                                                ].map((map, index3) => (
                                                  <li key={index3}>
                                                    <div className="clsradio">
                                                      <input
                                                        type="radio"
                                                        id={
                                                          'radio-' +
                                                          index2 +
                                                          '-' +
                                                          index3
                                                        }
                                                        name={'division'}
                                                        checked={
                                                          districtSelected[
                                                            'divisionName'
                                                          ].name === map.name
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) => {
                                                          petitionFormChange(
                                                            'division',
                                                            districtSelected[
                                                              'divisionName'
                                                            ].name,
                                                          );
                                                        }}
                                                        value={
                                                          petitionFormData.division
                                                        }
                                                      />
                                                      <label
                                                        htmlFor={
                                                          'radio-' +
                                                          index2 +
                                                          '-' +
                                                          index3
                                                        }
                                                        onClick={(e) => {
                                                          divisionSelectionChange(
                                                            map,
                                                          );
                                                        }}
                                                      >
                                                        <span> {map.name}</span>
                                                      </label>
                                                    </div>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      ),
                                    )
                                  ) : (
                                    <center>No District Mapped for you</center>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{t('Active For')}</label>
                <input
                  type="number"
                  name={'active_for'}
                  className="form-control"
                  onChange={(e) =>
                    petitionFormChange(e.target.name, e.target.value)
                  }
                  value={petitionFormData.active_for}
                  placeholder={t('weeks')}
                />
                <span
                  className={
                    petitionFormData.active_for === ''
                      ? 'active_for'
                      : petitionFormData.active_for === '1'
                      ? 'week active_for_week'
                      : 'active_for_week'
                  }
                ></span>
                {typeof petitionFormErrors.active_for !== 'undefined' &&
                  petitionFormErrors.active_for && (
                    <div className="clsinvalid">
                      {t(petitionFormErrors?.['active_for'])}
                    </div>
                  )}
              </div>
              <div className="form-group">
                <FileUploader
                  fileChange={petitionFormImageChange}
                  fileList={petitionFormData.files}
                  label={t('Upload Image')}
                  maxFiles="1"
                  acceptFormat={'.jpeg, .png, .jpg, .svg, .gif'}
                  removeLink={''}
                  multiple={false}
                  allowedImgWidth={990}
                />
                {petitionFormData.images &&
                petitionFormData.images.length > 0 ? (
                  <div className="form-group">
                    <aside style={thumbsContainer}>
                      <div className="image-preview mt-2">
                        <img
                          src={petitionFormData.images[0]}
                          alt={petitionFormData.title}
                        />
                        <div
                          className="btn image-preview-close"
                          onClick={removePetitionImage}
                        >
                          <img
                            src="/static/media/delete.02cc25b1.svg"
                            alt="Remove File"
                          />
                        </div>
                      </div>
                    </aside>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="form-group">
                <label>{t('Description')}</label>
                <Editor
                  editorState={description}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  wrapperStyle={{
                    border: '2px solid #eee',
                    borderRadius: '10px',
                    marginBottom: '20px',
                  }}
                  editorStyle={{ padding: '10px' }}
                  placeholder={t('Enter')}
                  onEditorStateChange={(editorState) => {
                    setDescription(editorState);
                  }}
                  toolbar={{
                    options: ['inline', 'emoji', 'history', 'link', 'list'],
                    inline: { options: ['bold', 'italic', 'underline'] },
                    list: {
                      options: ['unordered', 'ordered'],
                    },
                    link: {
                      defaultTargetOption: '_blank',
                    },
                  }}
                />
                {typeof petitionFormErrors.description !== 'undefined' &&
                  petitionFormErrors.description && (
                    <div className="clsinvalid">
                      {t(petitionFormErrors?.['description'])}
                    </div>
                  )}
              </div>
              <div className="form-group">
                <label>{t('Topics (Optional)')} (#)</label>
                <CivicTags
                  tagValues={petitionFormData.tags}
                  action={petitionFormChange}
                  id={'petitionTags'}
                />
                {typeof petitionFormErrors.tags !== 'undefined' &&
                  petitionFormErrors.tags && (
                    <div className="clsinvalid">
                      {t(petitionFormErrors?.['tags'])}
                    </div>
                  )}
              </div>
            </div>
            <div className="text-center mb-4 pb-2">
              <button type="submit" className="btn clsyellow-btn">
                {t('Publish')}
              </button>
            </div>
          </form>
          <div className="grey-info" role="alert">
            {notifyUser}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default NewPetitionForm;
