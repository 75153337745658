/*
 *
 * Close engagement actions
 *
 */

import {
  CLOSE_ENGAGEMENT_CHANGE,
  SET_CLOSE_ENGAGEMENT_LOADING,
  SET_CLOSE_ENGAGEMENT_FORM_ERRORS,
  SET_CLOSE_ENGAGEMENT_SUBMITTING,
  SET_CLOSE_ENGAGEMENT_DATA,
  RESET_APP,
} from './constants';
import {
  updateEngagementStatus,
  setReloadEngagementStatus,
} from '../Engagement/actions';
import { allFieldsValidation } from '../../utils/validation';
import moment from 'moment';

export const resetCloseEngagementState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const closeEngagementChange = (id, name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[id] = getState().closeEngagement.closeEngagementData[id];
    formData[id][name] = value;
    return dispatch({ type: CLOSE_ENGAGEMENT_CHANGE, payload: formData });
  };
};

/*export const closeCommentEditChange = (id, value) => {
  let formData = {};
  return async (dispatch, getState) => {
  formData[id] = getState().closeEngagement.closeEngagementData[id];
  formData[id].comment = JSON.stringify(value);
  return dispatch({
    type: CLOSE_ENGAGEMENT_CHANGE,
    payload: formData
  });
  }
}*/

export const setCloseEngagementData = (engagement) => {
  let formData = {};
  let completeTimeFrameStatus = moment(engagement.engagement_completion);

  let completeTimeFrame =
    completeTimeFrameStatus.isValid() === true
      ? moment(engagement.engagement_completion).local().toDate()
      : '';
  /*let completeTimeFrame = moment(engagement.engagement_completion).local().format('MM-DD-YYYY');*/

  formData[engagement.id] = {
    id: engagement.id,
    title: 'Close Engagement',
    comment: '',
    completeTimeFrame: completeTimeFrame,
    dismissal: false,
  };
  return {
    type: SET_CLOSE_ENGAGEMENT_DATA,
    payload: formData,
  };
};

export const submitCloseEngagement = (engagementId) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CLOSE_ENGAGEMENT_FORM_ERRORS, payload: {} });
    dispatch(validateForm(engagementId))
      .then(async () => {
        let engagement =
          getState().closeEngagement.closeEngagementData[engagementId];
        engagement = { 
          ...engagement,
          engagement_end: engagement.completeTimeFrame,
          stage: 'open' 
        };
        dispatch(updateEngagementStatus(engagement, 'closed')).then(() => {
          let formData = {};
          formData[engagementId] = { dismissal: true };
          dispatch({ type: CLOSE_ENGAGEMENT_CHANGE, payload: formData });
          //dispatch(resetEngagementData('closed'));
          dispatch(setReloadEngagementStatus(true, 'closed'));
        });
      })
      .catch((error) => {
        dispatch({ type: SET_CLOSE_ENGAGEMENT_FORM_ERRORS, payload: error });
        dispatch({ type: SET_CLOSE_ENGAGEMENT_SUBMITTING, payload: false });
        dispatch({ type: SET_CLOSE_ENGAGEMENT_LOADING, payload: false });
      })
      .finally(() => {
        dispatch({ type: SET_CLOSE_ENGAGEMENT_SUBMITTING, payload: false });
        dispatch({ type: SET_CLOSE_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const validateForm = (engagementId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let engagement =
        getState().closeEngagement.closeEngagementData[engagementId];
      let closeEngagementData = {
        comment: engagement.comment,
        completeTimeFrame: engagement.completeTimeFrame,
      };

      let errorStatus = false;
      let errorList = {};

      //Comment Validation
      /*let commentData = closeEngagementData.comment;
      if(commentData !== '') {
        commentData = JSON.parse(commentData);
        if(typeof commentData.blocks !== 'undefined') {
          if(commentData.blocks[0].text === '') {
            errorStatus = true;  
            errorList = {...errorList, comment:['Required']};
          }
          if(commentData.blocks[0].text.length > 500) {
            errorStatus = true;  
            errorList = {...errorList, comment:['Please enter upto 500 characters']};
          }
        } else {
          errorStatus = true;
          errorList = {...errorList, comment:['Required']};
        }
      } else {
        errorStatus = true;
        errorList = {...errorList, comment:['Required']};
      }*/

      let rules = {
        completeTimeFrame: 'required',
      };
      const { isValid, errors } = allFieldsValidation(
        closeEngagementData,
        rules,
        {
          'required.completeTimeFrame': 'Required',
        },
      );

      if (!isValid) {
        errorStatus = true;
      }

      if (typeof errors !== undefined) {
        errorList = { ...errorList, ...errors };
      }

      if (errorStatus) {
        reject(errorList);
      } else {
        resolve(true);
      }
    });
  };
};
