/**
 *
 * Question List
 *
 */

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicModal from '../../components/Common/CivicModal';
import QuestionFooter from '../../components/Manager/QuestionForm/questionFooter';
import QuestionList from '../../components/Manager/QuestionForm/questionList';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const QuestionModal = (props) => {
  const {
    engagement,
    surveyData,
    reload,
    surveyChange,
    submitSurvey,
    setReload,
    user,
    location,
    loadSurveyData,
  } = props;
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [footerBlock, setFooterBlock] = useState('');
  const uri = location.pathname.replace(/^\/|\/$/g, '');

  const renderBlock = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setFooterBlock(<Component engagement={engagement} {...props} />);
        return resolve(true);
      });
    },
    [setFooterBlock, engagement, props],
  );

  const setData = useCallback(async () => {
    if (
      uri !== 'dashboard/civicfeed' &&
      uri !== 'dashboard/searchresult' &&
      uri !== 'dashboard/user-profile' &&
      uri !== 'dashboard/civiclist' &&
      user.roleName === 'PUBLICOFFICIAL' &&
      engagement.stage !== 'unpublished'
    ) {
      renderBlock(QuestionFooter);
      setLoaded(true);
      setReload(false);
    } else {
      await loadSurveyData(engagement.id).then(() => {
        renderBlock(QuestionFooter);
        setLoaded(true);
        setReload(false);
      });
    }
  }, [
    engagement.id,
    renderBlock,
    setReload,
    loadSurveyData,
    uri,
    user.roleName,
    engagement.stage,
  ]);

  useEffect(() => {
    if (loaded === false || reload === true) {
      setData();
    }
  }, [
    loaded,
    reload,
    renderBlock,
    setLoaded,
    setReload,
    loadSurveyData,
    engagement,
    setData,
  ]);

  return (
    <>
      {loaded === true && typeof surveyData[engagement.id] !== 'undefined' ? (
        <CivicModal
          id={`survey-question-${engagement.id}`}
          modalClass={`survey-question-modal`}
          title={t(surveyData[engagement.id].title)}
          dismissal={surveyData[engagement.id].dismissal}
          footer={footerBlock}
          scrollable={true}
        >
          <QuestionList
            user={user}
            engagement={engagement}
            surveyData={surveyData[engagement.id]}
            reload={reload}
            surveyChange={surveyChange}
            submitSurvey={submitSurvey}
            location={location}
          />
        </CivicModal>
      ) : (
        <CivicModal
          id={`survey-question-${engagement.id}`}
          title={t(engagement.title)}
          dismissal={false}
          modalClass={`survey-question-modal`}
        >
          <LoadingIndicator />
        </CivicModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    surveyData: state.surveyQuestion.surveyData,
    reload: state.surveyQuestion.reload,
  };
};

export default connect(mapStateToProps, actions)(QuestionModal);
