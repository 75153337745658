import { isJson } from '../../../utils/helper';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import { useTranslation } from 'react-i18next';

const RenderProfileView = ({ 
  user, 
  userProfile, 
  officialInformations, 
  defaultText, 
  type, 
  format 
}) => {
  const { t } = useTranslation();

  if (type === 'aboutMe' && format === 'JSON') {
    return (
      isJson(userProfile.aboutMe) === true ? (
        <p
          dangerouslySetInnerHTML={convertFromJSONToHTML(userProfile.aboutMe)?.__html === '<p><br></p>'
              ? isJson(defaultText) ? convertFromJSONToHTML(defaultText) : { __html: t(defaultText) }
              : user.selected_language === userProfile.originalLanguage ?
                  convertFromJSONToHTML(userProfile.aboutMe)
                :
                  (JSON.parse(userProfile?.translation)?.[userProfile?.selected_language]?.aboutMe) ? 
                    { __html: JSON.parse(userProfile?.translation)?.[userProfile?.selected_language]?.aboutMe}
                  :
                    convertFromJSONToHTML(userProfile.aboutMe)
          }
        ></p>
      ) : (
        <p style={{ whiteSpace: 'pre-wrap' }}>
          {userProfile.aboutMe === null || userProfile.aboutMe === ''
            ? isJson(defaultText) ? '' : t(defaultText)
            : userProfile.aboutMe}
        </p>
      )
    );
  }
  else if (type === 'aboutMe' && format === 'HTML') {
    return (
      <p
        dangerouslySetInnerHTML={
          user.selected_language === userProfile.originalLanguage ?
          { __html: userProfile.aboutMe_html }
            :
              (JSON.parse(userProfile?.translation)?.[user.selected_language]?.aboutMe) ? 
                { __html: JSON.parse(userProfile?.translation)?.[user?.selected_language]?.aboutMe}
              :
                { __html: userProfile.aboutMe_html }
        }
      ></p>
    );
  }
  else if (type === 'politicalAffiliation' && format === 'JSON') {
    return (
      isJson(officialInformations.politicalAffiliation) === true ? (
        <>
          <p
            dangerouslySetInnerHTML={convertFromJSONToHTML(officialInformations.politicalAffiliation)?.__html === '<p><br></p>'
              ? isJson(defaultText) ? convertFromJSONToHTML(defaultText) : { __html: t(defaultText) }
              : user.selected_language === userProfile?.originalLanguage ?
                  convertFromJSONToHTML(officialInformations.politicalAffiliation)
                :
                  (isJson(officialInformations.translation) && JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation) ? 
                    { __html: JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation}
                  :
                    convertFromJSONToHTML(officialInformations.politicalAffiliation)
            }
          ></p>
        </>
      ) : (
        <>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {officialInformations.politicalAffiliation === null || officialInformations.politicalAffiliation === ''
                ? isJson(defaultText) ? '' : t(defaultText)
                : officialInformations.politicalAffiliation}
        </p>
        </>
      )
    );
  }
  else if (type === 'politicalAffiliation' && format === 'HTML') {
    return (
      <>
        <p
          dangerouslySetInnerHTML={
            user.selected_language === userProfile?.originalLanguage ?
            { __html: officialInformations.politicalAffiliation_html }
              :
                (isJson(officialInformations.translation) && JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation) ? 
                  { __html: JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation}
                :
                  { __html: officialInformations.politicalAffiliation_html }
          }
        ></p>
      </>
    );
  }
  else if (type === 'politicalBackground' && format === 'JSON') {
    return (
      isJson(officialInformations.politicalBackground) === true ? (
        <p
          dangerouslySetInnerHTML={convertFromJSONToHTML(officialInformations.politicalBackground)?.__html === '<p><br></p>'
          ? isJson(defaultText) ? convertFromJSONToHTML(defaultText) : { __html: t(defaultText) }
          : userProfile?.selected_language === userProfile?.originalLanguage ?
                convertFromJSONToHTML(officialInformations.politicalBackground)
              :
                (isJson(officialInformations.translation) && JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalBackground) ? 
                  { __html: JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalBackground}
                :
                  convertFromJSONToHTML(officialInformations.politicalBackground)
          }
        ></p>
      ) : (
        <p style={{ whiteSpace: 'pre-wrap' }}>
          {officialInformations.politicalBackground === null || officialInformations.politicalBackground === ''
              ? isJson(defaultText) ? '' : t(defaultText)
              : officialInformations.politicalBackground}
        </p>
      )
    );
  }
  else if (type === 'politicalBackground' && format === 'HTML') {
    return (
      <p
        dangerouslySetInnerHTML={
          user?.selected_language === userProfile?.originalLanguage ?
          { __html: officialInformations.politicalBackground_html }
            :
              (isJson(officialInformations.translation) && JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalBackground) ? 
                { __html: JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.politicalBackground}
              :
                { __html: officialInformations.politicalBackground_html }
        }
      ></p>
    );
  }
  else if (type === 'whatDoIStandFor' && format === 'JSON') {
    return (
      isJson(officialInformations.whatDoIStandFor) === true ? (
        <p
          dangerouslySetInnerHTML={convertFromJSONToHTML(officialInformations.whatDoIStandFor)?.__html === '<p><br></p>'
            ? isJson(defaultText) ? convertFromJSONToHTML(defaultText) : { __html: t(defaultText) }
            : user?.selected_language === userProfile?.originalLanguage ?
                convertFromJSONToHTML(officialInformations.whatDoIStandFor)
              :
                (isJson(officialInformations.translation) && JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor) ? 
                  { __html: JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor}
                :
                  convertFromJSONToHTML(officialInformations.whatDoIStandFor)
          }
        ></p>
      ) : (
        <p style={{ whiteSpace: 'pre-wrap' }}>
          {officialInformations.whatDoIStandFor === null || officialInformations.whatDoIStandFor === ''
            ? isJson(defaultText) ? '' : t(defaultText)
            : officialInformations.whatDoIStandFor}
        </p>
      )
    )
  }
  else if (type === 'whatDoIStandFor' && format === 'HTML') {
    return (
      <p
        dangerouslySetInnerHTML={
          user?.selected_language === userProfile?.originalLanguage ?
          { __html: officialInformations.whatDoIStandFor_html }
            :
              (isJson(officialInformations.translation) && JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor) ? 
                { __html: JSON.parse(officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor}
              :
                { __html: officialInformations.whatDoIStandFor_html }
        }
      ></p>
    );
  }
  else {
    return (
      <>
      </>
    );
  }
}

export default RenderProfileView;