/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { MapContainer, Marker, TileLayer, useMap, Polygon} from 'react-leaflet';
import { useGeomanControls } from 'react-leaflet-geoman-v2';
import axios from 'axios';
import { BASE_API_URL } from '../../../constants';
import { useTranslation } from 'react-i18next';

function DrawControls({ onNewPolygon, onClearArea }) {
  const map = useMap();
  const currentPolygon = useRef(null);

  const enableDragMode = () => {
    map.pm.enableGlobalDragMode();
    if (currentPolygon.current) {
      currentPolygon.current.pm.enableLayerDrag();
    }
  }

  const clearPolygon = () => {
    if (currentPolygon.current) {
      map.removeLayer(currentPolygon.current);
      currentPolygon.current = null;
    }
  }

  useEffect(() => {
    onClearArea(clearPolygon);
  }, [onClearArea]);

  useEffect(() => {
    if (!currentPolygon.current) {
      map.pm.enableDraw('Polygon');
    } else {
      enableDragMode();
    }
  }, [currentPolygon.current]);

  useGeomanControls({
    options: { 
      drawPolygon: false,
      drawCircle: false,
      drawPolyline: false,
      drawMarker: false,
      drawCircleMarker: false,
      drawRectangle: false,
      drawText: false,
      cutPolygon: false,
      rotateMode: false,
      editMode: true,
      dragMode: false,
    },
    onCreate: (e) => {
      currentPolygon.current = e.layer;
      onNewPolygon(e.layer.toGeoJSON());
      map.pm.enableGlobalDragMode();
      e.layer.pm.enableLayerDrag();
    },
    eventDebugFn: console.log,
    onEdit: (e) => {
      onNewPolygon(e.layer.toGeoJSON());
    },
    onGlobalEditModeToggled: (e) => {
      if (!e.enabled) {
        enableDragMode();
      }
    },
    onLayerRemove: (e) => {
      onNewPolygon(null);
      currentPolygon.current = null;
      map.pm.disableGlobalDragMode();
      map.pm.enableDraw('Polygon');
    }
  })

  return null;
}

const SizeInvalidator = ({ invalidateSize }) => {
  const map = useMap();
  useEffect(() => {
    if (invalidateSize) {
      map.invalidateSize();
    }
  }, [map, invalidateSize]);

  return null;
}

const ShowAreaMap = (props) => {
  const {
    communityIssueId,
    allCommunityIssues,
    user,
    invalidateSize,
    overrideCenter = null,
  } = props;
  const { t } = useTranslation();
  const communityIssue = allCommunityIssues[communityIssueId];
  const [population, setPopulation] = useState(0);
  const clearPolygonRef = useRef(null);

  // GeoJSON stored in (longitude, latitude)
  // Need to display in (latitude, longitude)
  const filteredCoordinates = communityIssue?.area?.[0]?.map((coordinate) => {
    return [coordinate[1], coordinate[0]]
  });

  if (!filteredCoordinates || filteredCoordinates.length === 0) {
    return null;
  }

  return (
    <>
      <MapContainer
        scrollWheelZoom={true}
        center={overrideCenter ? overrideCenter : (user?.coordinates ?? [37.386051, -122.083855])} // Mountain View
        zoom={13}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
        <Polygon pathOptions={{ color: '#FFAA32' }} positions={filteredCoordinates} />
        <SizeInvalidator invalidateSize={invalidateSize} />
        {!!user?.coordinates && <Marker position={user.coordinates} />}
      </MapContainer>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(ShowAreaMap);