/*
 *
 * Account reducer
 *
 */

import {
  FETCH_PROFILE,
  CLEAR_ACCOUNT,
  SET_PROFILE_LOADING,
  REQUEST_USER_PROFILE,
  SET_EDIT_PROFILE_DATA,
  EDIT_PROFILE_CHANGE,
  SET_EDIT_PROFILE_ERRORS,
  SET_EDIT_PROFILE_FORM_DATA,
  SET_EDIT_PROFILE_FORM_ERRORS,
  EDIT_PROFILE_FORM_CHANGE,
  SET_PROFILE_IMG_DATA,
  EDIT_PROFILE_IMG_CHANGE,
  SET_PROFILE_IMG_ERRORS,
  SET_PROFILE_BG_IMG_DATA,
  EDIT_PROFILE_BG_IMG_CHANGE,
  SET_PROFILE_BG_IMG_ERRORS,
  EDIT_SOCIAL_LINKS,
  SET_SOCIAL_LINKS_DATA,
  SET_SOCIAL_LINK_ERRORS,
  SET_EDIT_OPTIONAL_DATA,
  EDIT_OPTIONAL_CHANGE,
  SET_EDIT_OPTIONAL_ERRORS,
  SET_EDIT_PASSWORD_DATA,
  EDIT_PASSWORD_FORM_CHANGE,
  SET_PASSWORD_FORM_ERRORS,
  SET_REPORT_FORM_ERRORS,
  REPORT_FORM_CHANGE,
  SET_REPORT_FORM_RESET,
  SET_REPORT_LOADING,
  SET_REPORT_SUBMITTING,
  GET_FIREBASE_TOKEN,
  SET_NOTIFICATION_ENABLE_DATA,
  RESET_APP,
  SET_INACTIVE_MESSAGES, 
  EDIT_USER_CHANGE, 
  SET_SELECTED_LANGUAGE,
  SET_FORM_TYPE,
  GET_MY_DISTRICTS,
} from './constants';

import blocks from './blocks';
import pages, { landingPages } from './pages';

const initialState = {
  inactiveMessages: [],
  user: {
    firstName: '',
    lastName: '',
    profileCompletion: '10%',
    selected_language: localStorage.getItem('lng') !== null ? localStorage.getItem('lng') : 'EN',
  },
  blocks: blocks,
  pages: pages,
  landingPages: landingPages,
  isLoading: false,
  fetchUserProfile: false,
  isProfileLoading: false,
  editProfileData: {
    title: 'Edit My Info',
    first_name: '',
    last_name: '',
    showMyNameAs: '',
    address: '',
    city: '',
    zip_code: '',
    state: '',
    phone_number: '',
    email: '',
    organization_name: '',
    position: '',
    dismissal: false,
  },
  editProfileErrors: [],
  editProfileFormData: {
    title: '',
    aboutMe: '',
    politicalAffiliation: '',
    politicalBackground: '',
    whatDoIStandFor: '',
  },
  editProfileFormError: {},
  profileImgData: {
    title: 'Upload your profile image',
    profile_picture: '',
    dismissal: false,
    link: null,
    file: [],
    linkToBeRemoved: [],
  },
  profileImgError: {},
  profileBgImgData: {
    title: 'Upload your profile background image',
    profile_bg_picture: '',
    dismissal: false,
    link: null,
    file: [],
    linkToBeRemoved: [],
  },
  profileBgImgError: {},
  socialLinkData: {
    title: 'Edit Your Social Links',
    dismissal: false,
    socialPlatform: {},
    initialValue: {
      facebook: 'https://www.facebook.com/',
      twitter: 'https://twitter.com/',
      linkedIn: 'https://www.linkedin.com/',
      nextDoor: 'https://nextdoor.com/',
      email: '',
    },
  },
  socialLinkError: {},
  optionalInfoData: {},
  optionalInfoErrors: {},
  passwordData: {},
  passwordFormError: {},
  reportFormErrors: [],
  reportFormData: {
    reportLink: '',
    description: '',
    dismissal: false,
  },
  isReportLoading: false,
  isReportSubmitting: false,
  firebaseToken: '',
  notificationData: {},
  formType: 'aboutMe',
  myDistricts: {},
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return initialState;
    case FETCH_PROFILE:
      if (action.payload.officialInformations) {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
            officialInformations: {
              ...state.user.officialInformations,
              ...action.payload.officialInformations
            }
          }
        }
      }

      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case SET_INACTIVE_MESSAGES:
      return {
        ...state,
        inactiveMessages: action.payload,
      };
    case CLEAR_ACCOUNT:
      return {
        ...state,
        user: {
          firstName: '',
          lastName: '',
        },
      };
    case SET_PROFILE_LOADING:
      return {
        ...state,
        isProfileLoading: action.payload,
      };
    case REQUEST_USER_PROFILE:
      return {
        ...state,
        fetchUserProfile: action.payload,
      };
    case SET_EDIT_PROFILE_DATA:
      return {
        ...state,
        editProfileData: action.payload,
      };
    case EDIT_PROFILE_CHANGE:
      return {
        ...state,
        editProfileData: { ...state.editProfileData, ...action.payload },
      };
    case EDIT_USER_CHANGE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case SET_EDIT_PROFILE_ERRORS:
      return {
        ...state,
        editProfileErrors: action.payload,
      };
    case SET_EDIT_PROFILE_FORM_DATA:
      return {
        ...state,
        editProfileFormData: action.payload,
      };
    case SET_EDIT_PROFILE_FORM_ERRORS:
      return {
        ...state,
        editProfileFormError: action.payload,
      };
    case EDIT_PROFILE_FORM_CHANGE:
      return {
        ...state,
        editProfileFormData: {
          ...state.editProfileFormData,
          ...action.payload,
        },
      };
    case SET_PROFILE_IMG_DATA:
      return {
        ...state,
        profileImgData: action.payload,
      };
    case EDIT_PROFILE_IMG_CHANGE:
      return {
        ...state,
        profileImgData: { ...state.profileImgData, ...action.payload },
      };
    case SET_PROFILE_IMG_ERRORS:
      return {
        ...state,
        profileImgError: action.payload,
      };
    case SET_PROFILE_BG_IMG_DATA:
      return {
        ...state,
        profileBgImgData: action.payload,
      };
    case EDIT_PROFILE_BG_IMG_CHANGE:
      return {
        ...state,
        profileBgImgData: { ...state.profileBgImgData, ...action.payload },
      };
    case SET_PROFILE_BG_IMG_ERRORS:
      return {
        ...state,
        profileBgImgError: action.payload,
      };
    case SET_SOCIAL_LINKS_DATA:
      return {
        ...state,
        socialLinkData: action.payload,
      };
    case EDIT_SOCIAL_LINKS:
      return {
        ...state,
        socialLinkData: { ...state.socialLinkData, ...action.payload },
      };
    case SET_SOCIAL_LINK_ERRORS:
      return {
        ...state,
        socialLinkError: action.payload,
      };
    case SET_EDIT_OPTIONAL_DATA:
      return {
        ...state,
        optionalInfoData: action.payload,
      };
    case EDIT_OPTIONAL_CHANGE:
      return {
        ...state,
        optionalInfoData: { ...state.optionalInfoData, ...action.payload },
      };
    case SET_EDIT_OPTIONAL_ERRORS:
      return {
        ...state,
        optionalInfoErrors: action.payload,
      };
    case SET_EDIT_PASSWORD_DATA:
      return {
        ...state,
        passwordData: action.payload,
      };
    case EDIT_PASSWORD_FORM_CHANGE:
      return {
        ...state,
        passwordData: { ...state.passwordData, ...action.payload },
      };
    case SET_PASSWORD_FORM_ERRORS:
      return {
        ...state,
        passwordFormError: action.payload,
      };
    case SET_REPORT_FORM_ERRORS:
      return {
        ...state,
        reportFormErrors: action.payload,
      };
    case REPORT_FORM_CHANGE:
      return {
        ...state,
        reportFormData: { ...state.reportFormData, ...action.payload },
      };
    case SET_REPORT_FORM_RESET:
      return {
        ...state,
        reportFormData: {
          reportLink: '',
          description: '',
          dismissal: false,
        },
      };
    case SET_REPORT_LOADING:
      return {
        ...state,
        isReportLoading: action.payload,
      };
    case SET_REPORT_SUBMITTING:
      return {
        ...state,
        isReportSubmitting: action.payload,
      };
    case GET_FIREBASE_TOKEN:
      return {
        ...state,
        firebaseToken: action.payload,
      };
    case SET_NOTIFICATION_ENABLE_DATA:
      return {
        ...state,
        notificationData: { ...state.notificationData, ...action.payload },
      };
    case SET_SELECTED_LANGUAGE:
      return {
        ...state,
        user: {
          ...state.user,
          selected_language: action.payload
        }
      }
    case SET_FORM_TYPE:
      return {
        ...state,
        formType: action.payload,
      }
    case GET_MY_DISTRICTS:
      const myDistrictsAll = {};
      action.payload.forEach((district) => {
        myDistrictsAll[district.ocdId] = district;
      });

      return {
        ...state,
        myDistricts: myDistrictsAll,
      }
    default:
      return state;
  }
};

export default accountReducer;
