/**
 *
 * File Uploader
 *
 */

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  isImage,
  getFileExtension,
  formatFileSize,
} from '../../../utils/helper';
import { useTranslation } from 'react-i18next';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 0,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const FileUploader = (props) => {
  const {
    fileChange,
    fileList,
    linkList,
    removeLink,
    isVideoAllowed,
    acceptFormat,
    videoAcceptFormat,
    maximumSize,
    videoMaximumSize,
    preview,
    maxFiles,
    multiple,
    label,
    isDimensionCheck,
    allowedImgWidth,
    type,
    clearFiles,
    isEventSubmitting,
  } = props;

  const { t } = useTranslation();
  const hashtag = '#';

  const [files, setFiles] = useState(fileList);
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    if (isDimensionCheck === true) {
      let loopFiles = files;
      let newFiles = files;
      loopFiles.forEach((file, index) => {
        let fileType = file.type?.split('/', 1)[0];

        if (fileType === 'image') {
          var _URL = window.URL || window.webkitURL;
          var img = new Image();
          var objectUrl = _URL.createObjectURL(file);
          img.onload = function () {
            if (
              this.width > allowedImgWidth + 30 ||
              this.width < allowedImgWidth - 30
            ) {
              let rejectFile = {
                errors: [
                  {
                    code: 'Image size not fit',
                    message: `For better resolution, please upload the image with width ${allowedImgWidth}px`,
                  },
                ],
                file: file,
                fileName: file.name,
              };

              let matchFound = false;

              errorList.forEach((item) => {
                if (item.fileName === file.name) {
                  matchFound = true;
                }
              });
              if (matchFound === false) {
                errorList.push(rejectFile);
              }

              delete newFiles[index];
              newFiles = newFiles.filter((item, index) => {
                return item !== null;
              });
              setFiles(newFiles);
            }

            _URL.revokeObjectURL(objectUrl);
          };
          img.src = objectUrl;
        }
      });
    }
  }, [isDimensionCheck, files, allowedImgWidth, errorList, setErrorList]);

  useEffect(() => {
    if (type === 'surveyEngagement') {
      let allowedFormat = [
        'jpeg',
        'png',
        'jpg',
        'tiff',
        'svg',
        'gif',
        'mp4',
        'mov',
        'flv',
        'avi',
        'webm',
      ];
      let link_documents = [];
      if (linkList !== null) {
      link_documents = linkList
        .map((fileData, index) => {
          let fileExt = getFileExtension(fileData.fileLocation);
          if (allowedFormat.includes(fileExt) === true) {
            return true;
          }
          return undefined;
        })
        .filter(function (item) {
          return item !== undefined;
        });
      }

      let loopFiles = files;
      let newFiles = files;
      let fileCount = link_documents.length;
      loopFiles.forEach((file, index) => {
        let fileExt = getFileExtension(file.name);
        if (allowedFormat.includes(fileExt) === true) {
          fileCount++;
        }
        if (fileCount > 5) {
          let rejectFile = {
            errors: [
              {
                code: `Maximum file count reached.`,
                message: `Uploading more than five image/video type files is restricted for survey type engagements.`,
              },
            ],
            file: file,
            fileName: file.name,
          };
          errorList.push(rejectFile);
          delete newFiles[index];
        }
      });

      newFiles = newFiles.filter((item, index) => {
        return item !== null;
      });
      //setFiles(newFiles);
    }
  }, [files, linkList, type, errorList, maxFiles]);

  useEffect(() => {
    if (clearFiles === true) {
      setFiles([]);
    }
  }, [clearFiles]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setErrorList([]);
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      if (multiple) setFiles(files.concat(...acceptedFiles));
      else setFiles(acceptedFiles);
    },
    [files, multiple],
  );

  function fileValidation(file) {
    let format = '.' + getFileExtension(file.name);
    let fileType = file.type?.split('/', 1)[0];
    let linkLength = 0;

    if (linkList !== null && typeof linkList === 'object') {
      linkLength = linkList.length;
    }

    if (maxFiles !== 0 && files.length + linkLength >= maxFiles) {
      return {
        code: `Maximum file count reached.`,
        message: `You can upload ${maxFiles} files.`,
      };
    }

    if (fileType === 'video' && isVideoAllowed === true) {
      let videoAcceptFormatArray = videoAcceptFormat.split(',').map((item) => {
        return item.trim();
      });
      var videoResult = videoAcceptFormatArray.includes(format);
      if (videoResult === false) {
        return {
          code: 'Invalid File Type',
          message: `File type must be ${acceptFormat}`,
        };
      }
      if (file.size > videoMaximumSize) {
        return {
          code: 'File size exceeded',
          message: `File is larger than ${formatFileSize(videoMaximumSize)}`,
        };
      }
    } else {
      let acceptFormatArray = acceptFormat.split(',').map((item) => {
        return item.trim();
      });
      var result = acceptFormatArray.includes(format);
      if (result === false) {
        return {
          code: 'Invalid File Type',
          message: `File type must be ${acceptFormat}`,
        };
      }
      if (file.size > maximumSize) {
        return {
          code: 'File size exceeded',
          message: `File is larger than ${formatFileSize(maximumSize)}`,
        };
      }
    }
    return null;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    multiple: multiple,
    validator: fileValidation,
  });

  const fileRejectionItems = fileRejections.map(
    ({ file, errors }, rejIndex) => (
      <li key={`rejection-${rejIndex}`} className={`err-txt`}>
        {file.path} - {formatFileSize(file.size)}
        <ul>
          {errors.map((e, rejId) => (
            <li key={`rej-msg-id-${rejId}`}>{e.message}</li>
          ))}
        </ul>
      </li>
    ),
  );

  const errorFileItems = errorList.map(({ file, errors }, errIndex) => (
    <ul>
      <li key={`error-${errIndex}`} className={`err-txt`}>
        {file.path} - {formatFileSize(file.size)}
        <ul>
          {errors.map((e, idErr) => (
            <li key={`err-msg-${idErr}`}>{e.message}</li>
          ))}
        </ul>
      </li>
    </ul>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const remove = (index) => {
    const newFiles = [...files];
    let fileList = [];
    newFiles.splice(index, 1);
    fileList = newFiles.filter((file) => file !== null);
    setFiles(fileList);
  };

  useEffect(() => {
    fileChange('files', files);
    //files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files, fileChange]);

  const thumbs = files.map((file, index) => (
    <div key={`img-thumb-${index}`}>
      {file.type.slice(0, 5) === 'image' ? (
        <div className="image-preview mt-2">
          <img src={file.preview} alt={file.name} />
          <div
            className="btn image-preview-close"
            onClick={() => {
              remove(index);
            }}
          >
            <img src="/static/media/delete.02cc25b1.svg" alt="Remove File" />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  ));

  const linkThumbs =
    linkList !== null && typeof linkList !== 'undefined'
      ? linkList.map((link, index) => (
          <div key={`link-id-${index}`}>
            {isImage(link) === true ? (
              <div style={thumb} key={`link-${index}`}>
                <div style={thumbInner}>
                  <img src={link} style={img} alt={`link-${index}`} />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        ))
      : '';

  return (
    <div className="form-group" style={{ overflow: 'hidden' }}>
      <label>{label}</label>
      <section>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div>
            <span className={'file-drag-drop-txt'}>
              {t(`Drag & Drop or `)}{' '}
            </span>
            <span className={'file-browse-txt'}>{t(`Browse`)}</span>
          </div>
        </div>
      </section>
      {preview === true ? (
        <aside style={thumbsContainer}>
          {thumbs}
          {linkThumbs}
        </aside>
      ) : (
        ''
      )}
      <aside>
        {linkList !== null && typeof linkList !== 'undefined' ? (
          <>
            {linkList.map((link, index) => (
              <div className="file-uploader" key={`Link-${index}`}>
                <div className="link-list">
                  <div className="uploader-filename">{link.fileLocation}</div>
                  <div className="btn clsinfo uploader-close">
                    <a
                      href={hashtag}
                      onClick={() => {
                        removeLink(link, index);
                      }}
                    >
                      <img
                        src={require('../../../assets/img/delete.svg').default}
                        alt="Remove File"
                      />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          ''
        )}

        {fileRejectionItems}
        {errorFileItems}
        {files.map((file, index) => (
          <div key={`File-${index}`} className="file-uploader">
            <div className="uploader-filename">{file.name}</div>
            <div className="btn clsinfo uploader-close">
              <a
                href={hashtag}
                onClick={() => {
                  remove(index);
                }}
              >
                <img
                  src={require('../../../assets/img/delete.svg').default}
                  alt="Remove File"
                />
              </a>
            </div>
          </div>
        ))}
      </aside>
    </div>
  );
};

FileUploader.defaultProps = {
  fileList: [],
  linkList: [],
  acceptFormat:
    '.jpeg, .png, .jpg, .tiff, .svg, .gif, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .rtf, .odt, .txt, .zip',
  videoAcceptFormat: '.webm, .mp4, .mov, .flv, .avi',
  maximumSize: 5000000,
  videoMaximumSize: 5000000,
  isVideoAllowed: false,
  isDimensionCheck: false,
  allowedImgWidth: 240,
  preview: true,
  multiple: true,
  maxFiles: 0,
  label: 'Upload Documents (optional)',
  type: 'default',
};

export default FileUploader;
