/*
 *
 * LocalNews actions
 *
 */

import {
  SET_LOCALNEWS_TAB,
  FETCH_LOCALNEWS_DATA,
  LOCALNEWS_RELOAD_LIST,
  LOCALNEWS_CHANGE,
  SET_FILTERNEWS_TAB,
  RESET_APP,
} from './constants';
import { addNotification } from '../Notification/actions';
import axios from 'axios';
import { BASE_API_URL } from '../../constants';

export const resetLocalNewsState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const localNewsChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: LOCALNEWS_CHANGE, payload: formData });
  };
};

export const localNewsChangeByOption = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData[name] = value;
    if (name === 'sort') {
      dispatch({ type: LOCALNEWS_CHANGE, payload: formData });
      return dispatch(reloadLocalNews());
    } else {
      return dispatch({ type: LOCALNEWS_CHANGE, payload: formData });
    }
  };
};

export const reloadLocalNews = () => {
  return async (dispatch, getState) => {
    dispatch({ type: LOCALNEWS_RELOAD_LIST });
  };
};

export const setLocalNewsTab = (status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOCALNEWS_TAB, payload: status });
  };
};

export const setFilterNewsTab = (status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_FILTERNEWS_TAB, payload: status });
  };
};

export const getFilterNewsStates = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(BASE_API_URL + '/users/getStates')
        .then((response) => {
          if (response.data.success === 'true') {
            let filter = getState().localNews.filter;
            if (response.data.data.length > 0)
              filter['states'] = response.data.data.map((state, index) => ({
                value: state.stateId,
                label: state.name,
              }));
            let formData = {};
            formData['filter'] = filter;
            dispatch(localNewsChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const getFilterNewsCounties = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let filteredState = getState().localNews.filterSelected.state;
      await axios
        .get(BASE_API_URL + '/users/getCounties?stateId=' + filteredState)
        .then((response) => {
          if (response.data.success === 'true') {
            let filter = getState().localNews.filter;
            if (response.data.data.length > 0)
              filter['counties'] = response.data.data.map((county, index) => ({
                value: county.countyId,
                label: county.name,
              }));
            let formData = {};
            formData['filter'] = filter;
            dispatch(localNewsChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const getFilterNewsFeeds = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let filteredState = getState().localNews.filterSelected.state;
      let filteredCounty = getState().localNews.filterSelected.county;
      await axios
        .get(
          BASE_API_URL +
            '/users/rssFeedFilter?stateCode=' +
            filteredState +
            '&countyCode=' +
            filteredCounty,
        )
        .then((response) => {
          if (response.data.success === 'true') {
            let filter = getState().localNews.filter;
            filter['feedSources'] = response.data.data;
            let formData = {};
            formData['filter'] = filter;
            dispatch(localNewsChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const localNewsFilterChange = (type, value) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().localNews.filterSelected;
    if (type === 'feedSources') {
      if (filterSelected[type].indexOf(value) !== -1) {
        const index = filterSelected[type].indexOf(value);
        if (index > -1) {
          filterSelected[type].splice(index, 1);
        }
      } else {
        filterSelected[type].push(value);
      }
    } else {
      filterSelected[type] = value;
    }
    let formData = {};
    formData['filterSelected'] = { ...filterSelected };
    return dispatch(localNewsChange(formData));
  };
};

export const resetLocalNewsFilter = () => {
  return async (dispatch, getState) => {
    let filter = getState().localNews.filter;
    filter['counties'] = [];
    filter['feedSources'] = {
      city_sources: [],
      county_sources: [],
      state_sources: [],
    };
    let filterSelected = getState().localNews.filterSelected;
    filterSelected['state'] = '';
    filterSelected['county'] = '';
    filterSelected['feedSources'] = [];
    let formData = {};
    formData['filter'] = filter;
    formData['filterSelected'] = filterSelected;
    dispatch(localNewsChange(formData));
  };
};

export const submitLocalNewsFilter = () => {
  return async (dispatch, getState) => {
    let formData = {};
    formData['filterDismissal'] = true;
    dispatch(localNewsChange(formData));
    dispatch(reloadLocalNews());
  };
};

export const getLocalNewsLists = (page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let activeLocalNewsTab = getState().localNews.activeLocalNewsTab;
      let sort = getState().localNews.sort;
      let filterSelected = getState().localNews.filterSelected;

      let apiUri = '/users/rssFeedoutlet?page=' + page;
      if (
        activeLocalNewsTab === 'city' &&
        filterSelected['feedSources'].length === 0
      ) {
        apiUri += '&city=' + true;
      }
      if (
        activeLocalNewsTab === 'county' &&
        filterSelected['feedSources'].length === 0
      ) {
        apiUri += '&county=' + true;
      }
      if (
        activeLocalNewsTab === 'stateFeed' &&
        filterSelected['feedSources'].length === 0
      ) {
        apiUri += '&stateFeed=' + true;
      }

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }

      if (sort !== '') {
        apiUri += '&sort=' + sort;
      }

      if (filterSelected['feedSources'].length > 0) {
        apiUri +=
          '&stateCode=' +
          filterSelected['state'] +
          '&countyCode=' +
          filterSelected['county'] +
          '&newsSources=' +
          JSON.stringify(filterSelected['feedSources']);
      }

      if (page === 1) {
        formData['localNewsLists'] = [];
        dispatch(localNewsChange(formData));
      }

      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().localNews.localNewsLists.length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_LOCALNEWS_DATA,
                  payload: response.data.data,
                });
              }
            } else if (
              page === 1 &&
              getState().localNews.localNewsLists.length === 0
            ) {
              dispatch({
                type: FETCH_LOCALNEWS_DATA,
                payload: response.data.data,
              });
            }

            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            if (filterSelected['feedSources'].length > 0) {
              formData['submitDismissal'] = true;
            }
            if (filterSelected['feedSources'].length === 0) {
              formData['submitDismissal'] = false;
            }

            formData['reloadList'] = false;
            dispatch(localNewsChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'localNews',
            }),
          );
        })
        .finally(() => {
          // dispatch({ type: SET_PETITION_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const getLocalNewsListsForGuest = () => {

  const page = 1;
  const limit = 7;

  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let activeLocalNewsTab = getState().localNews.activeLocalNewsTab;
      let sort = getState().localNews.sort;
      let filterSelected = getState().localNews.filterSelected;

      let apiUri = '/users/rssFeedoutlet?page=' + page + '&limit=' + limit;

      if (activeLocalNewsTab === 'city' && filterSelected['feedSources'].length === 0) {
        apiUri += '&city=' + true;
      }

      if (activeLocalNewsTab === 'county' && filterSelected['feedSources'].length === 0) {
        apiUri += '&county=' + true;
      }

      if (activeLocalNewsTab === 'stateFeed' && filterSelected['feedSources'].length === 0) {
        apiUri += '&stateFeed=' + true;
      }

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }

      if (sort !== '') {
        apiUri += '&sort=' + sort;
      }

      if (filterSelected['feedSources'].length > 0) {
        apiUri +=
            '&stateCode=' +
            filterSelected['state'] +
            '&countyCode=' +
            filterSelected['county'] +
            '&newsSources=' +
            JSON.stringify(filterSelected['feedSources']);
      }

      if (page === 1) {
        formData['localNewsLists'] = [];
        dispatch(localNewsChange(formData));
      }

      await axios
          .get(BASE_API_URL + apiUri)
          .then((response) => {
            if (response.data.success === 'true') {
              if (getState().localNews.localNewsLists.length > 0) {
                if (page !== 1) {
                  dispatch({
                    type: FETCH_LOCALNEWS_DATA,
                    payload: response.data.data,
                  });
                }
              } else if (
                  page === 1 &&
                  getState().localNews.localNewsLists.length === 0
              ) {
                dispatch({
                  type: FETCH_LOCALNEWS_DATA,
                  payload: response.data.data,
                });
              }

              formData = {};
              if (response.data.data.length === 0) {
                formData['noRecordStatus'] = true;
              }
              if (filterSelected['feedSources'].length > 0) {
                formData['submitDismissal'] = true;
              }
              if (filterSelected['feedSources'].length === 0) {
                formData['submitDismissal'] = false;
              }

              formData['reloadList'] = false;
              dispatch(localNewsChange(formData));
            }
          })
          .catch((error) => {
            dispatch(
                addNotification({
                  type: 'error',
                  message: error?.response?.data?.error,
                  module: 'localNews',
                }),
            );
          });
      return resolve(true);
    });
  };
};