/**
 *
 * Civic Filter
 *
 */

import React, { useState } from 'react';
import SearchBox from './searchBox';
import { useTranslation } from 'react-i18next';

const PetitionFilter = (props) => {
  const hashUrl = '#';
  const {
    filter,
    filterSelected,
    searchBoxPetitionChange,
    filterPetitionChange,
  } = props;
  const types = typeof filter === 'object' ? Object.keys(filter) : [];
  const [viewAllStatus, setViewAllStatus] = useState(false);
  const { t } = useTranslation();

  const viewAllRecords = () => {
    setViewAllStatus(true);
  };

  return (
    <div className="clsfilter-modal-content">
      {types.map((type, index) => (
        <React.Fragment key={`${type}-${index}`}>
          <h5 className={`mt-4`}>
            {t(filter[type].title)}
            {type !== 'status' ? (
              <span>
                <a
                  href={hashUrl}
                  onClick={(e) => {
                    searchBoxPetitionChange(type, 'display', true);
                    e.preventDefault();
                  }}
                >
                  <img
                    src={require('../../../assets/img/add-plus.svg').default}
                    alt="Add"
                  />
                  {t(`Add`)}
                </a>
              </span>
            ) : (
              ''
            )}
          </h5>
          {type !== 'status' ? <SearchBox {...props} type={type} /> : ''}

          <ul className="mt-3 pt-1 mb-2" id={`type-${type}`}>
            {filter[type].items.map((item, index) => (
              <li
                key={`${type}-${item.id}`}
                style={{
                  display:
                    viewAllStatus === false && index > 8 ? 'none' : 'block',
                }}
              >
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`${type}-${item.id}`}
                    name="checkbox"
                    checked={
                      filterSelected[type].indexOf(item.id) !== -1
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      e.target.value =
                        filterSelected[type].indexOf(item.id) !== -1
                          ? true
                          : false;
                    }}
                    value={
                      filterSelected[type].indexOf(item.id) !== -1
                        ? true
                        : false
                    }
                  />
                  <label
                    htmlFor={`${type}-${item.id}`}
                    onClick={() => {
                      filterPetitionChange(type, item.id);
                    }}
                  >
                    <span>{item.name}</span>
                  </label>
                </div>
              </li>
            ))}
          </ul>

          {filter[type].items.length > 9 && viewAllStatus === false ? (
            <span
              className={`filter-view-all`}
              data-toggle="collapse"
              data-target={`#type-${type}`}
              onClick={() => {
                viewAllRecords();
              }}
            >
              {t(`View All`)}
            </span>
          ) : (
            ''
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
export default PetitionFilter;
