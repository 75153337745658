/*
 *
 * Authentication constants
 *
 */

export const SET_AUTH = 'src/Authentication/SET_AUTH';
export const CLEAR_AUTH = 'src/Authentication/CLEAR_AUTH';
export const RESET_APP = 'src/Authentication/RESET_APP';
export const INITIATIVE_URL = 'src/Authentication/INITIATIVE_URL';
export const SET_LOAD_MESSAGE_STATUS =
  'src/Authentication/SET_LOAD_MESSAGE_STATUS';
export const SET_SILENT_USER = 'src/Authentication/SET_SILENT_USER';