/*
 *
 * PushNotification constants
 *
 */
export const FETCH_UNREAD_NOTIFICATION =
  'src/PushNotification/FETCH_UNREAD_NOTIFICATION';
export const FETCH_READ_NOTIFICATION =
  'src/PushNotification/FETCH_READ_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'src/PushNotification/CLEAR_NOTIFICATION';
export const REQUEST_NOTIFICATION = 'src/PushNotification/REQUEST_NOTIFICATION';
export const NOTIFICATION_CHANGE = 'src/PushNotification/NOTIFICATION_CHANGE';
export const NOTIFICATION_LOADING = 'src/PushNotification/NOTIFICATION_LOADING';
export const RESET_APP = 'src/PushNotification/RESET_APP';
