/**
 *
 * Analytics View
 *
 */

import React, { useEffect, useState, useCallback, createContext } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import SurveyAnalytics from '../../components/Manager/Analaytics/surveyAnalytics';
import { Chart } from 'react-google-charts';
import SelectOption from '../../components/Common/SelectOption';
import { handleOptionValue, capitalizeFirstLetter } from '../../utils/helper';
import { weightedOptions, viewByOptionList } from './constants';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import store from '../../store';
import NoData from '../../components/Manager/Analaytics/noData';
import { useTranslation } from 'react-i18next';

const AnalyticsView = (props) => {
  const {
    discussionData,
    surveyData,
    postviewData,
    analyticsData,
    activeTab,
    changeDiscussionTabData,
    changeSurveyTabData,
    changePostviewTabData,
    discussionTabData,
    surveyTabData,
    postviewTabData,
    setDiscussionData,
    setSurveyData,
    setPostviewData,
    setActiveId,
    user,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [viewByOptions, setViewByOptions] = useState(viewByOptionList);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);
  const { getState } = store;
  const { t } = useTranslation();

  const loadChartData = useCallback(
    (result) => {
      let enableBlue = false;
      let dataFormat = [];
      let resultsOnlyFlag =
        (getState().analytics.activeTab === 'survey' &&
          getState().analytics.surveyTabData.weighted === 'resultOnly') ||
        (getState().analytics.activeTab === 'discussion' &&
          getState().analytics.discussionTabData.weighted === 'resultOnly') ||
        (getState().analytics.activeTab === 'postview' &&
          getState().analytics.postviewTabData.weighted === 'resultOnly') ||
        result['resultOnly'] === true
          ? true
          : false;

      for (let i = 0; i < result['result'][0].length; i++) {
        result['result'][0][i] = t(result['result'][0][i]);
      }

      result['result'].forEach((item, index) => {
        let tooltipTxt1 =
          `<div style="padding:10px"><p style="text-align: left"><b>${t('Entire District')}: </b><br/>` +
          item[1] +
          '% (' +
          item[5] +
          ` ${t('residents')})</p></div>`;

        let tooltipTxt2 =
          `<div style="padding:10px"><p font-weight: bold; font-size: 13px;"><b>${t('Total')}: </b>` +
          item[2] +
          '% (' +
          item[6] +
          ` ${t('residents')})</p>`;
        if (result['confirmed'] === true) {
          tooltipTxt2 +=
            `<p><b>${t('Self-reported')}: </b>` +
            item[3] +
            '% (' +
            item[7] +
            ` ${t('residents')})</p>`;
        } else {
          tooltipTxt2 += `<p><b>${t('Self-reported')}: </b> n/a</p>`;
        }

        if (result['estimated'] === true) {
          tooltipTxt2 +=
            `<p><b>${t('Estimated')}: </b>` +
            item[4] +
            '% (' +
            item[8] +
            ` ${t('residents')})</p>`;
        } else {
          tooltipTxt2 += `<p><b>${t('Estimated')}: </b> n/a</p>`;
        }

        tooltipTxt2 += '</div>';

        if (getState().analytics.activeTab === 'discussion') {
          if (
            getState().analytics.discussionTabData.weighted === 'resultOnly' ||
            result['resultOnly'] === true
          ) {
            enableBlue = true;
            if (index === 0) {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[2],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[2],
                tooltipTxt2,
              ];
            }
          } else {
            if (index === 0) {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                t(item[1]),
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
                t(item[2]),
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[1],
                t(tooltipTxt1),
                item[2],
                t(tooltipTxt2),
              ];
            }
          }
        } else if (getState().analytics.activeTab === 'survey') {
          if (
            getState().analytics.surveyTabData.weighted === 'resultOnly' ||
            result['resultOnly'] === true
          ) {
            enableBlue = true;
            if (index === 0) {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[2],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[2],
                t(tooltipTxt2),
              ];
            }
          } else {
            if (index === 0) {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[1],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
                item[2],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[1],
                tooltipTxt1,
                item[2],
                tooltipTxt2,
              ];
            }
          }
        } else if (getState().analytics.activeTab === 'postview') {
          if (
            getState().analytics.surveyTabData.weighted === 'resultOnly' ||
            result['resultOnly'] === true
          ) {
            enableBlue = true;
            if (index === 0) {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[2],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[2],
                tooltipTxt2,
              ];
            }
          } else {
            if (index === 0) {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[1],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
                item[2],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                t(capitalizeFirstLetter(item[0])),
                item[1],
                tooltipTxt1,
                item[2],
                tooltipTxt2,
              ];
            }
          }
        }
      });

      setChartData(dataFormat);

      let options = {
        title: '',
        chartArea: { width: '70%' },
        bar: { groupWidth: resultsOnlyFlag === true ? '25%' : '50%' },
        series: {
          1: {
            targetAxisIndex: 1,
          },
        },
        vAxis: {
          title: '',
          titleTextStyle: {
            color: '#5C5C5C',
            fontSize: 13,
            fontFamily: 'latoregular',
            format: 'percent',
          },
        },
        hAxes:
          resultsOnlyFlag === true
            ? { 0: { minValue: 0, maxValue: 100, format: "#'%'" } }
            : {
                0: { minValue: 0, maxValue: 100, format: "#'%'" },
                1: { minValue: 0, maxValue: 100, format: "#'%'" },
              },

        legend: {
          alignment: 'right',
          position: 'bottom',
          textStyle: {
            color: '#5C5C5C',
            fontSize: 13,
            fontFamily: 'latoregular',
          },
        },
        colors:
          enableBlue === true
            ? ['#089EEA', '#FFAA33', '#87D9FF', '#FFD121']
            : ['#FFAA33', '#089EEA', '#87D9FF', '#FFD121'],
        backgroundColor: {
          stroke: '#f5f4f9',
          strokeWidth: 5,
          rx: 15,
          ry: 15,
          fill: '#FFFFFF',
        },
        lineWidth: 3,
        tooltip: { isHtml: true },
      };

      setChartOptions(options);
    },
    [setChartData, setChartOptions, getState],
  );

  useEffect(() => {
    if (activeTab === 'discussion') {
      if (Object.keys(discussionData).length > 0) {
        loadChartData(discussionData);
      }
    } else if (activeTab === 'survey') {
      if (Object.keys(surveyData).length > 0) {
        loadChartData(surveyData);
      }
    } else if (activeTab === 'postview') {
      if (Object.keys(postviewData).length > 0) {
        loadChartData(postviewData);
      }
    }
  }, [activeTab, discussionData, surveyData, postviewData, loadChartData, user]);

  const loadData = useCallback(
    async (id) => {
      if (activeTab === 'discussion') {
        await setDiscussionData(id)
          .then(() => {
            setLoaded(true);
          })
          .catch((error) => {
            //
          });
      } else if (activeTab === 'survey') {
        await setSurveyData(id)
          .then(() => {
            setLoaded(true);
          })
          .catch((error) => {
            //
          });
      } else if (activeTab === 'postview') {
        await setPostviewData(id)
          .then(() => {
            setLoaded(true);
          })
          .catch((error) => {
            //
          });
      }
    },
    [setDiscussionData, setSurveyData, setPostviewData, activeTab, setLoaded],
  );

  useEffect(() => {
    setLoaded(false);
  }, [activeTab]);

  useEffect(() => {
    if (loaded === false && typeof activeTab !== 'undefined') {
      loadData(analyticsData.id);
    }
  }, [loaded, setLoaded, activeTab, analyticsData, loadData]);

  const onChangeHandler = async () => {
    setLoader(true);
    if (activeTab === 'discussion') {
      await setDiscussionData(analyticsData.id)
        .then(() => {
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        });
    } else if (activeTab === 'survey') {
      await setSurveyData(analyticsData.id)
        .then((res) => {
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        });
    } else if (activeTab === 'postview') {
      await setPostviewData(analyticsData.id)
        .then((res) => {
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  const checkDisableStatus = useCallback(
    (options, weighted) => {
      let disableOptions = [];
      if (weighted === 'resultOnly') {
        disableOptions = options.map((item, index) => {
          if (item.value === 'party_orientation') {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
      } else if (weighted === 'censusComparision') {
        disableOptions = options.map((item, index) => {
          if (item.value === 'education') {
            item.disabled = true;
          } else if (item.value === 'employment_status') {
            item.disabled = true;
          } else if (item.value === 'marital_status') {
            item.disabled = true;
          } else if (item.value === 'political_affiliation') {
            item.disabled = true;
          } else if (item.value === 'party_orientation') {
            item.disabled = true;
          } else if (item.value === 'religion') {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
      } else if (weighted === 'voterRegistrationComparision') {
        disableOptions = options.map((item, index) => {
          if (item.value === 'ethnicity') {
            item.disabled = true;
          } else if (item.value === 'education') {
            item.disabled = true;
          } else if (item.value === 'employment_status') {
            item.disabled = true;
          } else if (item.value === 'marital_status') {
            item.disabled = true;
          } else if (item.value === 'political_affiliation') {
            item.disabled = true;
          } else if (item.value === 'religion') {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
      }
      setViewByOptions(disableOptions);
      setOptionsLoaded(true);
    },
    [setViewByOptions],
  );

  useEffect(() => {
    if (activeTab === 'discussion') {
      checkDisableStatus(viewByOptionList, discussionTabData.weighted);
    } else if (activeTab === 'survey') {
      checkDisableStatus(viewByOptionList, surveyTabData.weighted);
    } else if (activeTab === 'postview') {
      checkDisableStatus(viewByOptionList, postviewTabData.weighted);
    }
  }, [
    activeTab,
    discussionTabData.weighted,
    surveyTabData.weighted,
    postviewTabData.weighted,
    optionsLoaded,
    checkDisableStatus,
  ]);

  const Context = createContext();

  return (
    <>
      {loaded === true && chartData.length > 0 ? (
        <div>
          <div className="form-group analytic-fiter-group">
            {activeTab === 'discussion' ? (
              <>
                <label>{ t('View') }</label>
                <SelectOption
                  name={'weighted'}
                  placeholder={'View'}
                  value={handleOptionValue(
                    weightedOptions,
                    discussionTabData.weighted,
                  )}
                  options={weightedOptions}
                  handleSelectChange={(value) => {
                    changeDiscussionTabData('weighted', value.value);
                    onChangeHandler();
                  }}
                />
                <SelectOption
                  name={'viewBy'}
                  placeholder={'View'}
                  value={handleOptionValue(
                    viewByOptions,
                    discussionTabData.viewBy,
                  )}
                  options={viewByOptions}
                  handleSelectChange={(value) => {
                    changeDiscussionTabData('viewBy', value.value);
                    onChangeHandler();
                  }}
                  isOptionDisabled={(option) => option.disabled}
                  defaultValue={{ value: 'age', label: 'Age', disabled: false }}
                />
              </>
            ) : (
              ''
            )}
            {activeTab === 'survey' ? (
              <>
                <label>{t('View')}</label>
                <SelectOption
                  name={'weighted'}
                  placeholder={'View'}
                  value={handleOptionValue(
                    weightedOptions,
                    surveyTabData.weighted,
                  )}
                  options={weightedOptions}
                  handleSelectChange={(value) => {
                    changeSurveyTabData('weighted', value.value);
                    onChangeHandler();
                  }}
                />
                <SelectOption
                  name={'viewBy'}
                  placeholder={'View'}
                  value={handleOptionValue(viewByOptions, surveyTabData.viewBy)}
                  options={viewByOptions}
                  handleSelectChange={(value) => {
                    changeSurveyTabData('viewBy', value.value);
                    onChangeHandler();
                  }}
                  isOptionDisabled={(option) => option.disabled}
                />
              </>
            ) : (
              ''
            )}
            {activeTab === 'postview' ? (
              <>
                <label>{t('View')}</label>
                <SelectOption
                  name={'weighted'}
                  placeholder={'View'}
                  value={handleOptionValue(
                    weightedOptions,
                    postviewTabData.weighted,
                  )}
                  options={weightedOptions}
                  handleSelectChange={(value) => {
                    changePostviewTabData('weighted', value.value);
                    onChangeHandler();
                  }}
                />
                <SelectOption
                  name={'viewBy'}
                  placeholder={'View'}
                  value={handleOptionValue(
                    viewByOptions,
                    postviewTabData.viewBy,
                  )}
                  options={viewByOptions}
                  handleSelectChange={(value) => {
                    changePostviewTabData('viewBy', value.value);
                    onChangeHandler();
                  }}
                  isOptionDisabled={(option) => {
                    checkDisableStatus(option, postviewTabData.viewBy);
                  }}
                />
              </>
            ) : (
              ''
            )}

            {/*<label>Participation</label>
               <SelectOption
                   name={'participation'}
                   placeholder={'Participation'}
                   value={activeTab === 'discussion' ? handleOptionValue(participationOptions, discussionTabData.participation) : handleOptionValue(participationOptions, surveyTabData.participation)}
                   options={participationOptions}
                   handleSelectChange={value => {
                     if(activeTab === 'discussion') {changeDiscussionTabData('participation', value.value); } else if(activeTab === 'survey') {changeSurveyTabData('participation', value.value);} onChangeHandler();
                   }}
               /> */}
          </div>
          <div id={`barChart`} style={{ minHeight: '420px' }}>
            {loader === false ? (
              chartData.length > 1 ? (
                <Context.Provider value={chartData}>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="400px"
                    data={chartData}
                    options={chartOptions}
                    id={`google-chart`}
                  />
                </Context.Provider>
              ) : (
                <NoData />
              )
            ) : (
              <LoadingIndicator position={`relative`} top={`200px`} />
            )}
          </div>
          {activeTab === 'survey' ? (
            <SurveyAnalytics
              setActiveId={setActiveId}
              analyticsData={analyticsData}
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {loaded === false ? (
        <div style={{ minHeight: `800px` }}>
          <LoadingIndicator postion={`fixed`} />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    discussionData: state.analytics.discussionData,
    surveyData: state.analytics.surveyData,
    postviewData: state.analytics.postviewData,
    questionData: state.analytics.questionData,
    activeTab: state.analytics.activeTab,
    discussionTabData: state.analytics.discussionTabData,
    surveyTabData: state.analytics.surveyTabData,
    postviewTabData: state.analytics.postviewTabData,
    questionTabData: state.analytics.questionTabData,
  };
};

export default connect(mapStateToProps, actions)(AnalyticsView);
