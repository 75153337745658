/*
 *
 * InAppropriate Report Actions
 *
 */

import {
  INAPPROPRIATE_REPORT_FORM_CHANGE,
  INAPPROPRIATE_REPORT_ERRORS,
  INAPPROPRIATE_REPORT_RESET,
  INAPPROPRIATE_REPORT_LOADING,
  INAPPROPRIATE_REPORT_SUBMITTING,
  RESET_APP,
} from './constants';
import { BASE_API_URL } from '../../constants';
import axios from 'axios';
import { addNotification } from '../Notification/actions';
import { allFieldsValidation } from '../../utils/validation';

export const resetInAppropriateState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const inAppropriateChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({
      type: INAPPROPRIATE_REPORT_FORM_CHANGE,
      payload: formData,
    });
  };
};

export const inAppropriateReset = () => {
  return async (dispatch, getState) => {
    return dispatch({
      type: INAPPROPRIATE_REPORT_RESET,
    });
  };
};
export const inAppropriateValidate = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let report = getState().inAppropriateReport.inAppropriateData;
      let errorList = {};

      let reportData = {
        description: 'required',
      };
      const { isValid, errors } = allFieldsValidation(report, reportData, {
        'required.description': 'Required',
      });

      errorList = { ...errorList, ...errors };

      if (!isValid) {
        dispatch({ type: INAPPROPRIATE_REPORT_ERRORS, payload: errorList });
        reject(errorList);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitInAppropriate = () => {
  return async (dispatch, getState) => {
    dispatch({ type: INAPPROPRIATE_REPORT_LOADING, payload: true });
    dispatch({ type: INAPPROPRIATE_REPORT_SUBMITTING, payload: true });
    dispatch(inAppropriateValidate())
      .then(async () => {
        dispatch({ type: INAPPROPRIATE_REPORT_ERRORS, payload: {} });
        dispatch(inAppropriateReportSubmit());
      })
      .catch((error) => {
        dispatch({ type: INAPPROPRIATE_REPORT_SUBMITTING, payload: false });
        dispatch({ type: INAPPROPRIATE_REPORT_LOADING, payload: false });
      });
  };
};

export const inAppropriateReportSubmit = () => {
  return async (dispatch, getState) => {
    let report = getState().inAppropriateReport.inAppropriateData;
    let reportInfo = {
      id: report.id,
      reportLink: report.reportLink,
      description: report.description,
    };
    let roleName = getState().account.user.roleName;
    let uri =
      roleName === 'PUBLICOFFICIAL' || roleName === 'ADMIN'
        ? '/official/reportAsInappropriate'
        : '/users/reportAsInappropriate';

    dispatch({ type: INAPPROPRIATE_REPORT_LOADING, payload: true });
    await axios
      .post(BASE_API_URL + uri, reportInfo)
      .then((response) => {
        dispatch(inAppropriateChange('dismissal', true));
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'dashboard',
            parentDivClass: 'top-notification',
          }),
        );
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        if (Array.isArray(errorList) === true) {
          errorList.forEach((error, index) => {
            dispatch(
              addNotification({
                type: 'error',
                message: errorList[index],
                module: 'dashboard',
                parentDivClass: 'top-notification',
              }),
            );
          });
        } else {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList,
              module: 'dashboard',
              displayFor: 5000,
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .finally(() => {
        dispatch(inAppropriateReset());
        dispatch({ type: INAPPROPRIATE_REPORT_SUBMITTING, payload: false });
        dispatch({ type: INAPPROPRIATE_REPORT_LOADING, payload: false });
      });
  };
};
