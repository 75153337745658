/**
 *
 * Petition Filter Modal Footer Section
 *
 */

import { useTranslation } from 'react-i18next';

const FilterFooter = (props) => {
  const { resetPetitionFilter, submitPetitionFilter } = props;
  const { t } = useTranslation();
  const hashUrl = '#';

  return (
    <>
      <a
        href={hashUrl}
        className={`clear-filter mr-5`}
        onClick={() => {
          resetPetitionFilter();
        }}
      >
        {t(`Clear All`)}
      </a>
      <button
        type="button"
        className="btn clsyellow-btn"
        onClick={() => {
          submitPetitionFilter();
        }}
      >
        {t(`Submit`)}
      </button>
    </>
  );
};
export default FilterFooter;
