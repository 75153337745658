import React, {useCallback, useEffect} from "react";
import SuggestionBox from "../RegisterForm/suggestionBox";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import actions from "../../../actions";
import debounce from 'lodash.debounce';
import { getRegistrationFormError } from "../../../containers/RegisterFlow/utils";
import {Modal} from "react-bootstrap";
import SelectType from "./selectType";

const suggestData = debounce((func) => {
    func();
}, 500);

const OfficialStepOne = (props) => {

    const {
        registration,
        registerChange,
        getDistrictData,
        districtBoxChange,
        registrationFormErrors,
        officialStepOneSubmit,
        formSubmissionErrors,
        isSignUpButtonClicked
    } = props;
    
    const {t} = useTranslation();

    const openDistrictBox = () => {
        if (registration.registerFormData.district > 0) {
            districtBoxChange('display', true);
        }
    };

    useEffect(() => {
        console.log(isSignUpButtonClicked);
    }, [isSignUpButtonClicked])

    const onDistrictChange = useCallback(() => {
        registerChange('ocdId', '');
        if (registration.registerFormData.district.length > 0) {
            districtBoxChange('display', true);
            suggestData(getDistrictData);
        }
    }, [registration.registerFormData, getDistrictData, districtBoxChange, registerChange]);

    const emailError = getRegistrationFormError('email', formSubmissionErrors);
    const phoneError = getRegistrationFormError('phone', formSubmissionErrors);

    return <>
        <Modal.Header closeButton={true}>
            <Modal.Title className="w-100 text-center">
                {registration.registerFormData.step === 1 && isSignUpButtonClicked ?
                    <>
                        {t(`Register your free`)}
                        <br />
                        {t(`government official account`)}
                    </> :
                    t(`Please create your account to continue scrolling!`)}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {registration.registerFormData.step === 1 && <SelectType/>}

            <div className="Second-Step" key={'official-second-step'}>
                <div className="form-group col-md-12">
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t(`Email Address`)}
                                name={'email'}
                                value={registration.registerFormData.email}
                                onChange={(e) => registerChange(e.target.name, e.target.value)}
                                id="regevent_email"
                            />
                            {((typeof registrationFormErrors !== 'undefined' && registrationFormErrors.email) || emailError) && (
                                <div className="clsinvalid">{t(emailError) || t(registrationFormErrors?.['email'])}</div>)}
                        </div>

                        <div className="form-group col-md-12">
                            <input
                                type="password"
                                className="form-control"
                                placeholder={t(`Password`)}
                                name={'password'}
                                value={registration.registerFormData.password}
                                onChange={(e) => registerChange(e.target.name, e.target.value)}
                                autoComplete={'no'}
                                id="regevent_password"
                            />
                            {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.password && (
                                <div className="clsinvalid">{t(registrationFormErrors?.['password'])}</div>)}
                        </div>

                        <div className="form-group col-md-6">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t(`First Name`)}
                                name={'firstName'}
                                value={registration.registerFormData.firstName}
                                onChange={(e) => {
                                    registerChange(e.target.name, e.target.value);
                                }}
                                id="regevent_firstName"
                            />
                            {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.first_name && (
                                <div className="clsinvalid">{t(registrationFormErrors?.['first_name'])}</div>)}
                        </div>
                        <div className="form-group col-md-6">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t(`Last Name`)}
                                name={'lastName'}
                                value={registration.registerFormData.lastName}
                                onChange={(e) => {
                                    registerChange(e.target.name, e.target.value);
                                }}
                                id="regevent_lastName"
                            />
                            {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.last_name && (
                                <div className="clsinvalid">{t(registrationFormErrors?.['last_name'])}</div>)}

                        </div>

                        <div className="form-group col-md-12 district-suggestion-box">
                            <input
                                type="text"
                                id={`district`}
                                className="form-control"
                                placeholder={t(`Enter District`)}
                                name={'district'}
                                value={registration.registerFormData.district}
                                autoComplete={`off`}
                                onChange={(e) => {
                                    registerChange(e.target.name, e.target.value);
                                    onDistrictChange();
                                }}
                                // id="regevent_district"
                                onFocus={openDistrictBox}
                            />
                            <figure
                                className={'clssearchclose-icon'}
                                style={{
                                    display: registration.registerFormData.suggestionBox.display === true ? 'block' : 'none'
                                }}
                            >
                                <a href="#"
                                   onClick={(e) => {
                                       districtBoxChange('display', false);
                                       e.preventDefault();
                                   }}><img src={require('../../../assets/img/search-close-icon.svg').default} alt="Close"/></a>
                            </figure>
                            <SuggestionBox {...props} registerFormData={registration.registerFormData}/>
                            {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.district && (
                                <div className="clsinvalid">{t(registrationFormErrors?.['district'])}</div>)}
                        </div>

                        <div className="form-group col-md-12">
                            <div className="position-relative">
                                <div className="input-icon">
                                    <img
                                        width="30px"
                                        src={
                                            require('../../../assets/img/us-flag.svg')
                                                .default
                                        }
                                        alt="US flag"
                                    />
                                </div>
                                <input
                                    type="tel"
                                    className="form-control pl-5"
                                    placeholder={t(`Cell Phone Number`)}
                                    name={'phoneNumber'}
                                    value={registration.registerFormData.phoneNumber}
                                    onChange={(e) => registerChange(e.target.name, e.target.value)}
                                    onFocus={(e) => registerChange(e.target.name, e.target.value)}
                                    id="regevent_phone"
                                    inputmode="numeric"
                                    pattern="[0-9]*"
                                />
                            </div>
                            {((typeof registrationFormErrors !== 'undefined' && registrationFormErrors.phone_number) || phoneError) && (
                                <div className="clsinvalid">{t(phoneError) || t(registrationFormErrors?.['phone_number'])}</div>)}
                        </div>
                        <div className="form-group col-md-12" style={{textAlign: 'center'}}>
                            <button
                                type="button"
                                className="btn clsyellow-btn"
                                onClick={officialStepOneSubmit}>{t(`Create my account`)}</button>
                        </div>

                        <div className="form-group col-md-12">
                            <p className="clsagree-text col-md-12">
                                {t(`By registering, you agree to our`)}{' '}
                                <a
                                    href={`https://about.civicbell.com/policies/public-official-terms-of-services`}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    id="regevent_legal_tos"
                                >
                                    {t(`Terms of Services`)}
                                </a>
                                , {t(`our`)}{' '}
                                <a
                                    href={`https://about.civicbell.com/policies/rules-of-engagements-for-government-accounts`}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    id="regevent_legal_roe"
                                >
                                    {t(`Rules of Engagements`)}
                                </a>
                                , {t(`and`)}{' '}
                                <a
                                    href={`https://about.civicbell.com/policies/privacy-policy`}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    id="regevent_legal_privacy"
                                >
                                    {t(`Privacy Policy`)}
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        registrationFormErrors: state.registration.formErrors,
        formSubmissionErrors: state.registration.formSubmissionErrors,
        isSignUpButtonClicked: state.registration.isSignUpButtonClicked
    };
};

export default connect(mapStateToProps, actions)(OfficialStepOne);
