/*
 *
 * Search Box
 *
 */

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import actions from '../../actions';
import { useTranslation } from 'react-i18next';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const SearchBox = (props) => {
  const {
    searchKey,
    error,
    searchKeyChange,
    searchSubmit,
    searchSubmission,
    setSearchSubmision,
    resetResult,
    setSearchResetResult,
    searchResultChange,
  } = props;
  const { t } = useTranslation();

  const location = useLocation();

  const history = useHistory();

  const uri = location.pathname.replace(/^\/|\/$/g, '');

  const [changes, setChanges] = useState(false);

  const [closeStatus, setCloseStatus] = useState(false);

  const prevUri = usePrevious(uri);

  const onKeyChange = () => {
    if (searchKey.length <= 2) {
      setChanges(true);
    }
  };

  useEffect(() => {
    if (searchKey.length === 0 && resetResult === false && changes === true) {
      setChanges(false);
      setSearchResetResult(true);
    }
  }, [
    searchKey,
    searchSubmission,
    setSearchSubmision,
    resetResult,
    setSearchResetResult,
    changes,
    setChanges,
  ]);

  useEffect(() => {
    if (
      uri !== prevUri &&
      prevUri !== 'undefined' &&
      uri !== 'dashboard/searchresult' &&
      prevUri !== 'dashboard/searchresult'
    ) {
      searchKeyChange('');
      setCloseStatus(false);
      let formData = {};
      formData['districtSearchResult'] = [];
      formData['anywhereSearchResult'] = [];
      formData['searchText'] = '';
      formData['showResult'] = false;
      searchResultChange(formData);
    }
  }, [uri, prevUri, searchKeyChange, searchResultChange]);

  const handleSubmit = (e) => {
    if (!uri.includes('dashboard/civicfeed')) {
      history.push('/dashboard/searchresult');
    }
    e.preventDefault();
    searchSubmit();
    setCloseStatus(true);
    return false;
  };

  const resetSearchResult = () => {
    searchKeyChange('');
    setChanges(false);
    setSearchResetResult(true);
    let formData = {};
    formData['districtSearchResult'] = [];
    formData['anywhereSearchResult'] = [];
    formData['searchText'] = '';
    formData['showResult'] = false;
    searchResultChange(formData);
    setCloseStatus(false);
  };

  return (
    <>
      <form
        className="clssearch-form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <input id="search_submit" value="Rechercher" type="submit" />
        <input
          id="search"
          name="search"
          type="text"
          value={searchKey}
          onChange={(e) => {
            searchKeyChange(e.target.value);
            onKeyChange();
          }}
          placeholder={t(`Search`)}
        />
        {closeStatus === true ? (
          <img
            src={require('../../assets/img/close-search.svg').default}
            className="search_close"
            onClick={resetSearchResult}
            alt={`close`}
          />
        ) : (
          ''
        )}
        {error !== '' ? <div className="clsinvalid">{t(error)}</div> : ''}
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    searchKey: state.search.searchKey,
    error: state.search.error,
    searchSubmission: state.search.searchSubmission,
    resetResult: state.search.resetResult,
  };
};

export default connect(mapStateToProps, actions)(SearchBox);
