/**
 *
 * Notifications (Display for success, error and common notification)
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const Notifications = (props) => {
  const {
    notifications,
    module,
    removeNotification,
    parentDivClass,
    childDivClass,
  } = props;

  const { t } = useTranslation();

  return notifications.map((message, id) => {
    return (
      <div
        key={id}
        className={
          typeof message.parentDivClass !== 'undefined'
            ? message.parentDivClass
            : parentDivClass
        }
      >
        {message.module === module ? (
          <>
            {message.type === 'success' ? (
              <div
                className={`alert alert-primary clsalert-success ${childDivClass} ${
                  typeof message.parentDivClass !== 'undefined'
                    ? ` top-notification-success-bg`
                    : ``
                }`}
                role="alert"
                key={'notification-' + id}
              >
                {t(message.message)}
                <span>
                  <button
                    className="clsalert-close"
                    onClick={() => removeNotification(message.id)}
                  >
                    <img
                      src={
                        require('../../../assets/img/close-blue.svg').default
                      }
                      alt="Close"
                    />
                  </button>
                </span>
              </div>
            ) : (
              [
                message.type === 'error' ? (
                  <div
                    className={`alert alert-danger clsalert-danger ${childDivClass} ${
                      typeof message.parentDivClass !== 'undefined'
                        ? ` top-notification-error-bg`
                        : ``
                    }`}
                    role="alert"
                    key={'notification-' + id}
                  >
                    {t(message.message)}
                    <span>
                      <button
                        className="clsalert-close"
                        onClick={() => removeNotification(message.id)}
                      >
                        <img
                          src={
                            require('../../../assets/img/close-red.svg').default
                          }
                          alt="Close"
                        />
                      </button>
                    </span>
                  </div>
                ) : (
                  <div
                    className={`alert alert-danger clsalert-danger ${childDivClass} ${
                      typeof message.parentDivClass !== 'undefined'
                        ? ` top-notification-error-bg`
                        : ``
                    }`}
                    role="alert"
                    key={'notification-' + id}
                  >
                    {t(message.message)}
                    <span>
                      <button
                        className="clsalert-close"
                        onClick={() => removeNotification(message.id)}
                      >
                        <img
                          src={
                            require('../../../assets/img/close-red.svg').default
                          }
                          alt="Close"
                        />
                      </button>
                    </span>
                  </div>
                ),
              ]
            )}
          </>
        ) : (
          ''
        )}
      </div>
    );
  });
};

export default Notifications;
