/**
 *
 * Survey Form
 *
 */

import React, { useEffect, useState } from 'react';
import Tooltip from '../../Common/Tooltip';
import { useTranslation } from 'react-i18next';

const QuestionList = (props) => {
  const { engagement, location, surveyData, surveyChange, user } = props;
  const { t } = useTranslation();
  const uri = location.pathname.replace(/^\/|\/$/g, '');

  const [publicResult, setPublishResult] = useState(false);
  const [dashboardView, setDashboardView] = useState(false);

  useEffect(() => {
    if (
      uri === 'dashboard/civicfeed' ||
      uri === 'dashboard/user-profile' ||
      uri === 'dashboard/civiclist'
    ) {
      if (engagement.stage === 'outcome') {
        setPublishResult(true);
      }
    } else {
      if (engagement.stage === 'closed' || engagement.stage === 'outcome') {
        setPublishResult(true);
        if (user.roleName === 'PUBLICOFFICIAL' || user.roleName === 'ADMIN') {
          setDashboardView(true);
        }
      }
    }
  }, [setPublishResult, uri, engagement.stage, user.roleName]);

  return (
    <>
      {surveyData.question.length > 0 ? (
        <div className="clssurvey-preview-blk">
          <div className="description">{engagement.description}</div>
          <div
            className={`clssurvey-preview-blk-in mt-2 ${
              (uri !== 'dashboard/civicfeed' &&
                uri !== 'dashboard/user-profile' &&
                uri !== 'dashboard/civiclist' &&
                uri !== 'dashboard/engagement') ||
              engagement.stage === 'closed' ||
              engagement.stage === 'outcome' ||
              engagement.canReact === false
                ? 'survey-disabled'
                : 'survey-enabled'
            }`}
          >
            <fieldset
              disabled={
                (uri !== 'dashboard/civicfeed' &&
                  uri !== 'dashboard/user-profile' &&
                  uri !== 'dashboard/civiclist' &&
                  uri !== 'dashboard/engagement') ||
                engagement.stage === 'closed' ||
                engagement.stage === 'outcome' ||
                engagement.canReact === false
                  ? 'disabled'
                  : ''
              }
            >
              {surveyData.question.map((question, index) =>
                surveyData.question?.[index]?.['choice']?.length > 0 ? (
                  <div key={index}>
                    <h6 className={index === 0 ? 'mb-2' : 'mb-2  mt-4'}>
                      {surveyData.question[index].title}{' '}
                      <span>{`(${t(`Please select one`)})`}</span>
                    </h6>
                    {surveyData.question[index]['choice'].map(
                      (choice, choiceIndex) => (
                        <div
                          className="checkbox"
                          key={`question-${index}choice-${choiceIndex}`}
                        >
                          <input
                            type="checkbox"
                            id={`choice-${surveyData.question[index]['choice'][choiceIndex].id}`}
                            name={`choice-${surveyData.question[index].id}`}
                            checked={
                              surveyData.question[index]['choice'][choiceIndex]
                                .answered === true
                                ? true
                                : false
                            }
                            onChange={() => {
                              surveyChange(
                                engagement.id,
                                index,
                                choiceIndex,
                                !surveyData.question[index]['choice'][
                                  choiceIndex
                                ].answered,
                                surveyData.question[index].type,
                              );
                            }}
                          />
                          <label
                            htmlFor={`choice-${surveyData.question[index]['choice'][choiceIndex].id}`}
                            id={`choice-label-${surveyData.question[index]['choice'][choiceIndex].id}`}
                          >
                            {' '}
                            <span></span>
                            <h4>
                              {
                                surveyData.question[index]['choice'][
                                  choiceIndex
                                ]['value']
                              }
                            </h4>
                            {dashboardView === true ||
                            (publicResult === true &&
                              surveyData.question[index].isSelected ===
                                true) ? (
                              <span
                                className={
                                  'choiceResult ' +
                                  (surveyData.question[index].type === '1'
                                    ? 'two-choice-percentage'
                                    : 'multiple-choice-percentage')
                                }
                              >
                                {
                                  surveyData.question[index]['choice'][
                                    choiceIndex
                                  ]['result']
                                }
                                {surveyData.question[index].isSelected}
                              </span>
                            ) : (
                              ''
                            )}
                          </label>
                          {engagement.canReact === false ? (
                            <Tooltip
                              targetId={`choice-label-${surveyData.question[index]['choice'][choiceIndex].id}`}
                              targetStatus={true}
                              styleClass={'react-tooltip'}
                              message={engagement.restriction_reason}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      ),
                    )}
                  </div>
                ) : (
                  ''
                ),
              )}
            </fieldset>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default QuestionList;
