/**
 *
 * District suggestion box
 *
 */

import LoadingIndicator from '../../Common/LoadingIndicator';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

const suggestBoxDisplay = debounce((func) => {
  func('district', true);
}, 500);

const SuggestionBox = (props) => {
  const { type, registerFormData, districtBoxChange, registerChange } = props;
  const { t } = useTranslation();

  if (document.getElementById('suggestion-box')) {
    document.addEventListener('click', (event) => {
      if (
        event.target.id !== 'district' &&
        event.target.id !== 'suggestion-box' &&
        event.target.className !== 'suggest-item' &&
        registerFormData.suggestionBox.display === true
      ) {
        suggestBoxDisplay(districtBoxChange);
      }
    });
  }

  return (
    <div
      id={`suggestion-box`}
      className="clsfilter-search-show-content"
      style={{
        minHeight: '45px',
        cursor: 'pointer',
        display:
          registerFormData.suggestionBox.display === false ? 'none' : 'block',
      }}
    >
      <ul>
        {registerFormData.suggestionBox.loading === false ? (
          registerFormData.suggestionBox['items'].length > 0 ? (
            <>
              {registerFormData.suggestionBox['items'].map((item, index) => (
                <li
                  key={`${type}-suggestion-${index}`}
                  className={`suggest-item`}
                  onClick={() => {
                    registerChange('district', item.name);
                    registerChange('ocdId', item.ocdId);
                    districtBoxChange('display', false);
                  }}
                >
                  <span className={`suggest-item`}>{item.name}</span>
                </li>
              ))}
            </>
          ) : (
            <li>
              <span>{t(`No results`)}</span>
            </li>
          )
        ) : (
          <LoadingIndicator
            position={`relative`}
            height={'35px'}
            backgroundSize={'35px'}
          />
        )}
      </ul>
    </div>
  );
};

export default SuggestionBox;
