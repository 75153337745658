/*
 *
 * District Offcials List
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import OfficialsList from '../../components/Manager/CivicMap/district-popup';

const OfficialList = (props) => {
  const {
    ocdid,
    currentLayer,
    getOfficials,
    officials,
    reloadList,
    officialChangeByOption,
    user,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [officialListComponent, setOfficialListComponent] = useState('');

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setOfficialListComponent(
          <Component
            {...props}
            officialChangeByOption={officialChangeByOption}
          />,
        );
        return resolve(true);
      });
    },
    [setOfficialListComponent, props, officialChangeByOption],
  );

  useEffect(() => {
    if (loading === true) {
      setOfficialListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        const fetchOfficials = async () => {
          await getOfficials(ocdid).then(() => {
            setLoading(false);
            setInProcess(false);
          });
        };
        fetchOfficials();
      }
    }
  }, [
    loading,
    setLoading,
    getOfficials,
    currentLayer,
    renderComponent,
    ocdid,
    inProcess,
    setInProcess,
    initialLoad,
    officials,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(OfficialsList);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setLoading, setInitialLoad]);

  useEffect(() => {
    renderComponent(OfficialsList);
  }, [user, renderComponent]);

  useEffect(() => {
    setLoading(true);
  }, [currentLayer]);

  return <>{officialListComponent}</>;
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    officials: state.map.officials,
    district: state.map.district,
    noRecords: state.map.noRecords,
    reloadList: state.map.reloadList,
    pageLoader: state.map.pageLoader,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    filterDismissal: state.map.filterDismissal,
    filter: state.map.filter,
    filterSelected: state.map.filterSelected,
    searchBox: state.map.searchBox,
    isOfficialLoading: state.map.isOfficialLoading,
  };
};

export default connect(mapStateToProps, actions)(OfficialList);
