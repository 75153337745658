/**
 *
 * Civic Filter
 *
 */

import React, { useEffect, useState } from 'react';
import SelectOption from '../../Common/SelectOption';
import { loadOptionValue } from '../../../utils/helper';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const EventFilter = (props) => {
  const {
    filter,
    filterSelected,
    getFilterStates,
    getFilterCounties,
    getFilterOfficials,
    eventFilterChange,
    level2OfficialSelectAll,
    level1OfficialSelectAll,
  } = props;

  const [loading, setLoading] = useState(true);
  const [optionLoading, setOptionLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (loading === true) {
      const filterStates = async () => {
        await getFilterStates().then(async () => {
          setLoading(false);
          setOptionLoading(false);
        });
      };
      filterStates();
    }
  }, [loading, getFilterStates]);

  useEffect(() => {
    if (filterSelected.state) {
      setOptionLoading(true);
      const filterCounties = async () => {
        await getFilterCounties().then(async () => {
          setOptionLoading(false);
        });
      };
      filterCounties();
    }
  }, [filterSelected.state, getFilterCounties]);

  useEffect(() => {
    if (filterSelected.county >= 0) {
      setOptionLoading(true);
      const filterOfficials = async () => {
        await getFilterOfficials().then(async () => {
          setOptionLoading(false);
        });
      };
      filterOfficials();
    }
  }, [filterSelected.county, getFilterOfficials]);

  return (
    <div className="clsevents-filter-content">
      {optionLoading && <LoadingIndicator />}
      <div className="form-group">
        <label htmlFor="">State</label>
        {optionLoading === false ? (
          <SelectOption
            options={filter.states}
            name="state"
            value={loadOptionValue(filter.states, filterSelected.state)}
            handleSelectChange={(e) => eventFilterChange('state', e.value)}
          />
        ) : (
          ''
        )}
      </div>
      <div className="form-group">
        <label htmlFor="">County</label>
        {optionLoading === false ? (
          <SelectOption
            options={filter.counties}
            name="county"
            value={loadOptionValue(filter.counties, filterSelected.county)}
            handleSelectChange={(e) => eventFilterChange('county', e.value)}
          />
        ) : (
          ''
        )}
      </div>
      <div id="accordion">
        {Object.keys(filter.officials).length ? (
          Object.keys(filter.officials).map((level1Item, l1index) => (
            <div className="card mb-2" key={l1index}>
              <div className="card-header" id={`heading-${l1index}`}>
                <h5 className="mb-0">
                  <a
                    role="button"
                    data-toggle="collapse"
                    href={`#collapse-${l1index}`}
                    aria-expanded={l1index === 0 ? true : false}
                    aria-controls={`collapse-${l1index}`}
                  >
                    {level1Item}
                  </a>
                  <div className="clscheckbox">
                    <span>
                      <label>
                        <input
                          id={`level-${l1index}`}
                          checked={
                            filterSelected['level'].indexOf(`${l1index}`) !== -1
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            e.target.value =
                              filterSelected['level'].indexOf(`${l1index}`) !==
                              -1
                                ? true
                                : false;
                          }}
                          type="checkbox"
                          name="roomtemp"
                          value="1"
                        />
                        <label
                          htmlFor={`level-${l1index}`}
                          onClick={() => {
                            level1OfficialSelectAll(
                              filter.officials[level1Item],
                              `${l1index}`,
                            );
                          }}
                        >
                          <span></span>
                        </label>
                      </label>
                    </span>
                  </div>
                </h5>
              </div>
              <div
                id={`collapse-${l1index}`}
                className={l1index === 0 ? 'collapse show' : 'collapse'}
                data-parent="#accordion"
                aria-labelledby={`heading-${l1index}`}
              >
                <div className="card-body">
                  <div id={`accordion-${l1index}`}>
                    {Object.keys(filter.officials[level1Item]).length
                      ? Object.keys(filter.officials[level1Item]).map(
                          (level2Item, l2index) => (
                            <div className="card" key={l2index}>
                              <div
                                className="card-header pt-0"
                                id={`heading-${l1index}-${l2index}`}
                              >
                                <h5 className="mb-0">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-toggle="collapse"
                                    href={`#collapse-${l1index}-${l2index}`}
                                    aria-expanded={
                                      l1index === 0 && l2index === 0
                                        ? true
                                        : false
                                    }
                                    aria-controls={`collapse-${l1index}-${l2index}`}
                                  >
                                    {level2Item}
                                  </a>
                                  <div className="clscheckbox">
                                    <span>
                                      <label>
                                        <input
                                          id={`level-${l1index}-${l2index}`}
                                          checked={
                                            filterSelected['level'].indexOf(
                                              `${l1index}-${l2index}`,
                                            ) !== -1
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            e.target.value =
                                              filterSelected['level'].indexOf(
                                                `${l1index}-${l2index}`,
                                              ) !== -1
                                                ? true
                                                : false;
                                          }}
                                          type="checkbox"
                                          name="official"
                                          value="school_elem"
                                        />
                                        <label
                                          htmlFor={`level-${l1index}-${l2index}`}
                                          onClick={() => {
                                            level2OfficialSelectAll(
                                              filter.officials[level1Item][
                                                level2Item
                                              ],
                                              `${l1index}-${l2index}`,
                                            );
                                          }}
                                        >
                                          <span></span>
                                        </label>
                                      </label>
                                    </span>
                                  </div>
                                </h5>
                              </div>
                              {filter.officials[level1Item][level2Item].length
                                ? filter.officials[level1Item][level2Item].map(
                                    (official, l3index) => (
                                      <div
                                        id={`collapse-${l1index}-${l2index}`}
                                        className={
                                          l1index === 0 && l2index === 0
                                            ? 'collapse show'
                                            : 'collapse'
                                        }
                                        data-parent={`#accordion-${l1index}`}
                                        aria-labelledby={`heading-${l1index}-${l2index}`}
                                        key={l3index}
                                      >
                                        <div className="card-body">
                                          <div className="clsevents-filter-users-list">
                                            <ul>
                                              <li>
                                                <div className="clsevents-user">
                                                  <img
                                                    src={
                                                      official.officialUser
                                                        .profile_picture !==
                                                      null
                                                        ? official.officialUser
                                                            .profile_picture
                                                        : require('../../../assets/img/profile-image.jpeg')
                                                            .default
                                                    }
                                                    alt={t('User')}
                                                    width="24"
                                                  />
                                                  <em>
                                                    {official.officialUser
                                                      .showMyNameAs ===
                                                    'organization'
                                                      ? official.organization_official &&
                                                        official
                                                          .organization_official
                                                          .name
                                                      : official.officialUser
                                                          .first_name +
                                                        ' ' +
                                                        official.officialUser
                                                          .last_name +
                                                        (official.position &&
                                                          ', ' +
                                                            official.position)}
                                                  </em>
                                                </div>
                                                <div className="clsevents-user-checkbox">
                                                  <div className="clscheckbox">
                                                    <span>
                                                      <label>
                                                        <input
                                                          id={`level-${l1index}-${l2index}-${l3index}`}
                                                          checked={
                                                            filterSelected[
                                                              'officials'
                                                            ].indexOf(
                                                              official
                                                                .officialUser
                                                                .userId,
                                                            ) !== -1
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) => {}}
                                                          type="checkbox"
                                                          name="roomtemp"
                                                          value="school_elem"
                                                        />
                                                        <label
                                                          htmlFor={`level-${l1index}-${l2index}-${l3index}`}
                                                          onClick={() => {
                                                            eventFilterChange(
                                                              'officials',
                                                              official
                                                                .officialUser
                                                                .userId,
                                                            );
                                                          }}
                                                        >
                                                          <span></span>
                                                        </label>
                                                      </label>
                                                    </span>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                  )
                                : ''}
                            </div>
                          ),
                        )
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <center>
            <h6 className="align-center">{t(`No officals found!`)}</h6>
          </center>
        )}
      </div>
    </div>
  );
};
export default EventFilter;
