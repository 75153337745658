import React, { useState, useEffect } from 'react';
import SelectOption from '../../Common/SelectOption';
import { loadOptionValue } from '../../../utils/helper';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const LocalNewsFilter = (props) => {
  const {
    filter,
    filterSelected,
    getFilterNewsStates,
    getFilterNewsCounties,
    getFilterNewsFeeds,
    localNewsFilterChange,
  } = props;

  const showSources = filter.feedSources;

  const [loading, setLoading] = useState(true);
  const [optionLoading, setOptionLoading] = useState(false);
  useEffect(() => {
    if (loading === true) {
      const filterStates = async () => {
        await getFilterNewsStates().then(async () => {
          setLoading(false);
          setOptionLoading(false);
        });
      };
      filterStates();
    }
  }, [loading, getFilterNewsStates]);

  useEffect(() => {
    if (filterSelected.state) {
      setOptionLoading(true);
      const filterCounties = async () => {
        await getFilterNewsCounties().then(async () => {
          setOptionLoading(false);
        });
      };
      filterCounties();
    }
  }, [filterSelected.state, getFilterNewsCounties]);

  useEffect(() => {
    if (filterSelected.county) {
      setOptionLoading(true);
      const filterOfficials = async () => {
        await getFilterNewsFeeds().finally(async () => {
          setOptionLoading(false);
        });
      };
      filterOfficials();
    }
  }, [filterSelected.county, getFilterNewsFeeds]);

  const { t } = useTranslation();

  return (
    <form action="">
      {optionLoading && <LoadingIndicator />}
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="inputEmail4">{t(`State`)}</label>
          <SelectOption
            options={filter.states}
            placeholder={t(`Select`)}
            name="state"
            value={loadOptionValue(filter.states, filterSelected.state)}
            handleSelectChange={(e) => localNewsFilterChange('state', e.value)}
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="inputPassword4">{t(`County`)}</label>
          <SelectOption
            options={filter.counties}
            placeholder={t(`Select`)}
            name="county"
            value={loadOptionValue(filter.counties, filterSelected.county)}
            handleSelectChange={(e) => localNewsFilterChange('county', e.value)}
          />
        </div>
      </div>
      <div className="tabbable-panel">
        <div className="tabbable-line mb-2">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="cityFilter-tab"
                data-toggle="tab"
                href="#cityFilter"
                role="tab"
                aria-controls="cityFilter"
                aria-selected="true"
              >
                {t(`City`)}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="countyFilter-tab"
                data-toggle="tab"
                href="#countyFilter"
                role="tab"
                aria-controls="countyFilter"
                aria-selected="false"
              >
                {t(`County`)}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="stateFilter-tab"
                data-toggle="tab"
                href="#stateFilter"
                role="tab"
                aria-controls="stateFilter"
                aria-selected="false"
              >
                {t(`State`)}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane show active"
              id="cityFilter"
              role="tabpanel"
              aria-labelledby="cityFilter-tab"
            >
              <div className="clsnews-filter-tab-content">
                <div className="clsnews-filter-checkbox mt-3">
                  {showSources.city_sources.length === 0 ? (
                    <center>{t(`No City News Source Found`)}</center>
                  ) : showSources.city_sources !== undefined ? (
                    showSources.city_sources.map((citySource, index) => (
                      <div className="clscheckbox" key={index}>
                        <span>
                          <label>
                            <input
                              id={`feedSources-${index}`}
                              checked={
                                filterSelected['feedSources'].indexOf(
                                  citySource.rssfeedId,
                                ) !== -1
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.value =
                                  filterSelected['feedSources'].indexOf(
                                    citySource.rssfeedId,
                                  ) !== -1
                                    ? true
                                    : false;
                              }}
                              type="checkbox"
                              name="roomtemp"
                              value="school_elem"
                            />
                            <label
                              htmlFor={`feedSources-${index}`}
                              onClick={() => {
                                localNewsFilterChange(
                                  'feedSources',
                                  citySource.rssfeedId,
                                );
                              }}
                            >
                              <div className="clsinner-div">
                                {citySource.news_source}
                                <span></span>
                              </div>
                            </label>
                          </label>
                        </span>
                      </div>
                    ))
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div
              className="tab-pane"
              id="countyFilter"
              role="tabpanel"
              aria-labelledby="countyFilter-tab"
            >
              <div className="clsnews-filter-tab-content">
                <div className="clsnews-filter-checkbox mt-3">
                  {showSources.county_sources.length === 0 ? (
                    <center>{t(`No County News Source Found`)}</center>
                  ) : showSources.county_sources !== undefined ? (
                    showSources.county_sources.map((countySource, index) => (
                      <div className="clscheckbox" key={index}>
                        <span>
                          <label>
                            <input
                              id={`feedSources-${index}`}
                              checked={
                                filterSelected['feedSources'].indexOf(
                                  countySource.rssfeedId,
                                ) !== -1
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.value =
                                  filterSelected['feedSources'].indexOf(
                                    countySource.rssfeedId,
                                  ) !== -1
                                    ? true
                                    : false;
                              }}
                              type="checkbox"
                              name="roomtemp"
                              value="school_elem"
                            />
                            <label
                              htmlFor={`feedSources-${index}`}
                              onClick={() => {
                                localNewsFilterChange(
                                  'feedSources',
                                  countySource.rssfeedId,
                                );
                              }}
                            >
                              <div className="clsinner-div">
                                {countySource.news_source}
                                <span></span>
                              </div>
                            </label>
                          </label>
                        </span>
                      </div>
                    ))
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div
              className="tab-pane"
              id="stateFilter"
              role="tabpanel"
              aria-labelledby="stateFilter-tab"
            >
              <div className="clsnews-filter-tab-content">
                <div className="clsnews-filter-checkbox mt-3">
                  {showSources.state_sources.length === 0 ? (
                    <center>{t(`No State News Source Found`)}</center>
                  ) : showSources.state_sources !== undefined ? (
                    showSources.state_sources.map((stateSource, index) => (
                      <div className="clscheckbox" key={index}>
                        <span>
                          <label>
                            <input
                              id={`feedSources-${index}`}
                              checked={
                                filterSelected['feedSources'].indexOf(
                                  stateSource.rssfeedId,
                                ) !== -1
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                e.target.value =
                                  filterSelected['feedSources'].indexOf(
                                    stateSource.rssfeedId,
                                  ) !== -1
                                    ? true
                                    : false;
                              }}
                              type="checkbox"
                              name="roomtemp"
                              value="school_elem"
                            />
                            <label
                              htmlFor={`feedSources-${index}`}
                              onClick={() => {
                                localNewsFilterChange(
                                  'feedSources',
                                  stateSource.rssfeedId,
                                );
                              }}
                            >
                              <div className="clsinner-div">
                                {stateSource.news_source}
                                <span></span>
                              </div>
                            </label>
                          </label>
                        </span>
                      </div>
                    ))
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LocalNewsFilter;
