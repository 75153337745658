/**
 *
 * Profile Legal Page
 *
 */

import React from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const ProfileLegal = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  return (
    <>
      {typeof user.roleName !== 'undefined' ? (
        <div className="clssettings-blk">
          <h5 className="mt-4 mb-2 pb-1">{t(`Legal`)}</h5>
          <div className="clswhite-box clssettings-box">
            <ul>
              <li>
                <a
                  href={'https://about.civicbell.com/policies/privacy-policy'}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      src={require('../../../assets/img/privacy.svg').default}
                      alt="Privacy Policy"
                    />
                    {t(`Privacy Policy`)}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={'https://about.civicbell.com/policies/cookie-policy'}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      src={require('../../../assets/img/privacy.svg').default}
                      alt="Cookie Policy"
                    />
                    {t(`Cookie Policy`)}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={
                    'https://about.civicbell.com/policies/community-guidelines'
                  }
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      src={require('../../../assets/img/privacy.svg').default}
                      alt="Community Guidelines"
                    />
                    {t(`Community Guidelines`)}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={
                    user.roleName === 'PUBLICOFFICIAL'
                      ? 'https://about.civicbell.com/policies/public-official-terms-of-services'
                      : 'https://about.civicbell.com/policies/resident-terms-of-services'
                  }
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      src={require('../../../assets/img/terms.svg').default}
                      alt="Terms and Conditions"
                    />{' '}
                    {t(`Terms and Conditions`)}
                  </span>
                </a>
              </li>
              {user.roleName === 'PUBLICOFFICIAL' ? (
                <li>
                  <a
                    href={
                      'https://about.civicbell.com/policies/federal-government-addendum-to-public-official-terms-of-service'
                    }
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    <span>
                      <img
                        src={require('../../../assets/img/terms.svg').default}
                        alt="Federal Govt Addendum"
                      />{' '}
                      {t(
                        `Federal Government Addendum to Elected and Public Official Terms of Service`,
                      )}
                    </span>
                  </a>
                </li>
              ) : (
                ''
              )}
              <li>
                <a
                  href={
                    'https://about.civicbell.com/policies/trademark-and-copyright-policy'
                  }
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      src={require('../../../assets/img/privacy.svg').default}
                      alt="Trademark and Copyright Policy"
                    />{' '}
                    {t(`Trademark and Copyright Policy`)}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <LoadingIndicator postion={`fixed`} />
      )}
    </>
  );
};

export default ProfileLegal;
