/**
 *
 * CivicFeed Filter Modal Footer Section
 *
 */

import { useTranslation } from 'react-i18next';

const FilterFooter = (props) => {
  const { resetFilter, submitFilter } = props;
  const { t } = useTranslation();
  const hashUrl = '#';

  return (
    <>
      <a
        href={hashUrl}
        className={`clear-filter mr-5`}
        onClick={() => {
          resetFilter();
        }}
      >
        {t(`Clear All`)}
      </a>
      <button
        type="button"
        className="btn clsyellow-btn"
        onClick={() => {
          submitFilter();
        }}
      >
        {t(`Submit`)}
      </button>
    </>
  );
};

export default FilterFooter;
