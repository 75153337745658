/**
 *
 * Post Type Event
 *
 */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getOfficialName, getOfficialPosition } from '../../../utils/helper';
import EngagementList from '../../../containers/CivicFeed/official-posts';
import { useTranslation } from 'react-i18next';
import RenderProfileView from './renderProfileView';

const PostView = (props) => {
  const [activeTab, setActiveTab] = useState('about');
  const { userProfile, followPublicOfficial, user } = props;
  const { t } = useTranslation();
  const hashUrl = '#';
  const history = useHistory();
  const initialValue = {
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/',
    linkedIn: 'https://www.linkedin.com/',
    nextDoor: 'https://nextdoor.com/',
  };

  var socialPlatform_Facebook = 0;
  var socialPlatform_Twitter = 0;
  var socialPlatform_Linkedin = 0;
  var socialPlatform_Nextdoor = 0;

  if (userProfile.socialPlatform !== null) {
    if (typeof userProfile.socialPlatform.facebook !== 'undefined') {
      if (
        userProfile.socialPlatform.facebook !== '' &&
        userProfile.socialPlatform.facebook !== null &&
        userProfile.socialPlatform.facebook !== initialValue.facebook
      ) {
        socialPlatform_Facebook = userProfile.socialPlatform.facebook;
      }
    }

    if (typeof userProfile.socialPlatform.twitter !== 'undefined') {
      if (
        userProfile.socialPlatform.twitter !== '' &&
        userProfile.socialPlatform.twitter !== null &&
        userProfile.socialPlatform.twitter !== initialValue.twitter
      ) {
        socialPlatform_Twitter = userProfile.socialPlatform.twitter;
      }
    }

    if (typeof userProfile.socialPlatform.linkedIn !== 'undefined') {
      if (
        userProfile.socialPlatform.linkedIn !== '' &&
        userProfile.socialPlatform.linkedIn !== null &&
        userProfile.socialPlatform.linkedIn !== initialValue.linkedIn
      ) {
        socialPlatform_Linkedin = userProfile.socialPlatform.linkedIn;
      }
    }

    if (typeof userProfile.socialPlatform.nextDoor !== 'undefined') {
      if (
        userProfile.socialPlatform.nextDoor !== '' &&
        userProfile.socialPlatform.nextDoor !== null &&
        userProfile.socialPlatform.nextDoor !== initialValue.nextDoor
      ) {
        socialPlatform_Nextdoor = userProfile.socialPlatform.nextDoor;
      }
    }
  }

  var socialPlatform_Viewable = 1;
  if (
    socialPlatform_Facebook === 0 &&
    socialPlatform_Twitter === 0 &&
    socialPlatform_Linkedin === 0 &&
    socialPlatform_Nextdoor === 0
  ) {
    socialPlatform_Viewable = 0;
  }

  return (
    <>
      {typeof user.roleName !== 'undefined' ? (
        <div className="clsback-link mb-3">
          <a
            href={hashUrl}
            onClick={(e) => {
              history.goBack();
              e.preventDefault();
            }}
          >
            <img
              src={require('../../../assets/img/back.svg').default}
              alt="Back"
            />{' '}
            {t(`Go Back`)}
          </a>
        </div>
      ) : (
        ''
      )}
      <div
        className={`clsprofile-details-blk clsgovt-profile-details-blk clswhite-box ${
          userProfile.roleId !== 2 && 'mt-5'
        }`}
      >
        {userProfile.roleId === 2 ? (
          <figure className="clsprofile-post-img">
            <img
              src={
                userProfile.profile_bg_picture !== null
                  ? userProfile.profile_bg_picture
                  : require('../../../assets/img/profile-post-img.jpg').default
              }
              alt={getOfficialName(userProfile)}
              className="img-fluid profile-bg-image"
            />
          </figure>
        ) : (
          ''
        )}
        <div className="clsprofile-details">
          <div className="media">
            <span className="clsprofile-text">
              <img
                src={
                  userProfile.profile_picture !== null
                    ? userProfile.profile_picture
                    : require('../../../assets/img/profile-image.jpeg').default
                }
                alt={getOfficialName(userProfile)}
              />
            </span>
            <div className="media-body align-self-end mb-1 ml-3">
              <h5 className="mt-0">{getOfficialName(userProfile)}</h5>
              <p>
                {userProfile.roleId === 2
                  ? getOfficialPosition(userProfile)
                  : userProfile.city}
                &nbsp;
              </p>
            </div>
          </div>
          {userProfile.roleId === 2 ? (
            <div className="clsuser-actions-blk">
              <ul>
                <li>
                  <div className="clsprofile-followers">
                    <figure className="mb-1">
                      <img
                        src={require('../../../assets/img/people.svg').default}
                        alt="People"
                      />
                    </figure>
                    <h6>
                      {userProfile.followers} <span>{t(`Followers`)}</span>
                    </h6>
                  </div>
                </li>

                {typeof user.roleName !== 'undefined' &&
                userProfile.userOutsideToPO === true ? (
                  <li>
                    <div className="clsprofile-followers">
                      {userProfile.userFollow}
                      {userProfile.userFollow ? (
                        <>
                          <h6>
                            <span>{t(`Following`)}</span>
                          </h6>
                          <h5>
                            <a
                              href={hashUrl}
                              onClick={(e) => {
                                followPublicOfficial(userProfile.userId, true);
                                e.preventDefault();
                              }}
                              className="clsunfollow"
                            >
                              {t(`Unfollow`)}
                            </a>
                          </h5>
                        </>
                      ) : (
                        <h5>
                          <a
                            href={hashUrl}
                            onClick={(e) => {
                              followPublicOfficial(userProfile.userId, true);
                              e.preventDefault();
                            }}
                            className="clsfollow"
                          >
                            {t(`Follow`)}
                          </a>
                        </h5>
                      )}
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {/* <li>
                            <div className="clsprofile-followers">
                                <figure className="mb-1">
                                    <img src={require('../../../assets/img/message-grey.svg').default} alt="Message" />
                                </figure>
                                <h5><a href="#">Send Message</a></h5>
                            </div>
                        </li> */}
              </ul>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="clsgovt-tab-blk mt-3 mb-5 pb-4">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className={activeTab === 'about' ? 'nav-link active' : 'nav-link'}
              id="nav-about-tab"
              data-toggle="tab"
              href="#nav-about"
              role="tab"
              aria-controls="nav-about"
              aria-selected="true"
              onClick={() => {
                setActiveTab('about');
              }}
            >
              {t(`About`)}
            </a>
            {userProfile.roleId === 2 ? (
              <a
                className={
                  activeTab === 'posts' ? 'nav-link active' : 'nav-link'
                }
                id="nav-post-tab"
                data-toggle="tab"
                href="#nav-post"
                role="tab"
                aria-controls="nav-post"
                aria-selected="false"
                onClick={() => {
                  setActiveTab('posts');
                }}
              >
                {t(`Posts`)}
              </a>
            ) : (
              ''
            )}
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className={
              activeTab === 'about'
                ? 'tab-pane fade show active'
                : 'tab-pane fade'
            }
            id="nav-about"
            role="tabpanel"
            aria-labelledby="nav-about-tab"
          >
            {activeTab === 'about' ? (
              <>
                <div className="clsprofile-about-blk clswhite-box mt-4">
                  <h5 className="mb-2 pb-1">{t(`About Me`)}</h5>
                  <div className={`editor-content`}>
                    {!!userProfile.aboutMe_html ? 
                      <RenderProfileView
                        user={user}
                        userProfile={userProfile}
                        officialInformations={userProfile?.userOfficial}
                        defaultText={userProfile?.aboutMe_html}
                        type={'aboutMe'}
                        format={'HTML'} 
                      />
                    : 
                      <RenderProfileView
                        user={user}
                        userProfile={userProfile}
                        officialInformations={userProfile?.userOfficial}
                        defaultText={userProfile?.aboutMe}
                        type={'aboutMe'}
                        format={'JSON'} 
                      />
                    }
                  </div>
                </div>
                {userProfile.userOfficial !== null ? (
                  <>
                    <div className="clsprofile-about-blk clswhite-box mt-2">
                      <h5 className="mb-2 pb-1">
                        {t(`Political Affiliation`)}
                      </h5>
                      <div className={`editor-content`}>
                        {!!userProfile.userOfficial.politicalAffiliation_html ? 
                          <RenderProfileView
                            user={user}
                            userProfile={userProfile}
                            officialInformations={userProfile?.userOfficial}
                            defaultText={userProfile?.userOfficial?.politicalAffiliation_html}
                            type={'politicalAffiliation'}
                            format={'HTML'} 
                          />
                        : 
                          <RenderProfileView
                            user={user}
                            userProfile={userProfile}
                            officialInformations={userProfile?.userOfficial}
                            defaultText={userProfile?.userOfficial?.politicalAffiliation}
                            type={'politicalAffiliation'}
                            format={'JSON'} 
                          />
                        }
                      </div>
                    </div>
                    <div className="clsprofile-about-blk clswhite-box mt-2">
                      <h5 className="mb-2 pb-1">
                        {t(`WHAT DO I STAND FOR`)}
                      </h5>
                      <div className={`editor-content`}>
                        {!!userProfile.userOfficial.whatDoIStandFor_html ? 
                          <RenderProfileView
                            user={user}
                            userProfile={userProfile}
                            officialInformations={userProfile?.userOfficial}
                            defaultText={userProfile?.userOfficial?.whatDoIStandFor_html}
                            type={'whatDoIStandFor'}
                            format={'HTML'} 
                          />
                        : 
                          <RenderProfileView
                            user={user}
                            userProfile={userProfile}
                            officialInformations={userProfile?.userOfficial}
                            defaultText={userProfile?.userOfficial?.whatDoIStandFor}
                            type={'whatDoIStandFor'}
                            format={'JSON'} 
                          />
                        }
                      </div>
                    </div>
                    <div className="clsprofile-about-blk clswhite-box mt-2">
                      <h5 className="mb-2 pb-1">
                        {t(`POLITICAL & PROFESSIONAL BACKGROUND`)}
                      </h5>
                      <div className={`editor-content`}>
                        {!!userProfile.userOfficial.politicalBackground_html ? 
                          <RenderProfileView
                            user={user}
                            userProfile={userProfile}
                            officialInformations={userProfile?.userOfficial}
                            defaultText={userProfile?.userOfficial?.politicalBackground_html}
                            type={'politicalBackground'}
                            format={'HTML'} 
                          />
                        : 
                          <RenderProfileView
                            user={user}
                            userProfile={userProfile}
                            officialInformations={userProfile?.userOfficial}
                            defaultText={userProfile?.userOfficial?.politicalBackground}
                            type={'politicalBackground'}
                            format={'JSON'} 
                          />
                        }
                       </div>
                    </div>
                  </>
                ) : (
                  ''
                )}

                {socialPlatform_Viewable ? (
                  <div className="clsprofile-socialmedia-blk clswhite-box mt-2">
                    <h5 className="mb-2 pb-1">{t(`MY SOCIAL MEDIA`)}</h5>
                    <ul>
                      {socialPlatform_Twitter ? (
                        <li>
                          <a
                            href={userProfile.socialPlatform.twitter}
                            target={`_blank`}
                          >
                            <img
                              src={
                                require('../../../assets/img/x-twitter.svg')
                                  .default
                              }
                              alt="Twitter"
                            />
                          </a>
                        </li>
                      ) : (
                        ''
                      )}

                      {socialPlatform_Facebook ? (
                        <li>
                          <a
                            href={userProfile.socialPlatform.facebook}
                            target={`_blank`}
                          >
                            <img
                              src={
                                require('../../../assets/img/facebook.svg')
                                  .default
                              }
                              alt="Facebook"
                            />
                          </a>
                        </li>
                      ) : (
                        ''
                      )}
                      {socialPlatform_Linkedin ? (
                        <li>
                          <a
                            href={userProfile.socialPlatform.linkedIn}
                            target={`_blank`}
                          >
                            <img
                              src={
                                require('../../../assets/img/linkedin.svg')
                                  .default
                              }
                              alt="Linkedin"
                            />
                          </a>
                        </li>
                      ) : (
                        ''
                      )}
                      {socialPlatform_Nextdoor ? (
                        <li>
                          <a
                            href={userProfile.socialPlatform.nextDoor}
                            target={`_blank`}
                          >
                            <img
                              src={
                                require('../../../assets/img/nextdoor-social.png')
                                  .default
                              }
                              alt="Nextdoor"
                            />
                          </a>
                        </li>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
          {userProfile.roleId === 2 ? (
            <div
              className={
                activeTab === 'posts'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="nav-post"
              role="tabpanel"
              aria-labelledby="nav-post-tab"
            >
              {activeTab === 'posts' ? (
                <EngagementList {...props} officialId={userProfile.userId} />
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default PostView;
