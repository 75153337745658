/**
 *
 * Petition Button
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { useTranslation } from 'react-i18next';

const PetitionButton = (props) => {
  const { blocks, user, location, resetPetitionForm, petitionFormData } = props;
  const { t } = useTranslation();
  const uri = location.pathname.replace(/^\/|\/$/g, '');

  const canShow =
    typeof blocks[user.roleName] !== 'undefined'
      ? blocks[user.roleName].filter((block) => block.name === 'New Petition')
      : [];

  const hashUrl = '#';

  return (
    <>
      {typeof blocks !== 'undefined' &&
      Object.keys(blocks).length !== 0 &&
      typeof user.roleName !== 'undefined' &&
      typeof canShow !== 'undefined' ? (
        <>
          {canShow.length > 0 && uri === 'dashboard/initiatives' ? (
            <div className="text-center clsstart-engagement-button mb-4">
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target="#form-petition"
                className="btn btn-block clsanchor-btn clsyellow-btn"
                onClick={() => {
                  if (petitionFormData.id) resetPetitionForm();
                }}
              >
                <span className="mr-2">
                  <img
                    src={require('../../../assets/img/plus-white.svg').default}
                    alt="New Petition"
                  />
                </span>
                {t('New Initiative')}
              </a>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    petitionFormData: state.petition.petitionFormData,
  };
};

export default connect(mapStateToProps, actions)(PetitionButton);
