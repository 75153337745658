/*
 *
 * Events
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import MyEvents from '../../components/Manager/Events/my-events';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Filter from '../../components/Manager/Events/filter';
import Notification from '../../containers/Notification';
import { useTranslation } from 'react-i18next';

const MyEventsList = (props) => {
  const {
    activeEventTab,
    setEventTab,
    activeMyEventTab,
    setMyEventTab,
    getMyCivicEvents,
    myEvents,
    noRecords,
    reloadList,
    resetResult,
    searchSubmission,
    reloadCivicEvent,
    searchResultReset,
    eventChangeByOption,
    user,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [eventListComponent, setEventListComponent] = useState('');

  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const resetTabContent = (tab) => {
    setLoading(true);
    setInitialLoad(true);
    setPage(1);
    setMyEventTab(tab);
  };
  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setEventListComponent(
          <Component
            {...props}
            expired={activeMyEventTab === 'past-events' ? true : false}
            eventChangeByOption={eventChangeByOption}
          />,
        );
        return resolve(true);
      });
    },
    [setEventListComponent, props, eventChangeByOption, activeMyEventTab],
  );

  const fetchEvents = useCallback(async () => {
    setInProcess(true);
    await getMyCivicEvents(page, 2).then(async () => {
      await renderComponent(MyEvents).then(() => {
        setPage(page + 1);
      });
    });
    setInProcess(false);
  }, [getMyCivicEvents, renderComponent, setPage, page]);

  useEffect(() => {
    if (loading === true) {
      setEventListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        const fetchEvents = async () => {
          await getMyCivicEvents().then(() => {
            setLoading(false);
            setInProcess(false);
          });
        };
        fetchEvents();
      }
    }
  }, [
    loading,
    setLoading,
    setEventTab,
    activeEventTab,
    getMyCivicEvents,
    renderComponent,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    myEvents,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(MyEvents);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setPage, setLoading, setInitialLoad]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await reloadCivicEvent();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, reloadCivicEvent],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    renderComponent(MyEvents);
  }, [user, renderComponent, page]);

  const EventListContent = (
    <>
      <Notification module={'event'} />
      <Filter {...props} noFilter={true} />
      {eventListComponent}
      {page === 2 && !noRecords ? (
        <div style={{ minHeight: '200px' }}></div>
      ) : (
        ''
      )}
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={myEvents.length}
            next={fetchEvents}
            hasMore={!noRecords}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
        </>
      )}
    </>
  );

  return (
    <div className="clsall-events-tab">
      <ul className="nav nav-tabs mb-4" id="myEventsTab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className={
              activeMyEventTab === 'upcoming-events'
                ? 'nav-link active'
                : 'nav-link'
            }
            id="upcoming-events-tab"
            data-toggle="tab"
            href="#upcoming-events"
            role="tab"
            aria-controls="upcoming-events"
            onClick={() => {
              resetTabContent('upcoming-events');
            }}
            aria-selected={
              activeMyEventTab === 'upcoming-events' ? 'true' : 'false'
            }
          >
            {t(`Upcoming Events`)}
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className={
              activeMyEventTab === 'past-events'
                ? 'nav-link active'
                : 'nav-link'
            }
            id="past-events-tab"
            data-toggle="tab"
            href="#past-events"
            role="tab"
            aria-controls="past-events"
            onClick={() => {
              resetTabContent('past-events');
            }}
            aria-selected={
              activeMyEventTab === 'past-events' ? 'true' : 'false'
            }
          >
            {t(`Past Events`)}
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myEventsTabContent">
        <div
          className={
            activeMyEventTab === 'upcoming-events'
              ? 'tab-pane fade show active'
              : 'tab-pane fade'
          }
          id="upcoming-events"
          role="tabpanel"
          aria-labelledby="upcoming-events-tab"
        >
          {activeMyEventTab === 'upcoming-events' ? EventListContent : ''}
        </div>

        <div
          className={
            activeMyEventTab === 'past-events'
              ? 'tab-pane fade show active'
              : 'tab-pane fade'
          }
          id="past-events"
          role="tabpanel"
          aria-labelledby="past-events-tab"
        >
          {activeMyEventTab === 'past-events' ? EventListContent : ''}
        </div>
      </div>
    </div>
  );
};

export default MyEventsList;
