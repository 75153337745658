/**
 *
 * Authentication
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

export default function auth(ComposedComponent) {
  const Authentication = (props) => {
    const { authenticated, user, landingPages, location, receivedUrl } = props;
    const uri = location.pathname.replace(/^\/|\/$/g, '');

    if (!authenticated) {
      return <Redirect to="/dashboard/civicfeed" />;
    } else {
      if (typeof user.roleName === 'undefined') {
        return <LoadingIndicator />;
      } else if (uri === 'dashboard' && user.roleName === 'PUBLICOFFICIAL') {
        return receivedUrl === '' ? (
          <ComposedComponent {...props} />
        ) : (
          <Redirect to={receivedUrl} />
        );
      } else if (
        uri === 'dashboard' &&
        user.roleName !== 'ADMIN' &&
        user.roleName !== 'PUBLICOFFICIAL'
      ) {
        return (
          <Redirect
            to={receivedUrl === '' ? landingPages[user.roleName] : receivedUrl}
          />
        );
      } else {
        return <ComposedComponent {...props} />;
      }
    }
  };

  const mapStateToProps = (state) => {
    return {
      authenticated: state.authentication.authenticated,
      user: state.account.user,
      landingPages: state.account.landingPages,
      receivedUrl: state.authentication.receivedUrl,
    };
  };

  return connect(mapStateToProps, actions)(Authentication);
}
