import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { BASE_API_URL } from '../../../constants';

const CommunityIssueSocialMediaIcons = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    communityIssueId,
    user,
    allCommunityIssues
  } = props;
  const hashUrl = '#';

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div className='social-media-icons'>
      <a
        href={`https://twitter.com/intent/tweet?text=Chime%20in%20on%20the%20discussion%20about%20${communityIssue?.title}%20on%20CivicBell%20:&url=${BASE_API_URL}/community-issues/?id=${communityIssueId}`}
        target="_blank" 
      >
        <img
          src={require('../../../assets/img/x-twitter.svg').default}
          alt="Twitter"
        />
      </a>
      <a
        href={'https://www.instagram.com/'}
        target="_blank" 
      >
        <img
          src={require('../../../assets/img/icon-instagram.svg').default}
          alt="Instagram"
        />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_API_URL}/community-issues/?id=${communityIssueId}&quote=Chime%20in%20on%20the%20discussion%20about%20${communityIssue?.title}%20on%20CivicBell:%20`}
        target="_blank" 
      >
        <img
          src={require('../../../assets/img/icon-fb.svg').default}
          alt="Facebook"
        />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${BASE_API_URL}/community-issues/?id=${communityIssueId}`}
        target="_blank" 
      >
        <img
          src={require('../../../assets/img/linkedin.svg').default}
          alt="LinkedIn"
        />
      </a>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueSocialMediaIcons);