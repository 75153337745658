import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';

// https://www.loc.gov/standards/iso639-2/php/code_list.php
export const languageMap = {
  EN: { label: 'English', active: true, abb: 'EN' },
  ES: { label: 'Español', active: false, abb: 'ES' },
  CN: { label: '普通话', active: false, abb: 'CN' },
  TL: { label: 'Tagalog', active: false, abb: 'TL' },
  VI: { label: 'Tiếng Việt', active: false, abb: 'VI' },
  KO: { label: '한국인', active: false, abb: 'KO' },
  RU: { label: 'Русский', active: false, abb: 'RU' },
};

const LanguageSelect = (props) => {
  const {
    user,
    authenticated,
    languageChangeSubmit,
    languageChangeSubmitLocal,
  } = props;

  const [chooseLanguage, setChooseLanguage] = useState(user.selected_language);
  const history = useHistory();

  const updateLanguage = async (e) => {
    const newLanguage = e.target.value;
    setChooseLanguage(newLanguage);
    if (authenticated) {
      languageChangeSubmit(newLanguage);
    }
    else {
      languageChangeSubmitLocal(newLanguage);
    }
    localStorage.setItem('lng', newLanguage);

    // Update URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('language')) {
      urlParams.set('language', newLanguage);
      history.push(`${window.location.pathname}?${urlParams.toString()}`);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const languageParam = urlParams.get('language');
    if (languageParam && languageMap.hasOwnProperty(languageParam.toUpperCase())) {
      const language = languageMap[languageParam.toUpperCase()].abb;
      setChooseLanguage(language);
      if (authenticated) {
        languageChangeSubmit(language);
      }
      else {
        languageChangeSubmitLocal(language);
      }
      localStorage.setItem('lng', language);
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <>
      <li className="nav-item dropdown clsavatar-dropdown clslanguage-dropdown">
        <a
          className="nav-link dropdown-toggle"
          href={'#'}
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{
            width: 80,
          }}
        >
          <img
            src={require('../../../assets/img/icon-language.svg').default}
            alt="Language"
          />
          {chooseLanguage}
        </a>
        <div 
          className="dropdown-menu" 
          aria-labelledby="navbarDropdownMenuLink"
          style={{
            left: 0,
          }}
        >
          {Object.keys(languageMap)?.map((item) => (
            <div key={item} className="checkbox dropdown-item language-empty-selection">
              <input
                type="radio"
                id={languageMap[item].abb}
                name="languagename"
                value={languageMap[item].abb}
                onChange={updateLanguage}
                checked={chooseLanguage === languageMap[item].abb}
                onClick={() => {
                  i18next.changeLanguage(item);
                }}
              />
              <label htmlFor={languageMap[item].abb} className="language-selection">
                <span></span>
                <h4>
                  {languageMap[item].label}
                </h4>
              </label>
            </div>
          ))}
        </div>
      </li>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    
  };
};

export default connect(mapStateToProps, actions)(LanguageSelect);
