/**
 * Dashboard Right Container
 */

import React from 'react';
import CivicModals from '../CivicModals';
import EngagementButton from '../Engagement/engagementButton';
import PetitionButton from '../Petitions/petitionButton';
import {connect} from 'react-redux';
import actions from '../../../actions';
import StatusMessage from "../../Common/StatusMessage";
import LocalNewsWidget from "../LocalNews/LocalNewsWidget";
import LocalNewsFilterWidget from "../LocalNews/LocalNewsFilterWidget";
import MyCommunityIssues from '../CommunityIssues/MyCommunityIssues';
import CommunityIssuesYouFollow from '../CommunityIssues/CommunityIssuesYouFollow';

const RightSidebar = (props) => {
  const {
    statusMessages,
    location,
    user,
  } = props;

  const mainClassname = props.mainClassname ? props.mainClassname : 'clsdashboard-event-blk sticky-custom';

  return (
    <>
      <CivicModals {...props} />
      <div className={mainClassname}>
        {
          !location.pathname.includes('/community-issues') && <EngagementButton {...props} />
        }
        <PetitionButton {...props} />
        {typeof props.user.roleName !== 'undefined' ?
          location.pathname === '/dashboard/localnews' ? (
            <LocalNewsFilterWidget/>
          ) : (
            location.pathname.includes('/community-issues') ? (
              user.roleName === 'PUBLICOFFICIAL' ?
                <CommunityIssuesYouFollow />
              :
                <MyCommunityIssues />
            ) : (
              <LocalNewsWidget />
            )
        ) : (
          <div>
            <StatusMessage statusMessages={statusMessages}/>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    upComingEvents: state.event.upComingEvents,
    statusMessages: state.application.statusMessages,
  };
};

export default connect(mapStateToProps, actions)(RightSidebar);
