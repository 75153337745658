export const getRegistrationFormError = (field, formSubmissionErrors) => {
  if (typeof formSubmissionErrors === 'string') {
      return formSubmissionErrors.toLowerCase().includes(field) ? formSubmissionErrors : '';
  }
  if (Array.isArray(formSubmissionErrors)) {
      return formSubmissionErrors.find((error) => {
          return error.toLowerCase().includes(field);
      });
  }

  return '';
};

export const removeSubmissionError = (field, formSubmissionErrors) => {
  if (typeof formSubmissionErrors === 'string') {
    return formSubmissionErrors.toLowerCase().includes(field) ? formSubmissionErrors : '';
  }

  if (Array.isArray(formSubmissionErrors)) {
    return formSubmissionErrors.filter((error) => {
        return !error.toLowerCase().includes(field);
    });
  }

  return '';
}