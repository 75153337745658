/*
 *
 * Events reducer
 *
 */

import {
  SET_EVENT_TAB,
  SET_MYEVENT_TAB,
  SET_EVENTS_LOADING,
  SET_OPTION_LOADING,
  FETCH_CIVIC_EVENTS,
  FETCH_MYCIVIC_EVENTS,
  FETCH_UPCOMING_EVENTS,
  EVENTS_RELOAD_LIST,
  EVENTS_CHANGE,
  EVENTS_RESET,
  EVENTS_LIST_CHANGE,
  EVENT_FORM_CHANGE,
  SET_EVENT_FORM_ERRORS,
  SET_EVENT_SUBMITTING,
  SET_EVENT_FORM_RESET,
  SET_EVENT_SINGLE,
  RESET_EVENT_SINGLE,
  EVENT_SPECIFIC_DATA,
  UPDATE_EVENT_SINGLE,
  RESET_APP,
} from './constants';

const initialState = {
  activeEventTab: 'all-events',
  activeMyEventTab: 'upcoming-events',
  events: {},
  eventsLength: 0,
  myEvents: [],
  upComingEvents: [],
  confirmPopup: null,
  editModalTriger: false,
  reloadList: false,
  isEventLoading: false,
  isOptionLoading: false,
  noRecords: false,
  pageLoader: false,
  filterDismissal: false,
  dismissal: false,
  filter: {
    states: [],
    counties: [],
    officials: [],
  },
  filterSelected: {
    state: '',
    county: '',
    officials: [],
    level: [],
  },
  calendar: [],
  calendarSelected: [],
  searchBox: {
    key: '',
  },
  eventFormData: {
    event_title: 'New Event',
    title: '',
    organizer: '',
    isRecurring: 0,
    startDate: '',
    startTime: '',
    recurringType: '',
    endDate: '',
    endTime: '',
    location: '',
    coordinates: null,
    description: '',
    images: [],
    removeImages: [],
    files: [],
    links: [],
  },
  isEventSubmitting: false,
  eventFormErrors: [],
  eventSingle: {},
};

const defaultState = JSON.stringify(initialState);

const EventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_EVENT_TAB:
      return {
        ...state,
        activeEventTab: action.payload,
      };
    case SET_MYEVENT_TAB:
      return {
        ...state,
        activeMyEventTab: action.payload,
      };
    case FETCH_CIVIC_EVENTS:
      var events = state.events;
      var { month, eventlist } = action.payload;
      if (month in events)
        events[month] = [
          ...new Map(
            [...events[month], ...eventlist].map((item) => [item['id'], item]),
          ).values(),
        ];
      else events[month] = eventlist;

      return {
        ...state,
        events: events,
      };
    case FETCH_MYCIVIC_EVENTS:
      return {
        ...state,
        myEvents: [...state.myEvents, ...action.payload],
      };
    case FETCH_UPCOMING_EVENTS:
      return {
        ...state,
        upComingEvents: action.payload,
      };
    case EVENTS_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case EVENTS_RELOAD_LIST:
      return {
        ...state,
        events: {},
        myEvents: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
      };
    case EVENTS_RESET:
      return {
        ...state,
        events: {},
        myEvents: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
        filterDismissal: false,
      };
    case EVENTS_LIST_CHANGE:
      month = action.payload.month;
      var id = action.payload.id;
      var eventsList = state.events;
      let list = state.events[month];
      var index;
      eventsList[month].find((item, i) => {
        index = i;
        return item.id === id;
      });
      list[index] = { ...list[index], ...action.payload.values };

      eventsList[month] = list;

      return {
        ...state,
        events: eventsList,
      };
    case SET_EVENTS_LOADING:
      return {
        ...state,
        isEventLoading: action.payload,
      };
    case SET_OPTION_LOADING:
      return {
        ...state,
        isOptionLoading: action.payload,
      };
    case EVENT_FORM_CHANGE:
      return {
        ...state,
        eventFormData: { ...state.eventFormData, ...action.payload },
      };

    case SET_EVENT_SUBMITTING:
      return {
        ...state,
        isEventSubmitting: action.payload,
      };
    case SET_EVENT_FORM_ERRORS:
      return {
        ...state,
        eventFormErrors: action.payload,
      };
    case EVENT_SPECIFIC_DATA:
      return {
        ...state,
        eventFormData: action.payload,
      };
    case SET_EVENT_FORM_RESET:
      return {
        ...state,
        eventFormData: {
          event_title: 'New Event',
          title: '',
          organizer: '',
          isRecurring: 0,
          startDate: '',
          startTime: '',
          recurringType: '',
          endDate: '',
          endTime: '',
          location: '',
          description: '',
          images: [],
          removeImages: [],
          files: [],
          links: [],
        },
      };
    case SET_EVENT_SINGLE:
      return {
        ...state,
        eventSingle: action.payload,
      };
    case UPDATE_EVENT_SINGLE:
      return {
        ...state,
        eventSingle: { ...state.eventSingle, ...action.payload },
      };
    case RESET_EVENT_SINGLE:
      return {
        ...state,
        eventSingle: {},
      };
    default:
      return state;
  }
};

export default EventsReducer;
