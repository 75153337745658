/*
 *
 * Onboarding Steps
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const Onboarding = (props) => {
  const { registerFormData, setNextStep } = props;
  const { t } = useTranslation();
  const hashUrl = '#';

  const getId = () => {
    switch (registerFormData.step) {
      case 3:
        return 'regevent_whatis_p1';
      case 4:
        return 'regevent_whatis_p2';
      case 5:
        return 'regevent_whatis_p3';
      default:
        return 'regevent_continue';
    }
  }

  return (
    <div className="clsonboarding-blk clswhite-box mt-3 pt-4 mb-3">
      {registerFormData.step === 3 ? (
        <figure className="text-center">
          <img
            src={require('../../../assets/img/step1.svg').default}
            alt="onboarding1"
            className="img-fluid"
          />
        </figure>
      ) : registerFormData.step === 4 ? (
        <figure className="text-center">
          <img
            src={require('../../../assets/img/step2.svg').default}
            alt="onboarding2"
            className="img-fluid"
          />
        </figure>
      ) : registerFormData.step === 5 ? (
        <figure className="text-center">
          <img
            src={require('../../../assets/img/step3.svg').default}
            alt="onboarding3"
            className="img-fluid"
          />
        </figure>
      ) : (
        ''
      )}
      <div className="clsonboarding-content pt-3">
        {registerFormData.step === 3 ? (
          <>
            <h4>{t(`Real officials and topics from your community.`)}</h4>
            <p className="mt-2">
              {t(
                `Participate in discussions and polls with your public officials.`,
              )}
            </p>
          </>
        ) : registerFormData.step === 4 ? (
          <>
            <h4>
              {t(
                `Follow public officials and topics outside your communities.`,
              )}
            </h4>
            <p className="mt-2">
              {t(
                `You can only engage with public officials from your community, but still follow and see all activities from any public official on CivicBell.`,
              )}
            </p>
          </>
        ) : registerFormData.step === 5 ? (
          <>
            <h4>{t(`Stay informed and engaged in your local community.`)}</h4>
            <p className="mt-2">
              {t(
                `CivicBell simplifies access to local events, news, and petitions.`,
              )}
            </p>
          </>
        ) : (
          ''
        )}
        {registerFormData.step === 3 ||
        registerFormData.step === 4 ||
        registerFormData.step === 5 ? (
          <>
            <div className="mt-4">
              <a
                href={hashUrl}
                onClick={(e) => {
                  setNextStep();
                  e.preventDefault();
                }}
                className="btn clsborder-btn"
                id={getId()}
              >
                {registerFormData.step === 5
                  ? t(`Complete Profile`)
                  : t(`Next`)}
              </a>
            </div>
            <div className="clssteps-indicator text-center mt-2 pt-1">
              <ul>
                <li
                  className={
                    registerFormData.step >= 3 && registerFormData.step <= 5
                      ? `clsactive`
                      : ``
                  }
                ></li>
                <li
                  className={
                    registerFormData.step >= 4 && registerFormData.step <= 5
                      ? `clsactive`
                      : ``
                  }
                ></li>
                <li
                  className={registerFormData.step === 5 ? `clsactive` : ``}
                ></li>
              </ul>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Onboarding;
