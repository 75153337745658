/**
 *
 * CiivcBell Tags
 *
 */

import React, { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import '../../../assets/styles/tags.css';
import SuggestionTags from './suggestionTags';
import debounce from 'lodash.debounce';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const suggestData = debounce((value, func) => {
  func(value);
}, 500);

const KeyCodes = {
  enter: 13,
  tab: 9,
  comma: 188,
  space: 32,
};

const delimiters = [
  KeyCodes.enter,
  KeyCodes.tab,
  KeyCodes.comma,
  KeyCodes.space,
];

const CivicTags = (props) => {
  const { action, tagValues, id, suggestOption, suggestAction } = props;

  const [tags, setTags] = useState(tagValues);

  const [displayStatus, setDisplayStatus] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [suggestionData, setSuggestionData] = useState([]);

  /*const onTagChange = useCallback(() => {
        registerChange('ocdId', '')
        if(registerFormData.district.length > 0) {
            districtBoxChange('display', true);
            suggestData(getDistrictData)
        } 
    }, [registerFormData, getDistrictData, districtBoxChange, registerChange]);*/

  useEffect(() => {
    setTags(tagValues);
  }, [setTags, tagValues]);

  useEffect(() => {
    action('tags', tags);
  }, [action, tags]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (!tag.text.startsWith('#')) {
      tag.text = '#' + tag.text;
    }
    setTags([...tags, tag]);
    setDisplayStatus(false);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {};

  const setTagFocus = () => {
    document.getElementById(id).focus();
  };

  const loadSuggestion = (value) => {
    setDisplayStatus(true);
    if (typeof suggestAction === 'function') {
      setLoadingStatus(true);
      suggestAction('topics', value).then((response) => {
        setSuggestionData(response);
        setLoadingStatus(false);
      });
    }
  };

  const handleInputChange = (value) => {
    $('#' + id).attr('autocomplete', 'off');
    suggestData(value, loadSuggestion);
    /*if(value.length > 2) {
        suggestData(value, loadSuggestion);
        } else {
            setDisplayStatus(false);
        }*/
  };

  const handleInputFocus = () => {
    $('#' + id).attr('autocomplete', 'off');
  };

  const hideSuggestion = () => {
    setDisplayStatus(false);
  };

  if (document.getElementById('tag-suggestion-box')) {
    document.addEventListener('click', (event) => {
      if (
        event.target.id !== id &&
        event.target.id !== 'tag-suggestion-box' &&
        event.target.className !== 'suggest-item'
      ) {
        setDisplayStatus(false);
      }
    });
  }

  const addTag = (item) => {
    document.getElementById(id).value = '';
    setTags([...tags, item]);
    setDisplayStatus(false);
  };

  $('.ReactTags__tags').on('click', function (event) {
    $('.ReactTags__tagInputField').focus();
  });

  const { t } = useTranslation();

  return (
    <>
      <div className="form-control tag-element" onClick={setTagFocus}>
        <ReactTags
          id={id}
          tags={tags}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="inline"
          placeholder={t(`Enter hashtags here`)}
          onClick={setTagFocus}
          handleInputChange={handleInputChange}
          handleInputFocus={handleInputFocus}
          autocomplete={false}
        />
      </div>
      {suggestOption === true ? (
        <SuggestionTags
          displayStatus={displayStatus}
          loadingStatus={loadingStatus}
          suggestionData={suggestionData}
          hideSuggestion={hideSuggestion}
          addTag={addTag}
          {...props}
        />
      ) : (
        ''
      )}
    </>
  );
};

CivicTags.defaultProps = {
  suggestOption: false,
};

export default CivicTags;
