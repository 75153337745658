/*
 *
 * Events actions
 *
 */

import {
  SET_EVENT_TAB,
  SET_MYEVENT_TAB,
  EVENTS_CHANGE,
  SET_EVENTS_LOADING,
  SET_OPTION_LOADING,
  FETCH_CIVIC_EVENTS,
  FETCH_MYCIVIC_EVENTS,
  FETCH_UPCOMING_EVENTS,
  EVENTS_RELOAD_LIST,
  EVENTS_LIST_CHANGE,
  SET_EVENT_FORM_ERRORS,
  SET_EVENT_FORM_RESET,
  SET_EVENT_SUBMITTING,
  EVENT_FORM_CHANGE,
  SET_EVENT_SINGLE,
  RESET_EVENT_SINGLE,
  EVENT_SPECIFIC_DATA,
  UPDATE_EVENT_SINGLE,
  RESET_APP,
} from './constants';
import { BASE_API_URL } from '../../constants';
import { allFieldsValidation } from '../../utils/validation';
import axios from 'axios';
import { getEventNextDate, isImageLink } from "../../utils/helper";
import { addNotification } from '../Notification/actions';
import moment from 'moment-timezone';

export const resetEventState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const eventFormChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: EVENT_FORM_CHANGE, payload: formData });
  };
};

export const eventChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: EVENTS_CHANGE, payload: formData });
  };
};

export const eventChangeByOption = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData[name] = value;
    return dispatch({ type: EVENTS_CHANGE, payload: formData });
  };
};

export const getCivicEvents = (page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let filterSelected = getState().event.filterSelected;
      let calendarSelected = getState().event.calendarSelected;
      let eventsLength = getState().event.eventsLength;
      var noRecords;
      let apiUri = '/users/allEvents?page=' + page;

      apiUri += '&from_date=' + moment.utc().format('YYYY-MM-DD HH:mm:ss');

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }

      if (filterSelected['state'].length > 0) {
        apiUri += '&state=' + filterSelected['state'];
      }

      if (filterSelected['county'].length > 0) {
        apiUri += '&county=' + filterSelected['county'];
      }

      if (filterSelected['officials'].length > 0) {
        apiUri += '&officials=' + JSON.stringify(filterSelected['officials']);
      }

      if (calendarSelected.length > 0) {
        apiUri += '&calender=' + JSON.stringify(calendarSelected);
      }

      if (page === 1) {
        formData['events'] = {};
        dispatch(eventChange(formData));
      }
      dispatch({ type: SET_EVENTS_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          let monthlyEventsData = [];
          if (response.data.success === 'true') {
            monthlyEventsData = response.data.data.reduce((p, c) => {
              let month = moment(
                moment.utc(c.next_date, 'YYYYMMDD').tz(moment.tz.guess()),
              ).format('YYYYMM01');
              if (!p.hasOwnProperty(month)) {
                p[month] = [];
              }
              p[month].push(c);
              return p;
            }, {});
            Object.keys(monthlyEventsData).forEach((monthKey, i) => {
              dispatch({
                type: FETCH_CIVIC_EVENTS,
                payload: {
                  month: monthKey,
                  eventlist: monthlyEventsData[monthKey],
                },
              });
            });

            formData = {};
            formData['noRecords'] = noRecords =
              response.data.data.length === 0 ? true : false;
            formData['eventsLength'] = eventsLength + response.data.data.length;
            formData['reloadList'] = false;
            dispatch(eventChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'events',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_EVENTS_LOADING, payload: false });
        });
      return resolve(noRecords);
    });
  };
};

export const getMyCivicEvents = (page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let user = getState().account.user;
      let activeMyEventTab = getState().event.activeMyEventTab;
      let searchKey = getState().search.searchKey;
      let filterSelected = getState().event.filterSelected;
      let apiKey = user.roleName === 'PUBLICOFFICIAL' ? 'official' : 'users';
      let apiUri = `/${apiKey}/myevent?month=upcoming&page=${page}`;
      if (activeMyEventTab === 'past-events') {
        apiUri = `/${apiKey}/myevent?month=past&page=${page}`;
      }

      apiUri += '&from_date=' + moment.utc().format('YYYY-MM-DD HH:mm:ss');

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }

      if (filterSelected['state'].length > 0) {
        apiUri += '&state=' + filterSelected['state'];
      }

      if (filterSelected['county'].length > 0) {
        apiUri += '&county=' + filterSelected['county'];
      }

      if (filterSelected['officials'].length > 0) {
        apiUri += '&officials=' + JSON.stringify(filterSelected['officials']);
      }

      if (page === 1) {
        formData['myEvents'] = [];
        dispatch(eventChange(formData));
      }
      dispatch({ type: SET_EVENTS_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().event.myEvents.length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_MYCIVIC_EVENTS,
                  payload: response.data.data,
                });
              }
            } else if (page === 1 && getState().event.myEvents.length === 0) {
              dispatch({
                type: FETCH_MYCIVIC_EVENTS,
                payload: response.data.data,
              });
            }

            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            formData['reloadList'] = false;
            dispatch(eventChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'event',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_EVENTS_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const getUpcomingEvents = (page = 1) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let apiUri = '/users/allEvents?page=' + page;

      apiUri += '&from_date=' + moment.utc().format('YYYY-MM-DD HH:mm:ss');

      if (page === 1) {
        formData['upComingEvents'] = [];
        dispatch(eventChange(formData));
      }
      dispatch({ type: SET_EVENTS_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({
              type: FETCH_UPCOMING_EVENTS,
              payload: response.data.data,
            });

            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            formData['reloadList'] = false;
            dispatch(eventChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'event',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_EVENTS_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const reloadCivicEvent = () => {
  return async (dispatch, getState) => {
    dispatch({ type: EVENTS_RELOAD_LIST });
  };
};

export const resetEventForm = () => {
  return async (dispatch, getState) => {
    return dispatch({
      type: SET_EVENT_FORM_RESET,
    });
  };
};

export const setEventTab = (status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_EVENT_TAB, payload: status });
  };
};

export const setMyEventTab = (status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_MYEVENT_TAB, payload: status });
  };
};

export const getStateCodebyName = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let user = getState().account.user;
      if (user.state)
        await axios
          .get(BASE_API_URL + '/users/getStateCodebyName?state=' + user.state)
          .then((response) => {
            if (response.data.success === 'true') {
              setTimeout(() => {
                dispatch(
                  eventFilterChange('state', response.data.data.stateCode),
                );
              }, 0);
              return resolve(true);
            }
          })
          .catch((error) => {
            return resolve(false);
          });
    });
  };
};

export const getFilterStates = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(BASE_API_URL + '/users/getStates')
        .then((response) => {
          if (response.data.success === 'true') {
            let filter = getState().event.filter;
            if (response.data.data.length > 0)
              filter['states'] = response.data.data.map((state, index) => ({
                value: state.stateId,
                label: state.name,
              }));
            let formData = {};
            formData['filter'] = filter;
            dispatch(eventChange(formData));
            dispatch(getStateCodebyName());
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const eventCalendarChange = (calendarId) => {
  return async (dispatch, getState) => {
    let calendarSelected = getState().event.calendarSelected;
    let indexOf = calendarSelected.indexOf(calendarId);
    if (indexOf > -1) calendarSelected.splice(indexOf, 1);
    else calendarSelected.push(calendarId);

    let formData = {};
    formData['calendarSelected'] = calendarSelected;
    return dispatch(eventChange(formData));
  };
};

export const getExploreCalendars = (keyword = '') => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_OPTION_LOADING, payload: true });
      var requestURI = BASE_API_URL + '/users/explorecalendar';
      if (keyword !== '')
        requestURI = BASE_API_URL + '/users/explorecalendar?keyword=' + keyword;
      await axios
        .get(requestURI)
        .then((response) => {
          if (response.data.success === 'true') {
            let formData = {};
            formData['calendar'] = response.data.data;
            dispatch(eventChange(formData));
            dispatch({ type: SET_OPTION_LOADING, payload: false });
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const getFilterCounties = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let filteredState = getState().event.filterSelected.state;
      await axios
        .get(BASE_API_URL + '/users/getCounties?stateId=' + filteredState)
        .then((response) => {
          if (response.data.success === 'true') {
            let filter = getState().event.filter;
            if (response.data.data.length > 0)
              filter['counties'] = response.data.data.map((county, index) => ({
                value: county.countyId,
                label: county.name,
              }));
            let formData = {};
            formData['filter'] = filter;
            dispatch(eventChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};
export const getFilterOfficials = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let filteredState = getState().event.filterSelected.state;
      let filteredCounty = getState().event.filterSelected.county;
      if (filteredState && filteredCounty)
        await axios
          .get(
            BASE_API_URL +
              '/users/eventFilter?stateId=' +
              filteredState +
              '&countyId=' +
              filteredCounty,
          )
          .then((response) => {
            if (response.data.success === 'true') {
              let filter = getState().event.filter;
              filter['officials'] = response.data.data;
              let formData = {};
              formData['filter'] = filter;
              dispatch(eventChange(formData));
              return resolve(true);
            }
          })
          .catch((error) => {
            return resolve(false);
          });
      else return resolve(false);
    });
  };
};

export const resetCalendar = () => {
  return async (dispatch, getState) => {
    let formData = {};
    formData['calendarSelected'] = [];
    return dispatch(eventChange(formData));
  };
};

export const resetEventFilter = () => {
  return async (dispatch, getState) => {
    let filter = getState().event.filter;
    let user = getState().account.user;
    let filterSelected = getState().event.filterSelected;
    if (!user.state) {
      filterSelected['state'] = '';
      filter['counties'] = [];
    }
    filter['officials'] = [];
    filterSelected['county'] = '';
    filterSelected['officials'] = [];
    filterSelected['level'] = [];
    let formData = {};
    formData['filter'] = filter;
    formData['filterSelected'] = filterSelected;
    formData['filterDismissal'] = true;
    dispatch(eventChange(formData));
    dispatch(reloadCivicEvent());
  };
};

export const eventFilterChange = (type, value) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().event.filterSelected;
    if (type === 'officials') {
      if (filterSelected[type].indexOf(value) !== -1) {
        const index = filterSelected[type].indexOf(value);
        if (index > -1) {
          filterSelected[type].splice(index, 1);
        }
      } else {
        filterSelected[type].push(value);
      }
    } else {
      filterSelected[type] = value;
    }
    let formData = {};
    formData['filterSelected'] = { ...filterSelected };
    return dispatch(eventChange(formData));
  };
};

const level2OfficialPush = (
  filterSelected,
  officials,
  level,
  pushAll = null,
) => {
  let selectedOfficials = filterSelected.officials;

  if (filterSelected['level'].indexOf(level) !== -1 && !pushAll) {
    let index = filterSelected['level'].indexOf(level);
    if (index > -1) {
      filterSelected['level'].splice(index, 1);
      officials.forEach((item) => {
        let userIndex = selectedOfficials.indexOf(item.officialUser.userId);
        if (userIndex > -1) selectedOfficials.splice(userIndex, 1);
      });
      filterSelected.officials = [...new Set(selectedOfficials)];
    }
  } else if (pushAll === false) {
    /*Do Nothing*/
  } else {
    filterSelected['level'].push(level);
    if (officials.length) {
      let level2Offcials = officials.map((item) => {
        return item.officialUser.userId;
      });
      selectedOfficials = selectedOfficials.concat(level2Offcials);
      filterSelected.officials = [...new Set(selectedOfficials)];
    }
  }
  return filterSelected;
};

export const level2OfficialSelectAll = (officials, level) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().event.filterSelected;
    filterSelected = level2OfficialPush(filterSelected, officials, level);
    let formData = {};
    formData['filterSelected'] = { ...filterSelected };
    return dispatch(eventChange(formData));
  };
};

export const level1OfficialSelectAll = (level1Items, level) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().event.filterSelected;
    if (filterSelected['level'].indexOf(level) !== -1) {
      const index = filterSelected['level'].indexOf(level);
      if (index > -1) {
        filterSelected['level'].splice(index, 1);

        Object.keys(level1Items).forEach((level1Item, i) => {
          filterSelected = level2OfficialPush(
            filterSelected,
            level1Items[level1Item],
            `${level}-${i}`,
            false,
          );
        });
      }
    } else {
      filterSelected['level'].push(level);
      if (Object.keys(level1Items).length) {
        Object.keys(level1Items).forEach((level1Item, i) => {
          filterSelected = level2OfficialPush(
            filterSelected,
            level1Items[level1Item],
            `${level}-${i}`,
            true,
          );
        });
      }
    }
    filterSelected.level = [...new Set(filterSelected.level)];
    let formData = {};
    formData['filterSelected'] = { ...filterSelected };
    return dispatch(eventChange(formData));
  };
};

export const searchBoxEventChange = (value) => {
  return async (dispatch, getState) => {
    let searchBox = getState().event.searchBox;
    searchBox['key'] = value;
    let formData = {};
    formData['searchBox'] = { ...searchBox };
    dispatch(eventChange(formData));
  };
};

export const submitEventFilter = () => {
  return async (dispatch, getState) => {
    let formData = {};
    formData['filterDismissal'] = true;
    dispatch(eventChange(formData));
    dispatch(reloadCivicEvent());
  };
};

export const eventFormImageChange = (name, value) => {
  return async (dispatch, getState) => {
    let eventFormData = getState().event.eventFormData;
    if (eventFormData.images.length > 0)
    eventFormData['removeImages'] = isImageLink(eventFormData.images);
    eventFormData['files'] = [];
    eventFormData['images'] = [];

    let formData = {};
    formData['eventFormData'] = eventFormData;
    dispatch(eventChange(formData));
    dispatch(eventFormChange(name, value));
  };
};

export const removeEventImage = () => {
  return async (dispatch, getState) => {
    let eventFormData = getState().event.eventFormData;
    if (eventFormData.images.length > 0)
    eventFormData['removeImages'] = isImageLink(eventFormData.images);
    eventFormData['files'] = [];
    eventFormData['images'] = [];

    let formData = {};
    formData['eventFormData'] = eventFormData;
    dispatch(eventChange(formData));
    dispatch(eventFormChange());
  };
};

export const addEventImage = (file) => {
  return async (dispatch, getState) => {
    file.preview = URL.createObjectURL(file);
    let fileInput = [file];
    dispatch(eventFormChange('files', fileInput));
  };
};

export const validateEventForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let event = getState().event.eventFormData;
      let errorList = {};

      let eventData = {
        title: 'required',
        startDate: 'required',
        startTime: 'required',
        endDate: [{ required_if: ['isRecurring', true] }],
        recurringType: [{ required_if: ['isRecurring', true] }],
        location: 'required',
        description: 'required',
      };
      const { isValid, errors } = allFieldsValidation(event, eventData, {
        'required.title': 'Required',
        'required.startDate': 'Required',
        'required_if.endDate': 'Required',
        'required_if.startTime': 'Required',
        'required_if.endTime': 'Required',
        'required_if.recurringType': 'Required',
        'required.location': 'Required',
        'required.description': 'Required',
      });

      errorList = { ...errorList, ...errors };

      if (!isValid) {
        dispatch({ type: SET_EVENT_FORM_ERRORS, payload: errorList });
        reject(errorList);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitEventForm = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_EVENTS_LOADING, payload: true });
    dispatch({ type: SET_EVENT_SUBMITTING, payload: true });
    dispatch(validateEventForm())
      .then(async () => {
        dispatch({ type: SET_EVENT_FORM_ERRORS, payload: {} });
        dispatch(eventSubmit());
      })
      .catch((error) => {
        dispatch({ type: SET_EVENT_SUBMITTING, payload: false });
        dispatch({ type: SET_EVENTS_LOADING, payload: false });
      });
  };
};

/*export const converToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    console.log("base64 file");
    console.log(file);
    fileReader.readAsDataURL(file);
    console.log(fileReader.result);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};*/

export const eventSubmit = () => {
  return async (dispatch, getState) => {
    //await dispatch(uploadEventToS3());
    //await dispatch(removeEventsFromS3());
    let event = getState().event.eventFormData;
    let activeEventTab = getState().event.activeEventTab;
    let activeMyEventTab = getState().event.activeMyEventTab;
    let startDate = moment(
      moment(event.startDate).format('YYYY-MM-DD') +
        moment(new Date()).format(' HH:mm:ss'),
      'YYYY-MM-DD HH:mm:ss',
    ).toDate();
    let endDate =
      event.endDate !== ''
        ? moment(
            moment(event.endDate).format('YYYY-MM-DD') +
              moment(new Date()).format(' HH:mm:ss'),
            'YYYY-MM-DD HH:mm:ss',
          ).toDate()
        : '';
    //let base64Image = await converToBase64(event.files[0]);
    // let eventFiles = event.files;

    /*let eventInfo = {
      title: event.title,
      organizer: event.organizer,
      recurring_status: event.isRecurring,
      recurring_type: event.recurringType,
      event_start_date:
        moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss") + "+00",
      event_start_time:
        moment.utc(event.startTime).format("YYYY-MM-DD HH:mm:ss") + "+00",
      event_end_date: endDate
        ? moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss") + "+00"
        : "",
      event_end_time: event.endTime
        ? moment.utc(event.endTime).format("YYYY-MM-DD HH:mm:ss") + "+00"
        : "",
      coordinates: JSON.stringify(event.coordinates),
      location: event.location,
      description: event.description,
      files: eventFiles,
      images: event.images.length > 0 ? event.images : event.links,
    };*/
    const eventInfo = new FormData();
    eventInfo.append('title', event.title);
    eventInfo.append('organizer', event.organizer);
    eventInfo.append('recurring_status', event.isRecurring);
    eventInfo.append(
      'event_start_date',
      moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss') + '+00',
    );
    eventInfo.append(
      'event_start_time',
      moment.utc(event.startTime).format('YYYY-MM-DD HH:mm:ss') + '+00',
    );
    eventInfo.append(
      'event_end_date',
      endDate ? moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss') + '+00' : '',
    );
    eventInfo.append(
      'event_end_time',
      event.endTime
        ? moment.utc(event.endTime).format('YYYY-MM-DD HH:mm:ss') + '+00'
        : '',
    );
    eventInfo.append('coordinates', JSON.stringify(event.coordinates));
    eventInfo.append('location', event.location);
    eventInfo.append('description', event.description);
    event.files.forEach((file, i) => {
      eventInfo.append('files', file, file.name);
    });
    //eventInfo.append('files', event.files);
    eventInfo.append(
      'images',
      event.images.length > 0 ? event.images : event.links,
    );

    eventInfo.append(
      'removeImages',
      event.removeImages.length > 0 ? event.removeImages : null,
    );

    dispatch({ type: SET_EVENTS_LOADING, payload: true });
    if (typeof event.id !== 'undefined') {
      //Edit Event
      await axios
        .put(BASE_API_URL + '/official/update-event/' + event.id, eventInfo, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
          dispatch(eventFormChange('files', []));
          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'event',
            }),
          );
          let formData = {};
          formData['filterDismissal'] = true;
          formData['reloadList'] = true;
          dispatch({ type: EVENTS_CHANGE, payload: formData });
          dispatch(resetEventForm());
          if (activeEventTab === 'my-events') dispatch(getMyCivicEvents());
          else dispatch(getCivicEvents());
        })
        .catch((error) => {
          let errorList = error.response.data.error;
          if (typeof errorList === 'string') errorList = [errorList];
          if (Array.isArray(errorList)) {
            errorList.forEach((error, index) => {
              dispatch(
                addNotification({
                  type: 'error',
                  message: errorList[index],
                  module: 'event',
                }),
              );
            });
          }
        })
        .finally(() => {
          dispatch({ type: SET_EVENT_SUBMITTING, payload: false });
          dispatch({ type: SET_EVENTS_LOADING, payload: false });
        });
    } else {
      await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
          'user-token': localStorage.getItem('token'),
        },
        url: BASE_API_URL + '/official/new-event',
        data: eventInfo,
      })
        .then((response) => {
          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'event',
            }),
          );
          let formData = {};
          formData['filterDismissal'] = true;
          dispatch({ type: EVENTS_CHANGE, payload: formData });
          dispatch(resetEventForm());
          if (activeMyEventTab === 'past-events')
            dispatch(setMyEventTab('upcoming-events'));
          if (activeEventTab === 'my-events') dispatch(getMyCivicEvents());
          else dispatch(getCivicEvents());
        })
        .catch((error) => {
          let errorList = error.response.data.error;
          if (typeof errorList === 'string') errorList = [errorList];
          if (Array.isArray(errorList)) {
            errorList.forEach((error, index) => {
              dispatch(
                addNotification({
                  type: 'error',
                  message: errorList[index],
                  module: 'event',
                }),
              );
            });
          }
        })
        .finally(() => {
          dispatch({ type: SET_EVENT_SUBMITTING, payload: false });
          dispatch({ type: SET_EVENTS_LOADING, payload: false });
        });
    }
  };
};

export const uploadEventToS3 = () => {
  return async (dispatch, getState) => {
    let files = getState().event.eventFormData.files;
    //let newFiles = [];
    if (files.length > 0) {
      try {
        let error = false;
        let errorList = getState().event.eventFormErrors;
        let links = getState().event.eventFormData.links;
        if (!error) {
          //dispatch(eventChange('files', []));
          if (errorList.hasOwnProperty('files') === true) {
            delete errorList['files'];
            dispatch({ type: SET_EVENT_FORM_ERRORS, payload: errorList });
          }
        } else {
          errorList = {
            ...errorList,
            files: [
              `Error on uploading files. Please remove it and upload it again.`,
            ],
          };
          dispatch({ type: SET_EVENT_FORM_ERRORS, payload: errorList });
        }
        //newFiles = files.filter(file => file !== null);

        dispatch(eventFormChange('links', links));
        return dispatch(eventFormChange('files', files));
      } catch (e) {
        console.log(e);
        return;
      }
    }
  };
};

export const toggleConfirmPopup = (event, month, action) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      var formData = {};
      formData['confirmPopup'] = { event: event, month: month, action };
      dispatch(eventChange(formData));
      return resolve(true);
    });
  };
};

export const setEventData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: RESET_EVENT_SINGLE });
      await axios
        .get(BASE_API_URL + '/users/events/' + id)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({ type: SET_EVENT_SINGLE, payload: response.data.data });
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'event',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const joinEvent = (event, month = false) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let activeEventTab = getState().event.activeEventTab;
      let activeMyEventTab = getState().event.activeMyEventTab;
      dispatch({ type: SET_EVENTS_LOADING, payload: true });
      await axios
        .post(BASE_API_URL + '/users/joinEvent/' + event.id, {
          id: event.id,
          next_date: getEventNextDate(event),
          start_time: moment
            .utc(`${event.start_time}`)
            .tz(moment.tz.guess())
            .format('HH:mm:ss'),
        })
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'event',
              }),
            );
            if (!month) {
              let event = getState().event.eventSingle;
              if (
                activeEventTab === 'my-events' &&
                activeMyEventTab === 'upcoming-events'
              ) {
                dispatch(getMyCivicEvents());
              } else {
                let formData = {};
                formData['joined'] = !event.joined;
                formData['attendeesCount'] =
                  !event.joined === true
                    ? parseInt(event.attendeesCount) + 1
                    : parseInt(event.attendeesCount) - 1;
                dispatch({ type: UPDATE_EVENT_SINGLE, payload: formData });
              }
            } else {
              let events = getState().event.events;
              let eventState = events[month].find((item) => {
                return item.id === event.id;
              });
              let formData = {
                month: month,
                id: event.id,
                values: { joined: !eventState.joined },
              };
              formData['attendeesCount'] =
                !eventState.joined === true
                  ? parseInt(eventState.attendeesCount) + 1
                  : parseInt(eventState.attendeesCount) - 1;
              dispatch({ type: EVENTS_LIST_CHANGE, payload: formData });
            }
            dispatch(eventChange({ confirmPopup: null }));
            dispatch({ type: SET_EVENTS_LOADING, payload: false });

            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch({ type: SET_EVENTS_LOADING, payload: false });
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'event',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const deleteEvent = (event, month = false) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_EVENTS_LOADING, payload: true });
      await axios
        .delete(BASE_API_URL + '/official/deleteEvent/' + event.id)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'event',
              }),
            );
            if (!month) {
              let events = getState().event.myEvents;
              let eventIndex = events.findIndex((item) => {
                return item.id === event.id;
              });
              if (eventIndex > -1) events.splice(eventIndex, 1);
              dispatch(eventChange({ myEvents: events }));
            } else {
              let events = getState().event.events;
              let eventIndex = events[month].findIndex((item) => {
                return item.id === event.id;
              });
              if (eventIndex > -1) events[month].splice(eventIndex, 1);
              dispatch(eventChange({ events: events }));
            }
            dispatch(eventChange({ confirmPopup: null }));
            dispatch({ type: SET_EVENTS_LOADING, payload: false });

            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch({ type: SET_EVENTS_LOADING, payload: false });
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'event',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const updateEventSingle = (event) => {
  return async (dispatch, getState) => {
    dispatch(resetEventForm());
    dispatch(eventChangeByOption('filterDismissal', false));
    dispatch(eventChangeByOption('editModalTriger', true));
    let formData = {
      event_title: 'Edit Event',
      id: event.id,
      title: event.name,
      organizer: event.organizer,
      isRecurring: event.recurring_status,
      startDate: moment.utc(event.start_date).tz(moment.tz.guess()).toDate(),
      startTime: moment.utc(event.start_time).tz(moment.tz.guess()).toDate(),
      recurringType: event.repetition_type,
      endDate: moment.utc(event.end_date).tz(moment.tz.guess()).toDate(),
      endTime: moment.utc(event.end_time).tz(moment.tz.guess()).toDate(),
      location: event.location,
      coordinates: JSON.parse(event.coordinates),
      description: event.description,
      images: event.images ? event.images : [],
      files: [],
      removeImages: [],
      links: [],
    };
    dispatch({ type: EVENT_SPECIFIC_DATA, payload: formData });
  };
};
