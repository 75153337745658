/**
 *
 * Individual Petition
 *
 */

import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import { profileImg } from '../../../utils/helper';
import CivicModal from '../../Common/CivicModal';
import EligiblePetition from './eligiblePetition';
import ShareLink from '../../Common/ShareLink';
import { useTranslation } from 'react-i18next';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import { isJson, isImageLink } from '../../../utils/helper';
import LinkedNotification from '../../Common/ShareLink/linkedInNotification';

const IndividualPetition = (props) => {
  const { petitionSingle, filterDismissal, user } = props;
  const shareUrl = window.location.href;
  const title = petitionSingle.name;

  const hashUrl = '#';
  const history = useHistory();
  const { t } = useTranslation();
  const navigateTo = (url) => {
    history.push(url);
  };

  let petitionImage = '';
  if (
    petitionSingle.cover_images !== 'undefined' &&
    petitionSingle.cover_images !== null &&
    petitionSingle.cover_images.length > 0
  ) {
    petitionImage = isImageLink(petitionSingle.cover_images[0]);
  } else if (
    petitionSingle.images.length > 0 &&
    petitionSingle.images !== null
  ) {
    petitionImage = isImageLink(petitionSingle.images[0]);
  }

  return (
    <>
      <LinkedNotification targetId={petitionSingle.id} shareUrl={shareUrl} />
      <div
        className="clsback-link mb-3"
        onClick={() => {
          navigateTo('/dashboard/initiatives');
        }}
      >
        <a href={hashUrl}>
          <img
            src={require('../../../assets/img/back.svg').default}
            alt="Back"
          />
          {t('Back to Initiatives')}
        </a>
      </div>
      <div className="clsprofile-details-blk clspetitions-details-blk clswhite-box">
        <figure className="clsprofile-post-img">
          <img
            src={
              petitionSingle.images.length > 0
                ? petitionImage
                : require('../../../assets/img/profile-post-img.png').default
            }
            alt="test"
            className="img-fluid"
          />
        </figure>
        <div className="clsprofile-details-box mt-2">
          <div className="clsprofile-details">
            <div className="clsprofile-details-left">
              <h4 className="mb-3">{petitionSingle.title}</h4>
              <div className="media">
                <NavLink
                  to={
                    '/dashboard/user-profile/?id=' + petitionSingle.user.userId
                  }
                  activeClassName="active"
                  exact
                >
                  <img
                    src={profileImg(petitionSingle.user.profile_picture)}
                    className=" header-profile-img"
                    alt="Profile"
                  />
                </NavLink>
                <div className="media-body align-self-center mb-1 ml-1">
                  <h5 className="mt-0">
                    <NavLink
                      to={
                        '/dashboard/user-profile/?id=' +
                        petitionSingle.user.userId
                      }
                      activeClassName="active"
                      className={'profile-link'}
                      exact
                    >
                      {petitionSingle.user.show_my_name}
                    </NavLink>
                  </h5>
                  <p>
                    {t('Started this petition on')}{' '}
                    {moment(petitionSingle.createdAt).format('DD MMM YYYY')}{' '}
                    {t('For')} {petitionSingle.division}
                  </p>
                  <p className="clspetition-location">
                    <img
                      src={require('../../../assets/img/location.svg').default}
                      alt="Location"
                    />
                    {petitionSingle.division}
                  </p>
                </div>
              </div>
            </div>
            {petitionSingle.active_status === 'current' ? (
              <ShareLink
                shareUrl={shareUrl}
                title={title}
                targetId={petitionSingle.id}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`signin-modal`}
          title={t('Sign Initiative')}
          dismissal={filterDismissal}
        >
          <EligiblePetition {...props} />
        </CivicModal>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="clsdescription-blk clswhite-box mt-3">
            <div className="clsbadge-tag-list mt-2 mb-4">
              <ul>
                {petitionSingle.topics.map((topic, index) => (
                  <li key={index}>
                    <span className="badge clslight-grey-badge">
                      {
                        user.selected_language === topic.originalLanguage ?
                          topic.value
                        :
                          (JSON.parse(topic.translation)?.[user.selected_language]?.name) ?
                            JSON.parse(topic.translation)?.[user.selected_language]?.name
                          :
                            topic.value
                      }
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <h5 className="mb-2 pb-1">{t('Description')}</h5>
            <div className={`editor-content`}>
              {isJson(petitionSingle.description) === true ? (
                <p
                  dangerouslySetInnerHTML={convertFromJSONToHTML(
                    petitionSingle.description,
                  )}
                ></p>
              ) : (
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {petitionSingle.description}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className={
              user.roleName === 'RESIDENT'
                ? 'clspetition-signin-box clswhite-box mt-3'
                : 'clspetition-signin-box clsbold-style clswhite-box mt-3'
            }
          >
            <p>
              <img
                src={
                  petitionSingle.signed === true
                    ? require('../../../assets/img/check-yellow.svg').default
                    : ''
                }
                alt=""
              />
              {petitionSingle.signeesCount} {t('people have signed')}
            </p>
            {user.roleName === 'RESIDENT' &&
            petitionSingle.signed === false &&
            petitionSingle.eligible_to_vote === true &&
            petitionSingle.active_status === 'current' ? (
              <div className="mt-2">
                <a
                  href={hashUrl}
                  className="btn btn-block clsyellow-btn"
                  data-toggle="modal"
                  data-target="#signin-modal"
                >
                  {t('You are eligible! Join an Initiative')}
                </a>
              </div>
            ) : user.roleName === 'RESIDENT' &&
              petitionSingle.signed === true &&
              petitionSingle.active_status === 'current' ? (
              <div className="mt-2">
                <button
                  className="btn btn-block clsyellow-btn"
                  disabled="disabled"
                >
                  {t('You Joined an Initiative')}
                </button>
              </div>
            ) : user.roleName === 'RESIDENT' &&
              petitionSingle.active_status === 'expired' ? (
              <div className="mt-2">
                <button
                  className="btn btn-block clsyellow-btn"
                  disabled="disabled"
                >
                  {t('This Initiative has expired')}
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualPetition;
