/*
 *
 * Discussion Engagement Form
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import DiscussionEngagementForm from '../../components/Manager/DiscussionEngagement';
import CivicModal from '../../components/Common/CivicModal';
import DiscussionFooter from '../../components/Manager/DiscussionEngagement/discussionFooter';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const DiscussionEngagement = (props) => {
  const {
    engagement,
    user,
    discussionEngagementData,
    reload,
    discussionEngagementChange,
    submitDiscussionEngagement,
    setDiscussionReload,
    setDiscussionEngagementData,
    setOpenedId,
    changePostComment,
    submitComment,
    setReactions,
    type,
    addNotification,
  } = props;
  const { t } = useTranslation();

  useEffect(async () => {
    if (typeof user.roleName !== 'undefined') {
      await setDiscussionEngagementData(engagement.id, false);
    }
  }, [
    user.roleName,
    setDiscussionEngagementData,
    engagement,
    reload,
    setDiscussionReload,
    submitComment,
  ]);

  const closeEngagementModal = (id) => {
    if ($(`#discussion-engagement-${engagement.id}`)) {
      $(`#discussion-engagement-${engagement.id}`).modal('hide');
    }
  };

  const [isViewMoreCommentsToggled, setIsViewMoreCommentsToggled] = useState(false);

  return (
    <>
      {
        type === 'Modal' ? (
          <div
            className={`clsdiscussion-modal`}
            key={`discussion-engagement-div-${engagement.id}`}
          >
            <CivicModal
              id={`discussion-engagement-${engagement.id}`}
              title={t(discussionEngagementData[engagement.id].title)}
              dismissal={discussionEngagementData[engagement.id].dismissal}
              footer={''}
              footerClass={`clsdiscussion-modal`}
              scrollable={true}
            >
              <DiscussionEngagementForm
                user={user}
                engagement={engagement}
                engagementId={engagement.id}
                discussionEngagementData={
                  discussionEngagementData[engagement.id]
                }
                discussionEngagementChange={discussionEngagementChange}
                submitDiscussionEngagement={submitDiscussionEngagement}
                setOpenedId={setOpenedId}
                changePostComment={changePostComment}
                submitComment={submitComment}
                reload={reload}
                setReactions={setReactions}
                canReact={engagement.canReact}
                restrictionReason={engagement.restriction_reason}
                addNotification={addNotification}
                closeEngagementModal={closeEngagementModal}
                isViewMoreCommentsToggled={isViewMoreCommentsToggled}
                setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
              />
            </CivicModal>
          </div>
        ) : (
          <>
            <DiscussionEngagementForm
              user={user}
              engagement={engagement}
              engagementId={engagement.id}
              discussionEngagementData={discussionEngagementData[engagement.id]}
              discussionEngagementChange={discussionEngagementChange}
              submitDiscussionEngagement={submitDiscussionEngagement}
              setOpenedId={setOpenedId}
              changePostComment={changePostComment}
              submitComment={submitComment}
              reload={reload}
              setReactions={setReactions}
              canReact={engagement.canReact}
              restrictionReason={engagement.restriction_reason}
              addNotification={addNotification}
              isViewMoreCommentsToggled={isViewMoreCommentsToggled}
              setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
            />
            <DiscussionFooter
              engagement={engagement}
              discussionEngagementData={discussionEngagementData[engagement.id]}
              submitComment={submitComment}
              discussionEngagementChange={discussionEngagementChange}
              type={`Normal`}
              user={user}
              isViewMoreCommentsToggled={isViewMoreCommentsToggled}
              setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
            />
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    discussionEngagementData: state.discussionEngagement.discussionEngagementData,
    reload: state.discussionEngagement.reload,
  };
};

DiscussionEngagement.defaultProps = {
  type: 'Modal',
};

export default connect(mapStateToProps, actions)(DiscussionEngagement);
