/**
 *
 * Analytics Tabs
 *
 */

import React from 'react';
import Tabs from '../../Common/CivicTabs/Tabs';
import AnalyticsView from '../../../containers/Analytics/analyticsView';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const AnalyticsTabs = (props) => {
  const { activeTab, setAnalyticTab, analyticsData } = props;
  const { t } = useTranslation();

  const setTab = (type) => {
    setAnalyticTab(type);
  };

  return (
    <>
      <Tabs
        panelClass={'clsdashboard-panel'}
        id={'Analytics-Tabs'}
        tabContentId={'AnalyticsTabContnet'}
      >
        <div
          tabTitle={t('Comments Analytics')}
          linkId={'Discussion'}
          effectClass={'fade'}
          isActive={activeTab === 'discussion' ? 'true' : 'false'}
          onClick={(event) => {
            setTab('discussion');
          }}
        >
          {activeTab === 'discussion' ? (
            <AnalyticsView type={activeTab} {...props} />
          ) : (
            <LoadingIndicator position={`relative`} />
          )}
        </div>
        {analyticsData.category === 'survey' ? (
          <div
            tabTitle={t('Survey Analytics')}
            linkId={'Survey'}
            effectClass={'fade'}
            isActive={activeTab === 'survey' ? 'true' : 'false'}
            onClick={(event) => {
              setTab('survey');
            }}
          >
            {activeTab === 'survey' ? (
              <AnalyticsView type={activeTab} {...props} />
            ) : (
              <LoadingIndicator position={`relative`} />
            )}
          </div>
        ) : (
          <div
            tabTitle={''}
            linkId={'Survey'}
            effectClass={'fade'}
            isActive={'false'}
          >
            <LoadingIndicator position={`relative`} />
          </div>
        )}

        <div
          tabTitle={t('Post Views Analytics')}
          linkId={'Postview'}
          effectClass={'fade'}
          isActive={activeTab === 'postview' ? 'true' : 'false'}
          onClick={(event) => {
            setTab('postview');
          }}
        >
          {activeTab === 'postview' ? (
            <AnalyticsView type={activeTab} {...props} />
          ) : (
            <LoadingIndicator position={`relative`} />
          )}
        </div>
      </Tabs>
    </>
  );
};

export default AnalyticsTabs;
