/**
 *
 * Analytics component
 *
 */

import React from 'react';
import AnalyticsHeader from './analyticsHeader';
import AnalyticsBriefInfo from './analyticsBriefInfo';
import TopicBox from './topicBox';
import ChartBox from './chartBox';
import AnalyticsTabs from './analyticsTabs';

const AnalyticsPage = (props) => {
  const { analyticsData } = props;

  return (
    <>
      {typeof analyticsData.id !== 'undefined' ? (
        <div className={`clsanalytics-content-blk`}>
          <AnalyticsHeader {...props} />
          <AnalyticsBriefInfo {...props} />
          <div className={`clsanalytics-donut-chart-blk mt-3`}>
            {analyticsData.category === 'survey' ? <ChartBox {...props} /> : ''}
          </div>
          <div className={`clsanalytics-tab-blk mt-4`}>
            <AnalyticsTabs {...props} />
            <div style={{ visibility: `hidden`, minHeight: `200px` }}></div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default AnalyticsPage;
