/**
 *
 * Share
 *
 */

import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const hashUrl = '#';

const ShareLink = ({ shareUrl, title, subMenu, module, notify, targetId }) => {
  if (subMenu === true) {
    $(document).ready(function () {
      $('.clsshare-dropdown > a.dropdown-toggle').click(function (e) {
        e.stopPropagation();
        $('.clsshare-dropdown > .dropdown-menu').toggle('show');
        e.stopImmediatePropagation();
        $('.clsshare-dropdown').addClass('show');
        e.preventDefault();
      });
      $('.shareWidth > ul > li').click(function (e) {
        //$('.shareWidth').hide();
        //$(".clsshare-dropdown").removeClass("show");
      });
    });
  }

  const { t } = useTranslation();

  return (
    <div className="clsprofile-followers">
      {subMenu === false ? (
        <figure className="mb-1">
          <img
            src={require('../../../assets/img/share.svg').default}
            alt="Share"
          />
        </figure>
      ) : (
        ''
      )}
      <div
        className={`nav-item dropdown clsavatar-dropdown clsshare-dropdown ${
          subMenu === true ? `shareLinkTxt` : ``
        }`}
      >
        <a
          className="nav-link dropdown-toggle"
          href={hashUrl}
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {' '}
          {t(`Share`)}{' '}
        </a>
        <div
          className={`dropdown-menu ${subMenu === true ? `shareWidth` : ``}`}
          aria-labelledby="navbarDropdownMenuLink"
        >
          <h5 className="mb-2">{t(`Share`)}</h5>
          <ul>
            <li>
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button dropdown-item"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </li>
            <li>
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button dropdown-item"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </li>
            <li>
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target={`#linkedin-modal-${targetId}`}
              >
                <LinkedinIcon
                  size={32}
                  round
                  onClick={() => {
                    navigator.clipboard.writeText(shareUrl);
                  }}
                />
              </a>
            </li>
            <li>
              <EmailShareButton
                url={shareUrl}
                subject={title}
                body="body"
                className="Demo__some-network__share-button dropdown-item"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </li>
            <li>
              <img
                src={require('../../../assets/img/copy-link.png').default}
                alt="Copy Link"
                onClick={() => {
                  navigator.clipboard.writeText(shareUrl);
                  if (typeof notify === 'function') {
                    notify({
                      type: 'success',
                      message: 'Link Copied',
                      module: 'dashboard',
                      displayFor: 5000,
                      parentDivClass: 'top-notification',
                    });
                  }
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

ShareLink.defaultProps = {
  subMenu: false,
  module: 'dashboard',
  notify: '',
};

export default ShareLink;
