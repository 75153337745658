/**
 *
 * Discussion Engagement Form
 *
 */

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from '../../../actions';
import CommentEmotions from '../CommentEmotions';
import ChildComment from './childComment';
import { NavLink } from 'react-router-dom';
import Tooltip from '../../Common/Tooltip';
import { useTranslation } from 'react-i18next';
import Comment from './comment';
import LoadingIndicator from '../../Common/LoadingIndicator';
import CommentBox from '../DiscussionEngagement/commentBox';
import { setIsSignUpButtonClicked, setRegistrationModalOpen } from '../../../containers/RegisterFlow/actions';

const DiscussionEngagementForm = (props) => {
  const {
    discussionEngagementData,
    engagement,
    engagementId,
    changePostComment,
    submitComment,
    setReactions,
    user,
    closeEngagementModal,
    setDiscussionEngagementData,
    authenticated,
    canReact,
    restrictionReason,
    engagementSpecific,
    isViewMoreCommentsToggled,
    setIsViewMoreCommentsToggled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [parentComments, setParentComments] = useState(null);
  const [latestComment, setLatestComment] = useState(null);
  const [parentCommentCount, setParentCommentCount] = useState(0);

  useEffect(() => {
    if (discussionEngagementData && discussionEngagementData[engagementId]) {
      let commentData = null;
      commentData = discussionEngagementData[engagementId]?.['comments']?.filter(function (
        comments,
      ) {
        return comments.parentId === null;
      });
      setParentComments(commentData);
      setLoaded(true);
    }
  }, [loaded, discussionEngagementData, engagementId]);
  useEffect(() => {
    setLatestComment(engagement?.latestComment);
  }, [engagement]);
  useEffect(() => {
    setParentCommentCount(engagement.parentCommentCount);
  }, [engagement]);
  useEffect(() => {
    if (engagementSpecific && engagementSpecific.id === engagement.id) {
      setParentCommentCount(engagementSpecific.parentCommentCount);
      setLatestComment(engagementSpecific.latestComment);
    }
  }, [engagementSpecific]);
  useEffect(() => {
    // Workaround for updating reactions on latest comment
    const commentData = discussionEngagementData?.[engagementId]?.['comments'];
    if (commentData && commentData?.length > 0) {
      if (!(commentData[commentData.length - 1]?.parentId)) {
        setLatestComment(commentData[commentData.length - 1]);
      }
    }
  }, [discussionEngagementData]);

  const isIconDisabled = (!user.isVerified || !user.isIdentityVerified || !user.isRegistrationComplete);

  const findDiscussionEngagementDataCommentId = (commentId, parentComments) => {
    if (parentComments) {
      for (let i = 0; i < parentComments.length; i++) {
        if (commentId === parentComments[i].id) {
          return parentComments[i];
        }
      }
    }
    return null;
  }

  return (
    <>
        <div
          className="clsdiscussion-blk clspost-box"
          style={{
            paddingTop: (engagement && parentCommentCount > 1) && 15
          }}
        >
          <ul className="list-unstyled">
            {
              (authenticated && engagement && parentCommentCount > 1) &&
              <a
                href={`#comment-show-more-`}
                data-target={`#comment-show-more-`}
                data-toggle="collapse"
                className="nav-toggle clsreply-link"
                id={`show-hide-link-`}
                onClick={async () => {
                  if (!loaded) {
                    setIsCommentsLoading(true);
                    dispatch(setDiscussionEngagementData(engagement.id, false));
                    setLoaded(true);
                    setIsCommentsLoading(false);
                  }
                  setIsViewMoreCommentsToggled(!isViewMoreCommentsToggled);
                }}
              >
                {
                  isViewMoreCommentsToggled ?
                    t('See fewer comments')
                    :
                    t('See more comments')
                }
              </a>
            }
            {
              (!authenticated && parentCommentCount > 1) &&
              <a
                className="nav-toggle clsreply-link pointer"
                onClick={() => {
                  if (!authenticated) {
                    dispatch(setIsSignUpButtonClicked(true));
                    dispatch(setRegistrationModalOpen(true));
                  }
                }}
              >
                {
                  t(`Register for free to read all ${engagement?.commentCount ?? 0} community comments`)
                }
              </a>
            }
            {
              !loaded && isCommentsLoading &&
              <LoadingIndicator
                position={`relative`}
                height={'35px'}
                backgroundSize={'35px'}
              />
            }
            {
              (!isViewMoreCommentsToggled && engagement && latestComment) &&
              <li
                className="media clsmany-replies my-4"
                key={`comment-${latestComment?.id}`}
                style={{
                  width: '100%'
                }}
              >
                <div className={!authenticated && 'blur'}>
                  {
                    authenticated ?
                      <NavLink
                        onClick={closeEngagementModal}
                        to={'/dashboard/user-profile/?id=' + latestComment?.userId}
                        activeClassName="active"
                        exact
                      >
                        <img
                          src={
                            latestComment?.commentOwnerProfilePicture ?? require('../../../assets/img/profile-image.jpeg').default
                          }
                          className="mr-3 engagement-profile-img"
                          alt={latestComment?.commentOwner}
                        />
                      </NavLink>
                    :
                      <img
                        src={
                          latestComment?.commentOwnerProfilePicture ?? require('../../../assets/img/profile-image.jpeg').default
                        }
                        className="mr-3 engagement-profile-img"
                        alt={''}
                      />
                  }
                </div>
                <div
                  className="media-body"
                  style={{
                    width: '75%'
                  }}
                >
                  <Comment
                    user={user}
                    closeEngagementModal={closeEngagementModal}
                    comment={latestComment}
                    authenticated={authenticated}
                  />
                  <CommentEmotions
                    id={engagementId}
                    commentId={latestComment?.id}
                    likes={latestComment?.likeReactionCount}
                    disLikes={latestComment?.dislikeReactionCount}
                    comments={latestComment?.childCount}
                    commentBoxStatus={true}
                    commentData={latestComment}
                    changePostComment={changePostComment}
                    submitComment={submitComment}
                    setReactions={setReactions}
                    user={user}
                    isChildComment={false}
                    authenticated={authenticated}
                    {...props}
                  />
                  {latestComment.childCount > 0 ? (
                    <ChildComment
                      comment={latestComment}
                      engagementId={engagementId}
                      user={user}
                      totalCount={latestComment.childCount}
                      {...props}
                    />
                  ) : (
                    ''
                  )}
                  {(!isIconDisabled) &&
                    <div id={`comment-box-${latestComment?.id}`} className="collapse clspost-box">
                      <CommentBox
                        id={engagementId}
                        commentId={latestComment?.id}
                        commentData={findDiscussionEngagementDataCommentId(latestComment?.id, parentComments)}
                        changePostComment={changePostComment}
                        submitComment={submitComment}
                        key={`comment-reply-box-${latestComment?.id}`}
                        user={user}
                        canReact={canReact}
                        restrictionReason={t(restrictionReason)}
                        discussionBoardEnabled={engagement.discussion_board}
                        isSeeMoreComments={true}
                        setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
                      />
                    </div>
                  }
                </div>
              </li>
            }
            {(loaded === true && parentComments != null) && parentComments.map((comment, index) => (
              <>
                {
                  (isViewMoreCommentsToggled) &&
                  <li
                    className="media clsmany-replies my-4"
                    key={`comment-${comment.id}`}
                    style={{
                      width: '100%'
                    }}
                  >
                    <NavLink
                      onClick={closeEngagementModal}
                      to={'/dashboard/user-profile/?id=' + comment.userId}
                      activeClassName="active"
                      exact
                    >
                      <img
                        src={
                          comment.commentOwnerProfilePicture !== null
                            ? comment.commentOwnerProfilePicture
                            : require('../../../assets/img/profile-image.jpeg')
                              .default
                        }
                        className="mr-3 engagement-profile-img"
                        alt={comment.commentOwner}
                      />
                    </NavLink>
                    <div
                      className="media-body"
                      style={{
                        width: '75%'
                      }}
                    >
                      <Comment
                        user={user}
                        closeEngagementModal={closeEngagementModal}
                        comment={comment}
                        authenticated={authenticated}
                      />
                      <CommentEmotions
                        id={engagementId}
                        commentId={comment.id}
                        likes={comment.likeReactionCount}
                        disLikes={comment.dislikeReactionCount}
                        comments={comment.childCount}
                        commentBoxStatus={true}
                        commentData={comment}
                        changePostComment={changePostComment}
                        submitComment={submitComment}
                        setReactions={setReactions}
                        user={user}
                        isChildComment={true}
                        authenticated={authenticated}
                        {...props}
                      />
                      {parentComments[index].childCount > 0 ? (
                        <ChildComment
                          comment={comment}
                          engagementId={engagementId}
                          user={user}
                          totalCount={parentComments[index].childCount}
                          {...props}
                        />
                      ) : (
                        ''
                      )}
                      {(!isIconDisabled) &&
                        <div id={`comment-box-${comment.id}`} className="collapse clspost-box">
                          <CommentBox
                            id={engagementId}
                            commentId={comment.id}
                            commentData={comment}
                            changePostComment={changePostComment}
                            submitComment={submitComment}
                            key={`comment-reply-box-${comment.id}`}
                            user={user}
                            canReact={canReact}
                            restrictionReason={t(restrictionReason)}
                            discussionBoardEnabled={engagement.discussion_board}
                            setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
                          />
                        </div>
                      }
                    </div>
                  </li>
                }
              </>
            ))}
          </ul>
        </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    discussionEngagementData: state.discussionEngagement.discussionEngagementData,
    engagementSpecific: state.engagement.engagementSpecific,
  };
};

export default connect(mapStateToProps, actions)(DiscussionEngagementForm);
