/*
 *
 * Engagement List
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Engagements from '../../components/Manager/Engagement/engagementList';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../store';
import SelectOption from '../../components/Common/SelectOption';

const sortOptions = [
  { value: 'publish', label: 'Publishing Date' },
  { value: 'create', label: 'Creation Date' },
]

const EngagementList = (props) => {
  const {
    getEngagements,
    status,
    activeTab,
    engagements,
    noRecords,
    resetStatus,
    setEngagementFormData,
    deleteHandle,
    changeDeleteHandle,
    isEngagementSubmitting,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [engagementSort, setEngagementSort] = useState(sortOptions[0]);
  const { getState } = store;

  const [engagementListComponent, setEngagementListComponent] = useState('');

  const [page, setPage] = useState(1);

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setEngagementListComponent(
          <Component
            {...props}
            setEngagementFormData={setEngagementFormData}
          />,
        );
        return resolve(true);
      });
    },
    [setEngagementListComponent, setEngagementFormData, props],
  );

  const fetchEngagements = useCallback(async () => {
    setInProcess(true);
    if (activeTab === status) {
      await getEngagements(status, page, engagementSort.value).then(async () => {
        await renderComponent(Engagements).then(() => {
          setPage(page + 1);
        });
      });
    }
    setInProcess(false);
  }, [activeTab, status, getEngagements, renderComponent, setPage, page, engagementSort]);

  useEffect(() => {
    if (loading === true) {
      setEngagementListComponent('');
      if (inProcess === false && !isEngagementSubmitting) {
        setInProcess(true);
        const fetchEngagements = async () => {
          if (activeTab === status) {
            await getEngagements(status, 1, engagementSort.value).then(() => {
              setLoading(false);
              setInProcess(false);
            });
          }
        };
        fetchEngagements();
      }
    }
  }, [
    loading,
    setLoading,
    activeTab,
    status,
    getEngagements,
    renderComponent,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    engagements,
    engagementSort,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(Engagements);
      setPage(page + 1);
      setInitialLoad(false);
      if (page === 2) {
        window.scrollBy(0, -10);
        window.scrollBy(0, 0);
      }
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (resetStatus === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [resetStatus, inProcess, setPage, setLoading, setInitialLoad]);

  useEffect(() => {
    if (
      getState().engagement.engagements.length !== engagements[status].length &&
      (page === 2 || page === 3)
    ) {
      renderComponent(Engagements);
    }
  }, [getState, engagements, status, page, renderComponent]);

  useEffect(() => {
    if (deleteHandle === true) {
      const setDeleteHandle = async (value) => {
        await changeDeleteHandle(value).then(() => {
          renderComponent(Engagements);
        });
      };
      setDeleteHandle(false);
    }
  }, [renderComponent, deleteHandle, changeDeleteHandle]);

  useEffect(() => {
    setPage(1);
    setLoading(true);
    setInitialLoad(true);
  }, [engagementSort, setPage, setLoading, setInitialLoad]);

  useEffect(() => {
    if (isEngagementSubmitting) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [isEngagementSubmitting])

  return (
    <>
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          {status === 'unpublished' && (
            <div style={{ marginBottom: 20 }}>
              <SelectOption
                name={'Sort By'}
                value={engagementSort}
                options={sortOptions}
                handleSelectChange={(value) => {
                  setEngagementSort(value);
                }}
              />
          </div>
          )}
          {engagementListComponent}
          <InfiniteScroll
            dataLength={engagements[status].length}
            next={fetchEngagements}
            hasMore={!noRecords[status]}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    engagements: state.engagement.engagements,
    activeTab: state.engagement.activeTab,
    noRecords: state.engagement.noRecords,
    resetStatus: state.engagement.resetStatus,
    deleteHandle: state.engagement.deleteHandle,
    isEngagementSubmitting: state.engagement.isEngagementSubmitting,
  };
};

EngagementList.defaultProps = {
  status: 'unpublished',
};

export default connect(mapStateToProps, actions)(EngagementList);
