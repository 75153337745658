import React from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import CommunityIssueImageEntry from './CommunityIssueImageEntry';

const MyCommunityIssues = (props) => {
  const { t } = useTranslation();
  const {
    myCommunityIssues,
    isCommunityIssuesLoading,
    allCommunityIssues,
    myCommunityIssuesFeedIds,
  } = props;

  return (
    <div className={myCommunityIssuesFeedIds.length > 0 ? 'my-community-issues-block' : ''}>
      <div className="clsdashboard-upcoming-event clswhite-box my-community-issues">
        <h5
            className="mb-1"
            style={{
              alignItems: 'center'
            }}
        >
          <img
              src={require('../../../assets/img/community-issues-icon-exclamation.svg').default}
              alt="Community Issue Exclamation"
          />
          {t('My Community Issues')}
        </h5>
        {
          myCommunityIssuesFeedIds && myCommunityIssuesFeedIds.length > 0 ?
              myCommunityIssuesFeedIds.map((communityIssueId) => {
                return (
                    <CommunityIssueImageEntry communityIssueId={communityIssueId} />
                )
              })
              :
              <div className='no-started-issues'>
                {
                  isCommunityIssuesLoading ?
                      <p>
                        {t('Loading...')}
                      </p>
                      :
                      <p>
                        {t('You haven’t started any Community Issues. Start one now!')}
                      </p>
                }
              </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    myCommunityIssues: state.communityIssues.myCommunityIssues,
    isCommunityIssuesLoading: state.communityIssues.isCommunityIssuesLoading,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    myCommunityIssuesFeedIds: state.communityIssues.myCommunityIssuesFeedIds,
  };
};

export default connect(mapStateToProps, actions)(MyCommunityIssues)