/**
 *
 * Display All Civic Event List
 *
 */

import React from 'react';
import Post from './post';
import moment from 'moment-timezone';
import ConfirmPopup from './confirmPopup';
import { useTranslation } from 'react-i18next';

const EventsList = (props) => {
  const { events, confirmPopup } = props;
  const { t } = useTranslation();

  return (
    <div className="clsevents-list-whole mb-5">
      {Object.keys(events).length === 0 ? (
        <div className={'mb-3 no-engagement'}>
          <span className={'sad-img'}>
            <img
              src={require('../../../assets/img/sad.svg').default}
              alt={t(`No Events`)}
            />
          </span>
          <span className="no-engagement-txt">
            {t(`You don't have any events`)}
          </span>
        </div>
      ) : (
        Object.keys(events).map((month, index) => (
          <div key={index}>
            {
              <h3 className="mb-3 mt-3">
                {month === moment().format('YYYYMM01')
                  ? 'This Month'
                  : moment(month, 'YYYYMMDD').format('MMMM')}
                {moment(month, 'YYYYMMDD').format('YYYY') ===
                moment().format('YYYY')
                  ? ''
                  : ' ' + moment(month, 'YYYYMMDD').format('YYYY')}
              </h3>
            }
            {events[month].length > 0 ? (
              events[month].map((event, index) => (
                <Post key={index} {...props} month={month} event={event} />
              ))
            ) : (
              <div key={index} className={'mb-3 no-engagement'}>
                <span className={'sad-img'}>
                  <img
                    src={require('../../../assets/img/sad.svg').default}
                    alt={t(`No Events`)}
                  />
                </span>
                <span className="no-engagement-txt">
                  {t(`No Events in this month`)}
                </span>
              </div>
            )}
          </div>
        ))
      )}
      {confirmPopup !== null ? <ConfirmPopup {...props} /> : ''}
    </div>
  );
};

export default EventsList;
