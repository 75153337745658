/*
 *
 * Password Reset Link Confirmation && Reset Password
 *
 */

import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import Notification from '../Notification';
import { useTranslation } from 'react-i18next';

const ResetPassword = (props) => {
  const {
    authenticated,
    resetFormData,
    resetFormErrors,
    resetPasswordChange,
    resetPassword,
    isResetFormSubmitting,
    isLinkVerified,
    isValidLink,
  } = props;

  const { t } = useTranslation();

  const verifyLink = useCallback(() => {
    if (!isLinkVerified && !isValidLink) {
      props.verifyLink();
    }
  }, [props, isLinkVerified, isValidLink]);

  useEffect(() => {
    verifyLink();
  }, [verifyLink]);

  if (authenticated) return <Redirect to="/dashboard" />;

  const handleSubmit = (event) => {
    event.preventDefault();
    resetPassword();
  };

  return (
    <div className="clslogin-form-blk pb-5 mb-5">
      <div className="clslogin-form clswhite-box mt-4 pt-3">
        <div
          className="tabbable-panel"
          style={{ position: !isLinkVerified ? 'relative' : '' }}
        >
          <h5 className="fs-1 text-center pb-5" style={{ fontSize: '18px' }}>
            {t(`Reset Password`)}
          </h5>
          <Notification module={'resetPassword'} />
          {!isLinkVerified ? (
            <LoadingIndicator />
          ) : (
            [
              isValidLink ? (
                <form onSubmit={handleSubmit} key="resetPassword">
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder={t(`New Password`)}
                      name={'password'}
                      value={resetFormData.password}
                      onChange={(e) =>
                        resetPasswordChange(e.target.name, e.target.value)
                      }
                    />
                    {typeof resetFormErrors !== 'undefined' &&
                      resetFormErrors.password && (
                        <div className="clsinvalid">
                          {t(resetFormErrors?.['password'])}
                        </div>
                      )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Retype Password"
                      name={'confirmPassword'}
                      value={resetFormData.confirmPassword}
                      onChange={(e) =>
                        resetPasswordChange(e.target.name, e.target.value)
                      }
                    />
                    {typeof resetFormErrors !== 'undefined' &&
                      resetFormErrors.confirmPassword && (
                        <div className="clsinvalid">
                          {t(resetFormErrors?.['confirmPassword'])}
                        </div>
                      )}
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn clsyellow-btn"
                      disabled={isResetFormSubmitting}
                    >
                      {t(`Submit`)}
                    </button>
                  </div>
                </form>
              ) : null,
            ]
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    resetFormData: state.resetPassword.resetFormData,
    resetFormErrors: state.resetPassword.resetFormErrors,
    isResetFormSubmitting: state.resetPassword.isResetFormSubmitting,
    isLinkVerified: state.resetPassword.isLinkVerified,
    isValidLink: state.resetPassword.isValidLink,
    errorNotification: state.resetPassword.errorNotification,
  };
};

export default connect(mapStateToProps, actions)(ResetPassword);
