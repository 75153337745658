/*
 *
 * Search key actions
 *
 */

import {
  SEARCH_KEY_CHANGE,
  RESET_SEARCH_KEY_RESET,
  SET_SEARCH_KEY_ERROR,
  SET_SEARCH_SUBMITTING,
  SET_SEARCH_RESULT_RESET,
  RESET_APP,
} from './constants';

export const resetSearchState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const searchKeyChange = (value) => {
  return async (dispatch, getState) => {
    return dispatch({ type: SEARCH_KEY_CHANGE, payload: value });
  };
};

export const resetSearchKey = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_SEARCH_KEY_RESET });
  };
};

export const searchSubmit = () => {
  return async (dispatch, getState) => {
    let value = getState().search.searchKey;
    if (value === '' || value === null) {
      return dispatch({
        type: SET_SEARCH_KEY_ERROR,
        payload: 'Please provide at least two characters',
      });
    } else if (value.length <= 1) {
      return dispatch({
        type: SET_SEARCH_KEY_ERROR,
        payload: 'Please provide at least two characters',
      });
    } else {
      dispatch({ type: SET_SEARCH_KEY_ERROR, payload: '' });
      dispatch({ type: SET_SEARCH_SUBMITTING, payload: true });
      dispatch({ type: SET_SEARCH_RESULT_RESET, payload: false });
    }
  };
};

export const setSearchSubmision = (value) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_SUBMITTING, payload: value });
  };
};

export const setSearchResetResult = (value) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_RESULT_RESET, payload: value });
  };
};
