import axios from 'axios';
import store from '../store';
import { SET_AUTH } from '../containers/Authentication/constants';
import {
  REQUEST_USER_PROFILE,
  SET_PROFILE_LOADING,
} from '../containers/Dashboard/constants';
import setToken from './token';
import qs from 'qs';
import { BASE_API_URL, API_CLIENT_ID } from '../constants';

const { dispatch } = store;

const setupInterceptors = axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    var location = new URL(originalConfig.url);
    var pathname = location.pathname.replace(/^\/|\/$/g, '');

    if (typeof originalConfig !== 'undefined') {
      if (err.response.status >= 500 && window.location.pathname !== '/500') {
        window.location.href = '500';
      }

      if (pathname !== 'login' && err.response) {
        // Access Token was expired
        if (
          err.response.status === 401 &&
          typeof originalConfig._retry === 'undefined'
        ) {
          originalConfig._retry = true;
          const refreshToken = localStorage.getItem('refreshToken');

          try {
            let data = {
              refresh_token: refreshToken,
              grant_type: 'refresh_token',
            };

            const rs = await axios.post(
              BASE_API_URL + '/login',
              qs.stringify(data),
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Authorization: API_CLIENT_ID,
                },
              },
            );
            const { access_token, refresh_token } = rs.data;
            localStorage.setItem('token', access_token);
            localStorage.setItem('refreshToken', refresh_token);
            setToken(access_token);
            dispatch({ type: SET_AUTH });
            dispatch({ type: SET_PROFILE_LOADING, payload: false });
            dispatch({ type: REQUEST_USER_PROFILE, payload: true });
            return axios(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
    } else {
      return Promise.reject(false);
    }
    return Promise.reject(err);
  },
);

export default setupInterceptors;
