import i18next from 'i18next';

export const REGISTER_CHANGE = 'src/RegisterFlow/REGISTER_CHANGE';
export const REGISTER_RESET = 'src/RegisterFlow/REGISTER_RESET';
export const SET_REGISTER_LOADING = 'src/RegisterFlow/SET_REGISTER_LOADING';
export const SET_REGISTER_FORM_ERRORS = 'src/RegisterFlow/SET_REGISTER_FORM_ERRORS';
export const SET_REGISTER_SUBMITTING = 'src/RegisterFlow/SET_REGISTER_SUBMITTING';
export const SET_CODE_VALIDATION_ERROR = 'src/RegisterFlow/SET_CODE_VALIDATION_ERROR';
export const SET_REGISTER_FORM_SUBMISSION_ERRORS = 'src/RegisterFlow/SET_REGISTER_FORM_SUBMISSION_ERRORS';
export const SET_REGISTRATION_MODAL_OPEN = 'src/RegisterFlow/SET_REGISTRATION_MODAL_OPEN';
export const SET_IS_SIGN_UP_BUTTON_CLICKED = 'src/RegisterFlow/SET_IS_SIGN_UP_BUTTON_CLICKED';
export const SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN = 'src/RegisterFlow/SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN';
export const RESET_APP = 'src/RegisterFlow/RESET_APP';
export const SET_IS_LOGIN_MODAL_OPEN = 'src/RegisterFlow/SET_IS_LOGIN_MODAL_OPEN';

export const signUpTypeOptions = [
    { value: 'resident', label: 'Resident', translationParsing: i18next.t('Resident') },
    { value: 'official', label: 'Government Official', translationParsing: i18next.t('Government Official') }
];

export const errorMessages = {
    'required.signUpType': 'User type is required',
    'required.email': 'Email is required.',
    'email.email': 'Email format is invalid.',
    'required.password': 'Password is required.',
    'password.password': 'Your password should contain at least six characters with at least one lowercase letter, one uppercase letter, and one number.',
    'required.dob': 'Date of birth is required.',
    'required.phone_number': 'Phone number is required.',
    'telephone.phone_number': 'Please check your phone number format.',
    'required.address': 'Address is required.',
    'required.city': 'City is required.',
    'required.state': 'State is required.',
    'required.zip_code': 'ZIP code is required.',
    'required.verificationCode': 'Verification code is required.',
    'required.firstName': 'First name is required.',
    'required.first_name': 'First name is required.',
    'required.lastName': 'Last name is required.',
    'required.last_name': 'Last name is required.',
    'required.district': 'District is required.',
    'required.position': 'Position is required.',
};

const errorMessagesTranslationParsing = {
    'required.signUpType': i18next.t('User type is required'),
    'required.email': i18next.t('Email is required.'),
    'email.email': i18next.t('Email format is invalid.'),
    'required.password': i18next.t('Password is required.'),
    'password.password': i18next.t('Please enter stronger password.'),
    'required.dob': i18next.t('Date of birth is required.'),
    'required.phone_number': i18next.t('Phone number is required.'),
    'telephone.phone_number': i18next.t('Please check your phone number format.'),
    'required.address': i18next.t('Address is required.'),
    'required.city': i18next.t('City is required.'),
    'required.state': i18next.t('State is required.'),
    'required.zip_code': i18next.t('ZIP code is required.'),
    'required.verificationCode': i18next.t('Verification code is required.'),
    'required.firstName': i18next.t('First name is required.'),
    'required.first_name': i18next.t('First name is required.'),
    'required.lastName': i18next.t('Last name is required.'),
    'required.last_name': i18next.t('Last name is required.'),
    'required.district': i18next.t('District is required.'),
    'required.position': i18next.t('Position is required.'),
}

export const verificationOptions = [
    { value: 'phone', label: 'Phone Number', translationParsing: i18next.t('Phone Number') },
    { value: 'mail', label: 'Mail Letter', translationParsing: i18next.t('Mail Letter') },
    { value: 'email', label: 'Email', translationParsing: i18next.t('Email') },
];

export const positionOptions = [
    { value: 'vice_mayor', label: 'Vice Mayor', translationParsing: i18next.t('Vice Mayor') },
    { value: 'senator', label: 'Senator', translationParsing: i18next.t('Senator') },
    { value: 'president_of_school', label: 'President Of School Board', translationParsing: i18next.t('President Of School Board') },
];

export const genderOptions = [
    { value: 'male', label: 'Male', translationParsing: i18next.t('Male') },
    { value: 'female', label: 'Female', translationParsing: i18next.t('Female') },
    { value: 'other', label: 'Other', translationParsing: i18next.t('Other') },
];

export const highEduOptions = [
    { value: 'some_high_school', label: 'Some High School', translationParsing: i18next.t('Some High School')},
    { value: 'high_school', label: 'High School', translationParsing: i18next.t('High School') },
    { value: 'college', label: 'College', translationParsing: i18next.t('College') },
    { value: 'graduate', label: 'Graduate', translationParsing: i18next.t('Graduate') },
    { value: 'phd', label: 'PhD', translationParsing: i18next.t('PhD') },
];

export const employmentStatusOptions = [
    { value: 'student', label: 'Student', translationParsing: i18next.t('Student') },
    { value: 'self_employed', label: 'Self Employed', translationParsing: i18next.t('Self Employed') },
    { value: 'employed', label: 'Employed', translationParsing: i18next.t('Employed') },
    { value: 'unemployed', label: 'Unemployed', translationParsing: i18next.t('Unemployed') },
    { value: 'retired', label: 'Retired', translationParsing: i18next.t('Retired') },
];

export const martialOptions = [
    { value: 'single', label: 'Single', translationParsing: i18next.t('Single') },
    { value: 'domestic_partnership', label: 'Domestic Partnership', translationParsing: i18next.t('Domestic Partnership') },
    { value: 'married', label: 'Married', translationParsing: i18next.t('Married') },
    { value: 'divorced', label: 'Divorced', translationParsing: i18next.t('Divorced') },
    { value: 'widowed', label: 'Widowed', translationParsing: i18next.t('Widowed') },
];

export const affiliateOptions = [
    { value: 'conservative', label: 'Conservative', translationParsing: i18next.t('Conservative') },
    { value: 'slightly_conservative', label: 'Slightly conservative', translationParsing: i18next.t('Slightly conservative') },
    { value: 'slightly_liberal', label: 'Slightly liberal', translationParsing: i18next.t('Slightly liberal') },
    { value: 'liberal', label: 'Liberal', translationParsing: i18next.t('Liberal') },
];

export const religionOptions = [
    { value: 'atheism', label: 'Atheism', translationParsing: i18next.t('Atheism') },
    { value: 'buddhism', label: 'Buddhism', translationParsing: i18next.t('Buddhism') },
    { value: 'christianity', label: 'Christianity', translationParsing: i18next.t('Christianity') },
    { value: 'hinduism', label: 'Hinduism', translationParsing: i18next.t('Hinduism')},
    { value: 'islam', label: 'Islam', translationParsing: i18next.t('Islam') },
    { value: 'judaism', label: 'Judaism', translationParsing: i18next.t('Judaism') },
    { value: 'other', label: 'Other', translationParsing: i18next.t('Other') },
    { value: 'none', label: 'None', translationParsing: i18next.t('None') },
];

export const raceOptions = [
    { value: 'white', label: 'White', translationParsing: i18next.t('White') },
    { value: 'hispanic', label: 'Hispanic', translationParsing: i18next.t('Hispanic') },
    { value: 'black', label: 'Black or African American', translationParsing: i18next.t('Black or African American') },
    { value: 'native', label: 'Native American', translationParsing: i18next.t('Native American') },
    { value: 'asian', label: 'Asian', translationParsing: i18next.t('Asian') },
    { value: 'pacific_islander', label: 'Pacific Islander', translationParsing: i18next.t('Pacific Islander') },
    { value: 'mixed', label: 'Two or More Races', translationParsing: i18next.t('Two or More Races') },
    { value: 'other', label: 'Other Race', translationParsing: i18next.t('Other Race') },
];