/**
 *
 * Authentication
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import actions from '../../actions';

export default function withoutauth(ComposedComponent) {
  const Authentication = (props) => {
    const { authenticated } = props;

    if (!authenticated) {
      return <ComposedComponent {...props} />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  };

  const mapStateToProps = (state) => {
    return {
      authenticated: state.authentication.authenticated,
      user: state.account.user,
      landingPages: state.account.landingPages,
      receivedUrl: state.authentication.receivedUrl,
    };
  };

  return connect(mapStateToProps, actions)(Authentication);
}
