/*
 *
 * Report Issue reducer
 *
 */

import {
  INAPPROPRIATE_REPORT_FORM_CHANGE,
  INAPPROPRIATE_REPORT_ERRORS,
  INAPPROPRIATE_REPORT_RESET,
  INAPPROPRIATE_REPORT_LOADING,
  INAPPROPRIATE_REPORT_SUBMITTING,
  RESET_APP,
} from './constants';

const initialState = {
  inAppropriateData: {
    reportLink: '',
    description: '',
    id: '',
    dismissal: false,
  },
  inAppropriateErrors: [],
  inAppropriateLoad: false,
  inAppropriateSubmit: false,
};

const defaultState = JSON.stringify(initialState);

const inAppropriateReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case INAPPROPRIATE_REPORT_FORM_CHANGE:
      return {
        ...state,
        inAppropriateData: { ...state.inAppropriateData, ...action.payload },
      };
    case INAPPROPRIATE_REPORT_ERRORS:
      return {
        ...state,
        inAppropriateErrors: action.payload,
      };
    case INAPPROPRIATE_REPORT_RESET:
      return {
        ...state,
        inAppropriateData: {
          reportLink: '',
          description: '',
          id: '',
          dismissal: false,
        },
      };
    case INAPPROPRIATE_REPORT_LOADING:
      return {
        ...state,
        inAppropriateLoad: action.payload,
      };
    case INAPPROPRIATE_REPORT_SUBMITTING:
      return {
        ...state,
        inAppropriateSubmit: action.payload,
      };
    default:
      return state;
  }
};

export default inAppropriateReportReducer;
