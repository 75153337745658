/*
 *
 * Event View
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import PostView from '../../components/Manager/Events/post-view';
import NoData from '../../components/Manager/Events/noData';

const View = (props) => {
  const { location, setEventData, user, event } = props;

  const Id = new URLSearchParams(location.search).get('id');

  const [loaded, setLoaded] = useState(false);

  const loadEvent = useCallback(async () => {
    if (typeof user.roleName !== 'undefined') {
      if (Id === null) {
        setLoaded(true);
      } else {
        await setEventData(Id).then(() => {
          setLoaded(true);
        });
      }
    }
  }, [setEventData, Id, setLoaded, user]);

  useEffect(() => {
    if (loaded === false && typeof user.roleName !== 'undefined') {
      loadEvent();
    }
  }, [loaded, loadEvent, user.roleName]);

  return (
    <>
      {loaded === false ? <LoadingIndicator position={`fixed`} /> : ''}
      {Id !== null && loaded === true && typeof event !== 'undefined' ? (
        <div>{<PostView {...props} event={event} />}</div>
      ) : (
        <NoData />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    event: state.event.eventSingle,
  };
};

export default connect(mapStateToProps, actions)(View);
