/**
 *
 * Carousel
 *
 */

import React from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const CustomLeftArrow = ({ onClick }) => {
  return (
    <button 
      onClick={onClick}
      className='btn btn-block clsyellow-btn'
      style={{
        position: 'absolute',
        maxWidth: '5%',
        left: 0,
        top: 10,
        // Idk why but unless specified, CustomLeftArrow is ~10 units above the CustomRightArrow
      }}
    >
      <img src={require('../../../assets/img/arrow-left.svg').default} alt="Messages" />
    </button>
  )
}

const CustomRightArrow = ({ onClick }) => {
  return (
    <button 
      onClick={onClick}
      className='btn btn-block clsyellow-btn'
      style={{
        position: 'absolute',
        maxWidth: '5%',
        right: 5,
        top: 0,
      }}
    >
      <img src={require('../../../assets/img/arrow-right.svg').default} alt="Messages" />
    </button>
  )
}

const CarouselSlider = (props) => {
  const {
    swipeable,
    draggable,
    showDots,
    infinite,
    autoPlay,
    keyBoardControl,
    autoPlaySpeed,
    ssr,
    responsive,
    containerClass,
    itemClass,
    shouldResetAutoplay,
    arrows,
    centerSlide,
    centerMode,
    children,
    removeArrowOnDeviceType,
  } = props;

  return (
    <Carousel
      swipeable={swipeable}
      draggable={draggable}
      showDots={showDots}
      infinite={infinite}
      autoPlay={autoPlay}
      keyBoardControl={keyBoardControl}
      autoPlaySpeed={autoPlaySpeed}
      ssr={ssr}
      responsive={responsive}
      customTransition="all 1s"
      transitionDuration={1000}
      containerClass={containerClass}
      dotListClass="carousel-dot-list-style"
      itemClass={itemClass}
      shouldResetAutoplay={shouldResetAutoplay}
      arrows={arrows}
      centerSlide={centerSlide}
      centerMode={centerMode}
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      removeArrowOnDeviceType={removeArrowOnDeviceType}
    >
      {children}
    </Carousel>
  );
};

CarouselSlider.defaultProps = {
  swipeable: false,
  draggable: false,
  showDots: false,
  infinite: true,
  autoPlay: false,
  keyBoardControl: true,
  ssr: false,
  autoPlaySpeed: 2000,
  containerClass: 'carousel-container',
  itemClass: 'carousel-slider-item',
  shouldResetAutoplay: false,
  arrows: true,
  centerSlide: false,
  centerMode: false,
};

export default CarouselSlider;
