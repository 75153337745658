/**
 *
 * Inactive Message
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';

const InactiveMessage = (props) => {
  const { inactiveMessages, user } = props;
  const [hideInactiveMessage, setHideInactiveMessage] = useState(false);

  const handleClose = () => {
    setHideInactiveMessage(true);
    localStorage.setItem('hide_inactive_message', 'true');
  }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('hide_inactive_message')) === true) {
      setHideInactiveMessage(true);
    }
  }, [])

  return (
    <>
      {inactiveMessages.length > 0 ? (
        <div className="clslogin-form-blk" style={{display: hideInactiveMessage ? 'none' : 'block'}}>
          <div className="clslogin-form clsresident-form clswhite-box pt-3 mb-3 flip-clock-bg flip-clock-div status-msg">
            <span
                className="float-right"
                onClick={handleClose}
                style={{fontSize: '2em', color: 'grey', position: 'relative', zIndex: 1}}
            >&times;</span>
            <ul className={`ml-2`}>
              {inactiveMessages.map((msg, index) => (
                <li
                  key={`inactive-msg-${index}`}
                  className={`status-msg-txt mt-2`}
                >
                  <div dangerouslySetInnerHTML={
                    user.selected_language === msg.originalLanguage ?
                      { __html: msg.message }
                    :
                      (JSON.parse(msg.translation)?.[user.selected_language]) ?
                        { __html: JSON.parse(msg.translation)?.[user.selected_language]}
                      :
                        { __html: msg.message }
                  } />
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

InactiveMessage.defaultProps = {
  inactiveMessages: [],
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
  }
}

export default connect(mapStateToProps, actions)(InactiveMessage);
