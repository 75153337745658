/**
 *
 * Notification Settings
 *
 */

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from '../../../actions';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { updateUserNotificationPreferences } from '../../../containers/UserNotificationPreferences/actions';

const NotificationSettings = (props) => {
  const {
    user,
    isProfileLoading,
    userNotificationPreferencesAll,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const NOTIFICATION_ROWS = [
    {
      heading: 'New Post Notifications',
      isAvaiableToUser: true,
      subRows: [
        {
          title: 'New Info Post',
          resourceType: 'info',
        },
        {
          title: 'New Discussion Post',
          resourceType: 'discussion',
        },
        {
          title: 'New Survey Post',
          resourceType: 'survey',
        },
      ],
    },
    {
      heading: "Posts you're engaging on",
      isAvaiableToUser: true,
      subRows: [
        {
          title: 'New Comments or Replies',
          resourceType: 'comment',
        },
        {
          title: 'New Reactions',
          resourceType: 'reaction',
        }
      ],
    },
    {
      heading: 'New Community Issue Notifications',
      isAvaiableToUser: true,
      subRows: [
        {
          title: 'New Community Issue becomes visible',
          resourceType: 'communityIssue',
        },
      ],
    },
    {
      heading: "Community Issues you're engaging on",
      isAvaiableToUser: true,
      subRows: [
        {
          title: 'New Comments or Replies',
          resourceType: 'communityIssueComment',
        },
        {
          title: 'New Reactions',
          resourceType: 'reaction',
        }
      ],
    },
    {
      heading: 'General',
      isAvaiableToUser: true,
      subRows: [
        {
          title: 'New Government Official Joining',
          resourceType: 'official',
        }
      ],
    },
    {
      heading: 'For Government Accounts',
      isAvaiableToUser: false,
      subRows: [
        {
          title: 'Engagement from residents on your posts',
          resourceType: 'forOfficial',
        }
      ],
    }
  ]

  return (
    <div className="clsfilter-modal-content">
      {isProfileLoading === true ? <LoadingIndicator position={`fixed`} /> : ''}
      <div className='notification-setting-grid'>
        <div></div>
        <div>{t('Push')}</div>
        <div>{t('Weekly Email')}</div>
      </div>
      {
        NOTIFICATION_ROWS.map((entry) => {
          return (
            <>
              { !(!entry.isAvaiableToUser && user.roleName !== 'PUBLICOFFICIAL' && user.roleName !== 'ADMIN') &&
                <>
                  {t(entry.heading)}
                  {
                    entry.subRows.map((subEntry) => {
                      return (
                        <div className='notification-setting-grid'>
                          <div>{t(subEntry.title)}</div>
                          <div>
                            <div
                              className="custom-switch-allign"
                            >
                              <div className="custom-control custom-switch clsswitch-large">
                                <input
                                  type="checkbox"
                                  name="pushNotification"
                                  className="custom-control-input"
                                  id={`customPushSwitch-push, ${subEntry.resourceType}`}
                                  value={userNotificationPreferencesAll[`push, ${subEntry.resourceType}`]}
                                  onClick={(e) =>
                                    dispatch(updateUserNotificationPreferences({
                                      receiver: 'push',
                                      resourceType: subEntry.resourceType,
                                      setting: !(userNotificationPreferencesAll[`push, ${subEntry.resourceType}`])
                                    }))
                                  }
                                  checked={userNotificationPreferencesAll[`push, ${subEntry.resourceType}`]}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customPushSwitch-push, ${subEntry.resourceType}`}
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              className="custom-switch-allign"
                            >
                              <div className="custom-control custom-switch clsswitch-large">
                                <input
                                  type="checkbox"
                                  name="pushNotification"
                                  className="custom-control-input"
                                  id={`customPushSwitch-email, ${subEntry.resourceType}`}
                                  value={userNotificationPreferencesAll[`email, ${subEntry.resourceType}`]}
                                  onClick={(e) =>
                                    dispatch(updateUserNotificationPreferences({
                                      receiver: 'email',
                                      resourceType: subEntry.resourceType,
                                      setting: !(userNotificationPreferencesAll[`email, ${subEntry.resourceType}`])
                                    }))
                                  }
                                  checked={userNotificationPreferencesAll[`email, ${subEntry.resourceType}`]}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customPushSwitch-email, ${subEntry.resourceType}`}
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              }
            </>
          )
        })
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    isProfileLoading: state.account.isProfileLoading,
    userNotificationPreferencesAll: state.userNotificationPreferences.all,
  };
};

export default connect(mapStateToProps, actions)(NotificationSettings);
