/*
 *
 * Individual Second Step Form
 *
 */

import React from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import { usePlacesWidget } from 'react-google-autocomplete';
import VerifyStep from './verifyStep';
import CivicModal from '../../Common/CivicModal';
import WhyNeed from './whyNeed';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import { GOOGLE_PLACES_API_KEY } from '../../../constants';
import Tooltip from '../../Common/Tooltip';
import { useTranslation } from 'react-i18next';

const IndividualSecondStep = (props) => {
  const {
    registerFormData,
    registerChange,
    fillAddress,
    registerFormErrors,
    register,
    isRegisterLoading,
  } = props;

  const { t } = useTranslation();

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) => {
      fillAddress(ref, place.address_components);
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry'],
    },
  });

  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    register();
  };

  const hashUrl = '#';

  return (
    <>
      <Notification module={'register'} />
      {isRegisterLoading && <LoadingIndicator position={`fixed`} />}
      <VerifyStep step={1} />
      <div className="form-row">
        <label htmlFor="">
          {t(`Name`)}{' '}
          <span className="clswhy-need">
            <a href={hashUrl} data-toggle="modal" data-target="#why-name" id="regevent_why_name">
              {t(`Why do you need this?`)}
            </a>
          </span>
        </label>
        <div className="form-group col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder={t(`First`)}
            name={'firstName'}
            value={registerFormData.firstName}
            onChange={(e) => registerChange(e.target.name, e.target.value)}
            autoComplete={'no'}
            id="regevent_firstname"
          />
          {typeof registerFormErrors !== 'undefined' &&
            registerFormErrors.firstName && (
              <div className="clsinvalid">
                {t(registerFormErrors?.['firstName'])}
              </div>
            )}
        </div>
        <CivicModal
          id={`why-name`}
          title={t(`Why do you need this?`)}
          modalClass={`clswhy-need-modal`}
        >
          <WhyNeed type={`name`} />
        </CivicModal>
        <div className="form-group col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder={t(`Last`)}
            name={'lastName'}
            value={registerFormData.lastName}
            onChange={(e) => registerChange(e.target.name, e.target.value)}
            autoComplete={'no'}
            id="regevent_lastname"
          />
          {typeof registerFormErrors !== 'undefined' &&
            registerFormErrors.lastName && (
              <div className="clsinvalid">{t(registerFormErrors?.['lastName'])}</div>
            )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="">
          {t(`Address`)}{' '}
          <span className="clswhy-need">
            <a href={hashUrl} data-toggle="modal" data-target="#why-address" id="regevent_why_address">
              {t(`Why do you need this?`)}
            </a>
          </span>
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t(`Street (incl. number and no P.O. Box)`)}
          name={'address'}
          defaultValue={registerFormData.address}
          onChange={(e) => registerChange(e.target.name, e.target.value)}
          ref={ref}
          style={{ width: '100%' }}
          onKeyDown={preventSubmit}
          id="regevent_address"
        />
        {typeof registerFormErrors !== 'undefined' &&
          registerFormErrors.address && (
            <div className="clsinvalid">{t(registerFormErrors?.['address'])}</div>
          )}
      </div>
      <CivicModal
        id={`why-address`}
        title={t(`Why do you need this?`)}
        modalClass={`clswhy-need-modal`}
      >
        <WhyNeed type={`address`} />
      </CivicModal>
      <div id={`reigster-addres-fields`}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder={t(`City`)}
              name={'city'}
              value={registerFormData.city}
              onChange={(e) => registerChange(e.target.name, e.target.value)}
              disabled={true}
            />
            {typeof registerFormErrors !== 'undefined' &&
              registerFormErrors.city && (
                <div className="clsinvalid">{t(registerFormErrors?.['city'])}</div>
              )}
          </div>
          <div className="form-group col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder={t(`Zip`)}
              name={'zipCode'}
              value={registerFormData.zipCode}
              onChange={(e) => registerChange(e.target.name, e.target.value)}
              disabled={true}
            />
            {typeof registerFormErrors !== 'undefined' &&
              registerFormErrors.zipCode && (
                <div className="clsinvalid">
                  {t(registerFormErrors?.['zipCode'])}
                </div>
              )}
          </div>
        </div>
        <div className="form-group">
          <RegionDropdown
            name="state"
            className="form-control"
            placeholder={t(`State`)}
            country={'United States'}
            valueType="short"
            defaultOptionLabel="Select State"
            value={registerFormData.state}
            onChange={(val) => registerChange('state', val)}
            disabled={true}
          />
          {typeof registerFormErrors !== 'undefined' &&
            registerFormErrors.state && (
              <div className="clsinvalid">{t(registerFormErrors?.['state'])}</div>
            )}
        </div>
      </div>
      <Tooltip
        targetId={`reigster-addres-fields`}
        targetStatus={true}
        styleClass={'react-tooltip'}
        message={t(
          `You can update the address fields by selecting the address auto suggestion listed in Street text box.`,
        )}
      />
      <div className="form-group">
        <label>
          {t(`Phone Number`)}
          <span className="clswhy-need">
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target="#why-phonenumber"
              id="regevent_why_phone"
            >
              {t(`Why do you need this?`)}
            </a>
          </span>
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t(`Phone Number`)}
          name={'phoneNumber'}
          value={registerFormData.phoneNumber}
          onChange={(e) => registerChange(e.target.name, e.target.value)}
          onFocus={(e) => registerChange(e.target.name, e.target.value)}
          id="regevent_phone"
        />
        {typeof registerFormErrors !== 'undefined' &&
          registerFormErrors.phoneNumber && (
            <div className="clsinvalid">
              {t(registerFormErrors?.['phoneNumber'])}
            </div>
          )}
      </div>
      <CivicModal
        id={`why-phonenumber`}
        title={t(`Why do you need this?`)}
        modalClass={`clswhy-need-modal`}
      >
        <WhyNeed type={`phonenumber`} />
      </CivicModal>
      <div className="text-center">
        <button
          type="button"
          className="btn  clsyellow-btn"
          onClick={handleSubmit}
          id="regevent_step2_continue"
        >
          {t(`Continue to Verification`)}
        </button>
      </div>
    </>
  );
};

export default IndividualSecondStep;
