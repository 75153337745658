import React, { useEffect, useState } from "react";
import Tooltip from "../../Common/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import actions from "../../../actions";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_PLACES_API_KEY } from "../../../constants";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import es from 'date-fns/locale/es';
import cn from 'date-fns/locale/zh-CN';
import tl from 'date-fns/locale/fi';
import vi from 'date-fns/locale/vi';
import ko from 'date-fns/locale/ko';
import ru from 'date-fns/locale/ru';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Modal } from "react-bootstrap";
import SelectType from "./selectType";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const ResidentStepOne = (props) => {

  const {
    registration,
    registerChange,
    registrationSubmit,
    account,
    guestProfileAddressUpdate,
    getInactiveStatusMesages,
    editProfileChange,
    registrationFormErrors,
    registrationFormSubmissionErrors,
    isSignUpButtonClicked
  } = props;

  const { t } = useTranslation();

  const [showGuestAddressPicker, setShowGuestAddressPicker] = useState(true);
  const [date, setDate] = useState(undefined);

  useEffect(() => {
    setShowGuestAddressPicker(false);
  }, [account.editProfileData.address]);

  const storeAddress = (address) => {
    const storedAddress = JSON.stringify(address);

    if (typeof address.name === 'undefined' && address) {
      localStorage.setItem('guest_address', storedAddress);

      formatAddress(address);
      guestProfileAddressUpdate(address);
      // getInactiveStatusMesages();

      setShowGuestAddressPicker(false);
    }
  };

  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handlePickGuestAddress = () => {
    setShowGuestAddressPicker(true);
  };

  const formatAddress = (address) => {
    let addr = {};

    editProfileChange('zip_code', '');

    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          addr.address = component.long_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'route': {
          addr.address = addr.hasOwnProperty('address')
            ? addr.address + ' ' + component.short_name
            : component.short_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'postal_code': {
          addr.zip_code = component.long_name;
          editProfileChange('zip_code', addr.zip_code)
          break;
        }
        case 'locality':
          addr.city = component.long_name;
          editProfileChange('city', addr.city)
          break;
        case 'administrative_area_level_1': {
          addr.state = component.short_name;
          editProfileChange('state', addr.state)
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  const getRegistrationFormError = (field) => {
    if (typeof registrationFormSubmissionErrors === 'string') {
      return registrationFormSubmissionErrors.toLowerCase().includes(field) ? registrationFormSubmissionErrors : '';
    }
    if (Array.isArray(registrationFormSubmissionErrors)) {
      return registrationFormSubmissionErrors.find((error) => {
        return error.toLowerCase().includes(field);
      });
    }

    return '';
  };

  const emailError = getRegistrationFormError('email');
  const phoneError = getRegistrationFormError('phone');

  const getDatePickerLocaleText = (languageCode) => {
    if (languageCode === 'ES') {
      return es;
    }
    if (languageCode === 'CN') {
      return cn;
    }
    if (languageCode === 'TL') {
      return tl;
    }
    if (languageCode === 'VI') {
      return vi;
    }
    if (languageCode === 'KO') {
      return ko;
    }
    if (languageCode === 'RU') {
      return ru;
    }
    return null;
  }

  return <>
    <Modal.Header closeButton={true}>
      <Modal.Title className="w-100 text-center">
        {registration.registerFormData.step === 1 && isSignUpButtonClicked ?
          t(`Register your free resident account`) :
          t(`Please create your account to continue scrolling!`)}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {registration.registerFormData.step === 1 && <SelectType />}

      <div className="First-Step">
        <div className="form-group col-md-12">
          <input
            type="text"
            className="form-control"
            placeholder={t(`Email Address`)}
            name={'email'}
            value={registration.registerFormData.email}
            onChange={(e) => registerChange(e.target.name, e.target.value)}
            autoComplete={'no'}
            id="regevent_email"
          />
          {((typeof registrationFormErrors !== 'undefined' && registrationFormErrors.email) || emailError) && (
            <div className="clsinvalid">{t(emailError) || t(registrationFormErrors?.['email'])}</div>)}
        </div>

        <div className="form-group col-md-12">
          <div className="position-relative">
            <input
              type="password"
              className="form-control"
              placeholder={t(`Password`)}
              name={'password'}
              value={registration.registerFormData.password}
              onChange={(e) => registerChange(e.target.name, e.target.value)}
              autoComplete={'no'}
              id="regevent_password"
            />
            <Tooltip
              targetId={'passwordTooltip'}
              message={t(
                'Your password should contain at least six characters with at least one lowercase letter, one uppercase letter, and one number.',
              )}
              parentDivClass={'tooltip-position-fix'}
            />
          </div>
          {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.password && (
            <div className="clsinvalid">{t(registrationFormErrors?.['password'])}</div>
          )}
        </div>

        <div className="form-group col-md-12">
          <div className="position-relative">
            <LocalizationProvider
              adapterLocale={getDatePickerLocaleText(localStorage.getItem('lng'))}
              // dateAdapter={AdapterDayjs}
            >
              <DesktopDatePicker
                value={date}
                onChange={(date) => {
                  setDate(date)
                  if (date) registerChange('dob', date.format('YYYY-MM-DD'))
                }}
                sx={{
                  width: '90%',
                  borderRadius: '10px',
                  input: {
                    padding: '15px',
                    "&::placeholder": {
                      opacity: 1,
                    },
                    fontSize: '14px',
                    fontFamily: 'latoregular',
                  },
                  '& label': {
                    fontSize: '14px',
                    fontFamily: 'latoregular',
                    color: '#566677'
                  },
                  '& .MuiOutlinedInput-root': {
                    color: '#cccccc',
                    borderColor: '#cccccc',
                    borderWidth: '1px',
                    '& fieldset': {
                      borderColor: '#cccccc',
                      borderWidth: '1px',
                    },
                  }
                }}
                id="regevent_birthday"
                label='Birthday'
              />
            </LocalizationProvider>
            <Tooltip
              targetId={'regevent_birthday'}
              message={t('The Children\'s Online Privacy Protection Rule ("COPPA") requires online services, including ours, to ensure all users are above 13 years of age.',)}
              id="regevent_why_birthday"
              parentDivClass={'tooltip-position-fix'}
            />
          </div>
          {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.dob && (
            <div className="clsinvalid">{t(registrationFormErrors?.['dob'])}</div>)}
        </div>

        <div className="form-group col-md-12">
          <div className="position-relative">
            <div className="input-icon">
              <img
                width="30px"
                src={
                  require('../../../assets/img/us-flag.svg')
                    .default
                }
                alt="US flag"
              />
            </div>
            <input
              type="tel"
              className="form-control pl-5"
              placeholder={t(`Cell Phone Number`)}
              name={'phoneNumber'}
              value={registration.registerFormData.phoneNumber}
              onChange={(e) => registerChange(e.target.name, e.target.value)}
              onFocus={(e) => registerChange(e.target.name, e.target.value)}
              id="regevent_phone"
              inputmode="numeric"
              pattern="[0-9]*"
            />
            <Tooltip
              targetId={'regevent-phone'}
              message={t(
                'We use your cell number for account recovery purposes and to automatically confirm your identity with your carrier. Your phone number will never be shared.',
              )}
              parentDivClass={'tooltip-position-fix'}
            />
          </div>
          {((typeof registrationFormErrors !== 'undefined' && registrationFormErrors.phone_number) || phoneError) && (
            <div className="clsinvalid">{t(phoneError) || t(registrationFormErrors?.['phone_number'])}</div>)}
        </div>

        <div className="form-group col-md-12">
          <>
            {(account.editProfileData.address === '' || showGuestAddressPicker) ?
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Autocomplete
                    apiKey={GOOGLE_PLACES_API_KEY}
                    className="form-control-plaintext"
                    placeholder={t(`Your residential address`)}
                    type="text"
                    name="location_search"
                    defaultValue={account.editProfileData.address ?
                      account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                      account.editProfileData.state + ', ' + account.editProfileData.zip_code
                      : ''}
                    onKeyDown={preventSubmit}
                    autoComplete="off"
                    onPlaceSelected={(place) => {
                      storeAddress(place);
                    }}
                    options={{
                      types: ['address'],
                      componentRestrictions: { country: 'us' },
                      fields: ['address_components', 'formatted_address'],
                    }}
                    style={{
                      border: '1px solid #ced4da',
                      borderRadius: '10px',
                      height: '50px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      fontSize: '14px',
                      lineHeight: '1.5',
                      fontWeight: 400,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 25,
                    }}
                  >
                    <Tooltip
                      targetId={'location_search'}
                      message={t('To map you to all your political districts, a precise residential address is required. Sometimes even your neighbor, who lives on the same street, might be part of different districts.',)}
                      id="location_search_why"
                      parentDivClass={'tooltip-position-fix'}
                      style={{
                        marginRight: 5,
                      }}
                    />
                  </div>
                  {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.address && (
                    <div className="clsinvalid">{t(registrationFormErrors?.['address'])}</div>)}
                </div>
              </div> : <div style={{
                marginBottom: '16px',
                backgroundColor: '#ffffff',
                borderRadius: '13px'
              }}>
                {account.editProfileData.address === '' ? <>{t('No address provided.')}</> : (
                  <>
                    <span
                      style={{ fontWeight: 'bold' }}>{t(`You're creating an account for`)}<br />
                    </span>
                    <span style={{ textDecoration: 'underline' }}>{account.editProfileData.address ?
                      account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                      account.editProfileData.state + ', ' + account.editProfileData.zip_code
                      : ''}</span>
                  </>
                )}
                <span
                  onClick={handlePickGuestAddress}
                  style={{
                    color: '#ffaa33',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    float: 'right'
                  }}>{t(`change`)}</span>
                {!(/^\d+$/.test(account.editProfileData.address.split(' ')[0])) &&
                  <div className="clsinvalid">{t(`Please ensure this is your full residential address, incl. street number`)}</div>}
              </div>
            }
          </>
        </div>
        <div className="form-group col-md-12 text-center">
          <button
            type="button"
            id="regevent_submit_form"
            className="btn clsyellow-btn"
            onClick={registrationSubmit}
          >{t(`Create my account`)}</button>
        </div>

        <div className="form-group col-md-12">
          <p className="clsagree-text col-md-12">
            {t(`We'll be sending you a one-time verification code. Msg & Data rates may apply. 1 message per request.`)}
          </p><br />
          <p className="clsagree-text col-md-12">
            {t(`By registering, you agree to our`)}{' '}
            <a
              href={`https://about.civicbell.com/policies/resident-terms-of-services`}
              target={'_blank'}
              rel="noopener noreferrer"
              id="regevent_legal_tos"
            >
              {t(`Resident Terms of Services`)}
            </a>
            , {t(`our`)}{' '}
            <a
              href={`https://about.civicbell.com/policies/rules-of-engagements-for-residents`}
              target={'_blank'}
              rel="noopener noreferrer"
              id="regevent_legal_roe"
            >
              {t(`Rules of Engagements`)}
            </a>
            , {t(`and`)}{' '}
            <a
              href={`https://about.civicbell.com/policies/privacy-policy`}
              target={'_blank'}
              rel="noopener noreferrer"
              id="regevent_legal_privacy"
            >
              {t(`Privacy Policy`)}
            </a>
            .
          </p>
        </div>
      </div>
    </Modal.Body>
  </>;
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    registration: state.registration,
    registrationFormErrors: state.registration.formErrors,
    registrationFormSubmissionErrors: state.registration.formSubmissionErrors,
    isSignUpButtonClicked: state.registration.isSignUpButtonClicked,
  };
};

export default connect(mapStateToProps, actions)(ResidentStepOne);
