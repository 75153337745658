/*
 *
 * Optional Informaiton Form
 *
 */

import React from 'react';
import SelectOption from '../../Common/SelectOption';
import {
  highEduOptions,
  employmentStatusOptions,
  martialOptions,
  affiliateOptions,
  religionOptions,
  raceOptions,
} from '../../../containers/Register/constants';
import {
  handleOptionValue,
  handleMultiOptionValue,
} from '../../../utils/helper';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const OptionalInformations = (props) => {
  const {
    optionalInfoData,
    optionalInfoChange,
    optionalFormSubmit,
    optionalInfoErrors,
    isProfileLoading,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="Fourth-Step clsresident-form">
      {isProfileLoading === true ? <LoadingIndicator position={`fixed`} /> : ''}
      {typeof optionalInfoData.id !== 'undefined' ? (
        <>
          <div className="clsoptional-info mb-3">
            <h4 className="pb-1 mb-2">{t(`Optional Information`)}</h4>
            <p className="optional-information pb-3">
              {t(
                `This information will help your public officials better understand you.`,
              )}{' '}
            </p>
            <p>{t(`What you provide here will only be used anonymously.`)}</p>
          </div>
          <div className="form-group">
            <label>{t(`Highest Education Degree`)}</label>
            <SelectOption
              error={optionalInfoErrors['highestEducationDegree']}
              name={'highestEducationDegree'}
              placeholder={t('Highest Education Degree')}
              value={handleOptionValue(
                highEduOptions,
                optionalInfoData.highestEducationDegree,
              )}
              options={highEduOptions}
              handleSelectChange={(value) => {
                optionalInfoChange('highestEducationDegree', value);
              }}
            />
          </div>
          <div className="form-group">
            <label>{t(`Employment Status`)}</label>
            <SelectOption
              error={optionalInfoErrors['employmentStatus']}
              name={'employmentStatus'}
              placeholder={t('Employment Status')}
              value={handleOptionValue(
                employmentStatusOptions,
                optionalInfoData.employmentStatus,
              )}
              options={employmentStatusOptions}
              handleSelectChange={(value) => {
                optionalInfoChange('employmentStatus', value);
              }}
            />
          </div>
          <div className="form-group">
            <label>Race</label>
            <SelectOption
              error={optionalInfoErrors['race']}
              name={'race'}
              placeholder={t('Race')}
              value={handleMultiOptionValue(raceOptions, optionalInfoData.race)}
              options={raceOptions}
              multi={true}
              handleSelectChange={(value) => {
                optionalInfoChange('race', value);
              }}
            />
          </div>
          <div className="form-group">
            <label>{t(`Marital Status`)}</label>
            <SelectOption
              error={optionalInfoErrors['martialStatus']}
              name={'martialStatus'}
              placeholder={'Marital Status'}
              value={handleOptionValue(
                martialOptions,
                optionalInfoData.martialStatus,
              )}
              options={martialOptions}
              handleSelectChange={(value) => {
                optionalInfoChange('martialStatus', value);
              }}
            />
          </div>
          <div className="form-group">
            <label>{t(`Political Orientation`)}</label>
            <SelectOption
              error={optionalInfoErrors['politicalAffiliation']}
              name={'politicalAffiliation'}
              placeholder={t('Political Orientation')}
              value={handleOptionValue(
                affiliateOptions,
                optionalInfoData.politicalAffiliation,
              )}
              options={affiliateOptions}
              handleSelectChange={(value) => {
                optionalInfoChange('politicalAffiliation', value);
              }}
            />
          </div>
          <div className="form-group">
            <label>{t('Religious Preference')}</label>
            <SelectOption
              error={optionalInfoErrors['religion']}
              name={'religion'}
              placeholder={t('Religion')}
              value={handleOptionValue(
                religionOptions,
                optionalInfoData.religion,
              )}
              options={religionOptions}
              handleSelectChange={(value) => {
                optionalInfoChange('religion', value);
              }}
            />
          </div>
          <div className="text-center pt-3">
            <button
              type="button"
              className="btn  clsyellow-btn"
              onClick={() => {
                optionalFormSubmit();
              }}
            >
              {t(`Save Changes`)}
            </button>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default OptionalInformations;
