import React from "react";
import {connect} from "react-redux";
import actions from "../../../actions";
import {useTranslation} from "react-i18next";

const SelectType = (props) => {

    const {
        registration,
        registerChange,
        resetRegisterState
    } = props;

    const {t} = useTranslation();

    const handleOnChange = (e) => {
        resetRegisterState();
        registerChange(e.target.name, e.target.checked);
    }

    return <div className="form-group col-md-12">
        <div className="toggle-btns">
            <div className="toggle-btn">
                <span className="toggle-btn__label-left">{t(`Resident`)}</span>
                <input
                    type="checkbox"
                    id="toggle-signUpType"
                    name="signUpType"
                    value={registration.registerFormData.signUpType}
                    checked={registration.registerFormData.signUpTypeBoolean}
                    onChange={(e) => handleOnChange(e)}
                />
                <label htmlFor="toggle-signUpType" style={{marginBottom: '0'}}/>
                <span className="toggle-btn__label-right">{t(`Government official`)}</span>
            </div>
        </div>
    </div>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration
    };
};

export default connect(mapStateToProps, actions)(SelectType);
