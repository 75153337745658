/*
 *
 * Login actions
 *
 */

import axios from 'axios';
import qs from 'qs';
import { push } from 'connected-react-router';
import { BASE_API_URL, API_CLIENT_ID } from '../../constants';
import { addNotification } from '../Notification/actions';
import {
  LOGIN_CHANGE,
  LOGIN_RESET,
  SET_LOGIN_LOADING,
  SET_LOGIN_FORM_ERRORS,
  SET_LOGIN_SUBMITTING,
  SET_TAB,
  RESET_APP,
  SET_RESEND_CONFIRMATION,
} from './constants';

import {
  setAuth,
  clearAuth,
  resetApp,
  initiativeUrl,
  setLoadingMsgStatus, setSilentUser,
} from '../Authentication/actions';
import setToken from '../../utils/token';
import { allFieldsValidation } from '../../utils/validation';
import { guestProfileRegistration } from '../Dashboard/actions';
import { setStep, resetRegisterState } from "../RegisterFlow/actions";
import {SET_INACTIVE_MESSAGES} from "../Dashboard/constants";

export const resetLoginState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const loginChange = (name, value) => {
  return (dispatch, getState) => {
    let formData = {};
    formData[name] = value;

    dispatch({
      type: LOGIN_CHANGE,
      payload: formData,
    });

    const errors = getState().login.formErrors || {};
    delete errors[name];

    dispatch({ type: SET_LOGIN_FORM_ERRORS, payload: { ...errors } });
  }
};

export const setTab = (value) => {
  return {
    type: SET_TAB,
    payload: value,
  };
};

export const login = (prevUrl) => {
  return async (dispatch, getState) => {
    const rules = {
      username: 'required|email',
      password: 'required|min:6',
    };

    let user = getState().login.loginFormData;

    const { isValid, errors } = allFieldsValidation(user, rules, {
      'required.username': 'Email is required.',
      'email.username': 'Email format is invalid.',
      'required.password': 'Password is required.',
      'min.password': 'Password must be at least 6 characters.',
    });

    if (!isValid) {
      return dispatch({ type: SET_LOGIN_FORM_ERRORS, payload: errors });
    }
    dispatch({ type: SET_LOGIN_FORM_ERRORS, payload: {} });
    dispatch({ type: SET_LOGIN_SUBMITTING, payload: true });
    dispatch({ type: SET_LOGIN_LOADING, payload: true });

    user = {
      ...user,
      grant_type: 'password',
      username: user.username.toLowerCase(),
    };
    /* Statically set the username and password. We should remove them after completed our testing. */
    /*user = {username: 'devclient@gmail.com', password: 'dev_user_password', grant_type:'password'};*/
    await axios
      .post(BASE_API_URL + '/login', qs.stringify(user), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: API_CLIENT_ID,
        },
      })
      .then((response) => {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        localStorage.setItem('email', user.username.toLowerCase());

        localStorage.removeItem('guest_user');
        localStorage.removeItem('guest_address');
        dispatch(initiativeUrl(prevUrl));
        if (response.data.roleId === 2) {
          dispatch(setLoadingMsgStatus(response.data.isFirstLogin));
        }

        setToken(response.data.access_token);

        dispatch(setAuth());

        dispatch({ type: LOGIN_RESET });
        dispatch({type: SET_INACTIVE_MESSAGES, payload: []})
      })
      .catch((error) => {
        if (
          error.response.data.error ===
          'Click the verification link sent to your registered email'
        ) {
          dispatch({ type: SET_RESEND_CONFIRMATION, payload: true });
        } else if (error.response.data.error.includes('Invalid grant')) {
          dispatch(
            addNotification({
              type: 'error',
              message: 'Invalid username or password',
              module: 'login',
            }),
          );
          dispatch({ type: SET_LOGIN_FORM_ERRORS, payload: { password: 'Invalid username or password' } });
        } else if (error.response.data.error) {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'login',
            }),
          );
          dispatch({ type: SET_LOGIN_FORM_ERRORS, payload: { username: error.response.data.error } });
        }
      })
      .finally(() => {
        dispatch({ type: SET_LOGIN_SUBMITTING, payload: false });
        dispatch({ type: SET_LOGIN_LOADING, payload: false });
      });
  };
};

export const resendEmailConfirmation = () => {
  return async (dispatch, getState) => {
    const rules = {
      username: 'required|email',
    };

    let user = getState().login.loginFormData;

    const { isValid, errors } = allFieldsValidation(user, rules, {
      'required.username': 'Email is required.',
      'email.username': 'Email format is invalid.',
    });

    if (!isValid) {
      return dispatch({ type: SET_LOGIN_FORM_ERRORS, payload: errors });
    }
    dispatch({ type: SET_LOGIN_FORM_ERRORS, payload: {} });
    dispatch({ type: SET_LOGIN_SUBMITTING, payload: true });
    dispatch({ type: SET_LOGIN_LOADING, payload: true });

    await axios
      .post(BASE_API_URL + '/resendverifylink', {
        email: user.username.toLowerCase(),
        password: user.password,
      })
      .then((response) => {
        dispatch({ type: SET_RESEND_CONFIRMATION, payload: false });
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.data,
            module: 'login',
          }),
        );
        dispatch({ type: LOGIN_RESET });
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'login',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_LOGIN_SUBMITTING, payload: false });
        dispatch({ type: SET_LOGIN_LOADING, payload: false });
      });
  };
};

export const signOut = (message = 'You have signed out!') => {
  return (dispatch, getState) => {
    dispatch(clearAuth());
    dispatch(resetApp());

    dispatch(guestProfileRegistration());
    dispatch(setSilentUser());
    dispatch(setStep(1));
    dispatch(resetRegisterState());
    // dispatch(push('/login'));
    dispatch(push('/dashboard/civicfeed/'));

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('registration_finished');
    localStorage.removeItem('guest_address');

    dispatch(
      addNotification({ type: 'success', message: message, module: 'login' }),
    );
  };
};

export const populateLoginData = (data) => {
  return async (dispatch) => {
    await dispatch({type: LOGIN_CHANGE, payload: data});
  }
}