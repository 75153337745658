import React, {useEffect, useState} from "react";
import OfficialStepOne from "./officialStepOne";
import OfficialStepTwo from "./officialStepTwo";
import OfficialStepThree from "./officialStepThree";
import OfficialStepFour from "./officialStepFour";
import ResidentStepOne from "./residentStepOne";
import ResidentStepTwo from "./residentStepTwo";
import ResidentStepThree from "./residentStepThree";
import ResidentStepFour from "./residentStepFour";
import ResidentStepFive from "./residentStepFive";
import ResidentStepSix from "./residentStepSix";
import actions from "../../../actions";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";

const RegisterFlow = (props) => {

    const {
        registration,
        isRegistrationModalOpen,
        setRegistrationModalOpen
    } = props;

    const [backdrop, setBackdrop] = useState(true);
    const [keyboard, setKeyboard] = useState(true);
    const [showCloseButton, setShowCloseButton] = useState(true);

    const handleBackdrop = () => {
        setBackdrop('static');
        setKeyboard(false);
        setShowCloseButton(false);
    };

    const closeRegistrationModal = () => {
        setRegistrationModalOpen(false);
    };

    useEffect(() => {
        if (registration.registerFormData.step > 1) {
            handleBackdrop();
        }
    }, [registration.registerFormData.step]);

    return <>
        <Modal
            show={isRegistrationModalOpen}
            onHide={() => {
                setRegistrationModalOpen(false)
            }}
            backdrop={backdrop}
            keyboard={keyboard}
            centered>
            {!registration.registerFormData.signUpTypeBoolean ? (
                <div className="resident">
                    {registration.registerFormData.step === 1 ? <ResidentStepOne/> :
                        registration.registerFormData.step === 2 ?
                            <ResidentStepTwo showCloseButton={showCloseButton}/> :
                            registration.registerFormData.step === 3 ?
                                <ResidentStepThree showCloseButton={showCloseButton}/> :
                                registration.registerFormData.step === 4 ?
                                    <ResidentStepFour showCloseButton={showCloseButton}/> :
                                    registration.registerFormData.step === 5 ?
                                        <ResidentStepFive showCloseButton={showCloseButton}/> :
                                        registration.registerFormData.step === 6 ?
                                            <ResidentStepSix showCloseButton={showCloseButton} closeRegistrationModal={closeRegistrationModal} /> :
                                            <ResidentStepOne/>}
                </div>
            ) : (
                <div className="official">
                    {registration.registerFormData.step === 1 ? <OfficialStepOne/> :
                        registration.registerFormData.step === 2 ?
                            <OfficialStepTwo showCloseButton={showCloseButton}/> :
                            registration.registerFormData.step === 3 ?
                                <OfficialStepThree showCloseButton={showCloseButton}/> :
                                registration.registerFormData.step === 4 ?
                                    <OfficialStepFour showCloseButton={showCloseButton}/> :
                                    <OfficialStepOne/>}
                </div>
            )}
        </Modal>
    </>;
};

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        isRegistrationModalOpen: state.registration.isRegistrationModalOpen
    };
};

export default connect(mapStateToProps, actions)(RegisterFlow);