/**
 *
 * Notification
 *
 */

import React from 'react';

import { connect } from 'react-redux';
import Notifications from '../../components/Common/Notifications';

import actions from '../../actions';

const Notification = (props) => {
  const {
    notifications,
    module,
    removeNotification,
    parentDivClass,
    childDivClass,
  } = props;

  return (
    <Notifications
      notifications={notifications}
      module={module}
      removeNotification={removeNotification}
      parentDivClass={parentDivClass}
      childDivClass={childDivClass}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
  };
};

Notification.defaultProps = {
  parentDivClass: '',
  childDivClass: '',
};

export default connect(mapStateToProps, actions)(Notification);
