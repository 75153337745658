/**
 *
 * Civic Filter
 *
 */

import React, { useState, useEffect } from 'react';
import SearchBox from './searchBox';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { getCalendarUser } from '../../../utils/helper';

const ExploreCalendar = (props) => {
  const {
    calendar,
    calendarSelected,
    eventCalendarChange,
    getExploreCalendars,
    isOptionLoading,
  } = props;
  const hashUrl = '#';
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading === true) {
      const getCalendars = async () => {
        await getExploreCalendars().then(async () => {
          setLoading(false);
        });
      };
      getCalendars();
    }
  }, [loading, getExploreCalendars]);

  return (
    <div className="clsevents-calendar-content">
      <SearchBox {...props} type={`calendar`} />
      {isOptionLoading && <LoadingIndicator />}
      <ul>
        {Object.keys(calendar).map((type, index) => (
          <li key={index}>
            <h5 className="mb-2 pb-1">{type}</h5>
            {calendar[type].map((item, itemIndex) => (
              <div key={itemIndex} className="clsevents-calendar-add-box mb-2">
                <span>
                  {item.user ? getCalendarUser(item.user) : item.name}
                </span>
                <span>
                  {calendarSelected.includes(item.id) ? (
                    <a
                      href={hashUrl}
                      onClick={() => {
                        eventCalendarChange(item.id);
                      }}
                    >
                      <img
                        src={require('../../../assets/img/check.svg').default}
                        alt={t(`Added`)}
                        width="15"
                      />{' '}
                      {t(`Added`)}
                    </a>
                  ) : (
                    <a
                      href={hashUrl}
                      onClick={() => {
                        eventCalendarChange(item.id);
                      }}
                    >
                      <img
                        src={
                          require('../../../assets/img/add-plus.svg').default
                        }
                        alt={t(`Add`)}
                        width="12"
                      />{' '}
                      {`Add`}
                    </a>
                  )}
                </span>
              </div>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ExploreCalendar;
