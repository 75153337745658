/*
 *
 * reducers.js
 * reducers configuration
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// import reducers
import notifications from './containers/Notification/reducer';
import applicationReducer from './containers/Application/reducer';
import searchKeyReducer from './containers/Search/reducer';
import homepageReducer from './containers/Homepage/reducer';
import authenticationReducer from './containers/Authentication/reducer';
import loginReducer from './containers/Login/reducer';
import registerReducer from './containers/Register/reducer';
import forgotPasswordReducer from './containers/ForgotPassword/reducer';
import resetPasswordReducer from './containers/ResetPassword/reducer';
import verifyEmailReducer from './containers/VerifyEmail/reducer';
import accountReducer from './containers/Dashboard/reducer';
import engagementReducer from './containers/Engagement/reducer';
import closeEngagementReducer from './containers/CloseEngagement/reducer';
import completeEngagementReducer from './containers/CompleteEngagement/reducer';
import engagementReactionReducer from './containers/Reactions/reducer';
import discussionEngagementReducer from './containers/DiscussionEngagement/reducer';
import civicFeedReducer from './containers/CivicFeed/reducer';
import eventsReducer from './containers/Events/reducer';
import petitionReducer from './containers/Petitions/reducer';
import surveyQuestionReducer from './containers/SurveyQuestions/reducer';
import inviteOtherReducer from './containers/InviteOthers/reducer';
import analyticsReducer from './containers/Analytics/reducer';
import localNewsReducer from './containers/LocalNews/reducer';
import inAppropriateReportReducer from './containers/InAppropriateReport/reducer';
import mapReducer from './containers/CivicMap/reducer';
import pushNotificationReducer from './containers/PushNotification/reducer';
import searchResultReducer from './containers/SearchResult/reducer';
import registrationReducer from "./containers/RegisterFlow/reducer";
import userNotificationPreferencesReducer from './containers/UserNotificationPreferences/reducer';
import CommunityIssuesReducer from './containers/CommunityIssues/reducer';

const createReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    notifications: notifications,
    application: applicationReducer,
    search: searchKeyReducer,
    homepage: homepageReducer,
    authentication: authenticationReducer,
    login: loginReducer,
    register: registerReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    verifyEmail: verifyEmailReducer,
    account: accountReducer,
    engagement: engagementReducer,
    closeEngagement: closeEngagementReducer,
    completeEngagement: completeEngagementReducer,
    engagementReaction: engagementReactionReducer,
    discussionEngagement: discussionEngagementReducer,
    civicFeed: civicFeedReducer,
    event: eventsReducer,
    petition: petitionReducer,
    surveyQuestion: surveyQuestionReducer,
    inviteOther: inviteOtherReducer,
    analytics: analyticsReducer,
    localNews: localNewsReducer,
    inAppropriateReport: inAppropriateReportReducer,
    map: mapReducer,
    pushnotification: pushNotificationReducer,
    searchResult: searchResultReducer,
    registration: registrationReducer,
    userNotificationPreferences: userNotificationPreferencesReducer,
    communityIssues: CommunityIssuesReducer,
  });

export default createReducer;
