/**
 *
 * Helper Functions
 *
 */

import moment from 'moment';
import i18next from 'i18next';
import { LoremIpsum } from "lorem-ipsum";

export const formatPhoneNumber = (value) => {
  // clean the input for any non-digit values.
  if (value.slice(0, 3) === '+1 ') {
    value = value.substring(3);
  } else if (value.slice(0, 2) === '+1') {
    value = value.substring(2);
  } else if (value.slice(0, 1) === '+') {
    value = value.substring(1);
  }

  if (!value) return '+1 ';

  const phoneNumber = value.replace(/[^\d]/g, '');

  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return '+1 ' + phoneNumber;

  if (phoneNumberLength < 7) {
    return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
};

export const handleOptionValue = (options, value) => {
  if (value === null || value === '') {
    return { value: value, label: '' };
  } else if (typeof value === 'object') {
    if (typeof value.value !== 'undefined') {
      return value;
    }
  } else if (
    value !== '' &&
    typeof value !== 'undefined' &&
    Array.isArray(value) === false
  ) {
    value = options
      .filter(function (item) {
        return item.value === value;
      })
      .map(function (item) {
        return { value: item.value, label: item.label };
      });
  }
  return typeof value !== 'undefined' ? value[0] : value;
};

export const loadOptionValue = (options, value) => {
  if (value !== '' && typeof value !== 'undefined') {
    value = options.filter(function (option) {
      return option.value === value;
    });
  }
  return value;
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getSubstring = (sting, limit) => {
  let returnText = sting.length > limit ? sting.substr(0, limit) + '..' : sting;
  return returnText;
};

export const getOptionArray = (options) => {
  var returnarray = [];
  options = options.map((option) => {
    returnarray[option.value] = option.label;
    return option.value;
  });
  return returnarray;
};

// TODO: Event is currently deprecated, but if we 
// reinstate them, need to remove this and replace with daysDiffTxt(...)
export const getEventDateDetail = (event) => {
  var returnText =
    'This event is happening on ' +
    moment
      .utc(event.start_date)
      .tz(moment.tz.guess())
      .format('MMMM Do, YYYY, dddd');
  if (event.recurring_status) {
    let endDate =
      'till ' +
      moment.utc(event.end_date).tz(moment.tz.guess()).format('Do MMM, YYYY');
    switch (event.repetition_type) {
      case 'daily':
        returnText = 'Repeats daily ' + endDate;
        break;
      case 'monthly':
        const endOfMonth = moment
          .utc(event.start_date)
          .tz(moment.tz.guess())
          .endOf('month')
          .format('YYYY-MM-DD');
        if (
          endOfMonth ===
          moment
            .utc(event.start_date)
            .tz(moment.tz.guess())
            .format('YYYY-MM-DD')
        )
          returnText = 'Repeats on last day of every month ' + endDate;
        else
          returnText =
            'Repeats on ' +
            moment.utc(event.start_date).tz(moment.tz.guess()).format('Do') +
            ' of every month ' +
            endDate;
        break;
      case 'weekly':
        returnText =
          'Repeats every week on ' +
          moment.utc(event.start_date).tz(moment.tz.guess()).format('dddd') +
          '<br />' +
          endDate;
        break;
      case 'weekdays':
        returnText =
          'Repeats every week from Monday to Friday <br />' + endDate;
        break;
      default:
        break;
    }
  }
  return returnText;
};

// TODO: Event is currently deprecated, but if we 
// reinstate them, need to remove this and replace with daysDiffTxt(...)
export const getEventNextDate = (event) => {
  let nextDate = moment(
    event.next_date + moment(event.startDate).format(' HH:mm:ss'),
    'YYYY-MM-DD HH:mm:ss',
  ).toDate();
  return moment.utc(nextDate).tz(moment.tz.guess()).toDate();
};

// TODO: Event is currently deprecated, but if we 
// reinstate them, need to remove this and replace with daysDiffTxt(...)
export const getEventRecurringDate = (event) => {
  var nextDate = event.start_date;
  var nearestDate,
    nearestDateTime,
    dayINeed,
    now = moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    startDate = event.start_date,
    startTime = event.start_time;

  if (event.recurring_status) {
    switch (event.repetition_type) {
      case 'daily':
        nearestDate = moment.utc().format('YYYY-MM-DD');
        nearestDateTime = moment.utc(nearestDate + ' ' + startTime);
        nextDate =
          nearestDateTime > now
            ? nearestDate
            : moment.utc(nearestDate).add(1, 'days').format('YYYY-MM-DD');
        break;
      case 'monthly':
        dayINeed = moment.utc(startDate).format('DD');
        var thisMonth = moment.utc().format('YYYY-MM');
        nearestDate = thisMonth + '-' + dayINeed;
        nearestDateTime = moment
          .utc(nearestDate + ' ' + startTime)
          .format('YYYY-MM-DD HH:mm:ss');
        nextDate =
          nearestDateTime > now
            ? nearestDate
            : moment.utc(nearestDate).add(1, 'months').format('YYYY-MM-DD');
        break;
      case 'weekly':
        dayINeed = moment.utc(startDate).day();
        nearestDate = moment.utc().day(dayINeed).format('YYYY-MM-DD');
        nearestDateTime = moment
          .utc(nearestDate + ' ' + startTime)
          .format('YYYY-MM-DD HH:mm:ss');
        nextDate =
          nearestDateTime > now
            ? nearestDate
            : moment.utc(nearestDate).add(1, 'weeks').format('YYYY-MM-DD');
        break;
      case 'weekdays':
        var todayDay = moment.utc().day();
        nearestDate = moment.utc().format('YYYY-MM-DD');
        nearestDateTime = moment.utc(nearestDate + ' ' + startTime);
        if (todayDay > 0 && todayDay < 5)
          nextDate =
            nearestDateTime > now
              ? nearestDate
              : moment.utc(nearestDate).add(1, 'days').format('YYYY-MM-DD');
        else
          nextDate =
            nearestDateTime > now && todayDay === 5
              ? nearestDate
              : moment.utc(nearestDate).day(1).format('YYYY-MM-DD');
        break;
      default:
        break;
    }
  }
  return nextDate;
};

export const getOfficialName = (user) => {
  var returnText = '';
  returnText += user.showMyNameAs
    ? user.show_my_name
    : user.first_name + ' ' + user.last_name;
  return returnText;
};

export const getOfficialPosition = (user) => {
  var returnText = '';

  if (
    user.showMyNameAs !== 'organization' &&
    user.userOfficial &&
    user.userOfficial.position
  )
    returnText +=
      user.userOfficial.position +
      ' ' +
      (user.userOfficial.area ? ' - ' + user.userOfficial.area : '');
  else returnText += user.city ? ' - ' + user.city : '';

  return returnText;
};

export const getEventPublishedBy = (user) => {
  var returnText = '';
  returnText += user.showMyNameAs
    ? user.show_my_name
    : user.first_name + ' ' + user.last_name;
  if (user.showMyNameAs === 'organization') return returnText;

  returnText +=
    user.userOfficial !== undefined &&
    user.userOfficial !== null &&
    user.userOfficial.position !== null &&
    user.userOfficial.position !== ''
      ? ', ' +
        user.userOfficial.position +
        (user.userOfficial.area !== null ? ' - ' + user.userOfficial.area : '')
      : '';
  return returnText;
};

export const getCalendarUser = (user) => {
  var returnText = '';
  if (user.showMyNameAs === 'organization') {
    returnText += user.userOfficial
      ? user.userOfficial.organization_official
        ? user.userOfficial.organization_official.name
        : ''
      : '';
    return returnText;
  }

  if (!returnText) returnText += user.first_name + ' ' + user.last_name;

  returnText +=
    user.userOfficial && user.userOfficial.position
      ? ', ' + user.userOfficial.position
      : '';

  return returnText;
};

export const getOptionKeyFromValue = (options, value) => {
  if (
    value !== '' &&
    typeof value !== 'undefined' &&
    Array.isArray(value) === false
  ) {
    value = options
      .filter(function (item) {
        return item.value === value;
      })
      .map(function (item) {
        return item.label;
      });
  }

  return typeof value[0] === 'object' ? value[0].props.i18nKey : value[0];
};

export const handleMultiOptionValue = (options, values) => {
  let newArray = Object.assign([]);
  if (
    values !== '' &&
    typeof values !== 'undefined' &&
    Array.isArray(values) === true
  ) {
    values.forEach((value, index) => {
      value = options
        .filter(function (item) {
          return item.value === value.value;
        })
        .map(function (item) {
          return { value: item.value, label: item.label };
        });
      newArray.push(value[0]);
    });
  }
  return newArray;
};

export const formatFileSize = (bytes, decimalPoint = 2) => {
  if (bytes === 0) return '0 Bytes';
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// Require: date1 > date2
export const daysDiffUntil = (date1, date2, t, utcDate = true) => {
  let date1Format;
  let date2Format;
  if (utcDate) {
    date1Format = moment(date1).utc().format('YYYY-MM-DD HH:mm:ss');
    date2Format = moment(date2).utc().format('YYYY-MM-DD HH:mm:ss');
  } else {
    date1Format= moment(date1).format('YYYY-MM-DD HH:mm:ss');
    date1Format= moment(date2).format('YYYY-MM-DD HH:mm:ss');
  }

  let hoursDiff = Math.floor(
    moment.duration(moment(date1Format).diff(moment(date2Format))).asHours(),
  );
  let minutesDiff = Math.floor(
    moment.duration(moment(date1Format).diff(moment(date2Format))).asMinutes(),
  );
  let daysDiff = Math.floor(
    moment.duration(moment(date1Format).diff(moment(date2Format))).asDays(),
  );
  let dateDiffTxt = '';


  if (daysDiff >= 0) {
    if (daysDiff === 0) {
      if (hoursDiff < 1) {
        dateDiffTxt = t('<1h left');
      } else {
        dateDiffTxt = t('more hours', { time: Math.floor(hoursDiff) });
      }
    } else {
      dateDiffTxt = t("{{time}} more days", { time: daysDiff });
    }
  }
  return dateDiffTxt;
}

// All other content
export const daysDiffTxt = (dateToBeCompared, t, utcDate = true) => {
  let todayDate = moment.utc().format('YYYY-MM-DD HH:mm:ss');
  let compareDate;
  if (utcDate) {
    compareDate = moment(dateToBeCompared).utc().format('YYYY-MM-DD HH:mm:ss');
  } else {
    compareDate = moment(dateToBeCompared).format('YYYY-MM-DD HH:mm:ss');
  }
 
  let hoursDiff = Math.floor(
    moment.duration(moment(todayDate).diff(moment(compareDate))).asHours(),
  );
  let minutesDiff = Math.floor(
    moment.duration(moment(todayDate).diff(moment(compareDate))).asMinutes(),
  );
  let daysDiff = Math.floor(
    moment.duration(moment(todayDate).diff(moment(compareDate))).asDays(),
  );
  let dateDiffTxt = '';

  if (daysDiff >= 0) {
    if (daysDiff === 0) {
      if (hoursDiff < 1) {
        dateDiffTxt = t('<1h ago');
      } else {
        dateDiffTxt = t('h ago', { time: Math.floor(hoursDiff) });
      }
    } else if (daysDiff == 1) {
      dateDiffTxt = i18next.t("1 day ago");
    } else if (1 < daysDiff && daysDiff < 7) {
      dateDiffTxt = i18next.t("days ago", { time: daysDiff });
    } else if (7 <= daysDiff && daysDiff < 14) {
      dateDiffTxt = i18next.t("1 week ago");
    } else if (14 <= daysDiff && daysDiff < 21) {
      dateDiffTxt = i18next.t("2 weeks ago");
    } else if (21 <= daysDiff && daysDiff < 28) {
      dateDiffTxt = i18next.t("3 weeks ago");
    } else {
      dateDiffTxt = moment
        .utc(dateToBeCompared)
        .tz(moment.tz.guess())
        .format('LL');
    }
  }
  return dateDiffTxt;
};

// Scheduled post
export const getScheduleTime = (dateTime) => {
  let dateTimeStatus = moment(dateTime);
  let resultDateTime =
    dateTimeStatus.isValid() === true
      ? moment.utc(dateTime).tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm A')
      : dateTime;
  return resultDateTime;
};

// Survey question reminder
export const remindDiffDays = (dateToBeCompared) => {
  let result = {};
  let today = moment.utc().format('YYYY-MM-DD HH:mm:ss');

  let difference = moment
    .duration(moment(today).diff(moment(dateToBeCompared)))
    .asMilliseconds();
  let daysDiff = moment
    .duration(moment(today).diff(moment(dateToBeCompared)))
    .asDays();
  let hoursDiff = moment
    .duration(moment(today).diff(moment(dateToBeCompared)))
    .asHours();
  let minutesDiff = moment
    .duration(moment(today).diff(moment(dateToBeCompared)))
    .asMinutes();

  if (daysDiff >= 0) {
    daysDiff = Math.floor(daysDiff);
    hoursDiff = Math.floor(hoursDiff);
    minutesDiff = Math.floor(minutesDiff);
    difference = Math.floor(difference);

    result = {
      days: daysDiff,
      hours: hoursDiff,
      minutes: minutesDiff,
      remindStatus: true,
    };
  } else {
    daysDiff = Math.floor(Math.abs(daysDiff));
    hoursDiff = Math.floor(Math.abs(hoursDiff));
    minutesDiff = Math.floor(Math.abs(minutesDiff));
    difference = Math.floor(Math.abs(difference));
    result = {
      days: daysDiff,
      hours: hoursDiff,
      minutes: minutesDiff,
      remindStatus: false,
      difference: difference,
    };
  }
  return result;
};

export const capitalizeFirstLetter = (string) => {
  if (string === null || string === '' || typeof string === 'undefined')
    return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFirstLetters = (string1 = '', string2 = '') => {
  let result = '';
  if (string1 === null || typeof string1 === 'undefined' || string1 === '') {
    result += '';
  } else {
    result += string1.charAt(0).toUpperCase();
  }
  if (string2 === null || typeof string2 === 'undefined' || string2 === '') {
    result += '';
  } else {
    result += string2.charAt(0).toUpperCase();
  }
  return result;
};

export const userProfileName = (user) => {
  switch (user.showMyNameAs) {
    case 'fullName':
      return (
        capitalizeFirstLetter(user.first_name) +
        ' ' +
        capitalizeFirstLetter(user.last_name)
      );
    case 'firstName':
      return capitalizeFirstLetter(user.first_name);
    case 'initials':
      return capitalizeFirstLetter(
        getFirstLetters(user.first_name, user.last_name),
      );
    default:
      return (
        capitalizeFirstLetter(user.first_name) +
        ' ' +
        capitalizeFirstLetter(user.last_name)
      );
  }
};

export const profileImageText = (user) => {
  return capitalizeFirstLetter(
    getFirstLetters(user.first_name, user.last_name),
  );
};

export const profileImg = (imgPath = '') => {
  if (typeof imgPath === 'undefined') {
    return require('../assets/img/avatar.png').default + '?' + Date.now();
  } else if (imgPath === '') {
    return require('../assets/img/avatar.png').default + '?' + Date.now();
  } else {
    return imgPath + '?' + Date.now();
  }
};

export const isImage = (url = '') => {
  if (url === '') {
    return false;
  } else {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
};

export const getFileExtension = (filename) => {
  if (typeof filename === 'undefined' || filename === null || filename === '') {
    return '';
  }
  const extension = filename.split('.').pop();
  return extension;
};

export const removeExtensionOfFile = (filename) => {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
};

export const isJson = (str) => {
  if (str === null) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isImageLink = (imgObj) => {
  if (imgObj.includes('fileLocation')) {
    var objImg = JSON.parse(imgObj);
    return objImg.fileLocation;
  } else return imgObj;
};

export const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});