import i18next from 'i18next';

/*
 *
 * Account constants
 *
 */

export const FETCH_PROFILE = 'src/Account/FETCH_PROFILE';
export const CLEAR_ACCOUNT = 'src/Account/CLEAR_ACCOUNT';
export const SET_PROFILE_LOADING = 'src/Account/SET_PROFILE_LOADING';
export const REQUEST_USER_PROFILE = 'src/Account/REQUEST_USER_PROFILE';
export const SET_EDIT_PROFILE_DATA = 'src/Account/SET_EDIT_PROFILE_DATA';
export const SET_EDIT_PROFILE_ERRORS = 'src/Account/SET_EDIT_PROFILE_ERRORS';
export const EDIT_PROFILE_CHANGE = 'src/Account/EDIT_PROFILE_CHANGE';
export const EDIT_USER_CHANGE = 'src/Account/EDIT_USER_CHANGE';
export const SET_EDIT_PROFILE_FORM_DATA =
  'src/Account/SET_EDIT_PROFILE_FORM_DATA';
export const SET_EDIT_PROFILE_FORM_ERRORS =
  'src/Account/SET_EDIT_PROFILE_FORM_ERRORS';
export const EDIT_PROFILE_FORM_CHANGE = 'src/Account/EDIT_PROFILE_FORM_CHANGE';
export const SET_PROFILE_IMG_DATA = 'src/Account/SET_PROFILE_IMG_DATA';
export const EDIT_PROFILE_IMG_CHANGE = 'src/Account/EDIT_PROFILE_IMG_CHANGE';
export const SET_PROFILE_IMG_ERRORS = 'src/Account/SET_PROFILE_IMG_ERRORS';
export const SET_PROFILE_BG_IMG_DATA = 'src/Account/SET_PROFILE_BG_IMG_DATA';
export const EDIT_PROFILE_BG_IMG_CHANGE =
  'src/Account/EDIT_PROFILE_BG_IMG_CHANGE';
export const SET_PROFILE_BG_IMG_ERRORS =
  'src/Account/SET_PROFILE_BG_IMG_ERRORS';
export const EDIT_SOCIAL_LINKS = 'src/Account/EDIT_SOCIAL_LINKS';
export const SET_SOCIAL_LINKS_DATA = 'src/Account/SET_SOCIAL_LINKS_DATA';
export const SET_SOCIAL_LINK_ERRORS = 'src/Account/SET_SOCIAL_LINK_ERRORS';
export const SET_EDIT_OPTIONAL_DATA = 'src/Account/SET_EDIT_OPTIONAL_DATA';
export const SET_EDIT_OPTIONAL_ERRORS = 'src/Account/SET_EDIT_OPTIONAL_ERRORS';
export const EDIT_OPTIONAL_CHANGE = 'src/Account/EDIT_OPTIONAL_CHANGE';
export const SET_EDIT_PASSWORD_DATA = 'src/Account/SET_EDIT_PASSWORD_DATA';
export const EDIT_PASSWORD_FORM_CHANGE =
  'src/Account/EDIT_PASSWORD_FORM_CHANGE';
export const SET_PASSWORD_FORM_ERRORS = 'src/Account/SET_PASSWORD_FORM_ERRORS';
export const SET_REPORT_FORM_ERRORS = 'src/Account/SET_REPORT_FORM_ERRORS';
export const REPORT_FORM_CHANGE = 'src/Account/REPORT_FORM_CHANGE';
export const SET_REPORT_FORM_RESET = 'src/Account/SET_REPORT_FORM_RESET';
export const SET_REPORT_LOADING = 'src/Account/SET_REPORT_LOADING';
export const SET_REPORT_SUBMITTING = 'src/Account/SET_REPORT_SUBMITTING';
export const GET_FIREBASE_TOKEN = 'src/Account/GET_FIREBASE_TOKEN';
export const SET_NOTIFICATION_ENABLE_DATA =
  'src/Account/SET_NOTIFICATION_ENABLE_DATA';
export const RESET_APP = 'src/Account/RESET_APP';
export const SET_INACTIVE_MESSAGES = 'src/Account/SET_INACTIVE_MESSAGES';
export const SET_SELECTED_LANGUAGE = 'src/Account/SET_SELECTED_LANGUAGE';
export const SET_FORM_TYPE = 'src/Account/SET_FORM_TYPE';
export const GET_MY_DISTRICTS = 'src/Account/GET_MY_DISTRICTS';

export const showMyNameOptions = [
  { value: 'fullName', label: 'Full Name', translationParsing: i18next.t('Full Name') },
  { value: 'firstName', label: 'First Name Last Initial', translationParsing: i18next.t('First Name Last Initial') },
  { value: 'initials', label: 'Initials', translationParsing: i18next.t('Initials') },
];

export const showMyNameOptionsPO = [
  { value: 'fullName', label: 'Display my own name', translationParsing: i18next.t('Display my own name') },
  { value: 'organization', label: 'Display my agency\'s name', translationParsing: i18next.t('Display my agency\'s name') },
];
