/**
 *
 * Not Found Page For User Profiles
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const NoData = (props) => {
  const { t } = useTranslation();

  return (
    <div className={'mb-3 no-engagement'}>
      <span className={'sad-img'}>
        <img
          src={require('../../../assets/img/sad.svg').default}
          alt="No Events"
        />
      </span>
      <span className="no-engagement-txt">{t(`Profile not found!`)}</span>
    </div>
  );
};

export default NoData;
