import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { daysDiffTxt } from '../../../utils/helper';
import Tooltip from '../../Common/Tooltip';
import { reactToCommunityIssueComment } from '../../../containers/CommunityIssues/actions';

const CommunityIssueCommentEmotions = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    communityIssueId,
    commentId,
    commentCount,
    showCommentBox = true,
    allCommunityIssues,
    allCommunityIssueComments,
    user,
    restrictionReason,
    authenticated,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];
  const comment = allCommunityIssueComments[commentId];

  const hashUrl = '#';
  const isIconDisabled = (!user.isVerified || !user.isIdentityVerified || !user.isRegistrationComplete) && authenticated;
  const canReact = true; // TODO

  return (
    <>
      {comment && (
        <div className={`comment-footer ${!authenticated && 'blur'}`}>
          {
            <div className={`clsdashboard-actions-left`}>
              <ul>
                <li>
                  <a
                    href={authenticated && hashUrl}
                    data-toggle="modal"
                    id={`comment-like-${commentId}`}
                    onClick={() => {
                      if (canReact === true && !isIconDisabled && authenticated) {
                        dispatch(reactToCommunityIssueComment(communityIssueId, commentId, 'like'));
                      }
                    }}
                    className={`${!authenticated && 'no-pointer'}`}
                  >
                    <span>
                      {isIconDisabled ? (
                        <img
                          src={require('../../../assets/img/approve-disable.svg').default}
                          alt="Approved"
                          key={`Approved`}
                        />
                      ) : (
                        [
                          comment?.userLikeReaction ? (
                            <img
                              src={require('../../../assets/img/approve-active.svg').default}
                              alt="Approved"
                              key={`Approved`}
                            />
                          ) : (
                            <img
                              src={require('../../../assets/img/approve.svg').default}
                              alt="Approve"
                              key={`Approve`}
                            />
                          ),
                        ]
                      )}
                    </span>
                    <span>{authenticated ? (comment?.likeReactionCount ?? 0) : Math.floor(Math.random() * 10)}</span>
                  </a>
                  {canReact === false && (
                    <Tooltip
                      targetId={`comment-like-${commentId}`}
                      targetStatus={true}
                      styleClass={'react-tooltip'}
                      message={t(restrictionReason)}
                    />
                  )}
                </li>
                <li>
                  <a
                    href={authenticated && hashUrl}
                    id={`comment-dislike-${commentId}`}
                    onClick={(e) => {
                      if (canReact === true && !isIconDisabled && authenticated) {
                        dispatch(reactToCommunityIssueComment(communityIssueId, commentId, 'dislike'));
                      }
                      e.preventDefault();
                    }}
                    className={`${!authenticated && 'no-pointer'}`}
                  >
                    <span>
                      {isIconDisabled ? (
                        <img
                          src={require('../../../assets/img/disapprove-disable.svg').default}
                          alt="Disapproved"
                          key={`Disapproved`}
                        />
                      ) : (
                        [
                          comment?.userDislikeReaction ? (
                            <img
                              src={require('../../../assets/img/disapproved.svg').default}
                              alt="Disapprove"
                              key={`Disapprove`}
                            />
                          ) : (
                            <img
                              src={require('../../../assets/img/disapprove.svg').default}
                              alt="Disapprove"
                              key={`Disapprove`}
                            />
                          ),
                        ]
                      )}
                    </span>{' '}
                    <span>{authenticated ? (comment?.dislikeReactionCount ?? 0) : Math.floor(Math.random() * 10)}</span>
                  </a>
                  {canReact === false && (
                    <Tooltip
                      targetId={`comment-dislike-${commentId}`}
                      targetStatus={true}
                      styleClass={'react-tooltip'}
                      message={t(restrictionReason)}
                    />
                  )}
                </li>
                <li>
                  { showCommentBox &&
                    <>
                      <a
                        href={authenticated && `#comment-box-${commentId}`}
                        data-target={`#comment-box-${commentId}`}
                        data-toggle="collapse"
                        className={`nav-toggle ${!authenticated && 'no-pointer'}`}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        id={`comment-icon-${commentId}`}
                      >
                        <span>
                          {typeof user.roleName !== 'undefined' && !isIconDisabled || !authenticated ? (
                            <img
                              src={
                                require('../../../assets/img/comment_icon.svg')
                                  .default
                              }
                              alt="Comment"
                            />
                          ) : (
                            <img
                              src={
                                require('../../../assets/img/comment-disable.svg')
                                  .default
                              }
                              alt="Comment"
                            />
                          )}
                        </span>
                        <span>{(authenticated ? (commentCount ?? 0) : Math.floor(Math.random() * 10))}</span>
                      </a>
                    </>
                  }
                </li>
              </ul>
            </div>
          }
          <div className={`comment-date`}>
            <p>
              {daysDiffTxt(comment.createdAt, t)}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueComments: state.communityIssues.allCommunityIssueComments,
    user: state.account.user,
    authenticated: state.authentication.authenticated,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueCommentEmotions);