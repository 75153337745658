/*
 *
 * Register Form
 *
 */

import React from 'react';
import FirstStep from './firstStep';
import PublicOffiicalSecondStep from './publicOffiicalSecondStep';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import { useTranslation } from 'react-i18next';

const RegisterForm = (props) => {
  const {
    registerFormData,
    register,
    isRegisterLoading,
    isRegisterSubmitting,
    activeTab,
  } = props;
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    register();
  };

  const getId = () => {
    switch (registerFormData.step) {
      case 1:
        return 'regevent_step1_continue';
      default:
        return 'regevent_continue';
    }
  }

  return (
    <div
      className={activeTab === 2 ? 'tab-pane active' : 'tab-pane'}
      id="register"
      role="tabpanel"
      aria-labelledby="profile-tab"
      style={{ position: isRegisterLoading ? 'relative' : '' }}
    >
      {isRegisterLoading && <LoadingIndicator />}
      <Notification module={'register'} />
      <form className="pb-1 mb-1" onSubmit={handleSubmit}>
        {registerFormData.step === 1 ? (
          <FirstStep {...props} key={'first-step'} />
        ) : (
          [
            registerFormData.step === 2 ? (
              <PublicOffiicalSecondStep
                {...props}
                key={'official-second-step'}
              />
            ) : (
              ''
            ),
          ]
        )}
        <div className="wrapper text-center">
          {registerFormData.step !== 7 ? (
            <div className="d-inline">
              <button
                type="submit"
                className="btn clsyellow-btn"
                disabled={isRegisterSubmitting}
                id={getId()}
              >
                {registerFormData.step === 1
                  ? t('Continue')
                  : [
                      registerFormData.step === 6
                        ? t('Continue')
                        : [
                            registerFormData.step === 2
                              ? [
                                  registerFormData.manualVerification === false
                                    ? t('Validate Credentials')
                                    : t('Continue to manual verification'),
                                ]
                              : t('Finish'),
                          ],
                    ]}
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
