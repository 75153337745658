/*
 *
 * Analytics reducer
 *
 */

import {
  SET_ANALYTICS_LOADING,
  SET_ANALYTICS_DATA,
  ANALYTICS_CHANGE,
  SET_ANALYTICS_TAB,
  SET_DISCUSSION_ANALYTICS_DATA,
  SET_SURVEY_ANALYTICS_DATA,
  SET_POSTVIEW_ANALYTICS_DATA,
  SET_QUESTION_ANALYTICS_DATA,
  CHANGE_DISCUSSION_TAB_DATA,
  CHANGE_SURVEY_TAB_DATA,
  CHANGE_POSTVIEW_TAB_DATA,
  CHANGE_QUESTION_TAB_DATA,
  SET_ACTIVE_QUESTION_ID,
  RESET_APP,
} from './constants';

const initialState = {
  analyticsData: {},
  analyticLoading: false,
  discussionData: {},
  surveyData: {},
  postviewData: {},
  questionData: {},
  activeTab: 'discussion',
  discussionTabData: {
    weighted: 'censusComparision',
    viewBy: 'ethnicity',
  },
  surveyTabData: {
    weighted: 'censusComparision',
    viewBy: 'ethnicity',
  },
  postviewTabData: {
    weighted: 'censusComparision',
    viewBy: 'ethnicity',
  },
  questionTabData: {},
  activeQuestionId: '',
};

const defaultState = JSON.stringify(initialState);

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_ANALYTICS_DATA:
      return {
        ...state,
        analyticsData: { ...state.analyticsData, ...action.payload },
      };
    case ANALYTICS_CHANGE:
      return {
        ...state,
        analyticsData: { ...state.analyticsData, ...action.payload },
      };
    case SET_ANALYTICS_LOADING:
      return {
        ...state,
        analyticLoading: action.payload,
      };
    case SET_ANALYTICS_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_DISCUSSION_ANALYTICS_DATA:
      return {
        ...state,
        discussionData: action.payload,
      };
    case SET_SURVEY_ANALYTICS_DATA:
      return {
        ...state,
        surveyData: action.payload,
      };
    case SET_POSTVIEW_ANALYTICS_DATA:
      return {
        ...state,
        postviewData: action.payload,
      };
    case SET_QUESTION_ANALYTICS_DATA:
      return {
        ...state,
        questionData: { ...state.questionData, ...action.payload },
      };
    case CHANGE_DISCUSSION_TAB_DATA:
      return {
        ...state,
        discussionTabData: { ...state.discussionTabData, ...action.payload },
      };
    case CHANGE_SURVEY_TAB_DATA:
      return {
        ...state,
        surveyTabData: { ...state.surveyTabData, ...action.payload },
      };
    case CHANGE_POSTVIEW_TAB_DATA:
      return {
        ...state,
        postviewTabData: { ...state.postviewTabData, ...action.payload },
      };
    case CHANGE_QUESTION_TAB_DATA:
      return {
        ...state,
        questionTabData: { ...state.questionTabData, ...action.payload },
      };
    case SET_ACTIVE_QUESTION_ID:
      return {
        ...state,
        activeQuestionId: action.payload,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
