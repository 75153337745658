/**
 *
 * CountDown Timer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import CompleteTimer from './completeTimer';
import moment from 'moment-timezone';

/*const releaseDate = moment.utc(new Date('2022-08-15 00:40:00')).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
const dateTime = new Date(releaseDate).getTime();*/

//const dateTime = new Date().getTime()+5000;
const dateTime = moment.tz('2022-09-09 09:00', 'America/Los_Angeles');

const CountDownTimer = (props) => {
  const { countDown } = props;

  return (
    <>
      {countDown === true ? (
        <div className="clslogin-form-blk">
          <div className="clslogin-form clsresident-form clswhite-box mt-4 pt-3 flip-clock-bg flip-clock-div">
            <h1
              className={`timer-welcome-msg mb-3`}
            >{`Welcome to CivicBell!`}</h1>
            <p className={`count-down-txt mt-2`}>
              {`To find out more about us, please visit`}{' '}
              <a
                className={`timer-link`}
                href={`https://about.civicbell.com/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                about.civicbell.com
              </a>
            </p>
            <p
              className={`count-down-txt mt-2 mb-4`}
            >{`Our platform will be available for government agencies, elected and public officials starting September 5th. Please plan to register and join then. If you are a resident of Mountain View, we will be ready for you on October 10th:`}</p>
            <FlipClockCountdown
              to={dateTime}
              className={'flip-clock'}
              labelStyle={{
                fontSize: 12,
                fontWeight: 'bold',
                textTransform: 'lowercase',
                fontFamily: 'latoregular',
                bottom: '80px',
                marginBottom: '5px',
                color: '#555',
              }}
              digitBlockStyle={{
                width: 40,
                height: 60,
                fontSize: 30,
                backgroundColor: '#F5F5F5',
                color: '#000000',
                fontWeight: 'bold',
              }}
              dividerStyle={{ color: '#fff', height: 1 }}
              separatorStyle={{ color: '#000', size: '6px' }}
              duration={0.5}
            >
              <CompleteTimer timerStatus={false} />
            </FlipClockCountdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    countDown: state.application.countDown,
  };
};

export default connect(mapStateToProps, actions)(CountDownTimer);
