import React, { useState, useEffect } from 'react';
import InputEmoji from 'react-input-emoji';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { createCommunityIssueComment } from '../../../containers/CommunityIssues/actions';
import moment from 'moment-timezone';

const CommunityIssueSubmitCommentBox = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    communityIssueId,
    parentCommentId,
    canReact,
    setIsViewMoreCommentsToggled,
    discussionBoardEnabled,
    allCommunityIssues,
    allCommunityIssueComments,
    authenticated,
    user,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];
  const [isCommunityIssueOpen, setIsCommunityIssueOpen] = useState(true);
  useEffect(() => {
    const currentDateTime = new Date(moment
      .utc(new Date())
      .tz(moment.tz.guess())
      .format('YYYY-MM-DD HH:mm:ss'));
    const t1 = currentDateTime.getTime();
    const t2 = new Date(communityIssue?.closingDate).getTime();
    setIsCommunityIssueOpen((t1 - t2 < -20000)); // .getTime() is inaccurrate; need some offset
  }, [communityIssue]);

  const [newComment, setNewComment] = useState({
    content: '',
    originalLanguage: user?.selected_language,
    parentCommentId,
  });

  if (user.roleName !== 'RESIDENT' && !communityIssue.isInGeographicArea && !communityIssue.isInDistrict) {
    return (
      <>
      </>
    )
  }
  else if (!isCommunityIssueOpen || (user.roleName === 'RESIDENT' && !communityIssue.isInArea)) {
    return (
      <>
      </>
    )
  }
  else return (
    <div className="clspost-box">
      <div className="media mt-3">
        <img
          src={
            typeof user.profile_picture !== 'undefined'
              ? user.profile_picture
              : require('../../../assets/img/profile-image.jpeg').default
          }
          className="align-self-center mr-3 engagement-profile-img"
          alt={user.first_name}
        />
        <div className="media-body">
          <div
            className="mb-3 mt-1 emoji-comment-box"
            style={{
              width: '95%',
            }}
          >
            <InputEmoji
              value={newComment.content}
              onChange={(value) => {
                setNewComment({
                  content: value,
                  originalLanguage: user?.selected_language,
                  parentCommentId,
                })
              }}
              placeholder={t(`Comment...`)}
              borderRadius={10}
            />
            <div className="input-group-append input-group emoji-post">
              <img
                className='submit-arrow'
                src={require('../../../assets/img/submit-arrow.svg').default}
                alt="submit-arrow"
                onClick={() => {
                  if (canReact === true) {
                    if (newComment.content !== '' && newComment.originalLanguage) {
                      dispatch(createCommunityIssueComment(communityIssueId, newComment));
                      setNewComment({
                        content: '',
                        originalLanguage: user?.selected_language,
                        parentCommentId,
                      });
                      setIsViewMoreCommentsToggled(true);
                    }
                  }
                }}
              />
            </div>
            <div
              className={`character-left comment-box-error ${newComment.content &&
                parseInt(500 - newComment.content.length) < 0
                ? `character-exceeded`
                : ``
                }`}
            >
              {newComment
                ? 500 - parseInt(newComment.content.length)
                : 500}{' '}
              {t('character(s) are')}{' '}
              {newComment &&
                parseInt(500 - newComment.content.length) < 0
                ? t('exceeded')
                : t('left')}
            </div>
            {/* {typeof commentData?.['error'] !== 'undefined' ? (
              <div className="clsinvalid comment-box-error">
                {commentData?.['error']}
              </div>
            ) : (
              ''
            )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueComments: state.communityIssues.allCommunityIssueComments,
    authenticated: state.authentication.authenticated,
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueSubmitCommentBox);