/**
 *
 * CivicFeed Search Result
 *
 */
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, NavLink } from 'react-router-dom';

const ResultsContainer = ({ title, results, showMore, followPublicOfficial, invitePublicOfficial, user, titleHTML, subtitle, truncateResults = true }) => {
  const { t } = useTranslation();
  const hashUrl = '#';

  const displayResults = truncateResults ? results.slice(0, 2) : results;

  return (
    <div className="csresults_container">
      {titleHTML && <div className="search_top_txt" dangerouslySetInnerHTML={titleHTML ? {__html: titleHTML} : undefined} />}
      {(title || subtitle) && (
        <div className="search_top_txt">
          {title && <h5>{title}</h5>}
          {subtitle && <span>{subtitle}</span>}
        </div>
      )}
      <div className="search_result_list_section">
        {results.length === 0 ? (
          <div className="search_result_list justify-content-between">
            <span className="showmore_search">{t('No result found')}</span>
          </div>
        ) : (
          ''
        )}
        {displayResults.map((profile) => (
          <div className="search_result_list justify-content-between">
            <div class="img-fluid">
              {profile.isRegistered === true ? (
                <NavLink
                  to={'/dashboard/user-profile/?id=' + profile.userId}
                  activeClassName="active"
                  exact
                >
                  <img
                    src={
                      typeof profile.profile_picture !==
                        'undefined' &&
                      profile.profile_picture !== null
                        ? profile.profile_picture
                        : require('../../../assets/img/profile-image.jpeg')
                            .default
                    }
                    alt=""
                    className="align-self-center mr-3 engagement-profile-img"
                  />
                </NavLink>
              ) : (
                <img
                  src={
                    typeof profile.profile_picture !== 'undefined' &&
                    profile.profile_picture !== null
                      ? profile.profile_picture
                      : require('../../../assets/img/profile-image.jpeg')
                          .default
                  }
                  alt=""
                  className="align-self-center mr-3 engagement-profile-img"
                />
              )}
            </div>
            <div className="search_content_section">
              <span className="title">
                {profile.isRegistered === true ? (
                  <NavLink
                    to={
                      '/dashboard/user-profile/?id=' + profile.userId
                    }
                    activeClassName="active"
                    exact
                  >
                    <strong>
                      {typeof profile.show_my_name !== 'undefined'
                        ? `${profile.show_my_name}`
                        : ''}
                    </strong>
                  </NavLink>
                ) : (
                  <strong>
                    {typeof profile.show_my_name !== 'undefined'
                      ? `${profile.show_my_name}`
                      : ''}
                  </strong>
                )}
                {typeof profile.userOfficial !== 'undefined'
                  ? typeof profile.userOfficial !== 'undefined' &&
                    profile.userOfficial !== null
                    ? `${', '}${profile.userOfficial.position}`
                    : ''
                  : ''}
              </span>
              <span>
                {typeof profile.userOfficial !== 'undefined' &&
                profile.userOfficial !== null
                  ? profile.userOfficial.area
                  : ''}
              </span>
            </div>
            <div className="text-btn_soc_media">
              {profile.isRegistered === true ? (
                profile.userOutsideToPO && (<a
                  href={hashUrl}
                  onClick={(e) => {
                    followPublicOfficial(profile.userId);
                    e.preventDefault();
                  }}
                >
                  {user?.userFollowingOfficials?.includes(
                    profile.userId,
                  ) === true
                    ? t('Unfollow')
                    : t('Follow')}
                </a>)
              ) : profile.officialEmail ? (
                <a
                  href={hashUrl}
                  onClick={(e) => {
                    invitePublicOfficial(
                      profile.officialEmail,
                      'dashboard',
                    );
                    e.preventDefault();
                  }}
                  className="invite"
                >
                  {t(`Invite`)}
                </a>
              ) : (
                ''
              )}
          </div>
        </div>
        ))}
        {results.length > 2 && truncateResults ? (
          <a
            href={hashUrl}
            className="showmore_search"
            onClick={showMore}
          >
            {t('Show')}{' '}{results.length - 2}{' '}{t('more search results')}
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const SearchResult = (props) => {
  const {
    user,
    searchText,
    districtSearchResult,
    anywhereSearchResult,
    followPublicOfficial,
    showResult,
    invitePublicOfficial,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const hashUrl = '#';
  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const search = location.search;
  const typeQuery = new URLSearchParams(search).get('type');

  const isOfficial = user.roleId === 2;

  const showMoreResult = (type) => {
    history.push('/dashboard/searchresult/?type=' + type);
  };

  const backToCivicFeed = () => {
    history.goBack();
  };

  return (
    <>
      {showResult === true ? (
        <>
          {uri === 'dashboard/searchresult' ? (
            <>
              <div className={`clsanalytics-title`}>
                <h6 className={`mb-2`}>
                  <a
                    href={hashUrl}
                    onClick={(e) => {
                      backToCivicFeed();
                      e.preventDefault();
                    }}
                  >
                    <img
                      src={require('../../../assets/img/back.svg').default}
                      alt="Back"
                    />
                    {t(`Back to Overview`)}
                  </a>
                </h6>
              </div>
              {typeQuery === 'district' ? (
                <ResultsContainer
                  titleHTML={`<h5>${t('Search results for')} "${searchText}" ${t('in')} <strong>${t('government officials')}</strong></h5>`}
                  subtitle={t(`Within your districts or accounts you follow`)}
                  results={districtSearchResult}
                  showMore={() => showMoreResult('district')}
                  followPublicOfficial={followPublicOfficial}
                  invitePublicOfficial={invitePublicOfficial}
                  user={user}
                  truncateResults={false}
                />
              ) : (
                ''
              )}
              {typeQuery === 'anywhere' ? (
                <ResultsContainer
                  subtitle={t('Anywhere')}
                  results={anywhereSearchResult}
                  showMore={() => showMoreResult('anywhere')}
                  followPublicOfficial={followPublicOfficial}
                  invitePublicOfficial={invitePublicOfficial}
                  user={user}
                  truncateResults={false}
                />
              ) : (
                ''
              )}
              {typeQuery === null ? (
                <>
                  {!isOfficial && (<ResultsContainer
                    titleHTML={`<h5>${t('Search results for')} "${searchText}" ${t('in')} <strong>${t('government officials')}</strong></h5>`}
                    subtitle={t(`Within your districts or accounts you follow`)}
                    results={districtSearchResult}
                    showMore={() => showMoreResult('district')}
                    followPublicOfficial={followPublicOfficial}
                    invitePublicOfficial={invitePublicOfficial}
                    user={user}
                  />)}
                  <ResultsContainer
                    titleHTML={isOfficial ? `<h5>${t('Search results for')} "${searchText}" ${t('in')} <strong>${t('government officials')}</strong></h5>` : undefined}
                    subtitle={t('Anywhere')}
                    results={anywhereSearchResult}
                    showMore={() => showMoreResult('anywhere')}
                    followPublicOfficial={followPublicOfficial}
                    invitePublicOfficial={invitePublicOfficial}
                    user={user}
                  />
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              {!isOfficial && (<ResultsContainer
                titleHTML={`<h5>${t('Search results for')} "${searchText}" ${t('in')} <strong>${t('government officials')}</strong></h5>`}
                subtitle={t(`Within your districts or accounts you follow`)}
                results={districtSearchResult}
                showMore={() => showMoreResult('district')}
                followPublicOfficial={followPublicOfficial}
                invitePublicOfficial={invitePublicOfficial}
                user={user}
              />)}
              <ResultsContainer
                titleHTML={isOfficial ? `<h5>${t('Search results for')} "${searchText}" ${t('in')} <strong>${t('government officials')}</strong></h5>` : undefined}
                subtitle={t('Anywhere')}
                results={anywhereSearchResult}
                showMore={() => showMoreResult('anywhere')}
                followPublicOfficial={followPublicOfficial}
                invitePublicOfficial={invitePublicOfficial}
                user={user}
              />
            </>
          )}
          <div className="search_top_txt">
            <h5>
              {t('Search results for')}{' '}"{searchText}"{' '}{t('in')}{' '}
              <strong>{t('all posts on CivicBell')}</strong>
            </h5>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default SearchResult;
