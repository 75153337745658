/*
 *
 * InviteOthers actions
 *
 */

import {
  INVITE_OTHERS_FORM_CHANGE,
  INVITE_OTHERS_FORM_RESET,
  INVITE_OTHERS_FORM_ERRORS,
  SET_INVITE_SUBMITTING,
  RESET_APP,
} from './constants';
import { addNotification } from '../Notification/actions';
import { allFieldsValidation } from '../../utils/validation';
import axios from 'axios';
import { BASE_API_URL } from '../../constants';

export const resetInviteOthersState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const inviteOthersChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: INVITE_OTHERS_FORM_CHANGE, payload: formData });
  };
};

export const validateInviteForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let inviteOther = getState().inviteOther.inviteFormData;

      let inviteOtherData = {
        email: 'required|email',
      };

      const { isValid, errors } = allFieldsValidation(
        inviteOther,
        inviteOtherData,
        {
          'required.email': 'Email is required',
          'email.email': 'Email format is invalid.',
        },
      );

      if (!isValid) {
        dispatch({ type: INVITE_OTHERS_FORM_ERRORS, payload: errors });
        return reject(errors);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitInviteOtherForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: SET_INVITE_SUBMITTING, payload: true });
      dispatch(validateInviteForm())
        .then(async () => {
          dispatch({ type: INVITE_OTHERS_FORM_ERRORS, payload: {} });
          dispatch(inviteOtherSubmit());
        })
        .catch((error) => {
          dispatch({ type: SET_INVITE_SUBMITTING, payload: false });
        });
    });
  };
};

export const resetInviteForm = () => {
  return async (dispatch, getState) => {
    return dispatch({
      type: INVITE_OTHERS_FORM_RESET,
    });
  };
};

export const inviteOtherSubmit = () => {
  return async (dispatch, getState) => {
    let inviteOther = getState().inviteOther.inviteFormData;

    let inviteOtherInfo = {
      inviteto: inviteOther.inviteWhom,
      email: inviteOther.email,
    };

    await axios
      .post(BASE_API_URL + '/users/inviteOffical', inviteOtherInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'inviteOther',
          }),
        );
        dispatch(resetInviteForm());
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'inviteOther',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_INVITE_SUBMITTING, payload: false });
      });
  };
};

export const invitePublicOfficial = (email, moduleName = 'inviteOther') => {
  return async (dispatch, getState) => {
    let inviteOtherInfo = {
      inviteto: 'publicofficial',
      email: email,
    };

    await axios
      .post(BASE_API_URL + '/users/inviteOffical', inviteOtherInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: moduleName,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'inviteOther',
          }),
        );
      })
      .finally(() => {});
  };
};
