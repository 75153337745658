/*
 *
 * Reset Password Reducer
 *
 */

import {
  RESET_PASSWORD_CHANGE,
  RESET_PASSWORD_FORM_RESET,
  SET_RESET_PASSWORD_FORM_ERRORS,
  SET_RESET_PASSWORD_SUBMITTING,
  SET_LINK_VERIFIED,
  SET_ERROR_NOTIFICATION,
  RESET_APP,
} from './constants';

const initialState = {
  resetFormData: {
    password: '',
    confirmPassword: '',
  },
  resetFormErrors: {},
  isResetFormSubmitting: false,
  isLinkVerified: false,
  isValidLink: false,
  errorNotification: false,
};

const defaultState = JSON.stringify(initialState);

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case RESET_PASSWORD_CHANGE:
      return {
        ...state,
        resetFormData: { ...state.resetFormData, ...action.payload },
      };
    case SET_RESET_PASSWORD_FORM_ERRORS:
      return {
        ...state,
        resetFormErrors: action.payload,
      };
    case RESET_PASSWORD_FORM_RESET:
      return {
        ...state,
        resetFormData: {},
        resetFormErrors: {},
        isResetFormSubmitting: false,
      };
    case SET_RESET_PASSWORD_SUBMITTING:
      return {
        ...state,
        isResetFormSubmitting: action.payload,
      };
    case SET_LINK_VERIFIED:
      return {
        ...state,
        isLinkVerified: action.payload.isLinkVerified,
        isValidLink: action.payload.isValidLink,
      };
    case SET_ERROR_NOTIFICATION:
      return {
        ...state,
        errorNotification: action.payload,
      };
    default:
      return state;
  }
};

export default resetPasswordReducer;
