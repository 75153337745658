/**
 *
 * Profile Page
 *
 */

import React from 'react';
import PublicOfficial from './publicOfficial';
import Resident from './resident';

const Profile = (props) => {
  const { user } = props;

  return (
    <>
      {typeof user.roleName !== 'undefined' ? (
        user.roleName === 'ADMIN' || user.roleName === 'PUBLICOFFICIAL' ? (
          <PublicOfficial {...props} />
        ) : (
          <Resident {...props} />
        )
      ) : (
        ''
      )}
    </>
  );
};

export default Profile;
