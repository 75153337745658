/**
 *
 * Notification Filter
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const NotificationFilters = (props) => {
  const { t } = useTranslation();

  return (
    <div className="clsfilter-modal-content">
      <center>{t(`No New Notifications`)}</center>
    </div>
  );
};
export default NotificationFilters;
