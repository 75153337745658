/*
 *
 * Reactions reducer
 *
 */

import { SET_REACTION_ENGAGEMENT_DATA, RESET_APP } from './constants';

const initialState = {
  engagementReactFormData: {
    id: null,
    title: 'Engagement Reactions',
    dismissal: false,
  },
};

const defaultState = JSON.stringify(initialState);

const engagementReactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_REACTION_ENGAGEMENT_DATA:
      return {
        ...state,
        engagementReactFormData: action.payload,
      };
    default:
      return state;
  }
};

export default engagementReactionReducer;
