/**
 *
 * Child Comment
 *
 */

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import CommentEmotions from '../CommentEmotions';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";
import { languageMap } from '../../Common/LanguageSelect';

const ChildComment = (props) => {
  const {
    comment,
    engagementId,
    getChildComments,
    discussionEngagementData,
    reloadComments,
    changePostComment,
    submitComment,
    setReactions,
    user,
    setReloadComments,
    canReact,
    restrictionReason,
    closeEngamentModal,
    reloadChildComments,
    loadChildComments,
    authenticated,
  } = props;
  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);
  const { t } = useTranslation();
  const [toggleTxt, setToggleTxt] = useState({
    active: `${t('Show')} ${comment.childCount} ${t('replies')}`,
    inActive: t('Hide replies'),
  });
  const [loaded, setLoaded] = useState(true);
  const [visible, setVisible] = useState(false);
  const [commentData, setCommentData] = useState(null);

  const loadComments = useCallback(async () => {
    let text = {};
    if (commentData === null) {
      await getChildComments(engagementId, comment.id).then(() => {
        let commentsData = null;
        commentsData = discussionEngagementData[engagementId][
          'comments'
        ].filter(function (comments) {
          return comments.parentId === comment.id;
        });
        setCommentData(commentsData);
        if (toggleTxt.active === t('Hide replies')) {
          text = {
            active: t('Show') + ' ' + commentsData.length + ' ' + t('replies'),
            inActive: t(toggleTxt.active),
          };
        } else {
          text = {
            active: t(toggleTxt.inActive),
            inActive:
              t('Show') + ' ' + commentsData.length + ' ' + t('replies'),
          };
        }
        setToggleTxt(text);
      });
    } else {
      if (toggleTxt.active === t('Hide replies')) {
        text = {
          active: t('Show') + ' ' + commentData.length + ' ' + t('replies'),
          inActive: t(toggleTxt.active),
        };
      } else {
        text = {
          active: t(toggleTxt.inActive),
          inActive: t('Show') + ' ' + commentData.length + ' ' + t('replies'),
        };
      }
      setToggleTxt(text);
    }
    setLoaded(true);
  }, [
    getChildComments,
    engagementId,
    comment.id,
    discussionEngagementData,
    toggleTxt,
    commentData,
    t,
  ]);

  const reloadComment = useCallback(async () => {
    await getChildComments(engagementId, comment.id).then(() => {
      let commentsData = null;
      commentsData = discussionEngagementData[engagementId]['comments'].filter(
        function (comments) {
          return comments.parentId === comment.id;
        },
      );
      setCommentData(commentsData);
      let text = {};
      if (toggleTxt.active !== t('Hide replies')) {
        text = {
          active: t('Show') + ' ' + commentsData.length + ' ' + t('replies'),
          inActive: t(toggleTxt.inActive),
        };
        setToggleTxt(text);
      }
      loadChildComments({ status: false, id: null });
    });
  }, [
    getChildComments,
    engagementId,
    comment.id,
    discussionEngagementData,
    toggleTxt,
    t,
    loadChildComments,
  ]);

  /* useEffect(() => {
  if(reloadComments === true) {
    reloadComment();
    setReloadComments(false);
  }
 }, [reloadComments, reloadComment, setReloadComments]) */

  useEffect(() => {
    if (
      reloadComments === true &&
      reloadChildComments.status === true &&
      reloadChildComments.id === comment.id
    ) {
      reloadComment();
      setReloadComments(false);
    }
  }, [
    reloadChildComments,
    comment.id,
    reloadComment,
    reloadComments,
    setReloadComments,
  ]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 240,
      fontSize: '14px',
      backgroundColor: '#fff',
      color: '#000',
      textAlign: 'center',
      borderRadius: '10px',
      border: '1px solid #f5f4f9',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 9px 0px',
      padding: '10px'
    },
  }));

  return (
    <>
      <div
        id={`comment-show-more-${comment.id}`}
        className="collapse clsreplied-content-blk clsrequired-conten"
      >
        <div className="clsreplied-content-blk clsrequired-conten clspost-box">
          {commentData !== null ? (
            commentData.map((child, index) => (
              <div className="media mt-3" key={child.id}>
                <NavLink
                  onClick={closeEngamentModal}
                  to={'/dashboard/user-profile/?id=' + comment.userId}
                  activeClassName="active"
                  exact
                >
                  <img
                    src={
                      child.commentOwnerProfilePicture !== null
                        ? child.commentOwnerProfilePicture
                        : require('../../../assets/img/profile-image.jpeg')
                          .default
                    }
                    className="align-self-center mr-3 engagement-profile-img"
                    alt={child.commentOwner}
                  />
                </NavLink>
                <div className="media-body">
                  <div
                    className={`clsdiscussion-details mb-2 ${child.commentOwnerRole === 'PUBLICOFFICIAL'
                        ? 'highlight-comment-official'
                        : 'highlight-comment-resident'
                      }`}
                  >
                    <div className="comment-user-detail mt-0 mb-1" style={{ display: 'flex' }}>
                      <h5 className="comment-username" style={{ width: 'auto' }}>
                        <NavLink
                          onClick={closeEngamentModal}
                          to={'/dashboard/user-profile/?id=' + comment.userId}
                          activeClassName="active"
                          exact
                        >
                          {`${child.commentOwner}`}
                        </NavLink>
                        <br />
                        <span className="user-position">
                          {comment.commentOwnerPlace
                            ? comment.commentOwnerPlace
                            : ''
                          }
                        </span>
                      </h5>
                      <div className="mr-3 globe-box">
                        {(user.selected_language !== comment?.originalLanguage) &&
                          <HtmlTooltip placement="bottom" PopperProps={{
                            onPointerDown: e => {
                              if (e.target instanceof Element && !e.target.classList.contains("MuiTooltip-tooltip")) {
                                e.preventDefault();
                              }
                            },
                          }} title={comment?.translation ?
                            <>{t(`This content was originally written in`) + '\u00A0' + `${t(languageMap?.[comment?.originalLanguage]?.label)}.`}
                              <a className="translation-link" href='https://civicl.ink/aitranslation'
                                target="_blank">{'\u00A0' + t('Learn more about our AI-based language translation.')}
                              </a>
                              <button className="translate-btn clsyellow-translate-btn"
                                onClick={() => setShowOriginalLanguage(!showOriginalLanguage)}>
                                {showOriginalLanguage ? t('Revert to requested language') : t('Show in original language')}</button>
                            </> : <>{t('No translation exists for this comment.')}</>}>
                            {comment?.translation ?
                              <img src={require('../../../assets/img/icon-language.svg').default} alt="Globe" /> :
                              <img src={require('../../../assets/img/icon-language-crossed.svg').default}
                                alt="Globe Crossed" />}
                          </HtmlTooltip>
                        }
                      </div>
                    </div>
                    <p className="discussion-comment">
                      {
                        user.selected_language === child.originalLanguage || showOriginalLanguage ?
                          child.comment
                          :
                          (JSON.parse(child.translation)?.[user.selected_language]?.content) ?
                            JSON.parse(child.translation)?.[user.selected_language]?.content
                            :
                            child.comment
                      }
                    </p>
                  </div>
                  <CommentEmotions
                    id={engagementId}
                    commentId={child.id}
                    likes={child.likeReactionCount}
                    disLikes={child.dislikeReactionCount}
                    comments={child.childCount}
                    commentBoxStatus={false}
                    commentData={child}
                    changePostComment={changePostComment}
                    submitComment={submitComment}
                    setReactions={setReactions}
                    user={user}
                    isChildComment={false}
                    canReact={canReact}
                    restrictionReason={restrictionReason}
                  />
                </div>
              </div>
            ))
          ) : visible === true ? (
            <LoadingIndicator
              position={`relative`}
              height={'35px'}
              backgroundSize={'35px'}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      {loaded && authenticated && (
        <p className="mt-3" id={`show-hide-comment-${comment.id}`}>
          <a
            href={`#comment-show-more-${comment.id}`}
            data-target={`#comment-show-more-${comment.id}`}
            data-toggle="collapse"
            className="nav-toggle clsreply-link"
            id={`show-hide-link-${comment.id}`}
            onClick={() => {
              setLoaded(false);
              setVisible(true);
              loadComments();
            }}
          >
            {toggleTxt.active}
          </a>
        </p>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    discussionEngagementData:
      state.discussionEngagement.discussionEngagementData,
    reloadComments: state.discussionEngagement.reloadComments,
    reloadChildComments: state.discussionEngagement.reloadChildComments,
    authenticated: state.authentication.authenticated,
  };
};

export default connect(mapStateToProps, actions)(React.memo(ChildComment));
