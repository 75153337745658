/**
 *
 * IDme Verification Button
 *
 */

import React from 'react';
import {
  IDME_SCOPE,
  IDME_CLIENT_ID,
  IDME_REDIRECT_URL,
  IDME_RESPONSE,
  IDME_BASE_URL,
} from '../../../constants';

const IDmeButton = () => {
  return (
    <div id="idme-verification" class="mt-3 text-center">
      <a
        href={`${IDME_BASE_URL}/oauth/authorize?client_id=${IDME_CLIENT_ID}&redirect_uri=${IDME_REDIRECT_URL}&response_type=${IDME_RESPONSE}&scope=${IDME_SCOPE}&state=488e864b`}
        className="cursor-pointer"
      >
        <img
          src={require('../../../assets/img/idmeVerify.svg').default}
          alt="IDme verification"
        />
      </a>
    </div>
  );
};

export default IDmeButton;
