import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPageSessionId from "../ErrorPageSessionId";

const Page500 = (props) => {
  const { t } = useTranslation();

  return (
    <div className="cls500">
      <div className="cls500-in">
        <h3 className="mt-4 mb-4 pt-3">
          <span>{t(`Welcome to CivicBell!`)}</span>
          <br/>
          {t(`We're currently hard at work.`)}
        </h3>

        <p className="mt-4 mb-4 pt-3">
          {t(`Online civic engagement doesn’t just require a lot of work from you, but also from our servers!`)}
          <br/>
          {t(`To ensure a smooth experience we have to periodically perform updates.`)}
          <br/>
          <br/>
          {t(`We apologize for any inconvenience this may cause you. Please visit us again later today!`)}
        </p>

        <img
            src={require('../../../assets/img/img-500.svg').default}
            alt="500"
            className="img-fluid"
        />

        <p className="mt-4 mb-4 pt-3 cls500-support-p">
          {t(`If this screen persists, please let us know via`)} <a href="mailto:support@civicbell.com">support@civicbell.com</a>.
          <br/>
          <ErrorPageSessionId />
        </p>
      </div>
    </div>
  );
};

export default Page500;