/**
 *
 * Dashboard Page Navigation Links
 *
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { CivicIcons } from '../CivicIcons';
import { useTranslation } from 'react-i18next';

const NavLinks = (props) => {
  const { pages, user } = props;
  const { t } = useTranslation();

  return (
    <>
      {Object.keys(pages).length !== 0 &&
      typeof user.roleName !== 'undefined' ? (
        <aside className="clsdashboard-sidebar clswhite-box">
          <ul>
            {pages[user.roleName].map((link, index) =>
              link.navBar === true ? (
                <li key={index}>
                  {link.type === 'page' ? (
                    <NavLink
                      to={link.to}
                      activeClassName={
                        link.name === 'Local News'
                          ? 'nav-link clslocal-news-icon active'
                          : link.name === 'Invite Others'
                          ? 'nav-link clsinvite-icon active'
                          : 'active'
                      }
                      exact
                    >
                      {CivicIcons[link.icon]}
                      <span>{t(link.name)}</span>
                    </NavLink>
                  ) : (
                    <NavLink to={{ pathname: link.to }} target="_blank">
                      {CivicIcons[link.icon]}
                      <span>{t(link.name)}</span>
                    </NavLink>
                  )}
                </li>
              ) : (
                ''
              ),
            )}
          </ul>
        </aside>
      ) : (
        ''
      )}
      {Object.keys(pages).length !== 0 &&
      typeof user.roleName === 'undefined' ? (
        <aside className="clsdashboard-sidebar clswhite-box">
          <ul>
            {pages['GUEST'].map((link, index) =>
              link.navBar === true ? (
                <li key={index}>
                  {link.type === 'page' ? (
                    <NavLink
                      to={link.to}
                      activeClassName={
                        link.name === 'Local News'
                          ? 'nav-link clslocal-news-icon active'
                          : link.name === 'Invite Others'
                          ? 'nav-link clsinvite-icon active'
                          : 'active'
                      }
                      exact
                    >
                      {CivicIcons[link.icon]}
                      <span>{t(link.name)}</span>
                    </NavLink>
                  ) : (
                    <NavLink to={{ pathname: link.to }} target="_blank">
                      {CivicIcons[link.icon]}
                      <span>{t(link.name)}</span>
                    </NavLink>
                  )}
                </li>
              ) : (
                ''
              ),
            )}
          </ul>
        </aside>
      ) : (
        ''
      )}
    </>
  );
};

NavLinks.defaultProps = {
  links: [],
  user: [],
};

export default NavLinks;
