/*
 *
 * Engagement constants
 *
 */
import i18next from 'i18next';

export const SET_EDIT_ENGAGEMENT_DATA =
  'src/Engagement/SET_EDIT_ENGAGEMENT_DATA';
export const ENGAGEMENT_CHANGE = 'src/Engagement/ENGAGEMENT_CHANGE';
export const ENGAGEMENT_ATTACHMENT = 'src/Engagement/ENGAGEMENT_ATTACHMENT';
export const REMOVE_ATTACHEMENT = 'src/Engagement/REMOVE_ATTACHEMENT';
export const ENGAGEMENT_RESET = 'src/Engagement/ENGAGEMENT_RESET';
export const SET_ENGAGEMENT_LOADING = 'src/Engagement/SET_ENGAGEMENT_LOADING';
export const SET_ENGAGEMENT_FORM_ERRORS =
  'src/Engagement/SET_ENGAGEMENT_FORM_ERRORS';
export const SET_ENGAGEMENT_SUBMITTING =
  'src/Engagement/SET_ENGAGEMENT_SUBMITTING';
export const SET_ENGAGEMENT_QUESTION_FORM_ERRORS =
  'src/Engagement/SET_ENGAGEMENT_QUESTION_FORM_ERRORS';
export const ADD_QUESTION = 'src/Engagement/ADD_QUESTION';
export const REMOVE_QUESTION = 'src/Engagement/REMOVE_QUESTION';
export const ADD_CHOICE = 'src/Engagement/ADD_CHOICE';
export const REMOVE_CHOICE = 'src/Engagement/REMOVE_CHOICE';

export const FETCH_ENGAGEMENTS = 'src/Engagement/FETCH_ENGAGEMENTS';
export const REMOVE_ENGAGEMENT = 'src/Engagement/REMOVE_ENGAGEMENT';
export const SET_DELETE_HANDLE = 'src/Engagement/SET_DELETE_HANDLE';
export const SET_ENGAGEMENT_TAB = 'src/Engagement/SET_ENGAGEMENT_TAB';
export const SET_NO_RECORD_STATUS = 'src/Engagement/SET_NO_RECORD_STATUS';
export const SET_RESET_STATUS = 'src/Engagement/SET_RESET_STATUS';
export const SET_ENGAGEMENT_FORM_RESET =
  'src/Engagement/SET_ENGAGEMENT_FORM_RESET';
export const ENGAGEMENTS_RESET = 'src/Engagement/ENGAGEMENTS_RESET';
export const ENGAGEMENT_RELOAD_LIST = 'src/Engagement/ENGAGEMENT_RELOAD_LIST';
export const ENGAGEMENT_SPECIFIC_DATA =
  'src/Engagement/ENGAGEMENT_SPECIFIC_DATA';
export const ENGAGEMENT_SPECIFIC_RESET =
  'src/Engagement/ENGAGEMENT_SPECIFIC_RESET';
export const RESET_APP = 'src/Engagement/RESET_APP';

export const categoryOptions = [
  { value: 'post', label: 'Info Post', translationParsing: i18next.t('Info Post') },
  { value: 'discussion', label: 'Discussion Post', translationParsing: i18next.t('Discussion Post') },
  { value: 'survey', label: 'Survey Post', translationParsing: i18next.t('Survey Post') },
];

export const targetPopulationOptions = [
  { value: '1', label: 'Residents living in your district', translationParsing: i18next.t('Residents living in your district') },
  // { value: '2', label: 'Residents ≥ 18 years in your district', translationParsing: i18next.t('Residents ≥ 18 years in your district') },
  // { value: '3', label: 'Eligible voters in your district', translationParsing: i18next.t('Eligible voters in your district') },
  { value: '4', label: 'All registered residents on CivicBell', translationParsing: i18next.t('All registered residents on CivicBell') },
];

export const questionTypeOptions = [
  { value: '1', label: 'Two Choice Answer', translationParsing: i18next.t('Two Choice Answer') },
  { value: '2', label: 'Multiple Choice Answer', translationParsing: i18next.t('Multiple Choice Answers') },
];
