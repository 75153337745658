/*
 *
 * Authentication reducer
 *
 */

import {
  SET_AUTH,
  CLEAR_AUTH,
  RESET_APP,
  INITIATIVE_URL,
  SET_LOAD_MESSAGE_STATUS,
  SET_SILENT_USER,
} from './constants';

const initialState = {
  authenticated: false,
  isLoading: false,
  receivedUrl: '',
  loadMsgStatus: false,
  isSilentUser: false
};

const defaultState = JSON.stringify(initialState);

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_AUTH:
      return {
        ...state,
        authenticated: true,
        isSilentUser: false,
      };
    case CLEAR_AUTH:
      return {
        ...state,
        authenticated: false,
      };
    case INITIATIVE_URL:
      return {
        ...state,
        receivedUrl: action.payload,
      };
    case SET_LOAD_MESSAGE_STATUS:
      return {
        ...state,
        loadMsgStatus: action.payload,
      };
    case SET_SILENT_USER:
      return {
        ...state,
        isSilentUser: true,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
