/*
 *
 * Reactions actions
 *
 */

import { SET_REACTION_ENGAGEMENT_DATA, RESET_APP } from './constants';

export const resetReactionState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const setReactionFormData = (engagement) => {
  let formData = {};

  formData = {
    id: engagement.id,
    title: 'Engagement Reactions',
    dismissal: false,
    engagement: engagement,
  };

  return {
    type: SET_REACTION_ENGAGEMENT_DATA,
    payload: formData,
  };
};
