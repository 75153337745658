/*
 *
 * Events constants
 *
 */
import i18next from 'i18next';

export const SET_EVENT_TAB = 'src/Events/SET_EVENT_TAB';
export const SET_MYEVENT_TAB = 'src/Events/SET_MYEVENT_TAB';
export const SET_EVENTS_LOADING = 'src/Events/SET_EVENTS_LOADING';
export const SET_OPTION_LOADING = 'src/Events/SET_OPTION_LOADING';
export const SET_EVENT_SUBMITTING = 'src/Events/SET_EVENT_SUBMITTING';
export const FETCH_CIVIC_EVENTS = 'src/Events/FETCH_CIVIC_EVENTS';
export const FETCH_MYCIVIC_EVENTS = 'src/Events/FETCH_MYCIVIC_EVENTS';
export const FETCH_UPCOMING_EVENTS = 'src/Events/FETCH_UPCOMING_EVENTS';
export const EVENTS_RELOAD_LIST = 'src/Events/EVENTS_RELOAD_LIST';
export const EVENTS_CHANGE = 'src/Events/EVENTS_CHANGE';
export const EVENTS_RESET = 'src/Events/EVENTS_RESET';
export const EVENTS_LIST_CHANGE = 'src/Events/EVENTS_LIST_CHANGE';
export const EVENT_FORM_CHANGE = 'src/Events/EVENT_FORM_CHANGE';
export const SET_EVENT_FORM_ERRORS = 'src/Events/SET_EVENT_FORM_ERRORS';
export const SET_EVENT_FORM_RESET = 'src/Events/SET_EVENT_FORM_RESET';
export const SET_EVENT_SINGLE = 'src/Events/SET_EVENT_SINGLE';
export const RESET_EVENT_SINGLE = 'src/Events/RESET_EVENT_SINGLE';
export const EVENT_SPECIFIC_DATA = 'src/Events/EVENT_SPECIFIC_DATA';
export const UPDATE_EVENT_SINGLE = 'src/Events/UPDATE_EVENT_SINGLE';
export const RESET_APP = 'src/Events/RESET_APP';

export const sortOptions = [
  { value: 'newest', label: 'Newest on top', translationParsing: i18next.t('Newest on top') },
  { value: 'oldest', label: 'Oldest on top', translationParsing: i18next.t('Oldest on top') },
];

export const recurringOptions = [
  { value: 'daily', label: 'Daily', translationParsing: i18next.t('Daily') },
  { value: 'weekdays', label: 'Monday-Friday', translationParsing: i18next.t('Monday-Friday')},
  { value: 'weekly', label: 'Weekly', translationParsing: i18next.t('Weekly')},
  { value: 'monthly', label: 'Monthly', translationParsing: i18next.t('Monthly') },
];
