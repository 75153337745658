/**
 *
 * LocalNews view and Filter
 *
 */

import React from 'react';
import SelectOption from '../../Common/SelectOption';
import { handleOptionValue } from '../../../utils/helper';
import { sortOptionsLocalNews } from '../../../containers/LocalNews/constants';
import CivicModal from '../../Common/CivicModal';
import FilterFooter from './filterFooter';
import LocalNewsFilter from './localNewsFilter';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const { sort, localNewsChangeByOption, filterDismissal } = props;
  const hashUrl = '#';
  const { t } = useTranslation();

  return (
    <div className="clsfeed-filter-blk mb-3">
      <div className="clsfeed-dropdown">
        <div className="dropdown">
          <div className="form-group">
            <SelectOption
              name={'sort'}
              placeholder={t(`View`)}
              value={handleOptionValue(sortOptionsLocalNews, sort)}
              options={sortOptionsLocalNews}
              handleSelectChange={(value) => {
                localNewsChangeByOption('sort', value.value);
              }}
            />
            <label>{t(`View`)}</label>
          </div>
        </div>
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`filter-modal`}
          title={t(`Filter`)}
          dismissal={filterDismissal}
          footer={FilterFooter(props)}
          footerClass={`text-center mt-4`}
        >
          <LocalNewsFilter {...props} />
        </CivicModal>
      </div>
      <div className="clsfeed-filter-blk clsfeed-filter clsfeed-filter--localnews">
        <a
          href={hashUrl}
          onClick={() => {
            localNewsChangeByOption('filterDismissal', false);
          }}
          data-toggle="modal"
          data-target="#filter-modal"
        >
          <img
            src={require('../../../assets/img/filter.svg').default}
            alt="Filter"
          />
          <span>{t(`Filter`)}</span>
        </a>
      </div>
    </div>
  );
};

export default Filter;
