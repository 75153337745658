/**
 *
 * Official Filter
 *
 */

import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OfficialFilter = (props) => {
  const { district } = props;
  const { t } = useTranslation();
  // const hashUrl = '#';

  return (
    <div className="clsmap-block mb-3">
      <div className="clsfeed-filter-blk mb-3">
        <div className="clsanalytics-title">
          <h6 className="mb-2">
            <NavLink to={'/dashboard/civicmap'} activeClassName="active" exact>
              <img
                src={require('../../../assets/img/back.svg').default}
                alt={t(`Back`)}
              />{' '}
              {t(`Back to CivicMap`)}
            </NavLink>
          </h6>
          {district != null ? (
            <>
              <h4>{district.name}</h4>
              <h6 className="clsscl-district mt-3">
                <em></em> {district.level2}
              </h6>
            </>
          ) : (
            ''
          )}
        </div>
        <div className={`civic-filter-modal`}>
          {/* <CivicModal id={`filter-modal`} title={`Filter`} dismissal={filterDismissal} footer={FilterFooter} modalClass={`clsmap-filter-modal`} footerClass={`text-center mt-4`}>
            <MapFilter {...props} />
        </CivicModal> */}
        </div>
        <div className="clsevents-filter-right">
          <ul>
            {/* <li>
                <div className="clsfeed-filter-blk clsfeed-filter mb-0">
                    <a href={hashUrl} onClick={()=> {mapChangeByOption('filterDismissal', false)}} data-toggle="modal" data-target="#filter-modal">
                        <img src={require('../../../assets/img/filter.svg').default }  alt="Filter" />
                        <span>Filter</span>
                    </a>
                </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OfficialFilter;
