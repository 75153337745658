import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const ErrorPageSessionId = (props) => {
    const {t} = useTranslation();

    const [slSessionCookieExist, setSlSessionCookieExist] = useState(false);
    const [sessionId, setSessionId] = useState('No session ID found');

    useEffect(() => {
        // Get all cookies
        let allCookies = document.cookie.split(';');

        // Find the SL cookie
        let slCookieIndex = -1;

        allCookies.forEach((el, index) => {
            if (el.indexOf('SL_') > -1) {
                slCookieIndex = index;
            }
        });

        if (slCookieIndex > -1) {
            let slCookie = allCookies[slCookieIndex];

            // Split the SL cookie to get the key and value
            let slCookieArray = slCookie.split('=');

            // Convert SL cookie value to JSON to easily get SL session ID
            let slCookieValue = JSON.parse(slCookieArray[1]);

            // Get SL session id
            let slSessionID = Object.values(slCookieValue)[0].sessionId;

            setSlSessionCookieExist(true);
            setSessionId(slSessionID);
        }
    }, []);

    return <>
        {slSessionCookieExist && <div>{t(`Don't forget to mention your unique session ID`)}: {sessionId}.</div>}
    </>;
}

export default ErrorPageSessionId;