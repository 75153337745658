/**
 *
 * Display All Civic Engagement List
 *
 */

import React, { useEffect, useState } from 'react';
import Survey from '../Engagement/survey';
import Post from '../Engagement/post';
import Discussion from '../Engagement/discussion';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

const onScroll = debounce((entry, checkStatus) => {
  checkStatus(entry);
}, 100);
let viewIdsArr = [];

const CivicList = (props) => {
  const { engagements, setViewedStatus, location } = props;
  const { t } = useTranslation();
  const [viewedList, setViewedList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const uri = location.pathname.replace(/^\/|\/$/g, '');

  useEffect(() => {
    if (loaded === false) {
      engagements.forEach((engagement, index) => {
        if (engagement.viewStatus === true) {
          setViewedList([...viewedList, ...[engagement.id]]);
        }
      });
      setLoaded(true);
    }
  }, [loaded, engagements, viewedList, setViewedList, setLoaded]);

  const MAX_NUM_AUTOMATIC_TRIGGER = 1;
  useEffect(() => { // Automatically trigger view for the first (MAX_NUM_AUTOMATIC_TRIGGER) posts
    if (engagements.length > 0) {
      for (let i = 0; i < Math.min(engagements.length, MAX_NUM_AUTOMATIC_TRIGGER); i++) {
        setViewedStatus(engagements[i].id);
      }
    }
  }, [engagements])

  useEffect(() => {
    if (engagements.length > 0 && engagements.length <= 2) {
      engagements.forEach((engagement, index) => {
        if (index === 0) {
          if (
            engagement.viewStatus === false &&
            viewedList.includes(engagement.id) === false &&
            viewIdsArr.includes(engagement.id) === false
          ) {
            if (viewIdsArr.includes(engagement.id) === false) {
              viewIdsArr.push(engagement.id);
            }
            setViewedList([...viewedList, ...[engagement.id]]);
            setViewedStatus(engagement.id);
          }
        }
      });
    }
  }, [engagements, setViewedStatus, viewedList]);

  var observer = new IntersectionObserver(onIntersection, {
    threshold: 1,
    root: null,
  });
  function onIntersection(entries, opts) {
    entries.forEach((entry) => {
      onScroll(entry, checkViewStatus);
    });
  }

  const checkViewedPost = () => {
    const blocks = document.querySelectorAll('.engagement-view-div');
    blocks.forEach((block) => observer.observe(block));
  };

  setInterval(checkViewedPost, 500);

  const checkViewStatus = (entry) => {
    if (entry?.['intersectionRatio'] === 1 || entry?.boundingClientRect?.top < entry?.rootBounds?.top) {
      // const rect = entry.target.getBoundingClientRect(); // I don't think this restriction does anything, but I'll leave it in for future reference.
      // const isInViewport =
      //   rect.top >= 0 &&
      //   rect.left >= 0 &&
      //   rect.bottom <=
      //     (window.innerHeight || document.documentElement.clientHeight) &&
      //   rect.right <=
      //     (window.innerWidth || document.documentElement.clientWidth);
      if (
        viewedList.includes(parseInt(entry.target.id)) === false &&
        viewIdsArr.includes(parseInt(entry.target.id)) === false
      ) {
        setViewedList([...viewedList, ...[parseInt(entry.target.id)]]);
        setViewedStatus(entry.target.id);
        if (viewIdsArr.includes(parseInt(entry.target.id)) === false) {
          viewIdsArr.push(parseInt(entry.target.id));
        }
      }
    }
  };

  return (
    <>
      {loaded === true ? (
        engagements.length === 0 ? (
          <div className={'mb-3 no-engagement'}>
            <span className={'sad-img'}>
              <img
                src={require('../../../assets/img/sad.svg').default}
                alt="No Engagements"
              />
            </span>
            <span className="no-engagement-txt">
              {uri === 'dashboard'
                ? t(`You don’t have any engagements here.`)
                : t(`None of your public officials have posted anything yet.`)}
            </span>
          </div>
        ) : (
          engagements.map((engagement, index) => (
            <div
              className={
                engagement.viewStatus === false &&
                viewedList.includes(engagement.id) === false
                  ? `engagement-view-div`
                  : ``
              }
              id={engagement.id}
              key={`engagement-index-${index}`}
            >
              {engagement.category === 'survey' ? (
                <Survey key={index} {...props} engagement={engagement} />
              ) : engagement.category === 'post' ? (
                <Post key={index} {...props} engagement={engagement} />
              ) : engagement.category === 'discussion' ? (
                <Discussion key={index} {...props} engagement={engagement} />
              ) : (
                ''
              )}
            </div>
          ))
        )
      ) : (
        ''
      )}
    </>
  );
};

export default CivicList;
