/**
 *
 * Filter Search Box
 *
 */

import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

const suggestData = debounce((key, func) => {
  func(key);
}, 500);

const SearchBox = (props) => {
  const { searchBox, searchBoxEventChange, getExploreCalendars } = props;

  const contentClass = 'clscontent-show';
  const { t } = useTranslation();

  const onSearchChange = useCallback(() => {
    suggestData(searchBox.key, getExploreCalendars);
  }, [getExploreCalendars, searchBox]);

  return (
    <div
      id={`search-box-calendar`}
      className={`clsfilter-search-blk mt-3 clssearch-field-show ${contentClass}`}
    >
      <div className="form-group">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <img
                src={require('../../../assets/img/search-1.svg').default}
                alt={t(`Search`)}
              />
            </div>
          </div>
          <input
            type="text"
            id={`search-calendar`}
            name={`search-calendar`}
            className="form-control"
            placeholder={t(`Search for calendar`)}
            value={searchBox.key}
            autoComplete={`off`}
            onChange={(e) => {
              searchBoxEventChange(e.target.value);
              onSearchChange();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
