/**
 *
 * Engagement New Form
 *
 */

import React from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import EngagementFirstStep from './engagementFirstStep';
import EngagementSecondStep from './engagementSecondStep';
import { useTranslation } from 'react-i18next';

const EngagementForm = (props) => {
  const {
    engagementFormData,
    submitEngagement,
    isEngagementLoading,
    isEngagementSubmitting,
    setPreviousStep,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitEngagement();
  };

  return (
    <div className="clsengagement-form">
      {isEngagementLoading && <LoadingIndicator />}
      <Notification module={'engagement'} />
      <form onSubmit={handleSubmit}>
        {engagementFormData.step === 1 ? (
          <EngagementFirstStep {...props} key={'first-step'} />
        ) : (
          <EngagementSecondStep {...props} key={'second-step'} />
        )}
        <div className="wrapper text-center pt-3">
          {engagementFormData.step === 2 ? (
            <>
              <div className="d-inline">
                <button
                  type="button"
                  className="btn clsyellow-btn"
                  onClick={setPreviousStep}
                  disabled={isEngagementSubmitting}
                >
                  {t(`Back`)}
                </button>
              </div>
              <div className="d-inline mr-5"></div>
            </>
          ) : (
            ''
          )}
          <div className="d-inline">
            {
              engagementFormData.step === 1 ? 
                <button
                  type="submit"
                  className={`btn clsyellow-btn ${
                    engagementFormData.step === 2 ? `engagement-submit` : ``
                  }`}
                  disabled={isEngagementSubmitting}
                >
                  {t('Continue')}
                </button>
              :
                <button
                  type="submit"
                  className={`btn clsyellow-btn ${
                    engagementFormData.step === 2 ? `engagement-submit` : ``
                  }`}
                  disabled={isEngagementSubmitting}
                  data-dismiss={"modal"}
                  aria-label={"Close"}
                  onClick={handleSubmit}
                >
                  {
                    engagementFormData.category === 'post' ?
                      engagementFormData.schedulePost === true ? 
                        t('Schedule')
                        : t('Save in Unpublished')
                      : engagementFormData.startNow === true ? 
                        t('Publish now')
                        : t('Save in Unpublished')
                  }
                </button>
            }
          </div>
        </div>
      </form>
    </div>
  );
};

export default EngagementForm;
