import { useEffect, useState } from 'react';
import { Switch } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SelectOption from '../../Common/SelectOption';
import Tooltip from "../../Common/Tooltip";
import { handleOptionValue } from '../../../utils/helper';
import { sortOptions, areaOptions } from '../../../containers/CommunityIssues/constants';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import {
  setCommunityIssueListFilter,
  setCommunityIssueListIssueCategory,
  getCommunityIssues,
  setCommunityIssueSortOrder,
  setCommunityIssueListAreaFilter,
  getMyCommunityIssues,
  geoId,
} from '../../../containers/CommunityIssues/actions';
import { 
  setIsSignUpButtonClicked, 
  setRegistrationModalOpen 
} from '../../../containers/RegisterFlow/actions';

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc"
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#FFAA32"
          }
        },
        track: {
          // Controls default (unchecked) color for the track
          backgroundColor: '#ccc',
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            backgroundColor: "#ccc"
          }
        }
      }
    }
  }
});

const CommunityIssueFilter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    allTopics,
    areaFilter,
    authenticated,
    listPage,
    listLimit,
    listFilterType,
    listIssueCategory,
    sortOrder,
    isCommunityIssuesLoading,
  } = props;

  // These are separate from Redux stored values
  const [eligibleToSignChecked, setEligibleToSignChecked] = useState(false);
  const [followingOnlyChecked, setFollowingOnlyChecked] = useState(false);
  const [categoryEntry, setCategoryEntry] = useState('');

  const [topics, setTopics] = useState({});
  useEffect(() => {
    setTopics({
      ...allTopics,
      0: {
        topicId: 0,
        name: 'All Issues'
      }
    })
  }, [allTopics]);

  if (user.roleName === 'PUBLICOFFICIAL' || user.roleName === 'ADMIN') {
    return (
      <div className='community-issue-filter'>
        <div className='official-dropdown-container'>
          {
            // TODO: "Sort:", "Area:", "Issue:" descriptors to the left of each dropdown
          }
          <div className='community-issue-filter-dropdown'>
            <SelectOption
              options={sortOptions}
              placeholder={t('Sort')}
              name={'sort'}
              value={handleOptionValue(sortOptions, sortOrder)}
              handleSelectChange={(e) => {
                window.scrollTo(0, 0);
                dispatch(getCommunityIssues({
                  page: 1,
                  limit: listLimit,
                  filter: listFilterType,
                  category: listIssueCategory,
                  sortOrder: e.value,
                  reset: true,
                }));
                dispatch(setCommunityIssueSortOrder(e.value));
              }}
            />
          </div>
          <div className='community-issue-filter-dropdown'>
            <SelectOption
              options={areaOptions}
              placeholder={t('Area')}
              name={'area'}
              value={handleOptionValue(areaOptions, areaFilter)} 
              handleSelectChange={(e) => {
                window.scrollTo(0, 0);
                dispatch(getCommunityIssues({
                  page: 1,
                  limit: listLimit,
                  filter: e.value,
                  category: listIssueCategory,
                  sortOrder: sortOrder,
                  reset: true,
                }));
                dispatch(setCommunityIssueListAreaFilter(e.value));
              }}
            />
          </div>
          <div className='community-issue-filter-dropdown'>
            <SelectOption
              options={Object.values(topics).map(topic => ({ value: topic?.topicId, label: topic?.name }))}
              placeholder={t('Filter By Issue')}
              name={'issue'}
              value={categoryEntry}
              handleSelectChange={(value) => {
                window.scrollTo(0, 0);
                setCategoryEntry(value);
                dispatch(setCommunityIssueListIssueCategory(value));
                dispatch(getCommunityIssues({
                  page: 1,
                  limit: listLimit,
                  filter: listFilterType,
                  category: value,
                  sortOrder: sortOrder,
                  reset: true,
                }));
              }}
            />
          </div>
        </div>
      </div>
    )
  }
  else return (
    <div className='community-issue-filter'>
      <div className='title'>
        <span>
          {t('Current Community Issues')}
        </span>
      </div>
      {
        !authenticated &&
          <div 
            className="clsdashboard-content-box clswhite-box mb-3 community-issue-sign-up-now-box"
          >
            <div>
              <b>{t('Sign up now')}</b>{' '}{t('to create your own community issues!')}
            </div>
            <div>
              <button
                type="button"
                className="btn clsyellow-btn"
                onClick={() => {
                  dispatch(setIsSignUpButtonClicked(true)); 
                  dispatch(setRegistrationModalOpen(true));
                }}
                style={{
                  height: 40
                }}
              >
                {t(`Sign up`)}
              </button>
            </div>
          </div>
      }
      <div className="clsfeed-filter-blk">
        <div className='community-issue-filter-toggles'>
          <div className='eligible-to-sign'>
            <ThemeProvider theme={theme}>
              <Switch 
                checked={eligibleToSignChecked}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(getCommunityIssues({
                    page: 1,
                    limit: listLimit,
                    filter: (!eligibleToSignChecked) ? 'myDistricts' : null,
                    category: listIssueCategory,
                    sortOrder: sortOrder,
                    reset: true,
                  }));
                  if (!eligibleToSignChecked) {
                    dispatch(setCommunityIssueListFilter('myDistricts'));
                  } else {
                    dispatch(setCommunityIssueListFilter(null));
                  }
                  setEligibleToSignChecked(!eligibleToSignChecked);
                  setFollowingOnlyChecked(false);
                }}
                disabled={isCommunityIssuesLoading}
              />
            </ThemeProvider>
            <p>
              {t('Eligible to Sign')}
            </p>
            <a>
              <img
                src={require('../../../assets/img/info-grey.svg').default}
                alt="Info"
                id='eligible-to-sign-info'
              />
              <Tooltip
                targetId={`eligible-to-sign-info`}
                message={t(`Display only issues you're eligible to sign and to participate in discussions.`)}
                parentDivClass={'eligible-to-sign'}
                iconDisplay={false}
                targetStatus={true}
                style={{
                  cursor: 'default',
                }}
              />
            </a>
          </div>
          <div className='following-only'>
            <ThemeProvider theme={theme}>
              <Switch 
                checked={followingOnlyChecked}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(getCommunityIssues({
                    page: 1,
                    limit: listLimit,
                    filter: (!followingOnlyChecked) ? 'following' : null,
                    category: listIssueCategory,
                    sortOrder: sortOrder,
                    reset: true,
                  }));
                  if (!followingOnlyChecked) {
                    dispatch(setCommunityIssueListFilter('following'));
                  } else {
                    dispatch(setCommunityIssueListFilter(null));
                  }
                  setEligibleToSignChecked(false);
                  setFollowingOnlyChecked(!followingOnlyChecked);
                }}
                disabled={!authenticated || isCommunityIssuesLoading}
              />
            </ThemeProvider>
            <p>
              {t('Following Only')}
            </p>
            <a>
              <img
                src={require('../../../assets/img/info-grey.svg').default}
                alt="Info"
                id='following-only-info'
              />
              <Tooltip
                targetId={`following-only-info`}
                message={t(`Display only issues you've previously commented on, signed, or followed.`)}
                parentDivClass={'following-only'}
                iconDisplay={false}
                targetStatus={true}
                style={{
                  cursor: 'default',
                }}
              />
            </a>
          </div>
        </div>
        <div className='community-issue-filter-dropdown'>
          <SelectOption
            options={Object.values(topics).map(topic => ({ value: topic?.topicId, label: topic?.name }))}
            placeholder={t('Filter By Issue')}
            name={'sort'}
            value={categoryEntry}
            handleSelectChange={(value) => {
              window.scrollTo(0, 0);
              setCategoryEntry(value);
              dispatch(setCommunityIssueListIssueCategory(value));
              dispatch(getCommunityIssues({
                page: 1,
                limit: listLimit,
                filter: listFilterType,
                category: value,
                sortOrder: sortOrder,
                reset: true,
              }));
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allTopics: state.communityIssues.allTopics,
    sortOrder: state.communityIssues.sortOrder,
    areaFilter: state.communityIssues.areaFilter,
    authenticated: state.authentication.authenticated,
    listPage: state.communityIssues.listPage,
    listLimit: state.communityIssues.listLimit,
    listFilterType: state.communityIssues.listFilterType,
    listIssueCategory: state.communityIssues.listIssueCategory,
    sortOrder: state.communityIssues.sortOrder,
    isCommunityIssuesLoading: state.communityIssues.isCommunityIssuesLoading,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueFilter);