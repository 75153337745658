/**
 *
 * Tab Content
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const TabContent = (props) => {
  const { children, linkId, effectClass, isActive } = props;

  return (
    <div
      className={`tab-pane ${effectClass} ${
        isActive === true ? ' show active' : ''
      }`}
      id={linkId}
      role="tabpanel"
      aria-labelledby={`${linkId}-tab`}
    >
      {children}
    </div>
  );
};

TabContent.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

TabContent.defaultProps = {
  linkId: '',
  effectClass: 'fade',
  isActive: false,
};

export default TabContent;
