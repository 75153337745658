/**
 *
 * Editor State To Html
 *
 */

import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

const options = {
  entityStyleFn: (entity) => {
    if (entity.get('type').toLowerCase() === 'link') {
      const data = entity.getData();
      return {
        element: 'a',
        attributes: {
          href: data.url,
          target: data.targetOption,
        },
      };
    }
  },
};

const convertFromJSONToHTML = (text) => {
  try {
    return { __html: stateToHTML(convertFromRaw(JSON.parse(text)), options) };
  } catch (exp) {
    return { __html: '' };
  }
};

export default convertFromJSONToHTML;
