/**
 *
 * Petition Filter Dropdown Suggestion Box
 *
 */

import LoadingIndicator from '../../Common/LoadingIndicator';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

const suggestBoxDisplay = debounce((func, type, key, value) => {
  func(type, key, value);
}, 500);

const SuggestionBox = (props) => {
  const {
    type,
    suggestionBox,
    suggestionBoxPetitionChange,
    filterSelected,
    followPetitionSuggested,
  } = props;
  const hashUrl = '#';
  const { t } = useTranslation();
  if (document.getElementById('suggestion-box-' + type)) {
    document.addEventListener('click', (event) => {
      if (
        event.target.id !== 'search-' + type &&
        event.target.id !== 'suggestion-box-' + type &&
        event.target.className !== 'suggest-item' &&
        suggestionBox[type].display === true
      ) {
        suggestBoxDisplay(suggestionBoxPetitionChange, type, 'display', false);
      }
    });
  }

  return typeof type !== 'undefined' ? (
    <div
      id={`suggestion-box-${type}`}
      className="clsfilter-search-show-content"
      style={{
        minHeight: '45px',
        display: suggestionBox[type].display === false ? 'none' : 'block',
      }}
    >
      <ul>
        {suggestionBox[type].loading === false ? (
          suggestionBox[type]['items'].length > 0 ? (
            <>
              {suggestionBox[type]['items'].map((item, index) => (
                <li
                  key={`${type}-suggestion-${index}`}
                  className={`suggest-item`}
                >
                  <span className={`suggest-item`}>{item.name}</span>
                  {filterSelected[type].indexOf(item.id) !== -1 ? (
                    <span className={`suggest-item added-cls`}>
                      <img
                        src={require('../../../assets/img/check.svg').default}
                        alt="Added"
                        width="11"
                      />
                      {t(`Added`)}
                    </span>
                  ) : (
                    <span>
                      <a
                        href={hashUrl}
                        className={`suggest-item`}
                        onClick={() => {
                          followPetitionSuggested(type, item);
                        }}
                      >
                        <img
                          src={
                            require('../../../assets/img/add-plus.svg').default
                          }
                          alt="Add"
                          width="10"
                        />{' '}
                        {t(`Add`)}
                      </a>
                    </span>
                  )}
                </li>
              ))}
            </>
          ) : (
            <li>
              <span>{t(`No records found.`)}</span>
            </li>
          )
        ) : (
          <LoadingIndicator
            position={`relative`}
            height={'35px'}
            backgroundSize={'35px'}
          />
        )}
      </ul>
    </div>
  ) : (
    ''
  );
};

export default SuggestionBox;
