/**
 *
 * Chart Box Slides
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import CarouselSlider from '../../Common/CarouselSlider';
import { responsiveOneItemCarousel } from '../../Common/CarouselSlider/helpers';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import NoData from './noData';

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartBox = (props) => {
  const { analyticsData } = props;
  const [visibleStatus, setVisibleStatus] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [arrow, setArrow] = useState(false);
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    if (loaded === false) {
      let data = {};
      let canShow = [];
      analyticsData.survey.question.forEach((question, index) => {
        data[question.id] = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: ['#089EEA', '#FFAA33', '#87D9FF', '#FFD121'],
              borderColor: ['#089EEA', '#FFAA33', '#87D9FF', '#FFD121'],
              borderWidth: 1,
            },
          ],
        };

        data[question.id].title = question.title;

        question.choice.forEach((choice, cindex) => {
          if (typeof choice.actualNumber !== 'undefined') {
            data[question.id].labels.push(choice.value);
            data[question.id].datasets[0].data.push(parseInt(choice.actualNumber));
            if (canShow.indexOf(question.id) === -1) {
              canShow.push(question.id);
            }
          }
        });
      });
      setVisibleStatus(canShow);
      setChartData(data);
      setLoaded(true);
    }
  }, [loaded, setChartData, analyticsData.survey.question, setVisibleStatus]);

  return (
    <>
      {loaded === true ? (
        <div
          className={`clsanalytics-donut-chart-box clswhite-box`}
          onMouseOver={() => {
            setArrow(true);
          }}
          onMouseLeave={() => {
            setArrow(false);
          }}
        >
          <CarouselSlider
            swipeable={true}
            showDots={false}
            autoPlay={false}
            focusOnSelect={false}
            slides={analyticsData.survey.question}
            responsive={responsiveOneItemCarousel}
            partialVisible={true}
            containerClass="slide-container"
            itemClass={`slide-item`}
            autoPlaySpeed={3000}
            shouldResetAutoplay={false}
            arrows={arrow}
            removeArrowOnDeviceType={analyticsData.survey.question.length > 1 ? [] : Object.keys(responsiveOneItemCarousel)}
          >
            {analyticsData.survey.question.map((question, index) => (
              <div className={`slide-content`} key={`question-${index}`}>
                <div className={`chart-data`}>
                  <div style={{ marginBottom: 20 }}>{chartData[question.id].title}</div>
                  {visibleStatus.indexOf(question.id) !== -1 ? (
                    <Doughnut
                      ref={chartRef}
                      data={chartData[question.id]}
                      options={{
                        aspectRatio: 3,
                        plugins: {
                          title: {
                            display: true,
                            text: chartData[question.id].title,
                          },
                          legend: {
                            display: true,
                            position: 'right'
                          }
                        },
                      }}
                    />
                  ) : (
                    <NoData cssClass={`pieChartNoData`} />
                  )}
                </div>
              </div>
            ))}
          </CarouselSlider>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ChartBox;
