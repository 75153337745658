/**
 *
 * PushNotification Page
 *
 */

import React from 'react';
import PublicResidentNotification from './publicResidentNotification';

const PushNotification = (props) => {
  return (
    <>
      <PublicResidentNotification {...props} />
    </>
  );
};

export default PushNotification;
