/*
 *
 * Register actions
 *
 */

import axios from 'axios';
import { BASE_API_URL } from '../../constants';
import { setTab } from '../Login/actions';
import { addNotification } from '../Notification/actions';
import {
  REGISTER_CHANGE,
  REGISTER_RESET,
  SET_REGISTER_LOADING,
  SET_REGISTER_FORM_ERRORS,
  SET_REGISTER_SUBMITTING,
  RESET_APP,
} from './constants';
import { formatPhoneNumber } from '../../utils/helper';
import { allFieldsValidation } from '../../utils/validation';

export const resetRegisterState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const registerChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    if (name === 'officialPhoneNumber' || name === 'phoneNumber') {
      formData[name] = formatPhoneNumber(value);
    } else {
      formData[name] = value;
    }

    if (name === 'signUpOfficialEmail') {
      if (value === true) {
        formData['officialEmail'] = getState().register.registerFormData.email;
      }
    }
    return dispatch({ type: REGISTER_CHANGE, payload: formData });
  };
};

export const fillAddress = (field, address) => {
  return async (dispatch, getState) => {
    if (address === null || address === undefined) return;
    let formData = { address: '', zipCode: '', city: '', state: '' };
    for (const component of address) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          formData.address = component.long_name;
          break;
        }

        case 'route': {
          formData.address = formData.hasOwnProperty('address')
            ? formData.address + ' ' + component.short_name
            : component.short_name;
          break;
        }

        case 'postal_code': {
          formData.zipCode = component.long_name;
          break;
        }
        case 'locality':
          formData.city = component.long_name;
          break;
        case 'administrative_area_level_1': {
          formData.state = component.short_name;
          break;
        }
        default: {
          field.current.value = formData.address;
          break;
        }
      }
    }
    return dispatch({ type: REGISTER_CHANGE, payload: formData });
  };
};

export const validateBeforeEmailVerification = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let user = getState().register.registerFormData;
      let rulesOfficial = {
        firstName: 'required',
        lastName: 'required',
        officialEmail: 'required|email',
      };

      const { isValid, errors } = allFieldsValidation(user, rulesOfficial, {
        'required.officialEmail': 'Email is required.',
        'email.officialEmail': 'Email format is invalid.',
        'required.firstName': 'Required.',
        'required.lastName': 'Required',
      });

      if (!isValid) {
        reject(errors);
      } else {
        resolve(true);
      }
    });
  };
};

/*export const verifyOfficialEmail = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(validateBeforeEmailVerification()).then( async () => {
        dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: {} });
        let user = getState().register.registerFormData;
        dispatch({ type: SET_REGISTER_SUBMITTING, payload: true });
        dispatch({ type: SET_REGISTER_LOADING, payload: true });
        let formData = {}; let officialEmailError = {};
          await axios.post(BASE_API_URL + '/official-email', {email:user.officialEmail.toLowerCase()}).then(response => {
          if (response.data.success === 'true') {
            formData['officialEmailVerification'] = true;
            dispatch({ type: REGISTER_CHANGE, payload: formData });
            dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: {} });
          }
      }).catch(error => {
        formData['officialEmailVerification'] = false;
        formData['signUpOfficialEmail'] = false;
        dispatch({ type: REGISTER_CHANGE, payload: formData });
        officialEmailError = {officialEmail: [error.response.data.error]};
        dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: officialEmailError });
        //dispatch(addNotification({type: 'error', message: error?.response?.data?.error, module: 'register'}));
      }).finally(() => {
        dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
        dispatch({ type: SET_REGISTER_LOADING, payload: false });
      });
      resolve(officialEmailError);
    }).catch( (error) => {
      dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: error });
      reject(error);
    });
  });
  }
};*/

export const register = () => {
  return async (dispatch, getState) => {
    let user = getState().register.registerFormData;

    const rules = {
      email: 'required|email',
      password: 'required|password',
      signUpType: 'required',
    };

    if (user.step === 1) {
      const { isValid, errors } = allFieldsValidation(user, rules, {
        'required.email': 'Email is required.',
        'email.email': 'Email format is invalid.',
        'required.password': 'Password is required.',
        'required.signUpType': 'Required',
      });

      if (!isValid) {
        return dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: errors });
      }
      //Check whether the email is already taken
      dispatch({ type: SET_REGISTER_SUBMITTING, payload: true });
      dispatch({ type: SET_REGISTER_LOADING, payload: true });
      let validStep = true;
      await axios
        .post(
          BASE_API_URL + '/user-email',
          {
            email: user.email.toLowerCase(),
            signuptype: user.signUpType.value,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
          dispatch({ type: SET_REGISTER_LOADING, payload: false });
          if (response.data.success === 'true') {
            validStep = true;
          } else {
            validStep = false;
          }
        })
        .catch((error) => {
          validStep = false;
          let emailExistError = { email: [error.response.data.error] };
          dispatch({
            type: SET_REGISTER_FORM_ERRORS,
            payload: emailExistError,
          });
          dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
          dispatch({ type: SET_REGISTER_LOADING, payload: false });
        });

      if (validStep === false) return;
      else dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: {} });
    }

    if (user.step === 1) {
      let formData = {};
      if (
        user.signUpType.value === 'public_official' ||
        user.signUpType.value === 'elected_official'
      ) {
        formData['step'] = 2;
        return dispatch({ type: REGISTER_CHANGE, payload: formData });
      } else {
        formData['step'] = 3;
        return dispatch({ type: REGISTER_CHANGE, payload: formData });
      }
    }

    if (user.step === 2) {
      //Check the official email is valid or not
      dispatch(validateRegisterForm())
        .then(async () => {
          dispatch(registerSubmit());
        })
        .catch((error) => {
          dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
          dispatch({ type: SET_REGISTER_LOADING, payload: false });
        });
    } else {
      dispatch(validateRegisterForm())
        .then(async () => {
          dispatch(registerSubmit());
        })
        .catch((error) => {
          dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
          dispatch({ type: SET_REGISTER_LOADING, payload: false });
        });
    }
  };
};

//Registration Form Validation
export const validateRegisterForm = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let user = getState().register.registerFormData;

      const reesidentRulesSecondStep = {
        firstName: 'required',
        lastName: 'required',
        address: 'required',
        city: 'required',
        state: 'required',
        zipCode: 'required',
        phoneNumber: 'telephone',
      };

      let rulesOfficial = {};
      rulesOfficial = {
        firstName: 'required',
        lastName: 'required',
        position: 'required',
        district: 'required',
        ocdId: 'required',
        newEmail: [{ required_if: ['manualVerification', true] }],
        phoneNumber: [
          { required_if: ['manualVerification', true] },
          'telephone_required',
        ],
      };

      if (
        user.signUpType.value === 'public_official' ||
        user.signUpType.value === 'elected_official'
      ) {
        const { isValid, errors } = allFieldsValidation(user, rulesOfficial, {
          'required.firstName': 'Required',
          'required.lastName': 'Required',
          'required.position': 'Required',
          'required.district': 'Required',
          'required.ocdId':
            'Please select the valid district from the auto suggestion box.',
          'required.newEmail': 'Required',
          'required_if.newEmail':
            'Please enter the email for manual verification',
          'required.phoneNumber': 'Required',
          'required_if.phoneNumber': 'Please enter the phone number',
        });
        if (!isValid) {
          dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: errors });
          return reject(errors);
        } else {
          let formData = getState().register.registerFormData;
          let credentialValidation = formData.validateCredentials;
          if (credentialValidation === false) {
            dispatch({ type: SET_REGISTER_SUBMITTING, payload: true });
            dispatch({ type: SET_REGISTER_LOADING, payload: true });
            let data = {
              first_name: formData.firstName,
              last_name: formData.lastName,
              position: formData.position,
              my_district: formData.district,
              ocdId: formData.ocdId,
              email: formData.email,
            };
            await axios
              .post(BASE_API_URL + '/validateCredentials', data, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((response) => {
                if (response.data.success === 'true') {
                  let status = response.data.data.combinationMatches;
                  let userFormData = {};
                  userFormData['validateCredentials'] = true;
                  //userFormData['manualVerification'] = true;
                  userFormData['manualVerification'] =
                    status === true ? false : true;
                  userFormData['showEmailConfirmation'] = status;
                  if (status === true) {
                    userFormData['poVerifiedEmail'] = response.data.data.email;
                  } else if (status === false) {
                    userFormData['poVerifiedEmail'] = '';
                    let newEmailError = {
                      newEmail: [
                        "Unfortunately, we don't have someone with your name affiliated with " +
                          formData.district +
                          '. Please enter your email and phone number below, and we will reach out within 24 hours.',
                      ],
                    };
                    dispatch({
                      type: SET_REGISTER_FORM_ERRORS,
                      payload: newEmailError,
                    });
                  }
                  dispatch({ type: REGISTER_CHANGE, payload: userFormData });
                }
              })
              .catch((error) => {
                dispatch(
                  addNotification({
                    type: 'error',
                    message: error?.response?.data?.error,
                    module: 'register',
                  }),
                );
                let userFormData = {};
                userFormData['validateCredentials'] = true;
                userFormData['manualVerification'] = true;
                dispatch({ type: REGISTER_CHANGE, payload: userFormData });
              })
              .finally(() => {
                dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
                dispatch({ type: SET_REGISTER_LOADING, payload: false });
              });
            return reject(false);
          } else {
            //submit the register form
            return resolve(true);
          }
        }
      } else {
        if (user.step < 6) {
          setNextStep();
          return reject(false);
        }
        const { isValid, errors } = allFieldsValidation(
          user,
          reesidentRulesSecondStep,
          {
            'required.firstName': 'Required',
            'required.lastName': 'Required',
            'required.address': 'Required',
            'required.city': 'Required',
            'required.state': 'Required',
            'required.zipCode': 'Required',
          },
        );
        if (!isValid) {
          dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: errors });
          return reject(errors);
        }
      }
      dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: {} });

      if (user.step === 6) {
        return resolve(true);
      }

      /*if(user.step === 3) {
          let formData = {};
          formData['step'] = 4;
          dispatch({ type: REGISTER_CHANGE, payload: formData });
          return reject(false);
      }

      if(user.step === 4) {
        //Resident registration validation
        const reesidentRulesThirdStep = { phoneNumber: 'telephone' }
        const { isValid, errors } = allFieldsValidation(user, reesidentRulesThirdStep);
        if (!isValid) {
          dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: errors });
          return reject(errors);
        } else {
          dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: {} });
        }
      }
      return resolve(true);*/
    });
  };
};

//Registration Form Submission
export const registerSubmit = () => {
  return async (dispatch, getState) => {
    let user = getState().register.registerFormData;
    let registrationData = {};
    let phoneNumber =
      user.signUpType.value === 'public_official' ||
      user.signUpType.value === 'elected_official'
        ? ''
        : user.phoneNumber;
    phoneNumber = phoneNumber.replace(/\s/g, '');

    if (
      user.signUpType.value === 'public_official' ||
      user.signUpType.value === 'elected_official'
    ) {
      //Old version code
      /*let position = user.positionText !== '' ? user.positionText : user.position.value;
      registrationData = {
        first_name : user.firstName, last_name: user.lastName, email: user.email.toLowerCase(), password: user.password, address: user.address, city: user.city, state: user.state, zip_code: user.zipCode, dob: user.dob, phone_number: phoneNumber, gender: '', citizen: user.signupStatus, personal_id: '', high_edu_degree: '', emp_status: '', race: '', ethnicity: '', maritial_status: '', political_affiliation: '', religion: '', signuptype: user.signUpType.value, alr_off_email:user.signUpOfficialEmail ? 1: 0, off_email_addr: user.officialEmail, off_verify_by: user.verificationBy.value, position: position
      };
      if(user.officialEmailVerification === true) {
        delete registrationData.phone_number;
        delete registrationData.off_verify_by;
        delete registrationData.position;
      }*/
      //Revised version
      if (phoneNumber === '+1') phoneNumber = '';
      registrationData = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email.toLowerCase(),
        password: user.password,
        signuptype: user.signUpType.value,
        position: user.position,
        district: user.district,
        ocdId: user.ocdId,
        manualVerification: user.manualVerification,
        newEmail: user.newEmail,
        phone_number: user.phoneNumber,
      };
    } else {
      if (phoneNumber === '+1') phoneNumber = '';
      /* let race = '';
      Object.keys(user.race).forEach(function(key){
        if(key !== '0') {
          race += ',';
        }
        race += '"value":"'+user.race[key].value+'"';
      });
      race = '{'+race+'}';
      
      registrationData = {
        first_name : user.firstName, last_name: user.lastName, email: user.email.toLowerCase(), password: user.password, address: user.address, city: user.city, state: user.state, zip_code: user.zipCode, dob: user.dob, phone_number: phoneNumber, gender: user.gender.value, citizen: user.signupStatus.value, personal_id: user.personalId, high_edu_degree: user.highestEducationDegree.value, emp_status: user.employmentStatus.value, race: race, ethnicity: user.ethnicity.value, maritial_status: user.martialStatus.value, political_affiliation: user.politicalAffiliation.value, religion: user.religion.value, signuptype: user.signUpType.value, alr_off_email:user.signUpOfficialEmail ? 1: 0, off_email_addr: user.officialEmail, off_verify_by: '', position: ''
      }*/
      registrationData = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email.toLowerCase(),
        password: user.password,
        address: user.address,
        city: user.city,
        state: user.state,
        zip_code: user.zipCode,
        phone_number: phoneNumber,
        signuptype: user.signUpType.value,
        alr_off_email: user.signUpOfficialEmail ? 1 : 0,
        off_email_addr: user.officialEmail,
        off_verify_by: '',
        position: '',
      };
    }

    dispatch({ type: SET_REGISTER_SUBMITTING, payload: true });
    dispatch({ type: SET_REGISTER_LOADING, payload: true });

    //eslint-disable-next-line
    await axios
      .post(BASE_API_URL + '/register', registrationData)
      .then((response) => {
        const firstName = user.firstName;
        if (
          user.signUpType.value === 'public_official' ||
          user.signUpType.value === 'elected_official'
        ) {
          if (user.manualVerification === true) {
            const title = `Thank you! We will reach out to you within 24 hours.`;
            dispatch(
              addNotification({
                type: 'success',
                message: title,
                module: 'login',
                displayFor: 10000,
              }),
            );
          } else {
            const title = `Hey${
              firstName ? ` ${firstName}` : ''
            }, Welcome, we sent an email to confirm your email address. Please check it`;
            dispatch(
              addNotification({
                type: 'success',
                message: title,
                module: 'login',
                displayFor: 10000,
              }),
            );
          }

          dispatch({ type: REGISTER_RESET });
          return dispatch(setTab(1));
        } else {
          dispatch({ type: REGISTER_RESET });
          let formData = {};
          localStorage.setItem('email', user.email);
          formData['step'] = 7;
          dispatch({ type: REGISTER_CHANGE, payload: formData });
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'register',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
        dispatch({ type: SET_REGISTER_LOADING, payload: false });
      });
  };
};

//Navigate To Previous Step
export const setPriviousStep = () => {
  let formData = {};
  return async (dispatch, getState) => {
    let step = getState().register.registerFormData.step;

    if (step === 2 || step === 3) {
      formData['step'] = 1;
    } else if (step === 4) {
      formData['step'] = 3;
    } else if (step === 5) {
      formData['step'] = 4;
    } else if (step === 6) {
      formData['step'] = 5;
    } else {
      formData['step'] = step;
    }
    return dispatch({ type: REGISTER_CHANGE, payload: formData });
  };
};

//Navigate To Next Step
export const setNextStep = () => {
  return async (dispatch, getState) => {
    let step = getState().register.registerFormData.step;
    let formData = {};
    if (step === 3) {
      formData['step'] = 4;
      dispatch({ type: REGISTER_CHANGE, payload: formData });
    } else if (step === 4) {
      formData['step'] = 5;
      dispatch({ type: REGISTER_CHANGE, payload: formData });
    } else if (step === 5) {
      formData['step'] = 6;
      dispatch({ type: REGISTER_CHANGE, payload: formData });
    } else if (step === 6) {
      dispatch(register());
    }
  };
};

//To Trigger Official Email Verification Process
/*export const emailVerification = () => {
  return async (dispatch, getState) => {
    dispatch(verifyOfficialEmail()).then( async (response) => {
      dispatch(validateRegisterForm()).then( async () => {
        dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
        dispatch({ type: SET_REGISTER_LOADING, payload: false });
      }).catch((error) => {
        if(response.hasOwnProperty('officialEmail')){
          let errors = {...getState().register.formErrors, ...response};
          dispatch({ type: SET_REGISTER_FORM_ERRORS, payload: errors });
        }
      });
    }).catch((error) => {
      dispatch({ type: SET_REGISTER_SUBMITTING, payload: false });
      dispatch({ type: SET_REGISTER_LOADING, payload: false });
    });
  }
}*/

export const redirectToLogin = () => {
  return async (dispatch, getState) => {
    const msg = `Hey, Welcome, we sent an email to confirm your email address. Please check it`;
    dispatch(
      addNotification({
        type: 'success',
        message: msg,
        module: 'login',
        displayFor: 10000,
      }),
    );
    dispatch({ type: REGISTER_RESET });
    registerChange('message', '');
    return dispatch(setTab(1));
  };
};

export const districtBoxChange = (key, value) => {
  return async (dispatch, getState) => {
    let suggestionBox = getState().register.registerFormData.suggestionBox;
    suggestionBox = { ...suggestionBox, ...{ [key]: value } };
    dispatch(registerChange('suggestionBox', suggestionBox));
  };
};

//getDistrictData
export const getDistrictData = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let value = getState().register.registerFormData.district;
      let formData = {};
      let suggestionBox = getState().register.registerFormData.suggestionBox;
      suggestionBox['loading'] = true;
      formData['suggestionBox'] = { ...suggestionBox };
      dispatch(registerChange('suggestionBox', suggestionBox));
      await axios
        .get(BASE_API_URL + '/ocdidSuggestion?keyword=' + value)
        .then((response) => {
          if (response.data.success === 'true') {
            suggestionBox = {};
            suggestionBox = {
              items: response.data.data,
              loading: false,
              display: true,
            };

            dispatch(registerChange('suggestionBox', suggestionBox));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};
