import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import actions from "../../../actions";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { predictUserData } from "../../../containers/RegisterFlow/actions";

const ResidentStepThree = (props) => {

  const {
    registration,
    setStep,
    verifyUserRegistration,
    showCloseButton,
    updateUserData
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    setStep(4);
  }

  const finishRegistration = () => {
    updateUserData()
      .then(() => verifyUserRegistration());
  }

  useEffect(() => {
    if (!registration.registerFormData.firstName) {
      dispatch(predictUserData());
    }
  }, [registration.registerFormData.firstName]);

  return <>
    <Modal.Header closeButton={showCloseButton}>
      <Modal.Title className="w-100 text-center">
        {t(`Welcome to CivicBell`)}, {registration.registerFormData.firstName}!
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="form-group col-md-12">
        <div>
          {t(`Thank you! For verification purposes, we've received the following information from your cell phone
                carrier`)}:
        </div>
      </div>

      <div className="form-group col-md-12">
        <div className="form-row" style={{
          border: '1px solid black',
          borderRadius: '13px',
          display: 'flex',
          flexDirection: 'row'
        }}>
          <img src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
            className="img-fluid"
            style={{
              width: '25%'
            }}
          />
          <div>
            <div>{t('First name')}</div>
            <h4>{registration.registerFormData.firstName}</h4>

            <div>{t('Last name')}</div>
            <h4>{registration.registerFormData.lastName}</h4>
          </div>
        </div>
      </div>

      <div className="form-group col-md-12">
        <div>{t(`If this is you, you're all set and ready to engage!`)}</div>
      </div>

      <div 
        className="form-group col-md-12"
      >
        <div 
          className="form-row"
            style={{
              textAlign: 'center',
            }}
        >
          <div className="form-group col-md-6">
            <button
              type="button"
              id="regevent_confirm_name"
              className="btn clsyellow-btn"
              onClick={finishRegistration}
              disabled={registration.isRegisterLoading}
              style={{
                color: 'black',
                fontWeight: 'bold',
                width: 180
              }}>
              {t(`Yes, that's me!`)}
            </button>
          </div>

          <div className="form-group col-md-6">
            <button
              type="button"
              id="regevent_change_name"
              className="btn clsyellow-btn"
              onClick={handleOnClick}
              disabled={registration.isRegisterLoading}
              style={{
                color: 'black',
                fontWeight: 'bold',
                width: 180
              }}>
              {t(`No, change name`)}
            </button>
          </div>
        </div>
      </div>
    </Modal.Body>
  </>;
}

const mapStateToProps = (state) => {
  return {
    registration: state.registration
  };
};

export default connect(mapStateToProps, actions)(ResidentStepThree);
