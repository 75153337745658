/**
 *
 * Tooltip
 *
 */

import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const Tooltip = (props) => {
  const {
    targetId,
    placement,
    title,
    message,
    styleClass,
    targetStatus,
    iconDisplay,
    labelTxt,
    parentDivClass,
    isGlobe,
    autohide,
    style,
    tooltipStyle,
  } = props;

  return (
    <>
      {targetId ? (
        <div
          className={`btn clsinfo ${parentDivClass}`}
          {...(targetStatus === false ? { id: targetId } : {})}
          style={{
            ...style,
          }}
        >
          {targetStatus === false && iconDisplay === true ? (
            <>
              {
                isGlobe ?
                  <img
                    src={require('../../../assets/img/icon-language.svg').default}
                    alt="Globe"
                    className={styleClass !== 'undefined' ? styleClass : ''}
                  />
                :
                  <img
                    src={require('../../../assets/img/info.svg').default}
                    alt="Info"
                    className={styleClass !== 'undefined' ? styleClass : ''}
                  />
              }
            </>
          ) : (
            <span className={styleClass}>{labelTxt}</span>
          )}
          <UncontrolledTooltip
            boundariesElement={''} 
            content={'test'}
            placement={placement} 
            target={targetId} 
            autohide={autohide} 
            style={{
              ...tooltipStyle,
              'pointer-events': false,
            }}
            transition={null}
          >
            {title !== '' ? (
              <>
                <strong>{title}</strong>
                <br />
              </>
            ) : (
              ''
            )}
            { message }
          </UncontrolledTooltip>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

Tooltip.defaultProps = {
  placement: 'top',
  title: '',
  targetStatus: false,
  iconDisplay: true,
  labelTxt: '',
  parentDivClass: '',
  autohide: true,
};

export default Tooltip;
