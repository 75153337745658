/**
 *
 * Petition view and Filter
 *
 */

import { useEffect, useState } from 'react';
import SelectOption from '../../Common/SelectOption';
import { handleOptionValue } from '../../../utils/helper';
import { sortOptionsPetition } from '../../../containers/Petitions/constants';
import CivicModal from '../../Common/CivicModal';
import PetitionFilter from './petitionFilter';
import FilterFooter from './filterFooter';
import NewPetitionForm from './newPetitionForm';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const {
    sort,
    petitionChangeByOption,
    filterDismissal,
    getFilterPetitionItems,
    petitionFormData,
  } = props;
  const hashUrl = '#';
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading === true) {
      const filterItem = async () => {
        await getFilterPetitionItems().then(async () => {
          setLoading(false);
        });
      };
      filterItem();
    }
  }, [loading, getFilterPetitionItems]);

  const { t } = useTranslation();

  return (
    <div className="clsfeed-filter-blk mb-3">
      <div className="clsfeed-dropdown">
        <div className="dropdown">
          <div className="form-group">
            <SelectOption
              name={'sort'}
              placeholder={t('View')}
              value={handleOptionValue(sortOptionsPetition, sort)}
              options={sortOptionsPetition}
              handleSelectChange={(value) => {
                petitionChangeByOption('sort', value.value);
              }}
            />
            <label>{t('View')}</label>
          </div>
        </div>
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`filter-modal`}
          title={t('Filter')}
          dismissal={filterDismissal}
          footer={FilterFooter(props)}
          footerClass={`text-center mt-4`}
        >
          <PetitionFilter {...props} />
        </CivicModal>
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`form-petition`}
          title={t('New Initiative')}
          dismissal={petitionFormData.dismissal}
          modalClass={'clsnew-petition-modal'}
        >
          <NewPetitionForm {...props} />
        </CivicModal>
      </div>
      <div className="clsfeed-filter-blk clsfeed-filter">
        <a
          href={hashUrl}
          onClick={() => {
            petitionChangeByOption('filterDismissal', false);
          }}
          data-toggle="modal"
          data-target="#filter-modal"
        >
          <img
            src={require('../../../assets/img/filter.svg').default}
            alt="Filter"
          />
          <span>{t('Filter')}</span>
        </a>
      </div>
    </div>
  );
};

export default Filter;
