import React from "react";
import {connect} from "react-redux";
import actions from "../../../actions";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {CivicIcons} from "../../Common/CivicIcons";

const ResidentStepSix = (props) => {

    const {
        showCloseButton,
        fetchProfile
    } = props;

    const {t} = useTranslation();

    const handleClick = () => {
        fetchProfile();
        props.closeRegistrationModal();
    }

    return <>
        <Modal.Header closeButton={showCloseButton}>
            <Modal.Title className="w-100 text-center">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <div>
                        <CivicIcons.WarningIconFilled color="red" size={66} />
                    </div>
                    <div>
                        {t(`Sorry, we couldn't verify your information`)}
                    </div>
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group col-md-12">
                {t(`Unfortunately, we couldn't verify your address and/or name with your cell phone carrier.`)}
            </div>

            <div className="form-group col-md-12">
                {t(`Someone from our team will reach out to you from`)} <a
                href="mailto:support@civicbell.com">support@civicbell.com</a>.
            </div>

            <div className="form-group col-md-12">
                {t(`We apologize for this inconvenience!`)}
            </div>

            <div className="form-group col-md-12 align-center">
                <button
                    type="button"
                    className="btn clsyellow-btn"
                    onClick={handleClick}
                    style={{
                        color: 'black',
                        fontWeight: 'bold'
                    }}>{t(`Return to CivicFeed`)}
                </button>
            </div>
        </Modal.Body>
    </>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration
    };
};

export default connect(mapStateToProps, actions)(ResidentStepSix);