/**
 *
 * Profile
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Profile from '../../components/Manager/Profile';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

const ProfileScreen = (props) => {
  const { user, setEditProfileData, setProfileFormData, setSocialLinkData } =
    props;

  const [loaded, setLoaded] = useState(false);

  const loadProfile = useCallback(async () => {
    if (typeof user.roleName !== 'undefined') {
      await setEditProfileData(user).then(() => {
        setLoaded(true);
      });
    }
  }, [setEditProfileData, setLoaded, user]);

  useEffect(() => {
    if (loaded === false && typeof user.roleName !== 'undefined') {
      loadProfile();
    }
  }, [loaded, loadProfile, user.roleName]);

  return (
    <>
      {loaded === false ? (
        <LoadingIndicator inline />
      ) : (
        <Profile
          {...props}
          setProfileFormData={setProfileFormData}
          setSocialLinkData={setSocialLinkData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    blocks: state.account.blocks,
    pages: state.account.pages,
    isProfileLoading: state.account.isProfileLoading,
    editProfileData: state.account.editProfileData,
  };
};

export default connect(mapStateToProps, actions)(ProfileScreen);
