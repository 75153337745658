import { convertToRaw } from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';

export const isDraftJsEmpty = (editorState) => {
  return convertToRaw(editorState.getCurrentContent()).blocks.length === 1 & convertToRaw(editorState.getCurrentContent()).blocks[0].text === '';
}

export const convertLexicalToDraftJs = (lexicalEditorHtml) => {
  const state = stateFromHTML(lexicalEditorHtml);
  return convertToRaw(state);
}