/*
 *
 * Discussion engagement constants
 *
 */

export const DISCUSSION_ENGAGEMENT_CHANGE =
  'src/DiscussionEngagement/DISCUSSION_ENGAGEMENT_CHANGE';
export const SET_DISCUSSION_ENGAGEMENT_DATA =
  'src/DiscussionEngagement/SET_DISCUSSION_ENGAGEMENT_DATA';
export const DISCUSSION_ENGAGEMENT_COMMENT_CHANGE =
  'src/DiscussionEngagement/DISCUSSION_ENGAGEMENT_COMMENT_CHANGE';
export const SET_DISCUSSION_ENGAGEMENT_LOADING =
  'src/DiscussionEngagement/SET_DISCUSSION_ENGAGEMENT_LOADING';
export const SET_DISCUSSION_ENGAGEMENT_RELOAD =
  'src/DiscussionEngagement/SET_DISCUSSION_ENGAGEMENT_RELOAD';
export const SET_DISCUSSION_ENGAGEMENT_COMMENTS_RELOAD =
  'src/DiscussionEngagement/SET_DISCUSSION_ENGAGEMENT_COMMENTS_RELOAD';
export const SET_DISCUSSION_ENGAGEMENT_CHILD_COMMENT_RELOAD =
  'src/DiscussionEngagement/SET_DISCUSSION_ENGAGEMENT_CHILD_COMMENT_RELOAD';
export const RESET_APP = 'src/DiscussionEngagement/RESET_APP';
