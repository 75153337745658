/**
 *
 * SelectOption
 *
 */

import React, {useEffect, forwardRef, useState} from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

const SelectOption = forwardRef((props, ref) => {
  const {
    disabled,
    error,
    label,
    multi,
    options,
    defaultValue,
    value,
    handleSelectChange,
    placeholder,
    menuOptions = {}
  } = props;
  const { t } = useTranslation();

  const [optionValues, setOptionValues] = useState(options);
  const [selectedValue, setSelectedValue] = useState(value);
  const language = i18next.language;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [language, options, value]);

  useEffect(() => {
    if (loaded === false) {
      if (options.length > 0) {
        const result = options.map((item, index) => {
          item['label'] =
            typeof item['label'] !== 'object' ? (
              <Trans i18nKey={item['label']}>{t(item['label'])}</Trans>
            ) : (
              <Trans i18nKey={item['label'].props.i18nKey}>
                {t(item['label'].props.i18nKey)}
              </Trans>
            );
          return item;
        });
        setOptionValues(result);
      }
      let valueResult = {};
      if (typeof value !== 'undefined') {
        if (value !== '' || value !== null) {
          if (typeof value === 'object') {
            if (typeof multi !== 'undefined') {
              valueResult = value.map((item, index) => {
                item['label'] =
                  typeof item['label'] !== 'object' ? (
                    <Trans i18nKey={item['label']}>{item['label']}</Trans>
                  ) : (
                    <Trans i18nKey={item['label'].props.i18nKey}>
                      {item['label'].props.i18nKey}
                    </Trans>
                  );
                return item;
              });
            } else {
              valueResult = value;
              valueResult['label'] =
                typeof value['label'] !== 'object' ? (
                  <Trans i18nKey={value['label']}>{value['label']}</Trans>
                ) : (
                  <Trans i18nKey={value['label'].props.i18nKey}>
                    {value['label'].props.i18nKey}
                  </Trans>
                );
            }
            setSelectedValue(valueResult);
          }
        }
      }
      setLoaded(true);
    }
  }, [loaded, options, t, value, multi]);

  const _handleSelectChange = (value) => {
    handleSelectChange(value);
    setLoaded(false);
  };

  const animatedComponents = makeAnimated();

  const styles = `select-box${error ? ' invalid' : ''}`;

  const searchKey = ({ label, value, data }, string) => {
    if (string) {
      let labelSearch = typeof label !== 'object' ? label : label.props.i18nKey;
      return (
        labelSearch.toLowerCase().includes(string.toLowerCase()) ||
        value.toString().toLowerCase().includes(string.toLowerCase())
      );
    } else {
      return true;
    }
  };

  return (
    <>
      <div className={styles}>
        {label && <label>{label}</label>}
        <Select
          ref={ref}
          isDisabled={disabled}
          className={
            multi ? 'select-container select-multiple' : 'select-container'
          }
          classNamePrefix="select-option"
          components={animatedComponents}
          isMulti={multi}
          options={optionValues}
          defaultValue={defaultValue}
          value={selectedValue}
          onChange={_handleSelectChange}
          placeholder={placeholder}
          isOptionDisabled={(option) => option.disabled}
          filterOption={searchKey}
          styles={{
            menu: (provided, state) => ({
              ...provided,
              ...menuOptions,
              zIndex: 9999,
            }),
          }}
        />
        <span className="clsinvalid">{error && error[0]}</span>
      </div>
    </>
  );
});

export default SelectOption;
