/*
 *
 * Close engagement reducer
 *
 */

import {
  CLOSE_ENGAGEMENT_CHANGE,
  CLOSE_ENGAGEMENT_RESET,
  SET_CLOSE_ENGAGEMENT_LOADING,
  SET_CLOSE_ENGAGEMENT_FORM_ERRORS,
  SET_CLOSE_ENGAGEMENT_SUBMITTING,
  SET_CLOSE_ENGAGEMENT_DATA,
  RESET_APP,
} from './constants';

const initialState = {
  closeEngagementData: [
    {
      comment: '',
      completeTimeFrame: '',
      dismissal: false,
    },
  ],
  closeEngagementErrors: [],
  closeEngagementLoading: false,
  closeEngagementSubmitting: false,
};

const defaultState = JSON.stringify(initialState);

const closeEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_CLOSE_ENGAGEMENT_DATA:
      return {
        ...state,
        closeEngagementData: {
          ...state.closeEngagementData,
          ...action.payload,
        },
      };
    case CLOSE_ENGAGEMENT_CHANGE:
      return {
        ...state,
        closeEngagementData: {
          ...state.closeEngagementData,
          ...action.payload,
        },
      };
    case SET_CLOSE_ENGAGEMENT_FORM_ERRORS:
      return {
        ...state,
        closeEngagementErrors: action.payload,
      };
    case SET_CLOSE_ENGAGEMENT_LOADING:
      return {
        ...state,
        closeEngagementLoading: action.payload,
      };
    case SET_CLOSE_ENGAGEMENT_SUBMITTING:
      return {
        ...state,
        closeEngagementSubmitting: action.payload,
      };
    case CLOSE_ENGAGEMENT_RESET:
      return {
        ...state,
        closeEngagementData: {
          comment: '',
          completeTimeFrame: '',
          dismissal: false,
        },
        closeEngagementErrors: [],
        closeEngagementLoading: false,
      };
    default:
      return state;
  }
};

export default closeEngagementReducer;
