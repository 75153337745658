import React from 'react';
import NewLists from './newLists';
import { useTranslation } from 'react-i18next';

const LocalNewsScreen = (props) => {
  const { localNewsLists } = props;
  const { t } = useTranslation();

  return (
    <>
      <>
        {localNewsLists.length === 0 ? (
          <div className={'mb-3 no-engagement'}>
            <span className={'sad-img'}>
              <img
                src={require('../../../assets/img/sad.svg').default}
                alt="No News"
              />
            </span>
            <span className="no-engagement-txt">
              {t(`No news has been listed yet.`)}
            </span>
          </div>
        ) : (
          <NewLists {...props} />
        )}
      </>
    </>
  );
};

export default LocalNewsScreen;
