/**
 *
 * Profile Image Upload Form
 *
 */

import React from 'react';
import CivicProfileImg from '../../Common/CivicProfileImg';

const ProfileImageForm = (props) => {
  const {
    user,
    changeProfileData,
    profileImgDataChange,
    removeProfileImg,
    profileImgData,
    uploadProfileImage,
    addNotification,
    createInitialImg,
  } = props;

  return (
    <CivicProfileImg
      profileImgChange={changeProfileData}
      profileImg={profileImgData.file}
      link={profileImgData.profile_picture}
      removeProfileImg={removeProfileImg}
      profileImgDataChange={profileImgDataChange}
      user={user}
      uploadProfileImage={uploadProfileImage}
      addNotification={addNotification}
      createDefault={createInitialImg}
    />
  );
};

export default ProfileImageForm;
