/*
 *
 * Notification actions
 *
 */

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, RESET_APP } from './constants';

export const resetNotificationState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const addNotification = (notification) => {
  return async (dispatch, getState) => {
    const id = getState().notifications.notifications.length;
    let { autoDismissal, displayFor } = notification;

    if (displayFor === undefined) {
      displayFor = 6000;
    }

    notification = { ...notification, id: id };

    if (notification?.message && typeof notification.message === 'string' && notification?.message?.toLowerCase()?.includes('malformed authorization')) {
      notification.message = 'There was an error with this request. Please try logging out and back in. If this error persists, contact support@civicbell.com.' // TODO: translate
    }

    dispatch({
      type: ADD_NOTIFICATION,
      payload: notification,
    });
    if (autoDismissal !== false) {
      setTimeout(() => dispatch(removeNotification(id)), displayFor);
    }
  };
};

export const removeNotification = (id) => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: id,
  };
};
