import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";
import { languageMap } from '../../Common/LanguageSelect';

const CommunityIssueChildComment = (props) => {
  const { t } = useTranslation();
  const {
    childComment,
    user,
  } = props;
  //

  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 240,
      fontSize: '14px',
      backgroundColor: '#fff',
      color: '#000',
      textAlign: 'center',
      borderRadius: '10px',
      border: '1px solid #f5f4f9',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 9px 0px',
      padding: '10px'
    },
  }));

  return (
    <div
      className={`clsdiscussion-details mb-2 ${childComment?.user?.roleId !== 3
        ? 'highlight-comment-official'
        : 'highlight-comment-resident'
      }`}
    >
      <div className="comment-user-detail mt-0 mb-1" style={{ display: 'flex' }}>
        <h5 className="comment-username" style={{ width: 'auto' }}>
          <NavLink
            to={'/dashboard/user-profile/?id=' + childComment?.userId}
            activeClassName="active"
            exact
          >
            {`${childComment?.user?.first_name ?? ''} ${childComment?.user?.last_name ?? ''}`} 
          </NavLink>
          <br />
          <span className="user-position">
            {childComment?.user?.displayPlace ?? ''}
          </span>
        </h5>
        <div className="mr-3 globe-box">
          {(user.selected_language !== childComment?.originalLanguage) &&
            <HtmlTooltip placement="bottom" PopperProps={{
              onPointerDown: e => {
                if (e.target instanceof Element && !e.target.classList.contains("MuiTooltip-tooltip")) {
                  e.preventDefault();
                }
              },
            }} title={childComment?.translation ?
              <>{t(`This content was originally written in`) + '\u00A0' + `${t(languageMap?.[childComment?.originalLanguage]?.label)}.`}
                <a className="translation-link" href='https://civicl.ink/aitranslation'
                  target="_blank" rel="noreferrer">{'\u00A0' + t('Learn more about our AI-based language translation.')}
                </a>
                <button className="translate-btn clsyellow-translate-btn"
                  onClick={() => setShowOriginalLanguage(!showOriginalLanguage)}>
                  {showOriginalLanguage ? t('Revert to requested language') : t('Show in original language')}</button>
              </> : <>{t('No translation exists for this comment.')}</>}>
              {childComment?.translation ?
                <img src={require('../../../assets/img/icon-language.svg').default} alt="Globe" /> :
                <img src={require('../../../assets/img/icon-language-crossed.svg').default}
                  alt="Globe Crossed" />}
            </HtmlTooltip>
          }
        </div>
      </div>
      <p className="discussion-comment">
        {
          user.selected_language === childComment?.originalLanguage || showOriginalLanguage ?
            childComment?.content :
            childComment?.translation && (JSON.parse(childComment?.translation)?.[user.selected_language]?.content) ?
              JSON.parse(childComment?.translation)?.[user.selected_language]?.content : childComment?.content
        }
      </p>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueComments: state.communityIssues.allCommunityIssueComments,
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueChildComment);