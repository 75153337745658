/**
 *
 * Email confirmation footer for public official
 *
 */
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const EmailConfirmationFooter = (props) => {
  const { registerChange, register } = props;
  const { t } = useTranslation();

  const closeModal = (submit = false) => {
    if ($('#email-confirmation-modal')) {
      $('#email-confirmation-modal').modal('hide');
    }
    if (submit === true) {
      register();
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn clsyellow-btn mr-4"
        onClick={(e) => {
          registerChange('showEmailConfirmation', false);
          registerChange('manualVerification', false);
          closeModal(true);
        }}
      >
        {t(`Yes`)}
      </button>
      <button
        type="button"
        className="btn clsyellow-btn ml-4"
        onClick={(e) => {
          registerChange('showEmailConfirmation', true);
          registerChange('manualVerification', true);
          closeModal(false);
        }}
      >
        {t(`No`)}
      </button>
    </>
  );
};

export default EmailConfirmationFooter;
