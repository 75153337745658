/**
 *
 * Post Type Event
 *
 */

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  getOptionArray,
  getEventDateDetail,
  getEventPublishedBy,
  isImageLink,
} from '../../../utils/helper';
import { recurringOptions } from '../../../containers/Events/constants';
import ShareLink from '../../Common/ShareLink';
import moment from 'moment-timezone';
import CivicModal from '../../Common/CivicModal';
import { NavLink } from 'react-router-dom';
import EventMap from './event_map';
import { useTranslation } from 'react-i18next';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import LinkedNotification from '../../Common/ShareLink/linkedInNotification';

const PostView = (props) => {
  const { user, event, joinEvent } = props;
  const recurringArray = getOptionArray(recurringOptions);
  const shareUrl = window.location.href;
  const title = event.name;
  const { t } = useTranslation();

  const hashUrl = '#';
  const history = useHistory();
  const navigateTo = (url) => {
    history.push(url);
  };

  let eventImage = '';
  if (
    event.cover_images !== 'undefined' &&
    event.cover_images !== null &&
    event.cover_images.length > 0
  ) {
    eventImage = isImageLink(event.cover_images[0]);
  } else if (event.images.length > 0 && event.images !== null) {
    eventImage = isImageLink(event.images[0]);
  }

  return (
    <>
      <LinkedNotification targetId={event.id} shareUrl={shareUrl} />
      <div className="clsback-link mb-3">
        <a
          href={hashUrl}
          onClick={() => {
            navigateTo('/dashboard/events');
          }}
        >
          <img
            src={require('../../../assets/img/back.svg').default}
            alt={t(`Back to Events`)}
          />{' '}
          {t(`Back to Events`)}
        </a>
      </div>
      <div className="clsprofile-details-blk clspetitions-details-blk clswhite-box">
        <figure className="clsprofile-post-img">
          <img
            src={
              event.images !== undefined && event.images !== null
                ? eventImage
                : require('../../../assets/img/profile-post-img.png').default
            }
            alt=""
            className="img-fluid"
          />
        </figure>
        <div className="clsprofile-details-box">
          <div className="clsprofile-details">
            <div className="clsprofile-details-left">
              <h4 className="mb-3">{event.name}</h4>
            </div>
            <ShareLink shareUrl={shareUrl} title={title} targetId={event.id} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="clsdescription-blk clswhite-box mt-3">
            <div className="clsevent-organiser-details-blk">
              <div className="clsevent-organiser-details-box mb-2">
                <span className="clsevent-organiser-icons mr-2 event-profile-img">
                  <NavLink
                    to={
                      '/dashboard/user-profile/?id=' +
                      event.eventsCalendar.officialId
                    }
                    activeClassName="active"
                    exact
                  >
                    <img
                      src={
                        event.eventsCalendar.user.profile_picture !== null
                          ? event.eventsCalendar.user.profile_picture
                          : require('../../../assets/img/profile-image.jpeg')
                              .default
                      }
                      alt={t(`Published by`)}
                    />
                  </NavLink>
                </span>
                <div className="clsevent-organiser-details">
                  <h6>{t(`Published by`)}</h6>
                  <p>
                    <NavLink
                      to={
                        '/dashboard/user-profile/?id=' +
                        event.eventsCalendar.officialId
                      }
                      activeClassName="active"
                      className={'profile-link'}
                      exact
                    >
                      {getEventPublishedBy(event.eventsCalendar.user)}
                    </NavLink>
                  </p>
                </div>
              </div>
              <div className="clsevent-organiser-details-box mb-2">
                <span className="clsevent-organiser-icons mr-2">
                  <img
                    src={require('../../../assets/img/calendar.svg').default}
                    alt="Date"
                  />
                </span>
                <div className="clsevent-organiser-details">
                  <h6>{t(`Date`)}</h6>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: getEventDateDetail(event),
                    }}
                  ></p>
                  <span className="clsrecurring-badge">
                    {recurringArray[event.repetition_type] !== 'undefined' &&
                      recurringArray[event.repetition_type]}
                  </span>
                </div>
              </div>
              <div className="clsevent-organiser-details-box mb-2">
                <span className="clsevent-organiser-icons mr-2">
                  <img
                    src={require('../../../assets/img/clock.svg').default}
                    alt={t(`Time`)}
                  />
                </span>
                <div className="clsevent-organiser-details">
                  <h6>{t(`Time`)}</h6>
                  <p>
                    {moment
                      .utc(`${event.start_time}`)
                      .tz(moment.tz.guess())
                      .format('LT')}
                    {event.end_time !== event.start_time
                      ? ' - ' +
                        moment
                          .utc(`${event.end_time}`)
                          .tz(moment.tz.guess())
                          .format('LT')
                      : ''}
                  </p>
                </div>
              </div>
              <div className="clsevent-organiser-details-box mb-3">
                <span className="clsevent-organiser-icons mr-2">
                  <img
                    src={
                      require('../../../assets/img/location-big.svg').default
                    }
                    alt={t(`Location`)}
                  />
                </span>
                <div className="clsevent-organiser-details">
                  <h6>{t(`Location`)}</h6>
                  <p>
                    {event.coordinates !== null ? (
                      <a
                        href={
                          'https://www.google.com/maps/place/' + event.location
                        }
                        data-toggle="modal"
                        data-target="#event-map-modal"
                        className={'profile-link'}
                        target="blank"
                      >
                        {event.location}
                      </a>
                    ) : (
                      <a
                        href={
                          'https://www.google.com/maps/place/' + event.location
                        }
                        className={'profile-link'}
                        target="blank"
                      >
                        {event.location}
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </div>
            {event.description.includes('{"blocks"') ? (
              <div className={`editor-content`}>
                <p
                  dangerouslySetInnerHTML={convertFromJSONToHTML(
                    event.description,
                  )}
                ></p>
              </div>
            ) : (
              <p>{event.description}</p>
            )}
          </div>
        </div>
        <div className="col-md-4">
          <div className="clspetition-signin-box clswhite-box mt-3">
            <p>
              <img
                src={require('../../../assets/img/check-yellow.svg').default}
                alt=""
              />
              {event.attendeesCount} {t(`people are coming`)}
            </p>
            {user.userId !== event.eventsCalendar.officialId && (
              <div className="mt-2 align-center">
                {event.joined ? (
                  <a
                    href={hashUrl}
                    className="join-event-link"
                    data-toggle="modal"
                    data-target="#join-confirmation-modal"
                  >
                    {t(`Cancel Attendance`)}
                  </a>
                ) : (
                  <button
                    onClick={async (e) => {
                      await joinEvent(event, false);
                    }}
                    className="btn btn-block clsyellow-btn"
                    type="button"
                  >
                    {t(`Join Event`)}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {event.coordinates !== null ? (
        <CivicModal
          id={`event-map-modal`}
          title={event.name}
          dismissal={false}
          footerClass={`text-center mt-4`}
        >
          <EventMap event={event} position={JSON.parse(event.coordinates)}>
            {t(`Event Map`)}
          </EventMap>
        </CivicModal>
      ) : (
        ''
      )}
      <CivicModal
        id={`join-confirmation-modal`}
        title={t(`Are you sure?`)}
        dismissal={false}
        modalClass={`clsnew-petition-modal`}
        footerClass={`text-center mt-4`}
      >
        <p className={`email-confirmation-txt`}>{t(`Leaving this event!`)}</p>
        <div className="text-center mt-4">
          <button
            type="button"
            className="btn clsyellow-btn mr-4"
            data-dismiss="modal"
            onClick={async (e) => {
              await joinEvent(event, false);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn clsyellow-btn ml-4"
            data-dismiss="modal"
          >
            {t(`No`)}
          </button>
        </div>
      </CivicModal>
    </>
  );
};

export default PostView;
