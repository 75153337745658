import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { signOrUnsignCommunityIssue } from '../../../containers/CommunityIssues/actions';
import SignedConfirmationModal from './SignedConfirmationModal';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

const SignThisCommunityIssueModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    communityIssueId,
    isSignThisCommunityIssueModalOpen,
    setIsSignThisCommunityIssueModalOpen,
    isSignedConfirmationModalOpen,
    setIsSignedConfirmationModalOpen,
    allCommunityIssues,
    user
  } = props;
  const hashUrl = '#';

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div className="sign-this-community-issue">
      <div className='text-container'>
        <p style={{
          textAlign: 'left'
        }}>
          {t('Sign this to show your community and government you think this issue is important.')}
        </p>
        <div className='name-display'>
          <p>
            {t('Your name will be publicly displayed as:')}{' '}
            <b>
              {user?.show_my_name}
            </b>
          </p>
          <a
            href='#'
            target="_blank" 
            className='text-btn text-btn-container'
            onClick={() => {
              window.open('/dashboard/profile', '_blank');
            }}
          >
            {t(`change`)}
          </a>
        </div>
      </div>
      <div className='verified-container'>
        <div className='left'>
          <img
            src={require('../../../assets/img/verified-icon.svg').default}
            alt="Verified Icon"
          />
        </div>
        <div className='right'>
          <p>
            {t('No one will have access to your personal information, such as address, email, or phone number.')}
          </p>
        </div>
      </div>
      <div className='button-container'>
        <div className='text-btn-container'>
          <a
            href="#"
            data-toggle="modal"
            data-target={`#sign-community-issue-${communityIssueId}`}
            onClick={() => { }}
            className='text-btn'
          >
            {t(`I'd rather not sign`)}
          </a>
        </div>
        <button
          type="button"
          className="btn clsyellow-btn"
          onClick={() => {
            setIsSignThisCommunityIssueModalOpen(false);
            setIsSignedConfirmationModalOpen(true);
            dispatch(signOrUnsignCommunityIssue(communityIssueId));
          }}
        >
          {t(`Ok, sign this Issue!`)}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(SignThisCommunityIssueModal);