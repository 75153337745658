import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../Common/LoadingIndicator';
import CommunityIssueSubmitCommentBox from './CommunityIssueSubmitCommentBox';
import CommunityIssueComment from './CommunityIssueComment';
import CommunityIssueCommentEmotions from './CommunityIssueCommentEmotions';
import CommunityIssueChildCommentsContainer from './CommunityIssueChildCommentsContainer';
import { setIsSignUpButtonClicked, setRegistrationModalOpen } from '../../../containers/RegisterFlow/actions';
import { useLocation } from 'react-router-dom';

const CommunityIssueCommentsContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    communityIssueId,
    allCommunityIssues,
    allCommunityIssueComments,
    isCommunityIssuesLoading,
    authenticated,
    user,
  } = props;

  const communityIssue = allCommunityIssues?.[communityIssueId];
  const isIconDisabled = (!user.isVerified || !user.isIdentityVerified || !user.isRegistrationComplete); // TODO

  const [isViewMoreCommentsToggled, setIsViewMoreCommentsToggled] = useState(false);

  const [parentCommentIds, setParentCommentIds] = useState([]);
  const [latestComment, setLatestComment] = useState(null);
  const location = useLocation();
  const totalCommentCount = communityIssue?.totalCommentCount ?? 0;

  useEffect(() => {
    setParentCommentIds(
      Object.values(communityIssue?.comments)
        .filter(comment => (comment?.parentCommentId) === null)
        .sort((a, b) => a.commentId - b.commentId)
        .map(comment => comment.commentId)
    );
  }, [allCommunityIssues, allCommunityIssueComments, location.search])
  useEffect(() => {
    if (parentCommentIds.length > 0) {
      setLatestComment(allCommunityIssueComments?.[parentCommentIds.slice(-1)]);
    }
    else if (communityIssue?.latestComment) {
      setLatestComment(communityIssue?.latestComment);
    }
  }, [parentCommentIds]);

  return (
    <>
      {
        <div
          className={`clsdiscussion-blk clspost-box}`}
          style={{
            paddingTop: (communityIssue && totalCommentCount > 1) && 15
          }}
        >
          <ul className="list-unstyled">
            {
              (authenticated && communityIssue && totalCommentCount > 1) &&
              <a
                href={`#comment-show-more-`}
                data-target={`#comment-show-more-`}
                data-toggle="collapse"
                className="nav-toggle clsreply-link"
                id={`show-hide-link-`}
                onClick={async () => {
                  if (authenticated) {
                    setIsViewMoreCommentsToggled(!isViewMoreCommentsToggled);
                  } else {
                    dispatch(setIsSignUpButtonClicked(true));
                    dispatch(setRegistrationModalOpen(true));
                  }
                }}
              >
                {
                  isViewMoreCommentsToggled ?
                    t('See fewer comments')
                  :
                    t('See more comments')
                }
              </a>
            }
            {
              (!authenticated && totalCommentCount > 1) &&
              <a
                className="nav-toggle clsreply-link pointer"
                onClick={() => {
                  if (!authenticated) {
                    dispatch(setIsSignUpButtonClicked(true));
                    dispatch(setRegistrationModalOpen(true));
                  }
                }}
              >
                {
                  t(`Register for free to read all ${totalCommentCount} community comments`)
                }
              </a>
            }
            {
              (!isViewMoreCommentsToggled && communityIssue && latestComment) &&
              <li
                className={`media clsmany-replies my-4 ${authenticated}`}
                key={`comment-${latestComment?.commentId}`}
                style={{
                  width: '100%'
                }}
              >
                <div className={!authenticated && 'blur'}>
                  {
                    authenticated ?
                      <NavLink
                        //onClick={closeEngagementModal}
                        to={'/dashboard/user-profile/?id=' + latestComment?.userId}
                        activeClassName="active"
                        exact
                      >
                        <img
                          src={
                            latestComment?.user?.profile_picture ?? require('../../../assets/img/profile-image.jpeg').default
                          }
                          className="mr-3 engagement-profile-img"
                          alt={latestComment?.commentOwner}
                        />
                      </NavLink>
                    :
                      <img
                        src={
                          latestComment?.user?.profile_picture ?? require('../../../assets/img/profile-image.jpeg').default
                        }
                        className="mr-3 engagement-profile-img"
                        alt={''}
                      />
                  }
                </div>
                <div
                  className="media-body"
                  style={{
                    width: '75%'
                  }}
                >
                  <CommunityIssueComment
                    communityIssueId={communityIssueId}
                    commentId={latestComment.commentId}
                  />
                  <CommunityIssueCommentEmotions
                    communityIssueId={communityIssueId}
                    commentId={latestComment.commentId}
                    commentCount={latestComment?.comments?.length ?? 0}
                  />
                  <CommunityIssueChildCommentsContainer
                    communityIssueId={communityIssueId}
                    commentId={latestComment.commentId}
                  />
                  {
                    !isIconDisabled &&
                    <div id={`comment-box-${latestComment.commentId}`} className="collapse clspost-box">
                      <CommunityIssueSubmitCommentBox
                        communityIssueId={communityIssueId}
                        parentCommentId={latestComment.commentId}
                        canReact={true} // TODO
                        setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
                        key={`comment-reply-box-${latestComment.commentId}`}
                      />
                    </div>
                  }
                </div>
              </li>
            }
            {
              parentCommentIds && parentCommentIds.map((parentCommentId, idx) => {
                const parentComment = allCommunityIssueComments[parentCommentId];
                return (
                  <>
                    {
                      (isViewMoreCommentsToggled) &&
                      <li
                        className="media clsmany-replies my-4"
                        key={`comment-${parentCommentId}`}
                        style={{
                          width: '100%'
                        }}
                      >
                        <NavLink
                          to={'/dashboard/user-profile/?id=' + parentComment.userId}
                          activeClassName="active"
                          exact
                        >
                          <img
                            src={
                              parentComment?.user?.profile_picture ?? require('../../../assets/img/profile-image.jpeg').default
                            }
                            className="mr-3 engagement-profile-img"
                            alt={parentComment.commentOwner}
                          />
                        </NavLink>
                        <div
                          className="media-body"
                          style={{
                            width: '75%'
                          }}
                        >
                          <CommunityIssueComment
                            communityIssueId={communityIssueId}
                            commentId={parentCommentId}
                          />
                          <CommunityIssueCommentEmotions
                            communityIssueId={communityIssueId}
                            commentId={parentCommentId}
                            commentCount={parentComment?.comments?.length ?? 0}
                          />
                          <CommunityIssueChildCommentsContainer
                            communityIssueId={communityIssueId}
                            commentId={parentCommentId}
                          />
                          {
                            !isIconDisabled &&
                            <div id={`comment-box-${parentCommentId}`} className="collapse clspost-box">
                              <CommunityIssueSubmitCommentBox
                                communityIssueId={communityIssueId}
                                parentCommentId={parentCommentId}
                                canReact={true} // TODO
                                setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
                                key={`comment-reply-box-${parentCommentId}`}
                              />
                            </div>
                          }
                        </div>
                      </li>
                    }
                  </>
                )
              })
            }
          </ul>
        </div>
      }
      {
        !isIconDisabled &&
        <div className="clspost-box">
          <CommunityIssueSubmitCommentBox
            communityIssueId={communityIssueId}
            parentCommentId={null}
            setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
            canReact={true} // TODO
          />
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueComments: state.communityIssues.allCommunityIssueComments,
    isCommunityIssuesLoading: state.communityIssues.isCommunityIssuesLoading,
    authenticated: state.authentication.authenticated,
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueCommentsContainer);