/**
 *
 * District suggestion box
 *
 */

import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const SuggestionTags = (props) => {
  const { displayStatus, loadingStatus, suggestionData, addTag } = props;
  const { t } = useTranslation();

  return (
    <div
      id={`tag-suggestion-box`}
      className="clsfilter-search-show-content"
      style={{
        minHeight: '45px',
        cursor: 'pointer',
        display: displayStatus === true ? 'block' : 'none',
      }}
    >
      <ul>
        {loadingStatus === false ? (
          suggestionData.length > 0 ? (
            <>
              {suggestionData.map((item, index) => (
                <li
                  key={`tag-suggestion-${index}`}
                  className={`suggest-item`}
                  onClick={() => {
                    addTag(item);
                  }}
                >
                  <span className={`suggest-item`}>{item.text}</span>
                </li>
              ))}
            </>
          ) : (
            <li>
              <span>{t(`No results`)}</span>
            </li>
          )
        ) : (
          <LoadingIndicator
            position={`relative`}
            height={'35px'}
            backgroundSize={'35px'}
          />
        )}
      </ul>
    </div>
  );
};

export default SuggestionTags;
