/*
 *
 * Search Key Reducer
 *
 */

import {
  SEARCH_KEY_CHANGE,
  RESET_SEARCH_KEY_RESET,
  SET_SEARCH_KEY_ERROR,
  SET_SEARCH_SUBMITTING,
  SET_SEARCH_RESULT_RESET,
  RESET_APP,
} from './constants';

const initialState = {
  searchKey: '',
  error: '',
  searchSubmission: false,
  resetResult: false,
};

const defaultState = JSON.stringify(initialState);

const searchKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SEARCH_KEY_CHANGE:
      return {
        ...state,
        searchKey: action.payload,
      };
    case SET_SEARCH_KEY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_SEARCH_KEY_RESET:
      return {
        ...state,
        searchKey: '',
        error: '',
      };
    case SET_SEARCH_SUBMITTING:
      return {
        ...state,
        searchSubmission: action.payload,
      };
    case SET_SEARCH_RESULT_RESET:
      return {
        ...state,
        resetResult: action.payload,
      };
    default:
      return state;
  }
};

export default searchKeyReducer;
