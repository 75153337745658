/**
 *
 * Reply Comment Box
 *
 */

import React, { useState } from 'react';
import InputEmoji from 'react-input-emoji';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const CommentBox = (props) => {
  const {
    id,
    commentId,
    commentData,
    changePostComment,
    submitComment,
    user,
    canReact,
    discussionBoardEnabled,
    setIsViewMoreCommentsToggled,
  } = props;
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);

  const childCommentSubmit = async (id, commentId) => {
    setLoader(true);
    await submitComment(id, commentId).then(() => {
      setLoader(false);
    });
    setIsViewMoreCommentsToggled(true);
  };

  // This is only to fix an issue with the child comment length not counting correctly
  const [localChildComment, setLocalChildComment] = useState(''); 

  const commentSubmit = async (id) => {
    setLoader(true);
    await submitComment(id).then(() => {
      setLoader(false);
    });
    setIsViewMoreCommentsToggled(true);
  };

  return (
    <>
      {loader === true ? <LoadingIndicator position={`fixed`} /> : ''}
      {typeof id !== 'undefined' &&
        typeof commentId !== 'undefined' &&
        typeof commentData !== 'undefined' &&
        typeof user !== 'undefined' && discussionBoardEnabled && canReact ? (
        <div className="clspost-box">
          <div className="media mt-3">
            <img
              src={
                typeof user.profile_picture !== 'undefined'
                  ? user.profile_picture
                  : require('../../../assets/img/profile-image.jpeg').default
              }
              className="align-self-center mr-3 engagement-profile-img"
              alt={user.first_name}
            />
            <div className="media-body">
              <div className="mb-3 mt-1 emoji-comment-box" style={{ width: '95%' }}>
                <InputEmoji
                  value={
                    typeof commentData?.['postComment'] !== 'undefined'
                      ? commentData?.['postComment']
                      : ''
                  }
                  onChange={(value) => {
                    setLocalChildComment(value);
                    changePostComment(id, commentId, value);
                  }}
                  placeholder={t(`Comment...`)}
                  borderRadius={10}
                />
                <div className="input-group-append emoji-post">
                  <img
                    className='submit-arrow'
                    src={
                      require('../../../assets/img/submit-arrow.svg')
                        .default
                    }
                    alt="submit-arrow"
                    onClick={() => {
                      if (canReact === true) {
                        childCommentSubmit(id, commentId);
                      }
                    }}
                  />
                </div>
                <div className="character-left comment-box-error">
                  {500 - localChildComment.length}{' '}
                  {t('characters are left')}
                </div>
                {typeof commentData?.['error'] !== 'undefined' ? (
                  <div className="clsinvalid comment-box-error">
                    {commentData?.['error']}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      ) : typeof id !== 'undefined' &&
        typeof commentData !== 'undefined' &&
        typeof commentId === 'undefined' &&
        typeof user !== 'undefined' && discussionBoardEnabled && canReact ? (
        <div className="clspost-box">
          <div className="media mt-3">
            <img
              src={
                typeof user.profile_picture !== 'undefined'
                  ? user.profile_picture
                  : require('../../../assets/img/profile-image.jpeg').default
              }
              className="align-self-center mr-3 engagement-profile-img"
              alt={user.first_name}
            />
            <div className="media-body">
              <div
                className="mb-3 mt-1 emoji-comment-box"
                style={{
                  width: '95%',
                }}
              >
                <InputEmoji
                  value={
                    typeof commentData?.['newComment'] !== 'undefined'
                      ? commentData?.['newComment']
                      : ''
                  }
                  onChange={(value) => {
                    changePostComment(id, 'newComment', value);
                  }}
                  placeholder={t(`Comment...`)}
                  borderRadius={10}
                />
                <div className="input-group-append input-group emoji-post">
                  <img
                    className='submit-arrow'
                    src={
                      require('../../../assets/img/submit-arrow.svg')
                        .default
                    }
                    alt="submit-arrow"
                    onClick={() => {
                      if (canReact === true) {
                        commentSubmit(id);
                        changePostComment(id, 'newComment', '');
                      }
                    }}
                  />
                </div>
                <div
                  className={`character-left comment-box-error ${typeof commentData?.['newComment'] !== 'undefined' &&
                      parseInt(500 - commentData?.['newComment'].length) < 0
                      ? `character-exceeded`
                      : ``
                    }`}
                >
                  {typeof commentData?.['newComment'] !== 'undefined'
                    ? 500 - parseInt(commentData?.['newComment'].length)
                    : 500}{' '}
                  {t('character(s) are')}{' '}
                  {typeof commentData?.['newComment'] !== 'undefined' &&
                    parseInt(500 - commentData?.['newComment'].length) < 0
                    ? t('exceeded')
                    : t('left')}
                </div>
                {typeof commentData?.['error'] !== 'undefined' ? (
                  <div className="clsinvalid comment-box-error">
                    {commentData?.['error']}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CommentBox;
