/**
 *
 * Survey Analytics
 *
 */

import React, { useEffect, useState, createContext } from 'react';
import { Chart } from 'react-google-charts';
import Accordion from 'react-bootstrap/Accordion';
import QuestionAnalytics from '../../../containers/Analytics/questionAnalytics';
import NoData from './noData';

const SurveyAnalytics = (props) => {
  const { analyticsData, setActiveId } = props;
  const [visibleStatus, setVisibleStatus] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartOptions, setPieChartOptions] = useState(null);
  const [openStatus, setOpenStatus] = useState({});
  //const [resizeChart, setResizeChart] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      let pieData = {},
        pieOptions = {};
      let canShow = [];
      analyticsData.survey.question.forEach((question, index) => {
        pieData[question.id] = [['', '']];

        pieOptions[question.id] = {
          pieHole: 0.85,
          is3D: false,
          colors: ['#089EEA', '#FFAA33', '#87D9FF', '#FFD121'],
          pieResidueSliceColor: '#f00',
          legend: {
            alignment: 'center',
            position: 'right',
            textStyle: {
              color: '#000000',
              fontSize: 14,
              fontFamily: 'latoregular',
            },
          },
          chartArea: { left: 0, right: 5, bottom: 10, top: 20 },
          backgroundColor: {
            stroke: '#f5f4f9',
            strokeWidth: 1,
            rx: 15,
            ry: 15,
            fill: '#f5f4f9',
          },
          pieSliceTextStyle: { color: '#000000' },
          pieSliceText: 'none',
          sliceVisibilityThreshold: 0,
        };

        question.choice.forEach((choice, cindex) => {
          if (typeof choice.actualNumber !== 'undefined') {
            pieData[question.id] = [
              ...pieData[question.id],
              [choice.value, parseInt(choice.actualNumber)],
            ];
            if (canShow.indexOf(question.id) === -1) {
              canShow.push(question.id);
            }
          }
        });
      });
      setVisibleStatus(canShow);
      setPieChartData(pieData);
      setPieChartOptions(pieOptions);
      setLoaded(true);
    }
  }, [
    loaded,
    setPieChartData,
    setPieChartOptions,
    analyticsData.survey.question,
  ]);

  const addQuestionId = (id) => {
    setActiveId(id);
    if (!!openStatus[id] === false) {
      setOpenStatus({ [id]: true });
      //setResizeChart(true);
    }
    window.dispatchEvent(new Event('resize'));
  };
  /*useEffect(() => {
    if(resizeChart === true) {
      setResizeChart(false);
      window.dispatchEvent(new Event('resize'));
    }
  }, [resizeChart, setResizeChart])*/

  const Context = createContext();

  return (
    <div className="clschart-blk clswhite-box mt-4">
      <div className="clsanalytics-chart-question-blk">
        {loaded === true ? (
          <Accordion defaultActiveKey="0">
            {analyticsData.survey.question.map((question, index) => (
              <Accordion.Item
                eventKey={`question-${question.id}`}
                key={`survey-question-${question.id}`}
                onClick={() => {
                  addQuestionId(question.id);
                }}
              >
                <Accordion.Header>{`Question ${index + 1}`}</Accordion.Header>
                <Accordion.Body>
                  <p className={`analytics-question`}>{question.title}</p>
                  <div
                    className={`chart-data mt-2 mb-5`}
                    style={{
                      width: '100%',
                      height: '170px',
                      textAlign: 'center',
                      backgroundColor: '#F5F4F9',
                      borderRadius: '10px',
                    }}
                  >
                    {visibleStatus.indexOf(question.id) !== -1 ? (
                      <Context.Provider value={pieChartData}>
                        <Chart
                          chartType="PieChart"
                          data={pieChartData[question.id]}
                          options={pieChartOptions[question.id]}
                          width={`100%`}
                          height={`170px`}
                        />
                      </Context.Provider>
                    ) : (
                      <NoData cssClass={`pieChartNoData`} />
                    )}
                  </div>
                  <QuestionAnalytics activeId={question.id} />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SurveyAnalytics;
