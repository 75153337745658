/**
 *
 * actions.js
 * actions configuration
 */

import { bindActionCreators } from 'redux';

import * as notifications from './containers/Notification/actions';
import * as application from './containers/Application/actions';
import * as homepage from './containers/Homepage/actions';
import * as authentication from './containers/Authentication/actions';
import * as login from './containers/Login/actions';
import * as register from './containers/Register/actions';
import * as forgotPassowrd from './containers/ForgotPassword/actions';
import * as resetPassword from './containers/ResetPassword/actions';
import * as verifyEmail from './containers/VerifyEmail/actions';
import * as account from './containers/Dashboard/actions';
import * as engagement from './containers/Engagement/actions';
import * as closeEngagement from './containers/CloseEngagement/actions';
import * as completeEngagement from './containers/CompleteEngagement/actions';
import * as reactEngagement from './containers/Reactions/actions';
import * as discussionEngagement from './containers/DiscussionEngagement/actions';
import * as searchKey from './containers/Search/actions';
import * as civicFeed from './containers/CivicFeed/actions';
import * as event from './containers/Events/actions';
import * as petition from './containers/Petitions/actions';
import * as surveyQuestion from './containers/SurveyQuestions/actions';
import * as inviteother from './containers/InviteOthers/actions';
import * as analytics from './containers/Analytics/actions';
import * as localnews from './containers/LocalNews/actions';
import * as inAppropriateReport from './containers/InAppropriateReport/actions';
import * as map from './containers/CivicMap/actions';
import * as pushnotification from './containers/PushNotification/actions';
import * as searchResult from './containers/SearchResult/actions';
import * as registration from './containers/RegisterFlow/actions';

export default function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...notifications,
      ...application,
      ...homepage,
      ...authentication,
      ...login,
      ...register,
      ...forgotPassowrd,
      ...resetPassword,
      ...verifyEmail,
      ...account,
      ...engagement,
      ...closeEngagement,
      ...completeEngagement,
      ...reactEngagement,
      ...discussionEngagement,
      ...searchKey,
      ...civicFeed,
      ...event,
      ...petition,
      ...surveyQuestion,
      ...inviteother,
      ...analytics,
      ...localnews,
      ...inAppropriateReport,
      ...map,
      ...pushnotification,
      ...searchResult,
      ...registration
    },
    dispatch,
  );
}
