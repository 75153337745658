/*
 *
 * CommunityIssues actions
 *
*/
import axios from 'axios';
import {
  BASE_API_URL,
} from '../../constants';
import {
  RESET_APP,
  RESET_COMMUNITY_ISSUE_FEED,
  GET_COMMUNITY_ISSUES,
  GET_MY_COMMUNITY_ISSUES,
  GET_INDIVIDUAL_COMMUNITY_ISSUE,
  CREATE_COMMUNITY_ISSUE,
  UPDATE_COMMUNITY_ISSUE,
  DELETE_COMMUNITY_ISSUE,
  CLOSE_COMMUNITY_ISSUE,
  HIDE_COMMUNITY_ISSUE,
  SIGN_OR_UNSIGN_COMMUNITY_ISSUE,
  SET_FOLLOWING_COMMUNITY_ISSUE,
  GET_TOPICS,
  SET_COMMUNITY_ISSUES_LOADING,
  SET_MY_COMMUNITY_ISSUES_LOADING,
  SET_TOPICS_LOADING,
  SET_COMMUNITY_ISSUE_LIST_PAGE,
  SET_COMMUNITY_ISSUE_LIST_LIMIT,
  SET_COMMUNITY_ISSUE_LIST_FILTER,
  SET_COMMUNITY_ISSUE_SORT_ORDER,
  SET_COMMUNITY_ISSUE_LIST_AREA_FILTER,
  SET_COMMUNITY_ISSUE_LIST_ISSUE_CATEGORY,
  CREATE_COMMUNITY_ISSUE_COMMENT,
  SET_SIGNEES_LOADING,
  GET_COMMUNITY_ISSUE_SIGNEES,
  REACT_TO_COMMUNITY_ISSUE_COMMENT,
} from './constants';
import { addNotification } from '../Notification/actions';

export const resetCommunityIssues = () => {
  return async (dispatch, getState) => {
    dispatch({ type: RESET_APP, payload: null });
  }
}

export const getCommunityIssues = ({ page, limit, filter, category, sortOrder, reset }) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });
      if (reset) {
        dispatch({ type: RESET_COMMUNITY_ISSUE_FEED, payload: null });
      }

      const params = {};
      if (page) {
        params['page'] = page;
      }
      if (limit) {
        params['limit'] = limit;
      }
      if (filter) {
        params['filter'] = filter;
      }
      if (category && category?.value && category?.value !== 0) {
        params['category'] = category?.value;
      }
      if (sortOrder && sortOrder !== 'Newest') {
        params['sort'] = sortOrder;
      }

      const response = await axios.get(`${BASE_API_URL}/users/communityissue`, {
        params,
      });
      if (response?.data?.success === 'true' && response?.data?.data) {
        dispatch({ type: GET_COMMUNITY_ISSUES, payload: response.data.data });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const getIndividualCommunityIssue = (communityIssueId, loadOverride = false) => {
  return async (dispatch, getState) => {
    if (!loadOverride && getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      if (!loadOverride) {
        dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });
      }

      const response = await axios.get(`${BASE_API_URL}/users/communityissue/${communityIssueId}`);
      if (!(response.data.data)) {
        throw Error('This Community Issue doesn’t exist or is no longer available.');
      }
      if (response?.data?.success === 'true') {
        dispatch({ type: GET_INDIVIDUAL_COMMUNITY_ISSUE, payload: response.data.data });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error ?? 'This Community Issue doesn’t exist or is no longer available.',
        module: 'dashboard',
       }));
    } finally {
      if (!loadOverride) {
        dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
      }
    }
  }
}

export const getMyCommunityIssues = () => {
  return async (dispatch, getState) => {
    if (!(getState().authentication.authenticated)) {
      return;
    }
    if (getState().communityIssues.isMyCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_MY_COMMUNITY_ISSUES_LOADING, payload: true });
      const response = await axios.get(`${BASE_API_URL}/users/communityissue?filter=myIssues`);
      if (response?.data?.success === 'true') {
        dispatch({ type: GET_MY_COMMUNITY_ISSUES, payload: response.data.data });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    } finally {
      dispatch({ type: SET_MY_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const createCommunityIssue = (data) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    let communityIssueId;
    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.post(`${BASE_API_URL}/users/communityissue`, data);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ type: CREATE_COMMUNITY_ISSUE, payload: response.data.data });
        dispatch(addNotification({
          message: 'Your issue has been created successfully.',
          type: 'success',
          module: 'dashboard'
        }));  
      }
      communityIssueId = response?.data?.data?.communityIssueId;
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: `Error submitting issue: ${error.response.data.error || error.response.data.errors.join(', ')}`,
        module: 'dashboard'
      }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
      return communityIssueId;
    }
  }
}

export const updateCommunityIssue = (communityIssueId, data) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.put(`${BASE_API_URL}/users/communityissue/${communityIssueId}`, data);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ type: UPDATE_COMMUNITY_ISSUE, payload: response.data.data });
        dispatch(addNotification({
          message: 'Your issue has been edited successfully.',
          type: 'success',
          module: 'dashboard'
        }));  
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: `Error editing issue: ${error.response.data.error || error.response.data.errors.join(', ')}`,
        module: 'dashboard'
      }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const closeCommunityIssue = (communityIssueId) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.post(`${BASE_API_URL}/users/communityissue/${communityIssueId}/close`);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ type: CLOSE_COMMUNITY_ISSUE, payload: response.data.data });
        dispatch(addNotification({
          message: 'Your issue has been closed successfully.',
          type: 'success',
          module: 'dashboard'
        }));  
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: `Error closing issue: ${error.response.data.error || error.response.data.errors.join(', ')}`,
        module: 'dashboard'
      }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const hideCommunityIssue = (communityIssueId) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.post(`${BASE_API_URL}/users/communityissue/${communityIssueId}/hide`);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ type: HIDE_COMMUNITY_ISSUE, payload: communityIssueId });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: `Error hiding issue: ${error.response.data.error || error.response.data.errors.join(', ')}`,
        module: 'dashboard'
      }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const deleteCommunityIssue = (communityIssueId) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.delete(`${BASE_API_URL}/users/communityissue/${communityIssueId}`);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ type: DELETE_COMMUNITY_ISSUE, payload: communityIssueId });
        dispatch(addNotification({
          message: 'Your issue has been deleted successfully.',
          type: 'success',
          module: 'dashboard'
        }));  
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: `Error deleting issue: ${error?.response?.data?.error || error?.response?.data?.errors?.join(', ')}`,
        module: 'dashboard'
      }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const getCommunityIssueTopics = () => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isTopicsLoading) {
      return;
    }

    try {
      dispatch({ type: SET_TOPICS_LOADING, payload: true });

      const response = await axios.get(BASE_API_URL + '/users/communityissue/topics');
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ type: GET_TOPICS, payload: response.data.data });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    } finally {
      dispatch({ type: SET_TOPICS_LOADING, payload: false });
    }
  }
}

export const signOrUnsignCommunityIssue = (communityIssueId) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.post(BASE_API_URL + `/users/communityissue/${communityIssueId}/sign`);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ 
          type: SIGN_OR_UNSIGN_COMMUNITY_ISSUE, 
          payload: {
            signed: response.data.signed,
            communityIssueId,
          }
        });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const setFollowingCommunityIssue = (communityIssueId) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.post(BASE_API_URL + `/users/communityissue/${communityIssueId}/follow`);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ 
          type: SET_FOLLOWING_COMMUNITY_ISSUE, 
          payload: {
            communityIssueId,
            isFollowing: response.data.following,
          }
        });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}

export const setCommunityIssueListPage = (listPage) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COMMUNITY_ISSUE_LIST_PAGE, payload: listPage });
  }
}

export const setCommunityIssueListLimit = (listLimit) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COMMUNITY_ISSUE_LIST_LIMIT, payload: listLimit });
  }
}

export const setCommunityIssueListFilter = (listFilter) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COMMUNITY_ISSUE_LIST_FILTER, payload: listFilter });
  }
}

export const setCommunityIssueSortOrder = (sortOrder) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COMMUNITY_ISSUE_SORT_ORDER, payload: sortOrder });
  }
}

export const setCommunityIssueListIssueCategory = (listIssueCategory) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COMMUNITY_ISSUE_LIST_ISSUE_CATEGORY, payload: listIssueCategory });
  }
}

export const setCommunityIssueListAreaFilter = (area) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COMMUNITY_ISSUE_LIST_AREA_FILTER, payload: area });
  }
}

export const createCommunityIssueComment = (communityIssueId, data) => {
  return async (dispatch, getState) => {
    try {
      if (!(data.parentCommentId)) {
        delete data?.['parentCommentId'];
      }

      // Do not have dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true })
      // here because then the individual community issue page will reload
      const response = await axios.post(`${BASE_API_URL}/users/communityissue/${communityIssueId}/comment`, data);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ type: CREATE_COMMUNITY_ISSUE_COMMENT, payload: response.data.data });
        if (response?.data?.data?.parentCommentId && response?.data?.data?.communityIssueId) {
          dispatch(getIndividualCommunityIssue(response?.data?.data?.communityIssueId, true));
        }
        // According to Anthony, do not display
        // dispatch(addNotification({ // TODO: Translate these notifications
        //   message: 'Your comment has been submitted successfully.',
        //   type: 'success',
        //   module: 'dashboard'
        // }));  
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: `Error submitting comment: ${error.response.data.error || error.response.data.errors.join(', ')}`,
        module: 'dashboard'
      }));
    }
  }
}

export const getCommunityIssueSignees = (communityIssueId) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isSigneesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_SIGNEES_LOADING, payload: true });

      const response = await axios.get(`${BASE_API_URL}/users/communityissue/${communityIssueId}/signees`);
      if (response.data.success === 'true' || response.data.success === true) {
        dispatch({ 
          type: GET_COMMUNITY_ISSUE_SIGNEES, 
          payload: {
            communityIssueId,
            data: response.data.data 
          }
        });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    } finally {
      dispatch({ type: SET_SIGNEES_LOADING, payload: false });
    }
  }
}

export const reactToCommunityIssueComment = (communityIssueId, commentId, reaction) => {
  return async (dispatch, getState) => {
    if (getState().communityIssues.isCommunityIssuesLoading) {
      return;
    }

    try {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: true });

      const response = await axios.post(`${BASE_API_URL}/users/communityissue/${communityIssueId}/react`, {
        commentId,
        reaction,
      });
      if (response.data.success === 'true' || response.data.success === true) {
        if (!response.data.data?.commentId) {
          response.data.data.commentId = commentId;
        }
        if (!response.data.data?.communityIssueId) {
          response.data.data.communityIssueId = communityIssueId;
        }
        dispatch({ type: REACT_TO_COMMUNITY_ISSUE_COMMENT, payload: response.data.data });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    } finally {
      dispatch({ type: SET_COMMUNITY_ISSUES_LOADING, payload: false });
    }
  }
}