import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { deleteCommunityIssue } from '../../../containers/CommunityIssues/actions';
import { useHistory } from 'react-router-dom';

const DeleteCommunityIssueConfirmationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    communityIssueId,
    allCommunityIssues,
    user
  } = props;
  const history = useHistory();
  const hashUrl = '#';

  const urlId = new URLSearchParams(window.location.search).get('id')

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div className="delete-community-issue-early">
      <div className='text-container'>
        <p>
          {t(`Warning: You can't undo this action.`)}
        </p>
      </div>
      <div className='button-container'>
        <div className='text-btn-container'>
          <a
            href="#"
            data-toggle="modal"
            data-target={`#delete-issue-${communityIssueId}`}
            onClick={() => { }}
            className='text-btn'
          >
            {t(`Go Back`)}
          </a>
        </div>
        <button
          type="button"
          className="btn clsyellow-btn"
          href="#"
          data-toggle="modal"
          data-target={`#delete-issue-${communityIssueId}`}
          data-dismiss="modal"
          onClick={() => {
            dispatch(deleteCommunityIssue(communityIssueId));
            if (urlId) {
              history.push(`/community-issues`);
            }
          }}
        >
          {t(`Delete`)}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(DeleteCommunityIssueConfirmationModal);