/*
 *
 * Why The Field Is Needed Popup
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const WhyNeed = (props) => {
  const { type } = props;
  const { t } = useTranslation();
  return (
    <div className="clswhy-need-modal-content">
      {type === 'name' ? (
        <>
          <p className="pb-3">
            {t(
              `Your full name is necessary for verification purposes. Later you can choose whether to display your name in the following ways:`,
            )}
          </p>
          <ul>
            <li>
              <span className="clsusername-short">JS</span>
              <span>John Smith</span>
            </li>
            <li>
              <span className="clsusername-short">JS</span>
              <span>J. Smith</span>
            </li>
            <li>
              <span className="clsusername-short">JS</span>
              <span>JS</span>
            </li>
          </ul>
        </>
      ) : type === 'address' ? (
        <p className="pb-3">
          {t(
            `This location information is necessary to automatically connect you to all public officials in your area. Enter the address where you currently reside. You don't need to be a registered voter at this address. Once you are registered, you can opt-in to engage with officials in other areas.`,
          )}
        </p>
      ) : type === 'phonenumber' ? (
        <p>
          {t(
            `Your phone number is in case you lose access to your account and need an option, other than email, to reset your password.`,
          )}
        </p>
      ) : (
        ''
      )}
      <div className="text-center mt-4">
        <button
          type="button"
          className="btn clsyellow-btn"
          data-dismiss="modal"
        >
          {t(`Ok`)}
        </button>
      </div>
    </div>
  );
};

export default WhyNeed;
