/*
 *
 * Reset Password Reducer
 *
 */

import {
  SET_EMAIL_VERIFIED,
  SET_ERROR_NOTIFICATION,
  RESET_APP,
} from './constants';

const initialState = {
  isEmailVerified: false,
  isValidEmail: false,
  errorNotification: false,
};

const defaultState = JSON.stringify(initialState);

const verifyEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_EMAIL_VERIFIED:
      return {
        ...state,
        isEmailVerified: action.payload.isLinkVerified,
        isValidEmail: action.payload.isValidLink,
      };
    case SET_ERROR_NOTIFICATION:
      return {
        ...state,
        errorNotification: action.payload,
      };
    default:
      return state;
  }
};

export default verifyEmailReducer;
