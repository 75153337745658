/**
 *
 * Edit About Me Section
 *
 */

import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { isJson } from '../../../utils/helper';
import LexicalEditor from '../../Common/LexicalEditor';

const ProfileEditForm = (props) => {
  const {
    submitProfileEditForm,
    editProfileFormData,
    profileEditorChange,
    editProfileFormError,
    isProfileLoading,
    formType,
    user
  } = props;
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProfileEditForm(formType);
  };

  /*const textKeyUp = (event) => {
    var lines = event.target.value.split("\n");
    if (event.keyCode === 13 && user.roleName !== 'PUBLICOFFICIAL' && user.roleName !== 'ADMIN') {
      event.target.value = lines.slice(0, 10).join("\n");
    }
  };*/

  return (
    <>
      {typeof editProfileFormData !== 'undefined' ? (
        <form onSubmit={handleSubmit}>
          {isProfileLoading === true ? (
            <LoadingIndicator position={`fixed`} />
          ) : (
            ''
          )}
          <div className="clsedit-profile-form-blk">
            <Notification module={`dashboard`} />
            {formType === 'aboutMe' ? (
              <div className="form-group">
                <label htmlFor="aboutMe">
                  {t(`About Me`)}
                </label>
                <LexicalEditor
                  initialDraftJs={editProfileFormData.aboutMe}
                  initialHtml={editProfileFormData.aboutMe_html}
                  onDraftJsChange={(editorState) => {profileEditorChange('aboutMe', editorState, true, false)}}
                  onHtmlChange={(html) => {profileEditorChange('aboutMe_html', html, false, true)}}
                  reloadToggle={user.selected_language + formType}
                />
                {typeof editProfileFormError !== 'undefined' &&
                  editProfileFormError['aboutMe'] && (
                    <div className="clsinvalid">
                      {t(editProfileFormError?.['aboutMe'])}
                    </div>
                  )}
              </div>
            ) : formType === 'politicalAffiliation' ? (
              <div className="form-group">
                <label htmlFor="politicalAffiliation">
                  {t(`Political Affiliation`)}
                </label>
                <LexicalEditor
                  initialDraftJs={editProfileFormData.politicalAffiliation}
                  initialHtml={editProfileFormData.politicalAffiliation_html}
                  onDraftJsChange={(editorState) => {profileEditorChange('politicalAffiliation', editorState, true, false)}}
                  onHtmlChange={(html) => {profileEditorChange('politicalAffiliation_html', html, false, true)}}
                  reloadToggle={user.selected_language + formType}
                />
                {typeof editProfileFormError !== 'undefined' &&
                  editProfileFormError['politicalAffiliation'] && (
                    <div className="clsinvalid">
                      {t(editProfileFormError?.['politicalAffiliation'])}
                    </div>
                  )}
              </div>
            ) : formType === 'politicalBackground' ? (
              <div className="form-group">
                <label htmlFor="politicalBackground">
                  {t(`Political & Professional Background`)}
                </label>
                <LexicalEditor
                  initialDraftJs={editProfileFormData.politicalBackground}
                  initialHtml={editProfileFormData.politicalBackground_html}
                  onDraftJsChange={(editorState) => {profileEditorChange('politicalBackground', editorState, true, false)}}
                  onHtmlChange={(html) => {profileEditorChange('politicalBackground_html', html, false, true)}}
                  reloadToggle={user.selected_language + formType}
                />
                {typeof editProfileFormError !== 'undefined' &&
                  editProfileFormError['politicalBackground'] && (
                    <div className="clsinvalid">
                      {t(editProfileFormError?.['politicalBackground'])}
                    </div>
                  )}
              </div>
            ) : formType === 'whatDoIStandFor' ? (
              <div className="form-group">
                <label htmlFor="whatDoIStandFor">
                  {t(`What Do I Stand For`)}
                </label>
                <LexicalEditor
                  initialDraftJs={editProfileFormData.whatDoIStandFor}
                  initialHtml={editProfileFormData.whatDoIStandFor_html}
                  onDraftJsChange={(editorState) => {profileEditorChange('whatDoIStandFor', editorState, true, false)}}
                  onHtmlChange={(html) => {profileEditorChange('whatDoIStandFor_html', html, false, true)}}
                  reloadToggle={user.selected_language + formType}
                />
                {typeof editProfileFormError !== 'undefined' &&
                  editProfileFormError['whatDoIStandFor'] && (
                    <div className="clsinvalid">
                      {t(editProfileFormError?.['whatDoIStandFor'])}
                    </div>
                  )}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="text-center mb-4 pb-2">
            <button type="submit" className="btn clsyellow-btn">
              {t(`Save`)}
            </button>
          </div>
        </form>
      ) : (
        ''
      )}
    </>
  );
};

export default ProfileEditForm;
