/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import CreateEditIssueControls from './CreateEditIssueControls';
import { updateCommunityIssue } from '../../../containers/CommunityIssues/actions';
import ShowAreaMap from './ShowAreaMap';
import useS3 from '../../../utils/useS3';

const EditIssue = (props) => {
  const {
    communityIssueId,
    isOpen,
    setIsCommunityIssueOpen,
    allCommunityIssues,
    allTopics,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { uploadFileToS3 } = useS3();
  const communityIssue = allCommunityIssues[communityIssueId];

  // Add 90 days from today for default closeDate
  const defaultCloseDate = dayjs().add(90, 'day');
  
  const [title, setTitle] = useState(communityIssue?.title ?? '');
  const [textContent, setTextContent] = useState(communityIssue?.textContent ?? '');
  const [category, setCategory] = useState({
    value: communityIssue.topicId,
    label: allTopics?.[communityIssue.topicId]?.name
  });
  const [closeDate, setCloseDate] = useState(dayjs(communityIssue?.closingDate) ?? defaultCloseDate);
  const [mainImageFiles, setMainImageFiles] = useState([]);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [mainImageURL, setMainImageURL] = useState(communityIssue.mainImage);
  const [additionalImageURLs, setAdditionalImageURLs] = useState(communityIssue.attachedDocuments)

  const [reloadToggle, setReloadToggle] = useState(0);
  const [selectedAreaOption, setSelectedAreaOption] = useState(communityIssue.ocdId ? 'ocdId' : 'area'); // ['ocdId', 'area']
  const reset = () => {
    setTitle(communityIssue?.title ?? '');
    setTextContent(communityIssue?.textContent ?? '');
    setCategory({
      value: communityIssue.topicId,
      label: allTopics?.[communityIssue.topicId]?.name
    });
    setCloseDate(dayjs(communityIssue?.closingDate) ?? defaultCloseDate);
    setMainImageFiles([]);
    setAdditionalFiles([]);
    setReloadToggle(prev => prev + 1);
    setSelectedAreaOption(communityIssue.ocdId ? 'ocdId' : 'area');
  }

  useEffect(() => {
    reset();
  }, [communityIssue])

  const [errors, setErrors] = useState({});

  const originalLanguage = useSelector(state => state.account.user.selected_language);

  // Clear errors
  useEffect(() => {
    if (title) {
      setErrors(prev => ({ ...prev, title: undefined }));
    }
    if (textContent) {
      setErrors(prev => ({ ...prev, textContent: undefined }));
    }
    if (category) {
      setErrors(prev => ({ ...prev, category: undefined }));
    }
    if (mainImageFiles && mainImageFiles.length > 0) {
      setErrors(prev => ({ ...prev, mainImage: undefined }));
    }
    if (additionalFiles && additionalFiles.length > 0) {
      setErrors(prev => ({ ...prev, additionalFiles: undefined }));
    }
  }, [title, textContent, category, mainImageFiles, additionalFiles]);

  const TITLE_LENGTH_LIMIT = 100;

  const validateIssue = () => {
    // Validate
    const errors = {};
    if (!title) {
      errors.title = t(`Please enter a title for your issue`);
    }
    if (title.length >= TITLE_LENGTH_LIMIT) {
      errors.title = t(`Title cannot be longer than`) + ' ' + TITLE_LENGTH_LIMIT + ' ' + t('characters');
    }
    if (!category) {
      errors.category = t(`Please select a category for your issue`);
    }

    if (mainImageFiles.length > 0 && mainImageFiles[0].size > 10 * 1024 * 1024) {
      errors.mainImage = t(`Main image cannot be larger than 10 MB`);
    } 
    if (additionalFiles.length > 0 && additionalFiles.some(file => file.size > 10 * 1024 * 1024)) {
      errors.additionalFiles = t(`Additional files cannot be larger than 10 MB`);
    }

    if (Object.keys(errors).length > 0) {
      console.log(errors);
      setErrors(errors);
      return false;
    }

    return true;
  }

  const errorTitleRef = useRef(null);
  const errorCategoryRef = useRef(null);
  const errorMainImageRef = useRef(null);

  useEffect(() => {
    if (errors.title) {
      errorTitleRef?.current?.scrollIntoView();
    }
    else if (errors.category) {
      errorCategoryRef?.current?.scrollIntoView();
    }
    else if (errors.mainImageRef) {
      errorMainImageRef?.current?.scrollIntoView();
    }
  }, [errors]);

  const submitIssue = async () => {
    if (!validateIssue()) {
      return;
    }

    let mainImageUrl;
    if (mainImageFiles.length > 0) {
      mainImageUrl = await uploadFileToS3(mainImageFiles[0])
    } else if (mainImageURL) {
      mainImageUrl = mainImageURL;
    } else {
      mainImageUrl = null;
    }
    const additionalImageUrls = additionalFiles.length > 0 ? await Promise.all(additionalFiles.map(async (file) => await uploadFileToS3(file))) : [];

    // Submit
    const validatedIssue = {
      title,
      textContent,
      category: category.value,
      closingDate: closeDate.format('YYYY-MM-DD'),
      originalLanguage: originalLanguage || 'EN',
      mainImage: mainImageUrl,
      attachedDocuments: [...(additionalImageURLs ?? []), ...(additionalImageUrls ?? [])]
    };
    dispatch(updateCommunityIssue(communityIssueId, validatedIssue));
    setIsCommunityIssueOpen(false);
  }

  const [invalidateSize, setInvalidateSize] = useState(false);
  useEffect(() => {
    if (isOpen) {
      // Toggle invalidateSize semi-frequently over 500 ms to ensure the map is resized properly
      const interval = setInterval(() => {
        setInvalidateSize(prev => !prev);
      }, 10);

      setTimeout(() => {
        clearInterval(interval);
      }, 500);
    }
  }, [isOpen]);

  return ( 
    <div className='edit-issue-container'>
      <CreateEditIssueControls>
      <div ref={errorTitleRef} />
        <CreateEditIssueControls.Title
          title={title} 
          setTitle={setTitle} 
          error={errors.title}
        />
        <CreateEditIssueControls.Description
          setDescription={setTextContent}
          error={errors.textContent}
          reloadToggle={reloadToggle}
          initialHtml={textContent}
        />
        <div ref={errorCategoryRef} />
        <div ref={errorMainImageRef} />
        <CreateEditIssueControls.UploadMainImage
          files={mainImageFiles}
          setFiles={setMainImageFiles}
          error={errors.mainImage}
          displayImagePreview
          initialURL={mainImageURL}
          setMainImageURL={setMainImageURL}
        />
        <CreateEditIssueControls.CategorySelect
          category={category}
          setCategory={setCategory}
          error={errors.category}
        />
        <CreateEditIssueControls.ClosingDatePicker
          closeDate={closeDate}
          setCloseDate={setCloseDate}
          defaultCloseDate={defaultCloseDate}
          defaultHasDateChanged={true}
        />
        <CreateEditIssueControls.SelectAreaOrDistrict
          selectedAreaOption={selectedAreaOption}
          setSelectedAreaOption={setSelectedAreaOption}
          disabled={true}
        />
        {
          selectedAreaOption === 'area' &&
            <ShowAreaMap communityIssueId={communityIssueId} invalidateSize={invalidateSize} />
        }
        {
          selectedAreaOption === 'ocdId' &&
            <CreateEditIssueControls.DistrictSelect
              defaultValue={communityIssue?.ocdIdInfo?.name}
              setDistrict={() => {}}
              error={errors.districts}
              disabled={true}
            />
        }
        <CreateEditIssueControls.UploadAdditionalFiles
          files={additionalFiles}
          setFiles={setAdditionalFiles}
          error={errors.additionalFiles}
          initialURLs={additionalImageURLs}
          setAdditionalImageURLs={setAdditionalImageURLs}
        />
        <div className='update-button-container'>
          <button 
            onClick={submitIssue} 
            type="button" 
            className="btn clsyellow-btn"
            // data-dismiss="modal"
          >
            { t(`Update`) }
          </button>
        </div>
      </CreateEditIssueControls>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allTopics: state.communityIssues.allTopics,
    myDistricts: state.account.myDistricts,
  };
};

export default connect(mapStateToProps, actions)(EditIssue)