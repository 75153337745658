/*
 *
 * Events
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import AllEventsList from './all-events-index';
import MyEventsList from './my-events-index';
import { useTranslation } from 'react-i18next';

const EventList = (props) => {
  const { activeEventTab, setEventTab, setMyEventTab } = props;

  const resetTabContent = (tab) => {
    setEventTab(tab);
    setMyEventTab('upcoming-events');
  };
  const { t } = useTranslation();

  return (
    <div className="clsall-events-tab">
      <ul className="nav nav-tabs mb-4" id="eventsTab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className={
              activeEventTab === 'all-events' ? 'nav-link active' : 'nav-link'
            }
            id="all-events-tab"
            data-toggle="tab"
            href="#all-events"
            role="tab"
            aria-controls="all-events"
            onClick={() => {
              resetTabContent('all-events');
            }}
            aria-selected={activeEventTab === 'all-events' ? 'true' : 'false'}
          >
            {t(`All Events`)}
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className={
              activeEventTab === 'my-events' ? 'nav-link active' : 'nav-link'
            }
            id="my-events-tab"
            data-toggle="tab"
            href="#my-events"
            role="tab"
            aria-controls="my-events"
            onClick={() => {
              resetTabContent('my-events');
            }}
            aria-selected={activeEventTab === 'my-events' ? 'true' : 'false'}
          >
            {t(`My Events`)}
          </a>
        </li>
      </ul>
      <div className="tab-content" id="eventsTabContent">
        <div
          className={
            activeEventTab === 'all-events'
              ? 'tab-pane fade show active'
              : 'tab-pane fade'
          }
          id="all-events"
          role="tabpanel"
          aria-labelledby="all-events-tab"
        >
          {activeEventTab === 'all-events' ? <AllEventsList {...props} /> : ''}
        </div>

        <div
          className={
            activeEventTab === 'my-events'
              ? 'tab-pane fade show active'
              : 'tab-pane fade'
          }
          id="my-events"
          role="tabpanel"
          aria-labelledby="my-events-tab"
        >
          {activeEventTab === 'my-events' ? <MyEventsList {...props} /> : ''}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    events: state.event.events,
    myEvents: state.event.myEvents,
    eventsLength: state.event.eventsLength,
    editModalTriger: state.event.editModalTriger,
    confirmPopup: state.event.confirmPopup,
    noRecords: state.event.noRecords,
    reloadList: state.event.reloadList,
    pageLoader: state.event.pageLoader,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    filterDismissal: state.event.filterDismissal,
    filter: state.event.filter,
    filterSelected: state.event.filterSelected,
    calendar: state.event.calendar,
    calendarSelected: state.event.calendarSelected,
    searchBox: state.event.searchBox,
    isEventLoading: state.event.isEventLoading,
    isOptionLoading: state.event.isOptionLoading,
    eventFormData: state.event.eventFormData,
    eventFormErrors: state.event.eventFormErrors,
    isEventSubmitting: state.event.isEventSubmitting,
    activeEventTab: state.event.activeEventTab,
    activeMyEventTab: state.event.activeMyEventTab,
  };
};

export default connect(mapStateToProps, actions)(EventList);
