/**
 *
 * Edit Social Links
 *
 */

import React from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import { useTranslation } from 'react-i18next';

const ResidentProfile = (props) => {
  const {
    submitSocialLinkForm,
    editSocialLinkData,
    socialLinkData,
    socialLinkError,
    isProfileLoading,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSocialLinkForm();
  };

  return (
    <>
      {typeof socialLinkData !== 'undefined' ? (
        <form onSubmit={handleSubmit}>
          {isProfileLoading === true ? (
            <LoadingIndicator position={`fixed`} />
          ) : (
            ''
          )}
          <div className="clsedit-profile-form-blk">
            <Notification module={`dashboard`} />
            <div className="form-group">
              <label htmlFor="twitter">{t(`Twitter`)}</label>
              <input
                type="text"
                className="form-control"
                placeholder="Twitter"
                name={'twitter'}
                value={socialLinkData.socialPlatform['twitter']}
                onChange={(e) =>
                  editSocialLinkData(e.target.name, e.target.value)
                }
              />
              {typeof socialLinkError !== 'undefined' &&
                socialLinkError.twitter && (
                  <div className="clsinvalid">{t(socialLinkError?.['twitter'])}</div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="facebook">{t(`Facebook`)}</label>
              <input
                type="text"
                className="form-control"
                placeholder="Facebook"
                name={'facebook'}
                value={socialLinkData.socialPlatform['facebook']}
                onChange={(e) =>
                  editSocialLinkData(e.target.name, e.target.value)
                }
              />
              {typeof socialLinkError !== 'undefined' &&
                socialLinkError.facebook && (
                  <div className="clsinvalid">
                    {t(socialLinkError?.['facebook'])}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="linkedIn">{t(`LinkedIn`)}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t(`LinkedIn`)}
                name={'linkedIn'}
                value={socialLinkData.socialPlatform['linkedIn']}
                onChange={(e) =>
                  editSocialLinkData(e.target.name, e.target.value)
                }
              />
              {typeof socialLinkError !== 'undefined' &&
                socialLinkError.linkedIn && (
                  <div className="clsinvalid">
                    {t(socialLinkError?.['linkedIn'])}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="nextDoor">{t(`Nextdoor`)}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t(`Nextdoor`)}
                name={'nextDoor'}
                value={socialLinkData.socialPlatform['nextDoor']}
                onChange={(e) =>
                  editSocialLinkData(e.target.name, e.target.value)
                }
              />
              {typeof socialLinkError !== 'undefined' &&
                socialLinkError.nextDoor && (
                  <div className="clsinvalid">
                    {t(socialLinkError?.['nextDoor'])}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="email">{t(`Email`)}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t(`Email`)}
                name={'email'}
                value={socialLinkData.socialPlatform['email']}
                onChange={(e) =>
                  editSocialLinkData(e.target.name, e.target.value)
                }
              />
              {typeof socialLinkError !== 'undefined' &&
                socialLinkError.email && (
                  <div className="clsinvalid">{t(socialLinkError?.['email'])}</div>
                )}
            </div>
          </div>
          <div className="text-center mb-4 pb-2">
            <button
              type="button"
              className="btn clsyellow-btn"
              onClick={handleSubmit}
            >
              {t(`Save`)}
            </button>
          </div>
        </form>
      ) : (
        ''
      )}
    </>
  );
};

export default ResidentProfile;
