/*
 *
 * Petition actions
 *
 */

import {
  SET_PETITION_TAB,
  PETITION_CHANGE,
  PETITION_FORM_CHANGE,
  SET_PETITION_FORM_RESET,
  SET_PETITION_LOADING,
  SET_PETITION_FORM_ERRORS,
  FETCH_PETITION_DATA,
  PETITION_RELOAD_LIST,
  SET_PETITION_SINGLE,
  SET_SIGNIN_PETITION,
  PETITION_SPECIFIC_DATA,
  FETCH_MY_PETITION_DATA,
  SET_MYPETITION_TAB,
  RESET_APP,
  SET_FILTER_LEVEL,
} from './constants';
import { addNotification } from '../Notification/actions';
import { allFieldsValidation } from '../../utils/validation';
import axios from 'axios';
import { BASE_API_URL } from '../../constants';
//import { formatFileSize } from '../../utils/helper';

export const resetPetitionState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const petitionFormChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: PETITION_FORM_CHANGE, payload: formData });
  };
};

export const petitionEditorChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = JSON.stringify(value);
    return dispatch({ type: PETITION_FORM_CHANGE, payload: formData });
  };
};

export const petitionChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: PETITION_CHANGE, payload: formData });
  };
};

export const petitionChangeByOption = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData[name] = value;
    if (name === 'sort') {
      dispatch({ type: PETITION_CHANGE, payload: formData });
      return dispatch(reloadPetition());
    } else {
      return dispatch({ type: PETITION_CHANGE, payload: formData });
    }
  };
};

export const getPetitionLists = (page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let activePetitionTab = getState().petition.activePetitionTab;
      let sort = getState().petition.sort;
      let filterSelected = getState().petition.filterSelected;
      let filter = getState().petition.filter;
      let apiUri = '/users/petitions?page=' + page;
      if (activePetitionTab === 'expired-petitions') {
        apiUri = '/users/petitions?page=' + page + '&expired=' + true;
      }

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }
      let pathName = getState().router.location.search;

      const topicId = new URLSearchParams(pathName).get('topicId');

      if (
        typeof topicId === 'undefined' ||
        topicId === null ||
        topicId === ''
      ) {
        //Sorting & Filter
        if (sort !== '') {
          apiUri += '&sort=' + sort;
        }

        if (filterSelected['topics'].length > 0) {
          apiUri += '&topics=' + JSON.stringify(filterSelected['topics']);
        }
        if (filterSelected['locations'].length > 0) {
          let locations = filter['locations']['items']
            .filter((item, index) => {
              return filterSelected['locations'].indexOf(item.id) !== -1;
            })
            .map((item) => item.name);
          apiUri += '&locations=' + JSON.stringify(locations);
        }
        // if (filterSelected['status'].length > 0) {
        //   apiUri += '&status=' + JSON.stringify(filterSelected['status']);
        // }
      } else {
        //Topic Parameter
        let topic = [];
        topic[0] = topicId;
        apiUri += '&topics=' + JSON.stringify(topic);
      }

      if (page === 1) {
        formData['petitionLists'] = [];
        dispatch(petitionChange(formData));
      }
      //dispatch({ type: SET_PETITION_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().petition.petitionLists.length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_PETITION_DATA,
                  payload: response.data.data,
                });
              }
            } else if (
              page === 1 &&
              getState().petition.petitionLists.length === 0
            ) {
              dispatch({
                type: FETCH_PETITION_DATA,
                payload: response.data.data,
              });
            }
            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            formData['reloadList'] = false;
            dispatch(petitionChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
            }),
          );
        })
        .finally(() => {
          // dispatch({ type: SET_PETITION_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const getMyPetitionLists = (page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let activeMyPetitionTab = getState().petition.activeMyPetitionTab;
      let apiUri = '/users/petitions?page=' + page + '&myPetition=' + true;

      if (activeMyPetitionTab === 'signed-petitions') {
        apiUri = '/users/petitions?page=' + page + '&signedPetitions=' + true;
      }
      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }

      if (page === 1) {
        formData['myPetitions'] = [];
        dispatch(petitionChange(formData));
      }
      //dispatch({ type: SET_PETITION_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().petition.myPetitions.length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_MY_PETITION_DATA,
                  payload: response.data.data,
                });
              }
            } else if (
              page === 1 &&
              getState().petition.myPetitions.length === 0
            ) {
              dispatch({
                type: FETCH_MY_PETITION_DATA,
                payload: response.data.data,
              });
            }
            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            formData['reloadList'] = false;
            dispatch(petitionChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
            }),
          );
        })
        .finally(() => {
          // dispatch({ type: SET_PETITION_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const resetPetitionForm = () => {
  return async (dispatch, getState) => {
    return dispatch({
      type: SET_PETITION_FORM_RESET,
    });
  };
};

export const reloadPetition = () => {
  return async (dispatch, getState) => {
    dispatch({ type: PETITION_RELOAD_LIST });
  };
};

export const setPetitionTab = (status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PETITION_TAB, payload: status });
  };
};

export const setMyPetitionTab = (status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_MYPETITION_TAB, payload: status });
  };
};

export const getFilterPetitionItems = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(BASE_API_URL + '/users/petitiontopiclocations')
        .then((response) => {
          if (response.data.success === 'true') {
            let filter = getState().petition.filter;
            filter['topics']['items'] = response.data.data.topics;
            filter['locations']['items'] = response.data.data.locations;
            let formData = {};
            formData['filter'] = filter;
            dispatch(petitionChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const resetPetitionFilter = () => {
  return async (dispatch, getState) => {
    let filterSelected = getState().petition.filterSelected;
    filterSelected['topics'] = [];
    filterSelected['locations'] = [];
    filterSelected['status'] = [];
    let formData = {};
    formData['filterSelected'] = filterSelected;
    return dispatch(petitionChange(formData));
  };
};

export const filterPetitionChange = (type, value) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().petition.filterSelected;
    if (filterSelected[type].indexOf(value) !== -1) {
      const index = filterSelected[type].indexOf(value);
      if (index > -1) {
        filterSelected[type].splice(index, 1);
      }
    } else {
      filterSelected[type].push(value);
    }
    let formData = {};
    formData['filterSelected'] = { ...filterSelected };
    return dispatch(petitionChange(formData));
  };
};

export const getSearchPetitionData = (type) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let searchBox = getState().petition.searchBox;
      let value = searchBox[type].key;
      let formData = {};
      let suggestionBox = getState().petition.suggestionBox;
      suggestionBox[type]['loading'] = true;
      formData['suggestionBox'] = { ...suggestionBox };
      dispatch(petitionChange(formData));
      await axios
        .get(
          BASE_API_URL +
            '/users/petitiontopiclocations/?searchBy=' +
            type +
            '&keyword=' +
            value,
        )
        .then((response) => {
          if (response.data.success === 'true') {
            suggestionBox = getState().petition.suggestionBox;
            suggestionBox[type]['items'] = response.data.data[type];
            suggestionBox[type]['loading'] = false;
            formData['suggestionBox'] = { ...suggestionBox };
            dispatch(petitionChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const searchBoxPetitionChange = (type, key, value) => {
  return async (dispatch, getState) => {
    let searchBox = getState().petition.searchBox;
    searchBox[type] = { ...searchBox[type], ...{ [key]: value } };
    let formData = {};
    formData['searchBox'] = { ...searchBox };
    dispatch(petitionChange(formData));
  };
};

export const suggestionBoxPetitionChange = (type, key, value) => {
  return async (dispatch, getState) => {
    let suggestionBox = getState().petition.suggestionBox;
    suggestionBox[type] = { ...suggestionBox[type], ...{ [key]: value } };
    let formData = {};
    formData['suggestionBox'] = { ...suggestionBox };
    dispatch(petitionChange(formData));
  };
};

export const followPetitionSuggested = (type, value) => {
  return async (dispatch, getState) => {
    let filter = getState().petition.filter;
    const found = filter[type]['items'].find((item) => item.id === value.id);

    if (typeof found === 'undefined') {
      filter[type]['items'] = [...filter[type]['items'], value];
      let formData = {};
      formData['filter'] = filter;
      dispatch(petitionChange(formData));
    }
    dispatch(filterPetitionChange(type, value.id));
  };
};

export const submitPetitionFilter = () => {
  return async (dispatch, getState) => {
    let formData = {};
    formData['filterDismissal'] = true;
    dispatch(petitionChange(formData));
    dispatch(reloadPetition());
  };
};

export const convertTagsToFrontend = (tags) => {
  let topicArray = tags.map((tag) => {
    return { id: tag.value, text: tag.value, uid: tag.id };
  });
  return topicArray;
};

export const convertTagsToBackend = (tags) => {
  let topicArray = tags.map((tag) => {
    return {
      id: typeof tag.uid !== 'undefined' ? tag.uid : null,
      value: tag.text,
    };
  });
  return topicArray;
};

export const validatePetitionForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let petition = getState().petition.petitionFormData;
      let errorList = {};
      let petitionFormValidation = {};
      let petitionData = {
        title: 'required',
        description: 'required|max:3000',
        tags: 'required',
      };
      petitionFormValidation = {
        'required.description': 'Required',
        'max.description':
          'The Description may not be greater than 3000 characters.',
      };
      const { isValid, errors } = allFieldsValidation(
        petition,
        petitionData,
        petitionFormValidation,
        {
          'required.title': 'Required',
          'required.tags': 'At least one topic is required',
        },
      );

      //active_until validation
      let activeUntil = petition.active_for;
      if (activeUntil === '') {
        errorList = { ...errorList, active_for: ['Required'] };
      }
      if (activeUntil > 26) {
        errorList = {
          ...errorList,
          active_for: ['Initatives can only last between 1 and 26 weeks'],
        };
      }

      errorList = { ...errorList, ...errors };

      if (!isValid || activeUntil > 26 || activeUntil === '') {
        dispatch({ type: SET_PETITION_FORM_ERRORS, payload: errorList });
        return reject(errorList);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitPetitionForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(validatePetitionForm())
        .then(async () => {
          dispatch({ type: SET_PETITION_FORM_ERRORS, payload: {} });
          dispatch(petitionSubmit());
        })
        .catch((error) => {
          dispatch({ type: SET_PETITION_LOADING, payload: false });
        });
    });
  };
};

export const petitionSubmit = () => {
  return async (dispatch, getState) => {
    await dispatch(uploadPetitionToS3());
    //await dispatch(removePetitionFromS3());

    let petition = getState().petition.petitionFormData;
    let topicArray = convertTagsToBackend(petition.tags);

    /*let petitionInfo = {
      title: petition.title,
      division: petition.division,
      active_for: petition.active_for,
      description: petition.description,
      topics: topicArray,
      images: (petition.images.length > 0 ? petition.images : petition.links),
      files: petition.files
    }*/
    const petitionInfo = new FormData();
    petitionInfo.append('title', petition.title);
    petitionInfo.append('division', petition.division);
    petitionInfo.append('active_for', petition.active_for);
    petitionInfo.append('description', petition.description);
    petitionInfo.append('topics', JSON.stringify(topicArray));
    petitionInfo.append(
      'images',
      petition.images.length > 0 ? petition.images : petition.links,
    );
    petition.files.forEach((file, i) => {
      petitionInfo.append('files', file, file.name);
    });

    dispatch({ type: SET_PETITION_LOADING, payload: true });
    if (typeof petition.id !== 'undefined') {
      await axios
        .put(
          BASE_API_URL + '/users/updateMyPetition/' + petition.id,
          petitionInfo,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'user-token': localStorage.getItem('token'),
            },
          },
        )
        .then((response) => {
          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'petition',
            }),
          );
          let formData = {};
          formData['dismissal'] = true;
          dispatch({ type: PETITION_FORM_CHANGE, payload: formData });
          dispatch(getPetitionLists());
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
            }),
          );
        })
        .finally(() => {
          dispatch(resetPetitionForm());
          dispatch({ type: SET_PETITION_LOADING, payload: false });
        });
    } else {
      await axios
        .post(BASE_API_URL + '/users/newPetition', petitionInfo, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'user-token': localStorage.getItem('token'),
          },
        })
        .then((response) => {
          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'petition',
            }),
          );
          let formData = {};
          formData['dismissal'] = true;
          dispatch({ type: PETITION_FORM_CHANGE, payload: formData });
          dispatch({ type: SET_PETITION_TAB, payload: 'my-petitions' });
          dispatch(getMyPetitionLists());
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
            }),
          );
        })
        .finally(() => {
          dispatch(resetPetitionForm());
          dispatch({ type: SET_PETITION_LOADING, payload: false });
        });
    }
  };
};

export const petitionFormImageChange = (name, value) => {
  return async (dispatch, getState) => {
    let petitionFormData = getState().petition.petitionFormData;
    if (petitionFormData.images.length > 0)
      petitionFormData['removeImages'] = petitionFormData.images;
    petitionFormData['files'] = [];
    petitionFormData['images'] = [];

    let formData = {};
    formData['petitionFormData'] = petitionFormData;
    dispatch(petitionChange(formData));
    dispatch(petitionFormChange(name, value));
  };
};

export const removePetitionImage = () => {
  return async (dispatch, getState) => {
    let petitionFormData = getState().petition.petitionFormData;
    if (petitionFormData.images.length > 0)
      petitionFormData['removeImages'] = petitionFormData.images;
    petitionFormData['files'] = [];
    petitionFormData['images'] = [];

    let formData = {};
    formData['petitionFormData'] = petitionFormData;
    dispatch(petitionChange(formData));
    dispatch(petitionFormChange());
  };
};

export const uploadPetitionToS3 = () => {
  return async (dispatch, getState) => {
    let files = getState().petition.petitionFormData.files;
    //let newFiles = [];

    if (files.length > 0) {
      try {
        let error = false;
        let errorList = getState().petition.petitionFormErrors;
        let links = getState().petition.petitionFormData.links;

        if (!error) {
          if (errorList.hasOwnProperty('files') === true) {
            delete errorList['files'];
            dispatch({ type: SET_PETITION_FORM_ERRORS, payload: errorList });
          }
        } else {
          errorList = {
            ...errorList,
            files: [
              'Error on uploading files. Please remove it and upload it again.',
            ],
          };
          dispatch({ type: SET_PETITION_FORM_ERRORS, payload: errorList });
        }
        //newFiles = files.filter(file => file !== null);

        dispatch(petitionFormChange('links', links));
        return dispatch(petitionFormChange('files', files));
      } catch (e) {
        return;
      }
    }
  };
};

export const setPetitionData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // dispatch({ type: RESET_PETITION_SINGLE });
      await axios
        .get(BASE_API_URL + '/users/petitions/' + id)
        .then((response) => {
          if (response.data.success === 'true') {
            if (typeof response.data.data !== 'undefined') {
              dispatch({
                type: SET_PETITION_SINGLE,
                payload: response.data.data,
              });
              let formData = {};
              formData['filterDismissal'] = false;
              dispatch({ type: PETITION_CHANGE, payload: formData });
            }
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const withoutAuthenticatePetitionData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(BASE_API_URL + '/users/particularPetition/' + id)
        .then((response) => {
          if (response.data.success === 'true') {
            if (typeof response.data.data !== 'undefined') {
              dispatch({
                type: SET_PETITION_SINGLE,
                payload: response.data.data,
              });
              let formData = {};
              formData['filterDismissal'] = false;
              dispatch({ type: PETITION_CHANGE, payload: formData });
            }
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const submitSignPetition = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(BASE_API_URL + '/users/signPetition/' + id)
        .then((response) => {
          if (response.data.success === 'true') {
            if (typeof response.data.data !== 'undefined') {
              dispatch({
                type: SET_SIGNIN_PETITION,
                payload: response.data.data,
              });
              dispatch(setPetitionData(id));
              let formData = {};
              formData['filterDismissal'] = true;
              dispatch({ type: PETITION_CHANGE, payload: formData });
            }
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const updatePetitionSingle = (petition) => {
  return async (dispatch, getState) => {
    resetPetitionForm();
    dispatch(petitionChangeByOption('filterDismissal', false));
    let formData = {
      id: petition.id,
      petition_title: 'Edit Initiative',
      title: petition.title,
      division: petition.division,
      active_for: petition.active_for,
      description: petition.description,
      images: petition.images ? petition.images : [],
      tags: convertTagsToFrontend(petition.topics),
      files: [],
      links: [],
      removeImages: [],
      dismissal: false,
    };
    dispatch({ type: PETITION_SPECIFIC_DATA, payload: formData });
  };
};

export const divisionNames = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // await axios.get(BASE_API_URL + '/users/getDivisionNames/'
      // ).then(response => {
      //   if (response.data.success === 'true') {
      //     if (typeof response.data.data !== 'undefined') {
      //       dispatch({ type: PETITION_DIVISION_DATA, payload: response.data.data });
      //     }
      //     return resolve(true)
      //   }
      // }).catch(error => {
      //   dispatch(addNotification({ type: 'error', message: error?.response?.data?.error, module: 'petition', displayFor: 5000 }));
      //   return reject(false)
      // })
      // return resolve(true)
      let apiUri = '/users/geoidbasedOcdids';
      await axios
        .get(BASE_API_URL + apiUri)
        .then(async (response) => {
          if (response.data.success === 'true') {
            if (typeof response.data.data !== 'undefined') {
              const divisionData = response.data.data;
              dispatch(getFilterData(divisionData));
            }
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'petition',
              displayFor: 3000,
            }),
          );
          return reject(false);
        });
    });
  };
};

export const getFilterData = (divisionValue) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      var filterData = { Local: [], State: [], Federal: [] };
      const requests = divisionValue.map(function (value) {
        if (value.level1 in filterData) {
          if (value.level2 in filterData[value.level1]) {
            filterData[value.level1][value.level2].push(value);
          } else {
            filterData[value.level1][value.level2] = [value];
          }
        } else {
          filterData[value.level1] = { [value.level2]: [value] };
        }
        return filterData;
      });
      // Wait for all requests, and then setState
      return Promise.all(requests).then(() => {
        dispatch({ type: SET_FILTER_LEVEL, payload: filterData });
      });
    });
  };
};

export const divisionSelectionChange = (divisionDetails) => {
  return async (dispatch, getState) => {
    let districtSelected = getState().petitioned;

    districtSelected['divisionName'] = divisionDetails;

    let formData = {};
    formData['districtSelected'] = { ...districtSelected };
  };
};
