import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import { SET_AUTH } from './containers/Authentication/constants';
import setToken from './utils/token';
import './utils/setupInterceptors';
import reportWebVitals from './reportWebVitals';
import './i18nextConf';
import {guestProfileLogin, guestProfileRegistration, getInactiveStatusMesages} from "./containers/Dashboard/actions";
import {setSilentUser} from "./containers/Authentication/actions";

// Authentication
const token = localStorage.getItem('token');

if (token) {
    const guest_user = localStorage.getItem('guest_user');

    if (!guest_user) {
        // authenticate api authorization
        setToken(token);

        // authenticate routes
        store.dispatch({type: SET_AUTH});
    } else {
        // authenticate api authorization
        setToken(token, true);

        store.dispatch(guestProfileLogin());
        // store.dispatch(getInactiveStatusMesages());

        // authenticate routes
        store.dispatch(setSilentUser());
    }
} else {
    store.dispatch(guestProfileRegistration())
    // store.dispatch(setSilentUser());
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
