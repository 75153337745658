/**
 *
 * Display All Petition List
 *
 */

import React from 'react';
import PetitionList from './petitionList';
import { useTranslation } from 'react-i18next';

const PetitionScreen = (props) => {
  const { petitionLists, user } = props;
  const { t } = useTranslation();

  return (
    <>
      {petitionLists.length === 0 ? (
        <div className={'mb-3 no-engagement'}>
          <span className={'sad-img'}>
            <img
              src={require('../../../assets/img/sad.svg').default}
              alt="No Initiatives"
            />
          </span>
          <span className="no-engagement-txt">
            {user.roleName === 'RESIDENT'
              ? t(
                  `Nobody in your area started a initiatives yet. Be the first one!`,
                )
              : t('Nobody in your area started a initiatives yet.')}
          </span>
        </div>
      ) : (
        petitionLists.map((petition, index) => (
          <PetitionList key={index} {...props} petition={petition} />
        ))
      )}
    </>
  );
};

export default PetitionScreen;
