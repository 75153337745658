/*
 *
 * Application actions
 *
 */

import {
  DEFAULT_ACTION,
  SET_COUNTDOWN_STATUS,
  SET_STATUS_MESSAGES,
} from './constants';
import axios from 'axios';
import { BASE_API_URL } from '../../constants';

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
};

export const setCoundDownStatus = (value) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_COUNTDOWN_STATUS, payload: value });
  };
};

export const getStatusMesages = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(
        BASE_API_URL + '/users/getStatusMessage/web',
      );
      if (response.data.success === 'true') {
        dispatch({ type: SET_STATUS_MESSAGES, payload: response.data.data });
      }
    } catch (error) {}
  };
};
