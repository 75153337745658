/**
 *
 * Edit Resident Profile
 *
 */

import React from 'react';
import SelectOption from '../../Common/SelectOption';
import { handleOptionValue } from '../../../utils/helper';
import { showMyNameOptionsPO } from '../../../containers/Dashboard/constants';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY } from '../../../constants';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import { useTranslation } from 'react-i18next';

const OfficialProfile = (props) => {
  const {
    submitProfileForm,
    editProfileData,
    editProfileChange,
    fillAddresses,
    editProfileErrors,
    isProfileLoading,
  } = props;
  const { t } = useTranslation();

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) => {
      fillAddresses(ref, place.address_components);
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry'],
    },
  });

  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitProfileForm(true);
  };

  return (
    <>
      {typeof editProfileData !== 'undefined' ? (
        <form onSubmit={handleSubmit}>
          {isProfileLoading === true ? (
            <LoadingIndicator position={`fixed`} />
          ) : (
            ''
          )}
          <div className="clsedit-profile-form-blk">
            <Notification module={`dashboard`} />
            <div className="clsedit-profile-lable-blk">
              <label>{t(`Name`)}</label>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name={'first_name'}
                  value={editProfileData.first_name}
                  onChange={(e) =>
                    editProfileChange(e.target.name, e.target.value)
                  }
                />
                {typeof editProfileErrors.first_name !== 'undefined' &&
                  editProfileErrors.first_name && (
                    <div className="clsinvalid">
                      {t(editProfileErrors?.['first_name'])}
                    </div>
                  )}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name={'last_name'}
                  value={editProfileData.last_name}
                  onChange={(e) =>
                    editProfileChange(e.target.name, e.target.value)
                  }
                />
                {typeof editProfileErrors.last_name !== 'undefined' &&
                  editProfileErrors.last_name && (
                    <div className="clsinvalid">
                      {t(editProfileErrors?.['last_name'])}
                    </div>
                  )}
              </div>
            </div>
            <div className="form-group">
              <label>{t(`Show My Name As:`)}</label>
              <SelectOption
                name={'showMyNameAs'}
                placeholder={'ShowMyName'}
                defaultValue={editProfileData.showMyNameAs}
                value={handleOptionValue(
                  showMyNameOptionsPO,
                  editProfileData.showMyNameAs === null
                    ? 'fullName'
                    : editProfileData.showMyNameAs,
                )}
                options={showMyNameOptionsPO}
                handleSelectChange={(value) => {
                  editProfileChange('showMyNameAs', value.value);
                }}
              />
              {typeof editProfileErrors.showMyNameAs !== 'undefined' &&
                editProfileErrors.showMyNameAs && (
                  <div className="clsinvalid">
                    {t(editProfileErrors?.['showMyNameAs'])}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label>{t(`Organization Name`)}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t(`Organization`)}
                name={'organization_name'}
                style={{ width: '100%' }}
                value={editProfileData.organization_name}
                onChange={(e) =>
                  editProfileChange(e.target.name, e.target.value)
                }
                onKeyDown={preventSubmit}
              />
              {typeof editProfileErrors.organization_name !== 'undefined' &&
                editProfileErrors.organization_name && (
                  <div className="clsinvalid">
                    {t(editProfileErrors?.['organization_name'])}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label>{t(`Position`)}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t(`Position`)}
                name={'position'}
                style={{ width: '100%' }}
                value={editProfileData.position}
                onChange={(e) =>
                  editProfileChange(e.target.name, e.target.value)
                }
                onKeyDown={preventSubmit}
              />
              {typeof editProfileErrors.position !== 'undefined' &&
                editProfileErrors.position && (
                  <div className="clsinvalid">
                    {t(editProfileErrors?.['position'])}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label>{t(`Phone Number`)}</label>
              <input
                type="text"
                name={'phone_number'}
                className="form-control"
                placeholder={t(`Phone Number`)}
                value={editProfileData.phone_number}
                onChange={(e) =>
                  editProfileChange(e.target.name, e.target.value)
                }
                onFocus={(e) =>
                  editProfileChange(e.target.name, e.target.value)
                }
                disabled
              />
              {typeof editProfileErrors.phone_number !== 'undefined' &&
                editProfileErrors.phone_number && (
                  <div className="clsinvalid">
                    {t(editProfileErrors?.['phone_number'])}
                  </div>
                )}
            </div>
            <div className="form-group">
              <label>{t(`Email`)}</label>
              <input
                type="text"
                name={'email'}
                className="form-control"
                placeholder={t(`Email Address`)}
                value={editProfileData.email}
                onChange={(e) =>
                  editProfileChange(e.target.name, e.target.value)
                }
                onFocus={(e) =>
                  editProfileChange(e.target.name, e.target.value)
                }
              />
              {typeof editProfileErrors.email !== 'undefined' &&
                editProfileErrors.email && (
                  <div className="clsinvalid">{t(editProfileErrors?.['email'])}</div>
                )}
            </div>
          </div>
          <div className="text-center mb-4 pb-2">
            <button type="submit" className="btn clsyellow-btn">
              {t(`Save`)}
            </button>
          </div>
        </form>
      ) : (
        ''
      )}
    </>
  );
};

export default OfficialProfile;
