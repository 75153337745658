/*
 *
 * InAppropriate Report constants
 *
 */

export const INAPPROPRIATE_REPORT_FORM_CHANGE =
  'src/InAppropriate/INAPPROPRIATE_REPORT_FORM_CHANGE';
export const INAPPROPRIATE_REPORT_ERRORS =
  'src/InAppropriate/INAPPROPRIATE_REPORT_ERRORS';
export const INAPPROPRIATE_REPORT_RESET =
  'src/InAppropriate/INAPPROPRIATE_REPORT_RESET';
export const INAPPROPRIATE_REPORT_LOADING =
  'src/InAppropriate/INAPPROPRIATE_REPORT_LOADING';
export const INAPPROPRIATE_REPORT_SUBMITTING =
  'src/InAppropriate/INAPPROPRIATE_REPORT_SUBMITTING';
export const RESET_APP = 'src/InAppropriate/RESET_APP';
