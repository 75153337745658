/**
 *
 * Complete Timer
 *
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';

const CompleteTimer = (props) => {
  const { timerStatus, setCoundDownStatus } = props;

  useEffect(() => {
    if (timerStatus === false) {
      setCoundDownStatus(false);
    }
  }, [timerStatus, setCoundDownStatus]);

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    countDown: state.application.countDown,
  };
};

export default connect(mapStateToProps, actions)(CompleteTimer);
