/**
 *
 * Survey Type Engagement
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import actions from '../../../actions';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import SurveyQuestions from '../../../containers/SurveyQuestions';
import EngagementHeader from './engagementHeader';
import TopicList from './topicList';
import EngagementFooter from './engagementFooter';
import ImgSlider from './imgSlider';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { useTranslation } from 'react-i18next';
import DiscussionEngagement from '../../../containers/DiscussionEngagement';
import PostContent from './postContent';

const Survey = (props) => {
  const { t } = useTranslation();
  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);
  const {
    user,
    authentication,
    engagement,
    setRegistrationModalOpen,
    setIsSignUpButtonClicked,
  } = props;
  const location = useLocation();
  const link = engagement.linkMeta ? JSON.parse(engagement.linkMeta).url : null;

  const fetcher = async (link) => {
    if (engagement.linkMeta) {
      return JSON.parse(engagement.linkMeta);
    } else {
      return {};
    }
  };

  const getSurveyClassName = (stage) => {
    if (stage === 'open') {
      return 'survey-header-open';
    } else if (stage === 'closed') {
      return 'survey-header-closed';
    } else if (stage === 'outcome') {
      return 'survey-header-outcome ';
    }
    return '';
  }

  const getSurveyBackgroundColor = (stage) => {
    if (stage === 'open') {
      return '#3C853D';
    } else if (stage === 'closed') {
      return '#566677';
    } else if (stage === 'outcome') {
      return '#FF99002E';
    }
    return '';
  }

  // https://civicbell.slack.com/archives/C04AGA1SF99/p1687556433397649?thread_ts=1687546657.852509&cid=C04AGA1SF99
  const registeredUnverified = user?.isVerified && user?.isRegistrationComplete && !(user?.isIdentityVerified);

  const SurveyStatusHeader = ({ engagement, authentication, registeredUnverified }) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: (engagement?.stage !== 'outcome') && '#FFFFFF',
          }}
        >
          <img
            width="15px"
            src={
              engagement?.stage !== 'outcome' ?
                require('../../../assets/img/survey-white.svg').default
                :
                require('../../../assets/img/survey-black.svg').default
            }
            alt="survey-icon"
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          />
          <p
            style={{
              fontWeight: 'bold',
              color: (engagement?.stage !== 'outcome') && '#FFFFFF',
            }}
          >
            {engagement?.stage === 'open' && t('This survey is open.')}
            {engagement?.stage === 'closed' && t('This survey is closed.')}
            {engagement?.stage === 'outcome' && t('This survey has concluded.')}
          </p>
          {
            engagement?.stage === 'closed' &&
            <p
              style={{
                paddingLeft: 4
              }}
            >
              {t('Expect a response statement by')}{' '}{new Date(engagement?.engagement_completion).toLocaleDateString('es-pa')}{'.'}
            </p>
          }
        </div>
        {
          engagement?.stage === 'open' &&
          <div
            style={{
              color: '#FFFFFF'
            }}
          >
            {
              (authentication.authenticated) ?
                [
                  (!registeredUnverified && (location.pathname === '/dashboard/civicfeed' || location.pathname === '/dashboard/civicfeed/')) ?
                    <div>
                      <img
                        width="15px"
                        src={
                          require('../../../assets/img/check-white.svg').default
                        }
                        alt="White check"
                        style={{
                          margin: 5
                        }}
                      />
                      {t('You are verified and eligible to participate.')}
                    </div>
                  :
                    <div>
                      {}
                    </div>
                ]
                :
                <p
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setIsSignUpButtonClicked(true);
                    setRegistrationModalOpen(true);
                  }}
                >
                  {t('Register now to participate!')}
                </p>
            }
          </div>
        }
        {
          engagement?.stage === 'outcome' &&
            <div
              style={{
                paddingLeft: '6%'
              }}
            >
              {engagement?.user?.first_name}{' '}{engagement?.user?.last_name}{' '}{t('released the following statement:')}
              {
                user.selected_langauge === engagement.originalLanguage || showOriginalLanguage ?
                  <p
                    dangerouslySetInnerHTML={convertFromJSONToHTML(
                      engagement.final_resolution,
                    )}
                    style={{
                      fontStyle: 'italic'
                    }}
                  ></p>
                  :
                  (JSON.parse(engagement.translation)?.[user.selected_language]?.final_resolution) ?
                    <p
                      dangerouslySetInnerHTML={{ __html: JSON.parse(engagement.translation)?.[user.selected_language]?.final_resolution }}
                      style={{
                        fontStyle: 'italic'
                      }}
                    >
                    </p>
                    :
                    <p
                      dangerouslySetInnerHTML={convertFromJSONToHTML(engagement.final_resolution)}
                      style={{
                        fontStyle: 'italic'
                      }}
                    ></p>
              }
            </div>
        }
      </>
    )
  }

  return (
    <>
      {
        <div
          className={getSurveyClassName(engagement.stage)}
          style={{
            backgroundColor: getSurveyBackgroundColor(engagement.stage)
          }}
        >
          <SurveyStatusHeader engagement={engagement} authentication={authentication} registeredUnverified={registeredUnverified} />
        </div>
      }
      <div
        className="clsdashboard-content-box clswhite-box mb-3"
        id={`engagement-list-id-${engagement.id}`}
        style={{
          borderTopLeftRadius: (!registeredUnverified && engagement?.stage !== 'unpublished') && 0,
          borderTopRightRadius: (!registeredUnverified && engagement?.stage !== 'unpublished') && 0
        }}
      >
        <EngagementHeader
          {...props}
          showOriginalLanguage={showOriginalLanguage}
          setShowOriginalLanguage={setShowOriginalLanguage}
        />
        <TopicList {...props} />
        <div className={`editor-content mt-2`}>
          <span>
            <PostContent {...props} showOriginalLanguage={showOriginalLanguage} />
          </span>
        </div>
        {engagement?.survey?.question?.length > 0 ? (
          <div className="clssurvey-preview-blk">
            <SurveyQuestions {...props} showOriginalLanguage={showOriginalLanguage} />
          </div>
        ) : (
          ''
        )}
        <ImgSlider {...props} />
        {link !== null ? (
          <div className="link-preview m-2">
            <LinkPreview
              url={link}
              fetcher={fetcher}
              className="preview-link-container"
            />
          </div>
        ) : null}
        <EngagementFooter {...props} />
        <DiscussionEngagement engagement={engagement} type={'Normal'} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actions)(Survey);
