/**
 *
 * Post Type Event
 *
 */

import React from 'react';
import {
  getOptionArray,
  getEventPublishedBy,
  getSubstring,
  getEventNextDate,
  isImageLink,
} from '../../../utils/helper';
import { recurringOptions } from '../../../containers/Events/constants';
import moment from 'moment-timezone';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import convertFromJSONToHTML from '../../Common/StateToHtml';

const Post = (props) => {
  const {
    user,
    event,
    joinEvent,
    updateEventSingle,
    month,
    toggleConfirmPopup,
    expired,
  } = props;
  const recurringArray = getOptionArray(recurringOptions);
  const { t } = useTranslation();

  const hashUrl = '#';
  return (
    <>
      <div
        className="clsevents-list-box clswhite-box mb-2"
        id={`event-${event.id}`}
      >
        <div className="media">
          <div className="clsevent-img-blk align-self-center link">
            <NavLink
              to={'/dashboard/event/?id=' + event.id}
              activeClassName="active"
              exact
            >
              <span className="clsrecurring-badge">
                {event.recurring_status && `Recurring`}{' '}
                {recurringArray[event.repetition_type] !== 'undefined' &&
                  recurringArray[event.repetition_type]}
              </span>
              <img
                src={
                  event.images !== undefined && event.images !== null
                    ? isImageLink(event.images[0])
                    : require('../../../assets/img/profile-post-img-small.png')
                        .default
                }
                className="align-self-center mr-1 img-fluid"
                width="359"
                alt={t('Events')}
              />
            </NavLink>
          </div>
          <div className="media-body align-self-center">
            <div className="clsevents-title-blk mb-2">
              <div className="clsevents-date">
                {event.recurring_status ? (
                  <>
                    <h5>
                      {moment(getEventNextDate(event)).format('DD MMMM')}{' '}
                    </h5>
                    <p>{moment(getEventNextDate(event)).format('dddd')}</p>
                  </>
                ) : (
                  <>
                    <h5>
                      {moment
                        .utc(event.start_date)
                        .tz(moment.tz.guess())
                        .format('DD MMMM')}{' '}
                    </h5>
                    <p>
                      {moment
                        .utc(event.start_date)
                        .tz(moment.tz.guess())
                        .format('dddd')}
                    </p>
                  </>
                )}
              </div>
              <div className="clsevents-title ml-3">
                <h5 className="mb-1 link">
                  <NavLink
                    to={'/dashboard/event/?id=' + event.id}
                    activeClassName="active"
                    exact
                  >
                    {event.name}
                  </NavLink>
                </h5>
                <p>{getEventPublishedBy(event.eventsCalendar.user)}</p>
              </div>
            </div>
            {event.description.includes('{"blocks"') ? (
              <p>
                {getSubstring(
                  convertFromJSONToHTML(event.description)
                    .__html.replace(/<(.|\n)*?>/g, '')
                    .replace(/&nbsp;/g, ''),
                  280,
                )}
              </p>
            ) : (
              <p>{getSubstring(event.description, 280)}</p>
            )}
            <div className="clsevents-join-event-blk mt-2">
              <span className="clsevents-join-people-total">
                <img
                  src={require('../../../assets/img/check-yellow.svg').default}
                  alt={t('People')}
                />
                {event.attendeesCount} {t(`people are coming`)} {!month}
              </span>
              {user.userId !== event.eventsCalendar.officialId && (
                <span>
                  {!expired ? (
                    event.joined ? (
                      <span
                        onClick={async () => {
                          await toggleConfirmPopup(event, month, 'leave');
                        }}
                        className="join-event-link"
                      >
                        {t(`Cancel Attendance`)}
                      </span>
                    ) : (
                      <button
                        onClick={async (e) => {
                          await joinEvent(event, month);
                        }}
                        className="btn clsanchor-btn clsyellow-btn"
                        type="button"
                      >
                        {t(`Join Event`)}
                      </button>
                    )
                  ) : (
                    ''
                  )}
                </span>
              )}
              {user.userId === event.eventsCalendar.officialId && (
                <span>
                  <span
                    onClick={async () => {
                      await toggleConfirmPopup(event, month, 'delete');
                    }}
                    className={'mr-3  join-event-link'}
                  >
                    {t(`Delete Event`)}
                  </span>
                  <a
                    href={hashUrl}
                    onClick={() => {
                      updateEventSingle(event);
                    }}
                    data-toggle="modal"
                    data-target="#event-form"
                  >
                    <span>
                      <img
                        src={require('../../../assets/img/edit.svg').default}
                        alt={t(`Edit Event`)}
                      />
                    </span>
                    <span>{t(`Edit Event`)}</span>
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
