/*
 *
 * Complete Engagement Form
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CompleteEngagementForm from '../../components/Manager/CompleteEngagement';
import CivicModal from '../../components/Common/CivicModal';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

const CompleteEngagement = (props) => {
  const {
    engagement,
    completeEngagementData,
    completeEngagementErrors,
    completeEngagementLoading,
    completeEngagementSubmitting,
    completeEngagementChange,
    questionSelectionChange,
    submitCompleteEngagement,
    setCompleteEngagementData,
    completeCommentEditChange,
    setCompleteComponent,
  } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      setCompleteEngagementData(engagement);
      setLoaded(true);
    }
  }, [loaded, setCompleteEngagementData, engagement]);

  return (
    <>
      {loaded === true &&
      typeof completeEngagementData[engagement.id] !== 'undefined' ? (
        <div key={`complete-engagement-div-${engagement.id}`}>
          <CivicModal
            id={`complete-engagement-${engagement.id}`}
            title={completeEngagementData[engagement.id].title}
            dismissal={completeEngagementData[engagement.id].dismissal}
            onUserInitiatedClose={setCompleteComponent}
          >
            <CompleteEngagementForm
              completeEngagementData={completeEngagementData[engagement.id]}
              completeEngagementErrors={completeEngagementErrors}
              completeEngagementLoading={completeEngagementLoading}
              completeEngagementSubmitting={completeEngagementSubmitting}
              completeEngagementChange={completeEngagementChange}
              submitCompleteEngagement={submitCompleteEngagement}
              completeCommentEditChange={completeCommentEditChange}
              questionSelectionChange={questionSelectionChange}
            />
          </CivicModal>
        </div>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    completeEngagementData: state.completeEngagement.completeEngagementData,
    completeEngagementErrors: state.completeEngagement.completeEngagementErrors,
    completeEngagementLoading:
      state.completeEngagement.completeEngagementLoading,
    completeEngagementSubmitting:
      state.completeEngagement.completeEngagementSubmitting,
  };
};

CompleteEngagement.defaultProps = {
  type: 'Modal',
};

export default connect(mapStateToProps, actions)(CompleteEngagement);
