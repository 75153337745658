/*
 *
 * Register Form First Step
 *
 */

import React, { useEffect, useState } from 'react';
import SelectOption from '../../Common/SelectOption';
import { signUpTypeOptions } from '../../../containers/Register/constants';
import Tooltip from '../../Common/Tooltip';
import { useTranslation } from 'react-i18next';

const FirstStep = (props) => {
  const { registerFormData, registerChange, registerFormErrors, countDown } =
    props;
  const { t } = useTranslation();
  const [signUpTypeUpdatedOptions, setSignUpTypeUpdatedOptions] =
    useState(signUpTypeOptions);

  useEffect(() => {
    if (countDown === true) {
      const optionArray = signUpTypeOptions.map((option) => {
        if (option.value === 'individual') {
          option['disabled'] = true;
          return option;
        } else {
          return option;
        }
      });
      setSignUpTypeUpdatedOptions(optionArray);
    } else {
      const optionArray = signUpTypeOptions.map((option) => {
        option['disabled'] = false;
        return option;
      });
      setSignUpTypeUpdatedOptions(optionArray);
    }
  }, [countDown]);

  return (
    <div className="First-Step">
      <div className="form-group">
        <label>{t(`Email`)}</label>
        <input
          type="text"
          className="form-control"
          placeholder={t(`Email`)}
          name={'email'}
          value={registerFormData.email}
          onChange={(e) => registerChange(e.target.name, e.target.value)}
          autoComplete="new-password"
          id="regevent_email"
        />
        {typeof registerFormErrors !== 'undefined' &&
          registerFormErrors.email && (
            <div className="clsinvalid">{t(registerFormErrors?.['email'])}</div>
          )}
      </div>
      <div className="form-group">
        <label>{t(`Password`)}</label>
        <input
          type="password"
          className="form-control"
          placeholder={t(`Password`)}
          name={'password'}
          value={registerFormData.password}
          onChange={(e) => registerChange(e.target.name, e.target.value)}
          autoComplete="new-password"
          id="regevent_password"
        />
        <Tooltip
          targetId={'passwordTooltip'}
          styleClass={'password-tooltip'}
          message={t(
            'Your password should contain at least six characters with at least one lowercase letter, one uppercase letter, and one number.',
          )}
        />
        {typeof registerFormErrors !== 'undefined' &&
          registerFormErrors.password && (
            <div className="clsinvalid">{t(registerFormErrors?.['password'])}</div>
          )}
      </div>
      <div className="form-group" id="regevent_usertype">
        <label>{t(`I'm signing up as:`)}</label>
        <SelectOption
          error={registerFormErrors['signUpType']}
          name={'signUpType'}
          placeholder={t('Choose one')}
          value={registerFormData.signUpType}
          options={signUpTypeUpdatedOptions}
          handleSelectChange={(value) => {
            registerChange('signUpType', value);
          }}
        />
      </div>
      {typeof registerFormData.signUpType !== 'undefined' &&
      typeof registerFormData.signUpType.value !== 'undefined' &&
      (registerFormData.signUpType.value === 'elected_official' ||
        registerFormData.signUpType.value === 'public_official') ? (
        <p className="clsagree-text mb-4">
          {t(`By registering, you agree to our`)}{' '}
          <a
            href={`https://about.civicbell.com/policies/public-official-terms-of-services`}
            target={'_blank'}
            rel="noopener noreferrer"
            id="regevent_legal_tos"
          >
            {t(`Terms of Services`)}
          </a>
          , {t(`our`)}{' '}
          <a
            href={`https://about.civicbell.com/policies/rules-of-engagements-for-government-accounts`}
            target={'_blank'}
            rel="noopener noreferrer"
            id="regevent_legal_roe"
          >
            {t(`Rules of Engagements`)}
          </a>
          , {t(`and`)}{' '}
          <a
            href={`https://about.civicbell.com/policies/privacy-policy`}
            target={'_blank'}
            rel="noopener noreferrer"
            id="regevent_legal_privacy"
          >
            {t(`Privacy Policy`)}
          </a>
          .
        </p>
      ) : (
        <p className="clsagree-text mb-4">
          {t(`By registering, you agree to our`)}{' '}
          <a
            href={`https://about.civicbell.com/policies/resident-terms-of-services`}
            target={'_blank'}
            rel="noopener noreferrer"
            id="regevent_legal_tos"
          >
            {t(`Resident Terms of Services`)}
          </a>
          , {t(`our`)}{' '}
          <a
            href={`https://about.civicbell.com/policies/rules-of-engagements-for-residents`}
            target={'_blank'}
            rel="noopener noreferrer"
            id="regevent_legal_roe"
          >
            {t(`Rules of Engagements`)}
          </a>
          , {t(`and`)}{' '}
          <a
            href={`https://about.civicbell.com/policies/privacy-policy`}
            target={'_blank'}
            rel="noopener noreferrer"
            id="regevent_legal_privacy"
          >
            {t(`Privacy Policy`)}
          </a>
          .
        </p>
      )}
    </div>
  );
};

export default FirstStep;
