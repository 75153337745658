/*
 *
 * PushNotification reducer
 *
 */

import {
  FETCH_READ_NOTIFICATION,
  FETCH_UNREAD_NOTIFICATION,
  NOTIFICATION_CHANGE,
  NOTIFICATION_LOADING,
  RESET_APP,
} from './constants';

const initialState = {
  notificationReadList: [],
  notificationUnReadList: [],
  isNotificationLoading: false,
  notificationCount: '',
};

const defaultState = JSON.stringify(initialState);

const pushnotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case NOTIFICATION_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_READ_NOTIFICATION:
      return {
        ...state,
        notificationReadList: [
          ...state.notificationReadList,
          ...action.payload,
        ],
      };
    case FETCH_UNREAD_NOTIFICATION:
      return {
        ...state,
        notificationUnReadList: [
          ...state.notificationUnReadList,
          ...action.payload,
        ],
      };
    case NOTIFICATION_LOADING:
      return {
        ...state,
        isNotificationLoading: action.payload,
      };
    default:
      return state;
  }
};

export default pushnotificationReducer;
