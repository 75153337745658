/**
 *
 * Engagement Dates
 *
 */

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DateInput from '../../Common/DateInput';
import { addDays } from 'date-fns';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import DateSelection from '../../Common/DateSelection';

const ScheduleDate = (props) => {
  const { engagementFormData, engagementErrors, engagementChange, user } = props;
  const { t } = useTranslation();
  const ScheduleTimeFrame = React.forwardRef((props, ref) => (
    <DateInput innerRef={ref} {...props} />
  ));

  //const today = moment.utc(new Date()).tz(moment.tz.guess()).format('YYYY-MM-DD');
  const currentDateTime = moment
    .utc(new Date())
    .tz(moment.tz.guess())
    .format('YYYY-MM-DD HH:mm:ss');
  const todayDate = new Date(currentDateTime);

  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);
    const currentDate = new Date(currentDateTime);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div
        style={{
          display: engagementFormData.category === 'post' ? 'block' : 'none',
        }}
      >
        <div className="form-group">
          <label htmlFor="postScheduleTime">{t(`Schedule For`)}</label>
          <DateSelection
            customInput={<ScheduleTimeFrame />}
            onChangeRaw={(e) => e.preventDefault()}
            placeholderText={t(`Select`)}
            selected={engagementFormData.postScheduleTime}
            onChange={(date) => engagementChange('postScheduleTime', date)}
            showTimeSelect
            minDate={todayDate}
            maxDate={addDays(new Date(), 90)}
            timeIntervals={15}
            dateFormat="MM-dd-yyyy h:mm aa"
            yearDropdownItemNumber={121}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            filterTime={filterPassedTime}
            locale={user?.selected_language}
            timeCaption={t('Time')}
          />

          {typeof engagementErrors !== 'undefined' &&
            engagementErrors.postScheduleTime && (
              <div className="clsinvalid">
                {t(engagementErrors?.['postScheduleTime'])}
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default ScheduleDate;
