/*
 *
 * Authentication actions
 *
 */

import {
  SET_AUTH,
  CLEAR_AUTH,
  RESET_APP,
  INITIATIVE_URL,
  SET_LOAD_MESSAGE_STATUS, SET_SILENT_USER,
} from './constants';
import { clearAccount } from '../Dashboard/actions';
import { resetAnalyticsState } from '../../containers/Analytics/actions';
import { resetCivicFeedState } from '../../containers/CivicFeed/actions';
import { resetCloseEngagementState } from '../../containers/CloseEngagement/actions';
import { resetCompleteEngagementState } from '../../containers/CompleteEngagement/actions';
import { resetDashboardState } from '../../containers/Dashboard/actions';
import { resetDiscussionEngagementState } from '../../containers/DiscussionEngagement/actions';
import { resetEngagementState } from '../../containers/Engagement/actions';
import { resetLoginState } from '../../containers/Login/actions';
import { resetEventState } from '../../containers/Events/actions';
import { resetMapState } from '../../containers/CivicMap/actions';
import { resetForgetPasswordState } from '../../containers/ForgotPassword/actions';
import { resetInAppropriateState } from '../../containers/InAppropriateReport/actions';
import { resetInviteOthersState } from '../../containers/InviteOthers/actions';
import { resetLocalNewsState } from '../../containers/LocalNews/actions';
import { resetNotificationState } from '../../containers/Notification/actions';
import { resetPetitionState } from '../../containers/Petitions/actions';
import { resetPushNotificationState } from '../../containers/PushNotification/actions';
import { resetReactionState } from '../../containers/Reactions/actions';
import { resetRegisterState } from '../../containers/Register/actions';
import { resetResetPasswordState } from '../../containers/ResetPassword/actions';
import { resetSearchState } from '../../containers/Search/actions';
import { resetSurveyState } from '../../containers/SurveyQuestions/actions';
import { resetVerifyEmailState } from '../../containers/VerifyEmail/actions';
import { resetCommunityIssues } from '../../containers/CommunityIssues/actions';

export const setAuth = () => {
  return {
    type: SET_AUTH,
  };
};

export const setSilentUser = () => {
  return {
    type: SET_SILENT_USER,
  };
};

export const initiativeUrl = (url) => {
  return (dispatch, getState) => {
    dispatch({ type: INITIATIVE_URL, payload: url });
  };
};

export const resetAuthState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const resetApp = () => {
  return async (dispatch, getState) => {
    dispatch(resetAnalyticsState());
    dispatch(resetAuthState());
    dispatch(resetCivicFeedState());
    dispatch(resetCloseEngagementState());
    dispatch(resetCompleteEngagementState());
    dispatch(resetDashboardState());
    dispatch(resetDiscussionEngagementState());
    dispatch(resetEngagementState());
    dispatch(resetLoginState());
    dispatch(resetEventState());
    dispatch(resetMapState());
    dispatch(resetForgetPasswordState());
    dispatch(resetInAppropriateState());
    dispatch(resetInviteOthersState());
    dispatch(resetLocalNewsState());
    dispatch(resetNotificationState());
    dispatch(resetPetitionState());
    dispatch(resetPushNotificationState());
    dispatch(resetReactionState());
    dispatch(resetRegisterState());
    dispatch(resetResetPasswordState());
    dispatch(resetSearchState());
    dispatch(resetSurveyState());
    dispatch(resetVerifyEmailState());
    dispatch(resetCommunityIssues());
    return;
  };
};

export const clearAuth = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CLEAR_AUTH });
    return dispatch(clearAccount());
  };
};

export const setLoadingMsgStatus = (flag = false) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOAD_MESSAGE_STATUS, payload: flag });
  };
};
