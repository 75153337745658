/*
 *
 * Search Key Constants
 *
 */

export const SEARCH_KEY_CHANGE = 'src/Search/SEARCH_KEY_CHANGE';
export const RESET_SEARCH_KEY_RESET = 'src/Search/RESET_SEARCH_KEY_RESET';
export const SET_SEARCH_KEY_ERROR = 'src/Search/SET_SEARCH_KEY_ERROR';
export const SET_SEARCH_SUBMITTING = 'src/Search/SET_SEARCH_SUBMITTING';
export const SET_SEARCH_RESULT_RESET = 'src/Search/SET_SEARCH_RESULT_RESET';
export const RESET_APP = 'src/Search/RESET_APP';
