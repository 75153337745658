/*
 *
 * ForgotPassword actions
 *
 */

import axios from 'axios';
import { push } from 'connected-react-router';
import { BASE_API_URL } from '../../constants';
import { addNotification } from '../Notification/actions';

import {
  RESET_PASSWORD_CHANGE,
  RESET_PASSWORD_FORM_RESET,
  SET_RESET_PASSWORD_FORM_ERRORS,
  SET_RESET_PASSWORD_SUBMITTING,
  SET_LINK_VERIFIED,
  SET_ERROR_NOTIFICATION,
  RESET_APP,
} from './constants';
import { allFieldsValidation } from '../../utils/validation';

export const resetResetPasswordState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const verifyLink = () => {
  return async (dispatch, getState) => {
    if (getState().resetPassword.isLinkVerified === true) {
      return;
    }

    let pathName = getState().router.location.pathname;
    pathName = pathName.replace('/reset/', '');

    if (pathName !== '' && getState().resetPassword.isLinkVerified === false) {
      await axios
        .get(BASE_API_URL + '/reset/' + pathName, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({ type: SET_ERROR_NOTIFICATION, payload: false });
            return dispatch({
              type: SET_LINK_VERIFIED,
              payload: { isLinkVerified: true, isValidLink: true },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: SET_LINK_VERIFIED,
            payload: { isLinkVerified: true, isValidLink: false },
          });
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'resetPassword',
            }),
          );
        });
    } else {
      const title = `We couldn't process your request. This reset link is expired.`;
      dispatch(
        addNotification({
          type: 'error',
          message: title,
          module: 'resetPassword',
        }),
      );
      dispatch({
        type: SET_LINK_VERIFIED,
        payload: { isLinkVerified: true, isValidLink: false },
      });
    }
    return {
      type: SET_LINK_VERIFIED,
      payload: { isLinkVerified: true, isValidLink: false },
    };
  };
};

export const resetPasswordChange = (name, value) => {
  let formData = {};
  formData[name] = value;
  return {
    type: RESET_PASSWORD_CHANGE,
    payload: formData,
  };
};

export const resetPassword = () => {
  return async (dispatch, getState) => {
    let pathName = getState().router.location.pathname;
    pathName = pathName.replace('/reset/', '');
    if (pathName !== '') {
      const rules = {
        password: 'required|min:6',
        confirmPassword: 'required|same:password',
      };

      const password = getState().resetPassword.resetFormData;

      const { isValid, errors } = allFieldsValidation(password, rules, {
        'required.password': 'Password is required.',
        'min.password': 'Password must be at least 6 characters.',
        'required.confirmPassword': 'Re-type the password',
        'same.confirmPassword': 'Password must be same',
      });

      if (!isValid) {
        return dispatch({
          type: SET_RESET_PASSWORD_FORM_ERRORS,
          payload: errors,
        });
      }
      dispatch({ type: SET_RESET_PASSWORD_FORM_ERRORS, payload: {} });
      dispatch({ type: SET_RESET_PASSWORD_SUBMITTING, payload: true });

      const passwordData = { new_password: password.password };
      await axios
        .post(BASE_API_URL + '/reset/' + pathName, passwordData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({ type: RESET_PASSWORD_FORM_RESET });
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'login',
                displayFor: 10000,
              }),
            );
            dispatch(push('/dashboard/civicfeed/'))
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'resetPassword',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_RESET_PASSWORD_SUBMITTING, payload: false });
        });
    } else {
      const title = `We couldn't process your request. Please try again!`;
      dispatch(
        addNotification({
          type: 'error',
          message: title,
          module: 'resetPassword',
        }),
      );
      dispatch({
        type: SET_LINK_VERIFIED,
        payload: { isLinkVerified: true, isValidLink: false },
      });
      dispatch({ type: RESET_PASSWORD_FORM_RESET });
    }
  };
};
