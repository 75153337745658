/**
 *
 * Tab Title
 *
 */

import React from 'react';

const TabTitle = (props) => {
  const { tabTitle, linkId, isActive, onClick } = props;

  return (
    <>
      <li className="nav-item" role="presentation">
        <a
          className={`nav-link ${isActive === true ? ' active' : ''}`}
          id={`${linkId}-tab`}
          data-toggle="tab"
          href={`#${linkId}`}
          role="tab"
          aria-controls={linkId}
          aria-selected="false"
          onClick={onClick}
        >
          {tabTitle}
        </a>
      </li>
    </>
  );
};

TabTitle.defaultProps = {
  tabTitle: '',
  linkId: '',
  isActive: false,
};

export default TabTitle;
