/**
 *
 * Petition Search Box
 *
 */

import { useCallback, useEffect } from 'react';
import SuggestionBox from './suggestionBox';
import debounce from 'lodash.debounce';

const suggestData = debounce((type, func) => {
  func(type);
}, 500);

const SearchBox = (props) => {
  const {
    type,
    searchBox,
    suggestionBox,
    searchBoxPetitionChange,
    getSearchPetitionData,
    suggestionBoxPetitionChange,
  } = props;

  const contentClass =
    suggestionBox[type].display === true ? 'clscontent-show' : '';

  const hashUrl = '#';

  const onSearchChange = useCallback(() => {
    if (searchBox[type].key.length > 0) {
      suggestionBoxPetitionChange(type, 'display', true);
      suggestData(type, getSearchPetitionData);
    }
  }, [type, getSearchPetitionData, searchBox, suggestionBoxPetitionChange]);

  useEffect(() => {
    if (searchBox[type].key.length === 0) {
      suggestionBoxPetitionChange(type, 'display', false);
    }
  }, [searchBox, type, suggestionBoxPetitionChange]);

  const openSuggestionBox = () => {
    if (searchBox[type].key.length > 0) {
      suggestionBoxPetitionChange(type, 'display', true);
    }
  };

  return typeof type !== 'undefined' ? (
    <div
      id={`search-box-${type}`}
      className={`clsfilter-search-blk mt-3 clssearch-field-show ${contentClass}`}
      style={{ display: searchBox[type].display === true ? 'block' : 'none' }}
    >
      <div className="form-group">
        <label>{`Search ${type}`}</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <img
                src={require('../../../assets/img/search-1.svg').default}
                alt="Search"
              />
            </div>
          </div>
          <input
            type="text"
            id={`search-${type}`}
            name={`search-${type}`}
            className="form-control"
            placeholder={`Search ${type}`}
            value={searchBox[type].key}
            autoComplete={`off`}
            onChange={(e) => {
              searchBoxPetitionChange(type, 'key', e.target.value);
              onSearchChange(type);
            }}
            onFocus={openSuggestionBox}
          />
          <figure
            className={'clssearchclose-icon'}
            style={{
              display: suggestionBox[type].display === true ? 'block' : 'none',
            }}
          >
            <a
              href={hashUrl}
              onClick={() => {
                suggestionBoxPetitionChange(type, 'display', false);
              }}
            >
              <img
                src={
                  require('../../../assets/img/search-close-icon.svg').default
                }
                alt="Close"
              />
            </a>
          </figure>
        </div>
        <SuggestionBox {...props} />
      </div>
    </div>
  ) : (
    ''
  );
};

export default SearchBox;
