/**
 *
 * Events Filter
 *
 */

import CivicModal from '../../Common/CivicModal';
import EventFilter from './eventFilter';
import ExploreCalendar from './exploreCalendar';
import NewEvent from './newEvent';
import { PlusIcon } from '../../Common/CivicIcons';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const {
    user,
    eventChangeByOption,
    eventFormData,
    resetEventForm,
    filterDismissal,
    resetEventFilter,
    submitEventFilter,
    noFilter,
    filterSelected,
  } = props;
  const hashUrl = '#';
  const { t } = useTranslation();

  const CalendarModalFooter = (
    <>
      <a href={hashUrl} data-dismiss="modal">
        {t(`Cancel`)}
      </a>
      <button
        type="button"
        className="btn clsyellow-btn"
        onClick={() => {
          submitEventFilter();
        }}
      >
        {t(`OK`)}
      </button>
    </>
  );

  const FilterFooter = (
    <>
      <a
        href={hashUrl}
        className={`clear-filter mr-5`}
        onClick={() => {
          resetEventFilter();
        }}
      >
        {t(`Clear All`)}
      </a>
      <button
        type="button"
        className="btn clsyellow-btn"
        disabled={filterSelected.officials.length ? false : true}
        onClick={() => {
          submitEventFilter();
        }}
      >
        {t(`Submit`)}
      </button>
    </>
  );

  return (
    <div className="clsfeed-filter-blk">
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`events-calendar-modal`}
          title={t(`Explore Calendars`)}
          dismissal={filterDismissal}
          footer={CalendarModalFooter}
          modalClass={`clsevents-calendar-modal`}
          footerClass={`text-center mt-4`}
        >
          <ExploreCalendar {...props} />
        </CivicModal>
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`filter-modal`}
          title={t(`Filter`)}
          dismissal={filterDismissal}
          footer={FilterFooter}
          modalClass={`clsevents-filter`}
          footerClass={`text-center mt-4`}
        >
          <EventFilter {...props} />
        </CivicModal>
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`event-form`}
          title={eventFormData.id ? t(`Edit Event`) : t(`New Event`)}
          dismissal={filterDismissal}
          footerClass={`text-center mt-4`}
        >
          <NewEvent {...props} />
        </CivicModal>
      </div>
      <div className="clsevents-filter-right">
        <ul>
          {!noFilter ? (
            <li>
              <div className="clsfeed-filter-blk clsfeed-filter">
                <a
                  href={hashUrl}
                  onClick={() => {
                    eventChangeByOption('filterDismissal', false);
                  }}
                  data-toggle="modal"
                  data-target="#events-calendar-modal"
                >
                  <img
                    src={
                      require('../../../assets/img/icon-explore.svg').default
                    }
                    alt={t('Explore Calendars')}
                  />
                  <span>{t(`Explore Calendars`)}</span>
                </a>
              </div>
            </li>
          ) : (
            ''
          )}
          {!noFilter ? (
            <li>
              <div className="clsfeed-filter-blk clsfeed-filter">
                <a
                  href={hashUrl}
                  onClick={() => {
                    eventChangeByOption('filterDismissal', false);
                  }}
                  data-toggle="modal"
                  data-target="#filter-modal"
                >
                  <img
                    src={require('../../../assets/img/filter.svg').default}
                    alt={t('Filter')}
                  />
                  <span>{t(`Filter`)}</span>
                </a>
              </div>
            </li>
          ) : (
            ''
          )}
          {user.roleName === 'PUBLICOFFICIAL' ? (
            <li>
              <div className="clsfeed-filter-blk clsfeed-filter">
                <a
                  href={hashUrl}
                  className="btn btn-block clsanchor-btn clsyellow-btn"
                  onClick={() => {
                    eventChangeByOption('filterDismissal', false);
                    if (eventFormData.id) resetEventForm();
                  }}
                  data-toggle="modal"
                  data-target="#event-form"
                >
                  <span className="mr-2">{PlusIcon()}</span>
                  {t(`New Event`)}
                </a>
              </div>
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </div>
  );
};

export default Filter;
