/*
 *
 * CivicMap
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Map from '../../components/Manager/CivicMap';
import Filter from '../../components/Manager/CivicMap/filter';
import Notification from '../../containers/Notification';

const CivicMap = (props) => {
  const { user, civicMaps, geoJsonDataArray, getCivicMap, resetMapState } =
    props;
  const [loading, setLoading] = useState(true);
  const [mapComponent, setMapComponent] = useState('');

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setMapComponent(<Component {...props} />);
        return resolve(true);
      });
    },
    [setMapComponent, props],
  );

  useEffect(() => {
    if (loading) {
      resetMapState();
      setLoading(false);
      getCivicMap().then(() => {
        renderComponent(Map).then();
      });
    }
  }, [loading, getCivicMap, renderComponent, resetMapState]);

  useEffect(() => {
    // if(geoJsonDataArray.length>0 || civicMaps.length>0)
    renderComponent(Map);
  }, [user, renderComponent, geoJsonDataArray, civicMaps]);

  return (
    <>
      <Notification module={'map'} />
      {loading === false ? <Filter {...props} /> : ''}
      {mapComponent}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    filterDismissal: state.map.filterDismissal,
    districtBoxDismissal: state.map.districtBoxDismissal,
    filter: state.map.filter,
    filterSelected: state.map.filterSelected,
    isCivicMapLoading: state.map.isCivicMapLoading,
    reloadMap: state.map.reloadMap,
    civicMaps: state.map.civicMaps,
    geoJsonData: state.map.geoJsonData,
    geoJsonDataArray: state.map.geoJsonDataArray,
    position: state.map.position,
    mapCenter: state.map.mapCenter,
    mapSearch: state.map.mapSearch,
    zoom: state.map.zoom,
    currentLayer: state.map.currentLayer,
    currentLoadedMap: state.map.currentLoadedMap,
    noData: state.map.noData,
  };
};

export default connect(mapStateToProps, actions)(CivicMap);
