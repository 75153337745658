import React, { useEffect } from 'react';
// import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import Marker from 'react-leaflet-enhanced-marker';
import MapFilter from './mapFilter';
import OfficialList from '../../../containers/CivicMap/district-officials-list';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import CivicModal from '../../Common/CivicModal';

const GeoJsonData = (props) => {
  const { geoJsonData, setCurrentLayer, setCivicMapFilter, mapChangeByOption, civicMapChange } =
    props;
  const [geoJson, setGeoJson] = React.useState();
  // const map = useMap();

  useEffect(() => {
    setGeoJson(geoJsonData);
  }, [geoJsonData]);

  const mapEvents = useMapEvents({
    zoomend: () => {
      civicMapChange({ zoom: mapEvents.getZoom() });
    },
    // dblclick(ev) {
    //   const { lat, lng } = ev.latlng;
    //   civicMapChange({"zoom":mapEvents.getZoom(), "position":[lat, lng]});
    // },
    dragend: () => {
      const { lat, lng } = mapEvents.getCenter();
      civicMapChange({ mapCenter: [lat, lng] });
    },
  });

  const changeFeatureColor = (event) => {
    setCivicMapFilter(event.latlng);
    setCurrentLayer(event.target.feature.properties);
    mapChangeByOption('districtBoxDismissal', false);
    mapChangeByOption('filterDismissal', false);
    event.target.setStyle({
      fillColor: '#FFAA32',
      fillOpacity: 0.2,
    });
  };

  const resetFeatureColor = (event) => {
    event.target.setStyle({
      fillColor: '#FFAA32',
      fillOpacity: 0,
    });
  };

  const polygonStyle = function (feature) {
    return { color: '#FFAA32', weight: 1.5 };
  };

  const onEachFeature = (feature, layer) => {
    const featureProps = feature.properties;

    layer.bindPopup(featureProps.name, null);
    layer.bindTooltip(featureProps.name).openTooltip();
    layer.options.fillOpacity = 0;
    layer.options.fillColor = '#FFAA32';

    layer.on({
      click: changeFeatureColor,
      popupclose: resetFeatureColor,
    });
  };

  if (geoJson) {
    // These next 3 lines purely for debuggins:
    // const geojsonObject = L.geoJSON(geoJson);
    // map.fitBounds(geojsonObject.getBounds());
    //map.on('click', mapClickEventHandler);
    // end debugging

    return (
      <GeoJSON
        data={geoJson}
        style={polygonStyle}
        onEachFeature={onEachFeature}
      />
    );
  } else {
    return null;
  }
};

const UserMarker = (props) => {
  const { user, position, zoom } = props;
  const [localPosition, setLocalPosition] = React.useState(position);
  const map = useMap();

  const iconMarker = (
    <>
      <div className={`leaflet-div-icon user-icon`}>
        <img
          src={
            user.profile_picture !== null
              ? user.profile_picture
              : require('../../../assets/img/profile-image.jpeg').default
          }
          alt={user.first_name}
        />
      </div>
    </>
  );
  useEffect(() => {
    if (localPosition !== position) {
      map.flyTo(position, zoom);
      setLocalPosition(position);
    }
  }, [position, zoom, map, localPosition]);

  return position ? (
    <Marker
      position={position}
      icon={iconMarker}
      className="leaflet-div-icon-marker"
    ></Marker>
  ) : null;
};

const Map = (props) => {
  const {
    geoJsonDataArray,
    position,
    mapCenter,
    zoom,
    isCivicMapLoading,
    currentLayer,
    filterDismissal,
    districtBoxDismissal,
    noData,
    civicMapChange,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (noData === true) {
      $('#nodata-alert-modal').modal({
        backdrop: 'static',
        keyboard: false,
      });
    }
  }, [noData]);

  return (
    <>
      <div
        className={
          filterDismissal
            ? 'clsmap-container'
            : 'clsmap-container filter-active'
        }
      >
        <div id="map" className="clswhite-box clsmap">
          <MapContainer
            scrollWheelZoom={true}
            id="mapId"
            zoom={zoom}
            center={mapCenter}
            className={'clswhite-box clsmap'}
            doubleClickZoom={true}
          >
            {isCivicMapLoading === true ? (
              <Marker
                position={position}
                icon={
                  <img
                    width="65"
                    src={require('../../../assets/img/loader.GIF').default}
                    alt={t('Loading')}
                  />
                }
              ></Marker>
            ) : (
              ''
            )}
            <UserMarker {...props} />
            {geoJsonDataArray.length > 0
              ? geoJsonDataArray.map((geoJson, index) => (
                  <GeoJsonData {...props} key={index} geoJsonData={geoJson} />
                ))
              : ''}
            <TileLayer
              attribution='CivicBell GIS | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            />
          </MapContainer>
          {currentLayer !== null && districtBoxDismissal === false ? (
            <OfficialList {...props} ocdid={currentLayer['ocd-id']} />
          ) : (
            ''
          )}
        </div>
        {filterDismissal === false ? <MapFilter {...props} /> : ''}
      </div>
      <CivicModal
        id={`nodata-alert-modal`}
        hideClose={true}
        dismissal={false}
        modalClass={`clsnew-petition-modal`}
        footerClass={`text-center mt-4`}
      >
        <p className={`email-confirmation-txt`}>
          {t(`We couldn’t find any districts associated with this address.`)}{' '}
          <a
            href="https://civicl.ink/nodistrictfound"
            target={'_blank'}
            rel="noopener noreferrer"
          >
            {t(`This could have a variety of reasons`)}.
          </a>
        </p>
        <div className="text-center mt-4">
          <button
            type="button"
            className="btn clsyellow-btn ml-4"
            data-dismiss="modal"
            onClick={(e) => {
              civicMapChange({ noData: false });
            }}
          >
            {t(`Ok`)}
          </button>
        </div>
      </CivicModal>
    </>
  );
};

export default Map;
