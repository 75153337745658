/**
 *
 * Complete Engagement Form
 *
 */

import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import { postSelections } from '../../../containers/CompleteEngagement/constants';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';

const CompleteEngagementForm = (props) => {
  const {
    completeEngagementData,
    completeEngagementErrors,
    completeEngagementLoading,
    completeEngagementSubmitting,
    completeCommentEditChange,
    completeEngagementChange,
    questionSelectionChange,
    submitCompleteEngagement,
  } = props;
  const { t } = useTranslation();

  const [comment, setComment] = useState(EditorState.createEmpty());

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      if (typeof completeEngagementData !== 'undefined') {
        if (typeof completeEngagementData.question !== 'undefined') {
          if (completeEngagementData.comment !== '') {
            const storeComment = async () => {
              const convertedState = convertFromRaw(
                JSON.parse(completeEngagementData.comment),
              );
              setComment(EditorState.createWithContent(convertedState));
            };
            storeComment();
          }
          setLoaded(true);
        }
      }
    }
  }, [loaded, completeEngagementData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    submitCompleteEngagement(completeEngagementData.id);
  };

  useEffect(() => {
    completeCommentEditChange(
      completeEngagementData.id,
      convertToRaw(comment.getCurrentContent()),
    );
  }, [completeCommentEditChange, completeEngagementData.id, comment]);

  return (
    <>
      {loaded === true && typeof completeEngagementData !== 'undefined' ? (
        <div className="clsengagement-form mt-2">
          {completeEngagementLoading && <LoadingIndicator />}
          <Notification module={'completeEngagement'} />
          <form onSubmit={handleSubmit}>
            <div className="clssurvey-preview-blk">
              <div className="clscomplete-engagement-modal-form-in mt-2 bg-white">
                <div className="form-group">
                  <label htmlFor="comment">{t('Your Reaction')}</label>
                  <Editor
                    editorState={comment}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    wrapperStyle={{
                      border: '2px solid #eee',
                      borderRadius: '10px',
                      marginBottom: '20px',
                    }}
                    editorStyle={{ padding: '10px' }}
                    onEditorStateChange={(editorState) => {
                      setComment(editorState);
                    }}
                    toolbar={{
                      options: ['inline', 'emoji', 'history', 'link', 'list'],
                      inline: { options: ['bold', 'italic', 'underline'] },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                      link: {
                        defaultTargetOption: '_blank',
                      },
                    }}
                  />
                  {typeof completeEngagementErrors !== 'undefined' &&
                    completeEngagementErrors.comment && (
                      <div className="clsinvalid">
                        {t(completeEngagementErrors?.['comment'])}
                      </div>
                    )}
                </div>
                {completeEngagementData.type === 'survey' ? (
                  <div className="form-group">
                    <label>{t(`What do you want to post?`)}</label>
                    <div className="clscomplete-engagement-radio-blk mb-3">
                      <ul>
                        {postSelections.map((item, index) => (
                          <li key={`postOptions-${index}`}>
                            <div className="checkbox">
                              <input
                                type="radio"
                                id={item.id}
                                name="postSelection"
                                value={item.value}
                                checked={
                                  completeEngagementData.postSelection ===
                                  item.value
                                }
                                onChange={(e) => {
                                  completeEngagementChange(
                                    completeEngagementData.id,
                                    e.target.name,
                                    e.target.value,
                                  );
                                }}
                              />
                              <label htmlFor={item.id}>
                                <span></span>
                                <h4>{item.label}</h4>
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {typeof completeEngagementErrors !== 'undefined' &&
                        completeEngagementErrors.postSelection && (
                          <div className="clsinvalid">
                            {t(completeEngagementErrors?.['postSelection'])}
                          </div>
                        )}
                    </div>
                    <fieldset>
                      {loaded === true
                        ? completeEngagementData.question.map(
                            (question, index) => (
                              <div
                                className={
                                  index === 0
                                    ? `clscomplete-engagement-checkbox-blk`
                                    : `clscomplete-engagement-checkbox-blk mt-3`
                                }
                                key={`question-${question.id}`}
                              >
                                <div className="clscheckbox">
                                  <span>
                                    <label>
                                      <input
                                        id={`question-${completeEngagementData.question[index].id}`}
                                        type="checkbox"
                                        name={`selectedQuestionList`}
                                        value={
                                          completeEngagementData.question[index]
                                            .id
                                        }
                                        onChange={(e) => {
                                          questionSelectionChange(
                                            completeEngagementData.id,
                                            e.target.value,
                                          );
                                        }}
                                        checked={
                                          completeEngagementData.selectedQuestionList.includes(
                                            completeEngagementData.question[
                                              index
                                            ].id.toString(),
                                          ) === true
                                            ? true
                                            : false
                                        }
                                      />
                                      <label
                                        htmlFor={`question-${completeEngagementData.question[index].id}`}
                                      >
                                        <span></span>
                                      </label>
                                    </label>
                                  </span>
                                </div>
                                <div className="clscomplete-engagement-checkbox-content-box">
                                  <label>{`${completeEngagementData.question[index].title}`}</label>
                                  <span> {`(${t(`Please select one`)})`}</span>
                                  <ul>
                                    {completeEngagementData.question[index][
                                      'choice'
                                    ].map((choice, choiceIndex) => (
                                      <li key={`Choice-${choice.id}`}>
                                        <span>{choice.value}</span>{' '}
                                        <span>{choice.result}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            ),
                          )
                        : ''}
                      {typeof completeEngagementErrors !== 'undefined' &&
                        completeEngagementErrors.selectedQuestionList && (
                          <div className="clsinvalid">
                            {t(completeEngagementErrors?.['selectedQuestionList'])}
                          </div>
                        )}
                    </fieldset>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="wrapper text-center">
              <div className="d-inline">
                <button
                  type="submit"
                  className="btn clsyellow-btn"
                  disabled={completeEngagementSubmitting}
                >
                  {t(`Post Completed Engagement`)}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CompleteEngagementForm;
