/**
 *
 * Optional Information Modal
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicModal from '../../components/Common/CivicModal';
import OptionalInformations from '../../components/Manager/Profile/optionalInformations';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const OptionalEdit = (props) => {
  const { user, setOptionalInfoData, optionalInfoData } = props;
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);

  const loadData = useCallback(async () => {
    if (typeof user.roleName !== 'undefined') {
      await setOptionalInfoData(user).then(() => {
        setLoaded(true);
      });
    }
  }, [setOptionalInfoData, setLoaded, user]);

  useEffect(() => {
    if (loaded === false && typeof user.roleName !== 'undefined') {
      loadData();
    }
  }, [loaded, loadData, user.roleName]);

  return (
    <CivicModal
      id={`edit-optional-info-${user.userId}`}
      title={t(optionalInfoData.title)}
      dismissal={optionalInfoData.dismissal}
      modalDialogueClass={`clswidth-480`}
      modalBodyClass={`clswidth-365 pt-1`}
    >
      {loaded === true ? (
        <OptionalInformations {...props} />
      ) : (
        <LoadingIndicator position={`fixed`} />
      )}
    </CivicModal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    optionalInfoData: state.account.optionalInfoData,
    optionalInfoErrors: state.account.optionalInfoErrors,
    isProfileLoading: state.account.isProfileLoading,
  };
};

export default connect(mapStateToProps, actions)(OptionalEdit);
