/**
 *
 * Civic Filter
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const EventFilter = (props) => {
  const {
    filter,
    filterSelected,
    mapSearch,
    civicMapFilterChange,
    resetCivicMapFilter,
    submitCivicMapFilter,
    filterDismissal,
  } = props;

  const hashUrl = '#';
  const { t } = useTranslation();

  return (
    <div className="clsmap-filter-blk">
      <div className="clsmap-filter-box clswhite-box">
        <div
          className={filterDismissal ? 'collapse' : 'collapse show'}
          id_ignore="collapseExample"
        >
          {/* {optionLoading && <LoadingIndicator />} */}
          <div className="card card-body">
            <div className="form-group">
             {mapSearch && mapSearch.formatted_address ?
              <p style={{ color: '#888' }}>
                {t(`Displaying districts for`) + '\u00A0'}
                <strong>{mapSearch && mapSearch.formatted_address}</strong>
              </p>
              : null}
            </div>
            <div className="tabbable-panel">
              <div className="tabbable-line mb-2">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {Object.keys(filter).length > 0
                    ? Object.keys(filter).map((level1, index) => (
                        <li
                          className="nav-item"
                          key={index}
                          role="presentation"
                        >
                          <a
                            className={
                              filterSelected['map'].level1 === level1 ||
                              index === 0
                                ? 'nav-link active'
                                : 'nav-link'
                            }
                            id={level1 + '-tab'}
                            data-toggle="tab"
                            href={'#' + level1}
                            role="tab"
                            aria-controls={level1}
                            aria-selected="true"
                          >
                            {level1}
                          </a>
                        </li>
                      ))
                    : ''}
                </ul>
                <div className="tab-content" id="myTabContent">
                  {Object.keys(filter).length > 0
                    ? Object.keys(filter).map((level1, index1) => (
                        <div
                          className={
                            filterSelected['map'].level1 === level1 ||
                            index1 === 0
                              ? 'tab-pane show active'
                              : 'tab-pane'
                          }
                          key={index1}
                          id={level1}
                          role="tabpanel"
                          aria-labelledby={level1 + '-tab'}
                        >
                          <div className="clsmap-filter-content">
                            <div className="accordion" id="accordionFilter">
                              {Object.keys(filter[level1]).length > 0
                                ? Object.keys(filter[level1]).map(
                                    (level2, index2) => (
                                      <div className="card" key={index2}>
                                        <div
                                          className="card-header"
                                          id={
                                            'heading-' + index1 + '-' + index2
                                          }
                                        >
                                          <h2 className="mb-0">
                                            <a
                                              href={hashUrl}
                                              data-toggle="collapse"
                                              data-target={
                                                '#collapse-' +
                                                index1 +
                                                '-' +
                                                index2
                                              }
                                              aria-expanded={
                                                filterSelected['map'].level2 ===
                                                level2
                                                  ? true
                                                  : false
                                              }
                                              aria-controls={
                                                'collapse-' +
                                                index1 +
                                                '-' +
                                                index2
                                              }
                                            >
                                              {level2}
                                            </a>
                                          </h2>
                                        </div>

                                        <div
                                          id={
                                            'collapse-' + index1 + '-' + index2
                                          }
                                          className={
                                            filterSelected['map'].level2 ===
                                            level2
                                              ? 'collapse show'
                                              : 'collapse'
                                          }
                                          aria-labelledby={
                                            'heading-' + index1 + '-' + index2
                                          }
                                          data-parent="#accordionFilter"
                                        >
                                          <div className="card-body">
                                            <ul>
                                              {filter[level1][level2].map(
                                                (map, index3) => (
                                                  <li key={index3}>
                                                    <div className="clsradio">
                                                      <input
                                                        type="radio"
                                                        id={
                                                          'radio-' +
                                                          index2 +
                                                          '-' +
                                                          index3
                                                        }

                                                        name="radio"
                                                        checked={
                                                          filterSelected['map']
                                                            .ocdId === map.ocdId
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) => {
                                                          e.target.value =
                                                            filterSelected[
                                                              'map'
                                                            ] === map.ocdId
                                                              ? true
                                                              : false;
                                                        }}
                                                        value={
                                                          filterSelected[
                                                            'map'
                                                          ] === map.ocdId
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                      <label
                                                        htmlFor={
                                                          'radio-' +
                                                          index2 +
                                                          '-' +
                                                          index3
                                                        }
                                                        onClick={() => {
                                                          civicMapFilterChange(
                                                            map,
                                                          );
                                                          submitCivicMapFilter();
                                                        }}
                                                      >
                                                        <span> {map.name}</span>
                                                      </label>
                                                    </div>
                                                  </li>
                                                ),
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                  )
                                : ''}
                            </div>
                          </div>
                        </div>
                      ))
                    : ''}
                </div>
              </div>
              <div className="clsmap-filter-actions text-center mt-3">
                <a
                  href={hashUrl}
                  onClick={() => {
                    resetCivicMapFilter();
                  }}
                >
                  {t(`Reset`)}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventFilter;
