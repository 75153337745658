/**
 *
 * Filter Search Box
 *
 */

import { useCallback, useEffect } from 'react';
import SuggestionBox from './suggestionBox';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

const suggestData = debounce((type, func) => {
  func(type);
}, 500);

const SearchBox = (props) => {
  const {
    type,
    searchBox,
    suggestionBox,
    searchBoxChange,
    getSearchData,
    suggestiongBoxChange,
  } = props;

  const { t } = useTranslation();

  const contentClass =
    suggestionBox[type].display === true ? 'clscontent-show' : '';

  const hashUrl = '#';

  const onSearchChange = useCallback(() => {
    if (searchBox[type].key.length > 0) {
      suggestiongBoxChange(type, 'display', true);
      suggestData(type, getSearchData);
    }
  }, [type, getSearchData, searchBox, suggestiongBoxChange]);

  useEffect(() => {
    if (searchBox[type].key.length === 0) {
      suggestiongBoxChange(type, 'display', false);
    }
  }, [searchBox, type, suggestiongBoxChange]);

  const openSuggestionBox = () => {
    if (searchBox[type].key.length > 0) {
      suggestiongBoxChange(type, 'display', true);
    }
  };

  return typeof type !== 'undefined' ? (
    <div
      id={`search-box-${type}`}
      className={`clsfilter-search-blk mt-3 clssearch-field-show ${contentClass}`}
      style={{ display: searchBox[type].display === true ? 'block' : 'none' }}
    >
      <div className="form-group">
        <label>
          {t(`Search`)}
          {` ${type}`}
        </label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <img
                src={require('../../../assets/img/search-1.svg').default}
                alt="Search"
              />
            </div>
          </div>
          <input
            type="text"
            id={`search-${type}`}
            name={`search-${type}`}
            className="form-control"
            placeholder={`${t(`Search`)} ${type}`}
            value={searchBox[type].key}
            autoComplete={`off`}
            onChange={(e) => {
              searchBoxChange(type, 'key', e.target.value);
              onSearchChange(type);
            }}
            onFocus={openSuggestionBox}
          />
          <figure
            className={'clssearchclose-icon'}
            style={{
              display: suggestionBox[type].display === true ? 'block' : 'none',
            }}
          >
            <a
              href={hashUrl}
              onClick={() => {
                suggestiongBoxChange(type, 'display', false);
              }}
            >
              <img
                src={
                  require('../../../assets/img/search-close-icon.svg').default
                }
                alt="Close"
              />
            </a>
          </figure>
        </div>
        <SuggestionBox {...props} />
      </div>
    </div>
  ) : (
    ''
  );
};

export default SearchBox;
