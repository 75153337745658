/*
 *
 * InviteOthers reducer
 *
 */

import {
  INVITE_OTHERS_FORM_CHANGE,
  INVITE_OTHERS_FORM_RESET,
  INVITE_OTHERS_FORM_ERRORS,
  SET_INVITE_SUBMITTING,
  RESET_APP,
} from './constants';

const initialState = {
  inviteFormData: { inviteWhom: 'publicofficial', email: '' },
  inviteFormErrors: [],
  isInviteSubmitting: false,
};

const defaultState = JSON.stringify(initialState);

const inviteOtherReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case INVITE_OTHERS_FORM_CHANGE:
      return {
        ...state,
        inviteFormData: { ...state.inviteFormData, ...action.payload },
      };
    case INVITE_OTHERS_FORM_RESET:
      return {
        ...state,
        inviteFormData: { inviteWhom: 'publicofficial', email: '' },
        inviteFormErrors: {},
      };
    case INVITE_OTHERS_FORM_ERRORS:
      return {
        ...state,
        inviteFormErrors: action.payload,
      };
    case SET_INVITE_SUBMITTING:
      return {
        ...state,
        isInviteSubmitting: action.payload,
      };
    default:
      return state;
  }
};

export default inviteOtherReducer;
