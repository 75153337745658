/**
 *
 * Engagement Header Block
 *
 */

import React, { useEffect, useState, createContext } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import {
  daysDiffTxt,
  capitalizeFirstLetter,
  getScheduleTime,
} from '../../../utils/helper';
//import {capitalizeFirstLetter} from '../../../utils/helper';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import ShareLink from '../../Common/ShareLink';
import InAppropriateReport from '../../../containers/InAppropriateReport';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import LinkedNotification from '../../Common/ShareLink/linkedInNotification';
import Tooltip from '../../Common/Tooltip';
import { languageMap } from '../../Common/LanguageSelect';

const EngagementHeader = (props) => {
  const {
    engagement,
    dotsDisable,
    location,
    turnOnOffNotification,
    addNotification,
    inAppropriateReset,
    user,
    followPublicOfficial,
    deleteEngagement,
    showOriginalLanguage,
    setShowOriginalLanguage,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const shareUrl =
    window.location.origin + '/dashboard/engagement/?id=' + engagement.id;
  const text = convertFromJSONToHTML(engagement?.comment);
  const title = text.__html.replace(/<\/?[^>]+>/gi, '');
  const hashUrl = '#';

  const statusString =
    typeof engagement.stage !== 'undefined'
      ? engagement.stage[0].toUpperCase() + engagement.stage.slice(1)
      : '';

  const navigateTo = (url) => {
    history.push(url);
  };

  const showReportModal = (event) => {
    inAppropriateReset();
    event.target.setAttribute(
      'data-target',
      '#inappropriate-report-' + engagement.id,
    );
    $(event.target).trigger('click');
  };

  $(document).ready(function () {
    $('.post-menu').mouseover(function (e) {
      $('.shareWidth').hide();
    });
    $('.share-option').click(function (e) {
      if (typeof e.target.id !== 'undefined' && e.target.id !== '') {
        if (e.target.id === 'share-option-' + engagement.id) {
          e.stopImmediatePropagation();
          $('.clsshare-dropdown > .dropdown-menu').toggle('show');
          $('.clsshare-dropdown').addClass('show');
          e.preventDefault();
        }
      }
    });
  });

  return (
    <>
      <InAppropriateReport engagementId={engagement.id} />
      <LinkedNotification targetId={engagement.id} shareUrl={shareUrl} />
      <div 
        className="clsdots"
      >
        <div className="btn-group">
          {(user.selected_language !== engagement?.originalLanguage) &&
            <div className="translation-tooltip">
              {engagement?.translation ?
                  <img src={require('../../../assets/img/icon-language.svg').default} alt="Globe"/> :
                  <img src={require('../../../assets/img/icon-language-crossed.svg').default} alt="Globe Crossed"/>}
              <div className="translation-tooltiptext">
                {engagement?.translation ?
                    <>{t(`This content was originally written in`) + '\u00A0' + `${t(languageMap?.[engagement?.originalLanguage]?.label)}.`}
                      <a href='https://civicl.ink/aitranslation'
                        target="_blank" rel="noreferrer">{'\u00A0' + t('Learn more about our AI-based language translation.')}</a>
                      <button className="translate-btn clsyellow-translate-btn"
                        onClick={() => { 
                          setShowOriginalLanguage(!showOriginalLanguage);
                        }}
                      >
                        {showOriginalLanguage ? t('Revert to requested language') : t('Show in original language')}
                      </button>
                    </> : <>{t('No translation exists for this engagement.')}</>}
              </div>
            </div>
          }
          {
            (typeof user.roleName !== 'undefined') && (engagement?.userOutsideToPO === true) && !(user?.userFollowingOfficials?.includes(engagement?.user?.userId)) &&
              <a
                href={hashUrl}
                onClick={(e) => {
                  followPublicOfficial(engagement?.user?.userId);
                  e.preventDefault();
                }}
                style={{
                  color: '#ffaa32',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  paddingRight: 15,
                }}
              >
                {
                  t('Follow')
                }
              </a>
          }
          {
            (typeof user.roleName !== 'undefined') &&
            <div>
              <button
                type="button"
                className="btn dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={require('../../../assets/img/more.svg').default}
                  alt="More"
                />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {engagement.stage !== 'unpublished' ? (
                  <>
                    <button
                      className="dropdown-item post-menu"
                      type="button"
                      onClick={() => {
                        navigateTo('/dashboard/engagement/?id=' + engagement.id);
                      }}
                    >
                      {t(`View`)}
                    </button>
                    <>
                      <div
                        className="dropdown-item share-option"
                        id={`share-option-${engagement.id}`}
                        type="button"
                      >
                        <ShareLink
                          shareUrl={shareUrl}
                          title={title}
                          subMenu={true}
                          module={'dashboard'}
                          notify={addNotification}
                          targetId={engagement.id}
                        />
                      </div>
                      {uri === 'dashboard/civicfeed' ||
                        uri === 'dashboard/user-profile' ||
                        uri === 'dashboard/civiclist' ||
                        uri === 'dashboard/engagement' ? (
                        <>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => {
                              turnOnOffNotification(
                                engagement.id,
                                !engagement.notification_status,
                              );
                            }}
                          >
                            {engagement.notification_status === true
                              ? t(`Turn off Notifications`)
                              : t(`Turn on Notifications`)}
                          </button>
                          <a
                            href={hashUrl}
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target={hashUrl}
                            onClick={(event) => {
                              showReportModal(event);
                            }}
                          >
                            {t(`Report as inappropriate`)}
                          </a>
                          {
                            (engagement?.userOutsideToPO === true) && (user?.userFollowingOfficials?.includes(engagement?.user?.userId)) &&
                              <a
                                href={hashUrl}
                                className="dropdown-item"
                                data-toggle="modal"
                                data-target={hashUrl}
                                onClick={(e) => {
                                  followPublicOfficial(engagement?.user?.userId);
                                  e.preventDefault();
                                }}
                              >
                                {
                                  t('Unfollow')
                                }
                              </a>
                          }
                          {
                            (engagement?.userOutsideToPO === false) && 
                              <>
                                <a
                                  id={`cannot-unfollow-${engagement.id}`}
                                  className="dropdown-item"
                                  data-toggle="modal"
                                  style={{
                                    textDecoration: 'line-through',
                                  }}
                                >
                                  {
                                    user?.userFollowingOfficials?.includes(engagement?.user?.userId)
                                      && t('Unfollow')
                                  }
                                </a>
                                <Tooltip
                                  targetId={`cannot-unfollow-${engagement.id}`}
                                  targetStatus={true}
                                  styleClass={'react-tooltip'}
                                  message={t('You cannot unfollow elected officials that represent you.')}
                                />
                              </>
                          }
                        </>
                      ) : (
                        ''
                      )}
                      {(user.roleName === 'PUBLICOFFICIAL' ||
                        user.roleName === 'ADMIN') &&
                        uri === 'dashboard' ? (
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            deleteEngagement(engagement);
                          }}
                        >
                          {t(`Delete`)}
                        </button>
                      ) : (
                        ''
                      )}
                    </>
                  </>
                ) : (
                  <>
                    <>
                      {(user.roleName === 'PUBLICOFFICIAL' ||
                        user.roleName === 'ADMIN') &&
                        uri === 'dashboard' ? (
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            deleteEngagement(engagement);
                          }}
                        >
                          {t(`Delete`)}
                        </button>
                      ) : (
                        ''
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
          }
        </div>
      </div>
      <div className="media">
        <NavLink
          to={'/dashboard/user-profile/?id=' + engagement.user.userId}
          activeClassName="active"
          exact
        >
          <img
            src={
              engagement.user.profile_picture !== null
                ? engagement.user.profile_picture
                : require('../../../assets/img/profile-image.jpeg').default
            }
            className="align-self-center mr-3 engagement-profile-img"
            alt={engagement.user.first_name}
          />
        </NavLink>
        <div className="media-body">
          {engagement.category === 'post' &&
            engagement.stage === 'unpublished' &&
            engagement.engagement_timeframe !== null ? (
            <span className="badge clsored-badge">{`Scheduled to publish on ${getScheduleTime(
              engagement.engagement_timeframe,
            )}`}</span>
          ) : (
            ''
          )}
          <h5 className="mt-0">
            <NavLink
              to={'/dashboard/user-profile/?id=' + engagement.user.userId}
              activeClassName="active"
              className={'profile-link'}
              exact
            >
              {engagement.post_under_organization === true
                ? engagement.user.organization_name
                : capitalizeFirstLetter(engagement.user.first_name) +
                ' ' +
                capitalizeFirstLetter(engagement.user.last_name)}
            </NavLink>
            {engagement.stage === 'unpublished' ? (
              <span className="badge clsgrey-white-badge">
                {t(statusString)}
              </span>
            ) : (
              ''
            )}
          </h5>
          <p>
            {engagement.post_under_organization !== true
              ? capitalizeFirstLetter(
                (engagement.user.position || '') + ' - ' + (engagement.user.area || ''),
              )
              : ''}
          </p>
          <p>
            {engagement.stage !== 'unpublished' && engagement.engagement_timeframe ?
                daysDiffTxt(engagement.engagement_timeframe, t, false)
              :
                daysDiffTxt(engagement.createdAt, t)
            }
          </p>
        </div>
      </div>
    </>
  );
};

export default EngagementHeader;
