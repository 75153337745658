/**
 *
 * CivicFeed Selected Filter Block
 *
 */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../Common/Tooltip';

const SelectedFilter = (props) => {
  const {
    filter,
    filterSelected,
    filterChange,
    user,
    followTopic,
    followLocation,
    resetFilter,
  } = props;
  const types = typeof filter === 'object' ? Object.keys(filter) : [];
  const hashUrl = '#';
  const { t } = useTranslation();
  const [viewFilter, setViewFilter] = useState(false);

  useEffect(() => {
    if (
      filterSelected['topics'].length > 0 ||
      filterSelected['locations'].length > 0 ||
      filterSelected['postType'].length > 0
    ) {
      setViewFilter(true);
    } else {
      setViewFilter(false);
    }
  }, [filterSelected]);

  const selectedFilterName = (id, filterArr) => {
    let result = filterArr.find((obj) => obj.id === id);
    if (typeof result === 'object') {
      return result['name'];
    } else {
      return '';
    }
  };

  const isFollowing = (id, type) => {
    if (type === 'topics') {
      let followArray = user.userFollowingTopics;
      let result = followArray.find((obj) => obj.id === id);
      if (typeof result === 'object') {
        return true;
      } else {
        return false;
      }
    } else {
      let followArray = user.followinglocations;
      let location = selectedFilterName(id, filter['locations'].items);
      let result = followArray.findIndex((item) => item === location);
      if (result === -1) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      {viewFilter === true ? (
        <div className="clsselected-filters mb-4">
          <h5>Selected Filters</h5>
          <h6 className="clsreset-btn">
            <a
              href={hashUrl}
              onClick={() => {
                resetFilter(true);
                setViewFilter(false);
              }}
            >
              Clear All{' '}
              <img
                src={require('../../../assets/img/chevron_up_icon.svg').default}
                alt="clearAll"
              />
            </a>
          </h6>
          <div className="clsselected-filters-list mt-4">
            <ul>
              {types.map((type, index) => (
                <React.Fragment key={`${type}-fragment-list-${index}`}>
                  {typeof filterSelected[type] !== 'undefined' &&
                  filterSelected[type].length > 0 ? (
                    <li key={`${type}-filter-list-${index}`}>
                      <label> {t(filter[type].title)}</label>
                      <div className="clsselected-filters-right">
                        {typeof filterSelected[type] !== 'undefined' &&
                        filterSelected[type].length > 0 ? (
                          <ul>
                            {filter[type].title !== 'Post Type' ? (
                              <>
                                {filterSelected[type].map((item, index) => (
                                  <li key={`${type}-list-${index}`}>
                                    <div className="checkbox">
                                      <h6>
                                        {selectedFilterName(
                                          item,
                                          filter[type].items,
                                        )}{' '}
                                        {isFollowing(item, type) === true ? (
                                          <span>
                                            <img
                                              src={
                                                require('../../../assets/img/check@1,5x.svg')
                                                  .default
                                              }
                                              alt="chexk"
                                            />
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </h6>
                                      <span className="clscheck-close">
                                        <a
                                          href={hashUrl}
                                          onClick={() => {
                                            filterChange(type, item, true);
                                          }}
                                        >
                                          <img
                                            src={
                                              require('../../../assets/img/close-orange.svg')
                                                .default
                                            }
                                            alt="Close"
                                            id={`close-filter-${type}-${index}`}
                                          />
                                          <Tooltip
                                            targetId={`close-filter-${type}-${index}`}
                                            targetStatus={true}
                                            styleClass={'react-tooltip'}
                                            message={`Close Filter`}
                                          />
                                        </a>
                                      </span>
                                      <span className="clscheck-more"></span>
                                      <div className="clsdots">
                                        <div className="btn-group">
                                          <button
                                            type="button"
                                            className="btn dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src={
                                                require('../../../assets/img/more.svg')
                                                  .default
                                              }
                                              alt="More"
                                            />
                                          </button>
                                          <div className="dropdown-menu dropdown-menu-right">
                                            <button
                                              className="dropdown-item"
                                              type="button"
                                              onClick={() => {
                                                if (type === 'topics') {
                                                  followTopic(
                                                    parseInt(item),
                                                    isFollowing(item, type) ===
                                                      true
                                                      ? 'unfollow'
                                                      : 'follow',
                                                  );
                                                } else {
                                                  followLocation(
                                                    selectedFilterName(
                                                      item,
                                                      filter[type].items,
                                                    ),
                                                    isFollowing(item, type) ===
                                                      true
                                                      ? 'unfollow'
                                                      : 'follow',
                                                  );
                                                }
                                              }}
                                            >
                                              {isFollowing(item, type) === true
                                                ? 'Unfollow'
                                                : 'Follow'}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </>
                            ) : (
                              <>
                                {filterSelected[type].map((item, index) => (
                                  <li key={`${type}-list-${index}`}>
                                    <div className="checkbox">
                                      <label className="clsfilter-info">
                                        <img
                                          src={
                                            selectedFilterName(
                                              item,
                                              filter[type].items,
                                            ) === 'Post'
                                              ? require('../../../assets/img/megaphone.svg')
                                                  .default
                                              : selectedFilterName(
                                                  item,
                                                  filter[type].items,
                                                ) === 'Discussion'
                                              ? require('../../../assets/img/comment_icon.svg')
                                                  .default
                                              : require('../../../assets/img/survey.svg')
                                                  .default
                                          }
                                          alt="Info"
                                        />
                                        <h6>
                                          {selectedFilterName(
                                            item,
                                            filter[type].items,
                                          )}
                                        </h6>
                                      </label>
                                      <span className="clscheck-close">
                                        <a
                                          href={hashUrl}
                                          onClick={() => {
                                            filterChange(type, item, true);
                                          }}
                                        >
                                          <img
                                            src={
                                              require('../../../assets/img/close-orange.svg')
                                                .default
                                            }
                                            alt="Close"
                                            id={`close-filter-${type}-${index}`}
                                          />
                                          <Tooltip
                                            targetId={`close-filter-${type}-${index}`}
                                            targetStatus={true}
                                            styleClass={'react-tooltip'}
                                            message={`Close Filter`}
                                          />
                                        </a>
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </>
                            )}
                          </ul>
                        ) : (
                          ''
                        )}
                      </div>
                    </li>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SelectedFilter;
