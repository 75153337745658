import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorPageSessionId from "../ErrorPageSessionId";

const Page404 = (props) => {
  const { t } = useTranslation();

  let history = useHistory();

  return (
    <div className="cls404">
      <div className="cls404-in">
        <img
          src={require('../../../assets/img/img-404.svg').default}
          alt="404"
          className="img-fluid"
        />
        <p className="mt-4 mb-4 pt-3">
          {t(`Oops! The page you requested was not found!`)}
        </p>

        <p className="mt-4 mb-4 pt-3">
          {t(`If this screen persists, please let us know via`)} <a href="mailto:support@civicbell.com">support@civicbell.com</a>.
          <br/>
          <ErrorPageSessionId />
        </p>

        <div className="text-center">
          <button
            type="submit"
            className="btn clsyellow-btn"
            onClick={() => history.goBack()}
          >
            {t(`Go Back`)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Page404;
