/**
 *
 * Display All Civic Event List
 *
 */

import React from 'react';
import Post from './post';
import ConfirmPopup from './confirmPopup';
import { useTranslation } from 'react-i18next';

const EventsList = (props) => {
  const { myEvents, expired, confirmPopup } = props;
  const { t } = useTranslation();

  return (
    <div className="clsevents-list-whole mb-5">
      {myEvents.length === 0 ? (
        <div className={'mb-3 no-engagement'}>
          <span className={'sad-img'}>
            <img
              src={require('../../../assets/img/sad.svg').default}
              alt={t('No Events')}
            />
          </span>
          <span className="no-engagement-txt">
            {t(`You don't have any events`)}
          </span>
        </div>
      ) : (
        myEvents.map((event, index) => (
          <Post
            key={index}
            {...props}
            month={false}
            expired={expired}
            event={event}
          />
        ))
      )}
      {confirmPopup !== null ? <ConfirmPopup {...props} /> : ''}
    </div>
  );
};

export default EventsList;
