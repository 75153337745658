/**
 *
 * token.js
 * axios default headers setup
 */

import axios from 'axios';
import store from "../store";
import {setSilentUser} from "../containers/Authentication/actions";

const setToken = (token, isGuestUser = false) => {
  if (token) {
    if (!isGuestUser) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      axios.defaults.headers.common['Authorization'] = 'Guest ' + token;

      store.dispatch(setSilentUser());
    }
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setToken;
