/*
 *
 * Survey Attachments
 *
 */

import React, { useEffect, useState } from 'react';
import CivicModal from '../../Common/CivicModal';
import LoadingIndicator from '../../Common/LoadingIndicator';
import EngagementHeader from '../Engagement/engagementHeader';
import moment from 'moment-timezone';
import Notification from '../../../containers/Notification';
import { useTranslation } from 'react-i18next';
import { getFileExtension } from '../../../utils/helper';

const SurveyAttachments = (props) => {
  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);
  const { engagement, sendSurveyDetail, user } = props;
  const { t } = useTranslation();
  const hashUrl = '#';
  const [attachments, setAttachments] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [startDate, setStartDate] = useState(engagement.engagement_timeframe);
  const [closeDate, setCloseDate] = useState(engagement.engagement_close);
  const [completeDate, setCompleteDate] = useState(
    engagement.engagement_completion,
  );
  const [surveySubmitting, setSurveySubmitting] = useState(false);

  const formatTimeFrame = (date) => {
    if (moment(date).isValid() === false) {
      return '-';
    }
    //return moment(date).format('MMM Do, YYYY');
    return moment.utc(date).tz(moment.tz.guess()).format('MMM Do, YYYY');
  };

  useEffect(() => {
    if (loaded === false) {
      let attached_documents = null;
      if (engagement.attached_documents !== null) {
        let allowedFormat = [
          'pdf',
          'doc',
          'docx',
          'xls',
          'xlsx',
          'ppt',
          'pptx',
          'rtf',
          'odt',
          'txt',
          'zip',
        ];
        attached_documents = engagement.attached_documents
          .map((file, index) => {
            let fileExt = getFileExtension(JSON.parse(file).fileLocation);
            if (allowedFormat.includes(fileExt) === true) {
              return JSON.parse(file);
            }
            return undefined;
          })
          .filter(function (item) {
            return item !== undefined;
          });
      }

      setAttachments(attached_documents);
      setStartDate(formatTimeFrame(engagement.engagement_timeframe));
      setCloseDate(formatTimeFrame(engagement.engagement_closing));
      setCompleteDate(formatTimeFrame(engagement.engagement_completion));
      setLoaded(true);
    }
  }, [setAttachments, engagement, loaded, setLoaded]);

  const emailSurveyDetail = async (id) => {
    setSurveySubmitting(true);
    await sendSurveyDetail(id).then(() => {
      setSurveySubmitting(false);
    });
  };

  return (
    <>
      {surveySubmitting === true ? <LoadingIndicator /> : ''}
      {typeof engagement !== 'undefined' ? (
        <div key={`survey-attachments-div-${engagement.id}`}>
          <CivicModal
            id={`survey-attachments-${engagement.id}`}
            title={``}
            dismissal={false}
          >
            <div className="survey-detail-attachment-block">
              <Notification module={'dashboard'} />
              <div className="tabbable-panel clsdashboard-panel">
                <div className="tabbable-line">
                  <ul
                    className="nav nav-tabs"
                    id="survey-detail-attachment-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id={`SurveyDetails-tab-${engagement.id}`}
                        data-toggle="tab"
                        href={`#SurveyDetails-${engagement.id}`}
                        role="tab"
                        aria-controls="open"
                        aria-selected="true"
                      >
                        {t(`Survey Details`)}
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id={`Attachments-tab-${engagement.id}`}
                        data-toggle="tab"
                        href={`#Attachments-${engagement.id}`}
                        role="tab"
                        aria-controls="Attachments"
                        aria-selected="false"
                      >
                        {t(`Attachments`)}
                      </a>
                    </li>
                  </ul>
                  <div
                    className="tab-content"
                    id={`myTabContent-${engagement.id}`}
                  >
                    <div
                      className="tab-pane fade active show"
                      id={`SurveyDetails-${engagement.id}`}
                      role="tabpanel"
                      aria-labelledby={`SurveyDetails-tab-${engagement.id}`}
                    >
                      <EngagementHeader {...props} dotsDisable="false" showOriginalLanguage={showOriginalLanguage} setShowOriginalLanguage={setShowOriginalLanguage}/>
                      <div className="status-rectangle">
                        <div className="engagement-period-div">
                          <h5 className="engagement-period">
                            {t(`Engagement Period`)}
                          </h5>
                        </div>
                        <div className="status-div">
                          <div className="status-item">
                            <span className="status-txt">{t(`Open`)}</span>
                            <span className="status-date">{startDate}</span>
                          </div>
                          <div className="status-item">
                            <div className="status-line"></div>
                          </div>
                          <div className="status-item">
                            <span className="status-txt">{t(`Close`)}</span>
                            <span className="status-date">{closeDate}</span>
                          </div>
                          <div className="status-item">
                            <div className="status-line"></div>
                          </div>
                          <div className="status-item">
                            <span className="status-txt">{t(`Outcome`)}</span>
                            <span className="status-date">{completeDate}</span>
                          </div>
                        </div>
                      </div>
                      <div className="survey-title">
                        <span>
                          {
                            user.selected_language === engagement.originalLanguage || showOriginalLanguage ?
                              engagement.title
                            :
                              JSON.parse(engagement.translation)?.[user.selected_language]?.title ?
                                JSON.parse(engagement.translation)?.[user.selected_language]?.title
                              :
                                engagement.title
                          }
                        </span>
                      </div>
                      <div className="survey-description">
                        <span>
                        {
                            user.selected_language === engagement.originalLanguage || showOriginalLanguage?
                              engagement.description
                            :
                              JSON.parse(engagement.translation)?.[user.selected_language]?.description ?
                                JSON.parse(engagement.translation)?.[user.selected_language]?.description
                              :
                                engagement.description
                          }
                        </span>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id={`Attachments-${engagement.id}`}
                      role="tabpanel"
                      aria-labelledby={`Attachments-tab-${engagement.id}`}
                    >
                      <div className="attachment-list">
                        {typeof attachments !== 'undefined' &&
                        attachments !== null ? (
                          <>
                            {attachments.length > 0 ? (
                              <>
                                {attachments.map((location, index) => (
                                  <div
                                    className="attachment-detail"
                                    key={`engagement-${engagement.id}-attachment-${index}`}
                                  >
                                    <div className="attachment-item">
                                      <span>
                                        <img
                                          src={
                                            require('../../../assets/img/survey.svg')
                                              .default
                                          }
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                    <div className="attachment-item">
                                      <span className="attachment-name">
                                        <a
                                          href={location.fileLocation}
                                          target="blank"
                                        >
                                          {typeof location.fileName !==
                                            'undefined' &&
                                          location.fileName !== ''
                                            ? location.fileName
                                            : location.fileLocation.substring(
                                                location.fileLocation.lastIndexOf(
                                                  '/',
                                                ) + 1,
                                              )}
                                        </a>
                                      </span>
                                    </div>
                                    <div className="attachment-item size-align">
                                      <span className="attachment-size">
                                        {location.fileSize}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="attachment-norecords">
                                {t(`No documents found.`)}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="attachment-norecords">
                            {t(`No documents found.`)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="send-survey-details">
                      <a
                        href={hashUrl}
                        onClick={(e) => {
                          emailSurveyDetail(engagement.id);
                          e.preventDefault();
                        }}
                      >
                        <span>
                          <img
                            src={
                              require('../../../assets/img/envelope.svg')
                                .default
                            }
                            className="align-self-center mr-3"
                            alt="Envelop"
                          />{' '}
                          {t(`Send Survey Details to my Email`)}
                        </span>
                        <span>
                          <img
                            src={
                              require('../../../assets/img/chevron_right_icon.svg')
                                .default
                            }
                            alt=""
                          />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CivicModal>
        </div>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

SurveyAttachments.defaultProps = {
  type: 'Modal',
};

export default SurveyAttachments;
