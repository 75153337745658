import React, { useCallback, useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
//import demoImage from '../../../assets/img/event-img1.jpg';

function CropImg(props) {
  const { imageToCrop, onImageCropped, type, setCropLoading, initialLoad } =
    props;
  const [loaded, setLoaded] = useState(false);
  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: 'px',
      width:
        type === 'profileImage'
          ? 200
          : window.innerWidth > 810
          ? 800
          : window.innerWidth > 800
          ? 699
          : 298,
      height:
        type === 'profileImage'
          ? 200
          : window.innerWidth > 810
          ? 800 * 0.28
          : window.innerWidth > 800
          ? 699 * 0.28
          : 345 * 0.28,
      x: initialLoad === true && type === 'profileBgImage' ? 0 : 50,
      y: initialLoad === true && type === 'profileBgImage' ? 0 : 50,
    },
  );

  const [imageRef, setImageRef] = useState();

  const cropImage = useCallback(
    async (crop) => {
      if (imageRef && crop.width && crop.height) {
        function getCroppedImage(sourceImage, cropConfig, fileName) {
          // creating the cropped image from the source image
          const canvas = document.createElement('canvas');
          const scaleX = sourceImage.naturalWidth / sourceImage.width;
          const scaleY = sourceImage.naturalHeight / sourceImage.height;

          canvas.width = cropConfig.width * scaleX;
          canvas.height = cropConfig.height * scaleY;
          const ctx = canvas.getContext('2d');

          ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
          );

          return new Promise((resolve, reject) => {
            canvas.toBlob(
              (blob) => {
                // returning an error
                if (!blob) {
                  reject(new Error('Canvas is empty'));
                  return;
                }

                blob.name = fileName;
                // creating a Object URL representing the Blob object given
                //const croppedImageUrl = window.URL.createObjectURL(blob);
                resolve(blob);
              },
              'image/png',
              1,
            );
          });
        }
        const croppedImage = await getCroppedImage(
          imageRef.target,
          crop,
          'croppedImage.png', // destination filename
        );

        // calling the props function to expose
        // croppedImage to the parent component
        onImageCropped(croppedImage);
      }
    },
    [imageRef, onImageCropped],
  );

  const onImageLoad = (imageRef) => {
    setImageRef(imageRef);
  };

  useEffect(() => {
    if (typeof imageRef !== 'undefined' && loaded === false) {
      if (typeof imageRef.target !== 'undefined') {
        let value = {
          unit: 'px',
          width:
            type === 'profileImage'
              ? 200
              : window.innerWidth > 810
              ? 800
              : window.innerWidth > 800
              ? 699
              : 298,
          height:
            type === 'profileImage'
              ? 200
              : window.innerWidth > 810
              ? 800 * 0.28
              : window.innerWidth > 800
              ? 699 * 0.28
              : 345 * 0.28,
          x: initialLoad === true && type === 'profileBgImage' ? 0 : 50,
          y: initialLoad === true && type === 'profileBgImage' ? 0 : 50,
        };

        cropImage(value);
        setLoaded(true);
      }
    }
  }, [
    imageRef,
    setCropConfig,
    cropImage,
    cropConfig,
    loaded,
    setLoaded,
    type,
    initialLoad,
  ]);

  return (
    <>
      <ReactCrop
        src={imageToCrop}
        crop={cropConfig}
        ruleOfThirds
        onImageLoaded={(imageRef) => {
          setImageRef(imageRef);
          setCropLoading(false);
        }}
        onComplete={(cropConfig) => cropImage(cropConfig)}
        onChange={(cropConfig) => {
          setCropLoading(false);
          setCropConfig(cropConfig);
        }}
        crossorigin="anonymous" // to avoid CORS-related problems
        circularCrop={type === 'profileImage' ? true : false}
        locked={false}
        aspect={type === 'profileImage' ? 1 : undefined}
      >
        <img
          src={imageToCrop}
          onLoad={(imageRef) => {
            onImageLoad(imageRef);
          }}
          alt={``}
        />
      </ReactCrop>
    </>
  );
}

CropImg.defaultProps = {
  onImageCropped: () => {},
};

export default CropImg;
