/**
 *
 * Civic Filter
 *
 */

import React, { useState } from 'react';
import SearchBox from './searchBox';
import { useTranslation } from 'react-i18next';

const CivicFilter = (props) => {
  const hashUrl = '#';
  const { filter, filterSelected, searchBoxChange, filterChange, user } = props;
  const { t } = useTranslation();
  const types = typeof filter === 'object' ? Object.keys(filter) : [];
  const [viewAllStatus, setViewAllStatus] = useState(false);

  const viewAllRecords = () => {
    setViewAllStatus(true);
  };

  const isFollowing = (item, type) => {
    if (type === 'topics') {
      if (!user || !user.userFollowingTopics) return false;
      let followArray = user.userFollowingTopics;
      let result = followArray.find((obj) => obj.id === item.id);
      if (typeof result === 'object') {
        return true;
      } else {
        return false;
      }
    } else {
      if (!user || !user.followinglocations) return false;
      let followArray = user.followinglocations;
      let location = item.name;

      if (followArray) {
        let result = followArray.findIndex((data) => data === location);
        if (result === -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  return (
    <div className="clsfilter-modal-content">
      <h4 className={`mt-4`}>
        <span>
          <img
            src={require('../../../assets/img/check@1,5x.svg').default}
            alt="chexk"
          />
        </span>
        { t('Topics/Locations you follow') }
      </h4>
      {types.map((type, index) => (
        <React.Fragment key={`${type}-${index}`}>
          <h5 className={`mt-4`}>
            {t(filter[type].title)}
            {type !== 'postType' ? (
              <span>
                <a
                  href={hashUrl}
                  onClick={(e) => {
                    searchBoxChange(type, 'display', true);
                    e.preventDefault();
                  }}
                >
                  <img
                    src={require('../../../assets/img/add-plus.svg').default}
                    alt="Add"
                  />
                  {t(`Add`)}
                </a>
              </span>
            ) : (
              ''
            )}
          </h5>
          {type !== 'postType' ? <SearchBox {...props} type={type} /> : ''}

          <ul className="mt-3 pt-1 mb-2 filter-item-list" id={`type-${type}`}>
            {filter[type].items.map((item, index) => (
              <li
                key={`${index}-${type}-${item.id}`}
                style={{
                  display:
                    viewAllStatus === false && index > 8 ? 'none' : 'block',
                }}
              >
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`${type}-${item.id}`}
                    name="checkbox"
                    checked={
                      filterSelected[type].indexOf(item.id) !== -1
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      e.target.value =
                        filterSelected[type].indexOf(item.id) !== -1
                          ? true
                          : false;
                    }}
                    value={
                      filterSelected[type].indexOf(item.id) !== -1
                        ? true
                        : false
                    }
                  />
                  <label
                    className={`civic-filter-item`}
                    htmlFor={`${type}-${item.id}`}
                    onClick={() => {
                      filterChange(type, item.id);
                    }}
                  >
                    <span>
                      {type === 'postType' ? (
                        <img
                          src={
                            item.name === 'Post'
                              ? require('../../../assets/img/megaphone.svg')
                                  .default
                              : item.name === 'Discussion'
                              ? require('../../../assets/img/comment_icon.svg')
                                  .default
                              : require('../../../assets/img/survey.svg')
                                  .default
                          }
                          alt="Info"
                          className={`filter-item-img`}
                        />
                      ) : (
                        ''
                      )}
                      {t(item.name)}
                      {isFollowing(item, type) === true &&
                      type !== 'postType' ? (
                        <img
                          src={
                            require('../../../assets/img/check@1,5x.svg')
                              .default
                          }
                          alt="chexk"
                        />
                      ) : (
                        ''
                      )}
                    </span>
                  </label>
                </div>
              </li>
            ))}
          </ul>

          {filter[type].items.length > 9 && viewAllStatus === false ? (
            <span
              className={`filter-view-all`}
              data-toggle="collapse"
              data-target={`#type-${type}`}
              onClick={() => {
                viewAllRecords();
              }}
            >
              {t(`View All`)}
            </span>
          ) : (
            ''
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CivicFilter;
