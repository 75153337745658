/*
 *
 * UserNotificationPreferences actions
 *
 */

import axios from 'axios';
import {
  BASE_API_URL,
} from '../../constants';
import { 
  GET_USER_NOTIFICATION_PREFERENCES,
  UPDATE_USER_NOTIFICATION_PREFERENCES 
} from './constants';
import { addNotification } from '../Notification/actions';

export const getUserNotificationPreferences = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(BASE_API_URL + '/users/userNotificationPreferences');
      if (response.data.success === 'true') {
        dispatch({ type: GET_USER_NOTIFICATION_PREFERENCES, payload: response.data.data })
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    }
  };
}

export const updateUserNotificationPreferences = ({ receiver, resourceType, setting }) => {
  return async (dispatch, getState) => {
    try {
      const dataInfo = {
        receiver,
        resourceType,
        setting,
      }
      const response = await axios.patch(BASE_API_URL + '/users/userNotificationPreferences', dataInfo);
      if (response.data.success === 'true') {
        dispatch({ type: UPDATE_USER_NOTIFICATION_PREFERENCES, payload: response.data.data })
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: error?.response?.data?.error,
        module: 'dashboard',
       }));
    }
  };
};