/*
 *
 * Login constants
 *
 */

export const LOGIN_CHANGE = 'src/Login/LOGIN_CHANGE';
export const LOGIN_RESET = 'src/Login/LOGIN_RESET';
export const SET_LOGIN_LOADING = 'src/Login/SET_LOGIN_LOADING';
export const SET_LOGIN_FORM_ERRORS = 'src/Login/SET_LOGIN_FORM_ERRORS';
export const SET_LOGIN_SUBMITTING = 'src/Login/SET_LOGIN_SUBMITTING';
export const SET_TAB = 'src/Login/SET_TAB';
export const SET_RESEND_CONFIRMATION = 'src/Login/SET_RESEND_CONFIRMATION';
export const RESET_APP = 'src/Login/RESET_APP';
