import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import actions from "../../../actions";
import {Modal} from "react-bootstrap";

const OfficialStepTwo = (props) => {

    const {
        registration,
        setStep,
        sendEmailConfirmation,
        showCloseButton,
        registrationFinished,
        resetRegisterState,
        setRegistrationModalOpen
    } = props;

    const {t} = useTranslation();
    const [emailSent, setEmailSent] = useState(false);

    const handleOnClick = () => {
        setStep(4);
    }

    const handleSendEmailConfirmation = () => {
        sendEmailConfirmation();
    }

    const handleCloseModal = () => {
        setRegistrationModalOpen(false);
        setStep(1);
        resetRegisterState();
    }

    useEffect(() => {
        if (registrationFinished) {
            setEmailSent(true);
        }
    }, [registrationFinished]);

    return <>
        <Modal.Header closeButton={showCloseButton}>
            <Modal.Title className="w-100 text-center">
                {t(`Welcome to CivicBell`)}, {registration.registerFormData.firstName}!
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display: !emailSent ? 'block' : 'none'}}>
                <div className="form-group col-md-12">
                    {t(`Thank you for signing up! We have a ${registration.registerFormData.firstName} ${registration.registerFormData.lastName} from ${registration.registerFormData.district} on file!`)}
                </div>

                <div className="form-group col-md-12">
                    {t(`If you have access to the following email address, you can automatically verify your account by clicking a verification link we'll send to that address:`)}
                </div>

                <div className="form-group col-md-12">
                    <h6><span ><strong>{registration.registerFormData.officialEmail}</strong></span></h6>
                </div>

                <div className="form-group col-md-12" style={{textAlign: 'center'}}>
                    <button
                        type="button"
                        className="btn clsyellow-btn"
                        onClick={handleSendEmailConfirmation}
                        style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>{t(`Send me the link!`)}
                    </button>
                </div>

                <div className="form-group col-md-12" style={{textAlign: 'center'}}>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: 'rgb(61 61 151)'
                        }}
                        onClick={handleOnClick}
                    >{t(`I don't have access to this email`)}</span>
                </div>
            </div>

            <div style={{display: emailSent ? 'block' : 'none'}}>
                <div className="form-group col-md-12">
                    {t(`We have sent you a verification link. Please click it to verify your identity. Also check your spam folder in case you didn‘t receive it or reach out to`)}
                    <a href="mailto:support@civicbell.com">support@civicbell.com</a>
                </div>

                <div className="form-group col-md-12" style={{textAlign: 'center'}}>
                    <button
                        type="button"
                        className="btn clsyellow-btn"
                        onClick={handleCloseModal}
                        style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>{t(`Close`)}
                    </button>
                </div>
            </div>
        </Modal.Body>
    </>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        registrationFinished: state.registration.registerFormData.registrationFinished
    };
};

export default connect(mapStateToProps, actions)(OfficialStepTwo);