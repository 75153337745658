/*
 *
 * Discussion engagement actions
 *
 */

import {
  DISCUSSION_ENGAGEMENT_CHANGE,
  SET_DISCUSSION_ENGAGEMENT_DATA,
  DISCUSSION_ENGAGEMENT_COMMENT_CHANGE,
  SET_DISCUSSION_ENGAGEMENT_LOADING,
  SET_DISCUSSION_ENGAGEMENT_RELOAD,
  SET_DISCUSSION_ENGAGEMENT_COMMENTS_RELOAD,
  SET_DISCUSSION_ENGAGEMENT_CHILD_COMMENT_RELOAD,
  RESET_APP,
} from './constants';

import { BASE_API_URL } from '../../constants';
import axios from 'axios';
import { addNotification } from '../Notification/actions';
import { updateCivicList } from '../CivicFeed/actions';
import { setEngagementData } from '../Engagement/actions';

export const resetDiscussionEngagementState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const discussionEngagementChange = (id, name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[id] = getState().discussionEngagement.discussionEngagementData[id];
    formData[id][name] = value;
    formData[id]['error'] = '';
    return dispatch({ type: DISCUSSION_ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const setDiscussionEngagementData = (id, reloadStatus = true) => {
  let formData = {};
  return async (dispatch, getState) => {
    dispatch({ type: SET_DISCUSSION_ENGAGEMENT_LOADING, payload: true });
    await axios
      .get(BASE_API_URL + '/official/comment/' + id)
      .then((response) => {
        if (response.data.success === 'true') {
          let commentData = response.data.data;

          formData[id] = {
            id: id,
            openedId: false,
            title: 'Engagement Discussion',
            comments: commentData,
            dismissal: false,
            newComment: '',
            error: [],
          };

          dispatch({
            type: SET_DISCUSSION_ENGAGEMENT_DATA,
            payload: formData,
          });
          if (reloadStatus === true) {
            dispatch(setDiscussionReload(true));
          }
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'dashboard',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_DISCUSSION_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const getChildComments = (id, commentId) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[id] = getState().discussionEngagement.discussionEngagementData[id];
    dispatch({ type: SET_DISCUSSION_ENGAGEMENT_LOADING, payload: true });
    await axios
      .get(BASE_API_URL + '/official/comment/' + id + '/' + commentId)
      .then((response) => {
        if (response.data.success === 'true') {
          let commentData = commentFilter(
            id,
            commentId,
            formData[id]['comments'],
            response.data.data,
          );

          formData[id]['comments'] = [
            ...formData[id]['comments'],
            ...commentData,
          ];
          dispatch({ type: DISCUSSION_ENGAGEMENT_CHANGE, payload: formData });
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'dashboard',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_DISCUSSION_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const setReactions = (id, commentId, reaction, isFromLatestComment) => {
  let formData = {},
    commentData = {};
  return async (dispatch, getState) => {
    let userId = getState().account.user.userId;
    commentData['userId'] = userId;
    commentData['postId'] = id;
    commentData['commentId'] = commentId;
    commentData['reaction'] = reaction;

    dispatch({ type: SET_DISCUSSION_ENGAGEMENT_LOADING, payload: true });
    await axios
      .post(BASE_API_URL + '/official/comment/', commentData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        if (response.data.success === 'true') {
          let data = response.data.data;
          formData = {
            id: id,
            commentId: commentId,
            values: {
              likeReactionCount: data.likeReactionCount,
              dislikeReactionCount: data.dislikeReactionCount,
              userLikeReaction: data.userLikeReaction,
              userDisLikeReaction: data.userDisLikeReaction,
            },
          };
          dispatch({
            type: DISCUSSION_ENGAGEMENT_COMMENT_CHANGE,
            payload: formData,
          });
          //dispatch(setReload(true))
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'dashboard',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_DISCUSSION_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const commentFilter = (id, commentId, comments, childComments) => {
  let commentIds = [];
  commentIds = comments.map(function (comment, index) {
    return comment.id;
  });

  let commentData = childComments.filter(function (comment, index) {
    return commentIds.lastIndexOf(comment.id) === -1;
  });
  commentData.forEach((comment, index) => {
    if (typeof commentData[index].postComment === 'undefined') {
      commentData[index].postComment = '';
    }
    if (typeof commentData[index].postCommentError === 'undefined') {
      commentData[index].postCommentError = '';
    }
  });

  return commentData;
};

export const setOpenedId = (id, commentId) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[id] = getState().discussionEngagement.discussionEngagementData[id];
    if (typeof formData[id]['openedId'] === 'undefined') {
      formData[id]['openedId'] = commentId;
    } else if (formData[id]['openedId'] !== commentId) {
      formData[id]['openedId'] = commentId;
    } else {
      formData[id]['openedId'] = false;
    }
    return dispatch({ type: DISCUSSION_ENGAGEMENT_CHANGE, payload: formData });
  };
};

//DISCUSSION_ENGAGEMENT_COMMENT_CHANGE
export const changePostComment = (id, commentId, comment) => {
  return async (dispatch, getState) => {
    let formData = {
      id: id,
      commentId: commentId,
      values: { postComment: comment, postCommentError: '' },
    };
    return dispatch({
      type: DISCUSSION_ENGAGEMENT_COMMENT_CHANGE,
      payload: formData,
    });
  };
};

export const submitComment = (id, commentId = null) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData = getState().discussionEngagement.discussionEngagementData;

    let userId = getState().account.user.userId;
    let childComment = '';
    if (commentId !== null) {
      let index = formData[id]['comments'].findIndex(function (comment, i) {
        return comment.id === commentId;
      });

      if (
        typeof formData[id]['comments'][index]['postComment'] !== 'undefined'
      ) {
        childComment = formData[id]['comments'][index]['postComment'];
      }
    }

    let commentData = {};
    commentData['userId'] = userId;
    commentData['postId'] = id;
    commentData['parentId'] = commentId;
    commentData['comment'] =
      commentId === null ? formData[id].newComment : childComment;

    if (commentId === null) {
      if (
        commentData['comment'] === '' ||
        commentData['comment'].match(/^ *$/) !== null
      ) {
        let formData = {};
        formData[id] =
          getState().discussionEngagement.discussionEngagementData[id];
        formData[id]['error'] = 'Please enter a comment before posting.';
        return dispatch({
          type: DISCUSSION_ENGAGEMENT_CHANGE,
          payload: formData,
        });
      } else if (commentData['comment'].length > 500) {
        let formData = {};
        formData[id] =
          getState().discussionEngagement.discussionEngagementData[id];
        formData[id]['error'] = 'Please enter up to 500 characters';
        return dispatch({
          type: DISCUSSION_ENGAGEMENT_CHANGE,
          payload: formData,
        });
      }
    } else {
      if (
        commentData['comment'] === '' ||
        commentData['comment'].match(/^ *$/) !== null
      ) {
        let formData = {
          id: id,
          commentId: commentId,
          values: {
            postComment: commentData['comment'],
            postCommentError: 'Please enter a comment before posting.',
          },
        };
        return dispatch({
          type: DISCUSSION_ENGAGEMENT_COMMENT_CHANGE,
          payload: formData,
        });
      } else if (commentData['comment'].length > 500) {
        let formData = {
          id: id,
          commentId: commentId,
          values: {
            postComment: commentData['comment'],
            postCommentError: 'Please enter up to 500 characters',
          },
        };
        return dispatch({
          type: DISCUSSION_ENGAGEMENT_COMMENT_CHANGE,
          payload: formData,
        });
      }
    }

    await axios
      .post(BASE_API_URL + '/official/comment/', commentData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(async (response) => {
        if (response.data.success === 'true') {
          dispatch(setEngagementData(id));
          dispatch(setDiscussionEngagementData(id)).then(() => {
            if (commentData['parentId'] !== null) {
              dispatch(
                loadChildComments({
                  status: true,
                  id: commentData['parentId'],
                }),
              );
            }
            dispatch({
              type: SET_DISCUSSION_ENGAGEMENT_COMMENTS_RELOAD,
              payload: true,
            });
            dispatch({
              type: SET_DISCUSSION_ENGAGEMENT_LOADING,
              payload: false,
            });
            dispatch(changePostComment(id, commentId, ''));
          });
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'dashboard',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_DISCUSSION_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const setDiscussionReload = (value) => {
  return async (dispatch, getState) => {
    if (value === true) {
      setReloadComments(value);
    }
    dispatch({ type: SET_DISCUSSION_ENGAGEMENT_RELOAD, payload: value });
  };
};

export const setReloadComments = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DISCUSSION_ENGAGEMENT_COMMENTS_RELOAD,
      payload: value,
    });
  };
};

export const loadChildComments = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DISCUSSION_ENGAGEMENT_CHILD_COMMENT_RELOAD,
      payload: value,
    });
  };
};
