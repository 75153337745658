/**
 *
 * Profile
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import ProfileLegal from '../../components/Manager/Profile/profileLegal';

const Legal = (props) => {
  return (
    <>
      <ProfileLegal {...props} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(Legal);
