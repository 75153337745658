/*
 *
 * Public Official Register Form
 *
 */

import React, { useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
//import { registerChange, districtBoxChange, getDistrictData } from '../../../containers/Register/actions';
import SuggestionBox from './suggestionBox';
import CivicModal from '../../Common/CivicModal';
import EmailConfirmationFooter from './emailConfirmationFooter';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const suggestData = debounce((func) => {
  func();
}, 500);

const PublicOffiicalSecondStep = (props) => {
  const {
    registerFormData,
    registerChange,
    registerFormErrors,
    getDistrictData,
    districtBoxChange,
  } = props;
  const { t } = useTranslation();

  const hashUrl = '#';

  const onDistrictChange = useCallback(() => {
    registerChange('ocdId', '');
    if (registerFormData.district.length > 0) {
      districtBoxChange('display', true);
      suggestData(getDistrictData);
    }
  }, [registerFormData, getDistrictData, districtBoxChange, registerChange]);

  useEffect(() => {
    if (registerFormData.district === 0) {
      districtBoxChange('display', false);
    }
  }, [registerFormData, districtBoxChange]);

  const openDistrictBox = () => {
    if (registerFormData.district > 0) {
      districtBoxChange('display', true);
    }
  };

  useEffect(() => {
    if (registerFormData.showEmailConfirmation === true) {
      $('#email-confirmation-modal').modal({
        backdrop: 'static',
        keyboard: false,
      });
    }
  }, [registerFormData.showEmailConfirmation]);

  return (
    <div className="Second-Step" key={'official-second-step'}>
      <h3 className="text-center pb-5">
        {t(`Welcome, please fill out info below to finish your registration.`)}
      </h3>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label>{`${t('Name')}*`}</label>
          <input
            type="text"
            className="form-control"
            placeholder="First"
            name={'firstName'}
            value={registerFormData.firstName}
            onChange={(e) => {
              registerChange(e.target.name, e.target.value);
            }}
          />
          {typeof registerFormErrors !== 'undefined' &&
            registerFormErrors.firstName && (
              <div className="clsinvalid">
                {t(registerFormErrors?.['firstName'])}
              </div>
            )}
        </div>
        <div className="form-group col-md-6">
          <label className="d-none d-md-block">&nbsp;</label>
          <input
            type="text"
            className="form-control"
            placeholder={t(`Last`)}
            name={'lastName'}
            value={registerFormData.lastName}
            onChange={(e) => {
              registerChange(e.target.name, e.target.value);
            }}
          />
          {typeof registerFormErrors !== 'undefined' &&
            registerFormErrors.lastName && (
              <div className="clsinvalid">{t(registerFormErrors?.['lastName'])}</div>
            )}
        </div>
        <div className="form-group col-md-12">
          <label>{`${t('Position')}*`}</label>
          <input
            type="text"
            className="form-control"
            placeholder={t(`Enter Position`)}
            name={'position'}
            value={registerFormData.position}
            onChange={(e) => registerChange(e.target.name, e.target.value)}
          />
          {typeof registerFormErrors !== 'undefined' &&
            registerFormErrors.position && (
              <div className="clsinvalid">{t(registerFormErrors?.['position'])}</div>
            )}
        </div>
        <div className="form-group col-md-12 district-suggestion-box">
          <label>{`${t('My District')}*`}</label>
          <input
            type="text"
            id={`district`}
            className="form-control"
            placeholder={t(`Enter District`)}
            name={'district'}
            value={registerFormData.district}
            autoComplete={`off`}
            onChange={(e) => {
              registerChange(e.target.name, e.target.value);
              onDistrictChange();
            }}
            onFocus={openDistrictBox}
          />
          <figure
            className={'clssearchclose-icon'}
            style={{
              display:
                registerFormData.suggestionBox.display === true
                  ? 'block'
                  : 'none',
            }}
          >
            <a
              href={hashUrl}
              onClick={(e) => {
                districtBoxChange('display', false);
                e.preventDefault();
              }}
            >
              <img
                src={
                  require('../../../assets/img/search-close-icon.svg').default
                }
                alt="Close"
              />
            </a>
          </figure>
          {typeof registerFormErrors !== 'undefined' &&
            registerFormErrors.district && (
              <div className="clsinvalid">{t(registerFormErrors?.['district'])}</div>
            )}
          <SuggestionBox {...props} />
        </div>
        {registerFormData.manualVerification === true ? (
          <>
            <div className="form-group col-md-12">
              <label>{`${t('Official Email')}*`}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t(`Enter Official Email`)}
                name={'newEmail'}
                value={registerFormData.newEmail}
                onChange={(e) => registerChange(e.target.name, e.target.value)}
              />
              {typeof registerFormErrors !== 'undefined' &&
                registerFormErrors.newEmail && (
                  <div className="clsinvalid">
                    {t(registerFormErrors?.['newEmail'])}
                  </div>
                )}
            </div>
            <div className="form-group col-md-12">
              <label>{`${t('Phone Number')}*`}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t(`Phone Number`)}
                name={'phoneNumber'}
                value={registerFormData.phoneNumber}
                onChange={(e) => registerChange(e.target.name, e.target.value)}
                onFocus={(e) => registerChange(e.target.name, e.target.value)}
              />
              {typeof registerFormErrors !== 'undefined' &&
                registerFormErrors.phoneNumber && (
                  <div className="clsinvalid">
                    {t(registerFormErrors?.['phoneNumber'])}
                  </div>
                )}
            </div>
          </>
        ) : (
          ''
        )}
        <CivicModal
          id={`email-confirmation-modal`}
          title={t(`Email Confirmation`)}
          dismissal={false}
          footer={EmailConfirmationFooter(props)}
          footerClass={`text-center mt-4`}
          hideClose={true}
        >
          <p
            className={`email-confirmation-txt`}
          >{`We have ${registerFormData.poVerifiedEmail} as your official email address on record. Do you have access to that email to verify your identity?`}</p>
        </CivicModal>
      </div>
    </div>
  );
};

export default PublicOffiicalSecondStep;
