import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disableFirstLogin } from "../containers/Dashboard/actions";
import {
  loadRegisterData,
  setAccountConfirmationModalOpen,
  setRegistrationModalOpen,
  setStep
} from "../containers/RegisterFlow/actions";

export const useUserModals = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.account.user);
  const authenticated = useSelector((state) => state.authentication.authenticated)
  const registration = useSelector((state) => state.registration);
  const isRegisterSubmitting = useSelector((state) => state.registration.isRegisterSubmitting);

  useEffect(() => {
    if (authenticated && user && !user.isRegistrationComplete) {
      if (!isRegisterSubmitting) {
        if (user.roleName && (user.roleName !== 'PUBLICOFFICIAL' && user.roleName !== 'ADMIN')) {
          dispatch(loadRegisterData());

          if (!user.isVerified) {
            dispatch(setStep(2));
            dispatch(setRegistrationModalOpen(true));
          } else if (!user.first_name && !user.last_name) {
            dispatch(setStep(3));
            dispatch(setRegistrationModalOpen(true));
          }
          // } else {
          //   dispatch(setStep(3));
          //   dispatch(setRegistrationModalOpen(true));
          // }
        }
      }
    }
  }, [user.roleName, authenticated, isRegisterSubmitting, user.isVerified, user.isRegistrationComplete, user.first_name, user.last_name])

  useEffect(() => {
    if (authenticated && user && user.isRegistrationComplete) {
      dispatch(disableFirstLogin());
    }

    if (authenticated && registration.registerFormData.step === 1 && user.first_name && user.last_name &&
        user.isVerified === true && user.isIdentityVerified === false && user.isRegistrationComplete === false) {
      dispatch(setAccountConfirmationModalOpen(true));
    }
  }, [authenticated, registration.registerFormData.step, user.isVerified, user.isIdentityVerified, user.isRegistrationComplete]);

  useEffect(() => {
    if (user.isRegistrationComplete) {
      dispatch(setRegistrationModalOpen(false));
    }
  }, [user.isRegistrationComplete])
};