/*
 *
 * Events
 *
 */

import React, { useEffect, useRef, useState, useCallback } from 'react';
import Events from '../../components/Manager/Events';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Filter from '../../components/Manager/Events/filter';
import Notification from '../../containers/Notification';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const AllEventsList = (props) => {
  const {
    activeEventTab,
    setEventTab,
    getCivicEvents,
    events,
    noRecords,
    reloadList,
    resetResult,
    searchSubmission,
    reloadCivicEvent,
    searchResultReset,
    eventChangeByOption,
    user,
    eventsLength,
    location,
    resetEventFilter,
    resetCalendar,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [eventListComponent, setEventListComponent] = useState('');

  const [page, setPage] = useState(1);

  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const prevUri = usePrevious(uri);

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setEventListComponent(
          <Component {...props} eventChangeByOption={eventChangeByOption} />,
        );
        return resolve(true);
      });
    },
    [setEventListComponent, props, eventChangeByOption],
  );

  const fetchEvents = useCallback(async () => {
    setInProcess(true);
    await getCivicEvents(page).then(async (noRecordStatus) => {
      await renderComponent(Events).then(() => {
        if (!noRecordStatus) {
          setPage(page + 1);
        }
      });
    });
    setInProcess(false);
  }, [getCivicEvents, renderComponent, setPage, page]);

  useEffect(() => {
    if (loading === true) {
      setEventListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        const fetchEvents = async () => {
          if (uri !== prevUri && prevUri !== 'undefined') {
            await resetEventFilter();
            await resetCalendar();
          }
          await getCivicEvents().then(() => {
            setLoading(false);
            setInProcess(false);
          });
        };
        fetchEvents();
      }
    }
  }, [
    loading,
    setLoading,
    setEventTab,
    activeEventTab,
    getCivicEvents,
    renderComponent,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    events,
    resetEventFilter,
    prevUri,
    uri,
    resetCalendar,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(Events);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setPage, setLoading, setInitialLoad]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await reloadCivicEvent();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, reloadCivicEvent],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    renderComponent(Events);
  }, [user, renderComponent, page]);

  const EventListContent = (
    <>
      <Notification module={'event'} />
      <Filter {...props} />
      {eventListComponent}
      {page === 2 && !noRecords ? (
        <div style={{ minHeight: '200px' }}></div>
      ) : (
        ''
      )}
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={eventsLength}
            next={fetchEvents}
            hasMore={true}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
        </>
      )}
    </>
  );

  return <>{EventListContent}</>;
};

export default AllEventsList;
