/*
 *
 * Petition
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import ActiveExpirePetition from './activeExpirePetition';
import MyPetitionList from './myPetitionList';
import { useTranslation } from 'react-i18next';

const PetitionLists = (props) => {
  const {
    activePetitionTab,
    setPetitionTab,
    setMyPetitionTab,
    user,
    resetPetitionFilter,
  } = props;

  const { t } = useTranslation();
  const resetTabContent = (tab) => {
    setPetitionTab(tab);
    setMyPetitionTab('created-petitions');
  };

  return (
    <>
      {user.roleName === 'RESIDENT' ? (
        <div className="clsall-events-tab">
          <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={
                  activePetitionTab === 'all-petitions'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id="all-petitions-tab"
                data-toggle="tab"
                href="#all-petitions"
                role="tab"
                aria-controls="all-petitions"
                onClick={() => {
                  resetTabContent('all-petitions');
                  resetPetitionFilter();
                }}
                aria-selected={
                  activePetitionTab === 'all-petitions' ? 'true' : 'false'
                }
              >
                {t('Active Initiatives')}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  activePetitionTab === 'my-petitions'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id="my-petitions-tab"
                data-toggle="tab"
                href="#my-petitions"
                role="tab"
                aria-controls="my-petitions"
                onClick={() => {
                  resetTabContent('my-petitions');
                  resetPetitionFilter();
                }}
                aria-selected={
                  activePetitionTab === 'my-petitions' ? 'true' : 'false'
                }
              >
                {t('My Initiatives')}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  activePetitionTab === 'expired-petitions'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id="expired-petitions-tab"
                data-toggle="tab"
                href="#expired-petitions"
                role="tab"
                aria-controls="expired-petitions"
                onClick={() => {
                  resetTabContent('expired-petitions');
                  resetPetitionFilter();
                }}
                aria-selected={
                  activePetitionTab === 'expired-petitions' ? 'true' : 'false'
                }
              >
                {t('Expired Initiatives')}
              </a>
            </li>
            {/* <li className="nav-item" role="presentation">
              <a className={(activePetitionTab === "signed-petitions") ? "nav-link active" : "nav-link"} id="signed-petitions-tab" data-toggle="tab" href="#signed-petitions" role="tab" aria-controls="signed-petitions" onClick={() => { resetTabContent('signed-petitions');resetPetitionFilter()}} aria-selected={(activePetitionTab === "signed-petitions") ? "true" : "false"}>Signed Initiatives</a>
            </li> */}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={
                activePetitionTab === 'all-petitions'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="all-petitions"
              role="tabpanel"
              aria-labelledby="all-petitions-tab"
            >
              {activePetitionTab === 'all-petitions' ? (
                <ActiveExpirePetition {...props} />
              ) : (
                ''
              )}
            </div>
            <div
              className={
                activePetitionTab === 'my-petitions'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="my-petitions"
              role="tabpanel"
              aria-labelledby="my-petitions-tab"
            >
              {activePetitionTab === 'my-petitions' ? (
                <MyPetitionList {...props} />
              ) : (
                ''
              )}
            </div>
            <div
              className={
                activePetitionTab === 'expired-petitions'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="expired-petitions"
              role="tabpanel"
              aria-labelledby="expired-petitions-tab"
            >
              {activePetitionTab === 'expired-petitions' ? (
                <ActiveExpirePetition {...props} />
              ) : (
                ''
              )}
            </div>
            {/* <div className={(activePetitionTab === "signed-petitions") ? "tab-pane fade show active" : "tab-pane fade"} id="signed-petitions" role="tabpanel" aria-labelledby="signed-petitions-tab">
              {(activePetitionTab === "signed-petitions") ? PetitionListContent : ''}
            </div> */}
          </div>
        </div>
      ) : user.roleName === 'PUBLICOFFICIAL' ? (
        <div className="clsall-events-tab">
          <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={
                  activePetitionTab === 'all-petitions'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id="all-petitions-tab"
                data-toggle="tab"
                href="#all-petitions"
                role="tab"
                aria-controls="all-petitions"
                onClick={() => {
                  resetTabContent('all-petitions');
                  resetPetitionFilter();
                }}
                aria-selected={
                  activePetitionTab === 'all-petitions' ? 'true' : 'false'
                }
              >
                {t('Active Initiatives')}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  activePetitionTab === 'expired-petitions'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id="expired-petitions-tab"
                data-toggle="tab"
                href="#expired-petitions"
                role="tab"
                aria-controls="expired-petitions"
                onClick={() => {
                  resetTabContent('expired-petitions');
                  resetPetitionFilter();
                }}
                aria-selected={
                  activePetitionTab === 'expired-petitions' ? 'true' : 'false'
                }
              >
                {t('Expired Initiatives')}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={
                activePetitionTab === 'all-petitions'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="all-petitions"
              role="tabpanel"
              aria-labelledby="all-petitions-tab"
            >
              {activePetitionTab === 'all-petitions' ? (
                <ActiveExpirePetition {...props} />
              ) : (
                ''
              )}
            </div>
            <div
              className={
                activePetitionTab === 'expired-petitions'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="expired-petitions"
              role="tabpanel"
              aria-labelledby="expired-petitions-tab"
            >
              {activePetitionTab === 'expired-petitions' ? (
                <ActiveExpirePetition {...props} />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    blocks: state.account.blocks,
    petitionLists: state.petition.petitionLists,
    petitionFormErrors: state.petition.petitionFormErrors,
    petitionFormData: state.petition.petitionFormData,
    noRecords: state.petition.noRecords,
    reloadList: state.petition.reloadList,
    pageLoader: state.petition.pageLoader,
    sort: state.petition.sort,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    filterDismissal: state.petition.filterDismissal,
    filter: state.petition.filter,
    filterSelected: state.petition.filterSelected,
    searchBox: state.petition.searchBox,
    suggestionBox: state.petition.suggestionBox,
    isPetitionLoading: state.petition.isPetitionLoading,
    activePetitionTab: state.petition.activePetitionTab,
    petitionDivisionData: state.petition.petitionDivisionData,
    divisionSelected: state.petition.divisionSelected,
    myPetitions: state.petition.myPetitions,
    activeMyPetitionTab: state.petition.activeMyPetitionTab,
    filterDivision: state.petition.filterDivision,
    districtSelected: state.petition.districtSelected,
  };
};

export default connect(mapStateToProps, actions)(PetitionLists);
