import React from "react";

const MouseClickIcon = () => {
  return (
    <img
      src={require('../../../assets/img/mouse-click.svg').default}
      alt="Using your mouse"
      width={25}
      height={25}
    />
  )
};

const InsuranceIcon = () => {
  return (
    <img
      src={require('../../../assets/img/insurance.svg').default}
      alt="Using your mouse"
      width={17}
      height={17}
    />
  )
}

export {
  MouseClickIcon,
  InsuranceIcon
};