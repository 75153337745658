/*
 *
 * Analytics constants
 *
 */
import i18next from 'i18next';

export const SET_ANALYTICS_LOADING = 'src/Analytics/SET_ANALYTICS_LOADING';
export const SET_ANALYTICS_DATA = 'src/Analytics/SET_ANALYTICS_DATA';
export const ANALYTICS_CHANGE = 'src/Analytics/ANALYTICS_CHANGE';
export const SET_ANALYTICS_TAB = 'src/Analytics/SET_ANALYTICS_TAB';

export const SET_DISCUSSION_ANALYTICS_DATA =
  'src/Analytics/SET_DISCUSSION_ANALYTICS_DATA';
export const SET_SURVEY_ANALYTICS_DATA =
  'src/Analytics/SET_SURVEY_ANALYTICS_DATA';
export const SET_POSTVIEW_ANALYTICS_DATA =
  'src/Analytics/SET_POSTVIEW_ANALYTICS_DATA';
export const SET_QUESTION_ANALYTICS_DATA =
  'src/Analytics/SET_QUESTION_ANALYTICS_DATA';

export const CHANGE_DISCUSSION_TAB_DATA =
  'src/Analytics/CHANGE_DISCUSSION_TAB_DATA';
export const CHANGE_SURVEY_TAB_DATA = 'src/Analytics/CHANGE_SURVEY_TAB_DATA';
export const CHANGE_POSTVIEW_TAB_DATA =
  'src/Analytics/CHANGE_POSTVIEW_TAB_DATA';
export const CHANGE_QUESTION_TAB_DATA =
  'src/Analytics/CHANGE_QUESTION_TAB_DATA';
export const SET_ACTIVE_QUESTION_ID = 'src/Analytics/SET_ACTIVE_QUESTION_ID';
export const RESET_APP = 'src/Analytics/RESET_APP';

export const weightedOptions = [
  { value: 'resultOnly', label: 'Results only', translationParsing: i18next.t('Results only') },
  { value: 'censusComparision', label: 'Census comparison', translationParsing: i18next.t('Census comparison') },
  { value: 'voterRegistrationComparision', label: 'Voter registration comparison', translationParsing: i18next.t('Viter registration comparison') },
];

export const viewByOptionList = [
  { value: 'ethnicity', label: 'Race / Ethnicity', disabled: false, translationParsing: i18next.t('Race / Ethnicity') },
  { value: 'age', label: 'Age', disabled: false, translationParsing: i18next.t('Age') },
  { value: 'gender', label: 'Gender', disabled: false, translationParsing: i18next.t('Gender') },
  { value: 'education', label: 'Highest Education Degree', disabled: false, translationParsing: i18next.t('Highest education Degree') },
  { value: 'employment_status', label: 'Employment Status', disabled: false, translationParsing: i18next.t('Employment Status') },
  { value: 'marital_status', label: 'Marital Status', disabled: false, translationParsing: i18next.t('Marital Status') },
  { value: 'political_affiliation', label: 'Political Orientation', disabled: false, translationParsing: i18next.t('Political Orientation') },
  { value: 'party_orientation', label: 'Party Orientation', disabled: false, translationParsing: i18next.t('Party Orientation')  },
  { value: 'religion', label: 'Religion', disabled: false, translationParsing: i18next.t('Religion')  },
];

/*export const discussionViewByOptions = [
    {value: 'ethnicity', label: 'Race / Ethnicity', disabled: false},
    {value: 'age', label: 'Age', disabled: false},
    {value: 'gender', label: 'Gender', disabled: false},
    {value: 'education', label: 'Highest Education Degree', disabled: false},
    {value: 'employment_status', label: 'Employment Status', disabled: false},
    {value: 'marital_status', label: 'Marital Status', disabled: false},
    {value: 'political_affiliation', label: 'Political Affiliation', disabled: false},
    {value: 'party_orientation', label: 'Party Orientation', disabled: false},
    {value: 'religion', label: 'Religion', disabled: false}
];

export const surveyViewByOptions = [
    {value: 'ethnicity', label: 'Race / Ethnicity', disabled: false},
    {value: 'age', label: 'Age', disabled: false},
    {value: 'gender', label: 'Gender', disabled: false},
    {value: 'education', label: 'Highest Education Degree', disabled: false},
    {value: 'employment_status', label: 'Employment Status', disabled: false},
    {value: 'marital_status', label: 'Marital Status', disabled: false},
    {value: 'political_affiliation', label: 'Political Affiliation', disabled: false},
    {value: 'party_orientation', label: 'Party Orientation', disabled: false},
    {value: 'religion', label: 'Religion', disabled: false}
];

export const postViewByOptions = [
    {value: 'ethnicity', label: 'Race / Ethnicity', disabled: false},
    {value: 'age', label: 'Age', disabled: false},
    {value: 'gender', label: 'Gender', disabled: false},
    {value: 'education', label: 'Highest Education Degree', disabled: false},
    {value: 'employment_status', label: 'Employment Status', disabled: false},
    {value: 'marital_status', label: 'Marital Status', disabled: false},
    {value: 'political_affiliation', label: 'Political Affiliation', disabled: false},
    {value: 'party_orientation', label: 'Party Orientation', disabled: false},
    {value: 'religion', label: 'Religion', disabled: false}
];

export const participationOptions = [
    {value: 'all', label: 'All'},
    {value: 'active', label: 'Active'},
    {value: 'passive', label: 'Passive'}
];*/

export const questionFilterOptions = [
  { value: 'ethnicity', label: 'Race / Ethnicity', translationParsing: i18next.t('Race / Ethnicity') },
  { value: 'age', label: 'Age', translationParsing: i18next.t('Age')  },
  { value: 'gender', label: 'Gender', translationParsing: i18next.t('Gender')  },
  { value: 'education', label: 'Highest Education Degree', translationParsing: i18next.t('Highest Education Degree') },
  { value: 'employment_status', label: 'Employment Status', translationParsing: i18next.t('Employment Status') },
  { value: 'marital_status', label: 'Marital Status', translationParsing: i18next.t('Marital Status') },
  { value: 'political_affiliation', label: 'Political Orientation', translationParsing: i18next.t('Political Orientation') },
  { value: 'religion', label: 'Religion', translationParsing: i18next.t('Religion') },
];
