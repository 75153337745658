/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import actions from "../../../actions";
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import dayjs from 'dayjs';
import CreateEditIssueControls from './CreateEditIssueControls';
import { createCommunityIssue } from '../../../containers/CommunityIssues/actions';
import useS3 from '../../../utils/useS3';
import { useHistory } from 'react-router-dom';

const CreateIssue = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { uploadFileToS3 } = useS3();
  const history = useHistory();

  const [isExpandedPart1, setIsExpandedPart1] = useState(false);
  const [isExpandedPart2, setIsExpandedPart2] = useState(false);
  const [invalidateSize, setInvalidateSize] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(0);

  const [selectedAreaOption, setSelectedAreaOption] = useState('ocdId'); // ['ocdId', 'area']

  // Add 90 days from today for default closeDate
  const defaultCloseDate = dayjs().add(90, 'day');
  const [closeDate, setCloseDate] = useState(defaultCloseDate);

  const [title, setTitle] = useState('');
  const [textContent, setTextContent] = useState('');
  const [category, setCategory] = useState(undefined);
  const [coordinates, setCoordinates] = useState([]);
  const [mainImageFiles, setMainImageFiles] = useState([]);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [reloadToggle, setReloadToggle] = useState(0);
  const [district, setDistrict] = useState(undefined);

  const [errors, setErrors] = useState({});

  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const originalLanguage = useSelector(state => state.account.user.selected_language);
  const isCommunityIssuesLoading = useSelector(state => state.communityIssues.isCommunityIssuesLoading);

  const reset = () => {
    setTitle('');
    setTextContent('');
    setCategory(undefined);
    setCloseDate(defaultCloseDate);
    setCoordinates([]);
    setIsExpandedPart1(false);
    setIsExpandedPart2(false);
    setReloadToggle(prev => prev + 1);
    setDistrict(undefined);
    setMainImageFiles([]);
    setAdditionalFiles([]);
  }

  // Clear errors
  useEffect(() => {
    if (title) {
      setErrors(prev => ({ ...prev, title: undefined }));
    }
    if (textContent) {
      setErrors(prev => ({ ...prev, textContent: undefined }));
    }
    if (category) {
      setErrors(prev => ({ ...prev, category: undefined }));
    }
    if (coordinates.length > 0) {
      setErrors(prev => ({ ...prev, coordinates: undefined }));
    }
    if (district) {
      setErrors(prev => ({ ...prev, districts: undefined }));
    } 
    if (mainImageFiles.length > 0) {
      setErrors(prev => ({ ...prev, mainImage: undefined }));
    }
    if (additionalFiles.length > 0) {
      setErrors(prev => ({ ...prev, additionalFiles: undefined }));
    }
  }, [title, textContent, category, coordinates, district, mainImageFiles, additionalFiles]);

  const TITLE_LENGTH_LIMIT = 100;

  const validateIssue = () => {
    // Validate
    const errors = {};
    if (!title) {
      errors.title = t(`Please enter a title for your issue`);
    }
    if (title.length >= TITLE_LENGTH_LIMIT) {
      errors.title = t(`Title cannot be longer than`) + ' ' + TITLE_LENGTH_LIMIT + ' ' + t('characters');
    }
    if (!category) {
      errors.category = t(`Please select a category for your issue`);
    }
    if (selectedAreaOption === 'area' && coordinates.length < 3) {
      errors.coordinates = t(`Please select an area for your issue`);
    } else if (selectedAreaOption === 'ocdId' && !district) {
      errors.districts = t(`Please select a district for your issue`);
    }

    // Check if mainImage or additionalFiles are too large (over 10 MB)
    if (mainImageFiles.length > 0 && mainImageFiles[0].size > 10 * 1024 * 1024) {
      errors.mainImage = t(`Main image cannot be larger than 10 MB`);
    } 
    if (additionalFiles.length > 0 && additionalFiles.some(file => file.size > 10 * 1024 * 1024)) {
      errors.additionalFiles = t(`Additional files cannot be larger than 10 MB`);
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    }

    return true;
  }

  const errorTitleRef = useRef(null);
  const errorCategoryRef = useRef(null);
  const errorCoordinatesRef = useRef(null);
  const errorDistrictsRef = useRef(null);

  useEffect(() => {
    if (errors.title) {
      errorTitleRef?.current?.scrollIntoView();
    }
    else if (errors.category) {
      errorCategoryRef?.current?.scrollIntoView();
    }
    else if (errors.coordinates) {
      errorCoordinatesRef?.current?.scrollIntoView();
    }
    else if (errors.districts) {
      errorDistrictsRef?.current?.scrollIntoView();
    }
  }, [errors]);

  const submitIssue = async () => {
    if (!validateIssue()) {
      return;
    }

    const mainImageUrl = mainImageFiles.length > 0 ? await uploadFileToS3(mainImageFiles[0]) : undefined;
    const additionalImageUrls = additionalFiles.length > 0 ? await Promise.all(additionalFiles.map(async (file) => await uploadFileToS3(file))) : undefined;

    // Submit
    const validatedIssue = {
      title,
      textContent,
      category: category.value,
      closingDate: closeDate.format('YYYY-MM-DD'),
      originalLanguage: originalLanguage || 'EN',
      mainImage: mainImageUrl,
      attachedDocuments: additionalImageUrls
    };

    if (selectedAreaOption === 'area') {
      validatedIssue.area = coordinates;
    } else {
      validatedIssue.ocdId = district.value;
    }

    dispatch(createCommunityIssue(validatedIssue)).then((res) => {
      reset();
      history.push(`/community-issues/?id=${res}`);
    });
  }

  // Handles the continue button
  const onContinue = () => {
    if (!isExpandedPart1) {
      setIsExpandedPart1(true);
    } else if (!isExpandedPart2) {
      setIsExpandedPart2(true);
    } else {
      submitIssue();
    }
  }

  const resizeElements = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }

    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }

  useEffect(() => {
    resizeElements();
  }, [containerRef.current, buttonRef.current]);

  useEffect(() => {
    window.addEventListener('resize', resizeElements);
    return () => {
      window.removeEventListener('resize', resizeElements);
    }
  }, []);

  useEffect(() => {
    if (isExpandedPart2) {
      // Toggle invalidateSize semi-frequently over 500 ms to ensure the map is resized properly
      const interval = setInterval(() => {
        setInvalidateSize(prev => !prev);
      }, 10);

      setTimeout(() => {
        clearInterval(interval);
      }, 500);
    }
  }, [isExpandedPart2]);

  const collapse = () => {
    setIsExpandedPart1(false);
    setIsExpandedPart2(false);
  }

  return (
    <CreateEditIssueControls>
      <div ref={errorTitleRef} />
      <div className="community-issues-create-container" ref={containerRef}>
        <div className="community-issues-create-header">
          <div className="community-issues-create-title"><strong>{t(`Start a new Community Issue for your area`)}</strong></div>
          <div onClick={isExpandedPart1 ? collapse : onContinue} style={{ cursor: 'pointer' }}>
            {isExpandedPart1 ? <ExpandLessIcon style={{ color: '#666' }} /> : <ExpandMoreIcon style={{ color: '#666' }} />}
          </div>
        </div>

        <CreateEditIssueControls.Title 
          title={title} 
          setTitle={setTitle} 
          error={errors.title} 
          animate={{
            isExpanded: isExpandedPart1,
            setIsExpanded: setIsExpandedPart1,
            containerWidth,
            buttonWidth
          }}
        />

      <motion.div animate={{ height: isExpandedPart1 ? 'auto' : 0, display: isExpandedPart1 ? 'block' : 'none' }}>
        <CreateEditIssueControls.Description
          setDescription={setTextContent}
          error={errors.textContent}
          reloadToggle={reloadToggle}
          animate={{
            isExpanded: isExpandedPart1,
          }}
        />
        <div ref={errorCategoryRef} />
        <div ref={errorCoordinatesRef} />
        <div ref={errorDistrictsRef} />
        <CreateEditIssueControls.UploadMainImage
          files={mainImageFiles}
          setFiles={setMainImageFiles}
          error={errors.mainImage}
          animate={{
            isExpanded: isExpandedPart2,
            containerWidth,
            buttonWidth
          }}
        />
      </motion.div>

      <motion.div animate={{ height: isExpandedPart2 ? 'auto' : 0, display: isExpandedPart2 ? 'block' : 'none' }} style={{ marginTop: isExpandedPart2 ? 30 : 0 }}>
        <CreateEditIssueControls.CategorySelect
          category={category}
          setCategory={setCategory}
          error={errors.category}
        />

        <CreateEditIssueControls.ClosingDatePicker
          closeDate={closeDate}
          setCloseDate={setCloseDate}
          defaultCloseDate={defaultCloseDate}
        />

        <CreateEditIssueControls.SelectAreaOrDistrict
          selectedAreaOption={selectedAreaOption}
          setSelectedAreaOption={setSelectedAreaOption}
        />
        
        {selectedAreaOption === 'area' && (
          <CreateEditIssueControls.DrawArea
            setCoordinates={setCoordinates}
            reloadToggle={reloadToggle}
            error={errors.coordinates}
            setError={(error) => setErrors(prev => ({ ...prev, coordinates: error }))}
            invalidateSize={invalidateSize}
          />
        )}

        {selectedAreaOption === 'ocdId' && (
          <CreateEditIssueControls.DistrictSelect
            district={district}
            setDistrict={setDistrict}
            error={errors.districts}
          />
        )}

        <CreateEditIssueControls.UploadAdditionalFiles
          files={additionalFiles}
          setFiles={setAdditionalFiles}
          error={errors.additionalFiles}
        />
      </motion.div>

        <button 
          ref={buttonRef} 
          onClick={onContinue} 
          type="button" 
          className="btn clsyellow-btn community-issues-cont-btn" 
          style={{ width: isExpandedPart2 ? containerWidth - 30 : undefined }}
          disabled={isCommunityIssuesLoading}
        >
          { isExpandedPart1 && isExpandedPart2 ? t(`Launch community issue`) : t(`Continue`) }
        </button>
      </div>
    </CreateEditIssueControls>
  )
};

const mapStateToProps = (state) => {
  return {

  }
}

export default connect(mapStateToProps, actions)(CreateIssue);