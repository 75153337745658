/**
 *
 * Engagement Footer Block
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import CloseEngagement from '../../../containers/CloseEngagement';
import CompleteEngagement from '../../../containers/CompleteEngagement';
import NewEngagement from './engagementForm';
import EngagementReactions from '../../../containers/Reactions';
import DiscussionEngagement from '../../../containers/DiscussionEngagement';
import { useHistory } from 'react-router-dom';
import CivicModal from '../../Common/CivicModal';
import Tooltip from '../../Common/Tooltip';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const EngagementFooter = (props) => {
  const {
    engagement,
    status,
    user,
    updateEngagementStatus,
    setEngagementFormData,
    setReloadEngagementStatus,
    followPost,
    setEmotions,
    location,
    getEngagements,
    discussionEngagementData,
    civicFeedEngagements,
    engagementSpecific,
    authenticated,
  } = props;
  const { t } = useTranslation();
  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const [followVisibility, setFollowVisibility] = useState(false);
  const [analyticsVisibility, setAnalyticsVisibility] = useState(false);
  const history = useHistory();
  const language = i18next.language;

  useEffect(() => {
    if (
      uri === 'dashboard/civicfeed' ||
      uri === 'dashboard/user-profile' ||
      uri === 'dashboard/civiclist' ||
      uri === 'dashboard/engagement'
    ) {
      setFollowVisibility(true);
      setAnalyticsVisibility(false);
    } else {
      setFollowVisibility(false);
      setAnalyticsVisibility(true);
    }
  }, [setFollowVisibility, setAnalyticsVisibility, uri]);

  //Close Engagement
  const [closeComponent, setCloseComponent] = useState('');
  const [closeComponentloaded, setCloseComponentLoaded] = useState(false);

  //Edit Engagement
  const [editEngagementComponent, setEditEngagementComponent] = useState('');
  const [engagementComponentloaded, setEngagementComponentloaded] =
    useState(false);

  //Complete Engagement
  const [completeComponent, setCompleteComponent] = useState('');
  const [completeComponentloaded, setCompleteComponentLoaded] = useState(false);

  //Reaction Component
  const [reactComponent, setReactComponent] = useState('');
  const [reactComponentLoaded, setReactComponentLoaded] = useState(false);

  //Discussion Engagement
  const [discussionComponent, setDiscussionComponent] = useState('');
  const [discussionComponentloaded, setDiscussionComponentLoaded] = useState(false);

  const hashUrl = '#';

  const closeModalRef = useRef(null);
  const engagementModalRef = useRef(null);
  const reactionModalRef = useRef(null);
  const completeModalRef = useRef(null);
  const discussionModalRef = useRef(null);

  const renderComponent = (Component, type, engagement) => {
    if (type === 'closeEngagement') {
      return new Promise((resolve, reject) => {
        setCloseComponent(<Component engagement={engagement} />);
        return resolve(true);
      });
    } else if (type === 'completeEngagement') {
      return new Promise((resolve, reject) => {
        setCompleteComponent(<Component engagement={engagement} setCompleteComponent={() => {
          setCompleteComponent('');
          setCompleteComponentLoaded(false);
        }} />);
        return resolve(true);
      });
    } else if (type === 'editEngagement') {
      return new Promise((resolve, reject) => {
        setEditEngagementComponent(<Component editEngagement={engagement} />);
        return resolve(true);
      });
    } else if (type === 'reactEngagement') {
      return new Promise((resolve, reject) => {
        //const setLike = await reactToEngagement(engagement);
        setReactComponent(<Component reactEngagement={engagement} />);
        return resolve(true);
      });
    } else if (type === 'discussionEngagement') {
      return new Promise(async (resolve, reject) => {
        setDiscussionComponent(
          <Component engagement={engagement} language={language} />,
        );
        return resolve(true);
      });
    }
  };

  const showCloseEngagement = (event, engagement) => {
    closeModalRef.current.setAttribute(
      'data-target',
      '#close-engagement-' + engagement.id,
    );
    if (closeComponentloaded === false) {
      closeModalRef.current.click();
      setCloseComponentLoaded(true);
    }
  };

  const showCompleteEngagement = (event, engagement) => {
    completeModalRef.current.setAttribute(
      'data-target',
      '#complete-engagement-' + engagement.id,
    );
    if (completeComponentloaded === false) {
      completeModalRef.current.click();
      setCompleteComponentLoaded(true);
    }
  };

  const showEngagementEdit = (event, engagement) => {
    engagementModalRef.current.setAttribute(
      'data-target',
      '#edit-engagement-' + engagement.id,
    );
    if (engagementComponentloaded === false) {
      engagementModalRef.current.click();
      setEngagementComponentloaded(true);
    } else {
      setEngagementFormData(engagement);
    }
  };

  const showReactEngagement = (event, engagement) => {
    reactionModalRef.current.setAttribute(
      'data-target',
      '#react-engagement-' + engagement.id,
    );
    if (reactComponentLoaded === false) {
      reactionModalRef.current.click();
      setReactComponentLoaded(true);
    }
  };

  const showDiscussionEngagement = (engagement) => {
    discussionModalRef.current.setAttribute(
      'data-target',
      '#discussion-engagement-' + engagement.id,
    );
    if (discussionComponentloaded === false) {
      discussionModalRef.current.click();
      setDiscussionComponentLoaded(true);
    }
  };

  const updateStatus = async (engagement, status) => {
    return new Promise(async (resolve, reject) => {
      await updateEngagementStatus(engagement, status);
      await getEngagements(status);
      resolve(true);
    }).then(async () => {
      await setReloadEngagementStatus(true, status);
    });
  };

  const navigateTo = (url) => {
    history.push(url);
  };

  const isIconDisabled = 
    (engagement.canReact === false && engagement.stage !== 'outcome') ||
    (engagement.canlikedislike === false && engagement.stage === 'outcome' && (engagement.category === 'discussion' || engagement.category === 'survey')) ||
    (!user.isVerified || !user.isIdentityVerified || !user.isRegistrationComplete)

  const checkReactStatus = (engagement, type) => {
    if (
      user.isIdentityVerified === false &&
      engagement.user.userId !== user.userId &&
      type !== 'comment'
    ) {
      return;
    } else if (
      engagement.canReact === false &&
      engagement.canlikedislike === false &&
      type !== 'comment'
    ) {
      return;
    } else {
      if (type === 'comment' && typeof user.roleName !== 'undefined') {
        renderComponent(
          DiscussionEngagement,
          'discussionEngagement',
          engagement,
        ).then(() => {
          showDiscussionEngagement(engagement);
        });
      } else {
        if (
          (engagement.canReact === true && engagement.stage !== 'outcome') ||
          (engagement.canlikedislike === true &&
            engagement.stage === 'outcome' &&
            (engagement.category === 'discussion' ||
              engagement.category === 'survey'))
        ) {
          setEmotions(engagement.id, type);
        } else {
          return;
        }
      }
    }
  };

  const hideModal = (id) => {
    if ($('#' + id)) {
      $('#' + id).modal('hide');
    }
  };

  const [commentCount, setCommentCount] = useState(0);
  const [parentCommentCount, setParentCommentCount] = useState(0);
  useEffect(() => {
    setCommentCount(engagement.commentCount);
    setParentCommentCount(engagement.parentCommentCount);
  }, [engagement]);
  useEffect(() => {
    if (engagementSpecific.id === engagement.id) {
      setCommentCount(engagementSpecific.commentCount);
      setParentCommentCount(engagementSpecific.parentCommentCount);
    }
  }, [engagementSpecific]);

  return (
    <>
      <hr 
        style={{
          marginTop: 20,
          marginBottom: 0,
          padding: 0,
          borderColor: '#D8D8D8',
        }}
      />
      <div className="clsdashboard-actions-blk">
        <div 
          className="clsdashboard-actions-left engagement-footer"
          style={{
            marginRight: 15
          }}
        >
          <CivicModal
            id={`react-eligible-modal`}
            title={``}
            dismissal={false}
            modalDialogueClass={`popupCommonModal`}
            hideClose={true}
          >
            <p className={`text-center modalInfo`}>
              {t(engagement.restriction_reason)}
            </p>
            <button
              type="button"
              className="mt-4 btn clsyellow-btn modal-bottom-btn"
              onClick={(e) => {
                hideModal('react-eligible-modal');
              }}
            >
              {t(`Ok`)}
            </button>
          </CivicModal>
          {engagement.stage !== 'unpublished' ? (
            <>
              <ul>
                {engagement.category !== 'discussion' ||
                (engagement.category === 'discussion' &&
                  engagement.stage === 'outcome') ? (
                  <>
                    {uri !== 'dashboard/civicfeed' &&
                    uri !== 'dashboard/user-profile' &&
                    uri !== 'dashboard/civiclist' &&
                    uri !== 'dashboard/engagement' ? (
                      <li>
                        {reactComponent}
                        <a
                          href={hashUrl}
                          data-toggle="modal"
                          data-target="#"
                          onClick={(event) => {
                            renderComponent(
                              EngagementReactions,
                              'reactEngagement',
                              engagement,
                            ).then(() => {
                              showReactEngagement(event, engagement);
                            });
                          }}
                          ref={reactionModalRef}
                        >
                          <span>
                            <img
                              src={
                                require('../../../assets/img/approve.svg')
                                  .default
                              }
                              alt="Approve"
                              key={`Approve`}
                            />
                          </span>{' '}
                          <span>{t(`Show reactions`)}</span>
                        </a>
                      </li>
                    ) : (
                      <>
                        <li>
                          <a
                            href={hashUrl}
                            id={`like-${engagement.id}`}
                            onClick={(e) => {
                              checkReactStatus(engagement, 'Like');
                              e.preventDefault();
                            }}
                            className={`clsapprove-active ${!authenticated && 'no-pointer'}`}
                          >
                            <span>
                              {(isIconDisabled && authenticated) ? (
                                <img
                                  src={
                                    require('../../../assets/img/approve-disable.svg')
                                      .default
                                  }
                                  alt="Approved"
                                  key={`Approved`}
                                />
                              ) : (
                                [
                                  engagement.userLikeReaction === true ? (
                                    <img
                                      src={
                                        require('../../../assets/img/approve-active.svg')
                                          .default
                                      }
                                      alt="Approved"
                                      key={`Approved`}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require('../../../assets/img/approve.svg')
                                          .default
                                      }
                                      alt="Approve"
                                      key={`Approve`}
                                    />
                                  ),
                                ]
                              )}
                            </span>{' '}
                            <span>
                              {typeof engagement.liked_count === 'undefined'
                                ? 0
                                : engagement.liked_count}
                            </span>
                          </a>
                          { ((isIconDisabled && engagement.restriction_reason) || !authenticated) &&
                            <Tooltip
                              targetId={`like-${engagement.id}`}
                              targetStatus={true}
                              styleClass={'react-tooltip'}
                              message={engagement.restriction_reason ? t(engagement.restriction_reason) : t('Please register to follow or engage with this government account')}
                            />
                          }
                        </li>
                        <li>
                          <a
                            href={hashUrl}
                            id={`dislike-${engagement.id}`}
                            onClick={(e) => {
                              checkReactStatus(engagement, 'Dislike');
                              e.preventDefault();
                            }}
                            className={`${!authenticated && 'no-pointer'}`}
                          >
                            <span>
                              { (isIconDisabled && authenticated) ? (
                                <img
                                  src={
                                    require('../../../assets/img/disapprove-disable.svg')
                                      .default
                                  }
                                  alt="Disapproved"
                                  key={`Disapproved`}
                                />
                              ) : (
                                [
                                  engagement.userDisLikeReaction === true ? (
                                    <img
                                      src={
                                        require('../../../assets/img/disapproved.svg')
                                          .default
                                      }
                                      alt="Disapproved"
                                      key={`Disapproved`}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require('../../../assets/img/disapprove.svg')
                                          .default
                                      }
                                      alt="Disapprove"
                                      key={`Disapprove`}
                                    />
                                  ),
                                ]
                              )}
                            </span>{' '}
                            <span>
                              {typeof engagement.disliked_count ===
                                'undefined'
                                  ? 0
                                  : engagement.disliked_count}
                            </span>
                          </a>
                          { ((isIconDisabled && engagement.restriction_reason) || !authenticated) &&
                            <Tooltip
                              targetId={`dislike-${engagement.id}`}
                              targetStatus={true}
                              styleClass={'react-tooltip'}
                              message={engagement.restriction_reason ? t(engagement.restriction_reason) : t('Please register to follow or engage with this government account')}
                            />
                          }
                        </li>
                      </>
                    )}
                  </>
                ) : (
                  ''
                )}
                {engagement.discussion_board === true ||
                engagement.category === 'discussion' ? (
                  <li>
                    {discussionComponent}
                    <a
                      id={`civicfeed-discussion-${engagement.id}`}
                    >
                      <span>
                        {(isIconDisabled && authenticated) ? (
                          <img
                            src={
                              require('../../../assets/img/comment-disable.svg')
                                .default
                            }
                            alt="Comment"
                          />
                        ) : (
                          <img
                            src={
                              require('../../../assets/img/comment_icon.svg')
                                .default
                            }
                            alt="Comment"
                          />  
                        )}
                      </span>{' '}
                      <span>{commentCount}</span>
                    </a>
                    {((isIconDisabled && engagement.restriction_reason) || !authenticated) &&
                      <Tooltip
                        targetId={`civicfeed-discussion-${engagement.id}`}
                        targetStatus={true}
                        styleClass={'react-tooltip'}
                        message={engagement.restriction_reason ? t(engagement.restriction_reason) : t('Please register to follow or engage with this government account')}
                      />
                    }
                  </li>
                ) : (
                  ''
                )}
                {analyticsVisibility === true ? (
                  <li>
                    <a
                      href={hashUrl}
                      onClick={() => {
                        navigateTo('/dashboard/analytics/?id=' + engagement.id);
                      }}
                    >
                      <span>
                        <img
                          src={
                            require('../../../assets/img/Combined Shape.svg')
                              .default
                          }
                          alt="Analytics"
                        />
                      </span>{' '}
                      <span>{t(`Analytics`)}</span>
                    </a>
                  </li>
                ) : (
                  ''
                )}
                {followVisibility === true &&
                typeof user.roleName !== 'undefined' && !(isIconDisabled && authenticated) ? (
                  <li>
                    <a
                      href={hashUrl}
                      onClick={(e) => {
                        followPost(engagement.id);
                        e.preventDefault();
                      }}
                    >
                      {engagement.userFollowPost === false ? (
                        <>
                          <span>
                            <img
                              src={
                                require('../../../assets/img/icon-follow.svg')
                                  .default
                              }
                              alt="Follow"
                              width="16"
                            />
                          </span>
                          <span>{t(`Follow`)}</span>
                        </>
                      ) : (
                        <>
                          <span>
                            <img
                              src={
                                require('../../../assets/img/icon-following.svg')
                                  .default
                              }
                              alt="Following"
                              width="16"
                            />
                          </span>
                          <span>{t(`Following`)}</span>
                        </>
                      )}
                    </a>
                  </li>
                ) : (
                  ''
                )}
              </ul>
            </>
          ) : (
            ''
          )}
          {(user.roleName === 'PUBLICOFFICIAL' || user.roleName === 'ADMIN') &&
          engagement.stage === 'unpublished' ? (
            <>
              <ul>
                <li>
                  {editEngagementComponent}
                  <a
                    href={hashUrl}
                    data-toggle="modal"
                    data-target="#"
                    onClick={(event) => {
                      renderComponent(
                        NewEngagement,
                        'editEngagement',
                        engagement,
                      ).then(() => {
                        showEngagementEdit(event, engagement);
                      });
                    }}
                    ref={engagementModalRef}
                  >
                    <span>
                      <img
                        src={require('../../../assets/img/edit.svg').default}
                        alt="Approve"
                      />
                    </span>
                    <span>{t(`Edit Engagement`)}</span>
                  </a>
                </li>
              </ul>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="clsdashboard-actions-right">
          {status === 'unpublished' ? (
            <a
              href={hashUrl}
              className="btn btn-block clsanchor-btn clsyellow-btn"
              onClick={() => {
                updateStatus(engagement, 'open');
              }}
            >
              {t(`Publish`)}
            </a>
          ) : status === 'open' && engagement.category !== 'post' ? (
            <>
              {closeComponent}
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target={hashUrl}
                className="btn btn-block clsanchor-btn clsyellow-btn"
                onClick={(event) => {
                  renderComponent(
                    CloseEngagement,
                    'closeEngagement',
                    engagement,
                  ).then(() => {
                    showCloseEngagement(event, engagement);
                  });
                }}
                ref={closeModalRef}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {t(`Close Engagement`)}
              </a>
            </>
          ) : status === 'closed' ? (
            <>
              {completeComponent}{' '}
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target={hashUrl}
                className="btn btn-block clsanchor-btn clsyellow-btn"
                onClick={(event) => {
                  renderComponent(
                    CompleteEngagement,
                    'completeEngagement',
                    engagement,
                  ).then(() => {
                    showCompleteEngagement(event, engagement);
                  });
                }}
                ref={completeModalRef}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {t(`Complete Engagement`)}
              </a>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <hr 
        style={{
          marginTop: 20,
          marginBottom: 0,
          padding: 0,
          borderColor: '#D8D8D8',
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    authentication: state.authentication,
    authentication: state.authentication.authenticated,
    discussionEngagementData: state.discussionEngagement.discussionEngagementData,
    engagementSpecific: state.engagement.engagementSpecific,
  }
}

export default connect(mapStateToProps, actions)(EngagementFooter);
