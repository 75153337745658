/**
 *
 * Civic Modals
 *
 */

import React from 'react';

export const renderComponent = (Component, type) => {
  return <Component type={type} />;
};

const CivicModals = (props) => {
  const { user, blocks } = props;

  return (
    <>
      {typeof blocks !== 'undefined' &&
      Object.keys(blocks).length !== 0 &&
      typeof user.roleName !== 'undefined' ? (
        <>
          {blocks[user.roleName]
            .filter((block) => block.type === 'Modal')
            .map((block, index) => (
              <div key={index}>
                {renderComponent(block.component, block.type)}
              </div>
            ))}
        </>
      ) : (
        ''
      )}
    </>
  );
};

CivicModals.defaultProps = {
  blocks: [],
};

export default CivicModals;
