import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import { MAX_POST_CHAR_COUNT, LIMIT_POST_CHAR_COUNT } from '../../../constants';
import { useTranslation } from 'react-i18next';

const PostContent = (props) => {
  const { t } = useTranslation();
  const { user, engagement, showOriginalLanguage } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeText, setActiveText] = useState(null);
  const postParagraph = useRef(null);

  useEffect(() => {
    if (user.selected_langauge === engagement?.originalLanguage || showOriginalLanguage || !(JSON.parse(engagement.translation)?.[user.selected_language]?.text_content)) {
      if (engagement.text_content_html) {
        setActiveText({ __html: engagement.text_content_html });
      }
      else {
        setActiveText(convertFromJSONToHTML(engagement.comment));
      }
    }
    else {
      setActiveText({ __html: JSON.parse(engagement.translation)?.[user.selected_language]?.text_content })
    }
  }, [engagement, user, isExpanded, showOriginalLanguage]);

  useEffect(() => {
    if (postParagraph.current) {
      const links = postParagraph.current.querySelectorAll('a');
      links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [postParagraph, activeText])

  return (
    <>
      <p
        dangerouslySetInnerHTML={
            activeText 
          ? 
              (!isExpanded && engagement?.comment?.length > MAX_POST_CHAR_COUNT)
            ?
              { __html: activeText.__html.substring(0, LIMIT_POST_CHAR_COUNT) + '...' }
            :
              activeText
          : 
            { __html: '' }
        }
        ref={postParagraph}
      ></p>
      {
        engagement?.comment?.length > MAX_POST_CHAR_COUNT &&
         <div>
          <a
            href={`#engagement-show-more-`}
            data-target={`#engagement-show-more-`}
            data-toggle="collapse"
            className="nav-toggle clsreply-link"
            id={`show-hide-link-`}
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
            style={{
              color: '#ffaa32',
              textDecoration: 'none',
              outline: 'none',
            }}
          >
            {
              isExpanded ?
                t('Read less')
              :
                t('Read more')
            }
          </a>
          </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {

  }
}

export default connect(mapStateToProps, actions)(PostContent);