/**
 *
 * PushNotification
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import PushNotification from '../../components/Manager/PushNotification';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';

const Pushnotification = (props) => {
  const {
    getUnReadNotification,
    notificationUnReadList,
    user,
    notificationChange,
  } = props;
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);

  const [notificationListComponent, setNotificationListComponent] =
    useState('');

  const [page, setPage] = useState(1);

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setNotificationListComponent(
          <Component {...props} notificationChange={notificationChange} />,
        );
        return resolve(true);
      });
    },
    [setNotificationListComponent, props, notificationChange],
  );

  const fetchNotification = useCallback(async () => {
    setInProcess(true);
    await getUnReadNotification(page, 2).then(async () => {
      await renderComponent(PushNotification).then(() => {
        setPage(page + 1);
      });
    });
    setInProcess(false);
  }, [getUnReadNotification, renderComponent, setPage, page]);

  useEffect(() => {
    if (loading === true) {
      setNotificationListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        const fetchNotification = async () => {
          await getUnReadNotification().then(() => {
            setLoading(false);
            setInProcess(false);
          });
        };
        fetchNotification();
      }
    }
  }, [
    loading,
    setLoading,
    getUnReadNotification,
    renderComponent,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    notificationUnReadList,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(PushNotification);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    renderComponent(PushNotification);
  }, [user, renderComponent]);

  return (
    <>
      {notificationListComponent}
      {page === 2 ? <div style={{ minHeight: '100px' }}></div> : ''}
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={notificationUnReadList.length}
            next={fetchNotification}
            hasMore={true}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    notificationReadList: state.pushnotification.notificationReadList,
    notificationUnReadList: state.pushnotification.notificationUnReadList,
    isNotificationLoading: state.pushnotification.isNotificationLoading,
  };
};

export default connect(mapStateToProps, actions)(Pushnotification);
