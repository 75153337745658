/*
 *
 * Reset Password constants
 *
 */

export const RESET_PASSWORD_CHANGE = 'src/ResetPassword/RESET_PASSWORD_CHANGE';
export const RESET_PASSWORD_FORM_RESET =
  'src/ResetPassword/RESET_PASSWORD_FORM_RESET';
export const SET_RESET_PASSWORD_FORM_ERRORS =
  'src/ResetPassword/SET_RESET_PASSWORD_FORM_ERRORS';
export const SET_RESET_PASSWORD_SUBMITTING =
  'src/ResetPassword/SET_RESET_PASSWORD_SUBMITTING';
export const SET_LINK_VERIFIED = 'src/ResetPassword/SET_LINK_VERIFIED';
export const SET_ERROR_NOTIFICATION =
  'src/ResetPassword/SET_ERROR_NOTIFICATION';
export const RESET_APP = 'src/ResetPassword/RESET_APP';
