/*
 *
 * Engagement View
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import Survey from '../../components/Manager/Engagement/survey';
import Post from '../../components/Manager/Engagement/post';
import Discussion from '../../components/Manager/Engagement/discussion';
import DiscussionEngagement from '../DiscussionEngagement';
import NoData from '../../components/Manager/Engagement/noData';

const View = (props) => {
  const { location, setEngagementData, engagement, setViewedStatus } = props;

  const Id = new URLSearchParams(location.search).get('id');

  const [loaded, setLoaded] = useState(false);

  const loadEngagement = useCallback(async () => {
    if (Id === null) {
      setLoaded(true);
    } else {
      await setEngagementData(Id).then(() => {
        setLoaded(true);
      });
    }
  }, [setEngagementData, Id, setLoaded]);

  useEffect(() => {
    if (loaded === false) {
      loadEngagement();
    }
  }, [loaded, loadEngagement]);

  useEffect(() => {
    const url = window.location.href;
    const postId = url.split('?id=').pop();
    setViewedStatus(postId);
  }, [engagement])

  return (
    <>
      {loaded === false ? <LoadingIndicator position={`fixed`} /> : ''}
      {Id !== null && loaded === true && typeof engagement !== 'undefined' ? (
        typeof engagement.stage !== 'undefined' ? (
          engagement.stage !== 'unpublished' ? (
            <div>
              {engagement.category === 'survey' ? (
                <Survey {...props} engagement={engagement} />
              ) : engagement.category === 'post' ? (
                <Post {...props} engagement={engagement} />
              ) : engagement.category === 'discussion' ? (
                <Discussion {...props} engagement={engagement} />
              ) : (
                ''
              )}
            </div>
          ) : (
            <NoData />
          )
        ) : (
          <NoData />
        )
      ) : Id === null ? (
        <NoData />
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    engagement: state.engagement.engagementSpecific,
  };
};

export default connect(mapStateToProps, actions)(View);
