/*
 *
 * Discussion engagement reducer
 *
 */

import {
  DISCUSSION_ENGAGEMENT_CHANGE,
  SET_DISCUSSION_ENGAGEMENT_DATA,
  DISCUSSION_ENGAGEMENT_COMMENT_CHANGE,
  SET_DISCUSSION_ENGAGEMENT_LOADING,
  SET_DISCUSSION_ENGAGEMENT_RELOAD,
  SET_DISCUSSION_ENGAGEMENT_COMMENTS_RELOAD,
  SET_DISCUSSION_ENGAGEMENT_CHILD_COMMENT_RELOAD,
  RESET_APP,
} from './constants';

const initialState = {
  discussionEngagementData: [],
  isDiscusisonEngagementLoading: true,
  reload: false,
  reloadComments: false,
  reloadChildComments: { status: false, id: null },
};

const defaultState = JSON.stringify(initialState);

const discussionEngagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_DISCUSSION_ENGAGEMENT_DATA:
      return {
        ...state,
        discussionEngagementData: {
          ...state.discussionEngagementData,
          ...action.payload,
        },
      };
    case DISCUSSION_ENGAGEMENT_CHANGE:
      return {
        ...state,
        discussionEngagementData: {
          ...state.discussionEngagementData,
          ...action.payload,
        },
      };
    case DISCUSSION_ENGAGEMENT_COMMENT_CHANGE:
      const { id, commentId, values } = action.payload;
      let commentsData = state.discussionEngagementData[id];

      if (commentsData && commentsData.comments) {
        let index = commentsData['comments']?.findIndex(function (comment, i) {
          return comment.id === commentId;
        });
        if (index !== -1) {
          Object.keys(values).forEach((item) => {
            if (typeof values[item] !== 'undefined') {
              commentsData['comments'][index][item] = values[item];
            }
          });
        }
      }

      return {
        ...state,
        discussionEngagementData: {
          ...state.discussionEngagementData,
          ...commentsData,
        },
      };
    case SET_DISCUSSION_ENGAGEMENT_LOADING:
      return {
        ...state,
        isDiscusisonEngagementLoading: action.payload,
      };
    case SET_DISCUSSION_ENGAGEMENT_RELOAD:
      return {
        ...state,
        reload: action.payload,
      };
    case SET_DISCUSSION_ENGAGEMENT_COMMENTS_RELOAD:
      return {
        ...state,
        reloadComments: action.payload,
      };
    case SET_DISCUSSION_ENGAGEMENT_CHILD_COMMENT_RELOAD:
      return {
        ...state,
        reloadChildComments: action.payload,
      };
    default:
      return state;
  }
};

export default discussionEngagementReducer;
