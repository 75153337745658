import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import actions from "../../actions";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';

const VerificationMessage = (props) => {

    const {
        user
    } = props;

    const {t} = useTranslation();
    const history = useHistory();

    const [registrationFinished, setRegistrationFinished] = useState();

    useEffect(() => {
        setRegistrationFinished(JSON.parse(localStorage.getItem('registration_finished')));
    }, [registrationFinished]);

    const finishRegistration = () => {
        setRegistrationFinished(true);
        localStorage.setItem('registration_finished', 'true');
    }

    const handleOnClick = (redirectFlag = false) => {
        finishRegistration();

        if (redirectFlag) {
            const url = '/dashboard/setting';
            history.push(url, { modalToOpen: 'optionalInformation' });
        }
    }

    return <>
        {user.roleId === 2 ? '' :
            registrationFinished ? '' :
                (user.isVerified === true && user.isIdentityVerified === false && user.isRegistrationComplete === true ? (
                    <div style={{
                        backgroundColor: '#2b3659',
                        color: 'grey',
                        borderRadius: '13px',
                        padding: '10px 10px',
                        marginBottom: '10px',
                        fontSize: '13px'
                    }}>
                        <div style={{
                            borderBottom: '2px solid grey',
                            paddingBottom: '10px',
                            marginBottom: '10px'
                        }}>
                            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                <img width="18px" src={require('../../assets/img/check@1,5x.svg').default}
                                     alt="check-icon"/>&nbsp;&nbsp;
                                <span>{t(`Create Your Account`)}</span>&nbsp;&mdash;&nbsp;
                                <img
                                    style={{filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(236deg) brightness(104%) contrast(101%)'}}
                                    width="18px"
                                    src={require('../../assets/img/edit.svg').default}
                                    alt="edit-icon"/>&nbsp;&nbsp;
                                <span
                                    style={{color: 'white'}}>{t(`Verify Your Account`)}</span>&nbsp;&mdash;&nbsp;
                                <div
                                    style={{
                                        width: '18px',
                                        height: '18px',
                                        backgroundColor: 'grey',
                                        borderRadius: '50%'
                                    }}/>
                                &nbsp;&nbsp;<span>{t(`Provide Optional Information`)}</span>
                            </div>
                        </div>
                        <div style={{color: 'white'}}>
                            <span><strong>{t(`Your verification is under manual review.`)}</strong></span><br/>
                            <span>{t(`We will reach out via email from`)} <a
                                href="mailto:support@civicbell.com">support@civicbell.com</a> - {t(`thank you for your patience!`)}</span>
                        </div>
                    </div>
                    ) :
                    (user.isVerified === true && user.isIdentityVerified === true && user.isRegistrationComplete === true ? (
                        <div style={{
                            backgroundColor: '#2b3659',
                            color: 'grey',
                            borderRadius: '13px',
                            padding: '10px 10px',
                            marginBottom: '10px',
                            fontSize: '13px'
                        }}>
                            <div style={{
                                borderBottom: '2px solid grey',
                                paddingBottom: '10px',
                                marginBottom: '10px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <img width="18px" src={require('../../assets/img/check@1,5x.svg').default}
                                         alt="check-icon"/>&nbsp;&nbsp;
                                    <span>{t(`Create Your Account`)}</span>&nbsp;&mdash;&nbsp;
                                    <img width="18px" src={require('../../assets/img/check@1,5x.svg').default}
                                         alt="check-icon"/>&nbsp;&nbsp;
                                    <span>{t(`Verify Your Account`)}</span>&nbsp;&mdash;&nbsp;
                                    <img
                                        style={{filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(236deg) brightness(104%) contrast(101%)'}}
                                        width="18px" src={require('../../assets/img/edit.svg').default}
                                        alt="edit-icon"/>&nbsp;&nbsp;<span
                                    style={{color: 'white'}}>{t(`Provide Optional Information`)}</span>
                                </div>
                            </div>
                            <div style={{color: 'white'}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                <span
                                    style={{width: '50%'}}>{t(`Provide optional demographic information to enable your officials to best serve you.`)}</span><br/>
                                    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                        <button style={{height: '35px'}} type="button"
                                                className="btn clsyellow-btn"
                                                onClick={(e) => {
                                                    handleOnClick(true)
                                                }}>
                                            {t(`Sure!`)}</button>
                                        <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                                              onClick={(e) => {
                                                  handleOnClick(false)
                                              }}
                                        >{t(`No, thank you`)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : '')
                )
        }
    </>
}

const mapStateToProps = (state) => {
    return {
        user: state.account.user
    };
};

export default connect(mapStateToProps, actions)(VerificationMessage);
