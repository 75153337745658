/*
 *
 * CommunityIssues constants
 *
 */
import i18next from 'i18next';

export const RESET_APP = 'src/CommunityIssues/RESET_APP';
export const RESET_COMMUNITY_ISSUE_FEED = 'src/CommunityIssues/RESET_COMMUNITY_ISSUE_FEED';
export const GET_COMMUNITY_ISSUES = 'src/CommunityIssues/GET_COMMUNITY_ISSUES';
export const GET_MY_COMMUNITY_ISSUES = 'src/CommunityIssues/GET_MY_COMMUNITY_ISSUES';
export const GET_INDIVIDUAL_COMMUNITY_ISSUE = 'src/CommunityIssues/GET_INDIVIDUAL_COMMUNITY_ISSUE';
export const CREATE_COMMUNITY_ISSUE = 'src/CommunityIssues/CREATE_COMMUNITY_ISSUE';
export const UPDATE_COMMUNITY_ISSUE = 'src/CommunityIssues/UPDATE_COMMUNITY_ISSUE';
export const CLOSE_COMMUNITY_ISSUE = 'src/CommunityIssues/CLOSE_COMMUNITY_ISSUE';
export const HIDE_COMMUNITY_ISSUE = 'src/CommunityIssues/HIDE_COMMUNITY_ISSUE';
export const DELETE_COMMUNITY_ISSUE = 'src/CommunityIssues/DELETE_COMMUNITY_ISSUE';
export const SIGN_OR_UNSIGN_COMMUNITY_ISSUE = 'src/CommunityIssues/SIGN_OR_UNSIGN_COMMUNITY_ISSUE';
export const SET_FOLLOWING_COMMUNITY_ISSUE = 'src/CommunityIssues/SET_FOLLOWING_COMMUNITY_ISSUE';
export const GET_TOPICS = 'src/CommunityIssues/GET_TOPICS';
export const SET_COMMUNITY_ISSUES_LOADING = 'src/CommunityIssues/SET_COMMUNITY_ISSUES_LOADING';
export const SET_MY_COMMUNITY_ISSUES_LOADING = 'src/CommunityIssues/SET_MY_COMMUNITY_ISSUES_LOADING';
export const SET_TOPICS_LOADING = 'src/CommunityIssues/SET_COMMUNITY_ISSUES_LOADING';
export const SET_COMMUNITY_ISSUE_LIST_PAGE = 'src/CommunityIssues/SET_COMMUNITY_ISSUE_LIST_PAGE';
export const SET_COMMUNITY_ISSUE_LIST_LIMIT = 'src/CommunityIssues/SET_COMMUNITY_ISSUE_LIST_LIMIT';
export const SET_COMMUNITY_ISSUE_LIST_FILTER = 'src/CommunityIssues/SET_COMMUNITY_ISSUE_LIST_FILTER';
export const SET_COMMUNITY_ISSUE_LIST_ISSUE_CATEGORY = 'src/CommunityIssues/SET_COMMUNITY_ISSUE_LIST_ISSUE_CATEGORY';
export const SET_COMMUNITY_ISSUE_SORT_ORDER = 'src/CommunityIssues/SET_COMMUNITY_ISSUE_SORT_ORDER';
export const SET_COMMUNITY_ISSUE_LIST_AREA_FILTER = 'src/CommunityIssues/SET_COMMUNITY_ISSUE_LIST_AREA_FILTER';
export const CREATE_COMMUNITY_ISSUE_COMMENT = 'src/CommunityIssues/CREATE_COMMUNITY_ISSUE_COMMENT';
export const SET_SIGNEES_LOADING = 'src/CommunityIssues/SET_SIGNEES_LOADING';
export const GET_COMMUNITY_ISSUE_SIGNEES = 'src/CommunityIssues/GET_COMMUNITY_ISSUE_SIGNEES';
export const REACT_TO_COMMUNITY_ISSUE_COMMENT = 'src/CommunityIssues/REACT_TO_COMMUNITY_ISSUE_COMMENT';

export const sortOptions = [
  { value: 'Newest', label: 'Newest', translationParsing: i18next.t('Newest') },
  { value: 'numSignees', label: 'Number of signatures', translationParsing: i18next.t('Number of signatures') },
];

export const areaOptions = [
  { value: 'All Issues', label: 'All Issues', translationParsing: i18next.t('All Issues') },
  { value: 'myDistrict', label: 'Addressed to your district', translationParsing: i18next.t('Addressed to your district') },
  { value: 'myArea', label: 'Geographic overlap with your district', translationParsing: i18next.t('Geographic overlap with your district') },
];