/**
 *
 * InAppropriate Report
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Notification from '../Notification';
import CivicModal from '../../components/Common/CivicModal';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const InAppropriateReport = (props) => {
  const {
    inAppropriateData,
    inAppropriateErrors,
    inAppropriateLoad,
    inAppropriateSubmit,
    inAppropriateChange,
    submitInAppropriate,
    engagementId,
  } = props;
  const { t } = useTranslation();
  const url =
    window.location.origin + '/dashboard/engagement/?id=' + engagementId;

  const handleSubmit = (event) => {
    event.preventDefault();
    inAppropriateChange('reportLink', url);
    inAppropriateChange('id', engagementId);
    submitInAppropriate();
  };

  return (
    <CivicModal
      id={`inappropriate-report-${engagementId}`}
      title={`Report an Issue`}
      dismissal={inAppropriateData.dismissal}
      footerClass={`text-center mt-4`}
    >
      <div className="clsengagement-form clsevent-form">
        {inAppropriateLoad && <LoadingIndicator />}
        <Notification module={'account'} />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">{t(`Description`)}</label>
            <textarea
              className="form-control"
              rows="5"
              placeholder={t(`Enter Description`)}
              name="description"
              value={inAppropriateData.description}
              onChange={(e) =>
                inAppropriateChange(e.target.name, e.target.value)
              }
            ></textarea>
            {typeof inAppropriateErrors.description !== 'undefined' &&
              inAppropriateErrors.description && (
                <div className="clsinvalid">
                  {t(inAppropriateErrors?.['description'])}
                </div>
              )}
          </div>
          <div className="text-center mt-4">
            <button
              type="submit"
              className="btn clsyellow-btn  clsanchor-btn"
              disabled={inAppropriateSubmit}
            >
              {t(`Report`)}{' '}
            </button>
          </div>
        </form>
      </div>
    </CivicModal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    inAppropriateErrors: state.inAppropriateReport.inAppropriateErrors,
    inAppropriateData: state.inAppropriateReport.inAppropriateData,
    inAppropriateLoad: state.inAppropriateReport.inAppropriateLoad,
    inAppropriateSubmit: state.inAppropriateReport.inAppropriateSubmit,
  };
};

export default connect(mapStateToProps, actions)(InAppropriateReport);
