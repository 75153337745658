/**
 *
 * Notification Section page
 *
 */

import React, { useEffect, useState, createContext } from 'react';
import { daysDiffTxt } from '../../../utils/helper';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const PublicOfficialNotification = (props) => {
  const [loading, setLoading] = useState(true);
  const hashUrl = '#';
  const {
    getReadNotification,
    notificationReadList,
    notificationUnReadList,
    updateNotification,
    clearLinkedNotifications,
    clearNotification,
    isNotificationLoading,
    getUnReadNotification,
    user,
  } = props;

  const history = useHistory();

  const navigateTo = (notification) => {
    const resourceType = notification?.notificationType?.resourceType;
    const resourceSubType = notification?.notificationType?.resourceSubType;
    if (resourceType === 'reaction' && resourceSubType === 'communityIssueCommentLike') {
      history.push('/community-issues/?id=' + notification?.communityIssueId);
    }
    if (['info', 'discussion', 'survey', 'comment', 'reaction'].includes(resourceType)) {
      history.push('/dashboard/engagement/?id=' + notification.postId);
    }
    else if (['communityIssue', 'communityIssueComment'].includes(resourceType)) {
      history.push('/community-issues/?id=' + notification?.communityIssueId);
    }
    else if (resourceType === 'official' && ['reminder', 'comment'].includes(resourceSubType)) {
      history.push('/dashboard/engagement/?id=' + notification.postId);
    }
    else if (resourceType === 'forOfficial' && ['postStatusReminder'].includes(resourceSubType)) {
      history.push('/dashboard/engagement/?id=' + notification.postId);
    }
    else if (['official', 'forOfficial'].includes(resourceType)) {
      history.push('/dashboard/user-profile/?id=' + notification.senderUserId);
    }
  };

  const { t } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(0); // Locking mechanism to ensure that notifications are marked as "read" once upon board opening. See BUG-127.

  useEffect(() => {
    if (loading === true) {
      const filterItem = async () => {
        await getUnReadNotification();
        await getReadNotification();
        setLoading(false);
        if (firstLoad === 0) {
          setFirstLoad(1);
        }
      };
      filterItem();
    }
  }, [loading, getUnReadNotification, getReadNotification]);

  useEffect(() => {
    if (firstLoad === 1) {
      for (let i = 0; i < notificationUnReadList.length; i++) {
        const notification = notificationUnReadList[i];
        clearLinkedNotifications(notification);
      }
      setFirstLoad(2);
    }
  })

  const Context = createContext();

  return (
    <>
      {isNotificationLoading === true ? (
        <LoadingIndicator position={`fixed`} />
      ) : (
        ''
      )}
      <div className="clsdashboard-right-common-blk">
        <div className="clsnotifications-blk">
          <h5 className="mb-3">
            {t(`Unread`)}
            <span className="clsclear-btn">
              <a href={hashUrl} onClick={() => clearNotification()}>
                {t(`Clear All`)}
              </a>
            </span>
          </h5>
          <ul>
            {notificationUnReadList.length > 0 ? (
              notificationUnReadList.map((notification, index) => (
                <li className="mb-2 pt-1" key={index}>
                  <div className="clsnotification-box clswhite-box">
                    <a
                      href={hashUrl}
                      onClick={() => {
                        clearLinkedNotifications(notification);
                        navigateTo(notification);
                      }}
                    >
                      <div className="clsnotification-profile-blk">
                        <figure className="mb-0 mr-3">
                          <img
                            src={
                              notification.senderUser !== null
                                ? notification?.senderUser?.profile_picture
                                : ''
                            }
                            alt=""
                            className="header-profile-img"
                          />
                        </figure>
                        <span>
                          <p
                            dangerouslySetInnerHTML={
                              user.selected_language === notification?.originalLangauge ?
                                { __html: notification?.message }
                              :
                                (JSON.parse(notification?.translation)?.[user?.selected_language]) ?
                                  { __html: JSON.parse(notification?.translation)?.[user?.selected_language]}
                                :
                                  { __html: notification?.message }
                            }
                          ></p>
                        </span>
                      </div>
                      <div className="clsnotification-date-time">
                        <Context.Provider value={user}>
                          {daysDiffTxt(notification.createdAt, t)}
                        </Context.Provider>
                      </div>
                    </a>
                  </div>
                </li>
              ))
            ) : (
              <center>{t(`You have no unread notifications`)}</center>
            )}
          </ul>
          <h5 className="mb-2 mt-4">{t(`Read`)}</h5>
          <ul>
            {notificationReadList.length > 0 ? (
              notificationReadList.map((notification, index) => (
                <li className="mb-2 pt-1" key={index}>
                  <div className="clsnotification-box clswhite-box">
                    <a
                      href={hashUrl}
                      onClick={() => {
                        navigateTo(notification);
                        clearLinkedNotifications(notification);
                      }}
                    >
                      <div className="clsnotification-profile-blk">
                        <figure className="mb-0 mr-3">
                          <img
                            src={
                              notification.senderUser !== null
                                ? notification?.senderUser?.profile_picture
                                : ''
                            }
                            alt=""
                            className=" header-profile-img"
                          />
                        </figure>
                        <span>
                          <p
                            dangerouslySetInnerHTML={
                              user.selected_language === notification?.originalLangauge ?
                                { __html: notification?.message }
                              :
                                (JSON.parse(notification?.translation)?.[user?.selected_language]) ?
                                  { __html: JSON.parse(notification?.translation)?.[user?.selected_language]}
                                :
                                  { __html: notification?.message }
                            }
                          ></p>
                        </span>
                      </div>
                      <div className="clsnotification-date-time">
                        <Context.Provider value={user}>
                          {daysDiffTxt(notification.createdAt, t)}
                        </Context.Provider>
                      </div>
                    </a>
                  </div>
                </li>
              ))
            ) : (
              <center>{t(`You have no read notifications`)}</center>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PublicOfficialNotification;
