/**
 *
 * Dashboard Blocks
 *
 */

let blocks = [];

blocks['ADMIN'] = [
  {
    name: 'New Engagement Form',
    type: 'Modal',
    component: require('../../components/Manager/Engagement/engagementForm')
      .default,
  },
  {
    name: 'Event Block',
  },
];

blocks['PUBLICOFFICIAL'] = [
  {
    name: 'New Engagement Form',
    type: 'Modal',
    component: require('../../components/Manager/Engagement/engagementForm')
      .default,
  },
  {
    name: 'Event Block',
  },
];

blocks['RESIDENT'] = [
  {
    name: 'New Petition',
  },
  {
    name: 'Event Block',
  },
];

blocks['INDIVIDUAL'] = [
  {
    name: 'Event Block',
  },
];

export default blocks;
