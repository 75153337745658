/**
 *
 * Map Filter
 *
 */
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY } from '../../../constants';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const {
    mapChangeByOption,
    civicMapChange,
    leaftLetMap,
    mapSearch,
    filterDismissal,
    resetCivicMapFilter,
    reloadCivicMap,
    filterSelected,
    currentLoadedMap,
    getCivicMap,
  } = props;
  const hashUrl = '#';
  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  const { t } = useTranslation();

  return (
    <div className="clsmap-block mb-2">
      <div className="clsfeed-filter-blk mb-3">
        <div className="clsmap-location-label">
          <label htmlFor="location_search">{t('Location')}:</label>
          <Autocomplete
            apiKey={GOOGLE_PLACES_API_KEY}
            className="form-control-plaintext"
            placeholder={t('incl. street number, no P.O. Box')}
            type="text"
            id="location_search"
            name="location_search"
            defaultValue={mapSearch && mapSearch.formatted_address}
            onKeyDown={preventSubmit}
            autoComplete="off"
            onPlaceSelected={(place) => {
              civicMapChange({
                mapSearch: place,
                geoJsonDataArray: [],
                filterSelected: { defaultMap: '', map: '' },
                currentLoadedMap: '',
              });
              setTimeout(getCivicMap, 0);
              if (leaftLetMap)
                leaftLetMap.flyTo([
                  place.geometry.location.lat(),
                  place.geometry.location.lng(),
                ]);
            }}
            options={{
              types: ['address'],
              componentRestrictions: { country: 'us' },
              fields: ['address_components', 'geometry', 'formatted_address'],
            }}
          />
        </div>
        <div className={`civic-filter-modal`}></div>
        <div className="clsevents-filter-right">
          <ul>
            <li>
              <div className="clsfeed-filter clsmap-filter-button clswhite-box">
                <a
                  onClick={() => {
                    mapChangeByOption('filterDismissal', !filterDismissal);
                  }}
                  className="clsfilter-btn"
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded={!filterDismissal}
                  aria-controls="collapseExample"
                >
                  <img
                    src={require('../../../assets/img/filter.svg').default}
                    alt={t('Filter')}
                  />
                  <span>{t(`Filter`)}</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {currentLoadedMap !== '' ? (
        <div className="clsmap-view-indicator">
          <label>
            {`${currentLoadedMap.level1} > ${currentLoadedMap.level2} > ${currentLoadedMap.name}`}
            {currentLoadedMap.name !== filterSelected['defaultMap'].name ? (
              <a
                href={hashUrl}
                className="clsindicator-close"
                onClick={(e) => {
                  e.preventDefault();
                  resetCivicMapFilter();
                  reloadCivicMap();
                }}
              >
                <img
                  src={require('../../../assets/img/close-small.svg').default}
                  alt=""
                />
              </a>
            ) : (
              ''
            )}
          </label>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Filter;
