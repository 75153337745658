import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import ShareLink from './ShareLink';
import CommunityIssueSocialMediaIcons from './CommunityIssueSocialMediaIcons';

const SignedConfirmationModal = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    allCommunityIssues,
    setIsSignedConfirmationModalOpen,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div className="signed-confirmation-modal">
      <div className='title'>
        <p>
          {t('Now share it with your friends!')}
        </p>
      </div>
      <div className='share-link-container'>
        <ShareLink communityIssueId={communityIssueId} />
      </div>
      <CommunityIssueSocialMediaIcons communityIssueId={communityIssueId} />
      <button
        type="button"
        className="btn clsyellow-btn"
        onClick={() => {
          setIsSignedConfirmationModalOpen(false);
        }}
      >
        {t(`Close`)}
      </button>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(SignedConfirmationModal);