/*
 *
 * Account actions
 *
 */

import axios from 'axios';
import {
  BASE_API_URL,
  FIREBASE_CONFIG,
  FIREBASE_VAPKEY,
} from '../../constants';
import { signOut } from '../Login/actions';
import { formatPhoneNumber, profileImageText } from '../../utils/helper';
import {
  FETCH_PROFILE,
  CLEAR_ACCOUNT,
  SET_PROFILE_LOADING,
  REQUEST_USER_PROFILE,
  SET_EDIT_PROFILE_DATA,
  EDIT_PROFILE_CHANGE,
  SET_EDIT_PROFILE_ERRORS,
  SET_EDIT_PROFILE_FORM_DATA,
  EDIT_PROFILE_FORM_CHANGE,
  SET_EDIT_PROFILE_FORM_ERRORS,
  SET_PROFILE_IMG_DATA,
  EDIT_PROFILE_IMG_CHANGE,
  SET_PROFILE_BG_IMG_DATA,
  EDIT_PROFILE_BG_IMG_CHANGE,
  SET_SOCIAL_LINKS_DATA,
  EDIT_SOCIAL_LINKS,
  SET_SOCIAL_LINK_ERRORS,
  SET_EDIT_OPTIONAL_DATA,
  EDIT_OPTIONAL_CHANGE,
  SET_EDIT_PASSWORD_DATA,
  EDIT_PASSWORD_FORM_CHANGE,
  SET_PASSWORD_FORM_ERRORS,
  SET_REPORT_FORM_ERRORS,
  REPORT_FORM_CHANGE,
  SET_REPORT_FORM_RESET,
  SET_REPORT_LOADING,
  SET_REPORT_SUBMITTING,
  GET_FIREBASE_TOKEN,
  SET_NOTIFICATION_ENABLE_DATA,
  RESET_APP,
  SET_INACTIVE_MESSAGES,
  SET_SELECTED_LANGUAGE,
  EDIT_USER_CHANGE,
  SET_FORM_TYPE,
  GET_MY_DISTRICTS
} from './constants';
//import ReactS3Client from 'react-aws-s3-typescript';
import { addNotification } from '../Notification/actions';
import { allFieldsValidation } from '../../utils/validation';
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import { setLoadingMsgStatus } from '../Authentication/actions';
import setToken from "../../utils/token";
import {
  SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN,
  SET_REGISTER_LOADING,
  SET_REGISTER_SUBMITTING
} from "../RegisterFlow/constants";
import {existingUserRegistration} from "../RegisterFlow/actions";

const firebaseApp = initializeApp(FIREBASE_CONFIG);
// const messaging = getMessaging(firebaseApp);
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    return null;
  } catch (err) {
    return null;
  }
})();

export const resetDashboardState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const fetchFirebaseToken = () => {
  return async (dispatch, getState) => {
    const messagingResolve = await messaging;
    return getToken(messagingResolve, { vapidKey: FIREBASE_VAPKEY })
      .then((currentToken) => {
        dispatch({ type: GET_FIREBASE_TOKEN, payload: currentToken });
      })
      .catch((err) => {
        return;
      });
  };
};

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        resolve(payload);
      });
    })(),
  );

export const clearAccount = () => {
  return {
    type: CLEAR_ACCOUNT,
  };
};

export const setProfileLoading = (value) => {
  return {
    type: SET_PROFILE_LOADING,
    payload: value,
  };
};

export const fetchProfile = (stillRegistering = false) => {
  return async (dispatch, getState) => {
    if (getState().account.isLoading === false) {
      try {
        let firebaseToken = getState().account.firebaseToken;
        dispatch(setProfileLoading(true));
        dispatch({ type: REQUEST_USER_PROFILE, payload: false });
        const response = await axios.get(
          BASE_API_URL + '/user?fcm_token=' + firebaseToken,
        );
        if (response.data.success === 'true') {
          dispatch({ type: FETCH_PROFILE, payload: response.data.data });

          if (response.data.data.userDistricts) {
            localStorage.setItem('user_districts', JSON.stringify(response.data.data.userDistricts));
          }

          if (response.data.data.roleId === 2) {
            dispatch(setLoadingMsgStatus(false));
          }
        }
      } catch (error) {
        dispatch(
          addNotification({
            type: 'error',
            message: 'Unable to fetch profile.',
            module: 'dashboard',
          }),
        );
        if (error.response.status !== '401') {
          dispatch(signOut('Please login again.'));
        }
      } finally {
        dispatch(setProfileLoading(false));
        if (!stillRegistering) {
          dispatch({type: SET_REGISTER_SUBMITTING, payload: false});
          dispatch({type: SET_REGISTER_LOADING, payload: false});
        }
      }
    }
  };
};

export const setUserData = (data) => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_PROFILE, payload: data });
  };
};

export const setEditProfileData = (profile) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_EDIT_PROFILE_ERRORS, payload: {} });
      let formData = {};
      formData = {
        id: profile.userId,
        title: 'Edit My Info',
        first_name: profile.first_name,
        last_name: profile.last_name,
        showMyNameAs:
          profile.showMyNameAs === null || profile.showMyNameAs === ''
            ? 'fullName'
            : profile.showMyNameAs,
        address: profile.address,
        city: profile.city,
        zip_code: profile.zip_code,
        state: profile.state,
        phone_number: profile.phone_number,
        email: profile.email,
        officialInformations: profile.officialInformations
          ? profile.officialInformations
          : null,
        dismissal: false,
      };
      if (profile.officialInformations) {
        formData.organization_name = profile.officialInformations
          .organization_name
          ? profile.officialInformations.organization_name
          : '';
        formData.position = profile.officialInformations.position
          ? profile.officialInformations.position
          : '';
      }
      dispatch({ type: SET_EDIT_PROFILE_DATA, payload: formData });
      return resolve(true);
    });
  };
};

export const editProfileChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    if (name === 'phone_number') {
      formData[name] = formatPhoneNumber(value);
    } else {
      formData[name] = value;
    }
    return dispatch({ type: EDIT_PROFILE_CHANGE, payload: formData });
  };
};

export const fillAddresses = (field, address) => {
  return async (dispatch, getState) => {
    if (address === null || address === undefined) return;
    let formData = { address: '', zip_code: '', city: '', state: '' };
    for (const component of address) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          formData.address = component.long_name;
          break;
        }

        case 'route': {
          formData.address = formData.hasOwnProperty('address')
            ? formData.address + ' ' + component.short_name
            : component.short_name;
          break;
        }

        case 'postal_code': {
          formData.zip_code = component.long_name;
          break;
        }
        case 'locality':
          formData.city = component.long_name;
          break;
        case 'administrative_area_level_1': {
          formData.state = component.short_name;
          break;
        }
        default: {
          field.current.value = formData.address;
          break;
        }
      }
    }
    return dispatch({ type: EDIT_PROFILE_CHANGE, payload: formData });
  };
};

export const validateProfile = (isOfficial) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let profile = getState().account.editProfileData;
      let profileData = {
        first_name: 'required',
        last_name: 'required',
        phone_number: 'required|telephone',
      };
      if (isOfficial) {
        profileData.organization_name = 'required';
        profileData.position = 'required';
        profileData.showMyNameAs = 'required';
        profileData.email = 'required|email';
      } else {
        profileData.address = 'required';
        profileData.city = 'required';
        profileData.state = 'required';
        profileData.zip_code = 'required';
      }

      const { isValid, errors } = allFieldsValidation(profile, profileData, {
        'required.first_name': 'Required',
        'required.last_name': 'Required',
        'required.address': 'Required',
        'required.city': 'Required',
        'required.state': 'Required',
        'required.zip_code': 'Required',
        'required.email': 'Required',
        'required.organization_name': 'Required',
        'required.position': 'Required',
        'required.showMyNameAs': 'Required',
        'required.phone_number': 'Required',
      });
      if (!isValid) {
        dispatch({ type: SET_EDIT_PROFILE_ERRORS, payload: errors });
        return reject(errors);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitProfileForm = (isOfficial = false) => {
  return async (dispatch, getState) => {
    dispatch(validateProfile(isOfficial))
      .then(async () => {
        dispatch({ type: SET_EDIT_PROFILE_ERRORS, payload: {} });
        if (isOfficial) dispatch(officialProfileSubmit(isOfficial));
        else dispatch(residentProfileSubmit(isOfficial));
      })
      .catch((error) => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const officialProfileSubmit = () => {
  return async (dispatch, getState) => {
    let profile = getState().account.editProfileData;
    let editProfileInfo = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      phone_number: profile.phone_number,
      showMyNameAs: profile.showMyNameAs,
      organization_name: profile.organization_name,
      position: profile.position,
      updatePOprofile: true,
    };
    dispatch({ type: SET_PROFILE_LOADING, payload: true });

    await axios
      .post(BASE_API_URL + '/users/editOfficialInfo', editProfileInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'dashboard',
          }),
        );
        let formData = {};
        formData['dismissal'] = true;
        dispatch({ type: EDIT_PROFILE_CHANGE, payload: formData });
        if (typeof response.data.data !== 'undefined') {
          let userFormData = {};
          userFormData = response.data.data.userData;
          dispatch(setUserData(userFormData));
        }
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        errorList.forEach((error, index) => {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList[index],
              module: 'dashboard',
            }),
          );
        });
      })
      .finally(() => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const residentProfileSubmit = () => {
  return async (dispatch, getState) => {
    let profile = getState().account.editProfileData;
    let editProfileInfo = {
      userId: profile.id,
      first_name: profile.first_name,
      last_name: profile.last_name,
      showMyNameAs: profile.showMyNameAs,
      address: profile.address,
      city: profile.city,
      state: profile.state,
      zip_code: profile.zip_code,
      phone_number: profile.phone_number,
      email: profile.email,
    };
    dispatch({ type: SET_PROFILE_LOADING, payload: true });

    await axios
      .post(BASE_API_URL + '/users/editMyProfile', editProfileInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'dashboard',
          }),
        );
        let formData = {};
        formData['dismissal'] = true;
        dispatch({ type: EDIT_PROFILE_CHANGE, payload: formData });
        if (typeof response.data.data !== 'undefined') {
          if (typeof response.data.data.profileCompletion !== 'undefined') {
            let userFormData = {};
            userFormData = response.data.data.userData;
            userFormData['profileCompletion'] =
              response.data.data.profileCompletion;
            dispatch(setUserData(userFormData));
          }
        }
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        errorList.forEach((error, index) => {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList[index],
              module: 'dashboard',
            }),
          );
        });
      })
      .finally(() => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const setFormType = (formType) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_FORM_TYPE, payload: formType });
  }
}

export const setProfileFormData = (user, formType) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let title = '';
      if (formType === 'aboutMe') {
        title = 'Edit About';
      } else if (formType === 'politicalAffiliation') {
        title = 'Edit Political Affiliation';
      } else if (formType === 'politicalBackground') {
        title = 'Edit Political Background';
      } else if (formType === 'whatDoIStandFor') {
        title = 'Edit What You Stand For';
      }

      let aboutMeJson = '';
      if (user.aboutMe) {
        if (user.selected_language === user.originalLanguage) {
          aboutMeJson = user.aboutMe;
        } else {
          if (JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe) {
            aboutMeJson = JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe;
          }
          else {
            aboutMeJson = user.aboutMe;
          }
        }
      }
      let aboutMe_html = '';
      if (user.aboutMe_html) {
        if (user.selected_language === user.originalLanguage) {
          aboutMe_html = user.aboutMe_html;
        } else {
          if (JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe) {
            aboutMe_html = JSON.parse(user.translation)?.[user?.selected_language]?.aboutMe;
          }
          else {
            aboutMe_html = user.aboutMe_html;
          }
        }
      }

      let politicalAffiliationJson = '';
      let politicalAffiliation_html = '';
      let politicalBackgroundJson = '';
      let politicalBackground_html = '';
      let whatDoIStandForJson = '';
      let whatDoIStandFor_html = '';
      if (user.roleName === 'PUBLICOFFICIAL') {
        if (user.officialInformations.politicalAffiliation) {
          if (user.selected_language === user.originalLanguage) {
            politicalAffiliationJson = user.officialInformations.politicalAffiliation;
          } else {
            if (JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation) {
              politicalAffiliationJson = JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation;
            }
            else {
              politicalAffiliationJson = user.officialInformations.politicalAffiliation;
            }
          }
        }
        if (user.officialInformations.politicalAffiliation_html) {
          if (user.selected_language === user.originalLanguage) {
            politicalAffiliation_html = user.officialInformations.politicalAffiliation_html;
          } else {
            if (JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation) {
              politicalAffiliation_html = JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalAffiliation;
            }
            else {
              politicalAffiliation_html = user.officialInformations.politicalAffiliation_html;
            }
          }
        }

        if (user.officialInformations.politicalBackground) {
          if (user.selected_language === user.originalLanguage) {
            politicalBackgroundJson = user.officialInformations.politicalBackground;
          } else {
            if (JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalBackground) {
              politicalBackgroundJson = JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalBackground;
            }
            else {
              politicalBackgroundJson = user.officialInformations.politicalBackground;
            }
          }
        }
        if (user.officialInformations.politicalBackground_html) {
          if (user.selected_language === user.originalLanguage) {
            politicalBackground_html = user.officialInformations.politicalBackground_html;
          } else {
            if (JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalBackground) {
              politicalBackground_html = JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.politicalBackground;
            }
            else {
              politicalBackground_html = user.officialInformations.politicalBackground_html;
            }
          }
        }

        if (user.officialInformations.whatDoIStandFor) {
          if (user.selected_language === user.originalLanguage) {
            whatDoIStandForJson = user.officialInformations.whatDoIStandFor;
          } else {
            if (JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor) {
              whatDoIStandForJson = JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor;
            }
            else {
              whatDoIStandForJson = user.officialInformations.whatDoIStandFor;
            }
          }
        }
        if (user.officialInformations.whatDoIStandFor_html) {
          if (user.selected_language === user.originalLanguage) {
            whatDoIStandFor_html = user.officialInformations.whatDoIStandFor_html;
          } else {
            if (JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor) {
              whatDoIStandFor_html = JSON.parse(user.officialInformations?.translation)?.[user?.selected_language]?.whatDoIStandFor;
            }
            else {
              whatDoIStandFor_html = user.officialInformations.whatDoIStandFor_html;
            }
          }
        }
        
      }
      
      formData = {
        id: user.userId,
        title: title,
        aboutMe: aboutMeJson,
        aboutMe_html: aboutMe_html,
        politicalAffiliation: politicalAffiliationJson,
        politicalAffiliation_html: politicalAffiliation_html,
        politicalBackground: politicalBackgroundJson,
        politicalBackground_html: politicalBackground_html,
        whatDoIStandFor: whatDoIStandForJson,
        whatDoIStandFor_html: whatDoIStandFor_html,
        dismissal: false,
      };
      dispatch({ type: SET_EDIT_PROFILE_FORM_DATA, payload: formData });
      return resolve(true);
    });
  };
};

export const editProfileFormChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: EDIT_PROFILE_FORM_CHANGE, payload: formData });
  };
};

export const profileEditorChange = (name, value, stringify = true, isHtml) => {
  let formData = {};
  return async (dispatch, getState) => {
    let newName = getState().account.formType;
    if (isHtml) {
      newName = newName + '_html';
    }
    formData[newName] = stringify ? JSON.stringify(value) : value;
    return dispatch({ type: EDIT_PROFILE_FORM_CHANGE, payload: formData });
  };
};

export const validateProfileForm = (formType = null) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (formType === null) {
        return reject('Invalid Data!');
      }
      let formData = getState().account.editProfileFormData;
      let user = getState().account.user;
      let formDataValue = {};
      let formValidation = {};
      if (formType === 'aboutMe') {
        formDataValue = {
          aboutMe:
            user.roleName === 'PUBLICOFFICIAL' || user.roleName === 'ADMIN'
              ? 'max:3000'
              : 'max:1500',
        };
        formValidation = {
          'max.aboutMe':
            user.roleName === 'PUBLICOFFICIAL' || user.roleName === 'ADMIN'
              ? 'The About Me may not be greater than 3000 characters.'
              : 'The About Me may not be greater than 1500 characters.',
        };
      } else if (formType === 'politicalAffiliation') {
        formDataValue = { politicalAffiliation: 'max:3000' };
        formValidation = {
          'max.politicalAffiliation':
            'The political affiliation may not be greater than 3000 characters.',
        };
      } else if (formType === 'politicalBackground') {
        formDataValue = { politicalBackground: 'max:3000' };
        formValidation = {
          'max.politicalBackground':
            'The political background may not be greater than 3000 characters.',
        };
      } else if (formType === 'whatDoIStandFor') {
        formDataValue = { whatDoIStandFor: 'max:3000' };
        formValidation = {
          'max.whatDoIStandFor':
            'The sentence may not be greater than 3000 characters.',
        };
      }

      const { isValid, errors } = allFieldsValidation(
        formData,
        formDataValue,
        formValidation,
      );
      if (!isValid) {
        dispatch({ type: SET_EDIT_PROFILE_FORM_ERRORS, payload: errors });
        return reject(errors);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitProfileEditForm = (formType) => {
  return async (dispatch, getState) => {
    dispatch(validateProfileForm(formType))
      .then(async () => {
        dispatch({ type: SET_EDIT_PROFILE_FORM_ERRORS, payload: {} });
        dispatch(ProfileEditFormSubmit(formType));
      })
      .catch((error) => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const ProfileEditFormSubmit = (formType) => {
  return async (dispatch, getState) => {
    let editFormData = getState().account.editProfileFormData;
    let user = getState().account.user;
    let uri =
      user.roleName === 'PUBLICOFFICIAL'
        ? '/official/editMyBio'
        : '/users/editMyBio';
    let dataInfo = {};
    if (formType === 'aboutMe') {
      dataInfo = { 
        userId: editFormData.id,
        aboutMe: editFormData.aboutMe, 
        aboutMe_html: editFormData.aboutMe_html 
      };
    } else if (formType === 'politicalAffiliation') {
      dataInfo = {
        userId: editFormData.id,
        politicalAffiliation: editFormData.politicalAffiliation,
        politicalAffiliation_html: editFormData.politicalAffiliation_html,
      };
    } else if (formType === 'politicalBackground') {
      dataInfo = {
        userId: editFormData.id,
        politicalBackground: editFormData.politicalBackground,
        politicalBackground_html: editFormData.politicalBackground_html, 
      };
    } else if (formType === 'whatDoIStandFor') {
      dataInfo = {
        userId: editFormData.id,
        whatDoIStandFor: editFormData.whatDoIStandFor,
        whatDoIStandFor_html: editFormData.whatDoIStandFor_html,
      };
    }

    dispatch({ type: SET_PROFILE_LOADING, payload: true });

    await axios
      .post(BASE_API_URL + uri, dataInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'dashboard',
          }),
        );
        let formData = {};
        formData['dismissal'] = true;
        dispatch({ type: EDIT_PROFILE_FORM_CHANGE, payload: formData });
        if (typeof response.data.data !== 'undefined') {
          if (typeof response.data.data.profileCompletion !== 'undefined') {
            let userFormData = {};
            userFormData = response.data.data.userData;
            userFormData['profileCompletion'] = response.data.data.profileCompletion;
            userFormData.officialInformations = { 
              politicalAffiliation: response.data.data?.officialInformations?.politicalAffiliation,
              politicalAffiliation_html: response.data.data?.officialInformations?.politicalAffiliation_html,
              politicalBackground: response.data.data?.officialInformations?.politicalBackground,
              politicalBackground_html: response.data.data?.officialInformations?.politicalBackground_html,
              whatDoIStandFor: response.data.data?.officialInformations?.whatDoIStandFor,
              whatDoIStandFor_html: response.data.data?.officialInformations?.whatDoIStandFor_html,
            }
            
            dispatch(setUserData(userFormData));
          }
        }
      })
      .catch((error) => {
        let errorList = error?.response?.data?.error;
        if (typeof errorList === 'object') {
          errorList.forEach((error, index) => {
            dispatch(
              addNotification({
                type: 'error',
                message: errorList[index],
                module: 'dashboard',
              }),
            );
          });
        } else {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList,
              module: 'dashboard',
            }),
          );
        }
      })
      .finally(() => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const setProfileImgData = (profile) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      formData = {
        id: profile.userId,
        title: 'Upload your profile image',
        profile_picture: profile.profile_picture,
        dismissal: false,
        link: null,
        file: [],
        linkToBeRemoved: [],
      };
      dispatch({ type: SET_PROFILE_IMG_DATA, payload: formData });
      return resolve(true);
    });
  };
};

export const changeProfileData = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData = getState().account.profileImgData;
    formData[name] = value[0];
    return dispatch({ type: SET_PROFILE_IMG_DATA, payload: formData });
  };
};

export const removeProfileImg = (path, index) => {
  let formData = {};
  return async (dispatch, getState) => {
    let link = getState().account.profileImgData.link;
    if (link !== null && link.length > 0) {
      //let linkToBeRemoved = getState().engagement.engagementFormData.linksToBeRemoved;
      formData['linksToBeRemoved'] = link;
      formData['links'] = [];
      return dispatch({ type: EDIT_PROFILE_IMG_CHANGE, payload: formData });
    }
  };
};

export const profileImgDataChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: EDIT_PROFILE_IMG_CHANGE, payload: formData });
  };
};

export const setProfileBgImgData = (profile) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      formData = {
        id: profile.userId,
        title: 'Upload your profile image',
        profile_bg_picture: profile.profile_bg_picture,
        dismissal: false,
        link: null,
        file: [],
        linkToBeRemoved: [],
      };
      dispatch({ type: SET_PROFILE_BG_IMG_DATA, payload: formData });
      return resolve(true);
    });
  };
};

export const changeProfileBgData = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData = getState().account.profileBgImgData;
    formData[name] = value[0];
    return dispatch({ type: SET_PROFILE_BG_IMG_DATA, payload: formData });
  };
};

export const removeProfileBgImg = (path, index) => {
  let formData = {};
  return async (dispatch, getState) => {
    let link = getState().account.profileBgImgData.link;
    if (link !== null && link.length > 0) {
      //let linkToBeRemoved = getState().engagement.engagementFormData.linksToBeRemoved;
      formData['linksToBeRemoved'] = link;
      formData['links'] = [];
      return dispatch({ type: EDIT_PROFILE_BG_IMG_CHANGE, payload: formData });
    }
  };
};

export const profileBgImgDataChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: EDIT_PROFILE_BG_IMG_CHANGE, payload: formData });
  };
};

export const uploadProfileImage = (
  type = 'profileImage',
  file,
  fileName,
  userId,
  initial = false,
) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        if (type === 'profileBgImage') {
          //formData = { 'userId': userId, 'profile_bg_picture':  file }
          formData.append('userId', userId);
          formData.append('profile_bg_picture', file);
        } else {
          //formData = { 'userId': userId, 'profile_picture': file }
          formData.append('userId', userId);
          formData.append('profile_picture', file);
        }

        let uri = '/official/updateProfilePicture';
        uri =
          type === 'profileBgImage'
            ? '/official/updateProfileBGPicture'
            : '/official/updateProfilePicture';

        await axios
          .post(BASE_API_URL + uri, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'user-token': localStorage.getItem('token'),
            },
          })
          .then((response) => {
            let userFormData = {};
            if (typeof response.data.data.profileCompletion !== 'undefined') {
              userFormData['profileCompletion'] =
                response.data.data.profileCompletion;
            }

            if (type === 'profileImage') {
              userFormData['profile_picture'] =
                response.data.data.profile_picture;
            } else {
              userFormData['profile_bg_picture'] =
                response.data.data.profile_bg_picture;
            }

            dispatch(setUserData(userFormData));
            if (initial === false) {
              dispatch(
                addNotification({
                  type: 'success',
                  message: 'Profile image uploaded successfully',
                  module: 'dashboard',
                }),
              );
            }
            return resolve(true);
          })
          .catch((error) => {
            let errorList = error.response.data.error;
            errorList.forEach((error, index) => {
              dispatch(
                addNotification({
                  type: 'error',
                  message: errorList[index],
                  module: 'dashboard',
                }),
              );
              return reject(errorList[index]);
            });
          });
      } catch (e) {
        dispatch(
          addNotification({
            type: 'error',
            message: 'Unable upload the profile image.',
            module: 'dashboard',
          }),
        );
        return reject(e);
      }
    });
  };
};

export const createInitialImg = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let user = getState().account.user;
      let imgTxt = profileImageText(user);
      let newImage = await generateProfileImage(imgTxt);
      try {
        dispatch(
          uploadProfileImage(
            'profileImage',
            newImage,
            'profile-' + user.userId,
            user.userId,
            true,
          ),
        ).then(() => {
          return resolve(true);
        });
      } catch (e) {
        return reject(e);
      }
    });
  };
};

export const generateProfileImage = (imgTxt) => {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement('canvas');

    var ctx = canvas.getContext('2d');
    ctx.canvas.width = 110;
    ctx.canvas.height = 110;
    ctx.fillStyle = '#E4E8F0';
    ctx.fillRect(0, 0, 110, 110);

    ctx.fillStyle = '#000';
    ctx.font = '40px Arial';
    ctx.fillText(imgTxt, 30, 70);
    canvas.toBlob(
      (blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        blob.name = 'profile.jpeg';
        resolve(blob);
      },
      'image/jpeg',
      1,
    );
  });
};

export const createInitialBgImg = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let user = getState().account.user;
      let newImage = await generateProfileBgImage();
      try {
        dispatch(
          uploadProfileImage(
            'profileBgImage',
            newImage,
            'profile-bg-' + user.userId,
            user.userId,
            true,
          ),
        ).then(() => {
          return resolve(true);
        });
      } catch (e) {
        return reject(e);
      }
    });
  };
};

export const generateProfileBgImage = () => {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement('canvas');

    var ctx = canvas.getContext('2d');
    ctx.canvas.width = 810;
    ctx.canvas.height = 240;
    ctx.fillStyle = '#E4E8F0';
    ctx.fillRect(0, 0, 810, 240);

    ctx.fillStyle = '#000';
    ctx.font = '40px Arial';
    //ctx.fillText(imgTxt, 30, 70);
    canvas.toBlob(
      (blob) => {
        // returning an error
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }

        blob.name = 'profile.jpeg';
        resolve(blob);
      },
      'image/jpeg',
      1,
    );
  });
};

export const setSocialLinkData = (profile) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = getState().account.socialLinkData;
      let socialInitialData = profile.socialPlatform;
      const initialValue = {
        facebook: 'https://www.facebook.com/',
        twitter: 'https://twitter.com/',
        linkedIn: 'https://www.linkedin.com/',
        nextDoor: 'https://nextdoor.com/',
        email: '',
      };
      if (socialInitialData.facebook === '') {
        socialInitialData.facebook = initialValue.facebook;
      }
      if (socialInitialData.twitter === '') {
        socialInitialData.twitter = initialValue.twitter;
      }
      if (socialInitialData.linkedIn === '') {
        socialInitialData.linkedIn = initialValue.linkedIn;
      }
      if (
        socialInitialData.nextDoor === '' ||
        socialInitialData.nextDoor === null
      ) {
        socialInitialData.nextDoor = initialValue.nextDoor;
      }
      if (socialInitialData.email === '' || socialInitialData.email === null) {
        socialInitialData.email = initialValue.email;
      }

      formData['socialPlatform'] = socialInitialData;
      formData['initialValue'] = initialValue;
      dispatch({ type: SET_SOCIAL_LINKS_DATA, payload: formData });
      return resolve(true);
    });
  };
};

export const editSocialLinkData = (name, value) => {
  return async (dispatch, getState) => {
    let formData = getState().account.socialLinkData;
    const initialValue = getState().account.socialLinkData['initialValue'];

    let initValLength = initialValue[name].length;
    if (value.slice(0, initValLength) !== initialValue[name]) {
      if (value === initialValue[name].slice(0, initValLength - 1)) {
        value = '';
      } else {
        value = initialValue[name] + value;
      }
    }
    if (value === initialValue[name]) {
      value = '';
    }

    formData['socialPlatform'] = {
      ...formData['socialPlatform'],
      ...{ [name]: value },
    };
    dispatch({ type: EDIT_SOCIAL_LINKS, payload: formData });
  };
};

export const validateSocialLinkForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let socialLink = getState().account.socialLinkData['socialPlatform'];
      let socialLinkData = {
        email: 'email',
      };

      const { isValid, errors } = allFieldsValidation(
        socialLink,
        socialLinkData,
        {
          'email.email': 'Email format is invalid.',
        },
      );
      if (!isValid) {
        dispatch({ type: SET_SOCIAL_LINK_ERRORS, payload: errors });
        return reject(errors);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitSocialLinkForm = (profile) => {
  return async (dispatch, getState) => {
    dispatch(validateSocialLinkForm())
      .then(async () => {
        dispatch({ type: SET_SOCIAL_LINK_ERRORS, payload: {} });
        dispatch(SocialLinkFormSubmit());
      })
      .catch((error) => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const SocialLinkFormSubmit = () => {
  return async (dispatch, getState) => {
    let socialLinkData = getState().account.socialLinkData;
    let socialLinkInfo = {
      userId: socialLinkData.id,
      facebook: socialLinkData.socialPlatform['facebook'],
      twitter: socialLinkData.socialPlatform['twitter'],
      linkedIn: socialLinkData.socialPlatform['linkedIn'],
      nextDoor: socialLinkData.socialPlatform['nextDoor'],
      email: socialLinkData.socialPlatform['email'],
    };

    dispatch({ type: SET_PROFILE_LOADING, payload: true });

    await axios
      .post(BASE_API_URL + '/users/saveSocialPlatform', socialLinkInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'dashboard',
          }),
        );
        let formData = {};
        formData['dismissal'] = true;
        dispatch({ type: EDIT_SOCIAL_LINKS, payload: formData });
        if (typeof response.data.data !== 'undefined') {
          let userFormData = {};
          if (typeof response.data.data.profileCompletion !== 'undefined') {
            //userFormData = response.data.data;
            userFormData['profileCompletion'] =
              response.data.data.profileCompletion;
            dispatch(setUserData(userFormData));
          }
          if (typeof response.data.data.socialPlatform !== 'undefined') {
            //userFormData = response.data.data;
            userFormData['socialPlatform'] = response.data.data.socialPlatform;
            dispatch(setUserData(userFormData));
          }
        }
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        errorList.forEach((error, index) => {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList[index],
              module: 'dashboard',
            }),
          );
        });
      })
      .finally(() => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const idMeVerify = (code) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let email = localStorage.getItem('email');
      let data = { code: code, email: email };
      await axios
        .post(BASE_API_URL + '/idmeVerification', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            if (typeof getState().account.user.roleName !== 'undefined') {
              let userFormData = {};
              userFormData['profileCompletion'] =
                response.data.data.profileCompletion;
              userFormData['isIDmeVerified'] =
                response.data.data.isIDmeVerified;
              if (typeof response.data.data.first_name !== 'undefined') {
                if (response.data.data.first_name !== '') {
                  userFormData['first_name'] = response.data.data.first_name;
                }
              }
              if (typeof response.data.data.last_name !== 'undefined') {
                if (response.data.data.last_name !== '') {
                  userFormData['last_name'] = response.data.data.last_name;
                }
              }
              dispatch(setUserData(userFormData));
            }
          }
          //localStorage.removeItem('email');
          return resolve(true);
        })
        .catch((error) => {
          return reject(error.response.data.error);
        });
      return resolve(true);
    });
  };
};

export const setOptionalInfoData = (profile) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      formData = {
        id: profile.userId,
        title: 'My Optional Information',
        dismissal: false,
        highestEducationDegree: profile.highest_edu,
        employmentStatus: profile.employment_status,
        race:
          profile.race === '' || profile.race === null
            ? '{[]}'
            : JSON.parse(profile.race),
        martialStatus: profile.marital_status,
        politicalAffiliation: profile.political_affiliation,
        religion: profile.religion,
      };
      dispatch({ type: SET_EDIT_OPTIONAL_DATA, payload: formData });
      return resolve(true);
    });
  };
};

export const optionalInfoChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: EDIT_OPTIONAL_CHANGE, payload: formData });
  };
};

export const optionalFormSubmit = () => {
  return async (dispatch, getState) => {
    let optionalData = getState().account.optionalInfoData;
    let dataInfo = {};
    let race = '';
    if (
      typeof optionalData.race !== 'undefined' &&
      optionalData.race !== null &&
      optionalData.race !== '' &&
      optionalData.race !== '{[]}'
    ) {
      Object.keys(optionalData.race).forEach(function (key) {
        if (key !== '0') {
          race += ',';
        }
        race += '{"value":"' + optionalData.race[key].value + '"}';
      });
      race = '[' + race + ']';
    } else {
      race = null;
    }

    dataInfo = {
      userId: optionalData.id,
      highest_edu:
        typeof optionalData.highestEducationDegree === 'object' &&
        optionalData.highestEducationDegree !== null
          ? optionalData.highestEducationDegree.value
          : optionalData.highestEducationDegree,
      employment_status:
        typeof optionalData.employmentStatus === 'object' &&
        optionalData.employmentStatus !== null
          ? optionalData.employmentStatus.value
          : optionalData.employmentStatus,
      race_or_ethnicity: race,
      marital_status:
        typeof optionalData.martialStatus === 'object' &&
        optionalData.martialStatus !== null
          ? optionalData.martialStatus.value
          : optionalData.martialStatus,
      political_affiliation:
        typeof optionalData.politicalAffiliation === 'object' &&
        optionalData.politicalAffiliation !== null
          ? optionalData.politicalAffiliation.value
          : optionalData.politicalAffiliation,
      religion:
        typeof optionalData.religion === 'object' &&
        optionalData.religion !== null
          ? optionalData.religion.value
          : optionalData.religion,
    };
    dispatch({ type: SET_PROFILE_LOADING, payload: true });

    await axios
      .post(BASE_API_URL + '/users/updateOptionalInformation', dataInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'dashboard',
          }),
        );
        let formData = {};
        formData['dismissal'] = true;
        dispatch({ type: EDIT_OPTIONAL_CHANGE, payload: formData });
        if (typeof response.data.data !== 'undefined') {
          let userFormData = {};
          //{"success":"true","message":"Details Updated Successfully!","data":{"profileCompletion":"80%","optionalInformations":{"highest_edu":"graduate","employment_status":null,"race_or_ethnicity":"{}","marital_status":null,"religion":null,"political_affiliation":null}}}
          userFormData['profileCompletion'] =
            response.data.data.profileCompletion;
          //userFormData['highestEducationDegree'] = response.data.data.optionalInformations.highest_edu;
          userFormData['highest_edu'] =
            response.data.data.optionalInformations.highest_edu;
          //userFormData['employmentStatus'] = response.data.data.optionalInformations.employment_status;
          userFormData['employment_status'] =
            response.data.data.optionalInformations.employment_status;
          userFormData['race'] =
            response.data.data.optionalInformations.race_or_ethnicity;
          userFormData['ethnicity'] =
            response.data.data.optionalInformations.ethnicity;
          userFormData['race_or_ethnicity'] =
            response.data.data.optionalInformations.race_or_ethnicity;
          //userFormData['martialStatus'] = response.data.data.optionalInformations.marital_status;
          userFormData['marital_status'] =
            response.data.data.optionalInformations.marital_status;
          //userFormData['politicalAffiliation'] = response.data.data.optionalInformations.political_affiliation;
          userFormData['political_affiliation'] =
            response.data.data.optionalInformations.political_affiliation;
          userFormData['religion'] =
            response.data.data.optionalInformations.religion;
          dispatch(setUserData(userFormData));
        }
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        if (typeof errorList === 'string') {
          errorList = [errorList];
        }
        errorList.forEach((error, index) => {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList[index],
              module: 'dashboard',
            }),
          );
        });
      })
      .finally(() => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const setPasswordFormData = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let user = getState().account.user;
      let formData = {};
      formData = {
        id: user.userId,
        title: 'Reset Password',
        dismissal: false,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      };
      dispatch({ type: SET_EDIT_PASSWORD_DATA, payload: formData });
      return resolve(true);
    });
  };
};

export const passwordFormChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: EDIT_PASSWORD_FORM_CHANGE, payload: formData });
  };
};

export const validatePasswordForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: SET_PASSWORD_FORM_ERRORS, payload: {} });
      let passwordFormData = getState().account.passwordData;
      let passwordRules = {
        oldPassword: 'required',
        newPassword: 'required|password|different:oldPassword',
        confirmPassword: 'required|same:newPassword',
      };

      const { isValid, errors } = allFieldsValidation(
        passwordFormData,
        passwordRules,
        {
          'required.oldPassword': 'Required',
          'required.newPassword': 'Required',
          'different.newPassword':
            'New password should not be same as old password.',
          'required.confirmPassword': 'Required',
          'same.confirmPassword':
            'Confirm password and New password fields must match.',
        },
      );
      if (!isValid) {
        dispatch({ type: SET_PASSWORD_FORM_ERRORS, payload: errors });
        return reject(errors);
      } else {
        resolve(true);
      }
    });
  };
};

export const passwordFormSubmit = () => {
  return async (dispatch, getState) => {
    dispatch(validatePasswordForm())
      .then(async () => {
        dispatch({ type: SET_PASSWORD_FORM_ERRORS, payload: {} });
        dispatch(resetPasswordSubmit());
      })
      .catch((error) => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const resetPasswordSubmit = () => {
  return async (dispatch, getState) => {
    let passwordData = getState().account.passwordData;
    let dataInfo = {
      userId: passwordData.id,
      new_password: passwordData.newPassword,
      old_password: passwordData.oldPassword,
    };

    dispatch({ type: SET_PROFILE_LOADING, payload: true });

    await axios
      .post(BASE_API_URL + '/changePassword', dataInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'dashboard',
          }),
        );
        let formData = {};
        formData['dismissal'] = true;
        dispatch({ type: EDIT_PASSWORD_FORM_CHANGE, payload: formData });
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        if (Array.isArray(errorList) === true) {
          errorList.forEach((error, index) => {
            dispatch(
              addNotification({
                type: 'error',
                message: errorList[index],
                module: 'dashboard',
              }),
            );
          });
        } else {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList,
              module: 'dashboard',
            }),
          );
        }
      })
      .finally(() => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const reportFormChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    return dispatch({ type: REPORT_FORM_CHANGE, payload: formData });
  };
};

export const resetReportForm = () => {
  return async (dispatch, getState) => {
    return dispatch({
      type: SET_REPORT_FORM_RESET,
    });
  };
};
export const validateReportForm = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let report = getState().account.reportFormData;
      let errorList = {};

      let reportData = {
        description: 'required',
      };
      const { isValid, errors } = allFieldsValidation(report, reportData, {
        'required.description': 'Required',
      });

      errorList = { ...errorList, ...errors };

      if (!isValid) {
        dispatch({ type: SET_REPORT_FORM_ERRORS, payload: errorList });
        reject(errorList);
      } else {
        resolve(true);
      }
    });
  };
};

export const submitReportForm = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_REPORT_LOADING, payload: true });
    dispatch({ type: SET_REPORT_SUBMITTING, payload: true });
    dispatch(validateReportForm())
      .then(async () => {
        dispatch({ type: SET_REPORT_FORM_ERRORS, payload: {} });
        dispatch(reportSubmit());
      })
      .catch((error) => {
        dispatch({ type: SET_REPORT_SUBMITTING, payload: false });
        dispatch({ type: SET_REPORT_LOADING, payload: false });
      });
  };
};

export const reportSubmit = () => {
  return async (dispatch, getState) => {
    let report = getState().account.reportFormData;

    let reportInfo = {
      reportLink: report.reportLink,
      description: report.description,
    };
    dispatch({ type: SET_REPORT_LOADING, payload: true });
    await axios
      .post(BASE_API_URL + '/users/reportAnIssue', reportInfo)
      .then((response) => {
        dispatch(
          addNotification({
            type: 'success',
            message: response.data.message,
            module: 'account',
          }),
        );
        setTimeout(function () {
          dispatch(reportFormChange('dismissal', true));
        }, 3000);
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        errorList.forEach((error, index) => {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList[index],
              module: 'account',
            }),
          );
        });
      })
      .finally(() => {
        dispatch(resetReportForm());
        dispatch({ type: SET_REPORT_SUBMITTING, payload: false });
        dispatch({ type: SET_REPORT_LOADING, payload: false });
      });
  };
};

export const disableFirstLogin = () => {
  return async (dispatch, getState) => {
    /*
    let user = getState().account.user;
    let dataInfo = {};
    dataInfo = { userId: user.userId, isFirstLogin: false}
    await axios.post(BASE_API_URL + '/users/disableFirstLogin', dataInfo).then(response => {
      if(typeof response.data.data !== 'undefined') {
        let userFormData ={};
        userFormData['isFirstLogin'] = response.data.data.firstLogin;
        dispatch(setUserData(userFormData));
      }
    })*/
    let userFormData = {};
    userFormData['isFirstLogin'] = false;
    dispatch(setUserData(userFormData));
  };
};

export const languageChangeSubmit = (languageCode) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_PROFILE_LOADING, payload: true });

    await axios.post(
      BASE_API_URL + '/users/updateSelectedLanguage',
      {
        language: languageCode,
      }
    )
      .then((response) => {
        dispatch({ type: SET_SELECTED_LANGUAGE, payload: languageCode });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: SET_PROFILE_LOADING, payload: false });
      });
  };
};

export const languageChangeSubmitLocal = (languageCode) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_LANGUAGE, payload: languageCode });
  }
};

export const getInactiveStatusMesages = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.account.user;
    // If user does not have a geoid set to 0
    const geoid = user.geoId || 0;
    const roleid = user.roleId;
    if (roleid === 3 || roleid === undefined) {
      try {
        const response = await axios.get(
          BASE_API_URL + `/users/inactive-region-message/${geoid}`,
        );
        if (response.data.success === 'true') {
          dispatch({
            type: SET_INACTIVE_MESSAGES,
            payload: response.data.data,
          });
        }
      } catch (error) {}
    }
  };
};

// TODO: upon registration, clear "guest_user" from local storage. Legacy comment, should not affect anything. Confirm.
export const guestProfileRegistration = () => {
  return async (dispatch, getState) => {
    await axios
        .post(BASE_API_URL + '/newuser')
        .then((response) => {
          localStorage.setItem('guest_user', true);
          localStorage.setItem('token', response.data.token);

          setToken(response.data.token, true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
};

export const guestProfileLogin = () => {
  return async (dispatch, getState) => {
    await axios
        .get(BASE_API_URL + '/newuser')
        .then((response) => {
          dispatch({type: FETCH_PROFILE, payload: response.data.user});
          dispatch(getInactiveStatusMesages());
        })
        .catch((error) => {
          console.log(error);
        })
  };
};

export const guestProfileAddressUpdate = (address) => {
  return async (dispatch, getState) => {

    if (address.address_components === null || address.address_components === undefined) return;

    let formData = { address: '', zip_code: '', city: '', state: '' };

    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          formData.address = component.long_name;
          break;
        }

        case 'route': {
          formData.address = formData.hasOwnProperty('address')
              ? formData.address + ' ' + component.long_name
              : component.long_name;
          break;
        }

        case 'postal_code': {
          formData.zip_code = component.long_name;
          break;
        }
        case 'locality':
          formData.city = component.long_name;
          break;
        case 'administrative_area_level_1': {
          formData.state = component.short_name;
          break;
        }
        default: {
          break;
        }
      }
    }

    await axios
        .post(BASE_API_URL + '/newuser/address', formData)
        .then((response) => {
          dispatch({type: FETCH_PROFILE, payload: response.data.user});
          dispatch(getInactiveStatusMesages());
        })
        .catch((error) => {
          console.log(error);
        });
  };
};

export const updateUserNameInfo = (data) => {
  return async (dispatch, getState) => {
    dispatch({type: FETCH_PROFILE, payload: data});
  }
}

export const updateUserDataSubmit = () => {
  return async (dispatch, getState) => {
    let profile = getState().account.user;

    let profileDob = profile.dob;

    if (typeof profileDob === 'object') {
      const year = profileDob.getFullYear();
      const month = profileDob.getMonth() + 1;
      const day = profileDob.getDate();

      profileDob = year + ' ' + month + ' ' + day;
    } else {
      const profileDobString = profile.dob.split('-');
      profileDob = profileDobString[2] + ' ' + profileDobString[1] + ' ' + profileDobString[0];
    }

    let editProfileInfo = {
      userId: profile.id,
      first_name: profile.first_name,
      last_name: profile.last_name,
      showMyNameAs: profile.showMyNameAs,
      address: profile.address,
      city: profile.city,
      state: profile.state,
      zip_code: profile.zip_code,
      phone_number: profile.phone_number,
      email: profile.email,
      dob: profileDob
    };

    await axios
        .post(BASE_API_URL + '/users/editMyProfile', editProfileInfo)
        .then((response) => {
          dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'dashboard',
              }),
          );

          dispatch(fetchProfile());
          // dispatch(verifyUser());

          let formData = {};
          formData['dismissal'] = true;
          dispatch({type: SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN, payload: false});
          dispatch({type: EDIT_PROFILE_CHANGE, payload: formData});
          dispatch({type: EDIT_USER_CHANGE, payload: formData});

          dispatch(existingUserRegistration());

        })
        .catch((error) => {
          throw error;
        })
  };
}

export const editUserChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    if (name === 'phone_number') {
      formData[name] = formatPhoneNumber(value);
    } else {
      formData[name] = value;
    }
    return dispatch({ type: EDIT_USER_CHANGE, payload: formData });
  };
};

export const verifyUser = () => {
  return async (dispatch, getState) => {
    await axios.post(BASE_API_URL + '/register/verify')
        .then((response) => {
          dispatch(fetchProfile());
        }).catch((error) => {
          console.log(error);
        })
  }
}

export const getMyDistricts = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/users/districts`);
      if (response.data.success === 'true') {
        dispatch({ type: GET_MY_DISTRICTS, payload: response.data.data });
      }
    } catch (error) {
      dispatch(addNotification({
        type: 'error',
        message: `Error getting notifications`,
        module: 'dashboard'
      }));
    }
  }
}