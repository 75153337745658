/*
 *
 * Register reducer
 *
 */

import {
  REGISTER_CHANGE,
  REGISTER_RESET,
  SET_REGISTER_LOADING,
  SET_REGISTER_FORM_ERRORS,
  SET_REGISTER_SUBMITTING,
  RESET_APP,
} from './constants';

const initialState = {
  registerFormData: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    signUpType: '',
    officialEmailVerification: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    dob: '',
    phoneNumber: '',
    gender: { value: '' },
    signupStatus: '',
    personalId: '',
    highestEducationDegree: { value: '' },
    employmentStatus: { value: '' },
    race: '',
    ethnicity: { value: '' },
    martialStatus: { value: '' },
    politicalAffiliation: { value: '' },
    religion: { value: '' },
    officialEmail: '',
    signUpOfficialEmail: false,
    verificationBy: '',
    officialPhoneNumber: '',
    position: '',
    positionText: '',
    district: '',
    ocdId: '',
    step: 1,
    message: '',
    suggestionBox: {
      display: false,
      loading: true,
      items: [],
    },
    showEmailConfirmation: false,
    manualVerification: false,
    validateCredentials: false,
    newEmail: '',
    poVerifiedEmail: '',
  },
  formErrors: [],
  isRegisterSubmitting: false,
  isRegisterLoading: false,
};

const defaultState = JSON.stringify(initialState);

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case REGISTER_CHANGE:
      return {
        ...state,
        registerFormData: { ...state.registerFormData, ...action.payload },
      };
    case SET_REGISTER_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };
    case SET_REGISTER_LOADING:
      return {
        ...state,
        isRegisterLoading: action.payload,
      };
    case SET_REGISTER_SUBMITTING:
      return {
        ...state,
        isRegisterSubmitting: action.payload,
      };
    case REGISTER_RESET:
      return {
        ...state,
        registerFormData: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          signUpType: '',
          officialEmailVerification: '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
          dob: '',
          phoneNumber: '',
          gender: { value: '' },
          signupStatus: '',
          personalId: '',
          highestEducationDegree: { value: '' },
          employmentStatus: { value: '' },
          race: '',
          ethnicity: { value: '' },
          martialStatus: { value: '' },
          politicalAffiliation: { value: '' },
          religion: { value: '' },
          officialEmail: '',
          signUpOfficialEmail: false,
          verificationBy: '',
          officialPhoneNumber: '',
          position: '',
          positionText: '',
          district: '',
          ocdId: '',
          step: 1,
          message: '',
          suggestionBox: {
            display: false,
            loading: true,
            items: [],
          },
          showEmailConfirmation: false,
          manualVerification: false,
          validateCredentials: false,
          newEmail: '',
          poVerifiedEmail: '',
        },
        formErrors: {},
        isRegisterLoading: false,
      };
    default:
      return state;
  }
};

export default registerReducer;
