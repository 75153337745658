/*
 *
 * Survey question actions
 *
 */

import {
  SURVEY_CHANGE,
  SURVEY_LOADING,
  SET_SURVEY_RELOAD,
  SET_SURVEY_DATA,
  RESET_APP,
} from './constants';
import axios from 'axios';
import { BASE_API_URL } from '../../constants';
import { addNotification } from '../Notification/actions';

export const resetSurveyState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const surveyChange = (
  id,
  index,
  choiceIndex,
  value,
  type,
  submit = false,
) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[id] = getState().surveyQuestion.surveyData[id];

    formData[id]['question'][index]['choice'].forEach((child, cIndex) => {
      if (cIndex === choiceIndex) {
        formData[id]['question'][index]['choice'][choiceIndex]['answered'] =
          value;
      } else {
        if (type === '1') {
          formData[id]['question'][index]['choice'][cIndex]['answered'] = false;
        }
      }
    });

    if (submit === true) {
      dispatch({ type: SURVEY_CHANGE, payload: formData });
      dispatch(submitSurvey(id));
    } else {
      return dispatch({ type: SURVEY_CHANGE, payload: formData });
    }
  };
};

export const setSurveyDataFromEngagement = (engagement) => {
  let formData = {};
  formData[engagement.id] = {
    id: engagement.id,
    title: engagement.title,
    question: engagement.survey.question,
    dismissal: false,
    error: [],
  };
  return {
    type: SET_SURVEY_DATA,
    payload: formData,
  };
};

export const loadSurveyData = (id) => {
  let formData = {};
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let location = getState().router.location.pathname.replace(
        /^\/|\/$/g,
        '',
      );
      let uri = '/users/civicfeed/' + id;
      let userType = getState().account.user.roleName;

      if (
        location !== 'dashboard/civicfeed' &&
        location !== 'dashboard/searchresult' &&
        location !== 'dashboard/civiclist' &&
        userType === 'PUBLICOFFICIAL'
      ) {
        uri = '/official/engagementSpecific/' + id;
      }

      await axios
        .get(BASE_API_URL + uri)
        .then((response) => {
          if (response.data.success === 'true') {
            let engagement = response.data.data;
            formData[id] = {
              id: id,
              title: engagement.title,
              question: engagement.survey.question,
              dismissal: false,
              error: [],
            };
            dispatch({
              type: SET_SURVEY_DATA,
              payload: formData,
            });
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
          return reject(false);
        });
    });
  };
};

export const submitSurvey = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: SURVEY_LOADING, payload: true });
    let data = getState().surveyQuestion.surveyData[id];
    let userId = getState().account.user.userId;
    let questions = [];
    let selectedChoices = [];
    data.question.forEach((quest, index) => {
      selectedChoices = [];
      quest['choice'].forEach((choice, cIndex) => {
        if (choice.answered === true) {
          selectedChoices.push(choice.id);
        }
      });
      questions = [
        ...questions,
        { id: quest.id, type: quest.type, choice: selectedChoices },
      ];
    });
    let requestData = {
      userId: userId,
      engagementId: id,
      questions: questions,
    };

    await axios
      .post(BASE_API_URL + '/users/userAnswers', requestData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        if (response.data.success === 'true') {
          let formData = {};
          formData = getState().surveyQuestion.surveyData[id];
          formData['dismissal'] = true;

          dispatch({ type: SURVEY_CHANGE, payload: formData });
          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'dashboard',
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'dashboard',
            parentDivClass: 'top-notification',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SURVEY_LOADING, payload: false });
      });
  };
};

export const setReload = (value) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_SURVEY_RELOAD, payload: value });
  };
};
