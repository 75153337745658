/**
 *
 * Display All District Officials List
 *
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import Notification from '../../../containers/Notification';
import LoadingIndicator from '../../../components/Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const OfficialsList = (props) => {
  const {
    officials,
    currentLayer,
    district,
    followDistrictOfficial,
    invitePublicOfficial,
    mapChangeByOption,
    isOfficialLoading,
    user,
  } = props;
  const hashUrl = '#';
  const { t } = useTranslation();
  const NoOfficial = (
    <div className={'mb-3 no-engagement'}>
      <span className={'sad-img'}>
        <img
          src={require('../../../assets/img/sad.svg').default}
          alt={t('No Officials')}
        />
      </span>
      <span className="no-engagement-txt">{`${t(`No officials found`)}`}</span>
    </div>
  );

  return (
    <div className="clsmap-address-details clswhite-box">
      <div className="clsclose">
        <a
          href={hashUrl}
          onClick={(e) => {
            mapChangeByOption('districtBoxDismissal', true);
          }}
        >
          <img
            src={require('../../../assets/img/close.svg').default}
            alt={t('Close')}
          />
        </a>
      </div>
      {isOfficialLoading === true ? (
        <LoadingIndicator />
      ) : (
        <>
          {district !== null ? (
            <>
              <h4 className="mb-1 pr-3">{district.name}</h4>
              <h6 className="mb-2">{district.level2} </h6>
            </>
          ) : (
            <h4 className="mb-1">{currentLayer.name}</h4>
          )}
          <Notification module={'inviteOther'} />
          <div className="accordion" id="accordionDistrict">
            {Object.keys(officials).length > 0
              ? Object.keys(officials).map((level, index) => (
                  <div className="card" key={index}>
                    <div className="card-header" id={'heading-' + index}>
                      <h2 className="mb-0">
                        <a
                          href={hashUrl}
                          data-toggle="collapse"
                          data-target={'#collapse-' + index}
                          aria-expanded={index === 0 ? true : false}
                          aria-controls={'collapse-' + index}
                        >
                          {level}
                        </a>
                      </h2>
                    </div>

                    <div
                      id={'collapse-' + index}
                      className={index === 0 ? 'collapse show' : 'collapse'}
                      aria-labelledby={'heading-' + index}
                      data-parent="#accordionDistrict"
                    >
                      <div className="card-body">
                        <ul className="clsmap-district-user-list">
                          {officials[level].length === 0
                            ? { NoOfficial }
                            : officials[level].map((official, index) => (
                                <li key={index}>
                                  {official.isRegistered ? (
                                    <div className="media">
                                      <NavLink
                                        to={
                                          '/dashboard/user-profile/?id=' +
                                          official.officialUser.userId
                                        }
                                        activeClassName="active"
                                        exact
                                      >
                                        <img
                                          src={
                                            official.officialUser
                                              .profile_picture !== null
                                              ? official.officialUser
                                                  .profile_picture
                                              : require('../../../assets/img/profile-image.jpeg')
                                                  .default
                                          }
                                          alt="Official"
                                          className="align-self-center mr-3"
                                          width="61"
                                        />
                                      </NavLink>
                                      <div className="media-body align-self-center">
                                        <h5 className="mt-0">
                                          <NavLink
                                            to={
                                              '/dashboard/user-profile/?id=' +
                                              official.officialUser.userId
                                            }
                                            activeClassName="active"
                                            exact
                                          >
                                            {official.officialUser.showMyNameAs
                                              ? official.show_my_name
                                              : official.officialUser
                                                  .first_name +
                                                ' ' +
                                                official.officialUser.last_name}
                                          </NavLink>
                                        </h5>
                                        <p>
                                          {official.officialUser
                                            .showMyNameAs !== 'organization'
                                            ? official.position
                                            : ''}{' '}
                                          &nbsp;
                                        </p>
                                        {official.userOutsideToPO === true &&
                                        typeof user.roleName !== 'undefined' ? (
                                          <p>
                                            {official.userFollow ? (
                                              <>
                                                {t(`Following`)} -{' '}
                                                <a
                                                  href={hashUrl}
                                                  onClick={(e) => {
                                                    followDistrictOfficial(
                                                      official.officialUser
                                                        .userId,
                                                    );
                                                    e.preventDefault();
                                                  }}
                                                  className="clsunfollow"
                                                >
                                                  {t(`Unfollow`)}
                                                </a>
                                              </>
                                            ) : (
                                              <a
                                                href={hashUrl}
                                                onClick={(e) => {
                                                  followDistrictOfficial(
                                                    official.officialUser
                                                      .userId,
                                                  );
                                                  e.preventDefault();
                                                }}
                                                className="clsfollow"
                                              >
                                                {t(`Follow`)}
                                              </a>
                                            )}
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="media">
                                      <img
                                        src={
                                          require('../../../assets/img/profile-image.jpeg')
                                            .default
                                        }
                                        alt={t('Official')}
                                        className="align-self-center mr-3"
                                        width="61"
                                      />
                                      <div className="media-body align-self-center">
                                        <h5 className="mt-0">
                                          {official.fName +
                                            ' ' +
                                            official.lName}
                                        </h5>
                                        <p>{official.position}</p>
                                        {official.officialEmail &&
                                        typeof user.roleName !== 'undefined' ? (
                                          <p>
                                            <a
                                              href={hashUrl}
                                              onClick={(e) => {
                                                invitePublicOfficial(
                                                  official.officialEmail,
                                                );
                                                e.preventDefault();
                                              }}
                                              className="invite"
                                            >
                                              {t(`Invite`)}
                                            </a>
                                          </p>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              : NoOfficial}
          </div>
        </>
      )}
      {typeof user.roleName !== 'undefined' ? (
        <div className="media mt-3">
          <img
            src={require('../../../assets/img/add.svg').default}
            className="align-self-center mr-3"
            alt=""
          />
          <div className="media-body align-self-center">
            <p>
              <small>{t(`Someone is not listed?`)}</small>
            </p>
            <p>
              <small>
                <NavLink
                  to={'/dashboard/inviteothers'}
                  className="clsunfollow"
                  activeClassName="clsnot-list-link"
                  exact
                >
                  + {t(`Add Government Official`)}
                </NavLink>
              </small>
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OfficialsList;
