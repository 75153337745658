/*
 *
 * Engagement Analytics
 *
 */

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import AnalyticsPage from '../../components/Manager/Analaytics';
import Notification from '../Notification';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
//changeDiscussionTabData, changeSurveyTabData, changeQuestionData, setDiscussionData, setSurveyData, setQuestionData
import NoData from '../../components/Manager/Engagement/noData';

const Analytics = (props) => {
  const { setAnalyticsData, analyticsData, location } = props;
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const engagementId = new URLSearchParams(location.search).get('id');

  const loadData = useCallback(
    async (id) => {
      await setAnalyticsData(id)
        .then(() => {
          setLoaded(true);
        })
        .catch((error) => {
          setLoaded(false);
          setError(true);
        });
    },
    [setAnalyticsData, setLoaded, setError],
  );

  useEffect(() => {
    if (loaded === false && error === false) {
      loadData(engagementId);
    }
  }, [loaded, error, loadData, engagementId]);

  return (
    <>
      <Notification module={'analytics'} />
      {loaded === false && error === false ? (
        <LoadingIndicator position={`fixed`} />
      ) : (
        ''
      )}
      {loaded === true &&
      error === false &&
      Object.keys(analyticsData).length > 0 ? (
        <div key={`engagement-analytics-${engagementId}`}>
          <AnalyticsPage {...props} />
        </div>
      ) : loaded === true ? (
        <NoData />
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    analyticsData: state.analytics.analyticsData,
    activeTab: state.analytics.activeTab,
  };
};

export default connect(mapStateToProps, actions)(Analytics);
