/**
 *
 * Question chart
 *
 */

import React, { useEffect, useState, createContext } from 'react';
import { Chart } from 'react-google-charts';

const QuestionChart = (props) => {
  const { chartType, data, options, width, height } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === true) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [loaded]);

  const Context = createContext();

  return (
    <Context.Provider value={data}>
      <Chart
        chartType={chartType}
        data={data}
        options={options}
        width={width}
        height={height}
        chartEvents={[
          {
            eventName: 'ready',
            callback: ({ chartWrapper, google }) => {
              google.charts.setOnLoadCallback(setLoaded(true));
            },
          },
        ]}
      />
    </Context.Provider>
  );
};

export default React.memo(QuestionChart);
