import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommunityIssueEngagement from './CommunityIssueEngagement';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { getCommunityIssues, setCommunityIssueListPage } from '../../../containers/CommunityIssues/actions';

const CommunityIssueList = (props) => {
  const { t } = useTranslation();
  const {
    allCommunityIssues,
    isCommunityIssuesLoading,
    listPage,
    listLimit,
    listFilterType,
    listIssueCategory,
    sortOrder,
    authenticated,
    currentCommunityIssuesFeedIds,
  } = props;
  const dispatch = useDispatch();

  const incrementPage = async () => {
    if (!isCommunityIssuesLoading && authenticated) {
      dispatch(getCommunityIssues({
        page: listPage + 1,
        limit: listLimit,
        filter: listFilterType,
        category: listIssueCategory,
        sortOrder: sortOrder,
        reset: false,
      }));
      dispatch(setCommunityIssueListPage(listPage + 1));
    }
  }

  return (
    <>
      {!(allCommunityIssues) || currentCommunityIssuesFeedIds.length === 0 ? (
        isCommunityIssuesLoading ? 
          <div className={'mb-3 no-engagement'}>
            <span className="no-engagement-txt">
              {t(`Loading your Community Issues...`)}
            </span>
          </div>
        :
          <div className={'mb-3 no-engagement'}>
            <span className={'sad-img'}>
              <img
                src={require('../../../assets/img/sad.svg').default}
                alt="No Community Issues"
              />
            </span>
            <span className="no-engagement-txt">
              {t(`You don’t have any community issues here.`)}
            </span>
          </div>
      ) : (
        currentCommunityIssuesFeedIds.map((communityIssueId) => {
          return (
            <>
              <CommunityIssueEngagement communityIssueId={communityIssueId} />
            </>
          )
        })
      )}
      <InfiniteScroll
        dataLength={currentCommunityIssuesFeedIds.length}
        next={incrementPage}
        hasMore={true}
        loader={
          isCommunityIssuesLoading ? (
            <LoadingIndicator position={`relative`} />
          ) : (
            ''
          )
        }
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    isCommunityIssuesLoading: state.communityIssues.isCommunityIssuesLoading,
    listPage: state.communityIssues.listPage,
    listLimit: state.communityIssues.listLimit,
    listFilterType: state.communityIssues.listFilterType,
    listIssueCategory: state.communityIssues.listIssueCategory,
    sortOrder: state.communityIssues.sortOrder,
    authenticated: state.authentication.authenticated,
    currentCommunityIssuesFeedIds: state.communityIssues.currentCommunityIssuesFeedIds,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueList);