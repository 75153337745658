/*
 *
 * Analytics actions
 *
 */

import axios from 'axios';
import { BASE_API_URL } from '../../constants';

import {
  SET_ANALYTICS_LOADING,
  SET_ANALYTICS_DATA,
  SET_ANALYTICS_TAB,
  SET_DISCUSSION_ANALYTICS_DATA,
  SET_SURVEY_ANALYTICS_DATA,
  SET_POSTVIEW_ANALYTICS_DATA,
  SET_QUESTION_ANALYTICS_DATA,
  CHANGE_DISCUSSION_TAB_DATA,
  CHANGE_SURVEY_TAB_DATA,
  CHANGE_POSTVIEW_TAB_DATA,
  CHANGE_QUESTION_TAB_DATA,
  SET_ACTIVE_QUESTION_ID,
  RESET_APP,
} from './constants';
import { addNotification } from '../Notification/actions';

export const resetAnalyticsState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const setAnalyticsData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_ANALYTICS_LOADING, payload: true });
      let uri = '/official/postAnalytics/' + id;
      await axios
        .get(BASE_API_URL + uri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (typeof response.data.data !== 'undefined') {
              dispatch({
                type: SET_ANALYTICS_DATA,
                payload: response.data.data,
              });
              let analyticsData = response.data.data;
              //Set Default Data For All The Questions
              let formData = {};
              if (analyticsData.category === 'survey') {
                analyticsData.survey.question.forEach((question, index) => {
                  formData['question-' + question.id] = 'ethnicity';
                });
                dispatch({ type: CHANGE_QUESTION_TAB_DATA, payload: formData });
              }
            }
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'analytics',
            }),
          );
          return reject(false);
        })
        .finally(() => {
          dispatch({ type: SET_ANALYTICS_LOADING, payload: false });
        });
    });
  };
};

export const setAnalyticTab = (type) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ANALYTICS_TAB, payload: type });
  };
};

export const setDiscussionData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_ANALYTICS_LOADING, payload: true });

      let viewBy = getState().analytics.discussionTabData.viewBy;
      let weighted = getState().analytics.discussionTabData.weighted;
      //let participation = getState().analytics.discussionTabData.participation;

      //let uri = '/official/discussAnalytics/'+id+'?viewBy='+viewBy+'&weighted='+weighted+'&participation='+participation;
      let uri =
        '/official/discussAnalytics/' +
        id +
        '?viewBy=' +
        viewBy +
        '&weighted=' +
        weighted;

      await axios
        .get(BASE_API_URL + uri)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({
              type: SET_DISCUSSION_ANALYTICS_DATA,
              payload: response.data.data,
            });
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'analytics',
            }),
          );
          return reject(false);
        })
        .finally(() => {
          dispatch({ type: SET_ANALYTICS_LOADING, payload: false });
        });
    });
  };
};

export const setSurveyData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_ANALYTICS_LOADING, payload: true });

      let viewBy = getState().analytics.surveyTabData.viewBy;
      let weighted = getState().analytics.surveyTabData.weighted;
      //let participation = getState().analytics.surveyTabData.participation;

      //let uri = '/official/surveyAnalytics/'+id+'?viewBy='+viewBy+'&weighted='+weighted+'&participation='+participation;
      let uri =
        '/official/surveyAnalytics/' +
        id +
        '?viewBy=' +
        viewBy +
        '&weighted=' +
        weighted;

      await axios
        .get(BASE_API_URL + uri)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({
              type: SET_SURVEY_ANALYTICS_DATA,
              payload: response.data.data,
            });
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'analytics',
            }),
          );
          return reject(false);
        })
        .finally(() => {
          dispatch({ type: SET_ANALYTICS_LOADING, payload: false });
        });
    });
  };
};

export const setPostviewData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_ANALYTICS_LOADING, payload: true });

      let viewBy = getState().analytics.postviewTabData.viewBy;
      let weighted = getState().analytics.postviewTabData.weighted;

      let uri =
        '/official/postviewAnalytics/' +
        id +
        '?viewBy=' +
        viewBy +
        '&weighted=' +
        weighted;

      await axios
        .get(BASE_API_URL + uri)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({
              type: SET_POSTVIEW_ANALYTICS_DATA,
              payload: response.data.data,
            });
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'analytics',
            }),
          );
          return reject(false);
        })
        .finally(() => {
          dispatch({ type: SET_ANALYTICS_LOADING, payload: false });
        });
    });
  };
};

export const setQuestionData = (id, questionId) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_ANALYTICS_LOADING, payload: true });
      let viewBy =
        getState().analytics.questionTabData['question-' + questionId];

      let uri =
        '/official/questionAnalytics/' +
        id +
        '?viewBy=' +
        viewBy +
        '&questionId=' +
        questionId;

      await axios
        .get(BASE_API_URL + uri)
        .then((response) => {
          if (response.data.success === 'true') {
            let formData = {};
            formData[questionId] = response.data.data;
            dispatch({ type: SET_QUESTION_ANALYTICS_DATA, payload: formData });
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'analytics',
            }),
          );
          return reject(false);
        })
        .finally(() => {
          dispatch({ type: SET_ANALYTICS_LOADING, payload: false });
          return resolve(true);
        });
    });
  };
};

export const changeDiscussionTabData = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    let discussionTabData = getState().analytics.discussionTabData;
    formData[name] = value;
    if (name === 'weighted') {
      if (
        value === 'voterRegistrationComparision' &&
        (discussionTabData.viewBy === 'ethnicity' ||
          discussionTabData.viewBy === 'education' ||
          discussionTabData.viewBy === 'employment_status' ||
          discussionTabData.viewBy === 'marital_status' ||
          discussionTabData.viewBy === 'political_affiliation' ||
          discussionTabData.viewBy === 'religion')
      ) {
        formData['viewBy'] = 'age';
      } else if (
        value === 'resultOnly' &&
        discussionTabData.viewBy === 'party_orientation'
      ) {
        formData['viewBy'] = 'ethnicity';
      } else if (
        value === 'censusComparision' &&
        (discussionTabData.viewBy === 'education' ||
          discussionTabData.viewBy === 'employment_status' ||
          discussionTabData.viewBy === 'marital_status' ||
          discussionTabData.viewBy === 'political_affiliation' ||
          discussionTabData.viewBy === 'party_orientation' ||
          discussionTabData.viewBy === 'religion')
      ) {
        formData['viewBy'] = 'ethnicity';
      }
    }
    return dispatch({ type: CHANGE_DISCUSSION_TAB_DATA, payload: formData });
  };
};

export const changeSurveyTabData = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    let surveyTabData = getState().analytics.surveyTabData;
    formData[name] = value;
    if (name === 'weighted') {
      if (
        value === 'voterRegistrationComparision' &&
        (surveyTabData.viewBy === 'ethnicity' ||
          surveyTabData.viewBy === 'education' ||
          surveyTabData.viewBy === 'employment_status' ||
          surveyTabData.viewBy === 'marital_status' ||
          surveyTabData.viewBy === 'political_affiliation' ||
          surveyTabData.viewBy === 'religion')
      ) {
        formData['viewBy'] = 'age';
      } else if (
        value === 'resultOnly' &&
        surveyTabData.viewBy === 'party_orientation'
      ) {
        formData['viewBy'] = 'ethnicity';
      } else if (
        value === 'censusComparision' &&
        (surveyTabData.viewBy === 'education' ||
          surveyTabData.viewBy === 'employment_status' ||
          surveyTabData.viewBy === 'marital_status' ||
          surveyTabData.viewBy === 'political_affiliation' ||
          surveyTabData.viewBy === 'party_orientation' ||
          surveyTabData.viewBy === 'religion')
      ) {
        formData['viewBy'] = 'ethnicity';
      }
    }
    return dispatch({ type: CHANGE_SURVEY_TAB_DATA, payload: formData });
  };
};

export const changePostviewTabData = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    let postviewTabData = getState().analytics.postviewTabData;
    formData[name] = value;
    if (name === 'weighted') {
      if (
        value === 'voterRegistrationComparision' &&
        (postviewTabData.viewBy === 'ethnicity' ||
          postviewTabData.viewBy === 'education' ||
          postviewTabData.viewBy === 'employment_status' ||
          postviewTabData.viewBy === 'marital_status' ||
          postviewTabData.viewBy === 'political_affiliation' ||
          postviewTabData.viewBy === 'religion')
      ) {
        formData['viewBy'] = 'age';
      } else if (
        value === 'resultOnly' &&
        postviewTabData.viewBy === 'party_orientation'
      ) {
        formData['viewBy'] = 'ethnicity';
      } else if (
        value === 'censusComparision' &&
        (postviewTabData.viewBy === 'education' ||
          postviewTabData.viewBy === 'employment_status' ||
          postviewTabData.viewBy === 'marital_status' ||
          postviewTabData.viewBy === 'political_affiliation' ||
          postviewTabData.viewBy === 'party_orientation' ||
          postviewTabData.viewBy === 'religion')
      ) {
        formData['viewBy'] = 'ethnicity';
      }
    }
    return dispatch({ type: CHANGE_POSTVIEW_TAB_DATA, payload: formData });
  };
};

export const changeQuestionData = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData[name] = value;

    //engagementFormData: { ...state.engagementFormData, ...action.payload }
    return dispatch({ type: CHANGE_QUESTION_TAB_DATA, payload: formData });
  };
};

export const setActiveId = (questionId) => {
  return async (dispatch, getState) => {
    return dispatch({ type: SET_ACTIVE_QUESTION_ID, payload: questionId });
  };
};

export const downloadData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SET_ANALYTICS_LOADING, payload: true });
      let uri = '/official/downloadAnalytics/' + id;
      await axios
        .get(BASE_API_URL + uri, { responseType: 'blob' })
        .then((response) => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'analyticsData-' + id + '.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'analytics',
            }),
          );
          return reject(false);
        })
        .finally(() => {
          dispatch({ type: SET_ANALYTICS_LOADING, payload: false });
        });
    });
  };
};
