/*
 *
 * Petition reducer
 *
 */

import {
  SET_PETITION_TAB,
  SET_PETITION_FORM_ERRORS,
  FETCH_PETITION_DATA,
  PETITION_RELOAD_LIST,
  PETITION_RESET,
  PETITION_CHANGE,
  PETITION_FORM_CHANGE,
  SET_PETITION_FORM_RESET,
  SET_PETITION_LOADING,
  SET_PETITION_SINGLE,
  RESET_PETITION_SINGLE,
  RESET_SIGNIN_PETITION,
  SET_SIGNIN_PETITION,
  PETITION_SPECIFIC_DATA,
  FETCH_MY_PETITION_DATA,
  SET_MYPETITION_TAB,
  RESET_APP,
  SET_FILTER_LEVEL,
} from './constants';

const initialState = {
  activePetitionTab: 'all-petitions',
  activeMyPetitionTab: 'created-petitions',
  petitionLists: [],
  reloadList: false,
  isPetitionLoading: false,
  noRecords: false,
  pageLoader: false,
  sort: 'newest',
  filterDismissal: false,
  filter: {
    topics: {
      title: 'Topics I follow',
      items: [],
    },
    locations: {
      title: 'Locations',
      items: [],
    },
    // status: {
    //   title: 'Status',
    //   items: [{id: 'current', name: 'Current'}, {id: 'expired', name: 'Expired'}]
    // }
  },
  filterSelected: {
    topics: [],
    locations: [],
    // status: []
  },
  searchBox: {
    topics: { key: '', display: false },
    locations: { key: '', display: false },
  },
  suggestionBox: {
    topics: {
      display: false,
      loading: true,
      items: [],
    },
    locations: {
      display: false,
      loading: false,
      items: [],
    },
    status: {
      display: false,
      items: [],
    },
  },
  petitionFormData: {
    petition_title: 'New Initiative',
    title: '',
    division: '',
    active_for: '',
    description: '',
    tags: [],
    files: [],
    links: [],
    images: [],
    removeImages: [],
    dismissal: false,
  },
  petitionFormErrors: [],
  petitionSingle: {},
  eligiblePetitionSign: {},
  petitionDivisionData: [],
  myPetitions: [],
  filterDivision: {},
  districtSelected: {
    divisionName: '',
  },
};

const defaultState = JSON.stringify(initialState);

const petitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_PETITION_TAB:
      return {
        ...state,
        activePetitionTab: action.payload,
      };
    case SET_MYPETITION_TAB:
      return {
        ...state,
        activeMyPetitionTab: action.payload,
      };
    case SET_PETITION_LOADING:
      return {
        ...state,
        isPetitionLoading: action.payload,
      };
    case FETCH_PETITION_DATA:
      return {
        ...state,
        petitionLists: [...state.petitionLists, ...action.payload],
      };
    case FETCH_MY_PETITION_DATA:
      return {
        ...state,
        myPetitions: [...state.myPetitions, ...action.payload],
      };
    case PETITION_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case PETITION_RELOAD_LIST:
      return {
        ...state,
        petitionLists: [],
        myPetitions: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
      };
    case PETITION_RESET:
      return {
        ...state,
        petitionLists: [],
        myPetitions: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
        sort: 'newest',
        filterDismissal: false,
      };
    case PETITION_FORM_CHANGE:
      return {
        ...state,
        petitionFormData: { ...state.petitionFormData, ...action.payload },
      };
    case SET_PETITION_FORM_ERRORS:
      return {
        ...state,
        petitionFormErrors: action.payload,
      };
    case PETITION_SPECIFIC_DATA:
      return {
        ...state,
        petitionFormData: action.payload,
      };
    // case PETITION_DIVISION_DATA:
    //   return {
    //     ...state,
    //     petitionDivisionData: [...action.payload ]
    //   };
    case SET_PETITION_FORM_RESET:
      return {
        ...state,
        petitionFormData: {
          petition_title: 'New Initiative',
          title: '',
          division: '',
          active_for: '',
          description: '',
          tags: [],
          files: [],
          links: [],
          images: [],
          removeImages: [],
          dismissal: false,
        },
        petitionFormErrors: {},
      };
    case SET_PETITION_SINGLE:
      return {
        ...state,
        petitionSingle: action.payload,
      };
    case RESET_PETITION_SINGLE:
      return {
        ...state,
        petitionSingle: {},
      };
    case SET_SIGNIN_PETITION:
      return {
        ...state,
        eligiblePetitionSign: action.payload,
      };
    case RESET_SIGNIN_PETITION:
      return {
        ...state,
        eligiblePetitionSign: {},
      };
    case SET_FILTER_LEVEL:
      return {
        ...state,
        filterDivision: action.payload,
      };
    default:
      return state;
  }
};

export default petitionReducer;
