/**
 *
 * Resident Profile Edit Modal
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicModal from '../../components/Common/CivicModal';
import ProfileEditForm from '../../components/Manager/Profile/profileEditForm';
import { useTranslation } from 'react-i18next';

const ProfileEdit = (props) => {
  const { editProfileFormData, formType } = props;
  const { t } = useTranslation();

  return (
    <>
      <CivicModal
        id={`profile-edit-` + formType + `form-modal`}
        title={t(editProfileFormData.title)}
        dismissal={editProfileFormData.dismissal}
      >
        <ProfileEditForm {...props} formType={formType} />
      </CivicModal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    editProfileFormData: state.account.editProfileFormData,
    editProfileFormError: state.account.editProfileFormError,
    isProfileLoading: state.account.isProfileLoading,
  };
};

export default connect(mapStateToProps, actions)(ProfileEdit);
