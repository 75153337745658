/**
 *
 * Engagement Dates
 *
 */

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DateInput from '../../Common/DateInput';
import { addDays } from 'date-fns';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import DateSelection from '../../Common/DateSelection';

const EngagementDate = (props) => {
  const { engagementFormData, engagementErrors, engagementChange, user } = props;
  const { t } = useTranslation();
  const StartTimeFrame = React.forwardRef((props, ref) => (
    <DateInput innerRef={ref} {...props} />
  ));
  const CloseTimeFrame = React.forwardRef((props, ref) => (
    <DateInput innerRef={ref} {...props} />
  ));
  const CompleteTimeFrame = React.forwardRef((props, ref) => (
    <DateInput innerRef={ref} {...props} />
  ));

  //const today = moment.utc(new Date()).tz(moment.tz.guess()).format('YYYY-MM-DD');
  const currentDateTime = moment
    .utc(new Date())
    .tz(moment.tz.guess())
    .format('YYYY-MM-DD HH:mm:ss');
  const todayDate = new Date(currentDateTime);

  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);
    const currentDate = new Date(currentDateTime);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <div
        style={{
          display: engagementFormData.category !== 'post' ? 'block' : 'none',
        }}
      >
        <div className="custom-control custom-switch clsengagement-start-switch">
          <input
            type="checkbox"
            name="startNow"
            className="custom-control-input"
            id="customSwitch"
            value={engagementFormData.startNow}
            onChange={(e) =>
              engagementChange(e.target.name, !engagementFormData.startNow)
            }
            checked={engagementFormData.startNow}
          />
          <label className="custom-control-label" htmlFor="customSwitch">
            {t(`Start Enagagement Now`)}
          </label>
        </div>
        <div className="form-group">
          <label
            htmlFor="engagementTimeFrame"
            className={
              engagementFormData.startNow === true ? `disabled-color` : ``
            }
          >
            {t(`Engagement Open`)}
          </label>
          <DateSelection
            customInput={
              <StartTimeFrame
                className={
                  engagementFormData.startNow === true
                    ? `date-picker-disabled`
                    : ''
                }
              />
            }
            onChangeRaw={(e) => e.preventDefault()}
            placeholderText={t(`Select`)}
            selected={engagementFormData.engagementTimeFrame}
            onChange={(date) => engagementChange('engagementTimeFrame', date)}
            showTimeSelect
            minDate={todayDate}
            maxDate={addDays(new Date(), 90)}
            timeIntervals={15}
            dateFormat="MM-dd-yyyy h:mm aa"
            yearDropdownItemNumber={121}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            disabled={engagementFormData.startNow === true}
            filterTime={filterPassedTime}
            locale={user?.selected_language}
            timeCaption={t('Time')}
          />

          {typeof engagementErrors !== 'undefined' &&
            engagementErrors.engagementTimeFrame && (
              <div className="clsinvalid">
                {t(engagementErrors?.['engagementTimeFrame'])}
              </div>
            )}
        </div>
        <div className="form-group">
          <label htmlFor="closeTimeFrame">{t(`Engagement Close`)}</label>
          <DateSelection
            customInput={<CloseTimeFrame />}
            onChangeRaw={(e) => e.preventDefault()}
            placeholderText={t(`Select`)}
            selected={engagementFormData.closeTimeFrame}
            onChange={(date) => engagementChange('closeTimeFrame', date)}
            showTimeSelect
            minDate={
              typeof engagementFormData.engagementTimeFrame !== 'undefined'
                ? engagementFormData.engagementTimeFrame !== ''
                  ? addDays(engagementFormData.engagementTimeFrame, 1)
                  : new Date()
                : new Date()
            }
            maxDate={addDays(new Date(), 90)}
            timeIntervals={15}
            dateFormat="MM-dd-yyyy h:mm aa"
            yearDropdownItemNumber={121}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            locale={user?.selected_language}
            timeCaption={t('Time')}
          />

          {typeof engagementErrors !== 'undefined' &&
            engagementErrors.closeTimeFrame && (
              <div className="clsinvalid">
                {t(engagementErrors?.['closeTimeFrame'])}
              </div>
            )}
        </div>
        <div className="form-group">
          <label htmlFor="completeTimeFrame">{t(`Engagement Outcome`)}</label>
          <DateSelection
            customInput={<CompleteTimeFrame />}
            onChangeRaw={(e) => e.preventDefault()}
            placeholderText={t(`Select`)}
            selected={engagementFormData.completeTimeFrame}
            onChange={(date) => engagementChange('completeTimeFrame', date)}
            showTimeSelect
            minDate={
              typeof engagementFormData.closeTimeFrame !== 'undefined'
                ? engagementFormData.closeTimeFrame !== ''
                  ? addDays(engagementFormData.closeTimeFrame, 1)
                  : new Date()
                : new Date()
            }
            maxDate={addDays(new Date(), 90)}
            timeIntervals={15}
            dateFormat="MM-dd-yyyy h:mm aa"
            yearDropdownItemNumber={121}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            locale={user?.selected_language}
            timeCaption={t('Time')}
          />

          {typeof engagementErrors !== 'undefined' &&
            engagementErrors.completeTimeFrame && (
              <div className="clsinvalid">
                {t(engagementErrors?.['completeTimeFrame'])}
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default EngagementDate;
