/**
 *
 * CivicFeed Filter
 *
 */

import { useEffect, useState } from 'react';
import SelectOption from '../../Common/SelectOption';
import { handleOptionValue } from '../../../utils/helper';
import { sortOptions } from '../../../containers/CivicFeed/constants';
import CivicModal from '../../Common/CivicModal';
import CivicFilter from './civicFilter';
import FilterFooter from './filterFooter';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const {
    sort,
    civicEngagementChangeByOption,
    filterDismissal,
    getFilterItems,
  } = props;
  const { t } = useTranslation();
  const hashUrl = '#';
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading === true) {
      const filterItem = async () => {
        await getFilterItems().then(async () => {
          setLoading(false);
        });
      };
      filterItem();
    }
  }, [loading, getFilterItems]);

  return (
    <div className="clsfeed-filter-blk">
      <div className="clsfeed-dropdown">
        <div className="dropdown">
          <div className="form-group">
            <SelectOption
              name={'sort'}
              placeholder={'View'}
              value={handleOptionValue(sortOptions, sort)}
              options={sortOptions}
              handleSelectChange={(value) => {
                civicEngagementChangeByOption('sort', value.value);
              }}
            />
            <label>{t(`View`)}:</label>
          </div>
        </div>
      </div>
      <div className={`civic-filter-modal`}>
        <CivicModal
          id={`filter-modal`}
          title={t(`Filter`)}
          dismissal={filterDismissal}
          footer={FilterFooter(props)}
          footerClass={`text-center mt-4`}
        >
          <CivicFilter {...props} />
        </CivicModal>
      </div>
      <div className="clsfeed-filter-blk clsfeed-filter">
        <a
          href={hashUrl}
          onClick={() => {
            civicEngagementChangeByOption('filterDismissal', false);
          }}
          data-toggle="modal"
          data-target="#filter-modal"
        >
          <img
            src={require('../../../assets/img/filter.svg').default}
            alt="Filter"
          />
          <span>{t(`Filter`)}</span>
        </a>
      </div>
    </div>
  );
};

export default Filter;
