import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { addNotification } from '../../../containers/Notification/actions';
import ShareLink from './ShareLink';

const IndividualCommunityIssueNavBar = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    sideMargins,
    allCommunityIssues,
  } = props;

  const history = useHistory();

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div 
      className="individual-community-issue-nav-bar"
    >
      <a
        className="back-container"
        onClick={() => {
          history.push('/community-issues');
        }}
        href={'/community-issues'}
      >
        <img
          src={require('../../../assets/img/back.svg').default}
          alt="Back arrow"
        />
        <p>
          {t('Back to Community Issues')}
        </p>
      </a>
      <ShareLink communityIssueId={communityIssueId} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(IndividualCommunityIssueNavBar);