/*
 *
 * Email Verification
 *
 */

import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import Notification from '../Notification';
import { useTranslation } from 'react-i18next';

const VerifyEmail = (props) => {
  const { authenticated, isEmailVerified, isValidEmail } = props;
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);

  const verifyEmail = useCallback(() => {
    if (!isEmailVerified && !isValidEmail && !processing) {
      setProcessing(true);
      props.verifyEmail();
    }
  }, [props, isEmailVerified, isValidEmail, processing]);

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  if (authenticated) return <Redirect to="/dashboard" />;

  return (
    <div className="clslogin-form-blk pb-5 mb-5">
      <div className="clslogin-form clswhite-box mt-4 pt-3">
        <div
          className="tabbable-panel"
          style={{ position: !isEmailVerified ? 'relative' : '' }}
        >
          <p className="fs-1 text-center">{t(`Email Verification`)}</p>
          <hr />
          <Notification module={'verifyEmail'} />
          {isEmailVerified === false ? (
            <LoadingIndicator />
          ) : (
            [
              isValidEmail ? (
                <div className="form-group">
                  <p>{t(`Email successfully verified! Please login.`)}</p>
                </div>
              ) : null,
            ]
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    isEmailVerified: state.verifyEmail.isEmailVerified,
    isValidEmail: state.verifyEmail.isValidEmail,
    errorNotification: state.verifyEmail.errorNotification,
  };
};

export default connect(mapStateToProps, actions)(VerifyEmail);
