/**
 *
 * ID.me Verification
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';

const Verification = (props) => {
  //const {location, getTokenFromIDme, user, getUserDataFromIDme, redirectToLogin, addNotification} = props;
  const { location, idMeVerify, user, addNotification } = props;
  const code = new URLSearchParams(location.search).get('code');
  const [loader, setLoader] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState(false);
  const history = useHistory();

  const reidrectUser = useCallback(() => {
    if (typeof user.roleName !== 'undefined') {
      history.push('/dashboard/civicfeed');
    } else {
      addNotification({
        type: 'success',
        message:
          'Hey, Welcome, we sent an email to confirm your email address. Please check it',
        module: 'login',
        displayFor: 10000,
      });
      history.push('/login');
    }
  }, [user.roleName, addNotification, history]);

  const verifyViaIDme = useCallback(async () => {
    //setLoader(true)
    await idMeVerify(code)
      .then((response) => {
        if (typeof user.roleName !== 'undefined') {
          addNotification({
            type: 'success',
            message: 'Your IDme verification is successful.',
            module: 'dashboard',
          });
        }
        setVerifyStatus(true);
        setLoader(false);
        reidrectUser();
      })
      .catch((error) => {
        //if(typeof user.roleName !== 'undefined') {
        addNotification({
          type: 'error',
          message: error,
          module: 'dashboard',
          displayFor: 10000,
        });
        addNotification({
          type: 'error',
          message: error,
          module: 'login',
          displayFor: 10000,
        });
        //}
        reidrectUser();
      });
  }, [code, idMeVerify, reidrectUser, addNotification, user.roleName]);

  useEffect(() => {
    if (
      verifyStatus === false &&
      typeof code !== 'undefined' &&
      loader === true &&
      inProcess === false
    ) {
      setInProcess(true);
      verifyViaIDme();
    }
  }, [verifyStatus, code, verifyViaIDme, loader, inProcess]);

  return (
    <div className="clslogin-form-blk pb-5 mb-5">
      <div
        className={`clslogin-form clsresident-form clswhite-box mt-4 pt-3 clsresident-form-new`}
      >
        <div className="verifyDiv">
          {loader === true ? <LoadingIndicator position={`fixed`} /> : ''}
          {verifyStatus === false ? (
            <span>Verification process in progress. Please wait....</span>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(Verification);
