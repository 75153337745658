/*
 *
 * Local News
 *
 */

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import LocalNewsScreen from '../../components/Manager/LocalNews';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Filter from '../../components/Manager/LocalNews/filter';
import { useTranslation } from 'react-i18next';
import Autocomplete from "react-google-autocomplete";
import {GOOGLE_PLACES_API_KEY} from "../../constants";
import StatusMessage from "../../components/Common/StatusMessage";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const LocalNews = (props) => {
  const {
    activeLocalNewsTab,
    setLocalNewsTab,
    getLocalNewsLists,
    localNewsLists,
    noRecords,
    reloadList,
    resetResult,
    searchSubmission,
    reloadLocalNews,
    searchResultReset,
    localNewsChangeByOption,
    isLocalNewsLoading,
    user,
    submitDismissal,
    location,
    resetLocalNewsFilter,
    submitLocalNewsFilter,
    isSilentUser,
    getLocalNewsListsForGuest,
    guestProfileAddressUpdate,
    getInactiveStatusMesages,
    account,
    editProfileChange,
    authenticated
  } = props;
  const hashUrl = '#';
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [localNewsListComponent, setLocalNewsListComponent] = useState('');
  const [page, setPage] = useState(1);
  const [guestAddress, setGuestAddress] = useState({});
  const [showGuestAddressPicker, setShowGuestAddressPicker] = useState(true);

  const { t } = useTranslation();

  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const prevUri = usePrevious(uri);

  useEffect(() => {
    if (uri !== prevUri && prevUri !== 'undefined') {
      resetLocalNewsFilter();
    }
  }, [uri, prevUri, resetLocalNewsFilter]);

  const resetTabContent = (tab) => {
    setLoading(true);
    setInitialLoad(true);
    setPage(1);
    setLocalNewsTab(tab);
  };

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setLocalNewsListComponent(
          <Component
            {...props}
            localNewsChangeByOption={localNewsChangeByOption}
          />,
        );
        return resolve(true);
      });
    },
    [setLocalNewsListComponent, props, localNewsChangeByOption],
  );

  const fetchLocalNews = useCallback(async () => {
    if (!isSilentUser) {
      setInProcess(true);
      await getLocalNewsLists(page, 2).finally(async () => {
        await renderComponent(LocalNewsScreen).then(() => {
          setPage(page + 1);
        });
      });
      setInProcess(false);
    }
  }, [getLocalNewsLists, renderComponent, setPage, page, isSilentUser]);

  useEffect(() => {
    if (account.editProfileData.address.length > 0) {
      setShowGuestAddressPicker(false);
    }
  }, [account.editProfileData]);

  useEffect(() => {
    setLoading(true);

    const fetchLocalNews = async () => {
      getLocalNewsListsForGuest();
      setLoading(false);
      setInProcess(false);
    };

    fetchLocalNews();
    }, [user.geoId]);

  useEffect(() => {
    if (loading === true) {
      setLocalNewsListComponent('');
      if (inProcess === false) {
        setInProcess(true);
        if (isSilentUser) {
          const fetchLocalNews = async () => {
            getLocalNewsListsForGuest();
            setLoading(false);
            setInProcess(false);
          };
          fetchLocalNews();
        } else {
          const fetchLocalNews = async () => {
            getLocalNewsLists();
            setLoading(false);
            setInProcess(false);
          };
          fetchLocalNews();
        }
      }
    }
  }, [
    loading,
    setLoading,
    setLocalNewsTab,
    activeLocalNewsTab,
    getLocalNewsLists,
    renderComponent,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    localNewsLists,
    isSilentUser,
  ]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(LocalNewsScreen);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setPage, setLoading, setInitialLoad]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await reloadLocalNews();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, reloadLocalNews],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    renderComponent(LocalNewsScreen);
  }, [user, renderComponent]);

  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handlePickGuestAddress = () => {
    setShowGuestAddressPicker(true);
  };

  const formatAddress = (address) => {
    let addr = {};

    editProfileChange('zip_code', '');

    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          addr.address = component.long_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'route': {
          addr.address = addr.hasOwnProperty('address')
              ? addr.address + ' ' + component.short_name
              : component.short_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'postal_code': {
          addr.zip_code = component.long_name;
          editProfileChange('zip_code', addr.zip_code)
          break;
        }
        case 'locality':
          addr.city = component.long_name;
          editProfileChange('city', addr.city)
          break;
        case 'administrative_area_level_1': {
          addr.state = component.short_name;
          editProfileChange('state', addr.state)
          break;
        }
        default: {
          break;
        }
      }
    }
    // return addr;
  }

  useEffect(() => {
    const storedAddress = JSON.parse(localStorage.getItem('guest_address'));

    if (storedAddress !== null) {
      formatAddress(storedAddress);

      setGuestAddress(storedAddress);
      setShowGuestAddressPicker(false);
    }
  }, []);

  const storeAddress = (address) => {
    const storedAddress = JSON.stringify(address);

    if (typeof address.name === 'undefined' && address) {
      localStorage.setItem('guest_address', storedAddress);

      formatAddress(address);
      guestProfileAddressUpdate(address);
      // getInactiveStatusMesages();

      setGuestAddress(address);
      setShowGuestAddressPicker(false);
    }
  };

  const LocalNewsListContent = (
    <>
      {isLocalNewsLoading === true ? (
        <LoadingIndicator position={`fixed`} />
      ) : (
        ''
      )}
      {submitDismissal === true ? (
        <div
          className="clsback-link mb-3"
          onClick={() => {
            resetLocalNewsFilter();
            submitLocalNewsFilter();
          }}
        >
          <a href={hashUrl}>
            <img
              src={require('../../assets/img/back.svg').default}
              alt="Back"
            />{' '}
            {t(`Back to Local News`)}
          </a>
        </div>
      ) : (
        ''
      )}
      {!isSilentUser && <Filter {...props} />}
      {localNewsListComponent}
      {page === 2 && !noRecords ? (
        <div style={{ minHeight: '200px' }}></div>
      ) : (
        ''
      )}
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={localNewsLists?.length}
            next={fetchLocalNews}
            hasMore={!noRecords}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
        </>
      )}
    </>
  );

  return (
    <div className="clsall-events-tab clsnews-tab-section">
      {!authenticated && <>
        {showGuestAddressPicker ?
            <div style={{
              marginBottom: '16px',
              padding: '15px',
              backgroundColor: '#ffffff',
              borderRadius: '13px',
            }}>
              <span style={{
                fontWeight: 'bold'
              }}>Enter your residential address</span> to see posts from your elected officials and agencies
              <div style={{
                display: 'flex',
                gap: '20px',
                marginTop: '10px'
              }}>

                <Autocomplete
                    apiKey={GOOGLE_PLACES_API_KEY}
                    className="form-control-plaintext"
                    placeholder="Residential Address (incl. street number, no PO Box)"
                    type="text"
                    id="location_search"
                    name="location_search"
                    defaultValue={account.editProfileData.address ?
                        account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                        account.editProfileData.state + ', ' + account.editProfileData.zip_code
                        : ''}
                    onKeyDown={preventSubmit}
                    autoComplete="off"
                    onPlaceSelected={(place) => {
                      storeAddress(place);
                    }}
                    options={{
                      types: ['address'],
                      componentRestrictions: {country: 'us'},
                      fields: ['address_components', 'formatted_address'],
                    }}
                    style={{
                      border: '1px solid #ced4da',
                      borderRadius: '10px',
                      height: '35px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      fontSize: '14px',
                      lineHeight: '1.5',
                      fontWeight: 400,
                    }}
                />
              </div>
            </div> : <div style={{
              marginBottom: '16px',
              padding: '15px',
              backgroundColor: '#ffffff',
              borderRadius: '13px',
              display: 'flex',
              gap: '10px'
            }}>
              {/*{Object.keys(guestAddress).length === 0 ? ('No address provided.') : (*/}
              {account.editProfileData.address === '' ? <>No address provided.</> : (
                  <div>
                        <span
                            style={{fontWeight: 'bold'}}>You're seeing posts for</span> {account.editProfileData.address ?
                      account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                      account.editProfileData.state + ', ' + account.editProfileData.zip_code
                      : ''}
                  </div>
              )}
              <span
                  onClick={handlePickGuestAddress}
                  style={{
                    color: '#ffaa33',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    float: 'right'
                  }}>change</span>
            </div>
        }
        {/*<StatusMessage statusMessages={account.inactiveMessages} showFinalizationButton={isSilentUser}/>*/}
      </>}
      {submitDismissal === false ? (
        <>
          <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className={
                  activeLocalNewsTab === 'city' ? 'nav-link active' : 'nav-link'
                }
                id="city-tab"
                data-toggle="tab"
                href="#city"
                role="tab"
                aria-controls="city"
                onClick={() => {
                  resetTabContent('city');
                }}
                aria-selected={activeLocalNewsTab === 'city' ? 'true' : 'false'}
              >
                {t(`City`)}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  activeLocalNewsTab === 'county'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id="county-tab"
                data-toggle="tab"
                href="#county"
                role="tab"
                aria-controls="county"
                onClick={() => {
                  resetTabContent('county');
                }}
                aria-selected={
                  activeLocalNewsTab === 'county' ? 'true' : 'false'
                }
              >
                {t(`County`)}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className={
                  activeLocalNewsTab === 'stateFeed'
                    ? 'nav-link active'
                    : 'nav-link'
                }
                id="stateFeed-tab"
                data-toggle="tab"
                href="#stateFeed"
                role="tab"
                aria-controls="stateFeed"
                onClick={() => {
                  resetTabContent('stateFeed');
                }}
                aria-selected={
                  activeLocalNewsTab === 'stateFeed' ? 'true' : 'false'
                }
              >
                {t(`State`)}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={
                activeLocalNewsTab === 'city'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="city"
              role="tabpanel"
              aria-labelledby="city-tab"
            >
              {activeLocalNewsTab === 'city' ? LocalNewsListContent : ''}
            </div>
            <div
              className={
                activeLocalNewsTab === 'county'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="county"
              role="tabpanel"
              aria-labelledby="county-tab"
            >
              {activeLocalNewsTab === 'county' ? LocalNewsListContent : ''}
            </div>
            <div
              className={
                activeLocalNewsTab === 'stateFeed'
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
              id="stateFeed"
              role="tabpanel"
              aria-labelledby="stateFeed-tab"
            >
              {activeLocalNewsTab === 'stateFeed' ? LocalNewsListContent : ''}
            </div>
          </div>
        </>
      ) : (
        LocalNewsListContent
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    localNewsLists: state.localNews.localNewsLists,
    noRecords: state.localNews.noRecords,
    reloadList: state.localNews.reloadList,
    pageLoader: state.localNews.pageLoader,
    sort: state.localNews.sort,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    filterDismissal: state.localNews.filterDismissal,
    isLocalNewsLoading: state.localNews.isLocalNewsLoading,
    activeLocalNewsTab: state.localNews.activeLocalNewsTab,
    filter: state.localNews.filter,
    filterSelected: state.localNews.filterSelected,
    filterNewsTab: state.localNews.filterNewsTab,
    submitDismissal: state.localNews.submitDismissal,
    isSilentUser: state.authentication.isSilentUser,
    account: state.account,
    authenticated: state.authentication.authenticated,
  };
};

export default connect(mapStateToProps, actions)(LocalNews);
