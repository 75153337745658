/*
 *
 * Engagement actions
 *
 */

import {
  SET_EDIT_ENGAGEMENT_DATA,
  ENGAGEMENT_CHANGE,
  ENGAGEMENT_ATTACHMENT,
  REMOVE_ATTACHEMENT,
  SET_ENGAGEMENT_LOADING,
  SET_ENGAGEMENT_FORM_ERRORS,
  SET_ENGAGEMENT_QUESTION_FORM_ERRORS,
  SET_ENGAGEMENT_SUBMITTING,
  ADD_QUESTION,
  REMOVE_QUESTION,
  ADD_CHOICE,
  REMOVE_CHOICE,
  FETCH_ENGAGEMENTS,
  REMOVE_ENGAGEMENT,
  SET_DELETE_HANDLE,
  SET_ENGAGEMENT_TAB,
  SET_NO_RECORD_STATUS,
  SET_RESET_STATUS,
  SET_ENGAGEMENT_FORM_RESET,
  ENGAGEMENTS_RESET,
  ENGAGEMENT_RELOAD_LIST,
  ENGAGEMENT_SPECIFIC_DATA,
  ENGAGEMENT_SPECIFIC_RESET,
  RESET_APP,
} from './constants';

import axios from 'axios';
import { BASE_API_URL } from '../../constants';
import { allFieldsValidation } from '../../utils/validation';
//import { formatFileSize, removeExtensionOfFile } from '../../utils/helper';
import moment from 'moment-timezone';
import { addNotification } from '../Notification/actions';
//import ReactS3Client from 'react-aws-s3-typescript';
import { setSearchResetResult, setSearchSubmision } from '../Search/actions';

export const resetEngagementState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const setEngagementFormData = (engagement) => {
  let formData = {};
  let completeTimeFrameStatus = moment(engagement.engagement_completion);
  let engagementTimeFrameStatus = moment(engagement.engagement_timeframe);
  let closeTimeFrameStatus = moment(engagement.engagement_closing);

  let completeTimeFrame =
    completeTimeFrameStatus.isValid() === true
      ? moment
          .utc(engagement.engagement_completion)
          .tz(moment.tz.guess())
          .toDate()
      : '';
  let engagementTimeFrame =
    engagementTimeFrameStatus.isValid() === true
      ? moment
          .utc(engagement.engagement_timeframe)
          .tz(moment.tz.guess())
          .toDate()
      : '';
  let closeTimeFrame =
    closeTimeFrameStatus.isValid() === true
      ? moment.utc(engagement.engagement_closing).tz(moment.tz.guess()).toDate()
      : '';

  /*let question = survey.map((item) => {
    item.map((choice, index) => {
      item.choice = JSON.parse
    })
  })*/

  let attached_documents = null;
  if (engagement.attached_documents !== null) {
    attached_documents = engagement.attached_documents.map((file, index) => {
      return JSON.parse(file);
    });
  }

  formData = {
    id: engagement.id,
    title: 'Edit Engagement',
    category: engagement.category,
    targetPopulation: engagement.target_population,
    postUnderOrganization:
      typeof engagement.post_under_organization === 'undefined'
        ? false
        : engagement.post_under_organization,
    startNow: false,
    engagementTimeFrame: engagementTimeFrame,
    closeTimeFrame: closeTimeFrame,
    completeTimeFrame: completeTimeFrame,
    tags: convertTagsToFrontend(engagement.topic),
    enableDiscussionBoard: engagement.discussion_board,
    visibleToNonConsttuent: engagement.non_constituents,
    comment: engagement.comment,
    comment_html: engagement.text_content_html,
    surveyTitle: engagement.title,
    question:
      engagement.category === 'survey'
        ? engagement.survey.question
        : [
            {
              type: '',
              title: '',
              choice: [
                { id: null, value: '' },
                { id: null, value: '' },
              ],
            },
          ],
    files: [],
    links: attached_documents,
    linksToBeRemoved: [],
    linkMeta: engagement.linkMeta ? engagement.linkMeta : null,
    description: engagement.description,
    step: 1,
    schedulePost: engagement.isScheduledInfoPost,
    postScheduleTime: engagementTimeFrame,
    dismissal: false,
  };

  return {
    type: SET_EDIT_ENGAGEMENT_DATA,
    payload: formData,
  };
};

export const convertTagsToFrontend = (tags) => {
  let topicArray = tags.map((tag) => {
    return { id: tag.value, text: tag.value, uid: tag.id };
  });
  return topicArray;
};

export const convertTagsToBackend = (tags) => {
  let topicArray = tags.map((tag) => {
    return {
      id: typeof tag.uid !== 'undefined' ? tag.uid : null,
      value: tag.text,
    };
  });
  return topicArray;
};

export const engagementChange = (name, value) => {
  let formData = {};
  return async (dispatch, getState) => {
    formData[name] = value;
    if (name === 'startNow') {
      formData['engagementTimeFrame'] = moment
        .utc(new Date())
        .tz(moment.tz.guess())
        .toDate();
    }

    let errorList = {},
      error = false;
    //const engagementTimeFrame = formData['engagementTimeFrame'].getTime();
    const engagementTime =
      getState().engagement.engagementFormData['engagementTimeFrame'];
    const closeTime =
      getState().engagement.engagementFormData['closeTimeFrame'];
    const completeTime =
      getState().engagement.engagementFormData['completeTimeFrame'];

    if (
      name === 'engagementTimeFrame' ||
      name === 'closeTimeFrame' ||
      name === 'completeTimeFrame' ||
      name === 'startNow'
    ) {
      let engagementTimeFrame = '',
        closeTimeFrame = '',
        completeTimeFrame = '';
      if (name === 'engagementTimeFrame' || name === 'startNow') {
        engagementTimeFrame =
          formData['engagementTimeFrame'] instanceof Date
            ? formData['engagementTimeFrame'].getTime()
            : '';
        closeTimeFrame = closeTime instanceof Date ? closeTime.getTime() : '';
        completeTimeFrame =
          completeTime instanceof Date ? completeTime.getTime() : '';
      } else if (name === 'closeTimeFrame') {
        closeTimeFrame = formData['closeTimeFrame'].getTime();
        engagementTimeFrame =
          engagementTime instanceof Date ? engagementTime.getTime() : '';
        completeTimeFrame =
          completeTime instanceof Date ? completeTime.getTime() : '';
      } else if (name === 'completeTimeFrame') {
        completeTimeFrame = formData['completeTimeFrame'].getTime();
        closeTimeFrame = closeTime instanceof Date ? closeTime.getTime() : '';
        engagementTimeFrame =
          engagementTime instanceof Date ? engagementTime.getTime() : '';
      }

      if (closeTime !== '' && closeTime !== null) {
        if (closeTime instanceof Date) {
          if (closeTimeFrame < engagementTimeFrame) {
            error = true;
            errorList = {
              ...errorList,
              closeTimeFrame: [
                'Engagement close time should not be prior to engagement start time.',
              ],
            };
          }
        }
      }
      if (completeTime !== '' && completeTime !== null) {
        if (completeTime instanceof Date) {
          if (completeTimeFrame < closeTimeFrame) {
            error = true;
            errorList = {
              ...errorList,
              completeTimeFrame: [
                'Engagement complete time should not be prior to engagement close time.',
              ],
            };
          } else if (completeTimeFrame < engagementTimeFrame) {
            error = true;
            errorList = {
              ...errorList,
              completeTimeFrame: [
                'Engagement complete time should not be prior to engagement start time.',
              ],
            };
          }
        }
      }
    }
    const {
      engagementTimeFrame,
      closeTimeFrame,
      completeTimeFrame,
      ...remaining
    } = getState().engagement.formErrors;

    if (error === true) {
      dispatch({
        type: SET_ENGAGEMENT_FORM_ERRORS,
        payload: { ...remaining, ...errorList },
      });
    } else {
      if (
        name === 'engagementTimeFrame' ||
        name === 'closeTimeFrame' ||
        name === 'completeTimeFrame' ||
        name === 'startNow'
      ) {
        dispatch({ type: SET_ENGAGEMENT_FORM_ERRORS, payload: remaining });
      }
    }
    return dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const commentEditChange = (value) => {
  let formData = {};
  formData['comment'] = JSON.stringify(value);
  return {
    type: ENGAGEMENT_CHANGE,
    payload: formData,
  };
};

export const commentLexicalChange = (value) => {
  let formData = {};
  formData['comment_html'] = value;

  return {
    type: ENGAGEMENT_CHANGE,
    payload: formData,
  };
};

export const linkMetaChange = (value) => {
  let formData = {};
  if (value) {
    formData['linkMeta'] = JSON.stringify(value);
  } else {
    formData['linkMeta'] = null;
  }

  return {
    type: ENGAGEMENT_CHANGE,
    payload: formData,
  };
};

export const addQuestion = () => {
  return {
    type: ADD_QUESTION,
  };
};

export const removeQuestion = (id) => {
  let formData = { questionId: id };
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENGAGEMENT_FORM_ERRORS, payload: [] });
    return dispatch({
      type: REMOVE_QUESTION,
      payload: formData,
    });
  };
};

export const addChoice = (questionId) => {
  let formData = { questionId: questionId };
  return {
    type: ADD_CHOICE,
    payload: formData,
  };
};

export const removeChoice = (questionId, choiceId) => {
  let formData = { questionId: questionId, choiceId: choiceId };
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENGAGEMENT_QUESTION_FORM_ERRORS, payload: [] });
    return dispatch({
      type: REMOVE_CHOICE,
      payload: formData,
    });
  };
};

export const questionChange = (questionId, field, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    let choice =
      getState().engagement.engagementFormData.question[questionId].choice;
    formData['question'] = getState().engagement.engagementFormData.question;
    if (field === 'type') {
      if (value === '1' && choice.length >= 2) {
        choice = choice.splice(0, 2);
        formData['question'][questionId]['choice'] = choice;
      }
    }
    formData['question'][questionId][field] = value;
    return dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const choiceChange = (questionId, choiceId, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData['question'] = getState().engagement.engagementFormData.question;
    formData['question'][questionId]['choice'][choiceId]['value'] = value;
    return dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const submitEngagement = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENGAGEMENT_FORM_ERRORS, payload: {} });
    dispatch(validateFirstStep())
      .then(async () => {
        let engagement = getState().engagement.engagementFormData;
        if (engagement.step === 1) {
          let formData = { step: 2 };
          return dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
        } else {
          dispatch(validateSecondStep())
            .then(async () => {
              dispatch(createEngagement());
            })
            .catch((error) => {
              dispatch({ type: SET_ENGAGEMENT_FORM_ERRORS, payload: error });
              dispatch({ type: SET_ENGAGEMENT_SUBMITTING, payload: false });
              dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
            });
        }
      })
      .catch((error) => {
        dispatch({ type: SET_ENGAGEMENT_FORM_ERRORS, payload: error });
        dispatch({ type: SET_ENGAGEMENT_SUBMITTING, payload: false });
        dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

//First Step Validation
export const validateFirstStep = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let engagement = getState().engagement.engagementFormData;
      let rules = {};
      if (engagement.category !== 'post') {
        rules = {
          category: 'required',
          targetPopulation: 'required',
          startNow: 'required|boolean',
          engagementTimeFrame: [{ required_if: ['startNow', false] }],
          closeTimeFrame: 'required',
          completeTimeFrame: 'required',
          // tags: 'required',
        };
      } else {
        rules = {
          category: 'required',
          targetPopulation: 'required',
          // tags: 'required',
        };
      }

      const { isValid, errors } = allFieldsValidation(engagement, rules, {
        'required.category': 'Required.',
        'required.targetPopulation': 'Required.',
        'required_if.engagementTimeFrame': 'Required.',
        'required.closeTimeFrame': 'Required',
        'required.completeTimeFrame': 'Required',
        // 'required.tags': 'At least one topic is required',
      });

      //Date Validation
      let errorList = {};

      const engagementTime =
        getState().engagement.engagementFormData['engagementTimeFrame'];
      const closeTime =
        getState().engagement.engagementFormData['closeTimeFrame'];
      const completeTime =
        getState().engagement.engagementFormData['completeTimeFrame'];
      const engagementTimeFrame =
        engagementTime instanceof Date ? engagementTime.getTime() : '';
      const closeTimeFrame =
        closeTime instanceof Date ? closeTime.getTime() : '';
      const completeTimeFrame =
        completeTime instanceof Date ? completeTime.getTime() : '';

      if (closeTimeFrame !== '') {
        if (closeTimeFrame < engagementTimeFrame) {
          errorList = {
            ...errorList,
            closeTimeFrame: [
              'Engagement close time should not be prior to engagement start time.',
            ],
          };
        }
      }
      if (completeTimeFrame !== '') {
        if (completeTimeFrame < closeTimeFrame) {
          errorList = {
            ...errorList,
            completeTimeFrame: [
              'Engagement complete time should not be prior to engagement close time.',
            ],
          };
        } else if (completeTimeFrame < engagementTimeFrame) {
          errorList = {
            ...errorList,
            completeTimeFrame: [
              'Engagement complete time should not be prior to engagement start time.',
            ],
          };
        }
      }

      errorList = { ...errorList, ...errors };

      if (!isValid) {
        reject(errorList);
      } else {
        resolve(true);
      }
    });
  };
};

//Second Step Validation
export const validateSecondStep = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let engagement = getState().engagement.engagementFormData;
      let errorStatus = false;
      let errorList = {};
      if (engagement.category === 'post' && engagement.schedulePost === true) {
        if (
          typeof engagement.postScheduleTime === 'undefined' ||
          engagement.postScheduleTime === '' ||
          engagement.postScheduleTime === null
        ) {
          errorStatus = true;
          errorList = {
            ...errorList,
            postScheduleTime: [
              'Please select the dateand time to schedule the post.',
            ],
          };
        }
      }

      //Comment Validation
      if (!engagement.comment_html) {
        let commentData = engagement.comment;
        if (commentData !== '') {
          commentData = JSON.parse(commentData);
          if (typeof commentData.blocks !== 'undefined') {
            if (commentData.blocks[0].text === '') {
              errorStatus = true;
              errorList = { ...errorList, comment: ['Required'] };
            }
            if (commentData.blocks[0].text.length > 5000) {
              errorStatus = true;
              errorList = {
                ...errorList,
                comment: ['Please enter upto 5000 characters'],
              };
            }
          } else {
            errorStatus = true;
            errorList = { ...errorList, comment: ['Required'] };
          }
          if (commentData.blocks[0].text.length > 5000) {
            errorStatus = true;
            errorList = {
              ...errorList,
              comment: ['Please enter up to 5000 characters'],
            };
          }
        } else {
          errorStatus = true;
          errorList = { ...errorList, comment: ['Required'] };
        }
      }

      if (engagement.category !== 'survey') {
        if (errorStatus) {
          reject(errorList);
        } else {
          resolve(true);
        }
        return;
      }

      const rules = {
        surveyTitle: 'required|max:500',
        description: 'max:5000',
      };

      const { isValid, errors } = allFieldsValidation(engagement, rules, {
        'required.surveyTitle': 'Required.',
        'max.surveyTitle': 'Please enter up to 500 characters',
        'max.description': 'Please enter up to 5000 characters',
      });

      if (!isValid) {
        errorStatus = true;
      }

      //Question section validation
      if (typeof errors !== undefined) {
        errorList = { ...errorList, ...errors };
      }
      errorList = { ...errorList, question: [] };

      engagement['question'].forEach((question, id) => {
        let questionRules = { type: 'required', title: 'required|max:5000' };
        let validationMsg = {
          'required.type': 'Required',
          'required.title': 'Required',
          'max.title': 'Please enter up to 5000 characters',
        };
        let { isValid, errors } = allFieldsValidation(
          question,
          questionRules,
          validationMsg,
        );
        if (!isValid) {
          errorStatus = true;
          errorList['question'][id] = errors;
        } else {
          errorList['question'][id] = { type: [], title: [], choice: [] };
        }

        if (engagement['question'][id]['choice'].length > 0) {
          errorList['question'][id]['choice'] = [];
          engagement['question'][id]['choice'].forEach((choice, choiceId) => {
            let choiceRules = { value: 'required|max:500' };
            let validationMsg = {
              'required.value': 'Required',
              'max.value': 'Please enter up to 500 characters',
            };
            let { isValid, errors } = allFieldsValidation(
              choice,
              choiceRules,
              validationMsg,
            );

            if (!isValid) {
              errorStatus = true;
              errorList['question'][id]['choice'][choiceId] = errors;
            }
          });
        }
      });
      if (errorStatus) {
        reject(errorList);
      } else {
        resolve(true);
      }
    });
  };
};

export const createEngagement = () => {
  return async (dispatch, getState) => {
    //Document Upload
    dispatch({ type: SET_ENGAGEMENT_SUBMITTING, payload: true });
    dispatch({ type: SET_ENGAGEMENT_LOADING, payload: true });
    let files = getState().engagement.engagementFormData.files;
    if (files.length > 0) {
      await dispatch(uploadToS3(files));
    }
    //await dispatch(removeFromS3());

    let engagement = getState().engagement.engagementFormData;
    let engagementFiles = getState().engagement.engagementAttachment;
    let engagementData = {};
    let topicArray = convertTagsToBackend(engagement.tags);
    engagementData = {
      category: engagement.category,
      target_population: engagement.targetPopulation,
      post_under_organization: engagement.postUnderOrganization,
      topics: topicArray,
      non_constituents: engagement.visibleToNonConsttuent,
      discussion_board: engagement.enableDiscussionBoard,
      comment: engagement.comment,
      linkMeta: engagement.linkMeta || null,
      comment_html: engagement.comment_html || null,
    };

    if (
      engagement.category === 'survey' ||
      engagement.category === 'discussion'
    ) {
      let engagement_timeframe = moment
        .utc(engagement.engagementTimeFrame)
        .format('YYYY-MM-DD HH:mm:ss');
      let closeTimeFrame = moment
        .utc(engagement.closeTimeFrame)
        .format('YYYY-MM-DD HH:mm:ss');
      let completeTimeFrame = moment
        .utc(engagement.completeTimeFrame)
        .format('YYYY-MM-DD HH:mm:ss');

      engagementData = {
        ...engagementData,
        start_now: engagement.startNow,
        engagement_timeframe: engagement_timeframe,
        engagement_closing: closeTimeFrame,
        engagement_completion: completeTimeFrame,
      };
    }

    if (engagement.category === 'post') {
      engagementData = {
        ...engagementData,
        is_scheduled_info_post: engagement.schedulePost,
      };
      if (engagement.schedulePost === true) {
        let postScheduleTime = moment
          .utc(engagement.postScheduleTime)
          .format('YYYY-MM-DD HH:mm:ss');
        engagementData = {
          ...engagementData,
          engagement_timeframe: postScheduleTime,
        };
      }
    }

    if (engagement.category === 'survey') {
      let questionData = engagement?.question;
      /*engagement['question'].forEach((question, id) => {
          questionData[id]['type'] = question.type.value;
      });*/
      engagementData = {
        ...engagementData,
        questions: questionData,
        title: engagement.surveyTitle,
      };

      engagementData = {
        ...engagementData,
        attached_documents: engagement.links,
        files: engagementFiles,
        description: engagement.description,
        linksToBeRemoved: engagement.linksToBeRemoved,
      };
    } else if (engagement.category === 'post') {
      engagementData = {
        ...engagementData,
        attached_documents: engagement.links,
        files: engagementFiles,
        linksToBeRemoved: engagement.linksToBeRemoved,
      };
    }

    // If there is a preview link, make sure an image does not appear
    if (engagementData.linkMeta) {
      delete engagementData.files;
    }

    //API CALL TO SERVER
    if (typeof engagement.id !== 'undefined') {
      //Edit Engagement
      await axios
        .put(
          BASE_API_URL + '/official/engagement/' + engagement.id,
          engagementData,
        )
        .then(async (response) => {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Engagement successfully updated.',
              module: 'dashboard',
              displayFor: 5000,
            }),
          );
          let formData = {};
          formData['dismissal'] = true;
          dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
          //dispatch({ type: SET_RESET_STATUS, payload: true });
          //dispatch({ type: ENGAGEMENTS_RESET, payload: 'unpublished' });
          let status = engagement.startNow === false ? 'unpublished' : 'open';
          // dispatch(setReloadEngagementStatus(true, status));
          // dispatch(getEngagements(status));
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'engagement',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_ENGAGEMENT_SUBMITTING, payload: false });
          dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
        });
    } else {
      await axios
        .post(BASE_API_URL + '/official/new-engagement', engagementData)
        .then((response) => {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Engagement successfully created.',
              module: 'dashboard',
              displayFor: 5000,
            }),
          );
          let formData = {};
          formData['dismissal'] = true;
          dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });

          dispatch(resetEngagementForm());
          let status = engagement.startNow === false ? 'unpublished' : 'open';
          dispatch(setReloadEngagementStatus(true, status));
          dispatch(getEngagements(status));
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'engagement',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_ENGAGEMENT_SUBMITTING, payload: false });
          dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
        });
    }
  };
};

//Navigate To Previous Step
export const setPreviousStep = () => {
  let formData = {};
  return async (dispatch, getState) => {
    let step = getState().engagement.engagementFormData.step;

    if (step === 2) {
      formData['step'] = 1;
    } else {
      formData['step'] = step;
    }
    return dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const getTitle = () => {
  return async (dispatch, getState) => {
    let step = getState().engagement.engagementFormData.step;
    let category = getState().engagement.engagementFormData.category;
    let formData = {};
    formData['title'] = 'Start New Engagement';
    if (step === 1) {
      formData['title'] = 'Start New Engagement';
    } else {
      if (category !== 'undefined') {
        if (category === 'survey') {
          formData['title'] = 'New Survey';
        } else if (category === 'post') {
          formData['title'] = 'New Post';
        } else {
          formData['title'] = 'New Discussion Topic';
        }
      }
    }
    return dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const getEngagements = (status, page = 1, sort = null) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let noRecordStatus = getState().engagement.noRecords;
      let searchKey = getState().search.searchKey;
      let apiUri = '/official/engagement/stage/' + status + '/' + page;

      if (sort) {
        apiUri += '?sort=' + sort;
      }

      if (searchKey.length > 0) {
        apiUri =
          '/official/search?stage=' +
          status +
          '&keyword=' +
          searchKey +
          '&page=' +
          page;
      }
      dispatch({ type: SET_ENGAGEMENT_LOADING, payload: true });
      if (page === 1) {
        dispatch({ type: ENGAGEMENTS_RESET, payload: status });
      }
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().engagement.engagements[status].length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_ENGAGEMENTS,
                  payload: { engagement: response.data.data, status: status },
                });
              }
            } else if (
              page === 1 &&
              getState().engagement.engagements[status].length === 0
            ) {
              dispatch({
                type: FETCH_ENGAGEMENTS,
                payload: { engagement: response.data.data, status: status },
              });
            }

            if (response.data.data.length === 0) {
              noRecordStatus[status] = true;
              dispatch({ type: SET_NO_RECORD_STATUS, payload: noRecordStatus });
            }

            dispatch({ type: SET_RESET_STATUS, payload: false });
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
        })
        .finally(() => {
          dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const updateEngagementStatus = (engagement, status, comment = '') => {
  return async (dispatch, getState) => {
    if (getState().engagement.isEngagementLoading) { // Do not send another request if request is already running
      return;
    }

    dispatch({ type: SET_ENGAGEMENT_LOADING, payload: true });
    let engagementData = {};
    if (comment === '') {
      engagementData = { id: engagement.id, stage: status };
    } else {
      engagementData = { id: engagement.id, stage: status, comment: comment };
    }
    if (status === 'closed') {
      engagementData.engagement_end = engagement.engagement_end;
    }

    if (status === 'outcome') {
      if (typeof engagement.selectedQuestionList !== 'undefined') {
        engagementData = {
          ...engagementData,
          questionId: engagement.selectedQuestionList,
        };
      } else {
        engagementData = { ...engagementData, questionId: [] };
      }
    }

    //let oldStatus = engagement.stage;
    await axios
      .post(BASE_API_URL + '/official/engagement/stage/', engagementData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        if (response.data.success === 'true') {
          //dispatch({type: SET_ENGAGEMENT_TAB, payload: status });
          //dispatch(setReloadEngagementStatus(true));
          let message = 'Engagement status updated.'
          if (status === 'open') {
            message = 'Your engagement was successfully published!'
          } else if (status === 'closed') {
            message = 'Your engagement was successfully closed.'
          } else if (status === 'outcome') {
            message = 'The outcome to your engagement was successfully published.'
          }
          dispatch(
            addNotification({
              type: 'success',
              message,
              module: 'dashboard',
              displayFor: 5000,
            }),
          );

          dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: 'error',
            message: error?.response?.data?.error,
            module: 'dashboard',
          }),
        );
      })
      .finally(() => {
        dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const setReloadEngagementStatus = (value, status = '') => {
  return async (dispatch, getState) => {
    let formData = [];
    formData['reloadList'] = value;
    let activeTab = getState().engagement.activeTab;
    if (status !== '') {
      activeTab = status;
    }
    formData['activeTab'] = activeTab;
    dispatch({ type: ENGAGEMENT_RELOAD_LIST, payload: formData });
  };
};

export const toggleTab = (status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENGAGEMENT_TAB, payload: status });
    let noRecordStatus = getState().engagement.noRecords;
    noRecordStatus[status] = false;
    dispatch({ type: SET_NO_RECORD_STATUS, payload: noRecordStatus });
  };
};

export const removeLink = (path, index) => {
  let formData = {};
  return async (dispatch, getState) => {
    let links = getState().engagement.engagementFormData.links;
    let linksToBeRemoved =
      getState().engagement.engagementFormData.linksToBeRemoved;
    linksToBeRemoved = [...linksToBeRemoved, path];
    links.splice(index, 1);
    links = links.filter((link) => link !== null);
    formData['linksToBeRemoved'] = linksToBeRemoved;
    formData['links'] = links;

    dispatch({ type: REMOVE_ATTACHEMENT, payload: formData });
    return dispatch({ type: ENGAGEMENT_CHANGE, payload: formData });
  };
};

export const uploadToS3 = (files) => {
  return async (dispatch, getState) => {
    let error = false;
    let errorList = getState().engagement.formErrors;
    let linkMeta = getState().engagement.engagementFormData.linkMeta;
    // If linkMeta exist, don't upload file content
    if (linkMeta) {
      return;
    }
    try {
      let links =
        getState().engagement.engagementFormData.links === null
          ? []
          : getState().engagement.engagementFormData.links;
      const engagementFiles = new FormData();
      files.forEach((file, i) => {
        engagementFiles.append('files', file, file.name);
      });
      await axios
        .post(BASE_API_URL + '/official/fileuploader', engagementFiles, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'user-token': localStorage.getItem('token'),
          },
        })
        .then((response) => {
          dispatch({
            type: ENGAGEMENT_ATTACHMENT,
            payload: response.data.data.images,
          });
          dispatch(engagementChange('links', links));
          return dispatch(engagementChange('files', files));
        });
    } catch (e) {
      error = true;
      return e;
    }
    if (!error) {
      //dispatch(engagementChange('files', []));
      if (errorList.hasOwnProperty('files') === true) {
        delete errorList['files'];
        dispatch({ type: SET_ENGAGEMENT_FORM_ERRORS, payload: errorList });
      }
    } else {
      errorList = {
        ...errorList,
        files: [
          'Error on uploading files. Please remove it and upload it again.',
        ],
      };
      dispatch({ type: SET_ENGAGEMENT_FORM_ERRORS, payload: errorList });
    }
  };
};

export const fetchEngagements = (activeTab, status, page) => {
  return async (dispatch, getState) => {
    if (activeTab === status) {
      return dispatch(getEngagements(status, page, 2));
    }
  };
};

export const reactToEngagement = (engagement) => {
  return async (dispatch, getState) => {};
};

export const resetEngagementForm = () => {
  return async (dispatch, getState) => {
    return dispatch({
      type: SET_ENGAGEMENT_FORM_RESET,
    });
  };
};

export const resetEngagementData = (status = 'unpublished') => {
  return async (dispatch, getState) => {
    return dispatch({ type: ENGAGEMENTS_RESET, payload: status });
  };
};

export const searchResultReset = (type) => {
  return async (dispatch, getState) => {
    if (type === 'submit') {
      dispatch(setSearchSubmision(false));
      dispatch(setSearchResetResult(false));
    } else if (type === 'reset') {
      dispatch(setSearchResetResult(false));
    }
  };
};

export const sendSurveyDetail = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(BASE_API_URL + '/users/sendSurveyDetails', { postId: id })
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'dashboard',
                displayFor: 5000,
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
              displayFor: 5000,
            }),
          );
        });
      return resolve(true);
    });
  };
};

export const setEngagementData = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: ENGAGEMENT_SPECIFIC_RESET });
      await axios
        .get(BASE_API_URL + '/users/civicfeed/' + id)
        .then((response) => {
          if (response.data.success === 'true') {
            if (typeof response.data.data !== 'undefined') {
              dispatch({
                type: ENGAGEMENT_SPECIFIC_DATA,
                payload: response.data.data,
              });
            }
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const updateEngagementSpecific = (data) => {
  return async (dispatch, getState) => {
    dispatch({ type: ENGAGEMENT_SPECIFIC_DATA, payload: data });
  };
};

export const getTagsData = (type, value) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let params = new URLSearchParams();
      params.append('searchBy', type);
      params.append('keyword', value);
      await axios
        .get(BASE_API_URL + '/users/followingandLocations/?' + params)
        .then((response) => {
          if (response.data.success === 'true') {
            let data = response.data.data[type];
            let result = [];
            result = data.map((item) => {
              return { id: item.name, text: item.name, uid: item.id };
            });
            return resolve(result);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const deleteEngagement = (engagement) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ENGAGEMENT_LOADING, payload: true });
    await axios
      .delete(BASE_API_URL + '/official/deletePost/' + engagement.id)
      .then((response) => {
        if (response.data.success === 'true') {
          dispatch({
            type: REMOVE_ENGAGEMENT,
            payload: {
              filterId: engagement.id,
              filterStatus: engagement.stage,
            },
          });
          let engagements = getState().engagement.engagements[engagement.stage];
          if (engagements.length === 0) {
            dispatch(setReloadEngagementStatus(true, engagement.stage));
          }
          let engagementSpecific = getState().engagement.engagementSpecific;
          if (typeof engagementSpecific.id !== 'undefined') {
            if (engagementSpecific.id === engagement.id) {
              dispatch({ type: ENGAGEMENT_SPECIFIC_RESET });
            }
          }

          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'dashboard',
              displayFor: 5000,
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        if (Array.isArray(errorList) === true) {
          errorList.forEach((error, index) => {
            dispatch(
              addNotification({
                type: 'error',
                message: errorList[index],
                module: 'dashboard',
                parentDivClass: 'top-notification',
              }),
            );
          });
        } else {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList,
              module: 'dashboard',
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .finally(() => {
        dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
      });
  };
};

export const changeDeleteHandle = (value) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_DELETE_HANDLE, payload: value });
  };

  /*
  dispatch({ type: SET_ENGAGEMENT_LOADING, payload: true });
    dispatch({type: REMOVE_ENGAGEMENT, payload: {filterId: engagement.id, filterStatus: engagement.stage}})
    dispatch({ type: SET_ENGAGEMENT_LOADING, payload: false });
  */
};
