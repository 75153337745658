/**
 *
 * Tabs
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TabTitle from './Title';
import TabContent from './Content';

const Tabs = (props) => {
  const { children, id, panelClass, tabbableClass, tabContentId } = props;
  const [openStatus, setOpenStatus] = useState(null);

  useEffect(() => {
    setOpenStatus(null);
  }, [children]);

  useEffect(() => {
    if (openStatus === null) {
      let openFlag = {};
      children.forEach((child) => {
        if (
          typeof child.props.isActive !== 'undefined' &&
          child.props.isActive === 'true'
        ) {
          openFlag = { ...openFlag, [child.props.linkId]: true };
        } else {
          openFlag = { ...openFlag, [child.props.linkId]: false };
        }
      });
      setOpenStatus(openFlag);
    } else {
    }
  }, [children, openStatus]);

  const changeOpenStatus = (linkId) => {
    let openFlag = {};
    children.forEach((child) => {
      if (child.props.linkId === linkId) {
        openFlag = { ...openFlag, [child.props.linkId]: true };
      } else {
        openFlag = { ...openFlag, [child.props.linkId]: false };
      }
    });
    setOpenStatus(openFlag);
  };

  return (
    <div className={`tabbable-panel ${panelClass}`}>
      <div className={`tabbable-line ${tabbableClass}`}>
        <ul className="nav nav-tabs" id={id} role="tablist">
          {children.map((child) => (
            <TabTitle
              id={child.props.id}
              tabTitle={child.props.tabTitle}
              linkId={child.props.linkId}
              isActive={
                openStatus !== null ? openStatus[child.props.linkId] : false
              }
              onClick={() => {
                changeOpenStatus(child.props.linkId);
                if (child.props.onClick instanceof Function)
                  child.props.onClick();
              }}
              key={child.props.linkId}
            />
          ))}
        </ul>
        <div className="tab-content" id={tabContentId}>
          {children.map((child) => (
            <TabContent
              linkId={child.props.linkId}
              effectClass={child.props.effectClass}
              isActive={
                openStatus !== null ? openStatus[child.props.linkId] : false
              }
              key={child.props.linkId}
            >
              {child.props.children}
            </TabContent>
          ))}
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

Tabs.defaultProps = {
  panelClass: '',
  tabbableClass: '',
  id: '',
  tabContentId: '',
};

export default Tabs;
