/**
 *
 * Dashboard Pages
 *
 */
import i18next from 'i18next';

let pages = [];

pages['ADMIN'] = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'DashboardIcon',
    component: require('../Engagement').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Dashboard'),
  },
  {
    name: 'CivicFeed',
    to: '/dashboard/civicfeed',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: '', // Do not translate propietary information
  },
  {
    name: 'Community Issues',
    to: '/community-issues',
    icon: 'CommunityIssuesIcon',
    component: require('../CommunityIssues').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Community Issues'),
  },
  {
    name: 'Government Officials',
    to: '/dashboard/govtofficials',
    icon: 'GovtOfficialIcon',
    component: require('../GovtOfficial').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Government Officials')
  },
  {
    name: 'Petitions',
    to: '/dashboard/petitions',
    icon: 'PetitionIcon',
    component: require('../Petitions').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Petiitons'),
  },
  {
    name: 'Events',
    to: '/dashboard/events',
    icon: 'EventsIcon',
    component: require('../Events').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Events'),
  },
  {
    name: 'Event',
    to: '/dashboard/event',
    icon: 'EventsIcon',
    component: require('../Events/view').default,
    // navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Event'),
  },
  {
    name: 'Profile',
    to: '/dashboard/profile',
    icon: 'DashboardIcon',
    component: require('../Dashboard/profile').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Profile'),
  },
  {
    name: 'Setting',
    to: '/dashboard/setting',
    icon: 'DashboardIcon',
    component: require('../Dashboard/setting').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Setting'),
  },
  {
    name: 'Legal',
    to: '/dashboard/legal',
    icon: 'DashboardIcon',
    component: require('../Dashboard/legal').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Legal'),
  },
  {
    name: 'Engagement',
    to: '/dashboard/engagement',
    icon: 'EngagementIcon',
    component: require('../Engagement/view').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('Engagement'),
  },
  {
    name: 'Engagement Analytics',
    to: '/dashboard/analytics',
    icon: 'EngagementIcon',
    component: require('../Analytics').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Engagement Analytics'),
  },
  {
    name: 'Help',
    to: 'https://help.civicbell.com',
    icon: 'HelpIcon',
    component: '',
    navBar: true,
    leftPanel: false,
    rightPanel: false,
    type: 'link',
    translationParsing: i18next.t('Help'),
  },
  {
    name: 'Search Result',
    to: '/dashboard/searchresult',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Search Result'),
  },
];

pages['PUBLICOFFICIAL'] = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'DashboardIcon',
    component: require('../Engagement').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Dashboard'),
  },
  {
    name: 'CivicFeed',
    to: '/dashboard/civicfeed',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: '', // Do not translate propietary information
  },
  {
    name: 'Community Issues',
    to: '/community-issues',
    icon: 'CommunityIssuesIcon',
    component: require('../CommunityIssues').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Community Issues'),
  },
  {
    name: 'Local News',
    to: '/dashboard/localnews',
    icon: 'LocalNewsIcon',
    component: require('../LocalNews').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Local News'),
  },
  {
    name: 'Government Officials',
    to: '/dashboard/govtofficials',
    icon: 'GovtOfficialIcon',
    component: require('../GovtOfficial').default,
    type: 'page',
    translationParsing: i18next.t('Government Officials'),
  },
  {
    name: 'Initiatives',
    to: '/dashboard/initiatives',
    icon: 'PetitionIcon',
    component: require('../Petitions').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Initiatives'),
  },
  {
    name: 'CivicMap',
    to: '/dashboard/civicmap',
    icon: 'CivicMapIcon',
    component: require('../CivicMap').default,
    navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    authentication: false,
    translationParsing: '', // Do not translate propietary information
  },
  {
    name: 'Events',
    to: '/dashboard/events',
    icon: 'EventsIcon',
    component: require('../Events').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Events')
  },
  {
    name: 'Event',
    to: '/dashboard/event',
    icon: 'EventsIcon',
    component: require('../Events/view').default,
    // navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Event')
  },
  {
    name: 'Profile',
    to: '/dashboard/profile',
    icon: 'DashboardIcon',
    component: require('../Dashboard/profile').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Profile')
  },
  {
    name: 'Setting',
    to: '/dashboard/setting',
    icon: 'DashboardIcon',
    component: require('../Dashboard/setting').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Setting')
  },
  {
    name: 'Legal',
    to: '/dashboard/legal',
    icon: 'DashboardIcon',
    component: require('../Dashboard/legal').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Legal')
  },
  {
    name: 'Engagement',
    to: '/dashboard/engagement',
    icon: 'EngagementIcon',
    component: require('../Engagement/view').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('Engagement')
  },
  {
    name: 'Initiatives',
    to: '/dashboard/initiative',
    icon: 'PetitionIcon',
    component: require('../Petitions/view').default,
    // navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Initiatives')
  },
  {
    name: 'Invite Others',
    to: '/dashboard/inviteothers',
    icon: 'InviteOthersIcon',
    component: require('../InviteOthers').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Invite Others')
  },
  {
    name: 'Engagement Analytics',
    to: '/dashboard/analytics',
    icon: 'EngagementIcon',
    component: require('../Analytics').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Engagement Analytics')
  },
  {
    name: 'Push Notification',
    to: '/dashboard/pushnotification',
    icon: 'Pushnotification',
    component: require('../PushNotification').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Push Notification')
  },
  {
    name: 'User Profile',
    to: '/dashboard/user-profile',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed/user-profile').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('User Profile')
  },
  {
    name: 'Help',
    to: 'https://help.civicbell.com',
    icon: 'HelpIcon',
    component: '',
    navBar: true,
    leftPanel: false,
    rightPanel: false,
    type: 'link',
    translationParsing: i18next.t('Help')
  },
  {
    name: 'Search Result',
    to: '/dashboard/searchresult',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Search Result')
  },
];

pages['RESIDENT'] = [
  {
    name: 'CivicFeed',
    to: '/dashboard/civicfeed',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: ''// Do not translate propietary information
  },
  {
    name: 'Community Issues',
    to: '/community-issues',
    icon: 'CommunityIssuesIcon',
    component: require('../CommunityIssues').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Community Issues'),
  },
  {
    name: 'Local News',
    to: '/dashboard/localnews',
    icon: 'LocalNewsIcon',
    component: require('../LocalNews').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Local News')
  },
  {
    name: 'Your Activities',
    to: '/dashboard/civiclist',
    icon: 'EngagementIcon',
    component: require('../CivicFeed').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Your Activities')
  },
  {
    name: 'CivicMap',
    to: '/dashboard/civicmap',
    icon: 'CivicMapIcon',
    component: require('../CivicMap').default,
    navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    authentication: false,
    translationParsing: '' // Do not translate propietary information
  },
  {
    name: 'District Officials',
    to: '/dashboard/district-officials',
    icon: 'CivicMapIcon',
    component: require('../CivicMap/district-officials').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('District Officials'),
  },
  {
    name: 'User Profile',
    to: '/dashboard/user-profile',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed/user-profile').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('User Profile')
  },
  {
    name: 'Initiatives',
    to: '/dashboard/initiatives',
    icon: 'PetitionIcon',
    component: require('../Petitions').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Initiatives')
  },
  {
    name: 'Events',
    to: '/dashboard/events',
    icon: 'EventsIcon',
    component: require('../Events').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Events')
  },
  {
    name: 'Event',
    to: '/dashboard/event',
    icon: 'EventsIcon',
    component: require('../Events/view').default,
    // navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Event')
  },
  {
    name: 'Profile',
    to: '/dashboard/profile',
    icon: 'DashboardIcon',
    component: require('../Dashboard/profile').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Profile')
  },
  {
    name: 'Setting',
    to: '/dashboard/setting',
    icon: 'DashboardIcon',
    component: require('../Dashboard/setting').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Setting')
  },
  {
    name: 'Legal',
    to: '/dashboard/legal',
    icon: 'DashboardIcon',
    component: require('../Dashboard/legal').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Legal')
  },
  {
    name: 'Engagement',
    to: '/dashboard/engagement',
    icon: 'EngagementIcon',
    component: require('../Engagement/view').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('Engagement')
  },
  {
    name: 'Initiatives',
    to: '/dashboard/initiative',
    icon: 'PetitionIcon',
    component: require('../Petitions/view').default,
    // navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Initiatives'),
  },
  {
    name: 'Invite Others',
    to: '/dashboard/inviteothers',
    icon: 'InviteOthersIcon',
    component: require('../InviteOthers').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Invite Others')
  },
  {
    name: 'Push Notification',
    to: '/dashboard/pushnotification',
    icon: 'Pushnotification',
    component: require('../PushNotification').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Push Notification')
  },
  {
    name: 'Help',
    to: 'https://help.civicbell.com',
    icon: 'HelpIcon',
    component: '',
    navBar: true,
    leftPanel: false,
    rightPanel: false,
    type: 'link',
    translationParsing: i18next.t('Help')
  },
  {
    name: 'Search Result',
    to: '/dashboard/searchresult',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Search Result')
  }
];

pages['INDIVIDUAL'] = [
  {
    name: 'CivicFeed',
    to: '/dashboard/civicfeed',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: '' // Do not translate propietary information
  },
  {
    name: 'Community Issues',
    to: '/community-issues',
    icon: 'CommunityIssuesIcon',
    component: require('../CommunityIssues').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Community Issues'),
  },
  {
    name: 'Your Activities',
    to: '/dashboard/civiclist',
    icon: 'EngagementIcon',
    component: require('../CivicFeed').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Your Activities'),
  },
  {
    name: 'Petitions',
    to: '/dashboard/petitions',
    icon: 'PetitionIcon',
    component: require('../Petitions').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Petitions')
  },
  {
    name: 'Events',
    to: '/dashboard/events',
    icon: 'EventsIcon',
    component: require('../Events').default,
    // navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Events')
  },
  {
    name: 'Profile',
    to: '/dashboard/profile',
    icon: 'DashboardIcon',
    component: require('../Dashboard/profile').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Profile')
  },
  {
    name: 'Setting',
    to: '/dashboard/setting',
    icon: 'DashboardIcon',
    component: require('../Dashboard/setting').default,
    navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Setting')
  },
  {
    name: 'Legal',
    to: '/dashboard/legal',
    icon: 'DashboardIcon',
    component: require('../Dashboard/legal').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    translationParsing: i18next.t('Legal')
  },
  {
    name: 'Engagement',
    to: '/dashboard/engagement',
    icon: 'EngagementIcon',
    component: require('../Engagement/view').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('Engagement')
  },
  {
    name: 'Help',
    to: 'https://help.civicbell.com',
    icon: 'HelpIcon',
    component: '',
    navBar: true,
    leftPanel: false,
    rightPanel: false,
    type: 'link',
    translationParsing: i18next.t('Help')
  },
  {
    name: 'Search Result',
    to: '/dashboard/searchresult',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    translationParsing: i18next.t('Search Result')
  },
];

pages['GUEST'] = [
  {
    name: 'CivicFeed',
    to: '/dashboard/civicfeed',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: '' // Do not translate propietary information
  },
  {
    name: 'Community Issues',
    to: '/community-issues',
    icon: 'CommunityIssuesIcon',
    component: require('../CommunityIssues').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('Community Issues'),
  },
  {
    name: 'Local News',
    to: '/dashboard/localnews',
    icon: 'LocalNewsIcon',
    component: require('../LocalNews').default,
    navBar: true,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('Local News'),
  },
  {
    name: 'CivicMap',
    to: '/dashboard/civicmap',
    icon: 'CivicMapIcon',
    component: require('../CivicMap').default,
    navBar: true,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    authentication: false,
    translationParsing: '' // Do not translate propietary information
  },
  {
    name: 'Help',
    to: 'https://help.civicbell.com',
    icon: 'HelpIcon',
    component: '',
    navBar: true,
    leftPanel: false,
    rightPanel: false,
    type: 'link',
    authentication: false,
    translationParsing: i18next.t('Help')
  },
  {
    name: 'User Profile',
    to: '/dashboard/user-profile',
    icon: 'CivicFeedIcon',
    component: require('../CivicFeed/user-profile').default,
    navBar: false,
    leftPanel: true,
    rightPanel: false,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('User Profile')
  },
  {
    name: 'Engagement',
    to: '/dashboard/engagement',
    icon: 'EngagementIcon',
    component: require('../Engagement/view').default,
    navBar: false,
    leftPanel: true,
    rightPanel: true,
    type: 'page',
    authentication: false,
    translationParsing: i18next.t('Engagement')
  }
]

export default pages;

export const landingPages = {
  ADMIN: '/dashboard',
  PUBLICOFFICIAL: '/dashboard',
  RESIDENT: '/dashboard/civicfeed',
  INDIVIDUAL: '/dashboard/civicfeed',
  GUEST: '/dashboard/civicfeed'
};
