/*
 *
 * ForgotPassword reducer
 *
 */

import {
  FORGOT_PASSWORD_CHANGE,
  FORGOT_PASSWORD_RESET,
  SET_FORGOT_PASSWORD_FORM_ERRORS,
  SET_FORGOT_PASSWORD_SUBMITTING,
  RESET_APP,
} from './constants';

const initialState = {
  forgotFormData: {
    email: '',
  },
  formErrors: {},
  isFormSubmitting: false,
};

const defaultState = JSON.stringify(initialState);

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case FORGOT_PASSWORD_CHANGE:
      return {
        ...state,
        forgotFormData: {
          email: action.payload,
        },
      };
    case SET_FORGOT_PASSWORD_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };
    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        forgotFormData: {
          email: '',
        },
        formErrors: {},
        isFormSubmitting: false,
        isFormLoading: false,
      };
    case SET_FORGOT_PASSWORD_SUBMITTING:
      return {
        ...state,
        isFormSubmitting: action.payload,
      };
    default:
      return state;
  }
};

export default forgotPasswordReducer;
