/**
 *
 * Profile Image Upload Form
 *
 */

import React from 'react';
import CivicProfileImg from '../../Common/CivicProfileImg';

const ProfileBgImageForm = (props) => {
  const {
    user,
    changeProfileBgData,
    profileBgImgDataChange,
    removeProfileBgImg,
    profileBgImgData,
    uploadProfileImage,
    addNotification,
    createInitialBgImg,
  } = props;

  return (
    <CivicProfileImg
      profileImgChange={changeProfileBgData}
      profileImg={profileBgImgData.file}
      link={
        profileBgImgData.profile_bg_picture === null
          ? require('../../../assets/img/profile-post-img.jpg').default
          : profileBgImgData.profile_bg_picture
      }
      removeProfileImg={removeProfileBgImg}
      profileImgDataChange={profileBgImgDataChange}
      user={user}
      uploadProfileImage={uploadProfileImage}
      type={'profileBgImage'}
      addNotification={addNotification}
      createDefault={createInitialBgImg}
    />
  );
};

export default ProfileBgImageForm;
