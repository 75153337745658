import axios from 'axios';
import { BASE_API_URL } from '../constants';
import heic2any from "heic2any";

const useS3 = () => {
  const s3Api = axios.create();
  s3Api.defaults.headers.common = {};

  const isProduction = window.location.hostname === 'civicbell.com';

  const uploadFileToS3 = (file) => new Promise(async (resolve, reject) => {
    let contentType = file.type;

    // Convert HEIC to JPEG before uploading
    if (file.type === 'image/heic') {
      try {
        const jpegBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.5,
        });
        file = jpegBlob;
        contentType = "image/jpeg";
      } catch (e) {
        reject(e);
        return;
      }
    }

    // Generate random file name
    const fileExtension = contentType === "image/jpeg" ? "jpeg" : file.name.split('.').pop();
    const fileName = `${Math.random().toString(36).substring(2, 15)}.${fileExtension}`;
    const contentDeposition = `inline;filename="${fileName}"`;
    const filePath = `community-issues/${fileName}`;
    const bucket = isProduction ? 'civic-prod-documents' : 'civic-dev-documents';

    const reader = new FileReader();
    reader.addEventListener('load', async () => {
      const arrayBuffer = new Uint8Array(reader.result);
      const { data } = await axios.get(
        `${BASE_API_URL}/users/s3SignedUrl?fileName=${encodeURIComponent(filePath)}&contentType=${encodeURIComponent(contentType)}&contentDeposition=${encodeURIComponent(contentDeposition)}&bucketName=${encodeURIComponent(bucket)}`,
      );

      if (!data || !data.data || !data.data.url) return;
      const { url } = data.data;
      await s3Api.put(url, arrayBuffer, {
        headers: {
          'Content-Type': contentType,
        },
      });

      resolve(`https://${bucket}.s3.us-west-1.amazonaws.com/${filePath}`);
    });
    reader.readAsArrayBuffer(file);
  });

  return { uploadFileToS3 };
};

export default useS3;
