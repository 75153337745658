/*
 *
 * CivicFeed constants
 *
 */
import i18next from 'i18next';

export const SET_CIVICFEED_LOADING = 'src/CivicFeed/SET_CIVICFEED_LOADING';
export const FETCH_CIVICFEED_ENGAGEMENTS =
  'src/CivicFeed/FETCH_CIVICFEED_ENGAGEMENTS';
export const CIVICFEED_RELOAD_LIST = 'src/CivicFeed/CIVICFEED_RELOAD_LIST';
export const CIVICFEED_CHANGE = 'src/CivicFeed/CIVICFEED_CHANGE';
export const CIVICFEED_RESET = 'src/CivicFeed/CIVICFEED_RESET';
export const CIVICFEED_LIST_CHANGE = 'src/CivicFeed/CIVICFEED_LIST_CHANGE';
export const SET_USER_PROFILE = 'src/CivicFeed/SET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'src/CivicFeed/UPDATE_USER_PROFILE';
export const RESET_USER_PROFILE = 'src/CivicFeed/RESET_USER_PROFILE';
export const RESET_APP = 'src/CivicFeed/RESET_APP';

export const sortOptions = [
  { value: 'newest', label: 'Newest on top', translationParsing: i18next.t('Newest on top') },
  { value: 'oldest', label: 'Oldest on top', translationParsing: i18next.t('Oldest on top') },
  { value: 'most_relevant', label: 'Most relevant', translationParsing: i18next.t('Most relevant') },
];

export const filterOptions = [
  { id: 'post', name: 'Info', translationParsing: i18next.t('Info') },
  { id: 'discussion', name: 'Discussion', translationParsing: i18next.t('Discussion') },
  { id: 'survey', name: 'Survey', translationParsing: i18next.t('Survey') },
  { id: 'location', name: 'Locations', translationParsing: i18next.t('Locations') },
  { id: 'type', name: 'Post Type', translationParsing: i18next.t('Post Type') }
]