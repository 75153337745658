import {
    REGISTER_CHANGE,
    REGISTER_RESET,
    RESET_APP,
    SET_CODE_VALIDATION_ERROR, SET_IS_SIGN_UP_BUTTON_CLICKED,
    SET_REGISTER_FORM_ERRORS,
    SET_REGISTER_FORM_SUBMISSION_ERRORS,
    SET_REGISTER_LOADING,
    SET_REGISTER_SUBMITTING,
    SET_REGISTRATION_MODAL_OPEN,
    SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN,
    SET_IS_LOGIN_MODAL_OPEN
} from './constants';

const initialState = {
    registerFormData: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        signUpType: 'resident',
        signUpTypeBoolean: false,
        officialEmailVerification: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        dob: '',
        phoneNumber: '',
        verificationCode: '',
        gender: {value: ''},
        signupStatus: '',
        personalId: '',
        highestEducationDegree: {value: ''},
        employmentStatus: {value: ''},
        race: '',
        ethnicity: {value: ''},
        martialStatus: {value: ''},
        politicalAffiliation: {value: ''},
        religion: {value: ''},
        officialEmail: '',
        signUpOfficialEmail: false,
        verificationBy: '',
        officialPhoneNumber: '',
        position: '',
        positionText: '',
        district: '',
        ocdId: '',
        step: 1,
        message: '',
        suggestionBox: {
            display: false,
            loading: true,
            items: [],
        },
        showEmailConfirmation: false,
        manualVerification: false,
        validateCredentials: false,
        newEmail: '',
        poVerifiedEmail: '',
        telesignVerified: false,
        registrationFinished: false,
        existingUser: false
    },
    formErrors: [],
    formSubmissionErrors: '',
    codeValidationError: false,
    isRegisterSubmitting: false,
    isRegisterLoading: false,
    isRegistrationModalOpen: false,
    isSignUpButtonClicked: false,
    isAccountConfirmationModalOpen: false,
    isLoginModalOpen: false,
};

const defaultState = JSON.stringify(initialState);

const registrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_APP:
            return {
                ...initialState,
                isRegistrationModalOpen: state.isRegistrationModalOpen,
                isSignUpButtonClicked: state.isSignUpButtonClicked
            };
        case REGISTER_CHANGE:
            return {
                ...state,
                registerFormData: {...state.registerFormData, ...action.payload},
            };
        case SET_REGISTER_FORM_ERRORS:
            return {
                ...state,
                formErrors: action.payload,
            };
        case SET_REGISTER_FORM_SUBMISSION_ERRORS:
            return {
                ...state,
                formSubmissionErrors: action.payload,
            };
        case SET_REGISTER_LOADING:
            return {
                ...state,
                isRegisterLoading: action.payload,
            };
        case SET_REGISTER_SUBMITTING:
            return {
                ...state,
                isRegisterSubmitting: action.payload,
            };
        case SET_CODE_VALIDATION_ERROR:
            return {
                ...state,
                codeValidationError: action.payload,
            };
        case REGISTER_RESET:
            return {
                ...state,
                initialState
            };
        case SET_REGISTRATION_MODAL_OPEN:
            return {
                ...state,
                isRegistrationModalOpen: action.payload
            };
        case SET_IS_SIGN_UP_BUTTON_CLICKED:
            return {
                ...state,
                isSignUpButtonClicked: action.payload
            };
        case SET_IS_ACCOUNT_CONFIRMATION_MODAL_OPEN:
            return {
                ...state,
                isAccountConfirmationModalOpen: action.payload
            };
        case SET_IS_LOGIN_MODAL_OPEN:
            return {
                ...state,
                isLoginModalOpen: action.payload
            };
        default:
            return state;
    }
};

export default registrationReducer;