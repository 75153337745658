import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../../Common/Tooltip";
import { connect } from "react-redux";
import { subYears } from 'date-fns';
import actions from "../../../actions";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_PLACES_API_KEY } from "../../../constants";
import DateInput from "../../Common/DateInput";
import moment from "moment";
import { Modal } from "react-bootstrap";
import DateSelection from "../../Common/DateSelection";

const ConfirmExistingUser = (props) => {

  const {
    user,
    updateUserDataSubmit,
    editUserChange,
    isAccountConfirmationModalOpen,
    setAccountConfirmationModalOpen
  } = props;

  const { t } = useTranslation();

  const [dateOfBirth, setDateOfBirth] = useState('');

  const InputForDate = React.forwardRef((props, ref) => (
    <DateInput innerRef={ref} {...props} />
  ));

  const [error, setError] = useState(null);

  useEffect(() => {
    if (user.dob !== '' && user.dob !== undefined) {
      if (moment(new Date(user.dob)).isValid()) {
        setDateOfBirth(new Date(user.dob));
      } else {
        setDateOfBirth(moment(user.dob, 'DD-MM-YYYY').toDate());
      }
    } else {
      setDateOfBirth('');
    }
  }, [user.dob])

  const confirmMyAccount = async (event) => {
    try {
      await updateUserDataSubmit();
    } catch (error) {
      setError(error?.response?.data?.error);
    }
  };

  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const storeAddress = (address) => {
    if (typeof address.name === 'undefined' && address) {
      formatAddress(address);
    }
  };

  const formatAddress = (address) => {
    let addr = {};

    editUserChange('zip_code', '');

    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          addr.address = component.long_name;
          editUserChange('address', addr.address)
          break;
        }
        case 'route': {
          addr.address = addr.hasOwnProperty('address')
            ? addr.address + ' ' + component.short_name
            : component.short_name;
          editUserChange('address', addr.address)
          break;
        }
        case 'postal_code': {
          addr.zip_code = component.long_name;
          editUserChange('zip_code', addr.zip_code)
          break;
        }
        case 'locality':
          addr.city = component.long_name;
          editUserChange('city', addr.city)
          break;
        case 'administrative_area_level_1': {
          addr.state = component.short_name;
          editUserChange('state', addr.state)
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  return <>
    <Modal
      show={isAccountConfirmationModalOpen}
      onHide={() => { setAccountConfirmationModalOpen(false) }}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title className="w-100 text-center">
          <strong>
            {t(`Sorry, we've updated our systems!`)}
          </strong>
          <br />
          {t(`Is the following information still accurate?`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="confirm-existing-user">
          <div className="form-group col-md-12">
            <label htmlFor="">{t(`Email`)}</label>
            <input
              type="text"
              className="form-control"
              placeholder={t(`Email Address`)}
              name={'email'}
              value={user.email}
              onChange={(e) => editUserChange(e.target.name, e.target.value)}
              autoComplete={'no'}
              id="regevent_email"
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="">{t(`Birthday`)}</label>
            <div className="position-relative">
              <DateSelection
                customInput={<InputForDate />}
                onChangeRaw={(e) => e.preventDefault()}
                placeholderText={t(`Birthday (MM-DD-YYYY)`)}
                selected={dateOfBirth}
                onChange={(date) => editUserChange('dob', date)}
                maxDate={subYears(new Date(), 13)}
                minDate={subYears(new Date(), 121)}
                dateFormat="MM-dd-yyyy"
                yearDropdownItemNumber={121}
                scrollableYearDropdown={true}
                showYearDropdown
                showMonthDropdown
                locale={user?.selected_language}
                timeCaption={t('Time')}
              />
              <Tooltip
                targetId={'birthdayTooltip'}
                message={t(
                  'The Children\'s Online Privacy Protection Rule ("COPPA") requires online services, including ours, to ensure all users are above 13 years of age.',
                )}
                parentDivClass={'tooltip-position-fix tooltip-position-fix--with-wrapper'}
              />
            </div>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="">{t(`Cell Phone Number`)}</label>
            <div className="position-relative">
              <div className="input-icon">
                <img
                  width="30px"
                  src={
                    require('../../../assets/img/us-flag.svg')
                      .default
                  }
                  alt="US flag"
                />
              </div>
              <input
                type="tel"
                className="form-control pl-5"
                placeholder={t(`Cell Phone Number`)}
                name={'phone_number'}
                value={user.phone_number}
                onChange={(e) => editUserChange(e.target.name, e.target.value)}
                id="regevent_phone"
                inputmode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="">{t(`Residential Address (incl. street number)`)}</label>
            <Autocomplete
              apiKey={GOOGLE_PLACES_API_KEY}
              className="form-control-plaintext"
              placeholder={t(`Your residential address`)}
              type="text"
              id="location_search"
              name="location_search"
              defaultValue={user.address ?
                user.address + ', ' + user.city + ', ' +
                user.state + ', ' + user.zip_code
                : ''}
              onKeyDown={preventSubmit}
              autoComplete="off"
              onPlaceSelected={(place) => {
                storeAddress(place);
              }}
              options={{
                types: ['address'],
                componentRestrictions: { country: 'us' },
                fields: ['address_components', 'formatted_address'],
              }}
              style={{
                border: '1px solid #ced4da',
                borderRadius: '10px',
                height: '50px',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '14px',
                lineHeight: '1.5',
                fontWeight: 400,
              }}
            />
          </div>
          <div 
            className="form-group col-md-12 text-center"
          >
            <div 
              className="clsinvalid"
            >
              {error && t(error)}
            </div>
          </div>
          <div className="form-group col-md-12 text-center">
            <button
              type="button"
              className="btn clsyellow-btn"
              onClick={confirmMyAccount}
            >{t(`Confirm My Account`)}</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>;
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    isAccountConfirmationModalOpen: state.registration.isAccountConfirmationModalOpen
  };
};

export default connect(mapStateToProps, actions)(ConfirmExistingUser);
