import {connect} from "react-redux";
import actions from "../../../actions";
import React, {useEffect, useRef, useState} from "react";
import SelectOption from "../../Common/SelectOption";
import {loadOptionValue} from "../../../utils/helper";
import {useTranslation} from "react-i18next";
import {getLocalNewsLists, submitLocalNewsFilter} from "../../../containers/LocalNews/actions";
import LoadingIndicator from "../../Common/LoadingIndicator";

const LocalNewsFilterWidget = (props) => {
  const {
    filter,
    filterSelected,
    getFilterNewsStates,
    getFilterNewsCounties,
    getFilterNewsFeeds,
    localNewsFilterChange,
    submitLocalNewsFilter,
  } = props;
  const {t} = useTranslation();
  const stateSelectRef = useRef(null);
  const countySelectRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [optionLoading, setOptionLoading] = useState(false);
  const {city_sources, county_sources, state_sources} = filter.feedSources;

  useEffect(() => {
    submitLocalNewsFilter();
  }, [JSON.stringify(filterSelected.feedSources)]);

  useEffect(() => {
    if (!filterSelected.state && !filterSelected.county) {
      stateSelectRef.current && stateSelectRef.current.clearValue();
      countySelectRef.current && countySelectRef.current.clearValue();
    }
  }, [filterSelected.state, filterSelected.county]);

  useEffect(() => {
    if (loading === true) {
      const filterStates = async () => {
        await getFilterNewsStates().then(async () => {
          setLoading(false);
          setOptionLoading(false);
        });
      };
      filterStates();
    }
  }, [loading, getFilterNewsStates]);

  useEffect(() => {
    if (filterSelected.state) {
      setOptionLoading(true);
      const filterCounties = async () => {
        await getFilterNewsCounties().then(async () => {
          setOptionLoading(false);
        });
      };
      filterCounties();
    }
  }, [filterSelected.state, getFilterNewsCounties]);

  useEffect(() => {
    if (filterSelected.county) {
      setOptionLoading(true);
      const filterOfficials = async () => {
        await getFilterNewsFeeds().then(async () => {
          setOptionLoading(false);
        });
      };
      filterOfficials();
    }
  }, [filterSelected.county, getFilterNewsFeeds]);

  const render = () => {
    if (optionLoading) {
      return <LoadingIndicator position="relative"/>
    }

    if (city_sources.length < 1 &&
      state_sources.length < 1 &&
      county_sources.length < 1 &&
      filterSelected.state &&
      filterSelected.county) {
      return <span>No state news sources found</span>
    }

    if (filterSelected.state && filterSelected.county) {
      return Object.keys(filter.feedSources).map(key => filter.feedSources[key].map((item, index) => (
          <div className="clscheckbox" key={index}>
                        <span style={{display: 'block', width: '100%'}}>
                          <label style={{width: '100%'}}>
                            <input
                              id={`feedSourcesWidget-${index}`}
                              checked={
                                filterSelected['feedSources'].indexOf(
                                  item.rssfeedId,
                                ) !== -1
                              }
                              onChange={(e) => {
                                e.target.value =
                                  filterSelected['feedSources'].indexOf(
                                    item.rssfeedId,
                                  ) !== -1;
                              }}
                              type="checkbox"
                              name="roomtemp"
                              value="school_elem"
                            />
                            <label
                              htmlFor={`feedSourcesWidget-${index}`}
                              onClick={() => {
                                localNewsFilterChange(
                                  'feedSources',
                                  item.rssfeedId,
                                );
                              }}
                              style={{width: '100%'}}
                            >
                              <div className="clsinner-div"
                                   style={{display: 'flex', justifyContent: 'space-between', gap: '5px'}}>
                                {item.news_source}
                                <span style={{flexShrink: 0}}></span>
                              </div>
                            </label>
                          </label>
                        </span>
          </div>
        ))
      )
    }
  }

  return (
    <div className="clsdashboard-upcoming-event clswhite-box">
      <h5 className="font-weight-bold mb-2">{ t('Filter') }</h5>
      <SelectOption
        ref={stateSelectRef}
        options={filter.states}
        placeholder={t(`State`)}
        name="state"
        value={loadOptionValue(filter.states, filterSelected.state)}
        handleSelectChange={(e) => localNewsFilterChange('state', e?.value || null)}
      />
      <div className="mb-2"/>
      <SelectOption
        disabled={!filterSelected.state}
        ref={countySelectRef}
        options={filter.counties}
        placeholder={t(`County`)}
        name="county"
        value={loadOptionValue(filter.counties, filterSelected.county)}
        handleSelectChange={(e) => localNewsFilterChange('county', e?.value || null)}
      />
      <div className="mb-3"/>
      {render()}
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    filter: state.localNews.filter,
    filterSelected: state.localNews.filterSelected,
    // localNewsLists is not used in this component but leave it since it triggers the state change,
    // if we have time we should check why updates to filter and filterSelected don't cause component to re-render in all situations
    localNewsLists: state.localNews.localNewsLists,
  };
};

export default connect(mapStateToProps, actions)(LocalNewsFilterWidget);
