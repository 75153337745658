/**
 *
 * Date Selection
 *
 */

import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import cn from 'date-fns/locale/zh-CN';
import tl from 'date-fns/locale/fi';
import vi from 'date-fns/locale/vi';
import ko from 'date-fns/locale/ko';
import ru from 'date-fns/locale/ru';

registerLocale('ES', es);
registerLocale('CN', cn);
registerLocale('TL', tl);
registerLocale('VI', vi);
registerLocale('KO', ko);
registerLocale('RU', ru);

const DateSelection = ({
  customInput,
  onChangeRaw,
  placeholderText,
  selected,
  onChange,
  showTimeSelect,
  showTimeSelectOnly,
  minDate,
  maxDate,
  timeIntervals,
  timeCaption,
  dateFormat,
  yearDropdownItemNumber,
  scrollableYearDropdown,
  showMonthDropdown,
  locale,
  id
}) => {
  return (
    <DatePicker
      customInput={customInput}
      onChangeRaw={onChangeRaw}
      placeholderText={placeholderText}
      selected={selected}
      onChange={onChange}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      minDate={minDate}
      maxDate={maxDate}
      timeIntervals={timeIntervals}
      timeCaption={timeCaption}
      dateFormat={dateFormat}
      yearDropdownItemNumber={yearDropdownItemNumber}
      scrollableYearDropdown={scrollableYearDropdown}
      showMonthDropdown={showMonthDropdown}
      locale={locale}
      id={id}
    />
  )
};

export default DateSelection;