import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';

const CommunityIssueEngagementBanner = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    allCommunityIssues,
  } = props;
  const communityIssue = allCommunityIssues[communityIssueId];

  const getBannerInfo = () => {
    if (communityIssue.isInGeographicArea) {
      return {
        className: 'community-issues-engagement-banner your-district',
        text: t('Addressed to an area within your district'),
      }
    } else if (communityIssue.isInDistrict) {
      return {
        className: 'community-issues-engagement-banner direct-district',
        text: t('Directly addressed to your district'),
      }
    } else {
      return {
        className: 'community-issues-engagement-banner outside-district',
        text: t('Outside your district'),
      }
    }
  }

  return (
    <div className={getBannerInfo().className}>
      <img
        src={require('../../../assets/img/map-dark-grey.svg').default}
        alt="Map Icon"
      />
      <b>{getBannerInfo().text}</b>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueEngagementBanner);