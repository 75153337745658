/**
 *
 * Resident Profile Edit Modal
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import CivicModal from '../../components/Common/CivicModal';
import ResidentProfile from '../../components/Manager/Profile/residentProfile';
import { useTranslation } from 'react-i18next';

const ResidentEdit = (props) => {
  const { user, editProfileData } = props;
  const { t } = useTranslation();

  return (
    <>
      {typeof editProfileData !== 'undefined' ? (
        <CivicModal
          id={`edit-profile-${user.userId}`}
          title={t(editProfileData.title)}
          dismissal={editProfileData.dismissal}
          modalClass="clsnew-petition-modal"
        >
          <ResidentProfile {...props} />
        </CivicModal>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    editProfileData: state.account.editProfileData,
    editProfileErrors: state.account.editProfileErrors,
    isProfileLoading: state.account.isProfileLoading,
  };
};

export default connect(mapStateToProps, actions)(ResidentEdit);
