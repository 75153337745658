import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { closeCommunityIssue } from '../../../containers/CommunityIssues/actions';

const CloseCommunityIssueEarlyConfirmationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    communityIssueId,
    allCommunityIssues,
    user
  } = props;
  const hashUrl = '#';

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div className="close-community-issue-early">
      <div className='text-container'>
        <p>
          {t(`Warning: You can't undo this action.`)}
        </p>
      </div>
      <div className='button-container'>
        <div className='text-btn-container'>
          <a
            href="#"
            data-toggle="modal"
            data-target={`#close-issue-${communityIssueId}`}
            onClick={() => { }}
            className='text-btn'
          >
            {t(`Go Back`)}
          </a>
        </div>
        <button
          type="button"
          className="btn clsyellow-btn"
          href="#"
          data-toggle="modal"
          data-target={`#close-issue-${communityIssueId}`}
          data-dismiss="modal"
          onClick={() => {
            dispatch(closeCommunityIssue(communityIssueId));
          }}
        >
          {t(`Close Early`)}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(CloseCommunityIssueEarlyConfirmationModal);