/**
 *
 * Civic Filter
 *
 */

import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import SelectOption from '../../Common/SelectOption';
import { recurringOptions } from '../../../containers/Events/constants';
import {
  handleOptionValue,
  getEventPublishedBy,
  isJsonString,
  isImageLink,
} from '../../../utils/helper';
import { addYears } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import DateInput from '../../Common/DateInput';
import TimeInput from '../../Common/DateInput/time';
import FileUploader from '../../Common/FileUploader';
import Notification from '../../../containers/Notification';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY } from '../../../constants';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import DateSelection from '../../Common/DateSelection';

const NewEvent = (props) => {
  const {
    user,
    eventFormData,
    eventFormChange,
    eventFormImageChange,
    eventFormErrors,
    removeEventImage,
    submitEventForm,
    isEventLoading,
    isEventSubmitting,
    editModalTriger,
    eventChangeByOption,
    filterDismissal,
  } = props;
  const CustomDateInput = React.forwardRef((props, ref) => (
    <DateInput innerRef={ref} {...props} />
  ));
  const CustomTimeInput = React.forwardRef((props, ref) => (
    <TimeInput innerRef={ref} {...props} />
  ));
  const { t } = useTranslation();
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [loaded, setLoaded] = useState(false);

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    width: '65%',
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitEventForm();
  };
  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (!eventFormData.description) {
      setDescription(EditorState.createEmpty());
    } else if (eventFormData.id && editModalTriger) {
      var convertedState;
      if (isJsonString(eventFormData.description))
        convertedState = convertFromRaw(JSON.parse(eventFormData.description));
      else
        convertedState = convertFromRaw({
          blocks: [
            {
              key: '6bip3',
              text: eventFormData.description,
              type: 'unstyled',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        });

      setDescription(EditorState.createWithContent(convertedState));
      eventChangeByOption('editModalTriger', false);
    }
  }, [
    setDescription,
    eventFormData.description,
    eventFormData.id,
    editModalTriger,
    eventChangeByOption,
  ]);

  useEffect(() => {
    if (loaded === false) {
      if (eventFormData.description !== '') {
        const storeComment = async () => {
          var convertedState;
          if (isJsonString(eventFormData.description))
            convertedState = convertFromRaw(
              JSON.parse(eventFormData.description),
            );
          else
            convertedState = convertFromRaw({
              blocks: [
                {
                  key: '6bip3',
                  text: eventFormData.description,
                  type: 'unstyled',
                  depth: 0,
                  inlineStyleRanges: [],
                  entityRanges: [],
                  data: {},
                },
              ],
              entityMap: {},
            });

          setDescription(EditorState.createWithContent(convertedState));
          setLoaded(true);
        };
        storeComment();
      } else {
        //setComment(EditorState.createWithContent(convertedState));
      }
    }
  });

  useEffect(() => {
    eventFormChange(
      'description',
      JSON.stringify(convertToRaw(description.getCurrentContent())),
    );
  }, [description, eventFormChange]);

  return (
    <div className="clsengagement-form clsevent-form">
      {isEventLoading && <LoadingIndicator />}
      <Notification module={'event'} />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">{t(`Event Title`)}</label>
          <input
            className="form-control"
            placeholder={t(`Event Title`)}
            type="text"
            id="title"
            name="title"
            value={eventFormData.title}
            onChange={(e) => eventFormChange(e.target.name, e.target.value)}
          />
          {typeof eventFormErrors.title !== 'undefined' &&
            eventFormErrors.title && (
              <div className="clsinvalid">{t(eventFormErrors?.['title'])}</div>
            )}
        </div>
        <div className="form-group">
          <label htmlFor="organizer">{t(`Published by`)}</label>
          <input
            className="form-control"
            disabled
            placeholder={t(`Published by`)}
            type="text"
            id="organizer"
            name="organizer"
            value={getEventPublishedBy(user)}
            onChange={(e) => eventFormChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="custom-control custom-switch clsengagement-start-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="isRecurring"
            name="isRecurring"
            checked={eventFormData.isRecurring}
            value={eventFormData.isRecurring}
            onChange={(e) =>
              eventFormChange('isRecurring', !eventFormData.isRecurring)
            }
          />
          <label className="custom-control-label" htmlFor="isRecurring">
            {t(`Recurring`)}
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="">
            {eventFormData.isRecurring ? 'Start' : ''} {t(`Date`)}
          </label>
          <DateSelection
            customInput={<CustomDateInput className={''} />}
            onChangeRaw={(e) => e.preventDefault()}
            placeholderText={t('Select Date')}
            selected={eventFormData.startDate}
            onChange={(date) => eventFormChange('startDate', date)}
            minDate={new Date()}
            maxDate={addYears(new Date(), 10)}
            timeIntervals={15}
            dateFormat="MM-dd-yyyy"
            yearDropdownItemNumber={121}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
            locale={user?.selected_language}
            timeCaption={t('Time')}
          />
          {typeof eventFormErrors.startDate !== 'undefined' &&
            eventFormErrors.startDate && (
              <div className="clsinvalid">{t(eventFormErrors?.['startDate'])}</div>
            )}
        </div>
        <div className="form-group">
          <label htmlFor="">{t('Start Time')}</label>
          <DateSelection
            customInput={<CustomTimeInput className={''} />}
            onChangeRaw={(e) => e.preventDefault()}
            placeholderText={' ' + t('Select Time')}
            selected={eventFormData.startTime}
            minTime={
              moment(eventFormData.startDate).format('YYYY-MM-DD') ===
              new Date().toISOString()?.split('T')[0]
                ? new Date()
                : moment().startOf('day').toDate()
            }
            maxTime={moment().endOf('day').toDate()}
            onChange={(date) => eventFormChange('startTime', date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t('Time')}
            dateFormat="h:mm aa"
            locale={user?.selected_language}
          />
          {typeof eventFormErrors.startTime !== 'undefined' &&
            eventFormErrors.startTime && (
              <div className="clsinvalid">{t(eventFormErrors?.['startTime'])}</div>
            )}
        </div>
        <div className="form-group">
          <label htmlFor="">{t(`End Time`)}</label>
          <DateSelection
            customInput={<CustomTimeInput className={''} />}
            onChangeRaw={(e) => e.preventDefault()}
            placeholderText={' ' + t('Select Time')}
            selected={eventFormData.endTime}
            minTime={new Date(eventFormData.startTime)}
            maxTime={moment().endOf('day').toDate()}
            onChange={(date) => eventFormChange('endTime', date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t('Time')}
            dateFormat="h:mm aa"
            locale={user?.selected_language}
          />
          {typeof eventFormErrors.startTime !== 'undefined' &&
            eventFormErrors.startTime && (
              <div className="clsinvalid">{t(eventFormErrors?.['startTime'])}</div>
            )}
        </div>
        {eventFormData.isRecurring ? (
          <>
            <div className="form-group">
              <label htmlFor="">{t(`Recurring`)}</label>
              <SelectOption
                options={recurringOptions}
                name="recurringType"
                value={handleOptionValue(
                  recurringOptions,
                  eventFormData.recurringType,
                )}
                handleSelectChange={(e) =>
                  eventFormChange('recurringType', e.value)
                }
              />
              {typeof eventFormErrors.recurringType !== 'undefined' &&
                eventFormErrors.recurringType && (
                  <div className="clsinvalid">{t(eventFormErrors?.['recurringType'])}</div>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="">{t(`End Date`)}</label>
              <DateSelection
                customInput={<CustomDateInput className={''} />}
                onChangeRaw={(e) => e.preventDefault()}
                placeholderText={t('Select Date')}
                selected={eventFormData.endDate}
                onChange={(date) => eventFormChange('endDate', date)}
                minDate={eventFormData.startDate}
                maxDate={addYears(new Date(), 10)}
                timeIntervals={15}
                dateFormat="MM-dd-yyyy"
                yearDropdownItemNumber={121}
                scrollableYearDropdown={true}
                showYearDropdown
                showMonthDropdown
                locale={user?.selected_language}
                timeCaption={t('Time')}
              />
              {typeof eventFormErrors.endDate !== 'undefined' &&
                eventFormErrors.endDate && (
                  <div className="clsinvalid">{t(eventFormErrors?.['endDate'])}</div>
                )}
            </div>
          </>
        ) : (
          ''
        )}
        <div className="form-group">
          <label htmlFor="event_location">{t(`Location`)}</label>
          <Autocomplete
            apiKey={GOOGLE_PLACES_API_KEY}
            className="form-control"
            placeholder={t('Enter Location')}
            type="text"
            id="event_location"
            name="event_location"
            value={eventFormData.location}
            onKeyDown={preventSubmit}
            autoComplete="off"
            onChange={(e) => eventFormChange('location', e.target.value)}
            onPlaceSelected={(place) => {
              eventFormChange('coordinates', [
                place.geometry.location.lat(),
                place.geometry.location.lng(),
              ]);
              eventFormChange('location', place.formatted_address);
            }}
            options={{
              types: ['address'],
              componentRestrictions: { country: 'us' },
              fields: ['address_components', 'geometry', 'formatted_address'],
            }}
          />
          {typeof eventFormErrors.location !== 'undefined' &&
            eventFormErrors.location && (
              <div className="clsinvalid">{t(eventFormErrors?.['location'])}</div>
            )}
        </div>
        <div className="form-group">
          <label htmlFor="description">{t(`Description`)}</label>
          <Editor
            editorState={description}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            wrapperStyle={{
              border: '2px solid #eee',
              borderRadius: '10px',
              marginBottom: '20px',
            }}
            editorStyle={{ padding: '10px' }}
            placeholder={t('Enter')}
            onEditorStateChange={(editorState) => {
              setDescription(editorState);
            }}
            toolbar={{
              options: ['inline', 'emoji', 'history', 'link', 'list'],
              inline: { options: ['bold', 'italic', 'underline'] },
              list: {
                options: ['unordered', 'ordered'],
              },
              link: {
                defaultTargetOption: '_blank',
              },
            }}
          />
          {typeof eventFormErrors.description !== 'undefined' &&
            eventFormErrors.description && (
              <div className="clsinvalid">{t(eventFormErrors?.['description'])}</div>
            )}
        </div>
        <FileUploader
          multiple={false}
          fileList={eventFormData.files}
          maxFiles="1"
          acceptFormat={'.jpeg, .png, .jpg, .svg, .gif'}
          fileChange={eventFormImageChange}
          removeLink={''}
          label={t('Upload Header Image')}
          clearFiles={filterDismissal}
        />
        {eventFormData.images && eventFormData.images.length > 0 ? (
          <div className="form-group">
            <aside style={thumbsContainer}>
              <div className="image-preview mt-2">
                <img
                  src={isImageLink(eventFormData.images[0])}
                  alt={eventFormData.title}
                />
                <div
                  className="btn image-preview-close"
                  onClick={removeEventImage}
                >
                  <img
                    src="/static/media/delete.02cc25b1.svg"
                    alt={t('Remove File')}
                  />
                </div>
              </div>
            </aside>
          </div>
        ) : (
          ''
        )}
        <div className="text-center mt-4">
          <button
            type="submit"
            className="btn clsyellow-btn  clsanchor-btn"
            disabled={isEventSubmitting}
          >
            {eventFormData.id ? t('Update') : t('Add')} {t(`Event`)}
          </button>
        </div>
      </form>
    </div>
  );
};
export default NewEvent;
