/*
 *
 * CivicFeed
 *
 */

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import actions from '../../actions';
import CivicFeed from '../../components/Manager/CivicFeed';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import Filter from '../../components/Manager/CivicFeed/filter';
import SelectedFilter from '../../components/Manager/CivicFeed/selectedFilter';
import Topic from '../../components/Manager/Engagement/topic';
import SearchResult from '../../components/Manager/CivicFeed/searchResult';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY } from '../../constants';
import StatusMessage from "../../components/Common/StatusMessage";
import {useInView} from "react-intersection-observer";
import VerificationMessage from "../Dashboard/verificationMessage";
import { useTranslation } from 'react-i18next';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const EngagementList = (props) => {
  const {
    getCivicEngagements,
    engagements,
    noRecords,
    reloadList,
    resetResult,
    searchSubmission,
    reloadCivicEngagement,
    searchResultReset,
    civicEngagementChangeByOption,
    isEngagementLoading,
    user,
    setViewedStatus,
    location,
    resetFilter,
    isSilentUser,
    getCivicEngagementsForGuest,
    guestProfileAddressUpdate,
    getInactiveStatusMesages,
    account,
    editProfileChange,
    authenticated,
    setIsSignUpButtonClicked,
    setRegistrationModalOpen,
    isIdentityVerified
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [engagementListComponent, setEngagementListComponent] = useState('');
  const [progressBarStatus, setProgressBarStatus] = useState(false);
  const [guestAddress, setGuestAddress] = useState({});
  const [showGuestAddressPicker, setShowGuestAddressPicker] = useState(true);

  const [page, setPage] = useState(1);

  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const prevUri = usePrevious(uri);
  const search = useLocation().search;
  const topic = new URLSearchParams(search).get('topic');
  const prevQueryParam = usePrevious(topic);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (uri !== prevUri && prevUri !== 'undefined') {
      resetFilter();
    }
  }, [uri, prevUri, resetFilter]);

  useEffect(() => {
    if (topic !== prevQueryParam && prevQueryParam !== 'undefined') {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [topic, prevQueryParam, resetFilter]);

  const renderComponent = useCallback(
    (Component) => {
      return new Promise((resolve, reject) => {
        setEngagementListComponent(
          <Component
            {...props}
            civicEngagementChangeByOption={civicEngagementChangeByOption}
            setViewedStatus={setViewedStatus}
          />,
        );
        return resolve(true);
      });
    },
    [
      setEngagementListComponent,
      props,
      civicEngagementChangeByOption,
      setViewedStatus,
    ],
  );

  const fetchEngagements = useCallback(async () => {
    if (!isSilentUser) {
      if (page !== 2) {
        setInProcess(true);
        await getCivicEngagements(page, 2).then(async () => {
          await renderComponent(CivicFeed).then(() => {
            setPage(page + 1);
          });
        });
        setInProcess(false);
      }
    }
  }, [getCivicEngagements, renderComponent, setPage, page, isSilentUser]);

  const loadSecondPage = useCallback(async () => {
    if (!isSilentUser) {
      if (page === 2) {
        setInProcess(true);
        await getCivicEngagements(page, 2).then(async () => {
          await renderComponent(CivicFeed).then(() => {
            setPage(page + 1);
          });
        });
        setInProcess(false);
      }

    }
  }, [getCivicEngagements, renderComponent, setPage, page, isSilentUser]);

  useEffect(() => {
    if (loading === true) {
      setEngagementListComponent('');
      if (inProcess === false) {
        setInProcess(true);

        if (isSilentUser) {
          const fetchEngagements = async () => {
            await getCivicEngagementsForGuest().then(() => {
              setLoading(false);
              setInProcess(false);
            });
          };
          fetchEngagements();
        } else {
          const fetchEngagements = async () => {
            await getCivicEngagements().then(() => {
              setLoading(false);
              setInProcess(false);
            });
          };
          fetchEngagements();
        }
      }
    }
  }, [
    loading,
    setLoading,
    getCivicEngagements,
    renderComponent,
    page,
    setPage,
    inProcess,
    setInProcess,
    initialLoad,
    engagements,
    getCivicEngagementsForGuest,
    isSilentUser
  ]);

  useEffect(() => {
    if (!authenticated) {
      if (inView && engagements.length > 0) {
        const registrationModalButton = document.getElementById('registration-modal-button');

        if (registrationModalButton) {
          setIsSignUpButtonClicked(false);
          setRegistrationModalOpen(true);
        }
      }
    }
  }, [inView, engagements]);

  useEffect(() => {
    if (loading === false && initialLoad === true) {
      renderComponent(CivicFeed);
      setPage(page + 1);
      setInitialLoad(false);
    }
  }, [loading, initialLoad, setInitialLoad, renderComponent, page, setPage]);

  useEffect(() => {
    if (reloadList === true && inProcess === false) {
      setPage(1);
      setLoading(true);
      setInitialLoad(true);
    }
  }, [reloadList, inProcess, setPage, setLoading, setInitialLoad]);

  const searchFormUpdate = useCallback(
    async (type = '') => {
      await reloadCivicEngagement();
      new Promise((resolve, reject) => {
        searchResultReset(type);
        if (type === 'submit') {
          setSearchSubmitted(true);
        }
        resolve(true);
      });
    },
    [searchResultReset, reloadCivicEngagement],
  );

  useEffect(() => {
    if (searchSubmitted === true && resetResult === true) {
      searchFormUpdate('reset');
    } else if (resetResult === false && searchSubmission === true) {
      searchFormUpdate('submit');
    }
  }, [searchSubmission, resetResult, searchFormUpdate, searchSubmitted]);

  useEffect(() => {
    renderComponent(CivicFeed);
  }, [user, renderComponent, page]);

  /** Allowed for individual user civicfeed page only **/

  useEffect(() => {
    if (progressBarStatus === false) {
      if (
        uri === 'dashboard/civicfeed' &&
        user.roleName !== 'PUBLICOFFICIAL' &&
        user.roleName !== 'ADMIN'
      ) {
        if (user.isIDmeVerified === false) {
          setProgressBarStatus(true);
        } else if (
          user.highest_edu === null &&
          user.gender === null &&
          user.employment_status === null &&
          user.race === null &&
          user.ethnicity === null &&
          user.marital_status === null &&
          user.political_affiliation === null &&
          user.religion === null
        ) {
          setProgressBarStatus(true);
        } else {
          setProgressBarStatus(false);
        }
      }
    }
  }, [user.roleName, uri, progressBarStatus, user]);

  useEffect(() => {
    if (page === 2 && inProcess === false) {
      loadSecondPage();
    }
  }, [page, inProcess, loadSecondPage]);

  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const formatAddress = (address) => {
    let addr = {};

    editProfileChange('zip_code', '');

    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          addr.address = component.long_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'route': {
          addr.address = addr.hasOwnProperty('address')
              ? addr.address + ' ' + component.short_name
              : component.short_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'postal_code': {
          addr.zip_code = component.long_name;
          editProfileChange('zip_code', addr.zip_code)
          break;
        }
        case 'locality':
          addr.city = component.long_name;
          editProfileChange('city', addr.city)
          break;
        case 'administrative_area_level_1': {
          addr.state = component.short_name;
          editProfileChange('state', addr.state)
          break;
        }
        default: {
          break;
        }
      }
    }
    // return addr;
  }

  useEffect(() => {
    const storedAddress = JSON.parse(localStorage.getItem('guest_address'));

    if (storedAddress !== null) {
      formatAddress(storedAddress);

      setGuestAddress(storedAddress);
      setShowGuestAddressPicker(false);
    }
  }, []);

  const storeAddress = (address) => {
    const storedAddress = JSON.stringify(address);

    if (typeof address.name === 'undefined' && address) {
      localStorage.setItem('guest_address', storedAddress);

      formatAddress(address);
      guestProfileAddressUpdate(address);
      // getInactiveStatusMesages();

      setGuestAddress(address);
      setShowGuestAddressPicker(false);
    }
  };

  useEffect(() => {
    reloadCivicEngagement();
  }, [user.address, reloadCivicEngagement])

  const handlePickGuestAddress = () => {
    setShowGuestAddressPicker(true);
  };

  useEffect(() => {
    if (authenticated && isIdentityVerified) {
      getCivicEngagements();
    }
  }, [authenticated, isIdentityVerified, user]);

  return (
    <>
      {/*{progressBarStatus === true ? <ProgressBar user={user} /> : ''}*/}
      <SearchResult {...props} />
      {isEngagementLoading === true ? (
        <LoadingIndicator position={`fixed`} />
      ) : (
        ''
      )}
      <VerificationMessage />
          <>
            {
              typeof topic === 'undefined' || topic === null ? (
                  <>
                    <Filter {...props} />
                    <SelectedFilter {...props} />
                  </>
              ) : (
                  <Topic {...props} />
              )
            }
            {!authenticated ?
              showGuestAddressPicker ?
                  <div style={{
                    marginBottom: '16px',
                    padding: '15px',
                    backgroundColor: '#ffffff',
                    borderRadius: '13px',
                  }}>
                <span style={{
                  fontWeight: 'bold'
                }}>{ t('Enter your residential address')}</span>{' '}{ t(' to see posts from your elected officials and agencies')}
                    <div style={{
                      display: 'flex',
                      gap: '20px',
                      marginTop: '10px'
                    }}>

                      <Autocomplete
                          apiKey={GOOGLE_PLACES_API_KEY}
                          className="form-control-plaintext"
                          placeholder={t('Residential Address (incl. street number, no PO Box)')}
                          type="text"
                          id="location_search"
                          name="location_search"
                          defaultValue={account.editProfileData.address ?
                              account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                              account.editProfileData.state + ', ' + account.editProfileData.zip_code
                              : ''}
                          onKeyDown={preventSubmit}
                          autoComplete="off"
                          onPlaceSelected={(place) => {
                            storeAddress(place);
                          }}
                          options={{
                            types: ['address'],
                            componentRestrictions: {country: 'us'},
                            fields: ['address_components', 'formatted_address'],
                          }}
                          style={{
                            border: '1px solid #ced4da',
                            borderRadius: '10px',
                            height: '35px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '14px',
                            lineHeight: '1.5',
                            fontWeight: 400,
                          }}
                      />
                    </div>
                  </div> : <div style={{
                    marginBottom: '16px',
                    padding: '15px',
                    backgroundColor: '#ffffff',
                    borderRadius: '13px',
                    display: 'flex',
                    gap: '10px'
                  }}>
                    {/*{Object.keys(guestAddress).length === 0 ? ('No address provided.') : (*/}
                    {account.editProfileData.address === '' ? <>{ t('No address provided.')}</> : (
                        <div>
                          <span
                              style={{fontWeight: 'bold'}}>{ t('You\'re seeing posts for ')}</span> {account.editProfileData.address ?
                            account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                            account.editProfileData.state + ', ' + account.editProfileData.zip_code
                            : ''}
                        </div>
                    )}
                    <span
                        onClick={handlePickGuestAddress}
                        style={{
                          color: '#ffaa33',
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          float: 'right'
                        }}>{ t('change')}</span>
                  </div>
              : ''
            }
            {Object.keys(guestAddress).length > 0 && account.inactiveMessages &&
            <StatusMessage
                statusMessages={account.inactiveMessages}
                showFinalizationButton={isSilentUser}
                openModal={setRegistrationModalOpen}
            />}
          </>
      {engagementListComponent}
      {page === 2 && !noRecords ? (
        <div style={{ minHeight: '200px' }}></div>
      ) : (
        ''
      )}
      {loading === true || initialLoad === true ? (
        <LoadingIndicator position={`relative`} />
      ) : (
        <>
          <InfiniteScroll
            dataLength={engagements.length}
            next={fetchEngagements}
            hasMore={!noRecords}
            loader={
              inProcess === true ? (
                <LoadingIndicator position={`relative`} />
              ) : (
                ''
              )
            }
          />
          <div ref={ref} style={{ minHeight: '200px' }}>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    engagements: state.civicFeed.engagements,
    noRecords: state.civicFeed.noRecords,
    reloadList: state.civicFeed.reloadList,
    pageLoader: state.civicFeed.pageLoader,
    sort: state.civicFeed.sort,
    resetResult: state.search.resetResult,
    searchSubmission: state.search.searchSubmission,
    filterDismissal: state.civicFeed.filterDismissal,
    filter: state.civicFeed.filter,
    filterSelected: state.civicFeed.filterSelected,
    searchBox: state.civicFeed.searchBox,
    suggestionBox: state.civicFeed.suggestionBox,
    isEngagementLoading: state.civicFeed.isEngagementLoading,
    districtSearchResult: state.searchResult.districtSearchResult,
    anywhereSearchResult: state.searchResult.anywhereSearchResult,
    showResult: state.searchResult.showResult,
    searchText: state.searchResult.searchText,
    isSilentUser: state.authentication.isSilentUser,
    account: state.account,
    registration: state.registration,
    authenticated: state.authentication.authenticated,
    isIdentityVerified: state.account.user.isIdentityVerified
  };
};

export default connect(mapStateToProps, actions)(EngagementList);
