/**
 *
 * Engagement Button
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { PlusIcon } from '../../Common/CivicIcons';
import { useTranslation } from 'react-i18next';

const EngagementButton = (props) => {
  const {
    blocks,
    user,
    engagementChange,
    resetEngagementForm,
    addNotification,
  } = props;
  const { t } = useTranslation();
  const canShow =
    typeof blocks[user.roleName] !== 'undefined'
      ? blocks[user.roleName].filter(
          (block) => block.name === 'New Engagement Form',
        )
      : [];

  const hashUrl = '#';

  const resetForm = (e) => {
    if (user.manualverification === true) {
      if (typeof user.isManuallyVerified === 'undefined') {
        addNotification({
          type: 'error',
          message: t('Please wait until you are manually verified'),
          module: 'dashboard',
          displayFor: 5000,
          parentDivClass: 'top-notification',
        });
        e.stopPropagation();
      } else if (user.isManuallyVerified === false) {
        addNotification({
          type: 'error',
          message: t('Please wait until you are manually verified'),
          module: 'dashboard',
          displayFor: 5000,
          parentDivClass: 'top-notification',
        });
        e.stopPropagation();
      }
    }
    /*if(typeof engagementFormData !== 'undefined') {
      if(typeof engagementFormData.id !== 'undefined') {
        resetEngagementForm();
      }
    }*/
    resetEngagementForm();
    if (user.showMyNameAs === 'organization')
      engagementChange('postUnderOrganization', true);
  };

  const lng = user.selected_language;

  return (
    <>
      {typeof blocks !== 'undefined' &&
      Object.keys(blocks).length !== 0 &&
      typeof user.roleName !== 'undefined' &&
      typeof canShow !== 'undefined' ? (
        <>
          {canShow.length > 0 ? (
            <div className="text-center clsstart-engagement-button mb-3">
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target="#new-engagement"
                className={
                  lng === 'EN'
                    ? 'btn btn-block clsanchor-btn clsyellow-btn'
                    : 'btn btn-block clsyellow-btn'
                }
                onClick={(event) => {
                  resetForm(event);
                }}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <span className="mr-2">{PlusIcon()}</span>{' '}
                {t(`Start New Engagement`)}
              </a>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    engagementFormData: state.engagement.engagementFormData,
  };
};

EngagementButton.defaultProps = {
  user: [],
  blocks: [],
  canShow: [],
};

export default connect(mapStateToProps, actions)(EngagementButton);
