import i18next from 'i18next';

/*
 *
 * Complete engagement constants
 *
 */

export const COMPLETE_ENGAGEMENT_CHANGE =
  'src/CompleteEngagement/COMPLETE_ENGAGEMENT_CHANGE';
export const COMPLETE_ENGAGEMENT_RESET =
  'src/CompleteEngagement/COMPLETE_ENGAGEMENT_RESET';
export const SET_COMPLETE_ENGAGEMENT_LOADING =
  'src/CompleteEngagement/SET_COMPLETE_ENGAGEMENT_LOADING';
export const SET_COMPLETE_ENGAGEMENT_FORM_ERRORS =
  'src/CompleteEngagement/SET_COMPLETE_ENGAGEMENT_FORM_ERRORS';
export const SET_COMPLETE_ENGAGEMENT_SUBMITTING =
  'src/CompleteEngagement/SET_COMPLETE_ENGAGEMENT_SUBMITTING';
export const SET_COMPLETE_ENGAGEMENT_DATA =
  'src/CompleteEngagement/SET_COMPLETE_ENGAGEMENT_DATA';
export const RESET_APP = 'src/CompleteEngagement/RESET_APP';

export const postSelections = [
  { value: 'selected', id: 'Selected', label: 'Selected Results', translationParsing: i18next.t('Selected Results') },
  { value: 'all', id: 'All', label: 'All Results', translationParsing: i18next.t('All Results') },
  { value: 'none', id: 'NoPost', label: "Don\'t Post Results", translationParsing: i18next.t('Don\'t Post Results') },
];
