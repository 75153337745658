/*
 *
 * Survey question reducer
 *
 */

import {
  SURVEY_CHANGE,
  SURVEY_LOADING,
  SET_SURVEY_RELOAD,
  SET_SURVEY_DATA,
  RESET_APP,
} from './constants';

const initialState = {
  surveyData: [],
  surveyLoading: false,
  reload: false,
};

const defaultState = JSON.stringify(initialState);

const surveyQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_SURVEY_DATA:
      return {
        ...state,
        surveyData: { ...state.surveyData, ...action.payload },
      };
    case SURVEY_CHANGE:
      return {
        ...state,
        surveyData: { ...state.surveyData, ...action.payload },
      };
    case SURVEY_LOADING:
      return {
        ...state,
        surveyLoading: action.payload,
      };
    case SET_SURVEY_RELOAD:
      return {
        ...state,
        reload: action.payload,
      };
    default:
      return state;
  }
};

export default surveyQuestionReducer;
