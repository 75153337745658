/**
 *
 * Reset Password
 *
 */

import React from 'react';
import Notification from '../../../containers/Notification';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const ResetPassword = (props) => {
  const {
    passwordData,
    passwordFormError,
    passwordFormChange,
    passwordFormSubmit,
    isProfileLoading,
  } = props;
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    passwordFormSubmit();
  };

  return (
    <div className="clssettings-info-blk">
      {isProfileLoading === true ? <LoadingIndicator position={`fixed`} /> : ''}
      <div className="text-center pt-3">
        <Notification module={`dashboard`} />
      </div>
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>{t(`Old Password`)}</label>
          <input
            type="password"
            className="form-control"
            placeholder={t(`Old Password`)}
            name={`oldPassword`}
            value={passwordData.oldPassword}
            onChange={(e) => passwordFormChange(e.target.name, e.target.value)}
          />
          {typeof passwordFormError !== 'undefined' &&
            passwordFormError.oldPassword && (
              <div className="clsinvalid">
                {t(passwordFormError?.['oldPassword'])}
              </div>
            )}
        </div>
        <div className="form-group">
          <label>{t(`New Password`)}</label>
          <input
            type="password"
            className="form-control"
            placeholder={t(`New Password`)}
            name={`newPassword`}
            value={passwordData.newPassword}
            onChange={(e) => passwordFormChange(e.target.name, e.target.value)}
          />
          {typeof passwordFormError !== 'undefined' &&
            passwordFormError.newPassword && (
              <div className="clsinvalid">
                {t(passwordFormError?.['newPassword'])}
              </div>
            )}
        </div>
        <div className="form-group">
          <label>{t(`Repeat New Password`)}</label>
          <input
            type="password"
            className="form-control"
            placeholder={t(`Repeat New Password`)}
            name={`confirmPassword`}
            value={passwordData.confirmPassword}
            onChange={(e) => passwordFormChange(e.target.name, e.target.value)}
          />
          {typeof passwordFormError !== 'undefined' &&
            passwordFormError.confirmPassword && (
              <div className="clsinvalid">
                {t(passwordFormError?.['confirmPassword'])}
              </div>
            )}
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="btn  clsyellow-btn">
            {t(`Change Password`)}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
