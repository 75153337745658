/**
 *
 * Dashboard
 *
 */

import React, { useEffect, useState } from 'react';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import AccountPanel from './AccountPanel';
import Notification from '../../../containers/Notification';
import { isMobile} from "react-device-detect";

const GuestDashboard = (props) => {
  const { pages, user, location } = props;
  const [leftSideBar, setLeftSideBar] = useState(false);
  const [rightSideBar, setRightSideBar] = useState(false);
  const uri = location.pathname.replace(/^\/|\/$/g, '');

  useEffect(() => {
    pages['GUEST'].forEach((link, index) => {
      if ('/' + uri === link.to) {
        setLeftSideBar(link.leftPanel);
        setRightSideBar(link.rightPanel);
      }
    });
  }, [user.roleName, pages, uri, setLeftSideBar, setRightSideBar]);

  return (
    <div className="clsdashboard-main-container">
      <div className="container">
        {leftSideBar === true ? <LeftSidebar {...props} /> : ''}
        <div className="clsdashboard-right-content-blk flex-reverse">
          <div
            className={
              rightSideBar === false
                ? `clsdashboard-right-common-blk`
                : `clsdashboard-content-blk`
            }
          >
            <Notification module={'dashboard'} />
            <AccountPanel {...props} />
          </div>
          {rightSideBar === true ? <RightSidebar {...props} mainClassname={isMobile ? 'clsdashboard-event-blk' : 'clsdashboard-event-blk sticky-custom'}/> : ''}
        </div>
      </div>
    </div>
  );
};

export default GuestDashboard;
