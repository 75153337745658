/**
 *
 * Question Analytics View
 *
 */

import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import SelectOption from '../../components/Common/SelectOption';
import { handleOptionValue, capitalizeFirstLetter } from '../../utils/helper';
import { questionFilterOptions } from './constants';
import QuestionChart from '../../components/Manager/Analaytics/questionChart';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import store from '../../store';
import NoData from '../../components/Manager/Analaytics/noData';
import { useTranslation } from 'react-i18next';

const QuestionAnalytics = (props) => {
  const {
    analyticsData,
    questionTabData,
    changeQuestionData,
    questionData,
    setQuestionData,
    activeQuestionId,
    activeId,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);
  const { getState } = store;
  const { t } = useTranslation();

  const loadChartData = useCallback(
    (activeQuestionId) => {
      let enableBlue = false;
      let dataFormat = [];
      let result = getState().analytics.questionData; //let txt1 = '', txt2 = '';

      if (typeof result[activeQuestionId]['result'] === 'undefined') {
        //
      } else if (result[activeQuestionId]['result'].length === 0) {
        //
      } else {
        result[activeQuestionId]['result'].forEach((item, index) => {
          let tooltipTxt1 =
            `<div style="padding:10px"><p style="text-align: left"><b>${t('Entire District')}: </b></p>` +
            item[5] +
            ` ${t('residents')} (` +
            item[1] +
            '%)</div>';

          let tooltipTxt2 =
            `<div style="padding:10px"><p font-size: 13px;"><b>${t('Total')}: </b>` +
            item[2] +
            '% (' +
            item[6] +
            ` ${t('residents')})</p>`;
          if (result[activeQuestionId]['confirmed'] === true) {
            tooltipTxt2 +=
              `<p><b>${t('Self-reported')}: </b>` +
              item[3] +
              '% (' +
              item[7] +
              `  ${t('residents')})</p>`;
          } else {
            tooltipTxt2 += `<p><b>${t('Self-reported')}: </b> n/a</p>`;
          }

          if (result[activeQuestionId]['estimated'] === true) {
            tooltipTxt2 +=
              `<p><b>${t('Estimated')}: </b>`+
              item[4] +
              '% (' +
              item[8] +
              ` ${t('residents')})</p>`;
          } else {
            tooltipTxt2 += `<p><b>${t('Estimated')}: </b> n/a</p>`;
          }
          tooltipTxt2 += '</div>';
          if (result[activeQuestionId]['resultOnly'] === true) {
            enableBlue = true;
            if (index === 0) {
              dataFormat[index] = [
                capitalizeFirstLetter(item[0]),
                item[2],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                capitalizeFirstLetter(item[0]),
                item[2],
                tooltipTxt2,
              ];
            }
          } else {
            if (index === 0) {
              dataFormat[index] = [
                capitalizeFirstLetter(item[0]),
                item[1],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
                item[2],
                {
                  label: '',
                  role: 'tooltip',
                  type: 'string',
                  p: { html: true },
                },
              ];
            } else {
              dataFormat[index] = [
                capitalizeFirstLetter(item[0]),
                item[1],
                tooltipTxt1,
                item[2],
                tooltipTxt2,
              ];
            }
          }
          /*txt1 = ''; txt2 ='';
          if(index === 0) {
            dataFormat[index] = [item[0], item[3], {label: '', role: 'tooltip'}, item[4], {label: '', role: 'tooltip'}]
          } else {

            let formatTxt1 = capitalizeFirstLetter(item[0]);
            let formatTxt2 = getOptionKeyFromValue(questionFilterOptions, getState().analytics.questionTabData['question-'+activeQuestionId]);
            let formatTxt3 = '\n' + parseFloat(item[3])+'%';
            txt1 = formatTxt1 + '- '+formatTxt2+''+formatTxt3;

            let txtFormat1 = capitalizeFirstLetter(item[0]);
            let txtFormat2 = getOptionKeyFromValue(questionFilterOptions, getState().analytics.questionTabData['question-'+activeQuestionId]);
            let txtFormat3 = '\n' + parseFloat(item[4])+'%';
            txt2 = txtFormat1 + ' - ' + txtFormat2 + txtFormat3;

            dataFormat[index] = [item[0], parseFloat(item[3]), txt1, parseFloat(item[4]), txt2]
          }*/
        });
        let data = { [activeQuestionId]: dataFormat };
        setChartData(data);

        let lineChartOptions = {
          title: '',
          curveType: 'function',
          legend: { position: 'bottom' },
          backgroundColor: {
            stroke: '#f5f4f9',
            fill: '#f5f4f9',
            rx: 10,
            ry: 10,
          },
          series: {
            0: { lineWidth: 2, pointSize: 10 },
            1: { lineWidth: 2, pointSize: 10 },
          },
          vAxes: {
            0: { minValue: 0, maxValue: 100, format: "#'%'" },
            1: { minValue: 0, maxValue: 100, format: "#'%'" },
          },
          tooltip: { isHtml: true },
          focusTarget: 'datum',
          colors:
            enableBlue === true
              ? ['#089EEA', '#FFAA33', '#87D9FF', '#FFD121']
              : ['#FFAA33', '#089EEA', '#87D9FF', '#FFD121'],
        };
        setChartOptions(lineChartOptions);
        setLoaded(true);
        setLoader(false);
      }
    },
    [setChartOptions, getState],
  );

  const loadQuestionData = useCallback(
    async (questionId) => {
      setLoader(true);
      await setQuestionData(analyticsData.id, questionId).then(() => {
        loadChartData(questionId);
      });
    },
    [loadChartData, setQuestionData, analyticsData],
  );

  useEffect(() => {
    if (activeId !== activeQuestionId) {
      setLoaded(false);
    }
  }, [activeId, activeQuestionId, setLoaded]);

  useEffect(() => {
    if (
      loaded === false &&
      typeof questionData[activeQuestionId] === 'undefined' &&
      activeQuestionId !== '' &&
      activeId === activeQuestionId
    ) {
      loadQuestionData(activeQuestionId);
      setLoaded(true);
    } else if (
      typeof questionData[activeQuestionId] !== 'undefined' &&
      activeQuestionId !== '' &&
      activeId === activeQuestionId
    ) {
      loadChartData(activeQuestionId);
    }
  }, [
    loaded,
    questionData,
    activeQuestionId,
    loadChartData,
    setLoaded,
    loadQuestionData,
    activeId,
  ]);

  //})
  const onChangeHandler = () => {
    loadQuestionData(activeQuestionId);
  };

  return (
    <>
      {loader === true ? <LoadingIndicator position={`fixed`} /> : ''}
      {loaded === true ? (
        typeof chartData[activeQuestionId] !== 'undefined' ? (
          <div
            className={`chart-data mt-2`}
            style={{
              width: '100%',
              backgroundColor: '#F5F4F9',
              borderRadius: '10px',
            }}
          >
            <div className="form-group question-view-by">
              <label>{ t('View') }</label>
              <SelectOption
                name={`question-${activeQuestionId}`}
                placeholder={t('View')}
                options={questionFilterOptions}
                value={handleOptionValue(
                  questionFilterOptions,
                  questionTabData['question-' + activeQuestionId],
                )}
                handleSelectChange={(value) => {
                  changeQuestionData(
                    `question-${activeQuestionId}`,
                    value.value,
                  );
                  onChangeHandler();
                }}
                disabled={
                  typeof chartData[activeQuestionId] !== 'undefined'
                    ? chartData[activeQuestionId].length > 0
                      ? false
                      : true
                    : true
                }
              />
            </div>
            <div id={`dual-line-chart`} style={{ minHeight: '420px' }}>
              {typeof chartData[activeQuestionId] !== 'undefined' ? (
                chartData[activeQuestionId].length > 0 ? (
                  chartData[activeQuestionId].length > 1 ? (
                    <QuestionChart
                      chartType="LineChart"
                      data={chartData[activeQuestionId]}
                      options={chartOptions}
                      width={`100%`}
                      height="400px"
                    />
                  ) : (
                    <NoData />
                  )
                ) : (
                  <LoadingIndicator position={`relative`} />
                )
              ) : (
                <LoadingIndicator position={`relative`} />
              )}
            </div>
          </div>
        ) : loader === false ? (
          <div
            className={`chart-data mt-2`}
            style={{
              width: '100%',
              textAlign: 'center',
              backgroundColor: '#F5F4F9',
              borderRadius: '10px',
            }}
          >
            <p style={{ textAlign: 'center' }}>{ t('No records available') }</p>
          </div>
        ) : (
          ''
        )
      ) : (
        <LoadingIndicator position={`relative`} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    analyticsData: state.analytics.analyticsData,
    questionTabData: state.analytics.questionTabData,
    changeQuestionData: state.analytics.changeQuestionData,
    questionData: state.analytics.questionData,
    setQuestionData: state.analytics.setQuestionData,
    activeQuestionId: state.analytics.activeQuestionId,
  };
};

export default connect(mapStateToProps, actions)(QuestionAnalytics);
