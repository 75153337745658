/**
 *
 * Engagement Form Second Step
 *
 */

import React, { useCallback } from 'react';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SelectOption from '../../Common/SelectOption';
import { handleOptionValue } from '../../../utils/helper';
import { questionTypeOptions } from '../../../containers/Engagement/constants';
import FileUploader from '../../Common/FileUploader';
import ScheduleDate from './scheduleDate';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BASE_API_URL } from '../../../constants';
import LexicalEditor from '../../Common/LexicalEditor'

const striptags = require('striptags');
const urlRegex = require('url-regex');

const TYPING_TIMEOUT = 1000;

const EngagementSecondStep = (props) => {
  const {
    engagementFormData,
    engagementErrors,
    engagementChange,
    commentEditChange,
    addQuestion,
    removeQuestion,
    addChoice,
    removeChoice,
    questionChange,
    choiceChange,
    removeLink,
    linkMetaChange,
    commentLexicalChange
  } = props;
  const { t } = useTranslation();

  const [showHideBtn, setShowHideBtn] = React.useState(false);
  const [linkPreviewObj, setLinkPreviewObj] = React.useState(null);
  const [currentLink, setCurrentLink] = React.useState('');
  const [hidePreview, setHidePreview] = React.useState(false);

  const hashtag = '#';


  const fetchLinkPreview = useCallback(async (link) => {
    try {
      let res = await axios.get(`${BASE_API_URL}/fetch-meta?link=${link}`);
      linkMetaChange(res.data?.success === 'true' ? res.data.data : null);
      setLinkPreviewObj(res.data?.success === 'true' ? res.data.data : null);
      setShowHideBtn(res.data?.success === 'true');
      setHidePreview(res.data?.success !== 'true');
    } catch (error) {
      console.log(error);
    }
  }, [linkMetaChange]);

  const detectLink = useCallback(async (content) => {
    const linkFound = content.match(urlRegex());
    // A link was found.
    if (linkFound != null && linkFound.length > 0) {
      // Current link is different from found link.
      // Strip any html tags (url parsing bug).
      // Remove period at the end if any.
      let lastLink = striptags(linkFound[linkFound.length - 1]);
      lastLink =
        lastLink.endsWith('.') === true ? lastLink.slice(0, -1) : lastLink;

      if (currentLink !== lastLink) {
        setCurrentLink(lastLink);
        setTimeout(() => fetchLinkPreview(lastLink), TYPING_TIMEOUT);
      }
    }
  }, [currentLink, fetchLinkPreview]);

  const displayLink = async (content, linkMeta) => {
    const linkFound = content.match(urlRegex());
    // A link was found.
    if (linkFound != null && linkFound.length > 0) {
      // Current link is different from found link.
      // Strip any html tags (url parsing bug).
      // Remove period at the end if any.
      let lastLink = striptags(linkFound[linkFound.length - 1]);
      lastLink =
        lastLink.endsWith('.') === true ? lastLink.slice(0, -1) : lastLink;
      setCurrentLink(lastLink);
      if (linkMeta) {
        setLinkPreviewObj(linkMeta);
        setShowHideBtn(true);
        setHidePreview(false);
      } else {
        setShowHideBtn(false);
        setHidePreview(true);
      }
    }
  };

  const fetcher = async (link) => {
    if (linkPreviewObj !== null) {
      return linkPreviewObj;
    } else {
      return {};
    }
  };

  const onHidePreview = async (link) => {
    setHidePreview(true);
    setShowHideBtn(false);
    setLinkPreviewObj(null);
    linkMetaChange(null);
  };

  const onLoad = () => {
    const html = convertFromJSONToHTML(engagementFormData.comment).__html;

    if (engagementFormData.linkMeta) {
      const linkMeta = JSON.parse(engagementFormData.linkMeta)
      setCurrentLink(linkMeta.url);
      fetchLinkPreview(linkMeta.url);
    }
    displayLink(html, engagementFormData.linkMeta);
  }

  return (
    <>
      <div className="form-group pt-3">
        <label htmlFor="comment">
          {engagementFormData.category === 'post'
            ? t('Your Post')
            : engagementFormData.category === 'discussion'
            ? t('Ask a question to start discussion')
            : t('Your comment')}
        </label>
        {
          engagementFormData.category === 'survey' &&
            <div
              style={{
                paddingBottom: 5,
                fontStyle: 'italic',
              }}
            >
              {t(`This will be your post message, visible on the CivicFeed.`)}
            </div>
        }
        <LexicalEditor
          initialHtml={engagementFormData.comment_html}
          initialDraftJs={engagementFormData.comment}
          onLoad={onLoad}
          onHtmlChange={commentLexicalChange}
          onTextContentChange={detectLink}
          onDraftJsChange={commentEditChange}
        />

        {typeof engagementErrors !== 'undefined' &&
          engagementErrors.comment && (
            <div className="clsinvalid">{t(engagementErrors?.['comment'])}</div>
          )}

        {hidePreview === false && currentLink ? (
          <div className="mb-3">
            {showHideBtn === true ? (
              <div className="link-preview-close">
                <button
                  type="button"
                  className="link-preview-btn"
                  aria-label="Hide Preview"
                  onClick={onHidePreview}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            ) : null}
            <LinkPreview
              url={currentLink}
              fetcher={fetcher}
              className="preview-link-container"
            />
          </div>
        ) : null}
      </div>
      {engagementFormData.category === 'post' ? (
        <>
          {showHideBtn === false ? (
            <FileUploader
              fileChange={engagementChange}
              fileList={engagementFormData.files}
              linkList={engagementFormData.links}
              removeLink={removeLink}
              isVideoAllowed={true}
              isDimensionCheck={false}
              allowedImgWidth={490}
              key={
                typeof engagementFormData.id !== 'undefined'
                  ? `post-file-uploader-${engagementFormData.id}`
                  : `post-file-uploader`
              }
              acceptFormat={'.jpeg, .png, .jpg, .tiff, .svg, .gif'}
              maxFiles={5}
              label={t(`Upload Documents (optional)`)}
            />
          ) : (
            <div>
              <label>{ t('Upload Documents (optional)') }</label>
              <section>
                <div role="button" tabIndex="0" className="disabled-upload">
                  <div>
                    <span className="file-drag-drop-txt">
                      { t('Drag \& Drop or')}{' '}
                    </span>
                    <span className="file-browse-txt">{ t('Browse') }</span>
                  </div>
                </div>
              </section>
              <div className="err-txt mt-2 mb-3">
                {t('Please delete your website preview to upload images/documents to this post')}
              </div>
            </div>
          )}
          <div className="clscheckbox">
            <span>
              <label>
                <input
                  id="schedulePost"
                  type="checkbox"
                  name="schedulePost"
                  checked={engagementFormData.schedulePost}
                  value={engagementFormData.schedulePost}
                  onChange={(e) =>
                    engagementChange(
                      e.target.name,
                      !engagementFormData.schedulePost,
                    )
                  }
                />
                <label htmlFor="schedulePost">
                  <span></span>
                  {t(`Schedule Post`)}
                </label>
              </label>
            </span>
          </div>
          {engagementFormData.schedulePost === true ? (
            <ScheduleDate {...props} />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <div
        style={{
          display: engagementFormData.category === 'survey' ? 'block' : 'none',
        }}
      >
        <div className="form-group">
          <label htmlFor="surveyTitle">{t(`Survey Title`)}</label>
          <div
            style={{
              paddingBottom: 15,
              fontStyle: 'italic',
            }}
          >
            {t(`The name of your survey, visible to residents when they want to see more information, e.g. how long the survey stays open.`)}
          </div>
          <input
            type="text"
            className="form-control"
            placeholder={t(`Survey Title`)}
            name={'surveyTitle'}
            value={
              engagementFormData.surveyTitle !== null
                ? engagementFormData.surveyTitle
                : ''
            }
            onChange={(e) => engagementChange(e.target.name, e.target.value)}
          />
          {typeof engagementErrors !== 'undefined' &&
            engagementErrors.surveyTitle && (
              <div className="clsinvalid">
                {t(engagementErrors?.['surveyTitle'])}
              </div>
            )}
        </div>
        <div className="form-group">
          <label htmlFor="description">
            {t(`Survey Description (optional)`)}
          </label>
          <div
            style={{
              paddingBottom: 15,
              fontStyle: 'italic',
            }}
          >
            {t(`More details about this survey, e.g. what documents are attached to it. Only visible if residents want to see more information.`)}
          </div>
          <textarea
            className="form-control"
            placeholder={t(`Enter`)}
            name={'description'}
            value={
              engagementFormData.description !== null
                ? engagementFormData.description
                : ''
            }
            onChange={(e) => engagementChange(e.target.name, e.target.value)}
            rows={4}
          />
          {typeof engagementErrors !== 'undefined' &&
            engagementErrors.description && (
              <div className="clsinvalid">
                {t(engagementErrors?.['description'])}
              </div>
            )}
        </div>
        {engagementFormData.category === 'survey' ? (
          <>
            {showHideBtn == false ? (
              <FileUploader
                fileChange={engagementChange}
                fileList={engagementFormData.files}
                linkList={engagementFormData.links}
                removeLink={removeLink}
                key={
                  typeof engagementFormData.id !== 'undefined'
                    ? `survey-file-uploader-${engagementFormData.id}`
                    : `survey-file-uploade`
                }
                label={t(`Upload Documents (optional)`)}
                type={`surveyEngagement`}
                isVideoAllowed={true}
              />
            ) : (
              <div>
                <FileUploader
                  fileChange={engagementChange}
                  fileList={engagementFormData.files}
                  linkList={engagementFormData.links}
                  removeLink={removeLink}
                  key={
                    typeof engagementFormData.id !== 'undefined'
                      ? `survey-file-uploader-${engagementFormData.id}`
                      : `survey-file-uploade`
                  }
                  label={t(`Upload Documents (optional)`)}
                  type={`surveyEngagement`}
                  acceptFormat={'.pdf'}
                  isVideoAllowed={true}
                />
                <div className="err-txt mt-2 mb-3">
                  {
                    t('Please delete your website preview to upload images/documents to this post')
                  }
                </div>
              </div>
            )}
            <div className="form-group">
              <label>{t(`Main Question`)}</label>
              {engagementFormData.question.map((question, index) => {
                return (
                  <div
                    className="clsfield-set-blk form-group question-section"
                    key={`question-${index}`}
                  >
                    <div className="form-group">
                      <label>{t(`Type`)}</label>
                      <SelectOption
                        error={
                          typeof engagementErrors.question !== 'undefined' &&
                          typeof engagementErrors['question'][index] !==
                            'undefined' &&
                          typeof engagementErrors['question'][index]['type'] !==
                            'undefined'
                            ? engagementErrors['question'][index]['type']
                            : ''
                        }
                        name={'questionType'}
                        placeholder={t('Question Type')}
                        value={handleOptionValue(
                          questionTypeOptions,
                          typeof engagementFormData.question[index]['type'] !==
                            'undefined' &&
                            engagementFormData.question[index]['type'] !== null
                            ? engagementFormData.question[index][
                                'type'
                              ].toString()
                            : '',
                        )}
                        options={questionTypeOptions}
                        handleSelectChange={(value) => {
                          questionChange(index, 'type', value.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>{t(`Question`)}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t(`Enter`)}
                        name={'question'}
                        value={engagementFormData.question[index].title}
                        onChange={(e) => {
                          questionChange(index, 'title', e.target.value);
                        }}
                      />
                      {typeof engagementErrors.question !== 'undefined' &&
                        typeof engagementErrors['question'][index] !==
                          'undefined' &&
                        typeof engagementErrors['question'][index]['title'] !==
                          'undefined' && (
                          <div className="clsinvalid">
                            {t(engagementErrors?.['question']?.[index]?.['title'])}
                          </div>
                        )}
                      <div
                        className={
                          index === 0 ? 'btn clsinfo clsques' : 'btn clsinfo'
                        }
                      >
                        <a
                          href={hashtag}
                          onClick={() => {
                            removeQuestion(index);
                          }}
                        >
                          <img
                            src={
                              require('../../../assets/img/delete.svg').default
                            }
                            alt="Info"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{t(`Choices`)}</label>
                      {engagementFormData.question[index].choice.map(
                        (option, id) => {
                          return (
                            <div
                              className={'choice-section'}
                              key={`question-${index}-choice${id}`}
                            >
                              <input
                                className="form-control form-group"
                                placeholder={t(`Enter`)}
                                name={'choice'}
                                value={
                                  engagementFormData.question[index].choice[id][
                                    'value'
                                  ]
                                }
                                onChange={(e) => {
                                  choiceChange(index, id, e.target.value);
                                }}
                              />
                              {typeof engagementErrors.question !==
                                'undefined' &&
                                typeof engagementErrors['question'][index] !==
                                  'undefined' &&
                                typeof engagementErrors['question'][index][
                                  'choice'
                                ] !== 'undefined' &&
                                engagementErrors['question'][index]['choice'][
                                  id
                                ] && (
                                  <div className="clsinvalid">
                                    {
                                      t(engagementErrors?.['question']?.[index]?.['choice']?.[id]?.['value'])
                                    }
                                  </div>
                                )}
                              <div
                                className={
                                  index === 0 &&
                                  engagementFormData.question[index]['choice']
                                    .length === 2
                                    ? 'btn clsinfo clsques'
                                    : 'btn clsinfo'
                                }
                              >
                                <a
                                  href={hashtag}
                                  onClick={() => {
                                    removeChoice(index, id);
                                  }}
                                >
                                  <img
                                    src={
                                      require('../../../assets/img/delete.svg')
                                        .default
                                    }
                                    alt="Info"
                                  />
                                </a>
                              </div>
                            </div>
                          );
                        },
                      )}
                      {engagementFormData.question[index]['type'] === '1' &&
                      engagementFormData.question[index]['choice'].length >=
                        2 ? (
                        ''
                      ) : (
                        <p className="clsadd-choice mt-3">
                          <a
                            href={hashtag}
                            onClick={() => {
                              addChoice(index);
                            }}
                          >
                            {t(`Add Choice`)}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="mt-3">
                <a
                  href={hashtag}
                  onClick={addQuestion}
                  className="clsadd-question-button"
                >
                  <img
                    src={require('../../../assets/img/plus.svg').default}
                    alt=""
                  />{' '}
                  {t(`Add Question`)}
                </a>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default EngagementSecondStep;
