/**
 *
 * Analytics component
 *
 */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AnalyticsHeader = (props) => {
  const { location, downloadData } = props;
  const { t } = useTranslation();
  const hashUrl = '#';
  const history = useHistory();
  const engagementId = new URLSearchParams(location.search).get('id');

  const navigateTo = (url) => {
    history.push(url);
  };

  return (
    <div className={`clsanlytics-title-blk`}>
      <div className={`clsanalytics-title`}>
        <h6 className={`mb-2`}>
          <a
            href={hashUrl}
            onClick={() => {
              navigateTo('/dashboard');
            }}
          >
            <img
              src={require('../../../assets/img/back.svg').default}
              alt="Back"
            />
            {t(`Back to Dashboard`)}
          </a>
        </h6>
        <h4>{t(`Post Engagement Analytics`)}</h4>
      </div>
      <div className={`clsanalytics-title-download`}>
        <div className={`btn-group`}>
          <button
            type="button"
            className={`btn dropdown-toggle`}
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {t(`Download`)}
          </button>
          <div className={`dropdown-menu dropdown-menu-right`}>
            <button
              className={`dropdown-item`}
              type="button"
              onClick={() => {
                downloadData(engagementId);
              }}
            >
              {t(`Analytics Data`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsHeader;
