/*
 *
 * CivicFeed actions
 *
 */

import {
  SET_CIVICFEED_LOADING,
  FETCH_CIVICFEED_ENGAGEMENTS,
  CIVICFEED_RELOAD_LIST,
  CIVICFEED_CHANGE,
  CIVICFEED_LIST_CHANGE,
  SET_USER_PROFILE,
  RESET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  RESET_APP,
} from './constants';
import { setUserData } from '../Dashboard/actions';
import { updateEngagementSpecific } from '../Engagement/actions';
import { searchResultChange } from '../SearchResult/actions';
import axios from 'axios';
import { BASE_API_URL } from '../../constants';
import { addNotification } from '../Notification/actions';
import { setEngagementData } from '../Engagement/actions';

export const resetCivicFeedState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const civicEngagementChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: CIVICFEED_CHANGE, payload: formData });
  };
};

export const civicEngagementChangeByOption = (name, value) => {
  return async (dispatch, getState) => {
    let formData = {};
    formData[name] = value;
    if (name === 'sort') {
      dispatch({ type: CIVICFEED_CHANGE, payload: formData });
      return dispatch(reloadCivicEngagement());
    } else {
      return dispatch({ type: CIVICFEED_CHANGE, payload: formData });
    }
  };
};

export const getCivicEngagements = (page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let sort = getState().civicFeed.sort;
      let filterSelected = getState().civicFeed.filterSelected;
      let filter = getState().civicFeed.filter;

      let location = getState().router.location;
      let uri = location.pathname;
      uri = uri.replace(/\/+$/, '');

      let apiUri =
        uri === '/dashboard/civicfeed' || uri === '/dashboard/searchresult'
          ? '/users/civicfeed?page=' + page
          : '/users/myengagement?page=' + page;

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
        if (page === 1) {
          await axios
            .get(
              BASE_API_URL +
                '/users/officialSearch?keyword=' +
                searchKey +
                '&isFollowing=true',
            )
            .then((response) => {
              if (response.data.success === 'true') {
                let districtSearchResult = {};
                districtSearchResult['districtSearchResult'] =
                  response.data.data;
                districtSearchResult['showResult'] = true;
                districtSearchResult['searchText'] = searchKey;
                dispatch(searchResultChange(districtSearchResult));
              }
            })
            .catch((error) => {
              dispatch(
                addNotification({
                  type: 'error',
                  message: error?.response?.data?.error,
                  module: 'dashboard',
                }),
              );
            });

          await axios
            .get(BASE_API_URL + '/users/officialSearch?keyword=' + searchKey)
            .then((response) => {
              if (response.data.success === 'true') {
                let anywhereSearchData = {};
                anywhereSearchData['anywhereSearchResult'] = response.data.data;
                anywhereSearchData['showResult'] = true;
                anywhereSearchData['searchText'] = searchKey;
                dispatch(searchResultChange(anywhereSearchData));
              }
            })
            .catch((error) => {
              dispatch(
                addNotification({
                  type: 'error',
                  message: error?.response?.data?.error,
                  module: 'dashboard',
                }),
              );
            });
        }
      }
      let pathName = location.search;
      const topicId = new URLSearchParams(pathName).get('topicId');

      if (
        typeof topicId === 'undefined' ||
        topicId === null ||
        topicId === ''
      ) {
        //Sorting & Filter
        if (sort !== '') {
          apiUri += '&sort=' + sort;
        }

        if (filterSelected['topics'].length > 0) {
          apiUri += '&topics=' + JSON.stringify(filterSelected['topics']);
        }
        if (filterSelected['locations'].length > 0) {
          let locations = filter['locations']['items']
            .filter((item, index) => {
              return filterSelected['locations'].indexOf(item.id) !== -1;
            })
            .map((item) => item.name);
          apiUri += '&locations=' + JSON.stringify(locations);
        }
        /*if(filterSelected['status'].length > 0) {
          apiUri += '&status='+JSON.stringify(filterSelected['status']);
        }*/
        if (filterSelected['postType'].length > 0) {
          apiUri += '&postType=' + JSON.stringify(filterSelected['postType']);
        }
      } else {
        //Topic Parameter
        let topic = [];
        topic[0] = topicId;
        apiUri += '&topics=' + JSON.stringify(topic) + '&topic_page=true';
      }

      if (page === 1) {
        formData['engagements'] = [];
        dispatch(civicEngagementChange(formData));
      }
      //dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().civicFeed.engagements.length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_CIVICFEED_ENGAGEMENTS,
                  payload: response.data.data,
                });
              }
            } else if (
              page === 1 &&
              getState().civicFeed.engagements.length === 0
            ) {
              dispatch({
                type: FETCH_CIVICFEED_ENGAGEMENTS,
                payload: response.data.data,
              });
            }
            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            formData['reloadList'] = false;
            dispatch(civicEngagementChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
        })
        .finally(() => {
          //dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const getCivicEngagementsForGuest = (page = 1, limit = 4) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let sort = getState().civicFeed.sort;
      let filterSelected = getState().civicFeed.filterSelected;
      let filter = getState().civicFeed.filter;

      let location = getState().router.location;
      let uri = location.pathname;
      uri = uri.replace(/\/+$/, '');

      let apiUri =
          uri === '/dashboard/civicfeed' || uri === '/dashboard/searchresult'
              ? '/users/civicfeed?page=' + page + '&limit=' + limit
              : '/users/myengagement?page=' + page + '&limit=' + limit;

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
        if (page === 1) {
          await axios
              .get(
                  BASE_API_URL +
                  '/users/officialSearch?keyword=' +
                  searchKey +
                  '&isFollowing=true',
              )
              .then((response) => {
                if (response.data.success === 'true') {
                  let districtSearchResult = {};
                  districtSearchResult['districtSearchResult'] =
                      response.data.data;
                  districtSearchResult['showResult'] = true;
                  districtSearchResult['searchText'] = searchKey;
                  dispatch(searchResultChange(districtSearchResult));
                }
              })
              .catch((error) => {
                dispatch(
                    addNotification({
                      type: 'error',
                      message: error?.response?.data?.error,
                      module: 'dashboard',
                    }),
                );
              });

          await axios
              .get(BASE_API_URL + '/users/officialSearch?keyword=' + searchKey)
              .then((response) => {
                if (response.data.success === 'true') {
                  let anywhereSearchData = {};
                  anywhereSearchData['anywhereSearchResult'] = response.data.data;
                  anywhereSearchData['showResult'] = true;
                  anywhereSearchData['searchText'] = searchKey;
                  dispatch(searchResultChange(anywhereSearchData));
                }
              })
              .catch((error) => {
                dispatch(
                    addNotification({
                      type: 'error',
                      message: error?.response?.data?.error,
                      module: 'dashboard',
                    }),
                );
              });
        }
      }
      let pathName = location.search;
      const topicId = new URLSearchParams(pathName).get('topicId');

      if (
          typeof topicId === 'undefined' ||
          topicId === null ||
          topicId === ''
      ) {
        //Sorting & Filter
        if (sort !== '') {
          apiUri += '&sort=' + sort;
        }

        if (filterSelected['topics'].length > 0) {
          apiUri += '&topics=' + JSON.stringify(filterSelected['topics']);
        }
        if (filterSelected['locations'].length > 0) {
          let locations = filter['locations']['items']
              .filter((item, index) => {
                return filterSelected['locations'].indexOf(item.id) !== -1;
              })
              .map((item) => item.name);
          apiUri += '&locations=' + JSON.stringify(locations);
        }
        /*if(filterSelected['status'].length > 0) {
          apiUri += '&status='+JSON.stringify(filterSelected['status']);
        }*/
        if (filterSelected['postType'].length > 0) {
          apiUri += '&postType=' + JSON.stringify(filterSelected['postType']);
        }
      } else {
        //Topic Parameter
        let topic = [];
        topic[0] = topicId;
        apiUri += '&topics=' + JSON.stringify(topic) + '&topic_page=true';
      }

      if (page === 1) {
        formData['engagements'] = [];
        dispatch(civicEngagementChange(formData));
      }
      //dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
      await axios
          .get(BASE_API_URL + apiUri)
          .then((response) => {
            if (response.data.success === 'true') {
              if (getState().civicFeed.engagements.length > 0) {
                if (page !== 1) {
                  dispatch({
                    type: FETCH_CIVICFEED_ENGAGEMENTS,
                    payload: response.data.data,
                  });
                }
              } else if (
                  page === 1 &&
                  getState().civicFeed.engagements.length === 0
              ) {
                dispatch({
                  type: FETCH_CIVICFEED_ENGAGEMENTS,
                  payload: response.data.data,
                });
              }
              formData = {};
              if (response.data.data.length === 0) {
                formData['noRecordStatus'] = true;
              }
              formData['reloadList'] = false;
              dispatch(civicEngagementChange(formData));
            }
          })
          .catch((error) => {
            dispatch(
                addNotification({
                  type: 'error',
                  message: error?.response?.data?.error,
                  module: 'dashboard',
                }),
            );
          })
          .finally(() => {
            //dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
          });
      return resolve(true);
    });
  };
};

export const getOfficialCivicEngagements = (page = 1, officialId = null) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      let searchKey = getState().search.searchKey;
      let sort = getState().civicFeed.sort;
      let filterSelected = getState().civicFeed.filterSelected;
      let filter = getState().civicFeed.filter;

      let location = getState().router.location;

      let apiUri = '/users/civicfeed?page=' + page;

      if (searchKey.length > 0) {
        apiUri += '&keyword=' + searchKey;
      }

      if (officialId !== null) {
        apiUri += '&officialId=' + officialId;
      }

      let pathName = location.search;
      const topicId = new URLSearchParams(pathName).get('topicId');

      if (
        typeof topicId === 'undefined' ||
        topicId === null ||
        topicId === ''
      ) {
        //Sorting & Filter
        if (sort !== '') {
          apiUri += '&sort=' + sort;
        }

        if (filterSelected['topics'].length > 0) {
          apiUri += '&topics=' + JSON.stringify(filterSelected['topics']);
        }
        if (filterSelected['locations'].length > 0) {
          let locations = filter['locations']['items']
            .filter((item, index) => {
              return filterSelected['locations'].indexOf(item.id) !== -1;
            })
            .map((item) => item.name);
          apiUri += '&locations=' + JSON.stringify(locations);
        }
        /*if(filterSelected['status'].length > 0) {
          apiUri += '&status='+JSON.stringify(filterSelected['status']);
        }*/
        if (filterSelected['postType'].length > 0) {
          apiUri += '&postType=' + JSON.stringify(filterSelected['postType']);
        }
      } else {
        //Topic Parameter
        let topic = [];
        topic[0] = topicId;
        apiUri += '&topics=' + JSON.stringify(topic) + '&topic_page=true';
      }

      if (page === 1) {
        formData['engagements'] = [];
        dispatch(civicEngagementChange(formData));
      }
      //dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
      await axios
        .get(BASE_API_URL + apiUri)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().civicFeed.engagements.length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_CIVICFEED_ENGAGEMENTS,
                  payload: response.data.data,
                });
              }
            } else if (
              page === 1 &&
              getState().civicFeed.engagements.length === 0
            ) {
              dispatch({
                type: FETCH_CIVICFEED_ENGAGEMENTS,
                payload: response.data.data,
              });
            }
            formData = {};
            if (response.data.data.length === 0) {
              formData['noRecordStatus'] = true;
            }
            formData['reloadList'] = false;
            dispatch(civicEngagementChange(formData));
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
        })
        .finally(() => {
          //dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

export const reloadCivicEngagement = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CIVICFEED_RELOAD_LIST });
  };
};

export const getFilterItems = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(BASE_API_URL + '/users/followingandLocations')
        .then((response) => {
          if (response.data.success === 'true') {
            let filter = getState().civicFeed.filter;
            filter['topics']['items'] = response.data.data.topics;
            filter['locations']['items'] = response.data.data.locations;
            let formData = {};
            formData['filter'] = filter;
            dispatch(civicEngagementChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const resetFilter = (reload = false) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().civicFeed.filterSelected;
    filterSelected['topics'] = [];
    filterSelected['locations'] = [];
    //filterSelected['status'] = [];
    filterSelected['postType'] = [];
    let formData = {};
    formData['filterSelected'] = filterSelected;
    if (reload === false) {
      return dispatch(civicEngagementChange(formData));
    } else {
      dispatch(civicEngagementChange(formData));
      return dispatch(reloadCivicEngagement());
    }
  };
};

export const filterChange = (type, value, reload = false) => {
  return async (dispatch, getState) => {
    let filterSelected = getState().civicFeed.filterSelected;
    if (filterSelected[type].indexOf(value) !== -1) {
      const index = filterSelected[type].indexOf(value);
      if (index > -1) {
        filterSelected[type].splice(index, 1);
      }
    } else {
      filterSelected[type].push(value);
    }

    let formData = {};
    formData['filterSelected'] = { ...filterSelected };
    if (filterSelected[type].length === 0 && reload === true) {
      dispatch(civicEngagementChange(formData));
      return dispatch(reloadCivicEngagement());
    }
    return dispatch(civicEngagementChange(formData));
  };
};

export const getSearchData = (type) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let searchBox = getState().civicFeed.searchBox;
      let value = searchBox[type].key;
      let formData = {};
      let suggestionBox = getState().civicFeed.suggestionBox;
      suggestionBox[type]['loading'] = true;
      formData['suggestionBox'] = { ...suggestionBox };
      dispatch(civicEngagementChange(formData));
      await axios
        .get(
          BASE_API_URL +
            '/users/followingandLocations/?searchBy=' +
            type +
            '&keyword=' +
            value,
        )
        .then((response) => {
          if (response.data.success === 'true') {
            suggestionBox = getState().civicFeed.suggestionBox;
            suggestionBox[type]['items'] = response.data.data[type];
            suggestionBox[type]['loading'] = false;
            formData['suggestionBox'] = { ...suggestionBox };
            dispatch(civicEngagementChange(formData));
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const searchBoxChange = (type, key, value) => {
  return async (dispatch, getState) => {
    let searchBox = getState().civicFeed.searchBox;
    searchBox[type] = { ...searchBox[type], ...{ [key]: value } };
    let formData = {};
    formData['searchBox'] = { ...searchBox };
    dispatch(civicEngagementChange(formData));
  };
};

export const suggestiongBoxChange = (type, key, value) => {
  return async (dispatch, getState) => {
    let suggestionBox = getState().civicFeed.suggestionBox;
    suggestionBox[type] = { ...suggestionBox[type], ...{ [key]: value } };
    let formData = {};
    formData['suggestionBox'] = { ...suggestionBox };
    dispatch(civicEngagementChange(formData));
  };
};

export const followSuggested = (type, value) => {
  return async (dispatch, getState) => {
    let filter = getState().civicFeed.filter;
    const found = filter[type]['items'].find((item) => item.id === value.id);

    if (typeof found === 'undefined') {
      filter[type]['items'] = [...filter[type]['items'], value];
      let formData = {};
      formData['filter'] = filter;
      dispatch(civicEngagementChange(formData));
    }

    dispatch(filterChange(type, value.id));
  };
};

export const submitFilter = () => {
  return async (dispatch, getState) => {
    let formData = {};
    formData['filterDismissal'] = true;
    dispatch(civicEngagementChange(formData));
    dispatch(reloadCivicEngagement());
  };
};

export const followTopic = (topicId, type) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let userId = getState().account.user.userId;
      let requestData = { topicId: topicId, userId: userId, type: type };
      await axios
        .post(BASE_API_URL + '/users/followTopic', requestData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            let formData = {};
            formData['userFollowingTopics'] =
              getState().account.user.userFollowingTopics;

            let userTopics = getState().account.user.userFollowingTopics;
            let found = false;

            userTopics.forEach((value, index) => {
              if (parseInt(value.id) === parseInt(topicId)) {
                if (type === 'unfollow') {
                  formData['userFollowingTopics'].splice(index, 1);
                  found = true;
                }
              }
            });

            if (found === false) {
              formData['userFollowingTopics'].push({ id: topicId });
            }

            dispatch(setUserData(formData));
            dispatch(reloadCivicEngagement());
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'dashboard',
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
          reject(error.response.data.error);
        });
      return resolve(true);
    });
  };
};

//Follow Locations
export const followLocation = (location, type) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let userId = getState().account.user.userId;
      let requestData = { location: location, userId: userId, type: type };
      await axios
        .post(BASE_API_URL + '/users/followLocation', requestData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            let formData = {};
            formData['followinglocations'] =
              getState().account.user.followinglocations;
            let position = formData['followinglocations'].find(
              (item) => item === location,
            );
            if (position === -1) {
              formData['followinglocations'].splice(position, 1);
            } else {
              formData['followinglocations'].push(location);
            }

            dispatch(setUserData(formData));
            dispatch(reloadCivicEngagement());
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'dashboard',
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
          reject(error.response.data.error);
        });
      return resolve(true);
    });
  };
};

//Follow public official
export const followPublicOfficial = (followerId, single = false) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let userId = getState().account.user.userId;
      let requestData = { userId: userId, followerId: followerId };
      dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
      await axios
        .post(BASE_API_URL + '/users/follow', requestData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            if (single) {
              let official = getState().civicFeed.userProfile;
              let formData = {};
              formData['userFollow'] = !official.userFollow;
              formData['followers'] =
                !official.userFollow === true
                  ? parseInt(official.followers) + 1
                  : parseInt(official.followers) - 1;
              dispatch({ type: UPDATE_USER_PROFILE, payload: formData });
            } else {
              let formData = {};
              formData['userFollowingOfficials'] =
                response.data.data.userFollowingOfficials;
              dispatch(setUserData(formData));
            }
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
          dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
          return reject(error.response.data.error);
        })
        .finally(() => {
          dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

//Follow Post
export const followPost = (postId) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let userId = getState().account.user.userId;
      let requestData = { userId: userId, postId: postId };
      dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
      await axios
        .post(BASE_API_URL + '/users/followPost', requestData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            let formData = {};
            formData['userFollowingPosts'] =
              response.data.data.userFollowingPosts;
            dispatch(setUserData(formData));
            //update engagements
            formData = {
              id: postId,
              values: {
                userFollowPost:
                  response.data.data.userAction === 'follow_post'
                    ? true
                    : false,
              },
            };
            dispatch({
              type: CIVICFEED_LIST_CHANGE,
              payload: formData,
            });
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
          dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
          return reject(error.response.data.error);
        })
        .finally(() => {
          dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
        });
      return resolve(true);
    });
  };
};

//Set Emotions
export const setEmotions = (id, reaction) => {
  let formData = {},
    engagementData = {};
  return async (dispatch, getState) => {
    if (getState().civicFeed.isEngagementLoading) {
      return;
    }

    return new Promise(async (resolve, reject) => {
      let userId = getState().account.user.userId;
      engagementData['userId'] = userId;
      engagementData['postId'] = id;
      engagementData['commentId'] = null;
      engagementData['reaction'] = reaction;
      dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
      await axios
        .post(BASE_API_URL + '/official/comment/', engagementData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            let data = response.data.data;
            formData = {
              id: id,
              values: {
                liked_count: data.likeReactionCount,
                disliked_count: data.dislikeReactionCount,
                userReaction: data.userReaction,
                userLikeReaction: data.userLikeReaction,
                userDisLikeReaction: data.userDisLikeReaction,
              },
            };
            let engagementSpecific = getState().engagement.engagementSpecific;
            if (typeof engagementSpecific.id !== 'undefined') {
              if (engagementSpecific.id === id) {
                engagementSpecific = {
                  ...engagementSpecific,
                  ...{
                    liked_count: data.likeReactionCount,
                    disliked_count: data.dislikeReactionCount,
                    userReaction: data.userReaction,
                    userLikeReaction: data.userLikeReaction,
                    userDisLikeReaction: data.userDisLikeReaction,
                  },
                };
                dispatch(updateEngagementSpecific(engagementSpecific));
              }
            }
            dispatch({
              type: CIVICFEED_LIST_CHANGE,
              payload: formData,
            });
            dispatch(setEngagementData(id));
          }
          dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
          dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
        });
    });
  };
};

export const remindToOfficial = (userId, id, type) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let reminderData = { userId: userId, resourceId: id, resourceType: type };
      await axios
        .post(BASE_API_URL + '/users/notifications/remindOfficial', reminderData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch(
              addNotification({
                type: 'success',
                message: response.data.message,
                module: 'dashboard',
                displayFor: 5000,
                parentDivClass: 'top-notification',
              }),
            );
            let formData = { id: id, values: { remindOfficial: true } };
            dispatch({
              type: CIVICFEED_LIST_CHANGE,
              payload: formData,
            });
            let engagementSpecific = getState().engagement.engagementSpecific;
            if (typeof engagementSpecific.id !== 'undefined') {
              if (engagementSpecific.id === id) {
                engagementSpecific = {
                  ...engagementSpecific,
                  ...{ remindOfficial: true },
                };
                dispatch(updateEngagementSpecific(engagementSpecific));
              }
            }
            return resolve(response.data.message);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'dashboard',
            }),
          );
          return reject(error.response.data.error);
        });
    });
  };
};

export const setViewedStatus = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let engagements = getState().civicFeed.engagements;
      let status = true;
      engagements.every((engagement) => {
        if (engagement.id === parseInt(id)) {
          if (engagement.viewStatus === true) {
            status = false;
            return false;
          }
        }
        return true;
      });

      if (status === true) {
        let userId = getState().account.user.userId;
        let param = { userId: userId, postId: parseInt(id) };
        await axios
          .post(BASE_API_URL + '/users/updateViewsCount', param, {
            headers: { 'Content-Type': 'application/json' },
          })
          .then((response) => {
            if (response.data.success === 'true') {
              let formData = { id: parseInt(id), values: { viewStatus: true } };
              dispatch({
                type: CIVICFEED_LIST_CHANGE,
                payload: formData,
              });
              return resolve(true);
            }
          });
      }
    });
  };
};

export const turnOnOffNotification = (id, status) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
    await axios
      .put(BASE_API_URL + '/users/notificationstatuses/updatePostFollowingStatus', {
        postId: id,
        status: status,
      })
      .then((response) => {
        if (response.data.success === 'true') {
          let formData = {
            id: id,
            values: { notification_status: response.data.data.status },
          };
          dispatch({
            type: CIVICFEED_LIST_CHANGE,
            payload: formData,
          });
          let engagementSpecific = getState().engagement.engagementSpecific;
          if (typeof engagementSpecific.id !== 'undefined') {
            if (engagementSpecific.id === id) {
              engagementSpecific = {
                ...engagementSpecific,
                ...{ notification_status: response.data.data.status },
              };
              dispatch(updateEngagementSpecific(engagementSpecific));
            }
          }

          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'dashboard',
              displayFor: 5000,
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        if (Array.isArray(errorList) === true) {
          errorList.forEach((error, index) => {
            dispatch(
              addNotification({
                type: 'error',
                message: errorList[index],
                module: 'dashboard',
                parentDivClass: 'top-notification',
              }),
            );
          });
        } else {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList,
              module: 'dashboard',
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .finally(() => {
        dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
      });
  };
};

export const reportEngagement = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_CIVICFEED_LOADING, payload: true });
    let roleName = getState().account.user.roleName;
    let uri =
      roleName === 'PUBLICOFFICIAL' || roleName === 'ADMIN'
        ? '/official/reportAsInappropriate'
        : '/users/reportAsInappropriate';
    const reportLink =
      window.location.origin + '/dashboard/engagement/?id=' + id;
    await axios
      .put(BASE_API_URL + uri, { postId: id, reportLink: reportLink })
      .then((response) => {
        if (response.data.success === 'true') {
          dispatch(
            addNotification({
              type: 'success',
              message: response.data.message,
              module: 'dashboard',
              displayFor: 5000,
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .catch((error) => {
        let errorList = error.response.data.error;
        if (Array.isArray(errorList) === true) {
          errorList.forEach((error, index) => {
            dispatch(
              addNotification({
                type: 'error',
                message: errorList[index],
                module: 'dashboard',
                parentDivClass: 'top-notification',
              }),
            );
          });
        } else {
          dispatch(
            addNotification({
              type: 'error',
              message: errorList,
              module: 'dashboard',
              displayFor: 5000,
              parentDivClass: 'top-notification',
            }),
          );
        }
      })
      .finally(() => {
        dispatch({ type: SET_CIVICFEED_LOADING, payload: false });
      });
  };
};

export const setUserProfile = (id) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: RESET_USER_PROFILE });
      await axios
        .get(BASE_API_URL + '/users/getOfficialProfile?officialId=' + id)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({ type: SET_USER_PROFILE, payload: response.data.data });
            return resolve(true);
          }
        })
        .catch((error) => {
          dispatch(
            addNotification({
              type: 'error',
              message: error?.response?.data?.error,
              module: 'map',
              displayFor: 5000,
            }),
          );
          return reject(false);
        });
      return resolve(true);
    });
  };
};

export const updateCivicList = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({
      type: CIVICFEED_LIST_CHANGE,
      payload: formData,
    });
  };
};
