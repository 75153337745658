/*
 *
 * CivicBell Theme Modal Component
 *
 */

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const CivicModal = (props) => {
  const closeRef = useRef(null);
  const {
    id,
    title,
    children,
    dismissal,
    footer,
    footerClass,
    scrollable,
    modalDialogueClass,
    modalClass,
    modalBodyClass,
    hideClose,
    onOpen,
    onClose,
    onUserInitiatedClose
  } = props;

  const modalContainerRef = useRef(null);

  useEffect(() => {
    if (dismissal !== null && closeRef !== null && hideClose !== true) {
      closeRef.current.click();
    }
  }, [dismissal, hideClose]);

  useEffect(() => {
    const handleClassMutation = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const classList = mutation.target.classList;
          
          if (classList.contains('show')) {
            if (onOpen) {
              onOpen();
            }
          } else {
            if (onClose) {
              onClose();
            }
          }
        }
      }
    };

    const observer = new MutationObserver(handleClassMutation);

    if (modalContainerRef.current) {
      observer.observe(modalContainerRef.current, {
        attributes: true,
        attributeFilter: ['class']
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);  // Empty dependency array ensures this effect only runs once


  const { t } = useTranslation();

  const handleCloseButtonClick = (e) => {
    // 'close' button is often clicked programmatically, this ensures that the function will be called only if user clicks the 'close' button
    if (e.isTrusted && onUserInitiatedClose) {
      onUserInitiatedClose();
    }
  }

  const handleOverlayClick = (e) => {
    if(e.isTrusted && e.target.classList.contains('clsmodal') && onUserInitiatedClose) {
      onUserInitiatedClose();
    }
  }

  return (
    <div
      className={`modal fade clsmodal ${modalClass}`}
      id={id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref={modalContainerRef}
      onClick={handleOverlayClick}
    >
      <div
        className={`modal-dialog modal-dialog-centered modal-lg ${modalDialogueClass} ${
          scrollable === true ? `modal-dialog-scrollable` : ``
        }`}
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title" id="exampleModalLabel">
              {t(title)}
            </h5>
            {hideClose === false ? (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={handleCloseButtonClick}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            ) : (
              ''
            )}
          </div>
          <div className={`modal-body ${modalBodyClass}`}>{children}</div>
          <div className={`modal-footer ${footerClass}`}>{footer}</div>
        </div>
      </div>
    </div>
  );
};

CivicModal.defaultProps = {
  dismissal: null,
  footer: '',
  footerClass: '',
  scrollable: false,
  modalDialogueClass: '',
  modalClass: '',
  modalBodyClass: '',
  hideClose: false,
};

export default CivicModal;
