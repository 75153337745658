import React, {useEffect, useState} from 'react';
import axios from "axios";
import {BASE_API_URL} from "../../../constants";
import {connect} from "react-redux";
import actions from "../../../actions";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';


const LocalNewsWidget = ({user}) => {
  const [localNewsData, setLocalNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    fetchLocalNews();
  }, []);

  const fetchLocalNews = async () => {
    try {
      setLoading(true);
      setIsError(false);
      const res = await axios.get(BASE_API_URL + '/users/rssFeedoutlet?page=1&city=true&sort=last_week')
      setLocalNewsData(res.data.data.slice(0, 3));
    } catch (e) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="clsdashboard-upcoming-event clswhite-box">
      <h5 className="mb-1">
        <img
          src={require('../../../assets/img/newspaper.svg').default}
          alt="newspaper"
        />
        { t('Your Local News') }
      </h5>
      <p className="clsnews-location mb-2">
        <img
          src={require('../../../assets/img/location.svg').default}
          alt="Location"
        />
        <span>{user.city}, </span>
        <span>{user.state}</span>
      </p>
      {
        loading ? (
          <p>{ t('Hang on, we\'re getting your local news...') }</p>
        ) : isError || !localNewsData || localNewsData.length < 1 ? (
          <p>{t('Check out')}{' '}<Link to="/dashboard/localnews" className="clear-filter" style={{ textDecoration: 'underline', fontSize: '14px' }}>
              {t('Local News')}
            </Link>{' '}{t('to find some local news sources.')}</p>
        ) : (
          <div className="clsnews-tab-section">
            {localNewsData.map((localNews, index) => (
              <div className="clsevents-list-box clswhite-box mb-2" style={{ backgroundColor: '#f5f4f9' }} key={index}>
                <a href={localNews.link} target="_blank" rel="noopener noreferrer">
                  <div className="media">
                    <div className="media-body">
                      <div className="clsevents-title-blk mb-2">
                        <div className="clsnews-user-img">
                          <img
                            src={localNews.thumbnail_link}
                            className="header-profile-img"
                            alt=""
                          />
                        </div>
                        <div className="clsevents-title ml-2">
                          <h5 className="mb-1">{localNews.newsSource}</h5>
                          <p className="clsnews-date">{localNews.published_date}</p>
                        </div>
                      </div>
                      <h6 className="mb-2">{localNews.title}</h6>
                    </div>
                  </div>
                </a>
              </div>
            ))}
            <div className="text-center">
              <Link to="/dashboard/localnews" className="clear-filter" style={{ textDecoration: 'underline', fontStyle: 'italic' }}>
                { t('Read more') }
              </Link>
            </div>
          </div>
        )
      }
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(LocalNewsWidget);
