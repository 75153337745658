/**
 *
 * Reminder To Public Official
 *
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { remindDiffDays, capitalizeFirstLetter } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';

const Reminder = (props) => {
  const { engagement, location, user, remindToOfficial } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const uri = location.pathname.replace(/^\/|\/$/g, '');
  const [reminderStatus, setReminderStatus] = useState(false);
  const [remindedStatus, setRemindedStatus] = useState(
    typeof engagement.remindOfficial === 'undefined'
      ? false
      : engagement.remindOfficial,
  );
  const [remindText, setRemindText] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const name =
    engagement.post_under_organization === true
      ? engagement.user.organization_name
      : capitalizeFirstLetter(engagement.user.first_name) +
        ' ' +
        capitalizeFirstLetter(engagement.user.last_name);

  //capitalizeFirstLetter(engagement.user.first_name)+' '+capitalizeFirstLetter(engagement.user.last_name);
  const hashUrl = '#';

  const remindStatus = useCallback(() => {
    let remindValues = remindDiffDays(engagement.engagement_completion);
    if (typeof remindValues.remindStatus !== 'undefined') {
      if (remindValues.remindStatus === true) {
        setReminderStatus(true);
        setRemindText('');
      } else {
        setReminderStatus(false);
        if (remindValues.days > 0) {
          setRemindText(
            'You can remind ' + name + ' in ' + remindValues.days + ' day(s)',
          );
        } else if (remindValues.days <= 0) {
          setReminderStatus(true);
        }
      }
    }
  }, [
    engagement.engagement_completion,
    setReminderStatus,
    setRemindText,
    name,
  ]);

  useEffect(() => {
    if (
      engagement.stage === 'closed' &&
      engagement.category !== 'post' &&
      loaded === false &&
      (uri === 'dashboard/civicfeed' ||
        uri === 'dashboard/user-profile' ||
        uri === 'dashboard/civiclist' ||
        uri === 'dashboard/engagement') &&
      remindedStatus === false
    ) {
      if (remindedStatus === true) {
        setRemindedStatus(true);
        setReminderStatus(false);
        setLoaded(true);
      } else {
        remindStatus();
        setLoaded(true);
      }
    }
  }, [
    loaded,
    setLoaded,
    engagement.stage,
    engagement.category,
    setReminderStatus,
    setRemindedStatus,
    remindedStatus,
    remindStatus,
    uri,
  ]);

  const remindOfficial = async () => {
    if (typeof user.roleName === 'undefined') {
      history.push('/login');
    } else {
      setLoader(true);
      await remindToOfficial(user.userId, engagement.id, engagement.category)
        .then((res) => {
          setLoader(false);
          setRemindedStatus(true);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  return (
    <>
      {typeof user.roleName !== 'undefined' ? (
        <div
          className="engagement-reminder mr-2 mt-2"
          id={`engagement-reminder-id-${engagement.id}`}
        >
          {loader === true ? <LoadingIndicator position={`fixed`} /> : ''}
          {remindedStatus === true ? (
            <p className={`reminder-msg`}>{`${t(
              'Your reminder notification has been sent to',
            )} ${name}`}</p>
          ) : (
            ''
          )}
          {remindedStatus === false && reminderStatus === true ? (
            <span>
              {`${t('Do you want to remind')} ${name} ${t(
                'about status update?',
              )}`}{' '}
              <a
                href={hashUrl}
                onClick={(e) => {
                  e.preventDefault();
                  remindOfficial();
                }}
              >
                {t(`Remind Now`)}
              </a>
            </span>
          ) : (
            ''
          )}
          {remindedStatus === false && reminderStatus === false ? (
            <p>{t(remindText)}</p>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Reminder;
