/**
 *
 * Status Messages
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import { useTranslation } from "react-i18next";

const StatusMessage = (props) => {
  const { statusMessages, user } = props;
  const { t } = useTranslation();

  return (
    <>
      {statusMessages.length > 0 ? (
        <div className="clslogin-form-blk">
          <div className="clslogin-form clsresident-form clswhite-box pt-3 mb-2 flip-clock-bg flip-clock-div status-msg">
            <ul className={`ml-2`}>
              {statusMessages.map((msg, index) => (
                <li
                  key={`satus-msg-${index}`}
                  className={`status-msg-txt mt-2`}
                >
                  <div dangerouslySetInnerHTML={
                    user?.selected_language === msg.originalLanguage ?
                      { __html: msg.message }
                    :
                      (JSON.parse(msg.translation)?.[user?.selected_language]) ?
                        { __html: JSON.parse(msg.translation)?.[user?.selected_language] }
                      :
                        { __html: msg.message }
                  } />
                </li>
              ))}
            </ul>
            {props.showFinalizationButton && <button
                type="button"
                className="btn clsyellow-btn"
                id="registration-modal-button"
                onClick={() => {props.openModal(true)}}
            >
              {t(`Ok, finalize my account!`)}
            </button>}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

StatusMessage.defaultProps = {
  statusMessages: [],
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
  }
}

export default connect(mapStateToProps, actions)(StatusMessage);
