import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import actions from "../../../actions";
import {useTranslation} from "react-i18next";
import { Modal } from "react-bootstrap";
import { setLoginModalOpen } from "../../../containers/RegisterFlow/actions";
import { forgotPassword, forgotPasswordChange } from "../../../containers/ForgotPassword/actions";

const Login = (props) => {

    const {
        loginObject,
        login,
        loginChange,
        formErrors,
        authenticated,
        isLoginModalOpen,
        forgotFormData,
        forgotFormErrors,
        forgotIsFormSubmitting
    } = props;

    const {t} = useTranslation();
    // const close = document.querySelector('.civic-login-modal .close');
    const dispatch = useDispatch();
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleForgotPassword = (event) => {
        setIsForgotPasswordModalOpen((isOpen) => !isOpen);
    };

    const handleForgotPasswordSubmit = (event) => {
        event.preventDefault();
        dispatch(forgotPassword());
    };
    
    const resetLoginState = () => {
        setIsSubmitted(false);
        setIsForgotPasswordModalOpen(false);
    };

    useEffect(() => {
        if (!isSubmitted && isSubmitting && !forgotIsFormSubmitting) {
            setIsSubmitting(false);
            setIsSubmitted(true);
        } else if (!isSubmitting && forgotIsFormSubmitting) {
            setIsSubmitting(true);
        }
    }, [forgotIsFormSubmitting, isSubmitting, isSubmitted]);

    const loginComponent = () => {
        return (
            <>
            <div className="form-group col-md-12">
                <input
                    type="text"
                    className="form-control"
                    placeholder={t(`Email Address`)}
                    name={'username'}
                    value={loginObject.loginFormData.username}
                    onChange={(e) => loginChange(e.target.name, e.target.value)}
                    autoComplete={'no'}
                />
                    {typeof formErrors !== 'undefined' && formErrors.username && (
                        <div className="clsinvalid">{t(formErrors?.['username'])}</div>)
                    }
                </div>

                <div className="form-group col-md-12">
                    <input
                        type="password"
                        className="form-control"
                        placeholder={t(`Password`)}
                        name={'password'}
                        value={loginObject.loginFormData.password}
                        onChange={(e) => loginChange(e.target.name, e.target.value)}
                        autoComplete={'no'}
                    />
                    {typeof formErrors !== 'undefined' && formErrors && (
                        <div className="clsinvalid">{t(formErrors?.['password'])}</div>
                    )}
                </div>

                <div className="form-group col-md-12" style={{
                    textAlign: 'center'
                }}>
                    <button
                        type="button"
                        className="btn clsyellow-btn"
                        onClick={() => login('')}>{ t('Login')}
                    </button>
                </div>
            </>
        )
    }

    const forgotPasswordComponent = () => {
        return (
            <>
                {!isSubmitted 
                ? (<div className="form-group col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t(`Email Address`)}
                        name={'username'}
                        value={forgotFormData.email}
                        onChange={(e) =>
                            dispatch(forgotPasswordChange(e.target.name, e.target.value))
                        }
                        autoComplete={'no'}
                    />
                    {typeof forgotFormErrors !== 'undefined' && forgotFormErrors && (
                        <div className="clsinvalid">{t(forgotFormErrors?.['email'])}</div>
                    )}
                </div>
                ) :
                (
                    <div className="form-group col-md-12">
                        <div>{ t('Your forgot password request has been submitted. If you have an account with us, we\'ve emailed you a recovery link.')}</div>
                    </div>
                )}

                <div className="form-group col-md-12" style={{
                    textAlign: 'center'
                }}>
                    <button
                        type="button"
                        className="btn clsyellow-btn"
                        onClick={isSubmitted ? resetLoginState : handleForgotPasswordSubmit}>
                            {isSubmitted ? t(`Back to login`) : t(`Submit`)}
                    </button>
                </div>
            </>
        )
    }


    useEffect(() => {
        if (authenticated) {
            dispatch(setLoginModalOpen(false))
        }
    }, [authenticated])

    return (
        <div className={`civic-login-modal`}>
            <Modal
                show={isLoginModalOpen}
                onHide={() => {
                    dispatch(setLoginModalOpen(false))
                }}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title className="w-100 text-center">
                        {isForgotPasswordModalOpen ? t(`Forgot Password`) : t(`Login`)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isForgotPasswordModalOpen ? forgotPasswordComponent() : loginComponent()}

                    {!isSubmitted && (<div className="form-group col-md-12">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    color: 'rgb(61 61 151)'
                                }}
                                onClick={toggleForgotPassword}>
                                    {isForgotPasswordModalOpen ? t(`Back to login`) : t(`Forgot your password?`)}
                            </span>
                        </div>
                    </div>)}
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        loginObject: state.login,
        formErrors: state.login.formErrors,
        authenticated: state.authentication.authenticated,
        isLoginModalOpen: state.registration.isLoginModalOpen,
        forgotFormData: state.forgotPassword.forgotFormData,
        forgotFormErrors: state.forgotPassword.formErrors,
        forgotIsFormSubmitting: state.forgotPassword.isFormSubmitting
    };
};

export default connect(mapStateToProps, actions)(Login);
