/**
 *
 * Topic List
 *
 */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const TopicList = (props) => {
  const { engagement, location, reloadCivicEngagement, user } = props;
  const history = useHistory();
  const uri = location.pathname.replace(/^\/|\/$/g, '');

  const navigateToTopic = (id, value) => {
    history.push(
      '/dashboard/civicfeed/?topicId=' +
        id +
        '&topic=' +
        encodeURIComponent(value),
    );
    if (uri === 'dashboard/civicfeed') {
      reloadCivicEngagement();
    }
  };

  const [topicFollowStatus, setTopicFollowStatus] = useState([]);

  useEffect(() => {
    if (typeof user.userFollowingTopics !== 'undefined') {
      if (user.userFollowingTopics.length > 0) {
        let topicsList = user.userFollowingTopics
          .map((value) => value.id)
          .filter((value) => value !== null);
        setTopicFollowStatus(topicsList);
      }
    }
  }, [user, setTopicFollowStatus]);

  const isFollow = (topicId) => {
    if (topicFollowStatus.indexOf(parseInt(topicId)) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {engagement.topic.length > 0 ? (
        <div className="clsbadge-tag-list mt-2 mb-2">
          <ul>
            {engagement.topic.map((topic, index) => (
              <li
                key={index}
                onClick={() => {
                  navigateToTopic(topic.id, topic.value);
                }}
              >
                <span className="badge clslight-grey-badge">
                  {
                    user.selected_language === topic.originalLanguage ?
                      topic.value
                    :
                      (JSON.parse(topic.translation)?.[user.selected_language]?.name) ?
                        JSON.parse(topic.translation)?.[user.selected_language]?.name
                      :
                        topic.value
                  }
                  {isFollow(topic.id) === true ? (
                    <img
                      src={
                        require('../../../assets/img/follow-tick.svg').default
                      }
                      className="ml-1"
                      alt="Follow"
                    />
                  ) : (
                    ''
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TopicList;
