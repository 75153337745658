import React from "react";
import {connect} from "react-redux";
import actions from "../../../actions";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";

const ResidentStepFour = (props) => {

    const {
        registration,
        registerChange,
        updateUserData,
        verifyUserRegistration,
        registrationFormErrors,
        showCloseButton
    } = props;

    const {t} = useTranslation();

    const handleOnClick = () => {
        updateUserData().then((response) => {
            if (response) {
                verifyUserRegistration();
            }
        });
    }

    const userFirstName = registration.registerFormData.firstName;

    return <>
        <Modal.Header closeButton={showCloseButton}>
            <Modal.Title className="w-100 text-center">
                {registration.registerFormData.telesignVerified ?
                    <span>{t(`You're not`)} {userFirstName}?</span> :
                    <span>{t(`Just one last step!`)}</span>
                }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group col-md-12">
                {registration.registerFormData.telesignVerified ?
                    'We apologize for the confusion. Please let us know your name below. Your name will be displayed when you want to comment or engage with other residents or officials on the platform.':
                    'If you want to comment or engage with other residents and officials on the platform, please provide your full name.'
                }
            </div>

            <div className="form-group col-md-12">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t(`First Name`)}
                            name={'firstName'}
                            value={registration.registerFormData.firstName}
                            onChange={(e) => registerChange(e.target.name, e.target.value)}
                            autoComplete={'no'}
                            id="regevent_firstName"
                        />
                        {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.firstName && (
                            <div className="clsinvalid">{t(registrationFormErrors?.['firstName'])}</div>)}

                    </div>

                    <div className="form-group col-md-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t(`Last Name`)}
                            name={'lastName'}
                            value={registration.registerFormData.lastName}
                            onChange={(e) => registerChange(e.target.name, e.target.value)}
                            autoComplete={'no'}
                            id="regevent_lastName"
                        />
                        {typeof registrationFormErrors !== 'undefined' && registrationFormErrors.lastName && (
                            <div className="clsinvalid">{t(registrationFormErrors?.['lastName'])}</div>)}

                    </div>
                </div>
            </div>

            <div className="form-group col-md-12 align-center">
                <button
                    type="button"
                    id="regevent_update_name"
                    className="btn clsyellow-btn"
                    onClick={handleOnClick}
                    style={{
                        color: 'black',
                        fontWeight: 'bold'
                    }}>{t(`Return to CivicFeed`)}
                </button>
            </div>

            <div className="form-group col-md-12">
                {t(`You can adjust the visibility of your name later in the settings:`)}
            </div>

            <div className="form-group col-md-12">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <span>Jane Smith</span><br/>
                        <span>Jane S.</span><br/>
                        <span>J.S.</span>
                    </div>
                    <div className="form-group col-md-6">
                        <span>{t(`Full Name`)}</span><br/>
                        <span>{t(`First Name, Initial Last Name`)}</span><br/>
                        <span>{t(`Just Initials`)}</span>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </>;
}

const mapStateToProps = (state) => {
    return {
        registration: state.registration,
        registrationFormErrors: state.registration.formErrors
    };
};

export default connect(mapStateToProps, actions)(ResidentStepFour);
