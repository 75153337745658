import React from 'react';
import CivicModal from '../CivicModal';
import { useTranslation } from 'react-i18next';

const LinkedNotification = (props) => {
  const linkedInUrl = 'https://www.linkedin.com/';
  const { t } = useTranslation();
  const { targetId, shareUrl } = props;

  return (
    <div className={`civic-filter-modal`}>
      <CivicModal
        id={`linkedin-modal-${targetId}`}
        title={`LinkedIn CopyURL`}
        dismissal={false}
      >
        <div className="form-group">
          <p className="text-center">
            {t(`You will be redirected to LinkedIn. The link `)}
            {` ${shareUrl}`}{' '}
            {t(`to this post has been copied to your clipboard.`)}
          </p>
        </div>
        <div className="signin-petition">
          <a
            href={linkedInUrl}
            className="btn clsanchor-btn clsyellow-btn linkedin-class"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(`Continue to LinkedIn`)}
          </a>
          <button
            type="button"
            className="btn clsyellow-btn"
            data-dismiss="modal"
          >
            {t(`Cancel`)}
          </button>
        </div>
      </CivicModal>
    </div>
  );
};

export default LinkedNotification;
