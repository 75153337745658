import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { motion } from "framer-motion"
import FormHelperText from '@mui/material/FormHelperText';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useTranslation } from 'react-i18next';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDropzone } from 'react-dropzone'
import heic2any from "heic2any";

import DrawAreaMap from './DrawAreaMap';
import { InsuranceIcon, MouseClickIcon } from './icons';
import LexicalEditor from '../../Common/LexicalEditor';
import SelectOption from '../../Common/SelectOption';
import Tooltip from "../../Common/Tooltip";
import { handleDocumentDownload } from './CommunityIssueSlider';

const CreateEditIssueControls = ({children}) => {
  return (
    <div>
      {children}
    </div>
  );
};

const Title = ({
  title,
  setTitle,
  error,
  animate = {
    isExpanded: true,
    setIsExpanded: () => {},
    containerWidth: 0,
    buttonWidth: 0
  },
}) => {
  const { t } = useTranslation();
  const { isExpanded, setIsExpanded, containerWidth, buttonWidth } = animate;

  return (
    <>
      <motion.input
        type="text"
        className="form-control"
        placeholder={t("A short title for your issue (e.g. Graffiti on Elm Street)")}
        animate={{ width: isExpanded ? '100%' : containerWidth - buttonWidth - 50  }}
        style={{ width: containerWidth > 0 ? containerWidth - buttonWidth - 50 : '100%' }}
        transition={{ bounce: 0, duration: isExpanded ? 0.2 : 0 }}
        onClick={() => setIsExpanded(true)}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        />
      <FormHelperText error>{error}</FormHelperText>
    </>
  );
};

const Description = ({
  setDescription,
  error,
  reloadToggle,
  animate = {
    isExpanded: true,
  },
  initialHtml = '',
}) => {
  const { isExpanded } = animate;
  
  return (
    <>
      <div style={{ height: 20 }} />
      <LexicalEditor 
        displayColor={false} 
        displayScripts={false}
        onHtmlChange={(html) => setDescription(html)}
        reloadToggle={reloadToggle}
        initialHtml={initialHtml}
      />
      <div style={{ height: isExpanded ? 10 : 50 }} />
      <FormHelperText error>{error}</FormHelperText>
    </>
  )
};

const CategorySelect = ({ 
  category, 
  setCategory, 
  error,
}) => {
  const { t } = useTranslation();
  const allTopics = useSelector((state) => state.communityIssues.allTopics);
  
  return (
    <>
      <div className="form-row">
        <div className="col-md-12">
          <SelectOption
            options={Object.values(allTopics).map(topic => ({ value: topic?.topicId, label: topic?.name }))}
            placeholder={t(`Select an issue category`)}
            name="category"
            value={category}
            handleSelectChange={(value) => setCategory(value)}
          />
        </div>
      </div>
      <div style={{ height: 30 }}>
        <FormHelperText error>{error}</FormHelperText>
      </div>
    </>
  )
};

const ClosingDatePicker = ({ 
  closeDate, 
  setCloseDate, 
  defaultCloseDate,
  defaultHasDateChanged = false,
}) => {
  const { t } = useTranslation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [hasDateChanged, setHasDateChanged] = useState(defaultHasDateChanged);

  return (
    <div className="community-issues-close">
      <div style={{ display: 'flex' }}>
        <CalendarTodayIcon />
        {!hasDateChanged 
          ? <p>{t(`Your issue stays open for`)} <strong>{t(`90 days`)}</strong> {t(`by default`)}</p>
          : <p>{t(`Your issue will stay open until`)} <strong>{closeDate.format('MMMM D, YYYY')}</strong>.</p>
        }
        &#160;
        <div className='icon'>
          <img
            src={require('../../../assets/img/info-orange.svg').default}
            alt="Unsigned Icon"
            id={`your-issue-stays-open-icon`}
          />
          <Tooltip
            targetId={`your-issue-stays-open-icon`}
            message={t(`After your issue closes, we will automatically inform all government officials serving the district you specified about this Community Issue.`)}
            parentDivClass={'icon'}
            iconDisplay={false}
            targetStatus={true}
            style={{
              cursor: 'default',
            }}
          />
        </div>
      </div>

      <button className="community-issues-btn" onClick={() => setIsDatePickerOpen(true)}>Change</button>
      <MobileDatePicker 
        slotProps={{
          field: { className: 'community-issues-date-field' }
        }}
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        defaultValue={defaultCloseDate}
        value={closeDate}
        onChange={(date) => {
          setCloseDate(date);
          setHasDateChanged(true);
        }}
      />
    </div>
  )
};

const SelectAreaOrDistrict = ({ 
  selectedAreaOption,
  setSelectedAreaOption, 
  disabled = false 
}) => {
  const { t } = useTranslation();

  return (
    <div>
        <div className='your-issue-community-container'>
          {t(`Your Issue Community`)}
          <div className='icon'>
            <img
              src={require('../../../assets/img/info-orange.svg').default}
              alt="Unsigned Icon"
              id={`your-issue-community-icon`}
            />
            <Tooltip
              targetId={`your-issue-community-icon`}
              message={t(`All residents from the district or area you specify will be eligible to sign your issue and participate in the discussion on finding solutions.`)}
              parentDivClass={'icon'}
              iconDisplay={false}
              targetStatus={true}
              style={{
                cursor: 'default',
              }}
            />
          </div>
        </div>
        <RadioGroup 
          row 
          value={selectedAreaOption} 
          onChange={(e) => { 
            setSelectedAreaOption(e.target.value); 
          }}
        >
          <FormControlLabel 
            value="ocdId"
            control={<Radio />} 
            label={t(`Select from your districts`)} 
            componentsProps={{ typography: { fontSize: 14 } }}
            disabled={disabled}
          />
          <FormControlLabel 
            value="area" 
            control={<Radio />} 
            label={t(`Select area on a map`)} 
            componentsProps={{ typography: { fontSize: 14 } }} 
            disabled={disabled}
          />
        </RadioGroup>
    </div>
  )
};

const DrawArea = ({ error, setError, reloadToggle, invalidateSize, setCoordinates }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="community-issues-area-explanation">
        <MouseClickIcon />

        <div style={{ marginLeft: 10 }}>
          <div>{t(`Using your mouse, you can create a polygon by clicking on the map. The area you start your issue in needs to include your residential address.`)}</div>
          <div className="community-issues-area-explanation-box">
            <InsuranceIcon />
            <span>{t(`Your address won't be shared with anyone.`)}</span>
          </div>
        </div>
      </div>
      <DrawAreaMap 
        invalidateSize={invalidateSize} 
        onCoordinatesChange={setCoordinates} 
        reloadToggle={reloadToggle}
        setError={setError}
      />
      <div style={{ height: 30 }}>
        <FormHelperText error>{error}</FormHelperText>
      </div>
    </>
  )
};

const DistrictSelect = ({ 
  district, 
  setDistrict, 
  error,
  defaultValue,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const myDistricts = useSelector((state) => state.account.myDistricts);

  return (
    <div className="form-row">
      <div className="col-md-12">
        <SelectOption
          options={Object.values(myDistricts).map(district => ({ value: district?.ocdId, label: district?.name }))}
          placeholder={t(`Select a district`)}
          name="district"
          value={district}
          handleSelectChange={(value) => setDistrict(value)}
          menuOptions={{
            marginBottom: 30,
            paddingBottom: 30
          }}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
      <div style={{ height: 100 }}>
        <FormHelperText error>{error}</FormHelperText>
      </div>
    </div>
  )
};

const baseStyle = {
  transition: 'border .3s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const UploadMainImage = ({
  files,
  setFiles,
  error,
  initialURL,
  setMainImageURL,
  displayImagePreview = false,
  animate = {
    isExpanded: true,
    containerWidth: 0,
    buttonWidth: 0
  },
}) => {
  const { isExpanded, containerWidth, buttonWidth } = animate;

  const onDrop = useCallback(async (acceptedFiles) => {
    let processedFiles = [];
    for (const file of acceptedFiles) {
      if (file.type === 'image/heic') {
        try {
          const jpegBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5,
          });
          const newFile = new File([jpegBlob], file.name.replace(/\.[^/.]+$/, ".jpeg"), {
            type: "image/jpeg",
          });
          processedFiles.push(newFile);
        } catch (e) {
          console.error(e);
        }
      } else {
        processedFiles.push(file);
      }
    }
    setFiles(processedFiles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { 
    getRootProps, 
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({ onDrop, maxFiles: 1, accept: 'image/*' });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject],
  );
  const { t } = useTranslation();
  
  return (
    <>
      <motion.div 
        className="community-issues-upload"
        animate={{ width: isExpanded ? '100%' : containerWidth - buttonWidth - 50  }}
        style={{ width: containerWidth > 0 ? containerWidth - buttonWidth - 50 : '100%', ...style }}
        transition={{ bounce: 0, duration: isExpanded ? 0.2 : 0 }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {files.length === 0 && (<>
          <p>{t('Upload a main image for your issue')}</p>
          <p>{t('Drag & Drop or')}{' '}<span style={{ color: '#FFAA32' }}>{t('Browse')}</span></p>
        </>)}
        {files.length > 0 && (<>
          <p>{files[0].name}</p>
          <p>{t('Drag & Drop or')}{' '}<span style={{ color: '#FFAA32' }}>{t('Browse')}</span></p>
        </>)}
      </motion.div>
      {displayImagePreview && files.length > 0 && (
        <div className="community-issues-upload-preview-container">
          <button className="remove-file-btn" onClick={() => setFiles([])}>X</button>
          <img src={URL.createObjectURL(files[0])} alt={files[0].name} className="community-issues-upload-preview-img" />
        </div>
      )}
      {displayImagePreview && initialURL && files.length === 0 && (
        <div className="community-issues-upload-preview-container">
          <button className="remove-file-btn" onClick={() => setMainImageURL(null)}>X</button>
          <img src={initialURL} alt={initialURL} className="community-issues-upload-preview-img" />
        </div>
      )}
      <FormHelperText error>{error}</FormHelperText>
    </>
  )
};

const UploadAdditionalFiles = ({
  files,
  setFiles,
  error,
  initialURLs,
  setAdditionalImageURLs
}) => {
  const { t } = useTranslation();

  const onDrop = useCallback(async (acceptedFiles) => {
    let processedFiles = [];
    for (const file of acceptedFiles) {
      if (file.type === 'image/heic') {
        try {
          const jpegBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5,
          });
          const newFile = new File([jpegBlob], file.name.replace(/\.[^/.]+$/, ".jpeg"), {
            type: "image/jpeg",
          });
          processedFiles.push(newFile);
        } catch (e) {
          console.error(e);
        }
      } else {
        processedFiles.push(file);
      }
    }
    setFiles((f) => [...f, ...processedFiles]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropzoneAccept = 'image/*,application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const { 
    getRootProps, 
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({ onDrop, accept: dropzoneAccept, multiple: true });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject],
  );
  
  return (
    <>
      <div style={{ marginBottom: 10 }}>{t(`Attach additional images or documents (pdf, docx, xlsx) (optional)`)}</div>
      <div 
        className="community-issues-upload"
        style={{ width: '100%', ...style }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>{t('Drag & Drop or')}{' '}<span style={{ color: '#FFAA32' }}>{t('Browse')}</span></p>
      </div>
      <FormHelperText error>{error}</FormHelperText>
      <div className="community-issues-upload-preview">
        {initialURLs && initialURLs?.map((url, index) => {
          if (['doc', 'docx', 'pdf', 'xls', 'xlsx'].some((ext) => url.split('.')[url.split('.').length-1].includes(ext))) {
            return (
              <div 
                className="community-issues-upload-preview-img community-issues-upload-preview-doc"
                key={index} 
                onClick={() => handleDocumentDownload(url)}
                style={{ cursor: 'pointer' }}
              >
                <div className="pdf-icon"></div>
                <div className="filename">...{url.slice(-30)}</div>
                <button className="remove-file-btn" onClick={() => setAdditionalImageURLs(initialURLs.filter((_, i) => i !== index))}>X</button>
              </div>
            )
          }
          
          return (
            <div key={url} style={{ position: 'relative', borderRadius: 20 }}>
              <img src={url} alt={url} className="community-issues-upload-preview-img" />
              <button className="remove-file-btn" onClick={() => setAdditionalImageURLs(initialURLs.filter((_, i) => i !== index))}>X</button>
            </div>
          );
        })}
        {files.map((file, index) => {
          if (file.type.includes('application')) {
            return (
              <div className="community-issues-upload-preview-img community-issues-upload-preview-doc" key={index}>
                <div className="pdf-icon"></div>
                <div className="filename">{file.name.substring(0, 50)}</div>
                <button className="remove-file-btn" onClick={() => setFiles(files.filter((_, i) => i !== index))}>X</button>
              </div>
            );
          } else {
            return (
              <div key={index} style={{ position: 'relative', borderRadius: 20 }}>
                <img src={URL.createObjectURL(file)} alt={file.name} className="community-issues-upload-preview-img" />
                <button className="remove-file-btn" onClick={() => setFiles(files.filter((_, i) => i !== index))}>X</button>
              </div>
            );
          }
        })}
      </div>
      {files.length > 0 && <div style={{ height: 70 }} />}
    </>
  )
};



CreateEditIssueControls.Title = Title;
CreateEditIssueControls.Description = Description;
CreateEditIssueControls.CategorySelect = CategorySelect;
CreateEditIssueControls.ClosingDatePicker = ClosingDatePicker;
CreateEditIssueControls.SelectAreaOrDistrict = SelectAreaOrDistrict;
CreateEditIssueControls.DrawArea = DrawArea;
CreateEditIssueControls.DistrictSelect = DistrictSelect;
CreateEditIssueControls.UploadMainImage = UploadMainImage;
CreateEditIssueControls.UploadAdditionalFiles = UploadAdditionalFiles;

export default CreateEditIssueControls;