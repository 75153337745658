/*
 *
 * ForgotPassword
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import * as actions from './actions';
import Notification from '../Notification';
import { useTranslation } from 'react-i18next';

const ForgotPassword = (props) => {
  const history = useHistory();
  const {
    authenticated,
    forgotFormData,
    formErrors,
    forgotPasswordChange,
    forgotPassword,
    isFormSubmitting,
  } = props;
  const { t } = useTranslation();
  if (authenticated) return <Redirect to="/dashboard" />;

  const handleSubmit = (event) => {
    event.preventDefault();
    forgotPassword();
  };

  const navigateToLogin = (event) => {
    forgotPasswordChange('email', '');
    history.push('/login');
  };

  return (
    <div className="clslogin-form-blk pb-5 mb-5">
      <div className="clslogin-form clswhite-box mt-4 pt-3">
        <div className="tabbable-panel">
          <h5 className="fs-1 text-center pb-5" style={{ fontSize: '18px' }}>
            {t(`Forgot Password`)}
          </h5>
          <div className="form-group">
            <p>
              {t(
                `Forgot your account’s password or having trouble logging in? Enter your email address and we’ll send you a recovery link.`,
              )}
            </p>
          </div>
          <Notification module={'forgotPassword'} />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder={t(`Email`)}
                name={'email'}
                value={forgotFormData.email}
                onChange={(e) =>
                  forgotPasswordChange(e.target.name, e.target.value)
                }
              />
              {typeof formErrors !== 'undefined' && formErrors.email && (
                <div className="clsinvalid">{t(formErrors?.['email'])}</div>
              )}
            </div>
            <div className="text-center">
              <button
                type="button"
                onClick={navigateToLogin}
                className="btn clsyellow-btn"
              >
                {t(`Cancel`)}
              </button>{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="submit"
                className="btn clsyellow-btn"
                disabled={isFormSubmitting}
              >
                {t(`Submit`)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    forgotFormData: state.forgotPassword.forgotFormData,
    formErrors: state.forgotPassword.formErrors,
    isFormSubmitting: state.forgotPassword.isFormSubmitting,
    notifications: state.forgotPassword.notifications,
  };
};

export default connect(mapStateToProps, actions)(ForgotPassword);
