/*
 *
 * Verification Step
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const VerifyStep = (props) => {
  const { step } = props;
  const { t } = useTranslation();
  const hashUrl = '#';

  return (
    <>
      <h3 className="text-center pb-3">{t(`Welcome to CivicBell!`)}</h3>
      <div className="clsform-steps-blk mb-3">
        <ul>
          <li>
            <a href={hashUrl} className={step === 1 ? `clsactive` : ''}>
              <span>1</span> <span>{t(`Set Up Profile`)}</span>
            </a>
          </li>
          <li>
            <a href={hashUrl} className={step === 2 ? `clsactive` : ''}>
              <span>2</span> <span>{t(`Verify`)}</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default VerifyStep;
