/**
 *
 * Not Found Page For Petition Module
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const NoPetition = (props) => {
  const { t } = useTranslation();

  return (
    <div className={'mb-3 no-engagement'}>
      <span className={'sad-img'}>
        <img
          src={require('../../../assets/img/sad.svg').default}
          alt="No Petitions"
        />
      </span>
      <span className="no-engagement-txt">{t('Data not found!')}</span>
    </div>
  );
};

export default NoPetition;
