/**
 *
 * Footer
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  const hashUrl = '#';
  return (
    <footer className="clsmain-footer">
      <div className="container">
        <div className="clsdashboard-footer-mobile mt-4">
          <p className="footer-text mb-2">
            {typeof user !== 'undefined' ? (
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target="#report-an-issue"
              >
                {' '}
                <img
                  src={require('../../../assets/img/issue.svg').default}
                  alt="Issue"
                />
                {t(`Report an Issue`)}
              </a>
            ) : (
              ''
            )}{' '}
            © {new Date().getFullYear()} {t(`CivicBell LLC.`)}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
