/**
 *
 * Discussion Engagement Form Footer
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import CommentBox from './commentBox';
import { useTranslation } from 'react-i18next';

const DiscussionFooter = (props) => {
  const {
    engagement,
    discussionEngagementData,
    submitComment,
    discussionEngagementChange,
    type,
    user,
    isViewMoreCommentsToggled,
    setIsViewMoreCommentsToggled,
  } = props;
  const { t } = useTranslation();

  const isIconDisabled = (!user.isVerified || !user.isIdentityVerified || !user.isRegistrationComplete);

  return (
    <>
      {
        !isIconDisabled &&
          <div className="clspost-box">
            <CommentBox
              id={engagement.id}
              commentData={discussionEngagementData[engagement.id]}
              changePostComment={discussionEngagementChange}
              submitComment={submitComment}
              key={`parent-comment-reply-box-${engagement.id}`}
              user={user}
              canReact={engagement.canReact}
              restrictionReason={engagement.restriction_reason}
              discussionBoardEnabled={engagement.discussion_board}
              setIsViewMoreCommentsToggled={setIsViewMoreCommentsToggled}
            />
            {type === 'Modal' ? (
              <div className="text-center">
                <button
                  type="button"
                  className="btn clsyellow-btn discussion-close"
                  data-dismiss="modal"
                >
                  {t(`Close`)}
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
      }
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    discussionEngagementData:
      state.discussionEngagement.discussionEngagementData,
  };
};

DiscussionFooter.defaultProps = {
  type: 'Modal',
};

export default connect(mapStateToProps, actions)(DiscussionFooter);
