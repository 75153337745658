/*
 *
 * PushNotification actions
 *
 */

import {
  FETCH_READ_NOTIFICATION,
  FETCH_UNREAD_NOTIFICATION,
  NOTIFICATION_CHANGE,
  NOTIFICATION_LOADING,
  RESET_APP,
} from './constants';
import axios from 'axios';
import { BASE_API_URL } from '../../constants';

export const resetPushNotificationState = () => {
  return async (dispatch, getState) => {
    return dispatch({ type: RESET_APP });
  };
};

export const notificationChange = (formData) => {
  return async (dispatch, getState) => {
    return dispatch({ type: NOTIFICATION_CHANGE, payload: formData });
  };
};

export const getUnReadNotification = (page = 1, limit = 2) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      if (page === 1) {
        formData['notificationUnReadList'] = [];
        dispatch(notificationChange(formData));
      }
      let unReadNotification = `/users/notifications?page=${page}&notified=${false}&receiver=${'push'}`;

      await axios
        .get(BASE_API_URL + unReadNotification)
        .then((response) => {
          if (response.data.success === 'true') {
            if (getState().pushnotification.notificationUnReadList.length > 0) {
              if (page !== 1) {
                dispatch({
                  type: FETCH_UNREAD_NOTIFICATION,
                  payload: response.data.data,
                });
              }
            } else if (
              page === 1 &&
              getState().pushnotification.notificationUnReadList.length === 0
            ) {
              dispatch({
                type: FETCH_UNREAD_NOTIFICATION,
                payload: response.data.data,
              });
            }
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const getReadNotification = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let formData = {};
      formData['notificationReadList'] = [];
      dispatch(notificationChange(formData));

      let readNotification = `/users/notifications?notified=${true}&receiver=${'push'}`;

      await axios
        .get(BASE_API_URL + readNotification)
        .then((response) => {
          if (response.data.success === 'true') {
            dispatch({
              type: FETCH_READ_NOTIFICATION,
              payload: response.data.data,
            });
            return resolve(true);
          }
        })
        .catch((error) => {
          return resolve(false);
        });
    });
  };
};

export const updateNotification = (notification, updateState = true) => {
  return async (dispatch, getState) => {
    const notifiedOption = {
      notified: true,
    };
    await axios
      .patch(
        BASE_API_URL +
          '/users/notifications/?notificationId=' +
          notification.notificationId,
        notifiedOption,
      )
      .finally(() => {
        dispatch({ type: NOTIFICATION_LOADING, payload: false });
        
        // Commented out due to BUG-127
        // dispatch(getUnReadNotification());
        // dispatch(getReadNotification());         
      });
  };
};

export const clearLinkedNotifications = (notification) => {
  return async (dispatch, getState) => {
    const notifiedOption = {
      notified: true,
    };
    await axios
      .patch(
        BASE_API_URL +
          '/users/notifications/clearLinkedNotifications/?notificationId=' +
          notification.notificationId,
        notifiedOption,
      )
      .finally(() => {
        dispatch({ type: NOTIFICATION_LOADING, payload: false });
        
        // Commented out due to BUG-127
        // dispatch(getUnReadNotification());
        // dispatch(getReadNotification());         
      });
  };
}

export const clearNotification = () => {
  return async (dispatch, getState) => {
    let readNotification = getState().pushnotification.notificationReadList;
    let unReadNotification = getState().pushnotification.notificationUnReadList;

    if (readNotification.length > 0 || unReadNotification.length > 0) {
      dispatch({ type: NOTIFICATION_LOADING, payload: true });
      await axios
        .delete(BASE_API_URL + '/users/notifications/clearAllNotifications')
        .then((response) => {
          dispatch(getUnReadNotification());
        })
        .catch((error) => {})
        .finally(() => {
          dispatch({ type: NOTIFICATION_LOADING, payload: false });
          dispatch(getUnReadNotification());
          dispatch(getReadNotification());
        });
    }
  };
};
