import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import Tooltip from "../../Common/Tooltip";
import CivicModal from '../../Common/CivicModal';
import CloseCommunityIssueEarlyConfirmationModal from './CloseCommunityIssueEarlyConfirmationModal';
import DeleteCommunityIssueConfirmationModal from './DeleteCommunityIssueConfirmationModal';
import SignatoriesModal from './SignatoriesModal';
import EditIssue from './EditIssue';
import { useHistory } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import moment from 'moment-timezone';
import { getCommunityIssueSignees } from '../../../containers/CommunityIssues/actions';
import {
  setIsSignUpButtonClicked,
  setRegistrationModalOpen
} from '../../../containers/RegisterFlow/actions';
import { languageMap } from '../../Common/LanguageSelect';

const CommunityIssueEngagementHeader = (props) => {
  const {
    communityIssueId,
    isSignThisCommunityIssueModalOpen,
    setIsSignThisCommunityIssueModalOpen,
    showOriginalLanguage,
    setShowOriginalLanguage,
    user,
    authenticated,
    allCommunityIssues,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hashUrl = '#';

  const communityIssue = allCommunityIssues[communityIssueId];
  const [isCommunityIssueOpen, setIsCommunityIssueOpen] = useState(true);
  useEffect(() => {
    const currentDateTime = new Date(moment
      .utc(new Date())
      .tz(moment.tz.guess())
      .format('YYYY-MM-DD HH:mm:ss'));
    const t1 = currentDateTime.getTime();
    const t2 = new Date(communityIssue?.closingDate).getTime();
    setIsCommunityIssueOpen((t1 - t2 < -20000)); // .getTime() is inaccurrate; need some offset
  }, [communityIssue]);

  const [isEditIssueModalOpen, setIsEditIssueModalOpen] = useState(false);
  const [editRefresh, setEditRefresh] = useState(0); // Triggering child component function from parent component

  const UnsignedIcon = ({ id }) => {
    return (
      <div className='icon'>
        <img
          src={require('../../../assets/img/info-blue.svg').default}
          alt="Unsigned Icon"
          id={`unsigned-icon-${id}`}
        />
        <Tooltip
          targetId={`unsigned-icon-${id}`}
          message={t(`Signing this issue will illustrate to elected officials you support this cause. Only your name is linked to this issue. Neither`) + ' ' + `${communityIssue.user.showMyNameAs}` + ' ' + t('nor any elected official will receive any more information about you.')}
          parentDivClass={'icon'}
          iconDisplay={false}
          targetStatus={true}
          style={{
            cursor: 'default',
          }}
        />
      </div>
    )
  }

  const IneligibleIcon = ({ id }) => {
    return (
      <div className='icon'>
        <img
          src={require('../../../assets/img/info-grey-dark.svg').default}
          alt="Ineligible Icon"
          id={`ineligible-icon-${id}`}
        />
        <Tooltip
          targetId={`ineligible-icon-${id}`}
          message={t('This community issue is outside any of your districts. You can only engage on issues being discussed in your areas.')}
          parentDivClass={'icon'}
          iconDisplay={false}
          targetStatus={true}
          style={{
            cursor: 'default',
          }}
        />
      </div>
    )
  }

  const GetCommunityIssueStatusInfo = ({ communityIssue, id }) => {
    if (!authenticated && !(user?.geoId)) {
      return (
        <div className={'stage unsigned register'}>
          <div className='col-left'>
            <img
              src={require('../../../assets/img/document-icon.svg').default}
              alt="Document Icon"
            />
            {
              <a
                style={{
                  color: 'inherit'
                }}
                onClick={() => {
                  dispatch(setIsSignUpButtonClicked(true));
                  dispatch(setRegistrationModalOpen(true));
                }}
              >
                {t('Register to sign')}
              </a>
            }
          </div>
          <div className='col-right'>
            <></>
          </div>
        </div>
      )
    }
    if (!communityIssue.isInArea && communityIssue.userId !== user.userId) { // TODO: Temp fix for BUG-247
      return (
        <div className={'stage ineligible'}>
          <div className='col-left'>
            <img
              src={require('../../../assets/img/document-icon.svg').default}
              alt="Document Icon"
            />
            {
              <p
              >
                {t('Ineligible to sign')}
              </p>
            }
          </div>
          <div className='col-right'>
            <IneligibleIcon id={id} />
          </div>
        </div>
      )
    }
    else if (!authenticated) {
      return (
        <div className={'stage unsigned register'}>
          <div className='col-left'>
            <img
              src={require('../../../assets/img/document-icon.svg').default}
              alt="Document Icon"
            />
            {
              <a
                style={{
                  color: 'inherit'
                }}
                onClick={() => {
                  dispatch(setIsSignUpButtonClicked(true));
                  dispatch(setRegistrationModalOpen(true));
                }}
              >
                {t('Register to sign')}
              </a>
            }
          </div>
          <div className='col-right'>
            <></>
          </div>
        </div>
      )
    }
    else if (communityIssue.hasUserSigned) {
      return (
        <div className={'stage signed'}>
          <div className='col-left'>
            <img
              src={require('../../../assets/img/document-icon.svg').default}
              alt="Document Icon"
            />
            {
              <p
              >
                {t('You signed')}
              </p>
            }
          </div>
          <div className='col-right'>
            <></>
          </div>
        </div>
      )
    } else {
      return (
        <div className={'stage unsigned'}>
          <div className='col-left'>
            <img
              src={require('../../../assets/img/document-icon.svg').default}
              alt="Document Icon"
            />
            {
              <a
                href={hashUrl}
                // data-toggle="modal"
                // data-target={`#sign-community-issue-${communityIssueId}`}
                style={{
                  color: 'inherit'
                }}
                onClick={() => {
                  setIsSignThisCommunityIssueModalOpen(true);
                }}
              >
                {t('Sign Now')}
              </a>
            }
          </div>
          <div className='col-right'>
            <UnsignedIcon id={id} />
          </div>
        </div>
      )
    }
  }

  const CommunityIssueStatus = ({ communityIssue }) => {
    const id = communityIssue.communityIssueId;
    return <GetCommunityIssueStatusInfo communityIssue={communityIssue} id={id} />
  }

  return (
    <div className='community-issues-engagement-header-container'>
      <div className='community-issues-engagement-header'>
        <div
          className='col-left'
          style={{
            alignItems: 'center'
          }}
        >
          {
            communityIssue.numSignees === 0 ?
              <p className='reg-text'>
                {t('No one has signed yet.')}
              </p>
              :
              <p className='profile-number'>
                {communityIssue.numSignees}{' '}{t('want to discuss this issue')}
              </p>
          }
          {
            ((communityIssue.numSignees > 0 && communityIssue.hasUserSigned) || (user.roleName !== 'RESIDENT' && (communityIssue.isInGeographicArea || communityIssue.isInDistrict))) &&
            <a
              className='text-btn'
              href={hashUrl}
              data-toggle="modal"
              data-target={`#community-issue-signatories-${communityIssueId}`}
              onClick={() => {
                dispatch(getCommunityIssueSignees(communityIssueId));
              }}
            >
              {t('View')}
            </a>
          }
        </div>
        <div className='col-right'>
          {
            (isCommunityIssueOpen === true) && (user.roleName === 'RESIDENT' || !authenticated) ?
              <CommunityIssueStatus communityIssue={communityIssue} />
              :
              <></>
          }
        </div>
      </div>
      {
        user.userId === communityIssue.userId ?
          <div
            className='edit-btn'
          >
            <button
              type="button"
              className="dropdown-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src={require('../../../assets/img/edit-orange.svg').default}
                alt="Edit Issue"
              />
            </button>
            <div
              className="dropdown-menu dropdown-menu-left community-issues-engagement-dropdown"
            >
              <button
                className="dropdown-item"
                type="button"
                href={`/community-issues/?id=${communityIssue.communityIssueId}`}
                onClick={() => {
                  history.push(`/community-issues/?id=${communityIssue.communityIssueId}`);
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    color: '#000000'
                  }}
                >
                  {t(`View`)}
                </p>
              </button>
              {
                isCommunityIssueOpen &&
                <button
                  className="dropdown-item"
                  type="button"
                  href={hashUrl}
                  data-toggle="modal"
                  data-target={`#edit-issue-${communityIssueId}`}
                  onClick={() => {
                    setIsEditIssueModalOpen(true);
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: '#000000'
                    }}
                  >
                    {t(`Edit`)}
                  </p>
                </button>
              }
              {
                <button
                  className="dropdown-item"
                  type="button"
                  href={hashUrl}
                  data-toggle="modal"
                  data-target={`#delete-issue-${communityIssueId}`}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: '#000000',
                    }}
                  >
                    {t(`Delete`)}
                  </p>
                </button>
              }
              {
                isCommunityIssueOpen &&
                <button
                  className="dropdown-item"
                  type="button"
                  href={hashUrl}
                  data-toggle="modal"
                  data-target={`#close-issue-${communityIssueId}`}
                >
                  <p
                    style={{
                      fontSize: 14,
                      color: '#000000',
                    }}
                  >
                    {t(`Close Early`)}
                  </p>
                </button>
              }
            </div>
          </div>
          :
          <div
            className='edit-btn'
          >
            <button
              type="button"
              className="dropdown-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src={require('../../../assets/img/more.svg').default}
                alt="Edit Issue"
              />
            </button>
            <div
              className="dropdown-menu dropdown-menu-left community-issues-engagement-dropdown"
            >
              <button
                className="dropdown-item"
                type="button"
                href={`/community-issues/?id=${communityIssue.communityIssueId}`}
                onClick={() => {
                  history.push(`/community-issues/?id=${communityIssue.communityIssueId}`);
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    color: '#000000'
                  }}
                >
                  {t(`View`)}
                </p>
              </button>
            </div>
          </div>
      }
      <div className='show-original-language-globe'>
        {(user.selected_language !== communityIssue?.originalLanguage) &&
          <div className="translation-tooltip">
            {communityIssue?.translation ?
              <img src={require('../../../assets/img/icon-language.svg').default} alt="Globe" /> :
              <img src={require('../../../assets/img/icon-language-crossed.svg').default} alt="Globe Crossed" />}
            <div className="translation-tooltiptext">
              {communityIssue?.translation ?
                <>{t(`This content was originally written in`) + '\u00A0' + `${t(languageMap?.[communityIssue?.originalLanguage]?.label)}.`}
                  <a href='https://civicl.ink/aitranslation'
                    target="_blank" rel="noreferrer">{'\u00A0' + t('Learn more about our AI-based language translation.')}</a>
                  <button className="translate-btn clsyellow-translate-btn"
                    onClick={() => {
                      setShowOriginalLanguage(!showOriginalLanguage);
                    }}
                  >
                    {showOriginalLanguage ? t('Revert to requested language') : t('Show in original language')}
                  </button>
                </> : <>{t('No translation exists for this Community Issue.')}</>}
            </div>
          </div>
        }
      </div>
      <CivicModal
        id={`community-issue-signatories-${communityIssueId}`}
        title={t(`Signatories`)}
        footerClass={`text-center mt-4`}
        modalDialogueClass={`clswidth-540`}
      >
        <SignatoriesModal communityIssueId={communityIssueId} />
      </CivicModal>
      <Modal
        show={isEditIssueModalOpen}
        onHide={() => {
          setIsEditIssueModalOpen(false);
        }}
        centered
        dialogClassName="community-issue-edit-modal"
      >
        <Modal.Header closeButton={true} />
        <Modal.Title style={{ textAlign: 'center' }}>{t('Edit Community Issue')}</Modal.Title>
        <Modal.Body>
          <EditIssue
            communityIssueId={communityIssueId}
            isOpen={isEditIssueModalOpen}
            setIsCommunityIssueOpen={setIsEditIssueModalOpen}
          />
        </Modal.Body>
      </Modal>
      <CivicModal
        id={`close-issue-${communityIssueId}`}
        title={t(`Close Community Issue`)}
        footerClass={`text-center mt-4`}
        modalDialogueClass={`clswidth-480`}
      >
        <CloseCommunityIssueEarlyConfirmationModal communityIssueId={communityIssueId} />
      </CivicModal>
      <CivicModal
        id={`delete-issue-${communityIssueId}`}
        title={t(`Delete Community Issue`)}
        footerClass={`text-center mt-4`}
        modalDialogueClass={`clswidth-480`}
      >
        <DeleteCommunityIssueConfirmationModal communityIssueId={communityIssueId} />
      </CivicModal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    authenticated: state.authentication.authenticated,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    myDistricts: state.account.myDistricts,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueEngagementHeader);