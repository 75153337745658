export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
export const GOOGLE_PLACES_API_KEY =
  process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
export const AWS_S3_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
export const AWS_S3_BUCKET_MAP_DIRECTORY_NAME =
  process.env.REACT_APP_AWS_S3_BUCKET_MAP_DIRECTORY_NAME;
export const IDME_SCOPE = process.env.REACT_APP_IDME_SCOPE;
export const IDME_CLIENT_ID = process.env.REACT_APP_IDME_CLIENT_ID;
export const IDME_SECRET_ID = process.env.REACT_APP_IDME_SECRET_ID;
export const IDME_REDIRECT_URL = process.env.REACT_APP_IDME_REDIRECT_URL;
export const IDME_BASE_URL = process.env.REACT_APP_IDME_BASE_URL;
export const IDME_RESPONSE = process.env.REACT_APP_IDME_RESPONSE;
export const IDME_VERIFY = process.env.REACT_APP_IDME_VERIFY;
export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
export const FIREBASE_VAPKEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
export const INVITE_URL = process.env.REACT_APP_INVITE_OTHERS_URL;
export const MAX_POST_CHAR_COUNT = 2000;
export const LIMIT_POST_CHAR_COUNT = 400;
