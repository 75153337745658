import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import ShareLink from './ShareLink';
import CommunityIssueSocialMediaIcons from './CommunityIssueSocialMediaIcons';

const YourCommunityIssueNotVisible = (props) => {
  const { t } = useTranslation();  
  const {
    communityIssueId,
    allCommunityIssues,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];

  return (
    <div className="your-community-issue-not-visible">
      <span className='title'>
        {t('Your Community Issue is not yet visible on the feed!')}
      </span>
      <div className='text-content'>
        <p>
          {t('We require your Community Issue to be')}{' '}
          <b>
            {t('signed by 2 residents')}
          </b>
          {' '}{t('before it’s visible on the main feed. Share your issue now among your friends!')}
        </p>
        <b>
          {t('Your issue is visible to anyone with the following link:')}
        </b>
      </div>
      <div className='share-link-container'>
        <ShareLink communityIssueId={communityIssueId} />
      </div>
      <CommunityIssueSocialMediaIcons communityIssueId={communityIssueId} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(YourCommunityIssueNotVisible);