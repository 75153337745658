/*
 *
 * Login
 *
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import actions from '../../actions';
import LoginForm from '../../components/Manager/LoginForm';
import RegisterForm from '../../components/Manager/RegisterForm';
import IndividualSecondStep from '../../components/Manager/RegisterForm/individualSecondStep';
import Onboarding from '../../components/Manager/RegisterForm/onboarding';
import VerifyUser from '../../components/Manager/RegisterForm/verification';
import { useTranslation } from 'react-i18next';

const Login = (props) => {
  const {
    authenticated,
    loginFormData,
    loginChange,
    login,
    formErrors,
    isLoading,
    isSubmitting,
    registerFormData,
    registerChange,
    fillAddress,
    register,
    registerFormErrors,
    isRegisterLoading,
    isRegisterSubmitting,
    activeTab,
    location,
    setTab,
    setPriviousStep,
    setNextStep,
    getDistrictData,
    districtBoxChange,
    countDown,
    resendEmailConfirmation,
    resendConfirmation,
  } = props;
  const { t } = useTranslation();
  const uri = location.pathname;

  useEffect(() => {
    setTab(uri.replace(/^\/|\/$/g, '') === 'register' ? 2 : 1);
  }, [setTab, uri]);

  if (authenticated) return <Redirect to="/dashboard" />;

  return (
    <div className="clslogin-form-blk pb-5 mb-5">
      <div
        className={`clslogin-form clsresident-form clswhite-box mt-4 pt-3 ${
          activeTab === 2 &&
          (registerFormData.step === 6 || registerFormData.step === 7)
            ? `clsresident-form-new`
            : ``
        }`}
      >
        <div className="tabbable-panel">
          <div className="tabbable-line">
            {activeTab === 2 && registerFormData.step === 3 ? (
              <Onboarding {...props} />
            ) : activeTab === 2 && registerFormData.step === 4 ? (
              <Onboarding {...props} />
            ) : activeTab === 2 && registerFormData.step === 5 ? (
              <Onboarding {...props} />
            ) : activeTab === 2 && registerFormData.step === 6 ? (
              <IndividualSecondStep {...props} />
            ) : activeTab === 2 && registerFormData.step === 7 ? (
              <VerifyUser {...props} />
            ) : (
              <>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      href="#login"
                      className={
                        activeTab === 1 ? 'nav-link active' : 'nav-link'
                      }
                      id="home-tab"
                      data-toggle="tab"
                      onClick={() => setTab(1)}
                      role="tab"
                      aria-controls="home"
                      aria-selected={activeTab === 1 ? true : false}
                    >
                      {t(`Log In`)}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      href="#register"
                      className={
                        activeTab === 2 ? 'nav-link active' : 'nav-link'
                      }
                      id="profile-tab"
                      data-toggle="tab"
                      onClick={() => setTab(2)}
                      role="tab"
                      aria-controls="profile"
                      aria-selected={activeTab === 2 ? true : false}
                    >
                      {t(`Register`)}
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <LoginForm
                    loginFormData={loginFormData}
                    loginChange={loginChange}
                    login={login}
                    formErrors={formErrors}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    activeTab={activeTab}
                    resendEmailConfirmation={resendEmailConfirmation}
                    resendConfirmation={resendConfirmation}
                  />
                  <RegisterForm
                    registerFormData={registerFormData}
                    registerChange={registerChange}
                    fillAddress={fillAddress}
                    register={register}
                    registerFormErrors={registerFormErrors}
                    isRegisterLoading={isRegisterLoading}
                    isRegisterSubmitting={isRegisterSubmitting}
                    activeTab={activeTab}
                    setPriviousStep={setPriviousStep}
                    setNextStep={setNextStep}
                    getDistrictData={getDistrictData}
                    districtBoxChange={districtBoxChange}
                    countDown={countDown}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authentication.authenticated,
    loginFormData: state.login.loginFormData,
    formErrors: state.login.formErrors,
    isLoading: state.login.isLoading,
    isSubmitting: state.login.isSubmitting,
    registerFormData: state.register.registerFormData,
    registerFormErrors: state.register.formErrors,
    isRegisterLoading: state.register.isRegisterLoading,
    isRegisterSubmitting: state.register.isRegisterSubmitting,
    activeTab: state.login.activeTab,
    countDown: state.application.countDown,
    resendConfirmation: state.login.resendConfirmation,
  };
};

export default connect(mapStateToProps, actions)(Login);
