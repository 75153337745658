/**
 *
 * Analytics component
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const AnalyticsBriefInfo = (props) => {
  const { analyticsData } = props;
  const { t } = useTranslation();

  return (
    <div className={`clsanalytics-brief-info-blk clswhite-box mt-4`}>
      <ul>
        <li>
          <div className={`clsanalytics-brief-info-box`}>
            <div className={`media`}>
              <img
                src={require('../../../assets/img/views.svg').default}
                className={`align-self-center mr-3`}
                alt="Views"
              />
              <div className={`media-body`}>
                <p>{t(`Views`)}</p>

                <div className="views">
                  <div>
                    <h5 className={`mt-0`}>
                      {typeof analyticsData.postViews !== 'undefined'
                        ? analyticsData.postViews + analyticsData.unverifiedViews
                        : 0}
                    </h5>
                    <span className={`unverified-views`}>{t(`Total`)}</span>
                  </div>

                  <div>
                    <h5 className={`mt-0`}>
                      {typeof analyticsData.postViews !== 'undefined'
                        ? analyticsData.postViews
                        : 0}
                    </h5>
                    <span className={`unverified-views`}>{t(`Verified residents`)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className={`clsanalytics-brief-info-box`}>
            <div className={`media`}>
              <img
                src={require('../../../assets/img/comments.svg').default}
                className={`align-self-center mr-3`}
                alt="Comments"
              />
              <div className={`media-body`}>
                <p>{t(`Comments`)}</p>
                <h5 className={`mt-0`}>
                  {typeof analyticsData.commentCount !== 'undefined'
                    ? analyticsData.commentCount
                    : 0}
                </h5>
              </div>
            </div>
          </div>
        </li>
        {analyticsData.category === 'survey' ? (
          <li>
            <div className={`clsanalytics-brief-info-box`}>
              <div className={`media`}>
                <img
                  src={require('../../../assets/img/responses.svg').default}
                  className={`align-self-center mr-3`}
                  alt="Responses"
                />
                <div className={`media-body`}>
                  <p>{t(`Survey Responses`)}</p>
                  <h5 className={`mt-0`}>
                    {typeof analyticsData.surveyResponses !== 'undefined'
                      ? analyticsData.surveyResponses
                      : 0}
                  </h5>
                </div>
              </div>
            </div>
          </li>
        ) : (
          ''
        )}
      </ul>
    </div>
  );
};

export default AnalyticsBriefInfo;
