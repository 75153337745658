/**
 *
 * Date Input
 *
 */

import React from 'react';
const TimeInput = ({
  onChange,
  placeholder,
  value,
  id,
  onClick,
  className,
}) => (
  <input
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    id={id}
    onClick={onClick}
    className={`form-control time-placeholder ${className}`}
    readOnly
  />
);

TimeInput.defaultProps = {
  className: '',
};

export default TimeInput;
