import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { MAX_POST_CHAR_COUNT, LIMIT_POST_CHAR_COUNT } from '../../../constants';

const CommunityIssueTextContent = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    showOriginalLanguage,
    allCommunityIssues,
    user,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeText, setActiveText] = useState(null);
  const postParagraph = useRef(null);

  const communityIssue = allCommunityIssues[communityIssueId];

  useEffect(() => {
    if (showOriginalLanguage || user.selected_langauge === communityIssue.originalLanguage || !(JSON.parse(communityIssue.translation)?.[user.selected_language])) {
      setActiveText({ __html: communityIssue.textContent });
    }
    else {
      setActiveText({ __html: JSON.parse(communityIssue.translation)?.[user.selected_language]?.textContent });
    }
  }, [communityIssue, user, isExpanded, showOriginalLanguage]);

  useEffect(() => {
    if (postParagraph.current) {
      const links = postParagraph.current.querySelectorAll('a');
      links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [postParagraph, activeText]);

  return (
    <>
      <p
        dangerouslySetInnerHTML={
          activeText
            ?
            (!isExpanded && activeText?.__html && activeText?.__html?.length > MAX_POST_CHAR_COUNT)
              ?
              { __html: activeText.__html.substring(0, LIMIT_POST_CHAR_COUNT) + '...' }
              :
              activeText
            :
            { __html: '' }
        }
        ref={postParagraph}
      ></p>
      {
        activeText && activeText?.__html?.length > MAX_POST_CHAR_COUNT &&
        <div>
          <a
            href={`#community-issue-show-more-`}
            data-target={`#community-issue-show-more-`}
            data-toggle="collapse"
            className="nav-toggle clsreply-link text-btn"
            id={`show-hide-link-`}
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            {
              isExpanded ?
                t('Read less')
                :
                t('Read more')
            }
          </a>
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    user: state.account.user,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueTextContent);