/*
 *
 * CivicFeed reducer
 *
 */

import {
  SET_CIVICFEED_LOADING,
  FETCH_CIVICFEED_ENGAGEMENTS,
  CIVICFEED_RELOAD_LIST,
  CIVICFEED_CHANGE,
  CIVICFEED_RESET,
  CIVICFEED_LIST_CHANGE,
  SET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  RESET_USER_PROFILE,
  RESET_APP,
} from './constants';

const initialState = {
  engagements: [],
  reloadList: false,
  isEngagementLoading: false,
  noRecords: false,
  pageLoader: false,
  sort: 'newest',
  filterDismissal: false,
  filter: {
    topics: {
      title: 'Topics',
      items: [],
    },
    locations: {
      title: 'Locations',
      items: [],
    },
    /*status: {
      title: 'Status',
      items: [{id: 'open', name: 'Open'}, {id: 'closed', name: 'Closed'}, {id: 'outcome', name: 'Outcome'}]
    }*/
    postType: {
      title: 'Post Type',
      items: [
        { id: 'post', name: 'Info' },
        { id: 'discussion', name: 'Discussion' },
        { id: 'survey', name: 'Survey' },
      ],
    },
  },
  filterSelected: {
    topics: [],
    locations: [],
    /*status: []*/
    postType: [],
  },
  searchBox: {
    topics: { key: '', display: false },
    locations: { key: '', display: false },
    /*status: {key: '', display: false},*/
    postType: { key: '', display: false },
  },
  suggestionBox: {
    topics: {
      display: false,
      loading: true,
      items: [],
    },
    locations: {
      display: false,
      loading: false,
      items: [],
    },
    /*status: {
      display: false,
      items: []
    }*/
    postType: {
      display: false,
      items: [],
    },
  },
  userProfile: {},
};

const defaultState = JSON.stringify(initialState);

const civicFeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case FETCH_CIVICFEED_ENGAGEMENTS:
      return {
        ...state,
        engagements: [...state.engagements, ...action.payload],
      };
    case CIVICFEED_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case CIVICFEED_RELOAD_LIST:
      return {
        ...state,
        engagements: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
      };
    case CIVICFEED_RESET:
      return {
        ...state,
        engagements: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
        sort: 'newest',
        filterDismissal: false,
      };
    case CIVICFEED_LIST_CHANGE:
      const id = action.payload.id;
      let engagementList = state.engagements;
      let list = state.engagements;

      engagementList.forEach((value, index) => {
        if (value.id === id) {
          list[index] = { ...list[index], ...action.payload.values };
        }
      });

      return {
        ...state,
        engagements: list,
      };
    case SET_CIVICFEED_LOADING:
      return {
        ...state,
        isEngagementLoading: action.payload,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.payload },
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case RESET_USER_PROFILE:
      return {
        ...state,
        userProfile: {},
      };
    default:
      return state;
  }
};

export default civicFeedReducer;
