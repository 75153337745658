/**
 *
 * Post Type Engagement
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import convertFromJSONToHTML from '../../Common/StateToHtml';
import EngagementHeader from './engagementHeader';
import TopicList from './topicList';
import EngagementFooter from './engagementFooter';
import ImgSlider from './imgSlider';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import DiscussionEngagement from '../../../containers/DiscussionEngagement';
import PostContent from './postContent';

const Post = (props) => {
  const [lexicalHtml, setLexicalHtml] = useState(null);
  const [showOriginalLanguage, setShowOriginalLanguage] = useState(false);
  const { user, engagement } = props;
  const link = engagement.linkMeta ? JSON.parse(engagement.linkMeta).url : null;

  const fetcher = async (link) => {
    if (engagement.linkMeta) {
      return JSON.parse(engagement.linkMeta);
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (engagement.text_content_html) {
      setLexicalHtml({__html: engagement.text_content_html});
    }
  }, [engagement]);

  return (
    <div
      className="clsdashboard-content-box clswhite-box mb-3"
      id={`engagement-list-id-${engagement.id}`}
    >
      <EngagementHeader {...props} showOriginalLanguage={showOriginalLanguage} setShowOriginalLanguage={setShowOriginalLanguage} />
      <TopicList {...props} />
      <div className="clssurvey-question-blk">
        <div className={`editor-content`}>
          <span>
            <PostContent {...props} showOriginalLanguage={showOriginalLanguage} />
          </span>
        </div>
      </div>
      {/*attachments !== null ? 
            <>
                {attachments.map((item, index) => {
                    return (
                    item.fileType.split('/', 1)[0] === 'video' ? 
                    <div className="post-img-block mt-2" key={`post-media-${index}`}>
                        <video width="100%" height="240" controls>
                            <source src={item.fileLocation} type={item.fileType === 'video/quicktime' ? 'video/mp4' : item.fileType} />
                            {t(`Your browser does not support the video tag.`)}
                        </video>
                    </div> : 
                    <div className="post-img-block mt-2" key={`post-media-${index}`}>
                        <img src={item.fileLocation} alt="Post" />
                    </div>
                    )
                })}
            </>
            : '' */}
      <ImgSlider {...props} />
      {link !== null ? (
        <div className="link-preview m-2">
          <LinkPreview
            url={link}
            fetcher={fetcher}
            className="preview-link-container"
          />
        </div>
      ) : null}
      <EngagementFooter {...props} />
      <DiscussionEngagement engagement={engagement} type={'Normal'} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
  }
}

export default connect(mapStateToProps, actions)(Post);
