/*
 *
 * Engagement Reactions
 *
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions';
import Reactions from '../../components/Manager/EngagementReactions';
import CivicModal from '../../components/Common/CivicModal';
import { useTranslation } from 'react-i18next';

const EngagementReactions = (props) => {
  const {
    reactEngagement,
    engagementReactFormData,
    type,
    setReactionFormData,
  } = props;
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      if (typeof reactEngagement !== 'undefined') {
        setReactionFormData(reactEngagement);
        setLoaded(true);
      }
    }
  }, [loaded, reactEngagement, setReactionFormData]);

  return (
    <>
      {typeof reactEngagement === 'undefined' ||
      (typeof reactEngagement !== 'undefined' && loaded === true) ? (
        <>
          {type === 'Modal' ? (
            <CivicModal
              id={`react-engagement-${reactEngagement.id}`}
              title={t(engagementReactFormData.title)}
              dismissal={engagementReactFormData.dismissal}
            >
              <Reactions engagementReactFormData={engagementReactFormData} />
            </CivicModal>
          ) : (
            <Reactions engagementReactFormData={engagementReactFormData} />
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    engagementReactFormData: state.engagementReaction.engagementReactFormData,
  };
};

EngagementReactions.defaultProps = {
  type: 'Modal',
};

export default connect(mapStateToProps, actions)(EngagementReactions);
