/*
 *
 * Notification Reducer
 *
 */

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, RESET_APP } from './constants';

const initialState = {
  notifications: [],
};

const defaultState = JSON.stringify(initialState);

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case REMOVE_NOTIFICATION:
      const index = state.notifications.findIndex(
        (notification) => notification.id === action.payload,
      );
      return {
        ...state,
        notifications: [
          ...state.notifications.slice(0, index),
          ...state.notifications.slice(index + 1),
        ],
      };
    default:
      return state;
  }
};

export default notifications;
