import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setFollowingCommunityIssue, hideCommunityIssue } from '../../../containers/CommunityIssues/actions';
import moment from 'moment-timezone';

const CommunityIssueSocialMediaIcons = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    communityIssueId,
    communityIssue: communityIssueProp,
    user,
    allCommunityIssues,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId] ?? communityIssueProp;

  const [isCommunityIssueOpen, setIsCommunityIssueOpen] = useState(true);
  const [closingDate, setClosingDate] = useState(new Date().toLocaleDateString('es-pa'));
  useEffect(() => {
    const currentDateTime = new Date(moment
      .utc(new Date())
      .tz(moment.tz.guess())
      .format('YYYY-MM-DD HH:mm:ss'));
    const t1 = currentDateTime.getTime();
    const t2 = new Date(communityIssue?.closingDate).getTime();
    setIsCommunityIssueOpen((t1 - t2 < -20000)); // .getTime() is inaccurrate; need some offset
    setClosingDate(new Date(communityIssue?.closingDate).toLocaleDateString('es-pa'))
  }, [communityIssue]);

  if (!communityIssue) {
    return <></>
  }
  return (
    <a
      className='image-entry'
      id={`image-entry-${communityIssue.communityIssueId}`}
    >
      <img
        className={`background-img ${isCommunityIssueOpen ? '' : 'grayscale'}`}
        src={communityIssue.mainImage ?? ''}
      />
      <span className='signature-count-box'>
        <b>{communityIssue.numSignees}</b>
        <p>{t('signatures')}</p>
        {
          isCommunityIssueOpen && user?.roleName !== 'PUBLICOFFICIAL' &&
          <a
            className='text-btn'
            onClick={() => {
              dispatch(hideCommunityIssue(communityIssue?.communityIssueId))
            }}
          >
            {t('hide')}
          </a>
        }
        {
          user?.roleName === 'PUBLICOFFICIAL' &&
          <a
            className='text-btn'
            onClick={() => {
              dispatch(setFollowingCommunityIssue(communityIssue.communityIssueId));
            }}
          >
            {t('unfollow')}
          </a>
        }
      </span>
      <div
        className='text-info'
        href={`/community-issues/?id=${communityIssue.communityIssueId}`}
        onClick={() => {
          history.push(`/community-issues/?id=${communityIssue.communityIssueId}`);
        }}
      >
        <b>
          {
            user.selected_language === communityIssue.originalLanguage ?
              communityIssue.title
              :
              (JSON.parse(communityIssue.translation)?.[user.selected_language]?.title) ?
                JSON.parse(communityIssue.translation)?.[user.selected_language]?.title
                :
                communityIssue.title
          }
        </b>
        <div className='inner-row'>
          <img
            src={require('../../../assets/img/alarm-clock-white.svg').default}
            alt="Edit Issue"
            className='icon'
          />
          {
            isCommunityIssueOpen ? 
              <p>
                {t('Open until')}{' '}{closingDate}
              </p>
            :
              <p>
                {t('Closed on')}{' '}{closingDate}
              </p>
          }
        </div>
        <div className='inner-row'>
          <img
            src={require('../../../assets/img/comments-white.svg').default}
            alt="Edit Issue"
            className='icon'
          />
          <p>
            {communityIssue?.numUniqueUsers ?? 0}{' '}{t('residents commented')}
          </p>
        </div>
      </div>
    </a>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueSocialMediaIcons);