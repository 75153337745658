/**
 *
 * Close Engagement Form
 *
 */

import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../Common/LoadingIndicator';
import Notification from '../../../containers/Notification';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-datepicker/dist/react-datepicker.css';
import DateInput from '../../Common/DateInput';
import { subYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import DateSelection from '../../Common/DateSelection';

const CloseEngagementForm = (props) => {
  const {
    closeEngagementData,
    closeEngagementErrors,
    closeEngagementLoading,
    closeEngagementSubmitting,
    closeEngagementChange,
    submitCloseEngagement,
    user,
  } = props;
  const { t } = useTranslation();

  //const [comment, setComment] = useState(EditorState.createEmpty());

  const [loaded, setLoaded] = useState(false);

  const CompleteTimeFrame = React.forwardRef((props, ref) => (
    <DateInput innerRef={ref} {...props} />
  ));

  useEffect(() => {
    if (loaded === false) {
      if (typeof closeEngagementData !== 'undefined') {
        /*if(closeEngagementData.comment !== ''){
          const storeComment = async () => {
            const convertedState = convertFromRaw(JSON.parse(closeEngagementData.comment));
            setComment(EditorState.createWithContent(convertedState));
          }
          storeComment();
        }*/
        setLoaded(true);
      }
    }
  }, [loaded, closeEngagementData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    submitCloseEngagement(closeEngagementData.id);
  };

  return (
    <>
      {loaded === true && typeof closeEngagementData !== 'undefined' ? (
        <div className="clsengagement-form">
          {closeEngagementLoading && <LoadingIndicator />}
          <Notification module={'closeEngagement'} />
          <form onSubmit={handleSubmit}>
            <div className="clssurvey-preview-blk">
              <p>
                {t(
                  `By closing this engagement users will not be able to comment, react or vote. Only you will be able to see survey results until you complete engagement and choose what to publish.`,
                )}
              </p>
              <div className="clssurvey-preview-blk-in mt-2 bg-white">
                {/*<div className="form-group">
                  <label htmlFor="comment">{t('Your Comment')}</label>
                  <Editor editorState={comment}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    wrapperStyle={{ border: "2px solid #eee", borderRadius: "10px", marginBottom: "20px" }}
                    editorStyle={{ padding: "10px"}}
                    onEditorStateChange={editorState => {setComment(editorState); closeCommentEditChange(closeEngagementData.id, convertToRaw(comment.getCurrentContent()))}}
                    toolbar={{
                      options: ['inline', 'emoji', 'history'],
                      inline: {options: ['bold', 'italic', 'underline']}
                    }}
                  />
                  {
                      typeof closeEngagementErrors !== 'undefined' && (closeEngagementErrors.comment && 
                      <div className="clsinvalid">
                          {closeEngagementErrors['comment']}
                      </div>
                      )
                  }
                </div> */}
                <div className="form-group">
                  <label htmlFor="completeTimeFrame">
                    { t('Time to Engagement Completion') }
                  </label>
                  <DateSelection
                    customInput={<CompleteTimeFrame />}
                    onChangeRaw={(e) => e.preventDefault()}
                    placeholderText="Engagement Timeframe"
                    selected={closeEngagementData.completeTimeFrame}
                    onChange={(date) =>
                      closeEngagementChange(
                        closeEngagementData.id,
                        'completeTimeFrame',
                        date,
                      )
                    }
                    minDate={subYears(new Date(), 121)}
                    timeIntervals={15}
                    dateFormat="MM-dd-yyyy"
                    yearDropdownItemNumber={121}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    showMonthDropdown
                    disabled
                    locale={user?.selected_language}
                    timeCaption={t('Time')}
                  />

                  {typeof closeEngagementErrors !== 'undefined' &&
                    closeEngagementErrors.completeTimeFrame && (
                      <div className="clsinvalid">
                        {t(closeEngagementErrors?.['completeTimeFrame'])}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="wrapper text-center">
              <div className="d-inline">
                <button
                  type="submit"
                  className="btn clsyellow-btn"
                  disabled={closeEngagementSubmitting}
                >
                  {t(`Close Engagement`)}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default CloseEngagementForm;
