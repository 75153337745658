import {
  SET_LOCALNEWS_TAB,
  SET_LOCALNEWS_LOADING,
  FETCH_LOCALNEWS_DATA,
  LOCALNEWS_RELOAD_LIST,
  LOCALNEWS_CHANGE,
  LOCALNEWS_RESET,
  SET_FILTERNEWS_TAB,
  RESET_APP,
} from './constants';

const initialState = {
  activeLocalNewsTab: 'city',
  filterNewsTab: 'city',
  localNewsLists: [],
  reloadList: false,
  isLocalNewsLoading: false,
  noRecords: false,
  pageLoader: false,
  sort: 'last_week',
  filterDismissal: false,
  submitDismissal: false,
  filter: {
    states: [],
    counties: [],
    feedSources: {
      city_sources: [],
      county_sources: [],
      state_sources: [],
    },
  },
  filterSelected: {
    state: '',
    county: '',
    feedSources: [],
  },
};

const defaultState = JSON.stringify(initialState);

const localNewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_LOCALNEWS_TAB:
      return {
        ...state,
        activeLocalNewsTab: action.payload,
      };
    case SET_FILTERNEWS_TAB:
      return {
        ...state,
        filterNewsTab: action.payload,
      };
    case SET_LOCALNEWS_LOADING:
      return {
        ...state,
        isLocalNewsLoading: action.payload,
      };
    case FETCH_LOCALNEWS_DATA:
      return {
        ...state,
        localNewsLists: [...state.localNewsLists, ...action.payload],
      };
    case LOCALNEWS_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case LOCALNEWS_RELOAD_LIST:
      return {
        ...state,
        localNewsLists: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
      };
    case LOCALNEWS_RESET:
      return {
        ...state,
        localNewsLists: [],
        reloadList: true,
        noRecords: false,
        pageLoader: false,
        sort: 'last_week',
      };
    default:
      return state;
  }
};

export default localNewsReducer;
