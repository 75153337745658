/*
 *
 * ForgotPassword constants
 *
 */

export const FORGOT_PASSWORD_CHANGE =
  'src/ForgotPassword/FORGOT_PASSWORD_CHANGE';
export const FORGOT_PASSWORD_RESET = 'src/ForgotPassword/FORGOT_PASSWORD_RESET';
export const SET_FORGOT_PASSWORD_FORM_ERRORS =
  'src/ForgotPassword/SET_FORGOT_PASSWORD_FORM_ERRORS';
export const SET_FORGOT_PASSWORD_SUBMITTING =
  'src/ForgotPassword/SET_FORGOT_PASSWORD_SUBMITTING';
export const RESET_APP = 'src/ForgotPassword/RESET_APP';
