/*
 *
 * Local News List
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const NewLists = (props) => {
  const { localNewsLists, user } = props;
  const { t } = useTranslation();

  return (
    <>
      <p className="clsnews-location mb-2">
        <img
          src={require('../../../assets/img/location.svg').default}
          alt="Location"
        />{' '}
        {t(`Your Location:`)} <span>{user.city}</span>
      </p>
      {localNewsLists.map((localNews, index) => (
        // <div className="clsevents-list-whole mb-5" key={index}>
        <div className="clsevents-list-box clswhite-box mb-2" key={index}>
          <a href={localNews.link} target="_blank" rel="noopener noreferrer">
            <div className="media">
              <div className="media-body align-self-center">
                <div className="clsevents-title-blk mb-2">
                  <div className="clsnews-user-img">
                    <img
                      src={localNews.thumbnail_link}
                      className="header-profile-img"
                      alt=""
                    />
                  </div>
                  <div className="clsevents-title ml-2">
                    <h5 className="mb-1">{localNews.newsSource}</h5>
                    <p className="clsnews-date">{localNews.published_date}</p>
                  </div>
                </div>
                <h6 className="mb-2">{localNews.title}</h6>
                <p className="description-less">
                  <span
                    dangerouslySetInnerHTML={{ __html: localNews.description }}
                  />
                </p>
              </div>
              {localNews.image !== 'N/A' ? (
                <div className="clsevent-img-blk align-self-center">
                  <img
                    src={localNews.image}
                    className="align-self-center img-fluid"
                    alt="localnews"
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </a>
        </div>
        // </div>
      ))}
    </>
  );
};

export default NewLists;
