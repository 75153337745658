/**
 *
 * Topic Header
 *
 */

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';

const Topic = (props) => {
  const { followTopic, user } = props;
  const [topicLoader, setTopicLoader] = useState(false);
  //const [topicFollowStatus, setTopicFollowStatus] = useState(false);
  const [topicTobeUpdate, setTopicTobeUpdate] = useState(null);
  const history = useHistory();
  const search = useLocation().search;
  const topic = new URLSearchParams(search).get('topic');
  const topicId = new URLSearchParams(search).get('topicId');
  const followStatus = async (topicId, type) => {
    setTopicLoader(true);
    await followTopic(topicId, type).then(() => {
      setTopicLoader(false);
    });
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof user.userFollowingTopics !== 'undefined') {
      //if(user.userFollowingTopics.length > 0) {
      let topicsList = user.userFollowingTopics
        .map((value) => value.id)
        .filter((value) => value !== null);
      if (topicsList.indexOf(parseInt(topicId)) !== -1) {
        //setTopicFollowStatus(true);
        setTopicTobeUpdate('unfollow');
      } else {
        //setTopicFollowStatus(false);
        setTopicTobeUpdate('follow');
      }
      //}
    }
  }, [user, setTopicTobeUpdate, topicId]);

  return (
    <>
      {topicLoader === true ? <LoadingIndicator /> : ''}
      <div className={`topic-head mb-3`}>
        <div className={`topic-back ml-2`} onClick={() => history.goBack()}>
          <span>
            <img
              src={require('../../../assets/img/back-arrow.svg').default}
              className="mr-3"
              alt="Back"
            />
          </span>
          <span className={`back-txt`}>{topic}</span>
        </div>
        <div
          className={`topic-follow mr-2`}
          onClick={() => {
            followStatus(parseInt(topicId), topicTobeUpdate);
          }}
        >
          <span>{t(capitalizeFirstLetter(topicTobeUpdate))}</span>
        </div>
      </div>
    </>
  );
};

export default Topic;
