/**
 *
 * Profile Setting Page
 *
 */

import React, {useState, useRef, useEffect} from 'react';
import OptionalEdit from '../../../containers/Dashboard/optionalEdit';
import PasswordReset from '../../../containers/Dashboard/passwordReset';
import LoadingIndicator from '../../Common/LoadingIndicator';
import { useHistory, useLocation } from 'react-router-dom';
import CivicModal from '../../Common/CivicModal';
import NotificationSettings from '../../Manager/Profile/notificationSetting';
import { useTranslation } from 'react-i18next';

const ProfileSetting = (props) => {
  const {
    user,
    setPasswordFormData,
    setOptionalInfoData,
  } = props;
  const { t } = useTranslation();
  const hashUrl = '#';
  const history = useHistory();
  const location = useLocation();

  const [optionalEdit, setOptionalEdit] = useState('');
  const [optionalEditLoaded, setOptionalEditLoaded] = useState(false);
  const optionalEditRef = useRef(null);

  const [idmeVerify, setIdmeVerify] = useState('');

  useEffect(() => {
    if (location && location.state && location.state.modalToOpen) {
      if (location.state.modalToOpen === 'optionalInformation') {
        optionalEditRef.current.click();
      }
    }
  }, [location]);

  const renderComponent = (Component, type) => {
    if (type === 'editOptionalInfo') {
      return new Promise(async (resolve, reject) => {
        await setOptionalInfoData(user).then(() => {
          setOptionalEdit(<Component {...props} />);
          return resolve(true);
        });
      });
    } else if (type === 'verification') {
      return new Promise((resolve, reject) => {
        setIdmeVerify(<Component {...props} />);
        return resolve(true);
      });
    }
  };

  const showOptionalInfoEdit = (event, user) => {
    optionalEditRef.current.setAttribute(
      'data-target',
      '#edit-optional-info-' + user.userId,
    );
    if (optionalEditLoaded === false) {
      optionalEditRef.current.click();
      setOptionalEditLoaded(true);
    }
  };

  return (
    <>
      {typeof user.roleName !== 'undefined' ? (
        <div className="clssettings-blk">
          {user.roleName !== 'PUBLICOFFICIAL' && user.roleName !== 'ADMIN' ? (
            <>
              <h5 className="mb-2 pb-1">{t(`My Profile`)}</h5>
              {optionalEdit}
              {idmeVerify}
            </>
          ) : (
            ''
          )}
          <PasswordReset />
          {user.roleName !== 'PUBLICOFFICIAL' && user.roleName !== 'ADMIN' ? (
            <div className="clswhite-box clssettings-box">
              <ul>
                <li>
                  <a
                    href={hashUrl}
                    data-toggle="modal"
                    data-target={hashUrl}
                    onClick={(event) => {
                      renderComponent(OptionalEdit, 'editOptionalInfo').then(
                        () => {
                          showOptionalInfoEdit(event, user);
                        },
                      );
                    }}
                    ref={optionalEditRef}
                  >
                    <span>
                      <img
                        src={require('../../../assets/img/info1.svg').default}
                        alt="Info"
                      />{' '}
                      {t(`My Optional Information`)}
                    </span>
                    <span>
                      {user.highest_edu !== null &&
                      user.employment_status !== null &&
                      user.race !== null &&
                      user.marital_status !== null &&
                      user.political_affiliation !== null &&
                      user.religion !== null ? (
                        <img
                          src={require('../../../assets/img/check.svg').default}
                          alt="Verified"
                        />
                      ) : (
                        <img
                          src={require('../../../assets/img/alert.svg').default}
                          alt="Alert"
                        />
                      )}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            ''
          )}
          <h5 className="mt-4 mb-2 pb-1">{t(`Settings`)}</h5>
          <div className="clswhite-box clssettings-box">
            <ul>
              <li>
                <a
                  href={hashUrl}
                  data-toggle="modal"
                  data-target="#reset-password-form"
                  onClick={() => {
                    setPasswordFormData();
                  }}
                >
                  <span>
                    <img
                      src={require('../../../assets/img/pass.svg').default}
                      alt="Pass"
                    />{' '}
                    {t(`Change Password`)}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={hashUrl}
                  data-toggle="modal"
                  data-target="#notification-settings"
                >
                  <span>
                    <img
                      src={require('../../../assets/img/bell-grey.svg').default}
                      alt="Notifications"
                    />
                    {t(`Notifications`)}
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <h5 className="mt-4 mb-2 pb-1">{t(`General`)}</h5>
          <div className="clswhite-box clssettings-box">
            <ul>
              <li>
                <a
                  href={'https://about.civicbell.com/'}
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <span>
                    <img
                      src={require('../../../assets/img/info2.svg').default}
                      alt="Info"
                    />{' '}
                    {t(`About`)}
                  </span>
                </a>
              </li>
              <li>
                <a
                  href={hashUrl}
                  onClick={() => {
                    history.push('/dashboard/legal');
                  }}
                >
                  <span>
                    <img
                      src={require('../../../assets/img/terms.svg').default}
                      alt="Legal"
                    />{' '}
                    {t(`Legal`)}
                  </span>
                </a>
              </li>
              {/*<li>
                  <a href={hashUrl}>
                    <span><img src={require('../../../assets/img/terms.svg').default } alt="Terms of Service" /> Terms of Service</span>
                  </a>
                </li>
                <li>
                  <a href={hashUrl}>
                    <span><img src={require('../../../assets/img/privacy.svg').default } alt="Privacy Policy" /> Privacy Policy</span>
                  </a>
                </li> */}
            </ul>
          </div>
          <CivicModal
            id={`notification-settings`}
            title={t(`Notifications`)}
            footerClass={`text-center mt-4`}
          >
            <NotificationSettings />
          </CivicModal>
        </div>
      ) : (
        <LoadingIndicator postion={`fixed`} />
      )}
    </>
  );
};

export default ProfileSetting;
