import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import ShareLink from './ShareLink';
import CommunityIssueSocialMediaIcons from './CommunityIssueSocialMediaIcons';
import { setFollowingCommunityIssue } from '../../../containers/CommunityIssues/actions';
import Tooltip from '../../Common/Tooltip';

const CommunityIssueEngagementFooter = (props) => {
  const {
    communityIssueId,
    user,
    authenticated,
    allCommunityIssues,
    allCommunityIssueComments,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const communityIssue = allCommunityIssues[communityIssueId];
  const isIconDisabled = (!user.isVerified || !user.isIdentityVerified || !user.isRegistrationComplete);
  const totalCommentCount = communityIssue?.totalCommentCount ?? 0;

  return (
    <div
      className="clsdashboard-actions-left community-issues-engagement-footer"
      style={{
        marginRight: 15
      }}
    >
      <a
        id={`community-issue-discussion-${communityIssueId}`}
        className={`${!authenticated && 'no-pointer'} a-inherit`}
      >
        <span>
          <img
            src={require('../../../assets/img/comment_icon.svg').default}
            alt="Comment"
          />
        </span>{' '}
        <span>
          <b>
            {totalCommentCount}
          </b>
        </span>
      </a>
      {
        !authenticated &&
          <Tooltip
            targetId={`community-issue-discussion-${communityIssueId}`}
            targetStatus={true}
            styleClass={'react-tooltip'}
            message={t('Please register to follow or engage with this Community Issue')}
          />
      }
      <a
        onClick={() => {
          if (authenticated) {
            dispatch(setFollowingCommunityIssue(communityIssueId));
          }
        }}
        id={`community-issue-follow-box-${communityIssueId}`}
        className={`${!authenticated && 'no-pointer'} a-inherit`}
      >
        <span>
          {communityIssue?.isFollowing ? (
            <>
              <span>
                <img
                  src={require('../../../assets/img/icon-following.svg').default}
                  alt="Following"
                  width="16"
                />
              </span>
            </>
          ) : (
            <>
              <span>
                <img
                  src={require('../../../assets/img/icon-follow.svg').default}
                  alt="Follow"
                  width="16"
                />
              </span>
            </>
          )}
        </span>{' '}
        {communityIssue?.isFollowing ? (
          <span>
            <b>
              {t('Following')}
            </b>
          </span>
        ) : (
          <span>
            <b>
              {t('Follow')}
            </b>
          </span>
        )}
      </a>
      {
        !authenticated &&
          <Tooltip
            targetId={`community-issue-follow-box-${communityIssueId}`}
            targetStatus={true}
            styleClass={'react-tooltip'}
            message={t('Please register to follow or engage with this Community Issue')}
          />
      }
      <div className="translation-tooltip">
        <span>
          <img
            src={require('../../../assets/img/share-black.svg').default}
            alt="Share"
          />
        </span>{' '}
        <span>
          <b>
            {t('Share')}
          </b>
        </span>
        <div className="translation-tooltiptext share-dropdown">
          <div className='share-link-container'>
            <ShareLink communityIssueId={communityIssueId} />
          </div>
          <CommunityIssueSocialMediaIcons communityIssueId={communityIssueId} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    authenticated: state.authentication.authenticated,
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    allCommunityIssueComments: state.communityIssues.allCommunityIssueComments,
  };
};

export default connect(mapStateToProps, actions)(CommunityIssueEngagementFooter);