/**
 *
 * Public Official Profile
 *
 */

import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import actions from '../../../actions';
import Notification from '../../../containers/Notification';
import ProfileEdit from '../../../containers/Dashboard/profileEdit';
import OfficialEdit from '../../../containers/Dashboard/officialEdit';
import SocialLinks from '../../../containers/Dashboard/socialLink';
import ProfileImage from '../../../containers/Dashboard/profileImage';
import ProfileBgImage from '../../../containers/Dashboard/profileBgImage';
import { profileImg } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
import RenderProfileView from './renderProfileView';

const PublicOfficial = (props) => {
  const {
    user,
    setEditProfileData,
    setProfileFormData,
    setProfileImgData,
    setSocialLinkData,
    setFormType,
  } = props;
  const { t } = useTranslation();

  const hashUrl = '#';
  const [officialEdit, setOfficialEdit] = useState('');
  const [editProfileLoaded, setEditProfileLoaded] = useState(false);
  const officialEditRef = useRef(null);

  const [profileEdit, setProfileEdit] = useState('');
  const [profileEditLoaded, setProfileEditLoaded] = useState(false);

  const [imgBlock, setImgBlock] = useState('');
  const [imgBlockLoaded, setImgBlockLoaded] = useState(false);
  const imgBlockRef = useRef(null);

  const [bgImgBlock, setBgImgBlock] = useState('');
  const [bgImgBlockLoaded, setBgImgBlockLoaded] = useState(false);
  const bgImgBlockRef = useRef(null);

  const [socialLinkBlock, setSocialLinkBlock] = useState('');
  const [socialLinkLoaded, setSocialLinkLoaded] = useState(false);
  const socialLinkRef = useRef(null);

  const renderComponent = (Component, type, formType = '') => {
    if (type === 'editProfile') {
      return new Promise(async (resolve, reject) => {
        await setEditProfileData(user).then(() => {
          setOfficialEdit(<Component {...props} type={type} user={user} />);
          return resolve(true);
        });
      });
    } else if (type === 'profileEdit') {
      return new Promise(async (resolve, reject) => {
        await setProfileFormData(user, formType).then(() => {
          setProfileEdit(<Component formType={formType} />);
          //editProfileFormChange('dismissal',false);
          return resolve(true);
        });
      });
    } else if (type === 'profileImage') {
      return new Promise(async (resolve, reject) => {
        await setProfileImgData(user).then(() => {
          setImgBlock(<Component timer={new Date().getTime()} />);
          return resolve(true);
        });
      });
    } else if (type === 'socialLink') {
      /*return new Promise((resolve, reject) => {
        setSocialLinkBlock(<Component timer={ new Date().getTime()}/>);
        return resolve(true);
      })*/
      return new Promise(async (resolve, reject) => {
        await setSocialLinkData(user).then(() => {
          setSocialLinkBlock(<Component timer={new Date().getTime()} />);
          return resolve(true);
        });
      });
    } else if (type === 'profileBgImage') {
      return new Promise((resolve, reject) => {
        setBgImgBlock(<Component timer={new Date().getTime()} />);
        return resolve(true);
      });
    }
  };

  const showProfileEdit = (event, user) => {
    officialEditRef.current.setAttribute(
      'data-target',
      '#edit-profile-' + user.userId,
    );
    if (editProfileLoaded === false) {
      officialEditRef.current.click();
      setEditProfileLoaded(true);
    }
  };

  const showProfileEditForm = (event, user, formType) => {
    event.target.setAttribute(
      'data-target',
      '#profile-edit-' + formType + 'form-modal',
    );
    if (profileEditLoaded === false) {
      event.target.click();
      setProfileEditLoaded(true);
    }
  };

  const showImgBlock = (event, user) => {
    imgBlockRef.current.setAttribute(
      'data-target',
      '#profile-image-modal-' + user.userId,
    );
    if (imgBlockLoaded === false) {
      imgBlockRef.current.click();
      setImgBlockLoaded(true);
    }
  };

  const showSocialLinkBlock = (event, user) => {
    socialLinkRef.current.setAttribute(
      'data-target',
      '#social-link-modal-' + user.userId,
    );
    if (socialLinkLoaded === false) {
      socialLinkRef.current.click();
      setSocialLinkLoaded(true);
    }
  };

  const showBgImgBlock = (event, user) => {
    bgImgBlockRef.current.setAttribute(
      'data-target',
      '#profile-bg-image-modal-' + user.userId,
    );
    if (bgImgBlockLoaded === false) {
      bgImgBlockRef.current.click();
      setBgImgBlockLoaded(true);
    }
  };

  var socialPlatform_Email = '0';
  if (user.socialPlatform.email !== '') {
    socialPlatform_Email = user.socialPlatform.email;
  }

  return (
    <>
      <Notification module={'profile'} />
      <div className="clsprofile-details-blk clswhite-box">
        {bgImgBlock}
        <figure className="clsprofile-post-img">
          <a
            href={hashUrl}
            data-toggle="modal"
            data-target={hashUrl}
            onClick={(event) => {
              renderComponent(ProfileBgImage, 'profileBgImage').then(() => {
                showBgImgBlock(event, user);
              });
            }}
            ref={bgImgBlockRef}
          >
            <img
              src={
                user.profile_bg_picture === null
                  ? require('../../../assets/img/profile-post-img.jpg').default
                  : profileImg(user.profile_bg_picture)
              }
              alt=""
              className="img-fluid profile-bg-image"
            />
          </a>
        </figure>
        {imgBlock}
        <div className="clsprofile-details">
          <div className="media po-profile">
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                renderComponent(ProfileImage, 'profileImage').then(() => {
                  showImgBlock(event, user);
                });
              }}
              ref={imgBlockRef}
            >
              <img
                src={profileImg(user.profile_picture)}
                alt="Avatar"
                className={`profile-img`}
              />
            </a>
            <div className="media-body align-self-end mb-1 ml-3">
              <h5 className="mt-0">
                {user.showMyNameAs ? (
                  <div> {user.show_my_name}</div>
                ) : (
                  <div>
                    {' '}
                    {user.first_name} {user.last_name}
                  </div>
                )}
              </h5>
              {user.showMyNameAs !== 'organization' &&
              user.officialInformations.position ? (
                <p>
                  {user.officialInformations.position}{' '}
                  {user.officialInformations.area
                    ? ' - ' + user.officialInformations.area
                    : ''}
                </p>
              ) : (
                <p>&nbsp;</p>
              )}
            </div>
            {officialEdit}
          </div>
          <div className="clsprofile-followers clsresident-profile-edit px-3">
            <p className="clsprofile-edit">
              <a
                href={hashUrl}
                data-toggle="modal"
                data-target={hashUrl}
                onClick={(event) => {
                  renderComponent(OfficialEdit, 'editProfile').then(() => {
                    showProfileEdit(event, user);
                  });
                }}
                ref={officialEditRef}
              >
                {t(`Edit`)}
              </a>
            </p>
          </div>
          <div className="clsprofile-followers">
            <figure className="mb-1">
              <img
                src={require('../../../assets/img/people.svg').default}
                alt="People"
              />
            </figure>
            <h6>
              {user.officialInformations.followers}{' '}
              <span>{t(`Follower(s)`)}</span>
            </h6>
          </div>
        </div>
      </div>
      {profileEdit}
      <div className="clsprofile-about-blk clswhite-box mt-3">
        <h5 className="mb-2 pb-1">
          {t(`ABOUT ME`)}{' '}
          <span>
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                setFormType('aboutMe');
                renderComponent(ProfileEdit, 'profileEdit', 'aboutMe').then(() => {
                  showProfileEditForm(event, user, 'aboutMe');
                });
              }}
            >
              {t(`Edit`)}
            </a>
          </span>
        </h5>
        <div className={`editor-content`}>
          {!!user.aboutMe_html ? 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'aboutMe'}
              format={'HTML'} 
            />
          : 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'aboutMe'}
              format={'JSON'} 
            />
          }
        </div>
      </div>
      <div className="clsprofile-about-blk clswhite-box mt-3">
        <h5 className="mb-2 pb-1">
          {t(`POLITICAL AFFILIATION`)}
          <span>
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                setFormType('politicalAffiliation');
                renderComponent(ProfileEdit, 'profileEdit', 'politicalAffiliation').then(() => {
                  showProfileEditForm(event, user, 'politicalAffiliation');
                });
              }}
            >
              {t(`Edit`)}
            </a>
          </span>
        </h5>
        <div className={`editor-content`}>
          {!!user.officialInformations.politicalAffiliation_html ? 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'politicalAffiliation'}
              format={'HTML'} 
            />
          : 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'politicalAffiliation'}
              format={'JSON'} 
            />
          }
        </div>
      </div>
      <div className="clsprofile-about-blk clswhite-box mt-3">
        <h5 className="mb-2 pb-1">
          {t(`WHAT DO I STAND FOR`)}{' '}
          <span>
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                setFormType('whatDoIStandFor');
                renderComponent(ProfileEdit, 'profileEdit','whatDoIStandFor').then(() => {
                  showProfileEditForm(event, user, 'whatDoIStandFor');
                });
              }}
            >
              {t(`Edit`)}
            </a>
          </span>
        </h5>
        <div className={`editor-content`}>
          {!!user.officialInformations.whatDoIStandFor_html ? 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'whatDoIStandFor'}
              format={'HTML'} 
            />
          : 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'whatDoIStandFor'}
              format={'JSON'} 
            />
          }
        </div>
      </div>
      <div className="clsprofile-about-blk clswhite-box mt-3">
        <h5 className="mb-2 pb-1">
          {t(`POLITICAL & PROFESSIONAL BACKGROUND`)}{' '}
          <span>
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                setFormType('politicalBackground');
                renderComponent(ProfileEdit, 'profileEdit', 'politicalBackground').then(() => {
                  showProfileEditForm(event, user, 'politicalBackground');
                });
              }}
            >
              {t(`Edit`)}
            </a>
          </span>
        </h5>
        <div className={`editor-content`}>
          {!!user.officialInformations.politicalBackground_html ? 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'politicalBackground'}
              format={'HTML'} 
            />
          : 
            <RenderProfileView
              user={user}
              userProfile={user}
              officialInformations={user.officialInformations}
              defaultText={'Please tell us about you.'}
              type={'politicalBackground'}
              format={'JSON'} 
            />
          }
        </div>
      </div>
      {socialLinkBlock}
      <div className="clsprofile-socialmedia-blk clswhite-box mt-3">
        <h5 className="mb-2 pb-1">
          {t(`MY SOCIAL MEDIA`)}{' '}
          <span>
            <a
              href={hashUrl}
              data-toggle="modal"
              data-target={hashUrl}
              onClick={(event) => {
                renderComponent(SocialLinks, 'socialLink').then(() => {
                  showSocialLinkBlock(event, user);
                });
              }}
              ref={socialLinkRef}
            >
              {t(`Edit`)}
            </a>
          </span>
        </h5>
        <ul>
          <li>
            <a
              href={
                user.socialPlatform.twitter !== ''
                  ? user.socialPlatform.twitter
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/x-twitter.svg').default}
                alt="Twitter"
              />
            </a>
          </li>
          <li>
            <a
              href={
                user.socialPlatform.facebook !== ''
                  ? user.socialPlatform.facebook
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/facebook.svg').default}
                alt="Facebook"
              />
            </a>
          </li>
          <li>
            <a
              href={
                user.socialPlatform.linkedIn !== ''
                  ? user.socialPlatform.linkedIn
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/linkedin.svg').default}
                alt="Linkedin"
              />
            </a>
          </li>
          <li>
            <a
              href={
                user.socialPlatform.nextDoor !== ''
                  ? user.socialPlatform.nextDoor
                  : hashUrl
              }
              target={`_blank`}
            >
              <img
                src={require('../../../assets/img/nextdoor-social.png').default}
                alt="Nextdoor"
              />
            </a>
          </li>
          {socialPlatform_Email ? (
            <li>
              <a href={'mailto:' + user.socialPlatform.email} target={`_blank`}>
                <img
                  src={require('../../../assets/img/envelope.svg').default}
                  alt="Email"
                />
              </a>
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.account.user,
    formType: state.account.formType,
  };
};

export default connect(mapStateToProps, actions)(PublicOfficial);
