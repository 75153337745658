/*
 *
 * Engagement reducer
 *
 */

import {
  SET_EDIT_ENGAGEMENT_DATA,
  ENGAGEMENT_CHANGE,
  ENGAGEMENT_ATTACHMENT,
  REMOVE_ATTACHEMENT,
  SET_ENGAGEMENT_LOADING,
  SET_ENGAGEMENT_FORM_ERRORS,
  SET_ENGAGEMENT_QUESTION_FORM_ERRORS,
  SET_ENGAGEMENT_SUBMITTING,
  ADD_QUESTION,
  REMOVE_QUESTION,
  ADD_CHOICE,
  REMOVE_CHOICE,
  FETCH_ENGAGEMENTS,
  REMOVE_ENGAGEMENT,
  SET_DELETE_HANDLE,
  SET_ENGAGEMENT_TAB,
  SET_NO_RECORD_STATUS,
  SET_RESET_STATUS,
  SET_ENGAGEMENT_FORM_RESET,
  ENGAGEMENTS_RESET,
  ENGAGEMENT_RELOAD_LIST,
  ENGAGEMENT_SPECIFIC_DATA,
  ENGAGEMENT_SPECIFIC_RESET,
  RESET_APP,
} from './constants';

const initialState = {
  engagements: {
    unpublished: [],
    open: [],
    closed: [],
    outcome: [],
  },
  deleteHandle: false,
  noRecords: {
    unpublished: false,
    open: false,
    closed: false,
    outcome: false,
  },
  reloadList: false,
  activeTab: 'unpublished',
  engagementFormData: {
    category: '',
    targetPopulation: '1',
    postUnderOrganization: false,
    startNow: false,
    engagementTimeFrame: '',
    closeTimeFrame: '',
    completeTimeFrame: '',
    tags: [],
    visibleToNonConsttuent: true,
    enableDiscussionBoard: true,
    surveyTitle: '',
    comment: '',
    files: [],
    links: [],
    linksToBeRemoved: [],
    description: '',
    question: [
      {
        type: '',
        title: '',
        choice: [
          { id: null, value: '' },
          { id: null, value: '' },
        ],
      },
    ],
    step: 1,
    title: 'Start New Engagement',
    dismissal: false,
    schedulePost: false,
    postScheduleTime: '',
  },
  formErrors: [],
  isEngagementSubmitting: false,
  isEngagementLoading: false,
  resetStatus: false,
  engagementSpecific: {},
  engagementAttachment: {},
};

const defaultState = JSON.stringify(initialState);

const engagementReducer = (state = initialState, action) => {
  let questionList = state.engagementFormData.question;

  switch (action.type) {
    case RESET_APP:
      return JSON.parse(defaultState);
    case SET_EDIT_ENGAGEMENT_DATA:
      return {
        ...state,
        engagementFormData: action.payload,
      };
    case FETCH_ENGAGEMENTS:
      const { engagement, status } = action.payload;
      let statusEngagement = [];
      statusEngagement[status] = [...state.engagements[status], ...engagement];
      return {
        ...state,
        engagements: { ...state.engagements, ...statusEngagement },
      };
    case REMOVE_ENGAGEMENT:
      const { filterId, filterStatus } = action.payload;
      let engagements = state.engagements[filterStatus].filter(
        (item, index) => {
          return item.id !== filterId;
        },
      );
      return {
        ...state,
        engagements: {
          ...state.engagements,
          ...{ [filterStatus]: engagements },
        },
        deleteHandle: true,
      };
    case SET_DELETE_HANDLE:
      return {
        ...state,
        deleteHandle: action.payload,
      };
    case ENGAGEMENTS_RESET:
      let resetEngagement = [];
      resetEngagement[action.payload] = [];
      let noRecordStatus = [];
      noRecordStatus[action.payload] = false;
      return {
        ...state,
        engagements: { ...state.engagements, ...resetEngagement },
        noRecords: { ...state.noRecords, ...noRecordStatus },
      };
    case ENGAGEMENT_RELOAD_LIST:
      return {
        ...state,
        reloadList: action.payload.reloadList,
        activeTab: action.payload.activeTab,
      };
    case ENGAGEMENT_CHANGE:
      return {
        ...state,
        engagementFormData: { ...state.engagementFormData, ...action.payload },
      };
    case SET_ENGAGEMENT_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };
    case SET_ENGAGEMENT_QUESTION_FORM_ERRORS:
      return {
        ...state,
        formErrors: { ...state.formErrors, question: action.payload },
      };
    case SET_ENGAGEMENT_LOADING:
      return {
        ...state,
        isEngagementLoading: action.payload,
      };
    case SET_ENGAGEMENT_SUBMITTING:
      return {
        ...state,
        isEngagementSubmitting: action.payload,
      };
    case ADD_QUESTION:
      questionList.push({
        type: '',
        title: '',
        choice: [
          { id: null, value: '' },
          { id: null, value: '' },
        ],
      });
      return {
        ...state,
        engagementFormData: {
          ...state.engagementFormData,
          question: questionList,
        },
      };
    case REMOVE_QUESTION:
      questionList.splice(action.payload.questionId, 1);
      return {
        ...state,
        engagementFormData: {
          ...state.engagementFormData,
          question: questionList,
        },
      };
    case ADD_CHOICE:
      questionList[action.payload.questionId].choice.push({
        id: null,
        value: '',
      });
      return {
        ...state,
        engagementFormData: {
          ...state.engagementFormData,
          question: questionList,
        },
      };
    case REMOVE_CHOICE:
      const { questionId, choiceId } = action.payload;
      delete questionList[questionId].choice[choiceId];
      let choiceArr = questionList[questionId].choice.filter(function (el) {
        return el !== 'empty';
      });
      questionList[questionId]['choice'] = choiceArr;
      return {
        ...state,
        engagementFormData: {
          ...state.engagementFormData,
          question: questionList,
        },
      };
    case SET_ENGAGEMENT_FORM_RESET:
      return {
        ...state,
        engagementFormData: {
          category: '',
          targetPopulation: '1',
          postUnderOrganization: false,
          startNow: false,
          engagementTimeFrame: '',
          closeTimeFrame: '',
          completeTimeFrame: '',
          tags: [],
          visibleToNonConsttuent: true,
          enableDiscussionBoard: true,
          surveyTitle: '',
          comment: '',
          step: 1,
          title: 'Start New Engagement',
          dismissal: false,
          files: [],
          links: [],
          linksToBeRemoved: [],
          description: '',
          question: [
            {
              type: '',
              title: '',
              choice: [
                { id: null, value: '' },
                { id: null, value: '' },
              ],
            },
          ],
          schedulePost: false,
          postScheduleTime: '',
        },
        engagementAttachment: {},
        formErrors: {},
      };
    case SET_ENGAGEMENT_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_RESET_STATUS:
      return {
        ...state,
        resetStatus: action.payload,
      };
    case SET_NO_RECORD_STATUS:
      return {
        ...state,
        noRecords: action.payload,
      };
    case ENGAGEMENT_SPECIFIC_DATA:
      return {
        ...state,
        engagementSpecific: action.payload,
      };
    case ENGAGEMENT_SPECIFIC_RESET:
      return {
        ...state,
        engagementSpecific: {},
      };
    case ENGAGEMENT_ATTACHMENT:
      return {
        ...state,
        engagementAttachment: action.payload,
      };
    case REMOVE_ATTACHEMENT:
      return {
        ...state,
        linksToBeRemoved: action.payload,
      };
    default:
      return state;
  }
};

export default engagementReducer;
