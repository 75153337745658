/**
 *
 * File Uploader
 *
 */

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import CropImg from '../CivicProfileImg/cropImg';
import LoadingIndicator from '../LoadingIndicator';
import { useTranslation } from 'react-i18next';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const CivicProfileImg = (props) => {
  const {
    user,
    profileImgDataChange,
    link,
    uploadProfileImage,
    type,
    addNotification,
    createDefault,
  } = props;
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [displayImg, setDisplayImg] = useState(true);

  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [uploadDisable, setUploadDisable] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [newUpload, setNewUpload] = useState(true);

  const onDrop = useCallback(
    (acceptedFile) => {
      setFile(acceptedFile[0]);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageToCrop(reader.result));
      reader.readAsDataURL(acceptedFile[0]);
      setFileUploaded(true);
      setDisplayImg(false);
      profileImgDataChange('title', 'Drag and Reposition');
    },
    [setFile, profileImgDataChange],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, multiple: false });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const resetCrop = () => {
    setFileUploaded(false);
    setImageToCrop(undefined);
    setImageToCrop(undefined);
    profileImgDataChange('title', t('Upload your profile image'));
    setDisplayImg(true);
    setNewUpload(true);
  };

  const profileImageUpload = async () => {
    if (typeof croppedImage !== 'undefined') {
      if (croppedImage !== null && croppedImage !== '') {
        let fileName =
          type === 'profileBgImage'
            ? 'profile-bg-' + user.userId + '.png'
            : 'profile-' + user.userId + '.png';
        let fileWithOutExt =
          type === 'profileBgImage'
            ? 'profile-bg-' + user.userId
            : 'profile-' + user.userId;

        let croppedFile = new File([croppedImage], fileName, {
          type: 'image/png',
        });
        setLoader(true);
        await uploadProfileImage(
          type,
          croppedFile,
          fileWithOutExt,
          user.userId,
        ).then(() => {
          setLoader(false);
          setDisplayImg(true);
          profileImgDataChange('dismissal', true);
        });
      }
    }
  };

  useEffect(() => {
    if (typeof file === 'object') {
      var _URL = window.URL || window.webkitURL;
      var img = new Image();
      var objectUrl = _URL.createObjectURL(file);
      img.onload = function () {
        if (type === 'profileBgImage') {
          if (
            typeof addNotification === 'function' &&
            (this.width < 800 || this.height < 240)
          ) {
            addNotification({
              type: 'error',
              message: t(
                'The background image is very small. Please upload a larger cover photo with minimum 800px width and 240px height.',
              ),
              module: 'dashboard',
              displayFor: 8000,
              parentDivClass: 'top-notification',
            });
            setUploadDisable(true);
          } else {
            setUploadDisable(false);
          }
        } else if (type === 'profileImage') {
          if (
            typeof addNotification === 'function' &&
            (this.width < 110 || this.height < 110)
          ) {
            addNotification({
              type: 'error',
              message: t(
                'The profile image is very small. Please upload a larger photo with minimum 110px width and 110px height.',
              ),
              module: 'dashboard',
              displayFor: 8000,
              parentDivClass: 'top-notification',
            });
            setUploadDisable(true);
          } else {
            setUploadDisable(false);
          }
        }
        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  }, [file, addNotification, type, t]);

  const generateDefault = async () => {
    setLoader(true);
    await createDefault().then(() => {
      setLoader(false);
      setDisplayImg(false);
    });
  };
  var d = new Date(); // for now

  return (
    <>
      {loader === true ? <LoadingIndicator position={`fixed`} /> : ''}

      {displayImg === true ? (
        <div className="text-center">
          <img
            src={link + '?' + d.getTime()}
            alt="Profile"
            width={
              type === 'profileImage'
                ? '110px'
                : window.innerWidth > '810px'
                ? '800px'
                : window.innerWidth > 800
                ? '699px'
                : '300px'
            }
            height={type === 'profileBgImage' ? '240px' : '110px'}
          />
          {/*<div className="btn clsinfo uploader-close">
            <a href={hashtag} onClick={() => { removeProfileImg(); }}>
              <img src={require('../../../assets/img/delete.svg').default } alt="Remove File" />
            </a>
          </div> */}
          <div className="btn image-preview-close">
            <img
              src={require('../../../assets/img/delete.svg').default}
              alt="Remove File"
              onClick={() => {
                generateDefault();
              }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <>
        {fileUploaded === false ? (
          <div className="form-group">
            <label>{t(`Upload Your Profile Photo`)}</label>
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div>
                  <span className={'file-drag-drop-txt'}>
                    {t(`Drag & Drop or `)}
                  </span>
                  <span className={'file-browse-txt'}>{t(`Browse`)}</span>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div>
            <aside>
              {file !== '' && typeof file === 'object' ? (
                <div className={`profile-upload mt-4`}>
                  <div className={`profile-original-img profile-img-item`}>
                    <CropImg
                      imageToCrop={imageToCrop}
                      onImageCropped={(croppedImage) => {
                        setCroppedImage(croppedImage);
                        setProceed(true);
                        setNewUpload(false);
                      }}
                      type={type}
                      setCropLoading={(value) => {
                        setProceed(value);
                      }}
                      initialLoad={newUpload}
                    />
                  </div>
                  <div className={`profile-cropped-img profile-img-item`}>
                    {croppedImage && (
                      <>
                        <div className={`profile-img-cropped-div`}>
                          <p>Cropped Photo</p>
                          <img
                            alt="Cropped"
                            src={window.URL.createObjectURL(croppedImage)}
                            className={
                              type === 'profileImage'
                                ? `cropped-image`
                                : `cropped-bg-image`
                            }
                            width={
                              type === 'profileImage'
                                ? '110px'
                                : window.innerWidth > '810px'
                                ? '800px'
                                : window.innerWidth > 800
                                ? '699px'
                                : '300px'
                            }
                            height={type === 'profileImage' ? '110px' : '240px'}
                          />
                        </div>
                        <div className={`profile-crop-button-div`}>
                          <button
                            type="button"
                            disabled={uploadDisable || !proceed}
                            className="btn clsyellow-btn mt-2 mr-4"
                            onClick={() => {
                              profileImageUpload();
                            }}
                          >
                            {t(`Upload Now`)}
                          </button>
                          <button
                            type="button"
                            className="btn clsyellow-btn mt-2"
                            onClick={() => {
                              resetCrop();
                            }}
                          >
                            {t(`Try New`)}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </aside>
          </div>
        )}
      </>
    </>
  );
};

CivicProfileImg.defaultProps = {
  link: null,
  type: 'profileImage',
};

export default CivicProfileImg;
