import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import actions from '../../actions';
import LoadingIndicator from '../../components/Common/LoadingIndicator';
import CreateIssue from '../../components/Manager/CommunityIssues/CreateIssue';
import CommunityIssueFilter from '../../components/Manager/CommunityIssues/CommunityIssueFilter';
import CommunityIssueEngagement from '../../components/Manager/CommunityIssues/CommunityIssueEngagement';
import IndividualCommunityIssueNavBar from '../../components/Manager/CommunityIssues/IndividualCommunityIssueNavBar';
import CommunityIssueList from '../../components/Manager/CommunityIssues/CommunityIssueList';
import '../../components/Manager/CommunityIssues/styles.css';
import {
  getCommunityIssues,
  getIndividualCommunityIssue,
  getMyCommunityIssues,
  getCommunityIssueTopics,
  setCommunityIssueListPage
} from './actions';
import { getMyDistricts } from '../Dashboard/actions';
import { useInView } from "react-intersection-observer";
import {
  setIsSignUpButtonClicked,
  setRegistrationModalOpen
} from '../RegisterFlow/actions';
import { useTranslation } from 'react-i18next';
import { GOOGLE_PLACES_API_KEY } from '../../constants';
import Autocomplete from 'react-google-autocomplete';
import StatusMessage from "../../components/Common/StatusMessage";
import { guestProfileAddressUpdate } from '../Dashboard/actions';
import Page404 from '../../components/Common/Page404';

const CommunityIssues = (props) => {
  const {
    location,
    allCommunityIssues,
    isCommunityIssuesLoading,
    authenticated,
    account,
    editProfileChange,
    isSilentUser,
    geoId,
    alerts,
    user,
    listPage,
    listLimit,
    listFilterType,
    listIssueCategory,
    sortOrder,
    myCommunityIssuesFeedIds,
  } = props;

  const dispatch = useDispatch();
  const [urlId, setUrlId] = useState(null);
  const [curLocation, setCurLocation] = useState('');
  const { t } = useTranslation();
  const [guestAddress, setGuestAddress] = useState({});
  const [showGuestAddressPicker, setShowGuestAddressPicker] = useState(true);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const title = urlId ? allCommunityIssues[urlId]?.title : 'Community Issues';
  const metaImage = `https://${process.env.NODE_ENV === 'production' ? 'civic-prod-documents' : 'civic-dev-documents'}.s3.us-west-1.amazonaws.com/community-issue-cover-images/${urlId}.png`;

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    setUrlId(new URLSearchParams(location.search).get('id'))
  }, [location.search])

  useEffect(() => {
    if (listPage !== 1) {
      dispatch(setCommunityIssueListPage(1));
      if (urlId) {
        dispatch(getIndividualCommunityIssue(urlId, true));
      } else {
        dispatch(getCommunityIssues({
          page: 1,
          limit: listLimit,
          filter: listFilterType,
          category: listIssueCategory,
          sortOrder: sortOrder,
          reset: true,
        }));
      }
      if (myCommunityIssuesFeedIds.length === 0) {
        dispatch(getMyCommunityIssues());
      }
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('token')) return;

    if (urlId) {
      dispatch(getIndividualCommunityIssue(urlId, true));
    } else {
      dispatch(getCommunityIssues({
        page: listPage,
        limit: listLimit,
        filter: listFilterType,
        category: listIssueCategory,
        sortOrder: sortOrder,
        reset: true,
      }));
    }
    if (myCommunityIssuesFeedIds.length === 0) {
      dispatch(getMyCommunityIssues());
    }
    dispatch(getCommunityIssueTopics());
    dispatch(getMyDistricts());
    setCurLocation(location);
  }, [urlId, isSilentUser, authenticated, geoId]);

  useEffect(() => {
    if (!authenticated) {
      if (inView && Object.values(allCommunityIssues).length > 0 && !isCommunityIssuesLoading) {
        const registrationModalButton = document.getElementById('registration-modal-button');

        if (registrationModalButton && !firstLoad) {
          dispatch(setIsSignUpButtonClicked(false));
          dispatch(setRegistrationModalOpen(true));
        }
        if (firstLoad) {
          setFirstLoad(false);
        }
      }
    }
  }, [inView, allCommunityIssues]);

  const preventSubmit = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const storedAddress = JSON.parse(localStorage.getItem('guest_address'));

    if (storedAddress !== null) {
      formatAddress(storedAddress);

      setGuestAddress(storedAddress);
      setShowGuestAddressPicker(false);
    }
  }, []);

  const formatAddress = (address) => {
    let addr = {};

    editProfileChange('zip_code', '');

    for (const component of address.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          addr.address = component.long_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'route': {
          addr.address = addr.hasOwnProperty('address')
              ? addr.address + ' ' + component.short_name
              : component.short_name;
          editProfileChange('address', addr.address)
          break;
        }
        case 'postal_code': {
          addr.zip_code = component.long_name;
          editProfileChange('zip_code', addr.zip_code)
          break;
        }
        case 'locality':
          addr.city = component.long_name;
          editProfileChange('city', addr.city)
          break;
        case 'administrative_area_level_1': {
          addr.state = component.short_name;
          editProfileChange('state', addr.state)
          break;
        }
        default: {
          break;
        }
      }
    }
    // return addr;
  }

  const storeAddress = (address) => {
    const storedAddress = JSON.stringify(address);

    if (typeof address.name === 'undefined' && address) {
      localStorage.setItem('guest_address', storedAddress);

      formatAddress(address);
      dispatch(guestProfileAddressUpdate(address));
      // getInactiveStatusMesages();

      setGuestAddress(address);
      setShowGuestAddressPicker(false);
    }
  };

  const handlePickGuestAddress = () => {
    setShowGuestAddressPicker(true);
  };

  useEffect(() => {
    if (alerts?.length > 0) {
      for (let i = 0; i < alerts.length; i++) {
        if (alerts[i]?.type === 'error') {
          setIsErrorAlert(true);
          break;
        }
      }
    }
  }, [alerts]);

  useEffect(() => {
    const ogImage = document.querySelector('meta[property="og:image"]');
    const metaTitle = document.querySelector('meta[name="title"]');
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const twitterImage = document.querySelector('meta[property="twitter:image"]');
    const twitterTitle = document.querySelector('meta[property="twitter:title"]');

    if (ogImage) ogImage.remove();
    if (metaTitle) metaTitle.remove();
    if (ogTitle) ogTitle.remove();
    if (twitterImage) twitterImage.remove();
    if (twitterTitle) twitterTitle.remove();
  }, []);

  // return <div>test</div>;

  return ( // List of community issues
    (urlId) ?
      <>
        <Helmet>
          <meta property="og:image" content={metaImage} />
          <meta name="title" content={`Community Issue - ${title}`} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://civicbell.com/" />
          <meta property="og:title" content={`Community Issue - ${title}`} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://civicbell.com/" />
          <meta property="twitter:title" content={`Community Issue - ${title}`} />
          <meta property="twitter:image" content={metaImage} />
        </Helmet>
        {
          !isErrorAlert ? (
            isCommunityIssuesLoading || !(allCommunityIssues[urlId]) ?
              <LoadingIndicator position={`fixed`} />
              :
              <>
                <IndividualCommunityIssueNavBar communityIssueId={urlId} />
                <CommunityIssueEngagement communityIssueId={urlId} />
              </>
          ) :
          <Page404 />
        }
      </>
      :
      <>
        {
          authenticated && user.roleName === 'RESIDENT' &&
          <div className="community-issues-parent">
            <CreateIssue />
          </div>
        }
        {!authenticated ?
          showGuestAddressPicker ?
            <div style={{
              marginBottom: '16px',
              padding: '15px',
              backgroundColor: '#ffffff',
              borderRadius: '13px',
            }}>
              <span style={{
                fontWeight: 'bold'
              }}>{t('Enter your residential address')}</span>{' '}{t(' to see posts from your elected officials and agencies')}
              <div style={{
                display: 'flex',
                gap: '20px',
                marginTop: '10px'
              }}>

                <Autocomplete
                  apiKey={GOOGLE_PLACES_API_KEY}
                  className="form-control-plaintext"
                  placeholder={t('Residential Address (incl. street number, no PO Box)')}
                  type="text"
                  id="location_search"
                  name="location_search"
                  defaultValue={account.editProfileData.address ?
                    account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                    account.editProfileData.state + ', ' + account.editProfileData.zip_code
                    : ''}
                  onKeyDown={preventSubmit}
                  autoComplete="off"
                  onPlaceSelected={(place) => {
                    storeAddress(place);
                  }}
                  options={{
                    types: ['address'],
                    componentRestrictions: { country: 'us' },
                    fields: ['address_components', 'formatted_address'],
                  }}
                  style={{
                    border: '1px solid #ced4da',
                    borderRadius: '10px',
                    height: '35px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    fontSize: '14px',
                    lineHeight: '1.5',
                    fontWeight: 400,
                  }}
                />
              </div>
            </div> : <div style={{
              marginBottom: '16px',
              padding: '15px',
              backgroundColor: '#ffffff',
              borderRadius: '13px',
              display: 'flex',
              gap: '10px'
            }}>
              {/*{Object.keys(guestAddress).length === 0 ? ('No address provided.') : (*/}
              {account.editProfileData.address === '' ? <>{t('No address provided.')}</> : (
                <div>
                  <span
                    style={{ fontWeight: 'bold' }}>{t('You\'re seeing issues for ')}</span> {account.editProfileData.address ?
                      account.editProfileData.address + ', ' + account.editProfileData.city + ', ' +
                      account.editProfileData.state + ', ' + account.editProfileData.zip_code
                      : ''}
                </div>
              )}
              <span
                onClick={handlePickGuestAddress}
                style={{
                  color: '#ffaa33',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  float: 'right'
                }}>{t('change')}</span>
            </div>
          : ''
        }
        {Object.keys(guestAddress).length > 0 && account.inactiveMessages &&
          <StatusMessage
            statusMessages={account.inactiveMessages}
            showFinalizationButton={isSilentUser}
            openModal={setRegistrationModalOpen}
          />}
        <CommunityIssueFilter />
        <CommunityIssueList />
        <div ref={ref} style={{ minHeight: '200px' }}>
        </div>
      </>
  )
};
const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
    isCommunityIssuesLoading: state.communityIssues.isCommunityIssuesLoading,
    authenticated: state.authentication.authenticated,
    account: state.account,
    isSilentUser: state.authentication.isSilentUser,
    geoId: state.account?.user?.geoId,
    alerts: state.notifications?.notifications,
    user: state.account.user,
    listPage: state.communityIssues.listPage,
    listLimit: state.communityIssues.listLimit,
    listFilterType: state.communityIssues.listFilterType,
    listIssueCategory: state.communityIssues.listIssueCategory,
    sortOrder: state.communityIssues.sortOrder,
    myCommunityIssuesFeedIds: state.communityIssues.myCommunityIssuesFeedIds,
  }
}

export default connect(mapStateToProps, actions)(CommunityIssues);