import i18next from 'i18next';

/*
 *
 * LocalNews constants
 *
 */

export const SET_LOCALNEWS_TAB = 'src/LocalNews/SET_LOCALNEWS_TAB';
export const SET_LOCALNEWS_LOADING = 'src/LocalNews/SET_LOCALNEWS_LOADING';
export const FETCH_LOCALNEWS_DATA = 'src/LocalNews/FETCH_LOCALNEWS_DATA';
export const LOCALNEWS_RELOAD_LIST = 'src/LocalNews/LOCALNEWS_RELOAD_LIST';
export const LOCALNEWS_CHANGE = 'src/LocalNews/LOCALNEWS_CHANGE';
export const LOCALNEWS_RESET = 'src/LocalNews/LOCALNEWS_RESET';
export const SET_FILTERNEWS_TAB = 'src/LocalNews/SET_FILTERNEWS_TAB';
export const RESET_APP = 'src/LocalNews/RESET_APP';

export const sortOptionsLocalNews = [
  { value: 'last_24_hours', label: 'Last 24 Hours', translationParsing: i18next.t('Last 24 Hours') },
  { value: 'last_week', label: 'Last Week', translationParsing: i18next.t('Last Week') },
  { value: 'last_month', label: 'Last Month', translationParsing: i18next.t('Last Month')},
  { value: 'all', label: 'Show All', translationParsing: i18next.t('Show All') },
];
