/**
 *
 * Not Found Page For Analytics Module
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';

const NoData = (props) => {
  const { cssClass } = props;
  const { t } = useTranslation();

  return (
    <div className={`mb-3 no-engagement ${cssClass}`}>
      <span className={'sad-img'}>
        <img
          src={require('../../../assets/img/sad.svg').default}
          alt="No Engagements"
        />
      </span>
      <span className="no-engagement-txt">{t(`No result data is currently available. If you believe this to be a mistake, please contact us at support@civicbell.com.`)}</span>
    </div>
  );
};

NoData.defaultProps = {
  cssClass: '',
};

export default NoData;
