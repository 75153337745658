import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from "../../../actions";
import { useTranslation } from 'react-i18next';
import ShowAreaMap from './ShowAreaMap';

const IssueAreaModal = (props) => {
  const { t } = useTranslation();
  const {
    communityIssueId,
    allCommunityIssues,
    isOpen,
  } = props;

  const communityIssue = allCommunityIssues[communityIssueId];
  const [invalidateSize, setInvalidateSize] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      // Toggle invalidateSize semi-frequently over 500 ms to ensure the map is resized properly
      const interval = setInterval(() => {
        setInvalidateSize(prev => !prev);
      }, 10);

      setTimeout(() => {
        clearInterval(interval);
      }, 500);
    }
  }, [isOpen]);
  
  return (
    <div className='issue-area-modal'>
      <p>
        {communityIssue?.ocdIdInfo?.name}
      </p>
      {
        communityIssue.area && 
          <ShowAreaMap 
            communityIssueId={communityIssueId} 
            invalidateSize={invalidateSize} 
            overrideCenter={communityIssue?.area[0]?.length > 0 ? [communityIssue?.area[0][0][1], communityIssue?.area[0][0][0]] : null}
          />
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allCommunityIssues: state.communityIssues.allCommunityIssues,
  };
};

export default connect(mapStateToProps, actions)(IssueAreaModal);